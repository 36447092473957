import { Component, OnInit } from '@angular/core';
import { Cmyk } from 'ngx-color-picker';
import { CommunicationService } from '../shared/services/communication.service';
import { LoginThemeService } from '../shared/services/login-theme.service';

declare var $: any;
@Component({
  selector: 'app-login-screen-customiztion',
  templateUrl: './login-screen-customiztion.component.html',
  styleUrls: ['./login-screen-customiztion.component.scss']
})
export class LoginScreenCustomiztionComponent implements OnInit {

  backgroundColor;
  fontColor;
  buttonColor;
  buttonTextColor;
  leftSideColor;
  rightSideColor;
  cancelButtonColor;
  cancelButtonTextColor;


  logoName;
  smallIconName;

  logoUpload;
  smallFileUpload;

  cmykValue = '';
  cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  fileSize = 1024 * 1024;
  btnSpinner = false;
  confirmationVar;
  logos;
  previousThemeFlag = false;
  isDefault = false;
  toggleDisable = false;
  customerTheme = [];
  imgError = false;
  logoUrl = 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/10t-new.png';
  fontColorChangeFlg = false;
  subdomain: string = '';

  constructor(
    private communicationService: CommunicationService,
    private loginThemeService: LoginThemeService
  ) { }

  ngOnInit(): void {
    this.getCustomerLoginTheme();
  }

  getCustomerLoginTheme() {
    this.loginThemeService.getCustomerLoginTheme()
      .subscribe((res: any) => {

        this.customerTheme = res;

        if(this.customerTheme.length > 0) {
          this.fontColorChangeFlg = true;

          this.subdomain = this.customerTheme[0].subdomain;
          this.backgroundColor = this.customerTheme[0].background;
          this.leftSideColor = this.customerTheme[0].leftColor;
          this.rightSideColor = this.customerTheme[0].rightColor;
          this.buttonColor = this.customerTheme[0].button;
          this.fontColor = this.customerTheme[0].font;
          this.buttonTextColor = this.customerTheme[0].buttonTextColor;
          this.cancelButtonColor = this.customerTheme[0].cancelButton;
          this.cancelButtonTextColor = this.customerTheme[0].cancelButtonTextColor;
        
          if (this.customerTheme[0].logoUrl) {
            this.logoUrl = this.customerTheme[0].logoUrl;
          } else {
             this.logoUrl = 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/10t-new.png';
          }

          if(document.getElementById('login-logo')) {
            document.getElementById('login-logo').setAttribute('src', this.logoUrl);
          }

          if(document.getElementById('p-logo-icon')) {
            document.getElementById('p-logo-icon').setAttribute('src', this.logoUrl);
          }

        } else {
          if(document.getElementById('login-logo')) {
            document.getElementById('login-logo').setAttribute('src', 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/10t-new.png');
          }

          if(document.getElementById('p-logo-icon')) {
            document.getElementById('p-logo-icon').setAttribute('src', 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/10t-new.png');
          }
          
          this.backgroundColor = '#ffffff';
          this.leftSideColor = '#ffffff';
          this.rightSideColor = '#FF9016';
          this.buttonColor = '#000000';
          this.buttonTextColor = '#ffffff';
          this.cancelButtonColor = '#004533 ';
          this.cancelButtonTextColor = '#ffffff';
          this.fontColor = '#363636';
        }

        $('.signin-inner').css('border', '1px solid rgb(116, 114, 114)');
        $('.signin-inner').css('border-radius', '15px');

        document.documentElement.style.setProperty('--p-login-background', this.backgroundColor);
        document.documentElement.style.setProperty('--p-login-leftSide', this.leftSideColor);
        document.documentElement.style.setProperty('--p-login-rightSide', this.rightSideColor);
        document.documentElement.style.setProperty('--p-login-btn', this.buttonColor);
        document.documentElement.style.setProperty('--p-login-btn-text', this.buttonTextColor);
        document.documentElement.style.setProperty('--p-login-cancel-btn', this.cancelButtonColor);
        document.documentElement.style.setProperty('--p-login-cancel-btn-text', this.cancelButtonTextColor);
        document.documentElement.style.setProperty('--p-login-font', this.fontColor);
        
      });
  }

  clickUploadIcon() {
      $('#loginLogo').click();
  }

  async onChangeIcon(event) {
    if (event.target.files.length > 0) {
      if (!(event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png')) {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File should be in JPG or PNG format.' });
        return;
      }

      if (event.target.files[0].size > (10 * this.fileSize)) {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File size should not be more than 10mb' });
        return;
      }

      this.logoUpload = event.target.files[0];
      this.logoName = event.target.files[0].name;
      event.target.value = '';
      const fr = new FileReader();
      const pfr = new FileReader();

      const formData = new FormData();
      formData.append('image', this.logoUpload);

      this.loginThemeService.uploadLogo(formData)
        .subscribe((res: any) => {
          this.logoUrl = res.url;
          fr.onload = () => {
            document.getElementById('login-logo').setAttribute('src', fr.result.toString());
          };
          fr.readAsDataURL(this.logoUpload);
          pfr.onload = () => {
            document.getElementById('p-logo-icon').setAttribute('src', pfr.result.toString());
          };
          pfr.readAsDataURL(this.logoUpload);
        }, err => {
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    }
  }

  public onEventLog(event: string, data: any, component): void {

    if (component === 'background' && (this.backgroundColor.length === 0 ||
      this.backgroundColor.length === 4 || this.backgroundColor.length === 5 || this.backgroundColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-background', this.backgroundColor);
    } else if (component === 'leftSide' && (this.leftSideColor.length === 0 ||
      this.leftSideColor.length === 4 || this.leftSideColor.length === 5 || this.leftSideColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-leftSide', this.leftSideColor);
    } else if (component === 'rightSide' && (this.rightSideColor.length === 0 ||
      this.rightSideColor.length === 4 || this.rightSideColor.length === 5 || this.rightSideColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-rightSide', this.rightSideColor);
    } else if (component === 'button' && (this.buttonColor.length === 0 ||
      this.buttonColor.length === 4 || this.buttonColor.length === 5 || this.buttonColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-btn', this.buttonColor);
    } else if (component === 'buttonText' && (this.buttonTextColor.length === 0 ||
      this.buttonTextColor.length === 4 || this.buttonTextColor.length === 5 || this.buttonTextColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-btn-text', this.buttonTextColor);
    }  else if (component === 'cancelButton' && (this.cancelButtonColor.length === 0 ||
      this.cancelButtonColor.length === 4 || this.cancelButtonColor.length === 5 || this.cancelButtonColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-cancel-btn', this.cancelButtonColor);
    } else if (component === 'cancelButtonText' && (this.cancelButtonTextColor.length === 0 ||
      this.cancelButtonTextColor.length === 4 || this.cancelButtonTextColor.length === 5 || this.cancelButtonTextColor.length === 7)) {
      document.documentElement.style.setProperty('--p-login-cancel-btn-text', this.cancelButtonTextColor);
    } else if (component === 'font' && (this.fontColor.length === 0 ||
      this.fontColor.length === 4 || this.fontColor.length === 5 || this.fontColor.length === 7)) {
      this.fontColorChangeFlg = true;
      document.documentElement.style.setProperty('--p-login-font', this.fontColor); 
    }  
  }

  onSaveLoginTheme() { 
    let subdomainRegx = /^([a-z0-9][a-z0-9-_]*\.)*[a-z0-9]*[a-z0-9-_]*[[a-z0-9]+$/gm;

    if (this.subdomain.length <= 0) {
      this.communicationService.openAlert({alertType: 'error', alertMsg: 'Subdomain is required!'});
      return;
    } else if (this.subdomain.length > 63) {
      this.communicationService.openAlert({alertType: 'error', alertMsg: 'Subdomain length must be less than 64 characters!'});
      return;
    } else if (!(subdomainRegx.test(this.subdomain))) {
      this.communicationService.openAlert({alertType: 'error', alertMsg: 'Please enter valid Subdomain!'});
      return;
    }

    const data = {
      domainName: 'my.10ttech.com', 
      background: this.backgroundColor, 
      logoUrl: this.logoUrl, 
      subdomainURL: this.subdomain + '.my.10ttech.com', 
      subdomain: this.subdomain, 
      font: this.fontColor, 
      button: this.buttonColor, 
      rightColor: this.rightSideColor, 
      leftColor: this.leftSideColor,
      buttonTextColor: this.buttonTextColor,
      cancelButton: this.cancelButtonColor,
      cancelButtonTextColor: this.cancelButtonTextColor
    }

    this.btnSpinner = true;

    this.loginThemeService.saveLoginScreenTheme(data)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Theme saved successfully!'});
      }, err => {
        this.btnSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      })

  }
}
