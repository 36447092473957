import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GovernanceReportService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getGoverenceReport(data) {
    return this.http.post(`${this.serverUrl}/governance`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getSingleUserReport(userId) {
    return this.http.post(`${this.serverUrl}/governance/${userId}`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  sendMail(data) {
    return this.http.post(`${this.serverUrl}/governance/d/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  downloadRport(data) {
    return this.http.post(`${this.serverUrl}/governance/d/report`, data, this.getHeader())
      .pipe(map(response => response));
  }
}
