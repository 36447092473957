import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleMgmtService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getRoleMeta() {
    return this.http.get(`${this.serverUrl}/rbac/meta`, this.getHeader())
      .pipe(map(response => response));
  }

  saveRole(data) {
    return this.http.post(`${this.serverUrl}/rbac/role`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getAllRoles() {
    return this.http.get(`${this.serverUrl}/rbac/roles`, this.getHeader())
      .pipe(map(response => response));
  }

  getSingleRole(roleId) {
    return this.http.get(`${this.serverUrl}/rbac/role/${roleId}`, this.getHeader())
      .pipe(map(response => response));
  }

  editRole(data, roleId) {
    return this.http.put(`${this.serverUrl}/rbac/role/${roleId}`, data, this.getHeader())
      .pipe(map(response => response));
  }

  deleteRole(roleId) {
    return this.http.delete(`${this.serverUrl}/rbac/role/${roleId}`, this.getHeader())
      .pipe(map(response => response));
  }
}
