<div class="dashboard-refresh-time-container">
    <div class="face-container-inner-bg" style="position: relative;">
		<div class="profile-manage-row result-not-found-row">
			<h4 class="result-not-found-row-heading">{{'Auto Refresh Time Setting' | translate}}</h4>
        </div>
        <div class="t-grid-table-new">
            <div class="t-grid-table-wrapper t-head-bg border-bottom-light">
                <div class="t-box c">{{'Widget Name' | translate}}</div>
                <div class="t-box c">{{'Auto Refresh Time' | translate}}</div>
            </div>
            <div class="t-body-overflow accordion user-management-tbody">
                <ng-container *ngFor="let widget of widgetRefreshTimeSetting; let i = index">
                    <div class="t-grid-table-wrapper t-body-bg">
                        <div class="t-box d t-font-light overflow-auto">{{widget.name}}</div>
                        <div class="t-box d t-font-light overflow-auto">
                            <select class="custom-selection-dropdown outline gridster-ignore-drag" [(ngModel)]="widget.time" (ngModelChange)="setWidgetRefreshTime()">
                                <option value="60000">{{'1 min'}}</option>
                                <option value="900000">{{'15 mins'}}</option>
                                <option value="3600000">{{'1 hr'}}</option>
                                <option value="manual">{{'Manual'}}</option>
                            </select>
                        </div>
                    </div>
                </ng-container>
                <!-- <div *ngIf="userList && userList.length <= 0">
                    <div class="row d-block py-3 p-2">
                        <div>
                            <div class="text-center">{{'No Users Found' | translate}}.</div>
                        </div>
                    </div>
                </div>         -->
            </div>
        </div>
    </div>
</div>