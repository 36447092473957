import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '../../shared/services/communication.service';
import { GroupMgmtService } from '../../shared/services/group-mgmt.service';
import * as papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CookieTrailService } from 'src/app/shared/services/cookie-trail.service';
import { UserService } from '../../shared/services/user.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../shared/stores/user/user.reducer';
import * as UserAction from '../../shared/stores/user/user.action';
import { environment } from '../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-group-mgmt',
  templateUrl: './group-mgmt.component.html',
  styleUrls: ['./group-mgmt.component.scss']
})
export class GroupMgmtComponent implements OnInit {

  groupList;
  groupSpinner = false;
  cutomer;
  level = 0;
  selectedIndex;
  deleteSpinner = false;
  iccidList;
  infoSpinner = false;
  currentCustomer;

  constructor(
    private communicationService: CommunicationService,
    private groupService: GroupMgmtService,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService,
    private userStore: Store<UserState>,
    private userService: UserService) { 
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {
    this.getAllGroups();
    if(localStorage.getItem('currentCustomer')) {
      this.currentCustomer = JSON.parse(localStorage.getItem('currentCustomer'));
    }

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
  }

  getAllGroups() {
    this.groupSpinner = true;
    
    this.groupService.getAllGroups()
      .subscribe((res: any) => {
        this.groupSpinner = false;
        this.groupList = res;
      }, err => {
        const cookieTrail = {
          screenName: 'Access Management - Group',
          action: 'Get All Groups',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.groupSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getSingleGroup(id) {
    this.infoSpinner = true;

    this.groupService.getSingleGroup(id)
      .subscribe((res: any) => {
        this.iccidList = res.iccidList;
        this.infoSpinner = false;
      }, err => {
        const cookieTrail = {
          screenName: 'Access Management - Group',
          action: 'Get Single Group',
          body: id,
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.infoSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  deleteGroup() {
    this.deleteSpinner = true;

    const cookieTrail = {
      screenName: 'Access Management - Group',
      action: 'Delete Single Group',
      body: this.groupList[this.selectedIndex]._id,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.groupService.deleteGroup(this.groupList[this.selectedIndex]._id)
      .subscribe((res: any) => {
        this.deleteSpinner = false;
        this.groupList.splice(this.selectedIndex, 1);
        this.getUser();
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Group deleted successfully'});
        $('#deleteGroupModal').modal('hide');
      }, err => {

        const cookieTrail = {
          screenName: 'Access Management - Group',
          action: 'Delete Single Group',
          body: this.groupList[this.selectedIndex]._id,
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.deleteSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getIccidList(index) {
    const tempList = [];
    if(!this.currentCustomer || !(this.currentCustomer && this.currentCustomer.isParent)) {
      this.iccidList.forEach((ele: any) => {
        const obj = {
          iccid: ele.iccid,
          'Profile Type': ele.profileType,
          'Parent Group': ele.parentGroupName ? ele.parentGroupName : 'NA',
          Status: ele.state
        };
        tempList.push(obj);
      });
    } else {
      this.iccidList.forEach((ele: any) => {
        const obj = {
          iccid: ele.iccid,
          'Profile Type': ele.profileType,
          Status: ele.state
        };
        tempList.push(obj);
      });
    }
    
    papa.unparse(tempList);
    const fileName = `${this.groupList[index].groupName}.txt`;
    const blob = new Blob([papa.unparse(tempList)], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
  }

  getUser() {
    this.userService.getUser()
      .subscribe((res: any) => {
        this.userStore.dispatch(new UserAction.GetUser(res));
      });
  }
}
