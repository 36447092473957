import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '../shared/services/communication.service';
import { UserState } from '../shared/stores/user/user.reducer';
import { CustomerService } from '../shared/services/customer.service';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent implements OnInit {

  isDefault = true;
  toggleDisable = false;
  serverForm: FormGroup;
  submitted = false;
  customEmailObjId;
  secure = false;
  emailSetup;
  customBtnSpinner = false;
  defaultBtnSpinner = false;
  testBtnSpinner = false;
  features;
  customerId;
  showScreen;
  fileSize = 1024 * 1024;
  emailLogo;
  emailLogoName;
  isTest = false;
  imgError = false;
  
  constructor(
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private userStore:Store<UserState>,
    private customerService: CustomerService,
    private cookieTrailService: CookieTrailService,
    private router: Router) {
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit(): void {
    this.serverForm = new FormGroup({
      outingServer: new FormControl({value: null, disabled: this.isDefault ? true : false}, [Validators.required]),
      portNo: new FormControl({value: null, disabled: this.isDefault ? true : false}, [Validators.required]),
      secure: new FormControl({value: false, disabled: this.isDefault ? true : false}, [Validators.required]),
      userName: new FormControl({value: null, disabled: this.isDefault ? true : false}, [Validators.required, Validators.email]),
      password: new FormControl({value: null, disabled: this.isDefault ? true : false}, [Validators.required]),
      aliasEmail: new FormControl({value: null, disabled: this.isDefault ? true : false}),
      sentBy: new FormControl({value: null, disabled: this.isDefault ? false : true}, [Validators.required]),
      supportEmail: new FormControl({value: null, disabled: this.isDefault ? false : true}, [Validators.required]),
      // messageBody: new FormControl({value: 'Using 10TTech default parameters for Email Configuration', disabled: this.isDefault ? true : false}, [Validators.required]),
    });

    this.getEmailServerSetup();

    this.communicationService.getRemovedCustomer()
      .subscribe((res: any) => {
        this.getEmailServerSetup();
      });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {

          if((user.data.currentCustomer && user.data.currentCustomer.isRoot) || (!user.data.currentCustomer && user.data.rootCustomer && user.data.rootCustomer.isRoot)) {
            this.showScreen = false;
            this.router.navigate(['page-not-found']);
          } else {
            this.showScreen = true;
          }

          if (user.data && user.data.currentCustomer) {
            this.customerId = user.data.currentCustomer._id;
          } else {
            this.customerId = user.data.rootCustomer._id;
          }
  
          if(user.featureRole) {
            this.features = user.featureRole;
          }
        }
        
      });
  }

  getMainFeatureIndex(name) {
    return this.features ? this.features.findIndex(ele => ele.name === name) : '';
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
  }

  changeSettingType() {
    this.isDefault = !this.isDefault;
    this.submitted = false;
    this.emailLogoName = '';
    if(document.getElementById('email-logo')) {
      document.getElementById('email-logo').setAttribute('src', '');
    }

    if(this.isDefault) {
      this.f.secure.setValue(false);
      this.serverForm.get('secure').disable();
      this.f.outingServer.setValue(null);
      this.serverForm.get('outingServer').disable();
      this.f.portNo.setValue(null);
      this.serverForm.get('portNo').disable();
      this.f.userName.setValue(null);
      this.serverForm.get('userName').disable();
      this.f.password.setValue(null);
      this.serverForm.get('password').disable();
      this.f.aliasEmail.setValue(null);
      this.serverForm.get('aliasEmail').disable();
      this.f.sentBy.setValue('10TTECH');
      this.serverForm.get('sentBy').disable();
      this.f.supportEmail.setValue('support@10ttech.com');
      this.serverForm.get('supportEmail').disable();
      // this.f.messageBody.setValue('Using 10TTech default parameters for Email Configuration');
      // this.serverForm.get('messageBody').disable();
      document.getElementById('email-logo').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
    } else {
      this.f.secure.setValue(this.emailSetup ? this.emailSetup.secure : false);
      this.serverForm.get('secure').enable();
      this.f.outingServer.setValue(this.emailSetup ? this.emailSetup.outgoingServer: null);
      this.serverForm.get('outingServer').enable();
      this.f.portNo.setValue(this.emailSetup ? this.emailSetup.port : null);
      this.serverForm.get('portNo').enable();
      this.f.userName.setValue(this.emailSetup ? this.emailSetup.username : null);
      this.serverForm.get('userName').enable();
      this.f.password.setValue(this.emailSetup ? this.emailSetup.password : null);
      this.serverForm.get('password').enable();
      this.f.aliasEmail.setValue(this.emailSetup ? this.emailSetup.alias : null);
      this.serverForm.get('aliasEmail').enable();
      this.f.sentBy.setValue(this.emailSetup && this.emailSetup.sentBy ? this.emailSetup.sentBy : '10TTECH');
      this.serverForm.get('sentBy').enable();
      this.f.supportEmail.setValue(this.emailSetup && this.emailSetup.supportEmail ? this.emailSetup.supportEmail : 'support@10ttech.com');
      this.serverForm.get('supportEmail').enable();
      // this.f.messageBody.setValue(this.emailSetup ? this.emailSetup.messageBody : null);
      // this.serverForm.get('messageBody').enable();
      if(document.getElementById('email-logo') && this.emailSetup && this.emailSetup.logo) {
        document.getElementById('email-logo').setAttribute('src', this.emailSetup.logo);
      } else {
        document.getElementById('email-logo').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
      }
    }
  }

  initiateForm() {
    this.getEmailServerSetup();
  }

  get f() { return this.serverForm.controls; }

  getEmailServerSetup() {
    if(document.getElementById('email-logo')) {
      document.getElementById('email-logo').setAttribute('src', '');
    }
    this.customerService.getEmailServerSetup()
      .subscribe((res: any) => {
        this.emailSetup = res.emailSetup;
        if(res.customer) {
          if(res.customer.customMailConfig === 'custom') {
            this.isDefault = false;
            this.f.outingServer.setValue(res.emailSetup.outgoingServer);
            this.serverForm.get('outingServer').enable();
            this.f.secure.setValue(res.emailSetup.secure);
            this.serverForm.get('secure').enable();
            this.f.portNo.setValue(res.emailSetup.port);
            this.serverForm.get('portNo').enable();
            this.f.userName.setValue(res.emailSetup.username);
            this.serverForm.get('userName').enable();
            this.f.password.setValue(res.emailSetup.password);
            this.serverForm.get('password').enable();
            this.f.aliasEmail.setValue(res.emailSetup.alias);
            this.serverForm.get('aliasEmail').enable();
            this.f.sentBy.setValue(res.emailSetup.sentBy ? res.emailSetup.sentBy : '10TTECH');
            this.serverForm.get('sentBy').enable();
            this.f.supportEmail.setValue(res.emailSetup.supportEmail ? res.emailSetup.supportEmail : 'support@10ttech.com');
            this.serverForm.get('supportEmail').enable();
            // this.f.messageBody.setValue(res.emailSetup.messageBody);
            // this.serverForm.get('messageBody').enable();
            this.customEmailObjId = res.emailSetup._id;
            if(document.getElementById('email-logo')) {
              document.getElementById('email-logo').setAttribute('src', res.emailSetup.logo);
            }
          } else if(res.customer.customMailConfig === 'default') {
            this.isDefault = true;
            this.f.secure.setValue(false);
            this.serverForm.get('secure').disable();
            this.f.outingServer.setValue('');
            this.serverForm.get('outingServer').disable();
            this.f.portNo.setValue('');
            this.serverForm.get('portNo').disable();
            this.f.userName.setValue('');
            this.serverForm.get('userName').disable();
            this.f.password.setValue('');
            this.serverForm.get('password').disable();
            this.f.aliasEmail.setValue('');
            this.serverForm.get('aliasEmail').disable();
            this.f.sentBy.setValue('10TTECH');
            this.serverForm.get('sentBy').disable();
            // this.f.messageBody.setValue('Using 10TTech default parameters for Email Configuration');
            // this.serverForm.get('messageBody').disable();
            if(document.getElementById('email-logo')) {
              document.getElementById('email-logo').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
            }
            this.customEmailObjId = res.emailSetup._id;
          }
        } else if(res.customMailConfig === 'default') {
            this.isDefault = true;
            this.f.secure.setValue(false);
            this.serverForm.get('secure').disable();
            this.f.outingServer.setValue('');
            this.serverForm.get('outingServer').disable();
            this.f.portNo.setValue('');
            this.serverForm.get('portNo').disable();
            this.f.userName.setValue('');
            this.serverForm.get('userName').disable();
            this.f.password.setValue('');
            this.serverForm.get('password').disable();
            this.f.aliasEmail.setValue('');
            this.serverForm.get('aliasEmail').disable();
            this.f.sentBy.setValue('10TTECH');
            this.serverForm.get('sentBy').disable();
            this.f.supportEmail.setValue('support@10ttech.com');
            this.serverForm.get('supportEmail').disable();
            // this.f.messageBody.setValue('Using 10TTech default parameters for Email Configuration');
            // this.serverForm.get('messageBody').disable();
            if(document.getElementById('email-logo')) {
              document.getElementById('email-logo').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
            }
        }
      }, err => {
        const cookieTrail = {
          screenName: 'Customer Management',
          action: 'Get Email Setup',
          body: this.customerId,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  customServerSetup() {
    
    this.submitted = true;
    this.isTest = false;
    if (this.serverForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('file', this.emailLogo);
    formData.append('customMailConfig', 'custom');
    formData.append('port', this.serverForm.get('portNo').value,);
    formData.append('outgoingServer', this.serverForm.get('outingServer').value,);
    formData.append('username', this.serverForm.get('userName').value,);
    formData.append('password', this.serverForm.get('password').value,);
    formData.append('alias', this.serverForm.get('aliasEmail').value,);
    formData.append('customEmailObjId', this.customEmailObjId ? this.customEmailObjId : '');
    formData.append('secure', this.serverForm.get('secure').value,);
    formData.append('sentBy', this.serverForm.get('sentBy').value,);
    formData.append('supportEmail', this.serverForm.get('supportEmail').value,);

    // const data = { 
    //   customMailConfig: 'custom',
      // outgoingServer: this.serverForm.get('outingServer').value,
      // port: this.serverForm.get('portNo').value,
      // username: this.serverForm.get('userName').value,
      // password: this.serverForm.get('password').value,
      // alias: this.serverForm.get('aliasEmail').value,
      // customEmailObjId: this.customEmailObjId ? this.customEmailObjId : '',
      // secure: this.serverForm.get('secure').value,
      // sentBy: this.serverForm.get('sentBy').value,
      // supportEmail: this.serverForm.get('supportEmail').value,
      // messageBody: this.serverForm.get('messageBody').value,
    // };

    const cookieTrail = {
      screenName: 'Customer Management',
      action: 'Set Custom Email Setup',
      body: {formData, customerId: this.customerId}
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.customBtnSpinner = true;

    this.customerService.setEmailServerSetup(formData)
     .subscribe((res: any) => {
      this.communicationService.openAlert({alertType: 'success', alertMsg: 'Email setup saved successfully!'});
      this.customBtnSpinner = false;
      this.emailLogo = '';
      this.emailLogoName = '';
     }, err => {
      this.customBtnSpinner = false;
      this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
     });
  }

  defaultServerSetup() {

    const data = {
      customMailConfig: 'default'
    };

    const cookieTrail = {
      screenName: 'Customer Management',
      action: 'Set Default Email Setup',
      body: {data, customerId: this.customerId}
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.defaultBtnSpinner = true;

    this.customerService.setEmailServerSetup(data) 
      .subscribe((res: any) => {
        this.defaultBtnSpinner = false;
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Email setup saved successfully!'});
      }, err => {
        this.defaultBtnSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  testEmailServerSetup() {
    console.log('called');
    this.isTest = true;
    this.submitted = true;
    if (this.serverForm.controls.outingServer.status === "INVALID" || this.serverForm.controls.password.status === "INVALID"
     || this.serverForm.controls.portNo.status === "INVALID" || this.serverForm.controls.userName.status === "INVALID") {
      console.log(this.serverForm);
      this.isTest = true;
      return;
    }

    const data = { 
      customMailConfig: 'custom',
      outgoingServer: this.serverForm.get('outingServer').value,
      port: this.serverForm.get('portNo').value,
      username: this.serverForm.get('userName').value,
      password: this.serverForm.get('password').value,
      alias: this.serverForm.get('aliasEmail').value,
      customEmailObjId: this.customEmailObjId ? this.customEmailObjId : '',
      secure: this.serverForm.get('secure').value,
      sentBy: this.serverForm.get('sentBy').value,
      supportEmail: this.serverForm.get('supportEmail').value,
      // messageBody: this.serverForm.get('messageBody').value,
    };
    
    console.log(data);

    this.testBtnSpinner = true;
    this.customerService.testEmailServerSetup(data) 
      .subscribe((res: any) => {
        this.testBtnSpinner = false;
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Tested Successfully!'});
      }, err => {
        this.testBtnSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  clickUploadIcon() {
      $('#emailLogo').click();
  }

  async onChangeIcon(event, type) {
    if (event.target.files.length > 0) {
      if (!(event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png')) {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File should be in JPG or PNG format.' });
        event.target.value = '';
        return;
      }

      this.imgError = false;
      let self = this;
     
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {

        //Initiate the JavaScript Image object.
        var image: any = new Image();
      
        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
      
        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          if (height > 300) {
            self.communicationService.openAlert({ alertType: 'error', alertMsg: 'Height must not exceed 300px.' });
            event.target.value = '';
            self.imgError = true;
            return;
          }
        }
      }

      setTimeout(() => {
        console.log(this.imgError);
        if(this.imgError) {
          return;
        }
        if (event.target.files[0].size > (10 * this.fileSize)) {
          this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File size should not be more than 10mb' });
          event.target.value = '';
          return;
        }
  
        this.emailLogo = event.target.files[0];
        this.emailLogoName = event.target.files[0].name;
        const fr = new FileReader();
        const pfr = new FileReader();
        event.target.value = '';
  
        console.log(this.emailLogo);
  
        fr.onload = () => {
          document.getElementById('email-logo').setAttribute('src', fr.result.toString());
        };
        fr.readAsDataURL(this.emailLogo);
      }, 100);
    }
  }

}
