import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Cmyk } from 'ngx-color-picker';
import { UiCustomizationService } from '../shared/services/ui-customization.service';
import { Store } from '@ngrx/store';
import { ThemeState } from '../shared/stores/theme/theme.reducer';
import * as ThemeAction from '../shared/stores/theme/theme.action';
import { LogoState } from '../shared/stores/logo/logo.reducer';
import { CommunicationService } from '../shared/services/communication.service';
import { ComponentCanDeactivate } from '../shared/services/component-can-deactivate';
import { CookieTrailService } from '../shared/services/cookie-trail.service';

declare var $: any;
@Component({
  selector: 'app-ui-customization',
  templateUrl: './ui-customization.component.html',
  styleUrls: ['./ui-customization.component.scss']
})
export class UiCustomizationComponent extends ComponentCanDeactivate implements OnInit {

  sideBarColor;
  backgroundColor;
  fontColor;
  buttonColor;

  originalSidebarColor;
  originalBackgroundColor;
  originalFontColor;
  originalButtonColor;

  screenMode;
  fileType;

  largeIconName;
  smallIconName;

  largeFileUpload;
  smallFileUpload;

  cmykValue = '';
  cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  fileSize = 1024 * 1024;
  btnSpinner = false;
  confirmationVar;
  logos;
  previousThemeFlag = false;
  isDefault = false;
  toggleDisable = false;

  constructor(
    public vcRef: ViewContainerRef,
    private themeService: UiCustomizationService,
    private themeStore: Store<ThemeState>,
    private logoStore: Store<LogoState>,
    private communicationService: CommunicationService,
    private cookieTrailService: CookieTrailService) {
    super();
  }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});
    this.screenMode = localStorage.getItem('screenMode');

    this.communicationService.getScreenMode()
      .subscribe((res: any) => {
        this.largeFileUpload = undefined;
        this.smallFileUpload = undefined;
        this.largeIconName = '';
        this.smallIconName = '';

        $('#largeAppIcon').val('');
        $('#smallAppIcon').val('');

        if (res === 'dark' && $('#switcher-1')[0]) {
          $('#switcher-1')[0].checked = false;
        } else if (res === 'light' && $('#switcher-1')[0]) {
          $('#switcher-1')[0].checked = true;
        }
      });

    this.logoStore.select('logo')
      .subscribe((res1: any) => {
        this.logoStore = res1;
        if (localStorage.getItem('screenMode') === 'dark') {
          if (res1 && !res1.message && res1.find(ele => (ele.mode === 'dark' && ele.type === 'large'))) {
            if(document.getElementById('large-app-icon')) {
              document.getElementById('large-app-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'dark' && ele.type === 'large')).data);
            }

            if(document.getElementById('p-large-icon')) {
              document.getElementById('p-large-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'dark' && ele.type === 'large')).data);
            }
          } else {
            if(document.getElementById('large-app-icon')) {
              document.getElementById('large-app-icon').setAttribute('src', 'assets/images/logo/large-dark-logo-new.png');
            }

            if(document.getElementById('p-large-icon')) {
              document.getElementById('p-large-icon').setAttribute('src', 'assets/images/logo/large-dark-logo-new.png');
            }
          }

          if (res1 && !res1.message && res1.find(ele => (ele.mode === 'dark' && ele.type === 'small'))) {
            if(document.getElementById('small-app-icon')) {
              document.getElementById('small-app-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'dark' && ele.type === 'small')).data);
            }

            if(document.getElementById('p-small-icon')) {
              document.getElementById('p-small-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'dark' && ele.type === 'small')).data);
            }
          } else {
            if(document.getElementById('p-small-icon')) {
              document.getElementById('p-small-icon').setAttribute('src', 'assets/images/logo/small-dark-logo-new.png');
            }

            if(document.getElementById('small-app-icon')) {
              document.getElementById('small-app-icon').setAttribute('src', 'assets/images/logo/small-dark-logo-new.png');
            }
          }
        }

        if (localStorage.getItem('screenMode') === 'light') {
          if (res1 && !res1.message && res1.find(ele => (ele.mode === 'light' && ele.type === 'large'))) {
            if(document.getElementById('large-app-icon')) {
              document.getElementById('large-app-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'light' && ele.type === 'large')).data);
            }

            if(document.getElementById('p-large-icon')){
              document.getElementById('p-large-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'light' && ele.type === 'large')).data);
            }
          } else {
            if (document.getElementById('large-app-icon')) {
              document.getElementById('large-app-icon').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
            }

            if(document.getElementById('p-large-icon')) {
              document.getElementById('p-large-icon').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
            }
          }

          if (res1 && !res1.message && res1.find(ele => (ele.mode === 'light' && ele.type === 'small'))) {
            if(document.getElementById('small-app-icon')) {
              document.getElementById('small-app-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'light' && ele.type === 'small')).data);
            }

            if(document.getElementById('p-small-icon')) {
              document.getElementById('p-small-icon')
                .setAttribute('src', 'data:image/png;base64,' + res1.find(ele => (ele.mode === 'light' && ele.type === 'small')).data);
            }
          } else {
            if(document.getElementById('small-app-icon')) {
              document.getElementById('small-app-icon').setAttribute('src', 'assets/images/logo/small-light-logo-new.png');
            }

            if(document.getElementById('p-small-icon')) {
              document.getElementById('p-small-icon').setAttribute('src', 'assets/images/logo/small-light-logo-new.png');
            }
          }
        }
      });


    this.themeStore.select('theme')
      .subscribe((res: any) => {
        if (res) {

          this.sideBarColor = res.sidebar;
          this.backgroundColor = res.background;
          this.fontColor = res.font;
          this.buttonColor = res.button;

          this.originalSidebarColor = res.sidebar;
          this.originalBackgroundColor = res.background;
          this.originalFontColor = res.font;
          this.originalButtonColor = res.button;

          document.documentElement.style.setProperty('--bg-sidebar', res.sidebar);
          document.documentElement.style.setProperty('--bg-inner-app', res.background);
          document.documentElement.style.setProperty('--t-font-color', res.font);
          document.documentElement.style.setProperty('--t-btn-color', res.button);

          document.documentElement.style.setProperty('--p-face-container-bg', res.sidebar);
          document.documentElement.style.setProperty('--p-backgroud', res.background);
          document.documentElement.style.setProperty('--p-font', res.font);
          document.documentElement.style.setProperty('--p-btn', res.button);
        } else {
          if (this.screenMode === 'dark') {
            this.sideBarColor = '#000000';
            this.backgroundColor = '#1A1A1A';
            this.fontColor = '#FF9016';
            this.buttonColor = '#FF9016';
          } else if (this.screenMode === 'light') {
            this.sideBarColor = '#F0F0F0';
            this.backgroundColor = '#FFFFFF';
            this.fontColor = '#FF9016';
            this.buttonColor = '#FF9016';
          }

          this.originalSidebarColor = this.sideBarColor;
          this.originalBackgroundColor = this.backgroundColor;
          this.originalFontColor = this.fontColor;
          this.originalButtonColor = this.buttonColor;

          document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
          document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
          document.documentElement.style.setProperty('--t-font-color', this.fontColor);
          document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);

          document.documentElement.style.setProperty('--p-face-container-bg', this.sideBarColor);
          document.documentElement.style.setProperty('--p-backgroud', this.backgroundColor);
          document.documentElement.style.setProperty('--p-font', this.fontColor);
          document.documentElement.style.setProperty('--p-btn', this.buttonColor);

          // console.log(document.documentElement.style.getPropertyValue('--t-font-color'));
        }
      });
  }

  canDeactivate(): boolean {
    if (this.largeIconName || this.smallIconName ||
      (this.originalSidebarColor !== this.sideBarColor) ||
      (this.originalBackgroundColor !== this.backgroundColor) ||
      (this.originalFontColor !== this.fontColor) ||
      (this.originalButtonColor !== this.buttonColor)) {
      // $('#deactivateRouteConfirmation').modal();

      return false;
    } else {
      return true;
    }
  }

  clickUploadIcon(type) {
    if (type === 'large') {
      $('#largeAppIcon').click();
    }

    if (type === 'small') {
      $('#smallAppIcon').click();
    }
  }

  async onChangeIcon(event, type) {
    if (event.target.files.length > 0) {
      if (!(event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png')) {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File should be in JPG or PNG format.' });
        return;
      }

      if (event.target.files[0].size > (10 * this.fileSize)) {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File size should not be more than 10mb' });
        return;
      }

      if (type === 'large') {
        this.largeFileUpload = event.target.files[0];
        this.largeIconName = event.target.files[0].name;
        const fr = new FileReader();
        const pfr = new FileReader();

        fr.onload = () => {
          document.getElementById('large-app-icon').setAttribute('src', fr.result.toString());
        };
        fr.readAsDataURL(this.largeFileUpload);
        pfr.onload = () => {
          document.getElementById('p-large-icon').setAttribute('src', pfr.result.toString());
        };
        pfr.readAsDataURL(this.largeFileUpload);
      }

      if (type === 'small') {
        this.smallFileUpload = event.target.files[0];
        this.smallIconName = event.target.files[0].name;
        const fr = new FileReader();
        const pfr = new FileReader();
        fr.onload = () => {
          document.getElementById('small-app-icon').setAttribute('src', fr.result.toString());
        };
        fr.readAsDataURL(this.smallFileUpload);

        pfr.onload = () => {
          document.getElementById('p-small-icon').setAttribute('src', pfr.result.toString());
        };
        pfr.readAsDataURL(this.smallFileUpload);
      }
    }
  }

  public onEventLog(event: string, data: any, component): void {
    if (component === 'sidebar' && (this.sideBarColor.length === 0 ||
      this.sideBarColor.length === 4 || this.sideBarColor.length === 5 || this.sideBarColor.length === 7)) {
      document.documentElement.style.setProperty('--p-face-container-bg', this.sideBarColor);
    } else if (component === 'background' && (this.backgroundColor.length === 0 ||
      this.backgroundColor.length === 4 || this.backgroundColor.length === 5 || this.backgroundColor.length === 7)) {
      document.documentElement.style.setProperty('--p-backgroud', this.backgroundColor);
    } else if (component === 'font' && (this.fontColor.length === 0 ||
      this.fontColor.length === 4 || this.fontColor.length === 5 || this.fontColor.length === 7)) {
      document.documentElement.style.setProperty('--p-font', this.fontColor);
    } else if (component === 'button' && (this.buttonColor.length === 0 ||
      this.buttonColor.length === 4 || this.buttonColor.length === 5 || this.buttonColor.length === 7)) {
      document.documentElement.style.setProperty('--p-btn', this.buttonColor);
    }
  }

  changeScreenMode() {
    if ($('#switcher-1')[0].checked) {
      localStorage.setItem('screenMode', 'light');
      $('#mainContainer').addClass('light-content');
      $('#mainContainer').removeClass('dark-content');
      this.screenMode = localStorage.getItem('screenMode');
      this.isDefault = true;
    } else {
      localStorage.setItem('screenMode', 'dark');
      $('#mainContainer').removeClass('light-content');
      $('#mainContainer').addClass('dark-content');
      this.screenMode = localStorage.getItem('screenMode');
      this.isDefault = false;
    }
  }

  onSave() {
    this.btnSpinner = true;
    const themeData = {
      mode: localStorage.getItem('screenMode'),
      sidebar: this.sideBarColor,
      background: this.backgroundColor,
      font: this.fontColor,
      button: this.buttonColor
    };

    const cookieTrail = {
      screenName: 'Edit Theme',
      action: 'Save Theme',
      body: themeData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.themeService.saveTheme(themeData)
      .subscribe((res: any) => {
        this.sideBarColor = res.sidebar;
        this.backgroundColor = res.background;
        this.fontColor = res.font;
        this.buttonColor = res.button;
        this.btnSpinner = false;
        $('#confirmationAlert').modal('hide');

        document.documentElement.style.setProperty('--bg-sidebar', res.sidebar);
        document.documentElement.style.setProperty('--bg-inner-app', res.background);
        document.documentElement.style.setProperty('--t-font-color', res.font);
        document.documentElement.style.setProperty('--t-btn-color', res.button);

        document.documentElement.style.setProperty('--p-face-container-bg', res.sidebar);
        document.documentElement.style.setProperty('--p-backgroud', res.background);
        document.documentElement.style.setProperty('--p-font', res.font);
        document.documentElement.style.setProperty('--p-btn', res.button);

        this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'Theme saved successfully!' });
      }, err => {
        const cookieTrail = {
          screenName: 'Edit Theme',
          action: 'Save Theme',
          body: themeData,
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    if (this.largeFileUpload) {
      const formData = new FormData();
      formData.append('file', this.largeFileUpload);

      const cookieTrail = {
        screenName: 'Edit Theme',
        action: 'File Upload',
        body: {
          logoSize: 'large',
          screenMode: localStorage.getItem('screenMode')
        },
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);

      this.themeService.saveLogo(localStorage.getItem('screenMode'), 'large', formData)
        .subscribe((res: any) => {
          this.largeFileUpload = undefined;
          this.largeIconName = '';

          this.communicationService.sendLogo('logo');

          $('#largeAppIcon').val('');
        }, err => {
          const cookieTrail = {
            screenName: 'Edit Theme',
            action: 'File Upload',
            body: {
              logoSize: 'large',
              screenMode: localStorage.getItem('screenMode')
            },
            error: err.error || 'Something went wrong!'
          };
          
          this.cookieTrailService.saveCookie(cookieTrail);
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }

    if (this.smallFileUpload) {
      const formData = new FormData();
      formData.append('file', this.smallFileUpload);

      const cookieTrail = {
        screenName: 'Edit Theme',
        action: 'File Upload',
        body: {
          logoSize: 'small',
          screenMode: localStorage.getItem('screenMode')
        },
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);

      this.themeService.saveLogo(localStorage.getItem('screenMode'), 'small', formData)
        .subscribe((res: any) => {

          this.communicationService.sendLogo('logo');
          this.smallFileUpload = undefined;
          this.smallIconName = '';

          $('#largeAppIcon').val('');
        }, err => {
          const cookieTrail = {
            screenName: 'Edit Theme',
            action: 'File Upload',
            body: {
              logoSize: 'small',
              screenMode: localStorage.getItem('screenMode')
            },
            error: err.error || 'Something went wrong!'
          };
          
          this.cookieTrailService.saveCookie(cookieTrail);

          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }
  }

  getTheme() {

    this.communicationService.sendScreenMode(localStorage.getItem('screenMode'));

    this.themeService.getTheme(localStorage.getItem('screenMode'))
      .subscribe((res: any) => {

        if (res.message === 'Theme not found!' || res.message === 'No previous theme found!') {

          if (localStorage.getItem('screenMode') === 'dark') {
            this.sideBarColor = '#000000';
            this.backgroundColor = '#1A1A1A';
            this.fontColor = '#FF9016';
            this.buttonColor = '#FF9016';
          } else if (localStorage.getItem('screenMode') === 'light') {
            this.sideBarColor = '#F0F0F0';
            this.backgroundColor = '#FFFFFF';
            this.fontColor = '#FF9016';
            this.buttonColor = '#FF9016';
          }

          document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
          document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
          document.documentElement.style.setProperty('--t-font-color', this.fontColor);
          document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);

          document.documentElement.style.setProperty('--p-face-container-bg', this.sideBarColor);
          document.documentElement.style.setProperty('--p-backgroud', this.backgroundColor);
          document.documentElement.style.setProperty('--p-font', this.fontColor);
          document.documentElement.style.setProperty('--p-btn', this.buttonColor);

          const themeData = {
            mode: localStorage.getItem('screenMode'),
            sidebar: this.sideBarColor,
            background: this.backgroundColor,
            font: this.fontColor,
            button: this.buttonColor
          };
          this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
        } else {
          document.documentElement.style.setProperty('--bg-sidebar', res.sidebar);
          document.documentElement.style.setProperty('--bg-inner-app', res.background);
          document.documentElement.style.setProperty('--t-font-color', res.font);
          document.documentElement.style.setProperty('--t-btn-color', res.button);

          document.documentElement.style.setProperty('--p-face-container-bg', res.sidebar);
          document.documentElement.style.setProperty('--p-backgroud', res.background);
          document.documentElement.style.setProperty('--p-font', res.font);
          document.documentElement.style.setProperty('--p-btn', res.button);

          const themeData = {
            mode: localStorage.getItem('screenMode'),
            sidebar: res.sidebar,
            background: res.background,
            font: res.font,
            button: res.button
          };
          this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
        }
      }, err => {
        const cookieTrail = {
          screenName: 'Edit Theme',
          action: 'Get Theme',
          body: {
            screenMode: localStorage.getItem('screenMode')
          },
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  onReset() {
    this.themeStore.select('theme')
      .subscribe((res: any) => {

        if (!this.previousThemeFlag && ((this.sideBarColor !== res.sidebar) || (this.backgroundColor !== res.background) ||
          (this.fontColor !== res.font) || (this.buttonColor !== res.button) || (this.largeFileUpload !== undefined)
          || (this.smallFileUpload !== undefined))) {

          this.largeFileUpload = undefined;
          this.smallFileUpload = undefined;
          this.largeIconName = '';
          this.smallIconName = '';

          this.communicationService.sendLogo('logo');
          if (res) {
            this.sideBarColor = res.sidebar;
            this.backgroundColor = res.background;
            this.fontColor = res.font;
            this.buttonColor = res.button;

            document.documentElement.style.setProperty('--bg-sidebar', res.sidebar);
            document.documentElement.style.setProperty('--bg-inner-app', res.background);
            document.documentElement.style.setProperty('--t-font-color', res.font);
            document.documentElement.style.setProperty('--t-btn-color', res.button);

            document.documentElement.style.setProperty('--p-face-container-bg', res.sidebar);
            document.documentElement.style.setProperty('--p-backgroud', res.background);
            document.documentElement.style.setProperty('--p-font', res.font);
            document.documentElement.style.setProperty('--p-btn', res.button);
          } else {
            document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
            document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
            document.documentElement.style.setProperty('--t-font-color', this.fontColor);
            document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);

            document.documentElement.style.setProperty('--p-face-container-bg', this.sideBarColor);
            document.documentElement.style.setProperty('--p-backgroud', this.backgroundColor);
            document.documentElement.style.setProperty('--p-font', this.fontColor);
            document.documentElement.style.setProperty('--p-btn', this.buttonColor);
          }
          this.btnSpinner = false;
          $('#confirmationAlert').modal('hide');
        } else if (!this.previousThemeFlag) {
          this.confirmationVar = 'resetToPrevious';
          $('#confirmationAlert').modal();
        }
      });
  }

  resetToPreviousTheme() {
    this.btnSpinner = true;
    const cookieTrail = {
      screenName: 'Edit Theme',
      action: 'Reset To Previous Theme',
      body: {
        screenMode: localStorage.getItem('screenMode')
      },
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.themeService.resetToPreviousTheme(localStorage.getItem('screenMode'))
      .subscribe((res: any) => {
        this.previousThemeFlag = true;
        this.getTheme();
        this.communicationService.sendLogo('logo');
        $('#confirmationAlert').modal('hide');
        this.btnSpinner = false;
      }, err => {
        const cookieTrail = {
          screenName: 'Edit Theme',
          action: 'Reset To Previous Theme',
          body: {
            screenMode: localStorage.getItem('screenMode')
          },
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  factoryReset() {
    this.btnSpinner = true;

    const cookieTrail = {
      screenName: 'Edit Theme',
      action: 'Factory Reset',
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.themeService.factoryResetTheme()
      .subscribe((res: any) => {
        this.btnSpinner = false;

        if (localStorage.getItem('screenMode') === 'dark') {
          document.getElementById('dark-large-app-icon').setAttribute('src', 'assets/images/logo/large-dark-logo-new.png');
          document.getElementById('dark-small-app-icon').setAttribute('src', 'assets/images/logo/small-dark-logo-new.png');
          document.getElementById('large-app-icon').setAttribute('src', 'assets/images/logo/large-dark-logo-new.png');
          document.getElementById('small-app-icon').setAttribute('src', 'assets/images/logo/small-dark-logo-new.png');

          document.getElementById('p-large-icon').setAttribute('src', 'assets/images/logo/large-dark-logo-new.png');
          document.getElementById('p-small-icon').setAttribute('src', 'assets/images/logo/small-dark-logo-new.png');
        }

        if (localStorage.getItem('screenMode') === 'light') {
          document.getElementById('light-large-app-icon').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
          document.getElementById('light-small-app-icon').setAttribute('src', 'assets/images/logo/small-light-logo-new.png');
          document.getElementById('large-app-icon').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
          document.getElementById('small-app-icon').setAttribute('src', 'assets/images/logo/small-light-logo-new.png');

          document.getElementById('p-large-icon').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
          document.getElementById('p-small-icon').setAttribute('src', 'assets/images/logo/small-light-logo-new.png');
        }

        $('#confirmationAlert').modal('hide');
        if (this.screenMode === 'dark') {
          this.sideBarColor = '#000000';
          this.backgroundColor = '#1A1A1A';
          this.fontColor = '#FF9016';
          this.buttonColor = '#FF9016';

          document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
          document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
          document.documentElement.style.setProperty('--t-font-color', this.fontColor);
          document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);

          document.documentElement.style.setProperty('--p-face-container-bg', this.sideBarColor);
          document.documentElement.style.setProperty('--p-backgroud', this.backgroundColor);
          document.documentElement.style.setProperty('--p-font', this.fontColor);
          document.documentElement.style.setProperty('--p-btn', this.buttonColor);
        } else if (this.screenMode === 'light') {
          this.sideBarColor = '#F0F0F0';
          this.backgroundColor = '#FFFFFF';
          this.fontColor = '#FF9016';
          this.buttonColor = '#FF9016';

          document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
          document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
          document.documentElement.style.setProperty('--t-font-color', this.fontColor);
          document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);

          document.documentElement.style.setProperty('--p-face-container-bg', this.sideBarColor);
          document.documentElement.style.setProperty('--p-backgroud', this.backgroundColor);
          document.documentElement.style.setProperty('--p-font', this.fontColor);
          document.documentElement.style.setProperty('--p-btn', this.buttonColor);
        }

        const themeData = {
          mode: localStorage.getItem('screenMode'),
          sidebar: this.sideBarColor,
          background: this.backgroundColor,
          font: this.fontColor,
          button: this.buttonColor
        };
        this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
        this.communicationService.sendLogo('logo');
      }, err => {
        const cookieTrail = {
          screenName: 'Edit Theme',
          action: 'Factory Reset',
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  previewToggle() {
    $('#review-nav').toggleClass('preview-screen-sidebar-toggle');
  }
}
