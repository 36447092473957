import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-smartjac-signin',
  templateUrl: './smartjac-signin.component.html',
  styleUrls: ['./smartjac-signin.component.scss']
})
export class SmartjacSigninComponent implements OnInit {
  signinForm: FormGroup;
  submitted = false;
  err = false;
  langSelected = 'en';
  constructor(private authService: AuthService,
    private router: Router,
    private translate: TranslateService) { }

  ngOnInit(): void {
    if (localStorage.getItem('authToken')) {
      this.router.navigate(['/dashboard']);
      return;
    }

    if(localStorage.getItem('language')) {
      this.langSelected = localStorage.getItem('language')
    } else {
      this.langSelected = 'en';
    }

    this.signinForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  get f() { return this.signinForm.controls; }

  onSignin() {
    this.submitted = true;
    if (this.signinForm.invalid) {
      return;
    }

    const userData = {
      email: this.signinForm.get('email').value,
      password: this.signinForm.get('password').value
    };

    this.authService.signin(userData);
  }

  changeLanguage() {
    localStorage.setItem('language', this.langSelected);
    this.translate.use(this.langSelected);
  }
}
