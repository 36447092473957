<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="row">
                <div class="col-lg-7">
                    <div class="mb-3">
                        <div class="my-account-section face-container-inner-bg">
                            <form [formGroup]="userInfoForm" (ngSubmit)="updateUser()">
                                <div class="border-bottom p-3 d-flex align-items-center">
                                    <div class="t-title">{{'My Account' | translate}}</div>
                                </div>
                                <div class="form-section p-3 mt-3">
                                    <tbody class="loader-flex-center" *ngIf="!user">
                                        <div class="loader quantum-spinner m-auto"></div>
                                    </tbody>
                                    <div class="form-row" *ngIf="user">
                                        <div class="col-lg-5 my-acc-field">
                                            <label for="" class="t-font-light t-font-medium my-acc-label">{{'First Name' | translate}}</label>
                                            <div class="flex-grow-1 error-parent">
                                                <input type="text" formControlName="firstName" (keypress)="isSpace($event, 'fName')" class="t-input p-1 form-control">
                                                <span class="help-block ng-invalid text-danger" *ngIf="(!f1.firstName.valid && !f1.firstName.pristine) || (f1.firstName.errors && userFormSubmitted)">
                                                    <span class="small" *ngIf="f1.firstName.errors.required">
                                                        {{ 'First Name is required' | translate}}!
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 my-acc-field ml-5">
                                            <label for="" class="t-font-light t-font-medium my-acc-label">{{'Last Name' | translate}}</label>
                                            <div class="flex-grow-1 error-parent">
                                                <input type="text" formControlName="lastName" (keypress)="isSpace($event, 'lName')" class="t-input p-1 form-control">
                                                <span class="help-block ng-invalid text-danger" *ngIf="(!f1.lastName.valid && !f1.lastName.pristine) || (f1.lastName.errors && userFormSubmitted)">
                                                    <span class="small" *ngIf="f1.lastName.errors.required">
                                                        {{ 'Last Name is required' | translate}}!
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 my-acc-field mt-5">
                                            <label for="" class="t-font-light t-font-medium my-acc-label word-wrap">{{'Email Address' | translate}}</label>
                                            <input type="text" formControlName="email" class="t-input p-1 form-control">
                                        </div>
                                        <div class="col-lg-5 mt-5 ml-5">
                                            <label for="" class="t-font-light t-font-medium my-acc-label">{{'Language' | translate}}</label>
                                            <div class="lang-input">
                                                <mat-form-field appearance="outline" style="width: 100%; position: relative; z-index: 1000;">
                                                    <mat-select id="customerPlan" class="positioning" formControlName="language">
                                                        <mat-option *ngFor="let lang of languageList" [value]="lang.key">{{lang.name | titlecase}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>    
                                            </div>
                                        </div>
                                        <div class="col-lg-5 my-acc-field mt-4">
                                            <label for="" class="t-font-light t-font-medium my-acc-label word-wrap">{{'Phone Number' | translate}}</label>
                                            <div class="flex-grow-1 error-parent">
                                                <ngx-intl-tel-input [cssClass]="'custom'" [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [maxLength]="10" [tooltipField]="TooltipLabel.Name"
                                                    [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" formControlName="phone" class="form-control t-input pl-0 pb-0 pr-0" [searchCountryPlaceholder]="'Search Country' | translate" id="phone">
                                                </ngx-intl-tel-input>
                                                <span class="help-block ng-invalid text-danger" *ngIf="((!f1.phone.valid && !f1.phone.pristine) || (f1.phone.errors)) && userFormSubmitted">
                                                    <span class="small" *ngIf="f1.phone.errors.required">
                                                        {{ 'Phone Number is required' | translate}}!
                                                    </span>
                                                    <span class="small" *ngIf="!f1.phone.errors.required && !f1.phone.errors.validatePhoneNumber.valid">
                                                        {{ 'Please enter valid Phone Number' | translate}}!
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-4" style="margin-top:14px;padding-left: 1rem !important;">
                                    <button class="btn py-1 px-3 t-bgcolor outline" type="submit" *ngIf="!btnSpinner">{{'Save Changes' | translate}}</button>
                                    <div class="btn-spinner" *ngIf="btnSpinner">
                                        <div class="loader quantum-spinner"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="mb-3">
                        <div class="my-account-section face-container-inner-bg">
                            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                                <div class="border-bottom p-3 d-flex align-items-center">
                                    <div class="t-title">{{'Change Password' | translate}} </div>
                                </div>
                                <div class="form-section p-3 mt-3">
                                    <div class="form-row">
                                        <div class="col-md-12">
                                            <div class="form-group text-left mb-5">
                                                <label class="t-font-light t-font-medium" for="fName">{{'Current Password' | translate}}</label>
                                                <input type="password" class="form-control t-input" id="currentPassword" formControlName="currentPassword" aria-describedby="emailHelp">
                                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.currentPassword.valid && !f.currentPassword.pristine) || (f.currentPassword.errors && submitted)">
                                                    <span class="small" *ngIf="f.currentPassword.errors.required">
                                                        {{'Current Password is required' | translate}}!
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group text-left mb-5">
                                                <label for="exampleInputEmail1" class="t-font-light t-font-medium">{{'New Password' | translate}}</label>
                                                <input type="password" class="form-control t-input" formControlName="password">
                                                <div>
                                                    <span class="help-block ng-invalid text-danger" *ngIf="(!f.password.valid && !f.password.pristine) || (f.password.errors && submitted)">
                                                    <span class="small" *ngIf="f.password.errors.required">
                                                        {{'New Password is required' | translate}}!
                                                    </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group text-left mb-4">
                                                <label for="exampleInputEmail1" class="t-font-light t-font-medium">{{'Confirm New Password' | translate}}</label>
                                                <input type="password" class="form-control  t-input" formControlName="confirmPassword">
                                                <span class="help-block ng-invalid text-danger mb-0" *ngIf="(!f.confirmPassword.valid && !f.confirmPassword.pristine) || (f.confirmPassword.errors && submitted)">
                                                    <span class="small" *ngIf="f.confirmPassword.errors.required">
                                                        {{'Confirm New Password is required' | translate}}!
                                                    </span>
                                                <span class="small" *ngIf="f.confirmPassword.errors.mustMatch">
                                                        {{'Passwords must match' | translate}}
                                                </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="p-2 mt-4">
                                            <button class="btn py-1 px-3 t-bgcolor outline" *ngIf="!changePasswordSpinner"> {{'Save Changes' | translate}}</button>
                                            <div class="btn-spinner" *ngIf="changePasswordSpinner">
                                                <div class="loader quantum-spinner m-auto"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>