import { Component, ElementRef, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import * as _moment from 'moment';
import { CompactType, DisplayGrid, Draggable, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType, PushDirections, Resizable } from 'angular-gridster2';
import { DeviceAnalyticsComponent } from './device-analytics/device-analytics.component';
import { DownloadForecastGraphComponent } from './download-forecast-graph/download-forecast-graph.component';
import { DownloadTrendGraphComponent } from './download-trend-graph/download-trend-graph.component';
import { ProfileTypeComponent } from './profile-type/profile-type.component';
import { TotalDownloadComponent } from './total-download/total-download.component';
import { DashboardService } from '../shared/services/dashboard.service';
import { DashboardContentModel, DashboardModel } from '../shared/models/dashboard-config';
import { HttpClient } from '@angular/common/http';
import { DashboardState } from '../shared/stores/dynamic-dashboard-sidebar/dashboard.reducer';
import * as DashboardAction from '../shared/stores/dynamic-dashboard-sidebar/dashboard.action';
import { Store } from '@ngrx/store';
import { UserState } from '../shared/stores/user/user.reducer';
import { CommunicationService } from '../shared/services/communication.service';
declare var $: any;
// import * as dynamicDashboardData from '../../assets/db/dashboard.json';
interface Safe extends GridsterConfig {
  draggable: Draggable;
  resizable: Resizable;
  pushDirections: PushDirections;
}
@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss'],
})
export class DynamicDashboardComponent implements OnInit {

  // public widgetsItems: DashboardItem[];
  // protected dashboardId: number;
  // protected dashboardArray: DashboardContentModel[];
  // protected dashboardCollection: DashboardModel;

  options: GridsterConfig;
	dashboardId: number;
	dashboardCollection: DashboardModel;
	dashboardArray: DashboardContentModel[];
  public components = {
    deviceAnalytics: DeviceAnalyticsComponent,
    downloadForecastGraph: DownloadForecastGraphComponent,
    downloadTrendGraph: DownloadTrendGraphComponent,
    profileType: ProfileTypeComponent,
    totalDownload: TotalDownloadComponent
  };

  componentCollection = [
		{ name: "Device Analytics", componentInstance: DeviceAnalyticsComponent },
		{ name: "Download Forecast Graph", componentInstance: DownloadForecastGraphComponent },
		{ name: "Download Trend Graph", componentInstance: DownloadTrendGraphComponent },
		{ name: "Profile Inventory", componentInstance: ProfileTypeComponent },
		{ name: "Total Downloaded", componentInstance: TotalDownloadComponent }
	];
  widgetImageSrc = [{
    name: "Profile Inventory",
    src: 'assets/images/dynamic-dashboard/profile-type.png',
  },
  {
    name: "Total Downloaded",
    src: 'assets/images/dynamic-dashboard/total-download.png',
  },
  {
    name: "Download Trend Graph",
    src: 'assets/images/dynamic-dashboard/trend-graph.png',
  },
  {
    name: "Device Analytics",
    src: 'assets/images/dynamic-dashboard/device-analytics.png',
  },
  {
    name: "Download Forecast Graph",
    src: 'assets/images/dynamic-dashboard/forecast-graph.png',
  }]

  lockDrag = true;
  firstTime: Boolean = true;
  allWidgets = [];
  features;
  rbacDashboard = [];
  rbacWidgets = [];
  selectedNotificationProfileTypes;
  notificationProfileTypeList = [
    {name: '10% of total profiles are in Available'},
    {name: '20% of total profiles are in Available'},
    {name: '10% of total profiles are in Released'},
    {name: '20% of total profiles are in Released'},
    {name: '80% of total profiles are in Deleted'},
    {name: '90% of total profiles are in Deleted'},
  ];
  profileNotificationDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'name',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  counts = ['None', '500', '1000', 'Custom'];
  availableCount = 'None';
  releaseCount = 'None';
  profileTypeData = {
    totalCount: 0,
    profileType: ''
  };

  outputs = {
    getProfileType: (profileType) => this.getProfileTypeDetails(profileType),
  };

  customeAvailableCount = 0;
  customeReleaseCount = 0;
  frequency = 'daily';
  availableErr = false;
  releaseErr = false;
  spinner = false;
  btnSpinner = false;
  widgetRefreshTimeSetting = [
    { name: 'Profile Type', time: '5m'},
    { name: 'eSIM installed on Device', time: '5m'},
    { name: 'Device Analytics', time: '5m'},
    { name: 'Trend Graph', time: '5m'},
    { name: 'Forecast Graph', time: '5m'}
  ];
  isDashboardUser = false;

  constructor(private elementRef: ElementRef,
    private renderer: Renderer2,
    private dashboardService: DashboardService,
    private httpClient: HttpClient,
    private dashboardStore: Store<DashboardState>,
    private userStore: Store<UserState>,
    private commnunicationService: CommunicationService) {}
  ngOnInit() {
    this.options = {
      gridType: GridType.Fit,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      // mobileBreakpoint: 640,
      minCols: 24,
      maxCols: 24,
      minRows: 40,
      maxRows: 40,



      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      emptyCellDropCallback: this.onDrop.bind(this),


      scrollSpeed: 20,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false,
        // stop: function (item, itemComponent, event) {
        //   // this.saveInDatabase($element.el.id, event, 'position');
        // }.bind(this),
        ignoreContentClass: 'gridster-ignore-drag',
      },
      resizable: {
        enabled: false,
        handles: {
          s: false, 
          e: false, 
          n: false, 
          w: false, 
          se: true, 
          ne: true, 
          sw: true, 
          nw: true
        }
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {north: true, east: true, south: true, west: true},
      pushResizeItems: false,
      displayGrid: DisplayGrid.Always,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
      // itemChangeCallback: this.itemChange.bind(this),
    };
    this.userStore.select('user')
    .subscribe(async (user: any) => {
      if(user) {
        this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Dashboard')].features;
        this.rbacDashboard = this.features.filter((f) => f.view);
        this.rbacWidgets = this.rbacDashboard.map(rb => rb.key);
        this.getOptions();

        if(user.data.roleNames.includes('dashboard-user') && user.data.roleType === 'default') {
          this.isDashboardUser = true;
        }

      }
    })
    // $('.dynamic-dashboard-container #profileType .inventory-overflow').css('height', '150px')    
    
    this.dashboardStore.select('dashboard').subscribe((data) => {
      if (data && Object.keys(data).includes('isWidgetAdded')) {
        let tempWidget = Object.assign({}, data, { isWidgetAdded: !data['isWidgetAdded'] });
        this.allWidgets.map((dA) => {
          if(dA.name == tempWidget['name']) {
            dA.isWidgetAdded = tempWidget.isWidgetAdded
          }
        });
        let tempDynamicData = {
          widgets: []
        };
        tempDynamicData['widgets'] = this.allWidgets.filter(dD => dD.isWidgetAdded)
        this.dashboardCollection = tempDynamicData;
        this.parseJson(this.dashboardCollection);
        this.dashboardArray = this.dashboardCollection['widgets'].slice();
        this.dashboardStore.dispatch(new DashboardAction.GetWidgets(JSON.parse(JSON.stringify({currentWidgets: this.dashboardArray, allWidgets: this.allWidgets}))));
        // let allWidgets = dynamicData['widgets'];
        // dynamicData['widgets'] = dynamicData['widgets'].filter(dD => dD.isWidgetAdded);
        // this.dashboardCollection = dynamicData;
        // this.parseJson(this.dashboardCollection);
        // this.dashboardArray = this.dashboardCollection['widgets'].slice();

      }
      // if(data && data['widget']) {
      //   // this.dashboardArray.push(data['widget']);
      //   // this.dashboardService.updateDashboard({ widgets: this.dashboardArray }).subscribe((data) => {
      //   //   this.dashboardCollection = { widgets: this.dashboardArray };
      //   //   this.parseJson(this.dashboardCollection);
      //   //   this.dashboardArray = this.dashboardCollection['widgets'].slice();
      //   // });
      // }
      if(data && Object.keys(data).includes('saveWidgetType')) {
        if(data['saveWidgetType']) {
          this.itemChange();
        } else {
          let data = {
            widgets: []
          };
          // this.getDashboardData();
          this.getDynamicData(data);
        }
      }
      if(Object.keys(data).includes('show') && !data['show']) {
        this.lockDrag = false;
        this.lockHandler();
      }
    });
    // const profileTypeHeight = $("[id='Profile Type']").css('height');
    // const profileTypeHeadHeight = $("[id='Profile Type'] thead").css('height');
    // const profileTypeTitleContainerHeight = $("[id='Profile Type'] .title-container").css('height');
    // if(profileTypeHeight && profileTypeHeadHeight && profileTypeTitleContainerHeight) {
    //   const dynamicHeight = profileTypeHeight.substr(0, profileTypeHeight.length - 2)  -   profileTypeHeadHeight.substr(0, profileTypeHeadHeight.length - 2) - profileTypeTitleContainerHeight.substr(0, profileTypeTitleContainerHeight.length - 2 ) - 10;
    //   $("[id='Profile Type'] .inventory-overflow").css('max-height', dynamicHeight + 'px');
    // }

    // const deviceAnalyticsHeight = $("[id='Device Analytics']").css('height');
    // const deviceAnalyticsHeadHeight = $("[id='Device Analytics'] .device-analytics-head").css('height');
    // const deviceAnalyticsTitleContainerHeight = $("[id='Device Analytics'] .dashboard-header").css('height');
    // if(deviceAnalyticsHeight && deviceAnalyticsHeadHeight && deviceAnalyticsTitleContainerHeight) {
    //   const deviceAnalyticsDynamicHeight = deviceAnalyticsHeight.substr(0, deviceAnalyticsHeight.length - 2)  -   deviceAnalyticsHeadHeight.substr(0, deviceAnalyticsHeadHeight.length - 2) - deviceAnalyticsTitleContainerHeight.substr(0, deviceAnalyticsTitleContainerHeight.length - 2 ) - 10;
    //   $("[id='Device Analytics'] .t-body-overflow").css('max-height', deviceAnalyticsDynamicHeight + 'px');
    // }

    let self = this;
    $('#profileTypeSettings').on('hidden.bs.modal', function () {
      self.frequency = 'daily';
      self.customeAvailableCount = 0;
      self.customeReleaseCount = 0;
      self.availableCount = 'None';
      self.releaseCount = 'None';
      self.availableErr = false;
      self.releaseErr = false;
      self.spinner = false;
    });
  }
  
  ngAfterViewChecked() {
    const profileTypeHeight = $("[id='Profile Type']").css('height');
    const profileTypeHeadHeight = $("[id='Profile Type'] thead").css('height');
    const profileTypeTitleContainerHeight = $("[id='Profile Type'] .title-container").css('height');
    if(profileTypeHeight && profileTypeHeadHeight && profileTypeTitleContainerHeight) {
      const dynamicHeight = profileTypeHeight.substr(0, profileTypeHeight.length - 2)  -   profileTypeHeadHeight.substr(0, profileTypeHeadHeight.length - 2) - profileTypeTitleContainerHeight.substr(0, profileTypeTitleContainerHeight.length - 2 ) - 10;
      $("[id='Profile Type'] .inventory-overflow").css('max-height', dynamicHeight + 'px');
    }

    const deviceAnalyticsHeight = $("[id='Device Analytics']").css('height');
    const deviceAnalyticsHeadHeight = $("[id='Device Analytics'] .device-analytics-head").css('height');
    const deviceAnalyticsTitleContainerHeight = $("[id='Device Analytics'] .dashboard-header").css('height');
    if(deviceAnalyticsHeight && deviceAnalyticsHeadHeight && deviceAnalyticsTitleContainerHeight) {
      const deviceAnalyticsDynamicHeight = deviceAnalyticsHeight.substr(0, deviceAnalyticsHeight.length - 2)  -   deviceAnalyticsHeadHeight.substr(0, deviceAnalyticsHeadHeight.length - 2) - deviceAnalyticsTitleContainerHeight.substr(0, deviceAnalyticsTitleContainerHeight.length - 2 ) - 10;
      $("[id='Device Analytics'] .t-body-overflow").css('max-height', deviceAnalyticsDynamicHeight + 'px');
    }
  }

  // ngAfterViewInit() {
  //     const profileTypeHeight = $('#profileType').css('height');
  //     const profileTypeHeadHeight = $('#profileType thead').css('height');
  //     const profileTypeTitleContainerHeight = $('#profileType .title-container').css('height');
  //     const dynamicHeight = profileTypeHeight.substr(0, profileTypeHeight.length - 2)  - profileTypeHeadHeight.substr(0, profileTypeHeadHeight.length - 2) - profileTypeTitleContainerHeight.substr(0, profileTypeTitleContainerHeight.length - 2 ) - 10;
  //     console.log(profileTypeHeight.substr(0, profileTypeHeight.length - 2)  - profileTypeHeadHeight.substr(0, profileTypeHeadHeight.length - 2) - profileTypeTitleContainerHeight.substr(0, profileTypeTitleContainerHeight.length - 2 ) - 10);
  //     console.log(`${dynamicHeight}px`, typeof (dynamicHeight+'px'));
  //     $('#profileType .inventory-overflow').css('max-height', dynamicHeight + 'px');
  //     console.log($('#profileType .inventory-overflow').height());
  //     $('#profileType .inventory-overflow').css('max-height', "190px");
  // }

  async getDashboardData() {
    let data = {
      widgets: []
    };
    await this.dashboardService.getDynamicDashboardData().subscribe((dynamicData) => {
      if(dynamicData['widgets'].length > 0) {
        // this.allWidgets = [];
        this.allWidgets = dynamicData['widgets'];
        dynamicData['widgets'] = dynamicData['widgets'].filter(dD => dD.isWidgetAdded && this.rbacWidgets.includes(dD.key));
        this.allWidgets = this.allWidgets.filter(w => this.rbacWidgets.includes(w.key));
        this.dashboardCollection = dynamicData;
        this.parseJson(this.dashboardCollection);
        this.dashboardArray = this.dashboardCollection['widgets'].slice();
        this.widgetImageSrc.map((wI) => {
          this.allWidgets.map(dA => {
            if(wI.name == dA.name) {
              dA['src'] = wI.src
            }
          });
        });
        this.dashboardStore.dispatch(new DashboardAction.GetWidgets(JSON.parse(JSON.stringify({currentWidgets: this.dashboardArray, allWidgets: this.allWidgets}))));
      } else {
        this.getDynamicData(data);
      }
    });
  }

  async getDynamicData(data) {
    await this.dashboardService.getStaticDashboardData().subscribe(staticData => {
      // this.dashboardCollection = staticData;
      if(staticData && staticData['widgets']) {
        this.allWidgets = staticData['widgets'].filter(dD => dD.isWidgetAdded && this.rbacWidgets.includes(dD.key));
        this.allWidgets = this.allWidgets.filter(w => this.rbacWidgets.includes(w.key));
        data.widgets = this.allWidgets;
        this.dashboardCollection = data;
        this.parseJson(this.dashboardCollection);
        this.dashboardArray = this.dashboardCollection['widgets'].slice();
        this.widgetImageSrc.map((wI) => {
          this.allWidgets.map(dA => {
            if(wI.name == dA.name) {
              dA['src'] = wI.src
            }
          });
        });
        this.dashboardStore.dispatch(new DashboardAction.GetWidgets(JSON.parse(JSON.stringify({currentWidgets: this.dashboardArray, allWidgets: this.allWidgets}))));
      }      
    });
  }

  parseJson(dashboardData) {
    dashboardData.widgets.forEach(dashboard => {
			this.componentCollection.forEach(component => {
				if (dashboard.name === component.name) {
					dashboard.component = component.componentInstance;
				}
			});
		});
  }

  getOptions() {
    // this.options = {
    //   gridType: GridType.Fit,
    //   compactType: CompactType.None,
    //   margin: 10,
    //   outerMargin: true,
    //   outerMarginTop: null,
    //   outerMarginRight: null,
    //   outerMarginBottom: null,
    //   outerMarginLeft: null,
    //   useTransformPositioning: true,
    //   // mobileBreakpoint: 640,
    //   minCols: 24,
    //   maxCols: 24,
    //   minRows: 30,
    //   maxRows: 100,



    //   maxItemCols: 100,
    //   minItemCols: 1,
    //   maxItemRows: 100,
    //   minItemRows: 1,
    //   maxItemArea: 2500,
    //   minItemArea: 1,
    //   defaultItemCols: 1,
    //   defaultItemRows: 1,
    //   fixedColWidth: 105,
    //   fixedRowHeight: 105,
    //   keepFixedHeightInMobile: false,
    //   keepFixedWidthInMobile: false,
    //   scrollSensitivity: 10,
    //   enableEmptyCellClick: false,
    //   enableEmptyCellContextMenu: false,
    //   enableEmptyCellDrop: false,
    //   enableEmptyCellDrag: false,
    //   enableOccupiedCellDrop: false,
    //   emptyCellDragMaxCols: 50,
    //   emptyCellDragMaxRows: 50,
    //   emptyCellDropCallback: this.onDrop.bind(this),


    //   scrollSpeed: 20,
    //   ignoreMarginInRow: false,
    //   draggable: {
    //     enabled: false,
    //     // stop: function (item, itemComponent, event) {
    //     //   // console.log("dragable");
    //     //   // console.log(item)
    //     //   // console.log(itemComponent);
    //     //   // this.saveInDatabase($element.el.id, event, 'position');
    //     // }.bind(this),
    //     ignoreContentClass: 'gridster-ignore-drag',
    //   },
    //   resizable: {
    //     enabled: false,
    //     handles: {
    //       s: false, 
    //       e: false, 
    //       n: false, 
    //       w: false, 
    //       se: true, 
    //       ne: true, 
    //       sw: true, 
    //       nw: true
    //     }
    //   },
    //   swap: false,
    //   pushItems: true,
    //   disablePushOnDrag: false,
    //   disablePushOnResize: false,
    //   pushDirections: {north: true, east: true, south: true, west: true},
    //   pushResizeItems: false,
    //   displayGrid: DisplayGrid.Always,
    //   disableWindowResize: false,
    //   disableWarnings: false,
    //   scrollToNewItems: false,
    //   itemChangeCallback: this.itemChange.bind(this),
    // };
    this.getDashboardData();
  }

  itemChange() {
		// this.dashboardCollection.widgets = this.dashboardArray;
		this.dashboardCollection.widgets = this.allWidgets;
		let tmp = JSON.stringify(this.dashboardCollection);
		let parsed: DashboardModel = JSON.parse(tmp);
		this.serialize(parsed.widgets);
		this.dashboardService.updateDashboard({ widgets: this.allWidgets }).subscribe((data) => {
      this.dashboardStore.dispatch(new DashboardAction.GetSidebar({ show: false }));
      this.dashboardCollection = { widgets: this.allWidgets };
      this.parseJson(this.dashboardCollection);
      this.allWidgets = this.dashboardCollection['widgets'].slice();
    });
	}

  serialize(dashboardCollection) {
		dashboardCollection.forEach(dashboard => {
			this.componentCollection.forEach(component => {
				if (dashboard.name === component.name) {
					dashboard.component = component.name;
				}
			});
		});
	}

  updateLockStatus() {
    this.lockDrag = !this.lockDrag;
  }

  public onDrop(event) {
  }
  
  public itemResize(item): void {
  }
  
  changedOptions() {
		this.options.api.optionsChanged();
	}

	removeItem(item) {
		this.dashboardArray.splice(
			this.dashboardArray.indexOf(item),
			1
		);
		this.itemChange();
	}

  lockHandler() {
    this.lockDrag = !this.lockDrag;
    this.options.draggable.enabled = !this.lockDrag;
    this.options.resizable.enabled = !this.lockDrag;
    if(this.options.api) {
      this.options.api.optionsChanged();
    }
  }

  showSidebarHandler() {
    this.lockHandler();
    // this.hierarchyStore.dispatch(new HierarchyAction.GetHierarchy(res.parentCustomer));
    this.dashboardStore.dispatch(new DashboardAction.GetSidebar({ show: true }));
  }

  // addItem(): void {
  //   console.log('comming here');
  //   // this.dashboardArray.push({x: 0, y: 0, cols: 1, rows: 1});
  // }

  getProfileTypeDetails(profileType) {
    this.profileTypeData = profileType;
    const data = {
      profileType: this.profileTypeData.profileType
    };

    this.spinner = true;

    this.dashboardService.getProfileTypeLimit(data)
      .subscribe((res: any) => {
        this.spinner = false;
        if(res.length > 0) {
          const availableData = res[0];
          const releaseData = res[1];

          this.frequency = availableData.frequency;

          this.availableCount = availableData.custom ? 'Custom' : (availableData.iccidCount === 0 ? 'None' : availableData.iccidCount.toString());
          this.customeAvailableCount = availableData.custom ? availableData.iccidCount : '';

          this.releaseCount = releaseData.custom ? 'Custom' : (releaseData.iccidCount === 0 ? 'None' : releaseData.iccidCount.toString());
          this.customeReleaseCount = releaseData.custom ? releaseData.iccidCount : '';
        }
      }, err => {
        this.spinner = false;
        this.commnunicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  setProfileLimit() {
    if(this.availableCount === 'Custom' && this.releaseCount === 'Custom') {
      if (this.customeAvailableCount >= this.profileTypeData.totalCount && this.customeReleaseCount >= this.profileTypeData.totalCount) {
        this.availableErr = true;
        this.releaseErr = true;
        return;
      } else if (this.customeAvailableCount >= this.profileTypeData.totalCount) {
        this.availableErr = true;
        return;
      } else if (this.customeReleaseCount >= this.profileTypeData.totalCount) {
        this.releaseErr = true;
        return;
      }
    } else if (this.availableCount === 'Custom' && (this.customeAvailableCount >= this.profileTypeData.totalCount)) {
      this.availableErr = true;
      return;
    } else if (this.releaseCount === 'Custom' && (this.customeReleaseCount >= this.profileTypeData.totalCount)) {
      this.releaseErr = true;
      return;
    }

    this.btnSpinner = true;

    const data = {
      profileType: this.profileTypeData.profileType,
      availableIccidCount: this.availableCount === 'None' ? 0 : (this.availableCount === 'Custom' ? Number(this.customeAvailableCount) : Number(this.availableCount)),
      releaseIccidCount: this.releaseCount === 'None' ? 0 : (this.releaseCount === 'Custom' ? Number(this.customeReleaseCount) : Number(this.releaseCount)),
      availableCustomFlg: this.availableCount === 'Custom' ? true : false,
      releaseCustomFlg: this.releaseCount === 'Custom' ? true : false,
      frequency: this.frequency
    };

    this.dashboardService.setProfileLimit(data)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        $('#profileTypeSettings').modal('hide');
      }, err => {
        this.btnSpinner = false;
        this.commnunicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  openFullscreen() {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.getElementById('dashboard-grid') as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
  
    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
    // this.isfullscreen = true;
  }
  
  closeFullscreen(){
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    // this.isfullscreen = false;
  }

  getWidgetRefreshTime() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        if (res.refreshTimeSetting.length > 0) {
          this.widgetRefreshTimeSetting = res.refreshTimeSetting;
        }
      }, err => {
        console.log(err);
      });
  }
}
