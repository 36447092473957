import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../../../shared/services/communication.service';
import { GroupMgmtService } from '../../../shared/services/group-mgmt.service';

@Component({
  selector: 'app-single-group',
  templateUrl: './single-group.component.html',
  styleUrls: ['./single-group.component.scss']
})
export class SingleGroupComponent implements OnInit {

  group;
  groupSpinner = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private groupService: GroupMgmtService,
    private communicationService: CommunicationService) { }

  ngOnInit() {
    this.activeRoute.params
      .subscribe((param: any) => {
        this.group = param;
        this.getSingleGroup(param.id);
      });
  }

  getSingleGroup(id) {
    this.groupSpinner = true;
    this.groupService.getSingleGroup(id)
      .subscribe((res: any) => {
        this.groupSpinner = false;
        this.group = res;
      }, err => {
        this.groupSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

}
