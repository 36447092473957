<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">{{'Profile Type Access Management' | translate}}</h4>
                    <div class="d-flex ml-auto">
                        <button class="btn t-bgcolor outline" [routerLink]="['/access-management/profiletype/create']"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Create New Profile Type Access' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table t-table table-scroll">
                        <thead>
                            <tr>
                                <th>{{'Profile Type Access Name' | translate}}</th>
                                <th>{{'Created By' | translate}}</th>
                                <th>{{'No. of Users' | translate}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <!-- <tbody class="loader-flex-center" *ngIf="userSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </tbody>
                        <tbody *ngIf="userList && userList.length <= 0 && !userSpinner">
                            <tr>
                                <td colspan="5" class="text-center">{{'No Data Found' | translate}}.</td>
                            </tr>
                        </tbody> -->
                        <tbody style="max-height: calc(95vh - 200px);">
                            <tr *ngFor="let role of [1, 2, 3, 4, 5, 6, 7]; let i = index">
                                <td>Dealer</td>
                                <td>Abhay Vibhute</td>
                                <td>15</td>
                                <td class="profile-manage-icon-row">
                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" ></i>
                                    <i class="fa fa-trash t-color cursor-p"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
