import { Action } from '@ngrx/store';
import { Filter } from './filter.model';

export const GET_FILTER = 'GET_FILTER';

export class GetFilter implements Action {
    readonly type = GET_FILTER;

    constructor(public payload: Filter) { }
}

export type FilterActions = GetFilter;
