import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { ShapeType } from '../../model/shape-types';
import { MousePosition, Rectangle, Triangle } from '../../model/shape';

// import { Field } from 'dynaform';

@Component({
  selector: 'app-triangle',
  templateUrl: './triangle.component.html',
  styleUrls: ['./triangle.component.css']
})
export class TriangleComponent extends ShapeComponent implements OnInit {

//   formFields: Field[] = [
//     {
//         name: 'x',
//         label: 'X:',
//         type: 'input',
//         inputType: 'text',
//         value: ''
//     },
//     {
//         name: 'y',
//         label: 'Y:',
//         type: 'input',
//         inputType: 'text',
//         value: ''
//     },
// ];

  value = '50 0, 100 100, 0 100';
  constructor() {
    super();
    // console.log('Triangle constructor');
    this.shape = new Triangle();
    this.shapeType = ShapeType.Triangle;
  }

  ngOnInit() {
    // console.log('TriangleComponent ngOnInit');
  }

  setStyles() {
    const styles = {
        'stroke': this.shape.shapeProperties.strokeColor,
        'fill': this.shape.shapeProperties.fillColor,
        'stroke-width': this.shape.shapeProperties.strokeWidth
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    // console.log('RectanleComponent startDrawing at ', beginPosition);
    if (this.shape instanceof Triangle) {
      this.shape.x1 = beginPosition.x;
      this.shape.y1 = beginPosition.y;
      this.shape.x2 = beginPosition.x + 50;
      this.shape.y2 = beginPosition.y + 100;
      this.shape.x3 = beginPosition.x - 50;
      this.shape.y3 = beginPosition.y + 100;
      this.value = `${this.shape.x1} ${this.shape.y1}, ${this.shape.x2} ${ this.shape.y2}, ${this.shape.x3} ${ this.shape.y3}`;
    }
  }

  // 50 15, 100 100, 0 100
  draw(currentPosition: MousePosition): void {
    // console.log('RectanleComponent startDrawing at ', currentPosition);
    // console.log('TriangleComponent draw');
    if (this.shape instanceof Triangle) {
      // console.log(this.shape.x1);
      // console.log(this.shape.x2);
      // console.log(currentPosition);
        // console.log(currentPosition);
      const addF = currentPosition.x - this.shape.x1;
      const addY = this.shape.y1 - currentPosition.y;
        // this.shape.y1 = Math.abs(currentPosition.x - this.shape.x1);
        // this.shape.y2 = Math.abs(currentPosition.y - this.shape.x2);
      this.shape.x1 = this.shape.x1 + addF;
      this.shape.y1 = this.shape.y1 - addY;
      this.shape.x2 = this.shape.x2 + 2 * addF;
      this.shape.y2 =  this.shape.y2 + addY;
        // this.shape.x3 = beginPosition.x - 50;
      this.shape.y3 =  this.shape.y3 + addY;
      this.value = `${this.shape.x1} ${this.shape.y1}, ${this.shape.x2} ${ this.shape.y2}, ${this.shape.x3} ${ this.shape.y3}`;
        // this.value = `${this.shape.x1+ currentPosition.x} ${this.shape.x2}, ${this.shape.y1+currentPosition.x} ${ this.shape.y1+currentPosition.y}, ${this.shape.y2} ${ this.shape.y2+currentPosition.y}`;
    }
  }
}
