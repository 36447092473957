export enum ShapeType {
    NoShape,
    Line,
    Circle,
    Ellipse,
    Rectangle,
    TextBox,
    Path,
    PolyLine,
    Image,
    Square,
    Triangle,
    RoundedRactangle,
    QRCode,
    Barcode,
    LPAString,
    ACToken,
    ICCID,
    PIN1,
    PIN2,
    PUK1,
    PUK2,
    MCC,
    MNC,
    IMSI,
    ConfirmationCode,
    EID,
    QRCodeWithLogo,
    Watermark,
    GroupQR,
    GroupQRWithLogo,
}

export enum ToolType {
    Pointer,
    Move,
    Rotate,
    SelectArea,
    Flipvertical,
    Fliphorizontal
}

export enum State {
    None,
    Moving,
    Finished

}
