<div class="inner-main-profile">
    <div class="ui-customization-head-section face-container-inner-bg p-2 mb-3">
        <div class="edit-theme-head-row d-flex align-items-center" style="height: 40px;">
            <p class="mb-0">{{'Login Theme' | translate}}</p>
            <div class="d-flex flex-grow-1 ml-2 justify-content-center align-items-center">
                <p class="mb-0 mr-2">{{'Subdomain' | translate}} :</p>
                <input type="text" class="form-control t-input w-25" [(ngModel)]="subdomain"><span class="ml-2">.my.10ttech.com</span>
            </div>
            <div class="preview-btns mr-3">
                <button class="btn btn-edit-head t-bgcolor py-0 px-4 outline" (click)="onSaveLoginTheme()" *ngIf="!btnSpinner">{{'Save' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="ui-customization-logo-section mb-3">
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12 mb-3">
                        <div class="face-container-inner-bg box-height">
                            <div class="border-bottom p-2">{{'Logo' | translate }}</div>
                            <div class="p-2 t-font-light t-font-medium">
                                <div class="mb-3 pt-1">
                                    {{'File should be in JPG or PNG format' | translate}}.
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="d-flex align-items-center pr-3" style="max-width: 250px;">
                                        <p class="mb-0">{{'Upload File' | translate}}:</p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <button class="btn py-0 mr-5 t-font-medium bg-light-grey text-secondary outline" (click)="clickUploadIcon()">
                                            <input id="loginLogo" type="file" style="display: none" accept="image/x-png,image/gif,image/jpeg" (change)="onChangeIcon($event)">
                                            {{'Choose File' | translate}}
                                        </button>
                                        <p class="mb-0" *ngIf="logoName">
                                            {{logoName}}
                                        </p>
                                        <div class="ml-5" style="width: 120px;">
                                            <img id="login-logo" class="img-fluid" style="height: 65px;" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="ui-customization-theme-color-section face-container-inner-bg mb-3 box-height auth-section">
                            <div class="border-bottom p-2">{{'Theme Color' | translate}}</div>
                            <div class="p-2 t-font-light t-font-medium">
                                <div class="mb-4 pt-1">
                                    {{'We suggest you choose dark colours for side bar and background for dark mode theme' | translate}}.
                                </div>
                                
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Background' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="backgroundColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'background')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="backgroundColor" [style.background]="backgroundColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'background')" (cpInputChange)="onEventLog('cpInputChange', $event, 'background')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'background')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Left Side' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="leftSideColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'leftSide')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="leftSideColor" [style.background]="leftSideColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'leftSide')" (cpInputChange)="onEventLog('cpInputChange', $event, 'leftSide')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'leftSide')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Right Side' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="rightSideColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'rightSide')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="rightSideColor" [style.background]="rightSideColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'rightSide')" (cpInputChange)="onEventLog('cpInputChange', $event, 'rightSide')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'rightSide')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Button' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="buttonColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'button')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="buttonColor" [style.background]="buttonColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'button')" (cpInputChange)="onEventLog('cpInputChange', $event, 'button')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'button')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Button Text' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="buttonTextColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'buttonText')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="buttonTextColor" [style.background]="buttonTextColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'buttonText')" (cpInputChange)="onEventLog('cpInputChange', $event, 'buttonText')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'buttonText')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Cancel Button' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="cancelButtonColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'cancelButton')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="cancelButtonColor" [style.background]="cancelButtonColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'cancelButton')" (cpInputChange)="onEventLog('cpInputChange', $event, 'cancelButton')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'cancelButton')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Cancel Button Text' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="cancelButtonTextColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'cancelButtonText')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="cancelButtonTextColor" [style.background]="cancelButtonTextColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'cancelButtonText')" (cpInputChange)="onEventLog('cpInputChange', $event, 'cancelButtonText')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'cancelButtonText')" type="text">
                                        </div>
                                    </div>
                                </div>
                                <div class="row color-input-row">
                                    <div class="col-lg-3 d-flex align-items-center">{{'Font' | translate}}</div>
                                    <div class="col-lg-9 d-flex">
                                        <div>
                                            <input type="text" [(ngModel)]="fontColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'font')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                                        </div>
                                        <div>
                                            <input class="color-input outline" [(colorPicker)]="fontColor" [style.background]="fontColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'font')" (cpInputChange)="onEventLog('cpInputChange', $event, 'font')"
                                                [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'font')" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="face-container-inner-bg box-height">
                    <div class="border-bottom p-2">{{'Preview' | translate }}</div>
                        <section class="new-auth-section preview-auth-section">
                            <section class="bg-background p-bg-onboarding" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                                <div class="signin-page" data-theme='dark' style="height: calc(76vh - 9px);">
                                    <div class="signin-inner" data-theme='dark' style="width: 85%; top: 52%;">
                                        <div class="row">
                                            <div class="col-lg-6 d-flex flex-column left-signin-inner">
                                                <div class="logo mt-auto mx-auto pb-5">
                                                    <img class="img-logo" id="p-logo-icon" src="https://s3.amazonaws.com/subdomain-logos.10ttech.com/10t-new.png">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 right-signin-inner">
                                                <div>
                                                    <div class="createaccount-form" style="padding: 120px 50px">
                                                        <div class="form-group mb-4">
                                                            <label for="exampleInputEmail1" class="mb-0">{{ "Email address" | translate}}</label>
                                                            <input type="email" class="form-control onboarding-form-input" placeholder="{{'Email' | translate}}">
                                                        </div>
                    
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1" class="mb-0">{{ "Password" | translate}}</label>
                                                            <input type="password"  class="form-control onboarding-form-input" placeholder="{{'Password' | translate}}">
                                                            <label for="forget-password" class="mt-3"> <a href="#" class="forget-pass-link">{{ "Forgot password?" | translate}}</a>
                                                                </label>
                                                            <button class="btn btn-block signin-inner-btn outline signin-inner-btn-default" id="sign-in" type="submit"> {{ "Sign In" | translate}}</button>
                                                            <button class="btn btn-block signin-inner-cancel-btn mt-3 outline" routerLink="/signin" type="submit">{{ "Cancel" | translate}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-lg-6">
            <div class="ui-customization-theme-color-section face-container-inner-bg mb-3 box-height">
                <div class="border-bottom p-2">{{'Theme Color' | translate}}</div>
                <div class="p-2 t-font-light t-font-medium">
                    <div class="mb-4 pt-1">
                        {{'We suggest you choose dark colours for side bar and background for dark mode theme' | translate}}.
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Left Side' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="sideBarColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'sidebar')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="sideBarColor" [style.background]="sideBarColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'sidebar')" (cpInputChange)="onEventLog('cpInputChange', $event, 'sidebar')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'sidebar')" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Right Side' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="backgroundColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'background')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="backgroundColor" [style.background]="backgroundColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'background')" (cpInputChange)="onEventLog('cpInputChange', $event, 'background')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'background')" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Button' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="buttonColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'button')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="buttonColor" [style.background]="buttonColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'button')" (cpInputChange)="onEventLog('cpInputChange', $event, 'button')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'button')" type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
        </div>
    </div> -->
</div> 

