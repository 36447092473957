import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DisableControlDirective } from './directive/disable-control.directive';
import { LimitToPipe } from './pipe/limit-to.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';

@NgModule({
    declarations: [
        DisableControlDirective,
        LimitToPipe,
        SafeHtmlPipe
    ],
    imports: [
        RouterModule,
        ReactiveFormsModule
    ],
    exports: [
        DisableControlDirective,
        LimitToPipe,
        SafeHtmlPipe
    ]
})
export class SharedModule { }
