import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { CommunicationService } from '../shared/services/communication.service';
declare var $: any;
import * as papa from 'papaparse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../shared/services/common.service';
import { Store } from '@ngrx/store';
import { UserState } from '../shared/stores/user/user.reducer';
import { CookieTrailService } from '../shared/services/cookie-trail.service';

@Component({
  selector: 'app-api-tree',
  templateUrl: './api-tree.component.html',
  styleUrls: ['./api-tree.component.scss']
})
export class ApiTreeComponent implements OnInit {

  apiObject;
  url;
  requestObject;
  responseObject;
  requestSchemaObject;
  responseSchemaObject;
  apiUser = false;
  apiId;
  apiName;
  apiFileds = [];
  userResponseBody;
  fileToUpload;
  uploadCsvErr = false;
  disableButton = true;
  iccidList = [];
  apiResponseSpinner = false;
  responseSpinner = false;
  userResponseSpinner = false;
  features;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private commonService: CommonService,
    private userStore: Store<UserState>,
    private cookieTrailService: CookieTrailService) { }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});
    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'API')].features;
          !this.features[this.getFeatureIndex('api_developer')].edit && this.features[this.getFeatureIndex('api_user')].edit ? this.apiUser = true : this.apiUser = false;
        }
      });

    this.activatedRoute.params
      .subscribe((params: any) => {
        this.apiResponseSpinner = true;
        this.apiId = params.id;
        this.apiObject = [];
        this.requestObject = '';
        this.responseObject = {};
        this.requestSchemaObject = '';
        this.responseSchemaObject = '';
        this.apiFileds = [];

        const cookieTrail = {
          screenName: 'APIs',
          action: 'Get Single API',
          body: {
            apiId: params.id
          },
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.apiService.getSingleApi(params.id)
          .subscribe((res: any) => {
            this.apiResponseSpinner = false;
            this.apiObject = res;
            this.apiName = res.apiName.
              replace(/([A-Z])/g, ' $1')
              .replace(/^./, (str) => str.toUpperCase());
            this.url = this.apiObject.url;
            this.requestObject = this.apiObject.request;
            // this.responseObject = this.apiObject.response;
            this.requestSchemaObject = this.apiObject.requestSchema;
            this.responseSchemaObject = this.apiObject.responseSchema;

            if (this.apiObject.requestSchema) {
              Object.keys(this.apiObject.requestSchema.properties).forEach((propEle, index) => {
                this.apiFileds.push({
                  name: propEle.trim(),
                  value: '',
                  isChecked: true,
                  type: (Object.values(this.apiObject.requestSchema.properties)[index] as any).type,
                  isRequired:
                    Object.values(this.apiObject.requestSchema.required).find(ele => ele === propEle) === undefined ? false : true,
                  isError: false
                });
              });
            }
          }, error => {
            this.apiResponseSpinner = false;
            this.communicationService.openAlert({ alertType: 'error', alertMsg: error.error.message || 'Something went wrong!' });
          });
      });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  sendApiCall() {
    if (!(document.getElementById('requestArea') as HTMLInputElement).value) {
      this.responseSpinner = false;
      this.communicationService.openAlert({ alertType: 'error', alertMsg: 'Please provide request body!' });
      return;
    }

    if (!this.commonService.isValidJSON((document.getElementById('requestArea') as HTMLInputElement).value, 'Request body')) {
      return;
    }

    this.requestObject = JSON.parse((document.getElementById('requestArea') as HTMLInputElement).value);

    this.responseSpinner = true;

    const cookieTrail = {
      screenName: 'APIs',
      action: 'Develper API Excution',
      body: {
        apiId: this.apiId,
        apiName: this.apiName,
        requestObject: this.requestObject,
      },
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.apiService.sendApiCall(this.apiId, this.requestObject)
      .subscribe((res: any) => {
        this.responseObject = res;
        this.responseSpinner = false;
      }, error => {
        this.responseSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: error.error.message || 'Something went wrong!' });
      });
  }

  executeApi() {
    let flg = 0;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.apiFileds.length; i++) {
      if (this.apiFileds[i].isRequired && !this.apiFileds[i].value.trim()) {
        this.apiFileds[i].isError = true;
        flg = 1;
      }
    }

    if (flg === 0) {
      const data = {};

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.apiFileds.length; i++) {
        if (this.apiFileds[i].name !== 'iccidList' && this.apiFileds[i].isChecked && this.apiFileds[i].value.trim()) {
          if (this.apiFileds[i].type !== 'array' && (this.apiFileds[i].value === 'true' || this.apiFileds[i].value === 'false')) {
            data[this.apiFileds[i].name] = this.apiFileds[i].value === 'true' ? true : false;
          } else if (this.apiFileds[i].type === 'array') {
            const tempArr = this.apiFileds[i].value.split(',');
            data[this.apiFileds[i].name] = tempArr;
          } else {
            data[this.apiFileds[i].name] = this.apiFileds[i].value;
          }
        } else if (this.apiFileds[i].name === 'iccidList') {
          data[this.apiFileds[i].name] = this.iccidList;
        }
      }

      this.userResponseSpinner = true;

      const cookieTrail = {
        screenName: 'APIs',
        action: 'User API Execution',
        body: {
          apiId: this.apiId,
          apiName: this.apiName,
          requestObject: data,
        },
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);

      this.apiService.executeApi(this.apiId, data)
        .subscribe((res: any) => {
          this.userResponseSpinner = false;
          this.userResponseBody = res;
          this.fileToUpload = '';
          this.uploadCsvErr = false;
        }, error => {
          this.uploadCsvErr = false;
          this.fileToUpload = '';
          this.userResponseSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: error.error.message || 'Something went wrong!' });
        });
    }
  }

  uploadCSV() {
    $('#apiIccidCsv').click();
  }

  async onFileChange(event) {
    if (event.target.files.length > 0) {
      this.uploadCsvErr = false;
      this.disableButton = false;
      this.iccidList = [];
      this.fileToUpload = event.target.files[0];
      papa.parse(this.fileToUpload, {
        complete: (results) => {
          results.data.forEach((ele: any, index) => {
            if (index) {
              this.iccidList.push({ iccid: ele[0] });
            }
          });
        }
      });
    }
  }

  copyToClipboard(id) {
    const selBox = document.createElement('textarea');

    if (id === 'requestArea') {
      selBox.value = (document.getElementById(id) as HTMLInputElement).value;
    } else {
      selBox.value = document.getElementById(id).innerText;
    }

    document.body.appendChild(selBox);

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      const range = document.createRange();
      range.selectNodeContents(selBox);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      selBox.setSelectionRange(0, 999999);
    } else {
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.focus();
      selBox.select();
    }
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.snackBar.open('Successfully Copied to Clipboard!', '', {
      duration: 4000
    });
  }
}
