<div class="inner-main-profile">
    <div class="face-container-inner-bg p-3 mb-3">
        <div class="date-n-search-row">
            <div class="search-container" style="padding-right:0;">
                <div class="search-inner-container" style="min-width: 700px;">
                    <input type="text" class="form-control c-input-serch" [(ngModel)]="searchTerm" placeholder="{{'Search by ICCID / EID / TAC number' | translate}}" (keypress)="isEnter($event)">
                    <button class="btn c-btn profile-info-tabs flex-shrink-0" (click)="getProfiles()"><i class="fa fa-search mr-2"></i> {{ "Search" | translate}}</button>
                </div>
            </div>
            <div class="right-btn-container" *ngIf="features && (features[getFeatureIndex('prepare_single', 'profile')].edit || features[getFeatureIndex('reset_single', 'profile')].edit || features[getFeatureIndex('qrcode_single', 'profile')].edit)">
                <div class="btn-spinner" *ngIf="syncResetBtnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <button class="btn c-btn-action mr-3" id="op-resetProfile" tooltip="{{'Reset Profile' | translate}}" [tooltipAnimation]="false" placement="top" (click)="activeButton('op-resetProfile')" [disabled]="disableReset" *ngIf="!syncResetBtnSpinner && features && features[getFeatureIndex('reset_single', 'profile')].edit">
                    <img src="assets/images/csap-icons/undo-w.png" alt="">
                </button>
            </div>
        </div>

        <div class="filter-tags-row-new" *ngIf="activeGroupSelection == 'Filter Private Group'">
            <div class="profile-status-checkbox-row">
                <div class="checkbox-perent-filter" *ngFor="let status of statusArray; let i = index">
                    <p class="checkbox-perent-filter-inner" [ngClass]="{'checkbox-perent-filter-inner-checked': status.checked}" (click)="status.checked = !status.checked;selectStatus(i)"> {{status.key}} <span> <i class="fa fa-plus ml-2" *ngIf="!status.checked"></i> <i class="fa fa-check ml-2" *ngIf="status.checked"></i></span></p>
                </div>
            </div>
        </div>
        <div class="date-n-status-row" *ngIf="activeGroupSelection == 'Filter Private Group'">
            <div class="date-n-status-row-date-container mr-5">
                <div class="date-n-status-row-date-container-inner">
                    <div class="date-n-status-row-date-container-inner-subtitle">
                        <p class="t-font-light">{{ "From" | translate}}: </p>
                    </div>
                    <input class="c-acc-input" [(ngModel)]="fromDate" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" (ngModelChange)="getProfiles();seletDate('fromDate')">
                    <owl-date-time #dt1 [hour12Timer]='flg'></owl-date-time>
                </div>
                <div class="date-n-status-row-date-container-inner">
                    <div class="date-n-status-row-date-container-inner-subtitle">
                        <p class="t-font-light">{{ "To" | translate}}: </p>
                    </div>
                    <input class="c-acc-input" [(ngModel)]="toDate" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" (ngModelChange)="getProfiles();seletDate('toDate')">
                    <owl-date-time #dt2 [hour12Timer]='flg'></owl-date-time>
                </div>
                <div class="py-1 c-color cursor-p" (click)="fromDate = '';toDate = '';getProfiles();">
                    {{ "Clear Date" | translate}}
                </div>
            </div>
            <!-- <div class="profile-type-lock-status py-1">
                <div class="profile-status-title mr-2 t-font-light">
                    Profile Type Lock Status:
                </div>
                <div class="filter-box-checkbox-container d-flex t-font-light">
                    <div *ngFor="let status of profileLockStatus" class="profile-lock-status">
                        <mat-checkbox [(ngModel)]="status.checked" (click)="getProfiles();">{{status.name}}</mat-checkbox>
                    </div>
                </div>
            </div> -->
            <div class="reset-filter ml-auto c-color cursor-p" (click)="clearFilters()">
                {{ "Clear Filter" | translate}}
            </div>
        </div>
    </div>
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg profile-manage-table">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">
                        {{ "Result" | translate}}: {{profileCount}} {{ "Found" | translate}}
                        <span class="ml-4 text-muted t-font-light">{{ "Selected" | translate}}: {{ selectAllIccid ? selectedProfileCount : selectedIccidList.length}}</span>
                    </h4>
                    <div class="ml-auto t-color cursor-p" (click)="getProfiles()"><i class="fa fa-refresh mr-2"></i>{{ "Refresh" | translate}}</div>
                    <div class="dropdown field-selection ml-3">
                        <!--select class="custom-selection-dropdown outline gridster-ignore-drag ml-1 range-selector flex-grow-1" [(ngModel)]="activeGroupSelection" (ngModelChange)='updateTableFilterByGroupMatchingId($event)'>
                            <option *ngFor="let option of activeGroupProfileList" [value]="option.id">{{ option.name }}</option>
                        </select-->
                        <button class="btn dropdown-toggle t-font-light field-types-container" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="mr-3 sg-font-regular">{{ activeGroupSelection }}</span></button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item pl-2 sg-font-regular" *ngFor="let item of activeGroupProfileList" (click)="updateTableFilterByGroupMatchingId(item)" [ngClass]="{'t-color': !item.selected, 'text-white': item.selected}">
                                {{ item.name | translate }}
                            </a>
                        </div>
                    </div>
                    <div class="dropdown field-selection ml-3">
                        <button class="btn dropdown-toggle t-font-light field-types-container" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="mr-3 sg-font-regular">{{ "Field Selection" | translate}}</span></button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="showList($event)">
                            <a class="dropdown-item pl-2 sg-font-regular" *ngFor="let item of fieldList" (click)="updateTableColums(item)" [ngClass]="{'t-color': !item.selected, 'text-white': item.selected}">
                                <i class="fa cursor-p mr-3" [ngClass]="{'fa-plus t-color': !item.selected, 'fa-check text-white': item.selected}" aria-hidden="true"></i>{{ item.name | translate }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="t-grid-table-new">
                    <div class="t-grid-table-wrapper t-head-bg border-bottom-light" [ngClass]="{'t-grid-table-wrapper-remove-tac': !showTac}" [style.gridTemplateColumns]="columnConfig.join(' ')">
                        <div class="t-box b">
                            <div class="filter-box-checkbox-container">
                                <mat-checkbox *ngIf="profileList && (profileList.lenght <= 0)" [disabled]="true"></mat-checkbox>
                                <mat-checkbox [(ngModel)]="select" (ngModelChange)="selectAllIccid = select; selectAllRows();getSelectedCount();" *ngIf="profileList && (profileList.length > 0)"></mat-checkbox>
                            </div>
                        </div>
                        <div class="t-box b">
                            <span class="mr-2">{{ "ICCID" | translate}}</span>
                            <i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'ICCID' && orderBy === 'ASC'}" (click)="sortBy = 'ICCID'; orderBy = 'ASC'; getProfiles();"></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'ICCID' && orderBy === 'DESC'}" (click)="sortBy = 'ICCID'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Profile Type')">
                            <span class="mr-2">{{ "Profile Type" | translate}}</span>
                            <i *ngIf="activeGroupSelection == 'Filter Private Group'" class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'PROFILE_TYPE' && orderBy === 'ASC'}" (click)="sortBy = 'PROFILE_TYPE'; orderBy = 'ASC'; getProfiles();"></i>
                            <i *ngIf="activeGroupSelection == 'Filter Private Group'" class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'PROFILE_TYPE' && orderBy === 'DESC'}" (click)="sortBy = 'PROFILE_TYPE'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Group Type')">{{ "Group Type" | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Status')">
                            <span class="mr-2">{{ "Status" | translate}}</span>
                            <i *ngIf="activeGroupSelection == 'Filter Private Group'" class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'STATUS' && orderBy === 'ASC'}" (click)="sortBy = 'STATUS'; orderBy = 'ASC'; getProfiles();"></i>
                            <i *ngIf="activeGroupSelection == 'Filter Private Group'" class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'STATUS' && orderBy === 'DESC'}" (click)="sortBy = 'STATUS'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Update On')">
                            <span class="mr-2">{{ "Update On" | translate}} (GMT)</span>
                            <i *ngIf="activeGroupSelection == 'Filter Private Group'" class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'LAST_UPDATE' && orderBy === 'ASC'}" (click)="sortBy = 'LAST_UPDATE'; orderBy = 'ASC'; getProfiles();"></i>
                            <i *ngIf="activeGroupSelection == 'Filter Private Group'" class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'LAST_UPDATE' && orderBy === 'DESC'}" (click)="sortBy = 'LAST_UPDATE'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('PRP')">{{'PRP' | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('PRP Count')">{{'PRP Count' | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('EID')">{{'EID' | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('TAC')">{{'TAC' | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Device Model')">{{'Device Model' | translate}}</div>
                        <div class="t-box c"></div>
                    </div>
                    <div class="loader-flex-center" *ngIf="profileSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                    <div *ngIf="profileList && profileList.length <= 0 && !profileSpinner">
                        <div class="text-center mt-2 mb-2">
                            <div class="text-center">{{'No Data Found' | translate}}.</div>
                        </div>
                    </div>
                    <div class="t-body-overflow accordion profile-table-tbody" id="profileAccordian" *ngIf="!profileSpinner" infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()"
                    [scrollWindow]="false">
                        <ng-container *ngFor="let profile of profileList;let i = index;">
                            <div class="t-grid-table-wrapper t-body-bg border-bottom-light" [ngClass]="{'t-grid-table-wrapper-remove-tac': !showTac}" [style.gridTemplateColumns]="columnConfig.join(' ')">
                                <div class="t-box d t-font-light filter-box-checkbox-container">
                                    <mat-checkbox [(ngModel)]="profile.checked" (ngModelChange)="selectCheck(profile.checked, profile.iccid, profile.state);"></mat-checkbox>
                                </div>
                                <div class="t-box d t-font-light">{{profile.iccid}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('Profile Type')">{{profile.profileType}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('Group Type')">{{profile.groupType}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('Status')">{{profile.state}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('Update On')">{{profile.updatedOn}}</div>
                                <div class="t-box c" *ngIf="activeTableColumns.includes('PRP')">{{profile.prp}}</div>
                                <div class="t-box c" *ngIf="activeTableColumns.includes('PRP Count')">{{profile.prpCounter}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('EID')">{{profile.eid}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('TAC')">{{profile.tac}}</div>
                                <div class="t-box d t-font-light" *ngIf="activeTableColumns.includes('Device Model')">{{profile.model}}</div>
                                <div class="t-box d t-color d-flex">
                                    <!-- <a class="t-font-color c-info py-0 c-color"><i class="fa fa-info mr-2"></i> </a>{{ "info" | translate}} -->
                                    <a class="t-font-color c-info py-0 c-color" data-toggle="collapse" href="#collapseOne_{{i}}" aria-expanded="true" aria-controls="collapseOne" (click)="features && features[getFeatureIndex('logs', 'profile')].view ? getLogs(profile.iccid, i, 'log') : features[getFeatureIndex('operations', 'profile')].view ? getOperations(profile.iccid, i, 'opr') : features[getFeatureIndex('tac_info', 'profile')].view ? getTacInfo(profile.iccid, i, 'tac') : features[getFeatureIndex('prepare_smdp', 'profile')].edit ? getStatus(profile.iccid, i, 'status') : ''; logList = []; getICCIDInfo(profile.iccid)">
                                        <i class="fa fa-info mr-2"></i>
                                    </a>
                                </div>
                            </div>
                            <div id="collapseOne_{{i}}" class="collapse" aria-labelledby="headingOneA" data-parent="#profileAccordian">
                                <div colspan="5" class="py-0 d-flex">
                                    <div id="collapseOne" class="collapse show accordian-info" aria-labelledby="headingOne" data-parent="#accordionExample1">
                                        <div class="card-body info-new-operation">

                                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                <li class="nav-item info-btn-container-individual" style="border-top-left-radius: 5px;"
                                                [ngStyle]="{'border-top-right-radius': features && !(features[getFeatureIndex('operations', 'profile')].view || features[getFeatureIndex('tac_info', 'profile')].view || features[getFeatureIndex('prepare_smdp', 'profile')].edit) ? '5px' : '0'}" (click)="getLogs(profile.iccid, i, 'log')" *ngIf="features && features[getFeatureIndex('logs', 'profile')].view">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-log-tab_{{i}}" data-toggle="pill" href="#info-log_{{i}}" role="tab" aria-controls="info-log" aria-selected="true">
                                                        {{ "Log" | translate}}
                                                    </a>
                                                </li>
                                                <li class="nav-item info-btn-container-individual"
                                                [ngStyle]="{'border-top-left-radius': features && !features[getFeatureIndex('logs', 'profile')].view ? '5px' : '0', 'border-top-right-radius': features && !(features[getFeatureIndex('tac_info', 'profile')].view || features[getFeatureIndex('prepare_smdp', 'profile')].edit) ? '5px' : '0'}" (click)="getOperations(profile.iccid, i, 'opr')" *ngIf="features && features[getFeatureIndex('operations', 'profile')].view">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-opr-tab_{{i}}" data-toggle="pill" href="#info-opr_{{i}}" role="tab" aria-controls="info-opr" aria-selected="false">
                                                        {{ "Operations" | translate}}
                                                    </a>
                                                </li>
                                                <li class="nav-item info-btn-container-individual"
                                                [ngStyle]="{'border-top-left-radius': features && !(features[getFeatureIndex('logs', 'profile')].view || features[getFeatureIndex('operations', 'profile')].view) ? '5px' : '0px', 'border-top-right-radius': features && !features[getFeatureIndex('prepare_smdp', 'profile')].edit ? '5px' : '0'}" (click)="getTacInfo(profile.iccid, i, 'tac')" *ngIf="features && features[getFeatureIndex('tac_info', 'profile')].view && showTac">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-tac-tab_{{i}}" data-toggle="pill" href="#info-tac_{{i}}" role="tab" aria-controls="info-tac" aria-selected="false">
                                                        {{ "TAC Info" | translate}}
                                                    </a>
                                                </li>
                                                <li class="nav-item info-btn-container-individual"
                                                [ngStyle]="{'border-top-left-radius': features && !(features[getFeatureIndex('logs', 'profile')].view || features[getFeatureIndex('operations', 'profile')].view || features[getFeatureIndex('tac_info', 'profile')].view) ? '5px' : '0px'}"
                                                style="border-top-right-radius: 5px;" *ngIf="features && features[getFeatureIndex('prepare_smdp', 'profile')].edit">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-status-tab_{{i}}" data-toggle="pill" href="#info-status_{{i}}" role="tab" aria-controls="info-status" aria-selected="false" (click)="getStatus(profile.iccid, i, 'status')">
                                                        {{ "Status" | translate}}
                                                    </a>
                                                </li>
                                                <li class="download-n-close ml-auto">
                                                    <a class="filter-download c-color c-info" (click)="downloadQrCodeIccid = profile.iccid;getDefaultLogo();" data-toggle="modal" data-target="#downloadQrCodeModal" *ngIf="(defaultSmdp != null && !defaultSmdp) && profile.state === 'RELEASED' && features && features[getFeatureIndex('download_qrcode_single', 'profile')].edit"><i class="fa fa-download"></i> <span class="ml-2"> {{ "Download QR code" | translate}}</span></a>
                                                    <a class="inner-filter-table-close ml-3 t-font-light" data-toggle="collapse" href="#collapseOne_{{i}}">
                                                        X
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade" id="info-log_{{i}}" role="tabpanel" aria-labelledby="info-log-tab" *ngIf="features && features[getFeatureIndex('logs', 'profile')].view">
                                                    <table class="table t-table t-filter-inner-table table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{{ "Time Stamp" | translate}}</th>
                                                                <th scope="col">{{ "Action" | translate}}</th>
                                                                <th scope="col">{{ "Action Status" | translate}}</th>
                                                                <th scope="col">{{ "EID" | translate}}</th>
                                                                <th scope="col" *ngIf="showTac">{{ "TAC" | translate}}</th>
                                                                <th scope="col" *ngIf="showTac">{{ "Device Model" | translate}}</th>
                                                                <th scope="col">{{ "Notification Event" | translate}}</th>
                                                                <th scope="col">{{ "Result Code" | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                            <div class="loader quantum-spinner m-auto"></div>
                                                        </tbody>
                                                        <tbody *ngIf="logList && logList.length <= 0 && !infoSpinner">
                                                            <tr>
                                                                <td colspan="5" class="text-center">{{ "No Data Found" | translate}}.</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="!infoSpinner">
                                                            <tr *ngFor="let log of logList">
                                                                <td>{{log.date}}</td>
                                                                <td>{{log.action}}</td>
                                                                <td>{{log.data.status}}</td>
                                                                <td>{{log.data.eid}}</td>
                                                                <td *ngIf="showTac">{{log.data.tac}}</td>
                                                                <td *ngIf="showTac">{{log.data.model}}</td>
                                                                <td>{{log.data.notificationEvent}}</td>
                                                                <td>{{log.data.resultCode}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="tab-pane fade" id="info-tac_{{i}}" role="tabpanel" aria-labelledby="info-tac-tab" *ngIf="features && features[getFeatureIndex('tac_info', 'profile')].view">
                                                    <table class="table t-table t-filter-inner-table table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{{ "TAC" | translate}}</th>
                                                                <th scope="col">{{ "Brand" | translate}}</th>
                                                                <th scope="col">{{ "Manufacturer" | translate}}</th>
                                                                <th scope="col">{{ "Model" | translate}}</th>
                                                                <th scope="col">{{ "Name" | translate}}</th>
                                                                <th scope="col">{{ "NFC" | translate}}</th>
                                                                <th scope="col">{{ "OS" | translate}}</th>
                                                                <th scope="col">{{ "Type" | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                            <div class="loader quantum-spinner m-auto"></div>
                                                        </tbody>
                                                        <tbody *ngIf="tac_info && tac_info.length <= 0 && !infoSpinner">
                                                            <tr>
                                                                <td colspan="5" class="text-center">{{ "No Data Found" | translate}}.</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="!infoSpinner">
                                                            <tr *ngFor="let info of tac_info">
                                                                <td>{{info.tac}}</td>
                                                                <td>{{info.brand}}</td>
                                                                <td>{{info.manufacturer}}</td>
                                                                <td>{{info.model}}</td>
                                                                <td>{{info.name}}</td>
                                                                <td>{{info.nfc}}</td>
                                                                <td>{{info.os}}</td>
                                                                <td>{{info.type}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="tab-pane fade" id="info-opr_{{i}}" role="tabpanel" aria-labelledby="info-opr-tab" *ngIf="features && features[getFeatureIndex('operations', 'profile')].view">
                                                    <table class="table t-table t-filter-inner-table table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{{ "Time Stamp" | translate}}</th>
                                                                <th scope="col">{{ "Action" | translate}}</th>
                                                                <th scope="col">{{ "User" | translate}}</th>
                                                                <th scope="col">{{ "Status" | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                            <div class="loader quantum-spinner m-auto"></div>
                                                        </tbody>
                                                        <tbody *ngIf="operationList && operationList.length <= 0 && !infoSpinner">
                                                            <tr>
                                                                <td colspan="5" class="text-center">{{ "No Data Found" | translate}}.</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="!infoSpinner">
                                                            <tr *ngFor="let opr of operationList">
                                                                <td>{{opr.created}}</td>
                                                                <td>{{opr.action}}</td>
                                                                <td>{{opr.email}}</td>
                                                                <td>{{opr.status}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="tab-pane fade" id="info-status_{{i}}" role="tabpanel" aria-labelledby="status-tac-tab">
                                                    <div class="profile-manage-table">
                                                        <div class="t-grid-table-new">
                                                            <div class="t-grid-status-table-wrapper t-head-bg">
                                                                <div class="t-box a border-lf-ext-light">{{'Status' | translate}}</div>
                                                                <div class="t-box b border-lf-ext-light border-rt-ext-light">{{'Release Type' | translate}}</div>
                                                            </div>
                                                            <div class="t-profile-status-overflow">
                                                                <div class="t-grid-status-table-wrapper t-body-bg border-bt-ext-light">
                                                                    <div class="t-box d t-font-light border-lf-ext-light">{{profile.state | titlecase}}</div>
                                                                    <div class="t-box d t-font-light border-lf-ext-light border-rt-ext-light">
                                                                        <span *ngIf="(profile.state === 'RELEASED' || profile.state === 'DOWNLOADED' || profile.state === 'INSTALLED' || profile.state === 'ENABLED') && (defaultSmdp != null && defaultSmdp)">Default SM-DP+</span>
                                                                        <span *ngIf="(profile.state === 'RELEASED' || profile.state === 'DOWNLOADED' || profile.state === 'INSTALLED' || profile.state === 'ENABLED') && (defaultSmdp != null && !defaultSmdp)">With QRCode</span>
                                                                        <span *ngIf="!((profile.state === 'RELEASED' || profile.state === 'DOWNLOADED' || profile.state === 'INSTALLED' || profile.state === 'ENABLED'))">NA</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="btn-spinner py-3" *ngIf="profileInfiniteSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- reset profile start -->

<div class="modal fade" id="resetProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Reset Profile" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <!-- <p [style.color]="'red'">This feature is temporarily unavailable. Please contact support to reset Group QR profile.</p> -->
                <p *ngIf="selectedIccidList.length === 1">{{ "ICCID" | translate}}: {{selectedIccidList[0].iccid}}</p>
                <p *ngIf="selectedIccidList.length > 1 || selectAllIccid">{{ "ICCID" | translate}}: {{selectAllIccid ? selectedProfileCount : selectedIccidList.length }} {{ "selected" | translate}}</p>
                <div class="form-group">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="resetOption">
                        <mat-radio-button class="example-radio-button mr-4" *ngFor="let option of resetOptions" [value]="option">
                            {{option}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3" data-toggle="modal" data-target="#resetConfirmation" (click)="openConfirmation()">{{ "Reset" | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="resetConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Reset Profile Confirmation" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <p>{{ "Do you want to confirm to Reset to" | translate}} {{resetOption}}?</p>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3" (click)="resetProfile()" [disabled]="disable" *ngIf="!btnSpinner">{{ "Confirm Reset" | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- reset profile end -->

<!-- download qr code start -->

<div class="modal fade" id="downloadQrCodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Download QR Code" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="logoId = '';closeOperations();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4  device-flex-body">
                <div>
                    <mat-radio-group aria-labelledby="example-radio-group-label" style="justify-content: space-between; padding: 0px 20px;" class="example-radio-group d-flex" [(ngModel)]="qrOption">
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[0]">
                            {{bulkQrCodeOptions[0] | translate}}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[1]" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                            {{bulkQrCodeOptions[1] | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="text-center mt-4" *ngIf="qrOption==='QR Code'">
                    <img src="assets/images/qr-code.png" style="width: 85px;" alt="">
                </div>

                <div class="d-flex mt-4 align-items-center justify-content-around" *ngIf="qrOption==='QR Code With Logo'" style="justify-content: space-between;">
                    <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                    <div class="text-font-radio-top ml-3"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                </div>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3" (click)="getQrCode()">{{ "Download" | translate}}</button>
            </div>
        </div>
    </div>
</div>

<!-- download qr code end -->

<!-- select logo start -->

<div class="modal fade" id="selectLogoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Select Logo" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="selectLogo();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="small text-danger" *ngIf="logoSelectErr">{{"Please select logo" | translate}}</div>
                <div *ngIf="logosArray && logosArray.length <= 0 && !allLogoSpinner">{{'No Logo Found' | translate}}!</div>
                <div class="main-logo-list" *ngIf="logosArray && logosArray.length > 0">
                    <div class="single-logo" *ngFor="let item of logosArray;let i = index;">
                        <div *ngIf="defaultLogoId" [ngClass]="{'default-icon': item._id === defaultLogoId}">
                            <i [ngClass]="{'fa fa-check': item._id === defaultLogoId}"></i>
                        </div>
                        <img src="{{'data:image/png;base64,' + item.data}}" id="profile-single-logo-{{i}}" class="img-logo cursor-p" (keypress)="logoSelectErr = false;" (click)="logoId = item._id; logoImg = 'data:image/png;base64,' + item.data; logoSelected = logoId === item._id ? true : false;toggleBorderClass(i);" alt="">
                    </div>
                </div>
                <div class="d-flex manage-logo">
                    <a class="btn logo-btn my-3 d-block d-flex mr-3" [ngClass]="{'logo-btn': !logoSelected, 'disable-logo-btn': logoSelected }" (click)="uploadQrCodeLogo()">
                        {{ "Upload" | translate}}
                        <input id="qr-code-logo" type="file" style="display: none" accept=".png,.jpg" (change)="onQrLogoChange($event)">
                        <svg class="ml-2 svg-t-size cursor-p" xmlns="http://www.w3.org/2000/svg" width="13.26" height="22.595" viewBox="0 0 19.26 22.595"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-37.781)"><g transform="translate(46.692)"><g transform="translate(0)"><path class="a" d="M240.577,0a.862.862,0,0,0-.862.862V14.546a.862.862,0,0,0,1.725,0V.862A.862.862,0,0,0,240.577,0Z" transform="translate(-239.715 0)"/></g></g><g transform="translate(41.92 0)"><path class="a" d="M142.577,4.688,137.805.232a.863.863,0,0,0-1.177,0l-4.772,4.456a.862.862,0,1,0,1.177,1.261l4.183-3.906L141.4,5.949a.862.862,0,0,0,1.177-1.261Z" transform="translate(-131.582 0)"/></g><g transform="translate(37.781 7.359)"><g transform="translate(0)"><path class="a" d="M56.179,166.758h-4.6a.862.862,0,1,0,0,1.725h3.737v11.786H39.506V168.483h3.737a.862.862,0,0,0,0-1.725h-4.6a.862.862,0,0,0-.862.862v13.511a.862.862,0,0,0,.862.862H56.179a.862.862,0,0,0,.862-.862V167.62A.862.862,0,0,0,56.179,166.758Z" transform="translate(-37.781 -166.758)"/></g></g></g></svg>
                    </a>
                    <button class="btn logo-btn btn-block my-3 mr-3" style="width: 655px;" [ngClass]="{'logo-btn': logoSelected, 'disable-logo-btn': !logoSelected }" (click)="setDefaultLogo();">{{ "Set As Default" | translate}}<i class="fa fa-check ml-2"></i></button>
                    <button class="btn logo-btn btn-block my-3" [ngClass]="{'logo-btn': logoSelected, 'disable-logo-btn': !logoSelected }" (click)="deleteLogo();">{{ "Delete" | translate}}<i class="fa fa-trash ml-2"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- select logo end -->
