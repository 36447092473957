import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getFileHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
      })
    };
    return httpOptions;
  }

  getProfiles(filter, filteTerms) {
    return this.http.post(`${this.serverUrl}/filter/${filter}`, filteTerms, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileTypeList(includeGroupQR: boolean = false) {
    return this.http.get(`${this.serverUrl}/getProfileTypeList?includeGroupQR=${includeGroupQR}`, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileTypeListForGroup () {
    return this.http.get(`${this.serverUrl}/getProfileTypeListForGroup`, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileTypeListAssignedToGroupQR(){
    return this.http.get(`${this.serverUrl}/getProfileTypeListAssignedToGroupQR`, this.getHeader())
    .pipe(map(response => response));
  }

  getLogs(iccid) {
    return this.http.post(`${this.serverUrl}/getLogs`, iccid, this.getHeader())
      .pipe(map(response => response));
  }

  getOperations(iccid) {
    return this.http.post(`${this.serverUrl}/report/operation`, iccid, this.getHeader())
      .pipe(map(response => response));
  }

  getTacInfo(iccid) {
    return this.http.post(`${this.serverUrl}/getICCIDTac`, iccid, this.getHeader())
      .pipe(map(response => response));
  }

  getQrCode(iccid, logoId, qrCodeWithLogo) {
    return this.http.get(`${this.serverUrl}/getQRCode?iccid=${iccid}&logoId=${logoId}&qrCodeWithLogo=${qrCodeWithLogo}`, this.getHeader())
      .pipe(map(response => response));
  }

  allIccidOperation(profileData) {
    return this.http.post(`${this.serverUrl}/selectAll`, profileData, this.getHeader())
        .pipe(map(response => response));
  }

  prepareProfile(profileData, iccid) {
    if (iccid) {
      return this.http.post(`${this.serverUrl}/prepareProfile/${iccid}`, profileData, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/prepareProfile`, profileData, this.getHeader())
        .pipe(map(response => response));
    }
  }

  prepareProfileWithDevice(profileData, iccid) {
    if (iccid) {
      return this.http.post(`${this.serverUrl}/prepareProfileEID/${iccid}`, profileData, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/prepareProfileEID`, profileData, this.getHeader())
        .pipe(map(response => response));
    }
  }

  prepareProfileWithDeviceUsingSMDP(profileData, iccid) {
    if (iccid) {
      console.log(this);
      return this.http.post(`${this.serverUrl}/prepareProfileEIDUsingSMDP/${iccid}`, profileData, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/prepareProfileEID`, profileData, this.getHeader())
        .pipe(map(response => response));
    }
  }

  resetProfile(resetData, iccid) {
    if (iccid) {
      return this.http.post(`${this.serverUrl}/resetProfile/${iccid}`, resetData, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/resetProfile`, resetData, this.getHeader())
        .pipe(map(response => response));
    }
  }

  qrCode(data, iccid) {
    if (iccid) {
      return this.http.post(`${this.serverUrl}/getQRCode/${iccid}`, data, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/getQRCode`, data, this.getHeader())
        .pipe(map(response => response));
    }
  }

  generateMultipleQrCode(data) {
    return this.http.post(`${this.serverUrl}/multiQRCode`, data, this.getFileHeader())
      .pipe(map(response => response));
  }

  getLogos() {
    return this.http.post(`${this.serverUrl}/logos`, {}, this.getFileHeader())
      .pipe(map(response => response));
  }

  uploadQrLogo(file) {
    return this.http.post(`${this.serverUrl}/logos/upload`, file, this.getFileHeader())
      .pipe(map(response => response));
  }

  syncInventory(data) {
    return this.http.post(`${this.serverUrl}/syncInventory`, data, this.getFileHeader())
      .pipe(map(response => response));
  }

  changePRPSetting(data) {
    return this.http.post(`${this.serverUrl}/profile/changePRPSetting`, data, this.getFileHeader())
      .pipe(map(response => response));
  }

  downloadICCIDList(data, term, type) {
    if(term) {
      return this.http.post(`${this.serverUrl}/downloadICCIDList/${term}?type=${type}`, data, this.getHeader())
      .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/downloadICCIDList?type=${type}`, data, this.getHeader())
          .pipe(map(response => response));
    }
  }

  downloadJob(jobId) {
    const authToken = localStorage.getItem('authToken');

    let headers = new HttpHeaders({
      Authorization: `Bearer ${authToken}`, // Auth header
    });

    this.http.get(`${this.serverUrl}/downloadJob/${jobId}`, {headers, responseType: "blob"})
      .toPromise()
      .then(blob => {
          saveAs(blob);
      })
      .catch(err => console.error("download error = ", err));
  }

  getICCIDInfo(iccid) {
    return this.http.post(`${this.serverUrl}/getICCIDInfo`, iccid, this.getHeader())
      .pipe(map(response => response));
  }

  getICCIDPRPSetting(iccidlist) {
    return this.http.post(`${this.serverUrl}/profile/getICCIDPRPSetting`, {iccidlist}, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileTypePRPSetting(profileType, option, customerId, groupArray){
    return this.http.post(`${this.serverUrl}/profile/getProfileTypePRPSetting`, {profileType, option, customerId, groupArray}, this.getHeader())
      .pipe(map(response => response));
  }
}