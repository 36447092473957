import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../shared/services/profile.service';
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { ReportService } from '../../shared/services/report.service';
import { CommunicationService } from '../../shared/services/communication.service';
import * as papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CookieTrailService } from '../../shared/services/cookie-trail.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../shared/stores/user/user.reducer';
import { GroupMgmtService } from '../../shared/services/group-mgmt.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import { timezones } from '../report';
import { Subscription } from 'rxjs';
import * as UserAction from '../../shared/stores/user/user.action'
import { PageEvent } from '@angular/material/paginator';
declare var $: any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-detail-download',
  templateUrl: './detail-download.component.html',
  styleUrls: ['./detail-download.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class DetailDownloadComponent implements OnInit {

  profileTypeList;
  detailSelectedProfileTypes = [];
  detailProfileDropdownSettings =  {
    singleSelection: false,
    idField: 'name',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'name',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  detailReportList;
  generateReportSpinner = false;
  sendEmailSpinner = false;
  downloadReportSpinner = false;
  user;
  selectProfileFlg = false;
  groupList;
  selectedGroups;
  selectedIdGroups = [];
  groupDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'groupName',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  tempSelectedProfileTypes = [];
  show = false;
  dateRange = 'month';
  public selectedMoments = [
    moment(new Date()).subtract(14, 'day'),
    moment(new Date())
  ];
  timezones = timezones;
  selectedTimezone = 'Etc/UTC';
  timezoneSubscription: Subscription;
  sortOrderOption = [
    { name: 'ICCID', order: true, value: 'ICCID', selected: true },
    { name: 'PROFILE TYPE', order: true, value: 'PROFILE_TYPE', selected: true },
    // { name: 'CURRENT STATE', order: true, value: 'CURRENT STATE', selected: true },
    { name: 'RELEASE TIMESTAMP', order: true, value: 'RELEASE TIMESTAMP', selected: true },
    { name: 'EID', order: true, value: 'EID', selected: true },
    { name: 'TAC', order: true, value: 'TAC', selected: true },
    { name: 'IMEI', order: true, value: 'IMEI', selected: true },
    { name: 'DOWNLOAD RESULT', order: true, value: 'DOWNLOAD RESULT', selected: false },
    { name: 'COMPLETION STATUS', order: true, value: 'COMPLETION STATUS', selected: false },
    { name: 'DOWNLOAD TRANSACTION START TIMESTAMP', order: true, value: 'DOWNLOAD TRANSACTION START TIMESTAMP', selected: false },
    { name: 'INSTALLATION CONFIRMATION TIMESTAMP', order: true, value: 'INSTALLATION CONFIRMATION TIMESTAMP', selected: false },
    { name: 'LAST UPDATE TIMESTAMP', order: true, value: 'LAST UPDATE TIMESTAMP', selected: false },
  ];
  sortBy = 'ICCID';
  orderBy = 'ASC';
  gmtTimeZoneName;
  tableLoader = false;
  language;
  activeTableColumns = [];
  columnConfig = ['150px', '150px', '1fr', '1fr', '1fr', '1fr'];
  // MatPaginator Inputs
  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50, 100, 150, 200];

  // MatPaginator Output
  pageEvent: PageEvent;
  downloadOptions = ['Generate a single-file report', 'Generate separate report files by month'];
  downloadOption = 'Generate a single-file report';
  estimateFileSize = 0.4;
  estimateDuration = 1;
  estimateTimeBlock = 'minutes';
  customMessage = '';
  customMessageBold = '';
  customMessageEnd = '';
  constructor(
    private profileService: ProfileService,
    private reportService: ReportService,
    private communicationService: CommunicationService,
    private cookieTrailService: CookieTrailService,
    private userStore: Store<UserState>,
    private groupService: GroupMgmtService,
    private translate: TranslateService,
    private userService: UserService) {
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      this.language = localStorage.getItem('language');
      if (!this.language) {
        this.language = 'en';
        localStorage.setItem('language', this.language);
      }
      translate.use(this.language.match(environment.langRegex) ? this.language : 'en');

      translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.detailProfileDropdownSettings.selectAllText = res;
        this.groupDropdownSettings.selectAllText = res;
        this.refreshDropSettings();
      });
      translate.get('Search').subscribe((res: string) => {
        this.show = false;
        this.detailProfileDropdownSettings.searchPlaceholderText = res;
        this.groupDropdownSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });
      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.detailProfileDropdownSettings.unSelectAllText = res;
        this.groupDropdownSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.detailProfileDropdownSettings.noDataAvailablePlaceholderText = res;
        this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });

      this.activeTableColumns = this.sortOrderOption.filter(col => col.selected).map(col => col.name);
    }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});

    this.userStore.select('user')
      .subscribe((res: any) => {
        if(res) {
          this.user = Object.assign({}, res);
          this.selectedTimezone = res.data.timezone;
          this.gmtTimeZoneName = this.timezones[this.timezones.findIndex(ele => ele.timezonekey === this.selectedTimezone)].timezoneName.split(')')[0].replace('UTC', 'GMT') + ')';
          if(!this.user.data.currentCustomer && (this.user.data.rootCustomer && this.user.data.rootCustomer.isParent)) {
            this.selectProfileFlg = true;
            this.getProfileTypes();
          } else if(this.user.data.currentCustomer && this.user.data.currentCustomer.isParent){
            this.selectProfileFlg = true;
            this.getProfileTypes();
          } else {
            this.selectProfileFlg = false;
            this.getAllCustomerGroup();
          }
        }
      });

    this.getDetailedDownloadReport(null);

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });
  }

  openOperations() {
    this.sendEmail(true).then((res: any) => {
      if (res.message) {
        if (res.messageBold && res.messageEnd) {
            this.customMessage = res.message;
            this.customMessageBold = res.messageBold;
            this.customMessageEnd = res.messageEnd;
            $('#downloadReportModalMessage').modal('show');
        } else {
            this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }
      } else {
        this.estimateFileSize = res.estimateFileSize;
        this.estimateDuration = res.estimateDuration;
        this.estimateTimeBlock = res.estimateTimeBlock;
        $('#downloadReportModal').modal('show');
      }
    })
  }

  closeOperations() {
    $('#downloadReportModal').modal('hide');
    $('#downloadReportModalMessage').modal('hide');
  }

  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  updateTableColumns (item){
    item.selected = !item.selected;
    this.sortOrderOption = this.sortOrderOption.map(field => {
      if(field.name === item.name){
        field.selected = item.selected;
      }
      return field;
    })
    this.activeTableColumns = this.sortOrderOption.filter(i => i.selected).map(i => i.name);
    this.columnConfig = this.activeTableColumns.map((v, i) => {
      if (i === 0 || i === 1) {
        return '200px';
      }
      return '1fr';
    })
  }

  changeLanguage() {
    this.language = localStorage.getItem('language');
    this.translate.use(this.language);

    this.translate.get('Select All').subscribe((res: string) => {
      this.show = false;
      this.detailProfileDropdownSettings.selectAllText = res;
      this.groupDropdownSettings.selectAllText = res;
      this.refreshDropSettings();
    });
    this.translate.get('Search').subscribe((res: string) => {
      this.show = false;
      this.detailProfileDropdownSettings.searchPlaceholderText = res;
      this.groupDropdownSettings.searchPlaceholderText = res;
      this.refreshDropSettings();
    });
    this.translate.get('UnSelect All').subscribe((res: string) => {
      this.show = false;
      this.detailProfileDropdownSettings.unSelectAllText = res;
      this.groupDropdownSettings.unSelectAllText = res;
      this.refreshDropSettings();
    });
    this.translate.get('No data available').subscribe((res: string) => {
      this.show = false;
      this.detailProfileDropdownSettings.noDataAvailablePlaceholderText = res;
      this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
      this.refreshDropSettings();
    });
  }

  sortList(sortBy, orderBy){
    this.tableLoader = true;

    setTimeout(() => {
      if (sortBy === 'ICCID'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
              if(a.iccid) {
                return   orderBy === 'ASC' ? a.iccid.localeCompare( b.iccid) : -( a.iccid.localeCompare(  b.iccid));
              }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.iccid) {
              return   orderBy === 'DESC' ? b.iccid.localeCompare( a.iccid) : -( b.iccid.localeCompare(  a.iccid));
            }
          });
        }
      }
      if (sortBy === 'PROFILE_TYPE'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.profileType) {
              return   orderBy === 'ASC' ? a.profileType.localeCompare( b.profileType) : -( a.profileType.localeCompare(  b.profileType));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.profileType) {
              return   orderBy === 'DESC' ? b.profileType.localeCompare( a.profileType) : -( b.profileType.localeCompare(  a.profileType));
            }
          });
        }
      }
      if (sortBy === 'CURRENT STATE'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.currentState) {
              return   orderBy === 'ASC' ? a.currentState.localeCompare( b.currentState) : -( a.currentState.localeCompare(  b.currentState));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.currentState) {
              return   orderBy === 'DESC' ? b.currentState.localeCompare( a.currentState) : -( b.currentState.localeCompare(  a.currentState));
            }
          });
        }
      }
      if (sortBy === 'RELEASE TIMESTAMP'){
        const releaseTimestampData = [];
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.releaseTimestamp) {
              return   orderBy === 'ASC' ? a.releaseTimestamp.localeCompare( b.releaseTimestamp) : -( a.releaseTimestamp.localeCompare(b.releaseTimestamp));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.releaseTimestamp) {
              return orderBy === 'DESC' ? b.releaseTimestamp.localeCompare( a.releaseTimestamp) : -( b.releaseTimestamp.localeCompare(a.releaseTimestamp));
            }
          });
        }
      }
      if (sortBy === 'EID'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.eid) {
              return   orderBy === 'ASC' ? a.eid.localeCompare( b.eid) : -( a.eid.localeCompare(  b.eid));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.eid) {
              return   orderBy === 'DESC' ? b.eid.localeCompare( a.eid) : -( b.eid.localeCompare(  a.eid));
            }
          });
        }
      }
      if (sortBy === 'TAC'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.tac) {
              return   orderBy === 'ASC' ? a.tac.localeCompare( b.tac) : -( a.tac.localeCompare(  b.tac));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.tac) {
              return   orderBy === 'DESC' ? b.tac.localeCompare( a.tac) : -( b.tac.localeCompare(  a.tac));
            }
          });
        }
      }
      if (sortBy === 'IMEI'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.imei) {
              return   orderBy === 'ASC' ? a.imei.localeCompare( b.imei) : -( a.imei.localeCompare(  b.imei));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.imei) {
              return   orderBy === 'DESC' ? b.imei.localeCompare( a.imei) : -( b.imei.localeCompare(  a.imei));
            }
          });
        }
      }
      if (sortBy === 'DOWNLOAD RESULT'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.downloadResult) {
              return   orderBy === 'ASC' ? a.downloadResult.localeCompare( b.downloadResult) : -( a.downloadResult.localeCompare(  b.downloadResult));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.downloadResult) {
              return   orderBy === 'DESC' ? b.downloadResult.localeCompare( a.downloadResult) : -( b.downloadResult.localeCompare(  a.downloadResult));
            }
          });
        }
      }
      if (sortBy === 'COMPLETION STATUS'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.completionStatus) {
              return   orderBy === 'ASC' ? a.completionStatus.localeCompare( b.completionStatus) : -( a.completionStatus.localeCompare(  b.completionStatus));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.completionStatus) {
              return   orderBy === 'DESC' ? b.completionStatus.localeCompare( a.completionStatus) : -( b.completionStatus.localeCompare(  a.completionStatus));
            }
          });
        }
      }
      if (sortBy === 'DOWNLOAD TRANSACTION START TIMESTAMP'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.downloadTimestamp) {
              return   orderBy === 'ASC' ? a.downloadTimestamp.localeCompare( b.downloadTimestamp) : -( a.downloadTimestamp.localeCompare(  b.downloadTimestamp));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.downloadTimestamp) {
              return   orderBy === 'DESC' ? b.downloadTimestamp.localeCompare( a.downloadTimestamp) : -( b.downloadTimestamp.localeCompare(  a.downloadTimestamp));
            }
          });
        }
      }
      if (sortBy === 'INSTALLATION CONFIRMATION TIMESTAMP'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.installationTimestamp) {
              return   orderBy === 'ASC' ? a.installationTimestamp.localeCompare( b.installationTimestamp) : -( a.installationTimestamp.localeCompare(  b.installationTimestamp));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.installationTimestamp) {
              return   orderBy === 'DESC' ? b.installationTimestamp.localeCompare( a.installationTimestamp) : -( b.installationTimestamp.localeCompare(  a.installationTimestamp));
            }
          });
        }
      }
      if (sortBy === 'LAST UPDATE TIMESTAMP'){
        if ( orderBy === 'ASC') {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(a.lastUpdateTimestamp) {
              return   orderBy === 'ASC' ? a.lastUpdateTimestamp.localeCompare( b.lastUpdateTimestamp) : -( a.lastUpdateTimestamp.localeCompare(  b.lastUpdateTimestamp));
            }
            });
        } else {
          const sortedUsers = this.detailReportList.sort((a, b) => {
            if(b.lastUpdateTimestamp) {
              return   orderBy === 'DESC' ? b.lastUpdateTimestamp.localeCompare( a.lastUpdateTimestamp) : -( b.lastUpdateTimestamp.localeCompare(  a.lastUpdateTimestamp));
            }
          });
        }
      }
      this.tableLoader = false;
    }, 10);

  }
  getAllCustomerGroup() {
    this.groupService.getCustomerUserGroups()
      .subscribe((res: any) => {
        this.groupList = res;
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getProfileTypes() {
    this.profileService.getProfileTypeList(true)
      .subscribe((res: any) => {
        this.profileTypeList = res.profileTypes;
      }, err => {
        const cookieTrail = {
          screenName: 'Detail Download Report',
          action: 'Get Profile Types',
          error: err.error || 'Something went wrong!',
        };

        this.cookieTrailService.saveCookie(cookieTrail);
      });
  }

  async createGroupId(index) {
    this.selectedIdGroups.push(this.selectedGroups[index]._id);
  }

  async getDetailedDownloadReport(event? : PageEvent) : Promise<any> {

    let { pageIndex = this.pageIndex, pageSize = this.pageSize } = event || {}

    if(event) {
      this.pageIndex = pageIndex;
      this.pageSize = pageSize;
    }

    this.tempSelectedProfileTypes = [];
    if(this.detailSelectedProfileTypes && this.detailSelectedProfileTypes.length > 0) {
      for(let i = 0; i < this.detailSelectedProfileTypes.length; i++) {
        this.createProfileTypes(this.detailSelectedProfileTypes[i].name);
      }
    }

    if(this.selectedGroups && this.selectedGroups.length > 0) {
      for(let i = 0; i < this.selectedGroups.length; i++) {
        await this.createGroupId(i);
      }
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    const data = {
      profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
      groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
      fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      dateRange: this.dateRange,
      timezoneName,
      timezonekey: this.selectedTimezone,
      pageIndex,
      pageSize
    }

    if (event) {
      const cookieTrail = {
        screenName: 'Detail Download Report',
        action: 'Get Detail Download Report',
        body: data,
      };

      this.cookieTrailService.saveCookie(cookieTrail);
    }

    this.reportService.getDetailDownloadReport(data)
      .subscribe((res: any) => {
        const { result = [], page, length = 0 } = res || {};
        this.generateReportSpinner = false;
        this.tableLoader = false;
        this.detailReportList = result;
        this.length = length;
        this.sortList(this.sortBy, this.orderBy);
      }, err => {
        const cookieTrail = {
          screenName: 'Detail Download Report',
          action: 'Get Detail Download Report',
          body: data,
          error: err.error || 'Something went wrong!',
        };

        this.cookieTrailService.saveCookie(cookieTrail);
        this.generateReportSpinner = false;
        this.tableLoader = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    this.pageEvent = event;
  }

  async sendEmail(fileInfo = false) {
    this.tempSelectedProfileTypes = [];
    if(this.detailSelectedProfileTypes && this.detailSelectedProfileTypes.length > 0) {
      for(let i = 0; i < this.detailSelectedProfileTypes.length; i++) {
        this.createProfileTypes(this.detailSelectedProfileTypes[i].name);
      }
    }

    if(this.selectedGroups && this.selectedGroups.length > 0) {
      for(let i = 0; i < this.selectedGroups.length; i++) {
        await this.createGroupId(i);
      }
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    const data = {
      profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
      groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
      fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      dateRange: this.dateRange,
      timezoneName,
      timezonekey: this.selectedTimezone,
      bulk: this.downloadOptions.indexOf(this.downloadOption) === 0,
      fileInfo,
    }

    const cookieTrail = {
      screenName: 'Detail Download Report',
      action: 'Send Detail Download Report',
      body: data
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.sendEmailSpinner = true;

    return new Promise((resolve, reject) => {
      this.reportService.sendDetailDownloadReport(data)
        .subscribe((res: any) => {
          this.sendEmailSpinner = false;
          if (!fileInfo) {
            if (res.messageBold && res.messageEnd) {
                this.customMessage = res.message;
                this.customMessageBold = res.messageBold;
                this.customMessageEnd = res.messageEnd;
                $('#downloadReportModalMessage').modal('show');
            } else {
                this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
            }
          }
          resolve(res);
        }, err => {
          this.sendEmailSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          reject(err);
        });
    })
  }

  downloadReport() {
    const tempList = [];
    const fileName = `Detail Download Report.txt`;
    this.detailReportList.forEach(element => {
      const obj = {
        Iccid: element.iccid,
        'PROFILE TYPE': element.profileType,
        'CURRENT STATE': element.currentState,
        'RELEASE TIMESTAMP': element.releaseTimestamp,
        EID: element.eid,
        TAC: element.tac,
        IMEI: element.imei,
        'DOWNLOAD RESULT': element.downloadResult,
        'COMPLETION STATUS': element.completionStatus,
        'DOWNLOAD TRANSACTION START TIMESTAMP': element.downloadTimestamp,
        'INSTALLATION CONFIRMATION TIMESTAMP': element.installationTimestamp,
        'LAST UPDATE TIMESTAMP': element.lastUpdateTimestamp
      };
      tempList.push(obj);
    });

    const cookieTrail = {
      screenName: 'Detail Download Report',
      action: 'Send Detail Download Report',
      body: tempList
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
    saveAs(blob, fileName);
  }

  async createProfileTypes(name) {
    this.tempSelectedProfileTypes.push(name);
  }


  setTimezone() {
    if(this.timezoneSubscription) {
      this.timezoneSubscription.unsubscribe();
    }
    this.timezoneSubscription = this.userService.setTimezone({timezone: this.selectedTimezone})
      .subscribe((res: any) => {
        this.user.data = Object.assign({...this.user.data}, {timezone: this.selectedTimezone});
        this.userStore.dispatch(new UserAction.GetUser(this.user));
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }
}
