import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../shared/services/communication.service';
import { SupportService } from '../shared/services/support.service';
import { Store } from '@ngrx/store';
import { UserState } from '../shared/stores/user/user.reducer';
import { RoutingStateService } from '../shared/services/routing-state.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  ticketTypes = ['query', 'feedback', 'issue'];
  selectedTicketType = 'query';
  ticketList = [];
  ticketSpinner = false;
  btnSpinner = false;
  description;
  descErr = false;
  user;
  previousRoute;
  cookieTrail;

  constructor(
    private communicationService: CommunicationService,
    private supportService: SupportService,
    private userStore: Store<UserState>,
    private routingState: RoutingStateService,
    private translate: TranslateService) { 

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {
    this.getAllTickets();
    
    if (!this.previousRoute) {
      this.previousRoute = this.routingState.onLoadGetPreviousUrl();
      if (this.previousRoute === '/index' && !localStorage.getItem('previousRoute')) {
        this.previousRoute = '/';
        localStorage.setItem('previousRoute', this.previousRoute);
      } else if (this.previousRoute === '/index' && localStorage.getItem('previousRoute')) {
        localStorage.setItem('previousRoute', localStorage.getItem('previousRoute'));
      } else if (this.previousRoute !== '/sign' && this.previousRoute !== '/signup' && this.previousRoute !== '/signup-password'
        && this.previousRoute !== '/forget-password' && this.previousRoute !== 'reset-password') {
        localStorage.setItem('previousRoute', this.previousRoute);
      }
    }

    this.cookieTrail = JSON.parse(localStorage.getItem('cookieTrail'));
    this.cookieTrail.previousRoute = window.origin + localStorage.getItem('previousRoute');

    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.user = user.data;
        }
      });

      this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });
  }

  changeLanguage() {
    this.translate.use(localStorage.getItem('language'));
  }

  getAllTickets() {
    this.ticketSpinner = true;
    this.supportService.getAllTickets()
      .subscribe((res: any) => {
        this.ticketSpinner = false;
        this.ticketList = res;
      }, err => {
        this.ticketSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something Went Wrong!'});
      });
  }

  ticketSubmmitConfirmation() {
    if(!this.description || (this.description && !this.description.trim())) {
      this.descErr = true;
      return;
    }

    $('#ticketSubmitConfirmationModal').modal();
  }

  submitTicket() {
    this.btnSpinner = true;

    const data = {
      type: this.selectedTicketType,
      description: this.description,
      cookieTrail: this.cookieTrail
    };
    
    this.supportService.submitTicket(data)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.ticketList.unshift(res);
        this.description = '';
        this.descErr = false;
        this.selectedTicketType = 'query';
        $('#ticketSubmitConfirmationModal').modal('hide');
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Ticket submitted successfully!'});
      }, err => {
        this.btnSpinner = false;
        $('#ticketSubmitConfirmationModal').modal('hide');
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something Went Wrong!'});
      })
  }

  isSpace(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode === 32 && (!this.description || (this.description && !this.description.trim()))) {
        return false;
      }

    return true;
  }
}
