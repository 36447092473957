import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ProfileTroubleshootingComponent } from './profile-troubleshooting.component';
import { ResizableModule } from 'angular-resizable-element';
import { MatTableModule } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CdkTableModule } from '@angular/cdk/table';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        ProfileTroubleshootingComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        ResizableModule,
        CdkTableModule,
        MatTableModule,
        ScrollingModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
            }
          }),
    ],
    exports: [
        ProfileTroubleshootingComponent
    ]
})
export class ProfileTroubleshootingModule { }
