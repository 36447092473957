import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Shape, MousePosition } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

// import { Field } from 'dynaform';

@Component({
    selector: 'app-shape',
    templateUrl: './shape.component.html',
    styleUrls: ['./shape.component.css']
})
export class ShapeComponent implements OnInit {

    @ViewChild('shapeTemplate', {static: true}) shapeTemplate: TemplateRef<any>;

    formFields = [];

    public shape: Shape;
    shapeType: ShapeType;
    offset: MousePosition;
    isSelected = false;
    selectionPoints: MousePosition[] = [];
    lineSecPoint = {
        x2: 0,
        y2: 0
    };

    constructor() {
        // console.log('ShapeComponent constructor');
    }

    ngOnInit() {
        // console.log('ShapeComponent ngOnInit');
    }

    getFormFields() {
        return this.formFields;
    }

    updateShapeProperties(value: any) {
        // console.log(value);
        // console.log('ShapeComponent : updateShapeProperties');
    }

    startDrawing(beginPosition: MousePosition): void {
        // console.log('ShapeComponent: startDrawing at ', beginPosition);
    }

    endDrawing(): void {
        // console.log('ShapeComponent: endDrawing()');
    }

    draw(currentPosition: MousePosition): void {
        // console.log('ShapeComponent: draw at ', currentPosition);
    }

    setPoint(point: MousePosition): void {
        // console.log('ShapeComponent: setPoint at ', point);
    }

    drag(draqPosition: MousePosition): void {
        // this.lineSecPoint = {
        //     x2: 0,
        //     y2: 0
        // };

        if (this.offset === undefined) {
            this.offset = Object.assign({}, draqPosition);
            this.offset.x -= this.shape.originX;
            this.offset.y -= this.shape.originY;
            if (this.shape.shapeProperties.name.includes('line')) { 
                this.lineSecPoint.x2 = this.shape.originX - this.shape.x2;
                this.lineSecPoint.y2 = this.shape.originY - this.shape.y2;
            }
        }
        if (this.shape.shapeProperties.name.includes('Triangle')) {
            this.shape.x1 = (draqPosition.x - this.shape.x1);
            this.shape.y1 = (draqPosition.y - this.shape.y1);
            // this.shape.y1 = beginPosition.x + 50;
            // this.shape.y2 = beginPosition.y + 85;
            // this.shape.z1 = beginPosition.x - 50;
            // this.shape.z2 = beginPosition.y + 85;
        } else {
            this.shape.originX = (draqPosition.x - this.offset.x);
            this.shape.originY = (draqPosition.y - this.offset.y);
            if (this.shape.shapeProperties.name.includes('line')) { 
                this.shape.x2 = (this.shape.originX) + this.lineSecPoint.x2;
                this.shape.y2 = (this.shape.originY) + this.lineSecPoint.y2;
            }
        }
    }

    resizeShape(resizePosition: MousePosition) {
        // console.log('ShapeComponent: resizeShape ', resizePosition);
    }

}
