import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { ProfileService } from '../shared/services/profile.service';
import { CommunicationService } from '../shared/services/communication.service';
import { saveAs } from 'file-saver';
import { Store } from '@ngrx/store';
import { FilterState } from '../shared/stores/filter/filter.reducer';
import JSZip from 'jszip';
import * as papa from 'papaparse';
import { ApiService } from '../shared/services/api.service';
import { Subscription } from 'rxjs';
import { UserState } from '../shared/stores/user/user.reducer';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { PdfGeneratorService } from '../shared/services/pdf-generator.service';
import { UiCustomizationService } from '../shared/services/ui-customization.service';
import { environment } from '../../environments/environment';
import { UserService } from '../shared/services/user.service';

declare var $: any;
type BigInt = number;
declare const BigInt: typeof Number;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class ProfileComponent implements OnInit, OnDestroy {

  @ViewChild('apiElementView', { read: ElementRef, static: true }) apiElementView: ElementRef;
  setBtnLabel= 'Aceptar';
  fromDate;
  toDate;
  flg = true;
  searchTerm = '';
  profileList;
  infiniteProfileList;
  statusArray = [
    { checked: false, key: 'Available' },
    { checked: false, key: 'Allocated' },
    { checked: false, key: 'Linked' },
    { checked: false, key: 'Confirmed' },
    { checked: false, key: 'Released' },
    { checked: false, key: 'Downloaded' },
    { checked: false, key: 'Installed' },
    { checked: false, key: 'Enabled' },
    { checked: false, key: 'Deleted' },
    { checked: false, key: 'Error' },
    { checked: false, key: 'Unavailable' }
  ];
  selectedStatusArr = [];
  sortOrderOption = [
    { name: 'ICCID', order: true, value: 'ICCID' },
    { name: 'STATUS', order: true, value: 'STATUS' },
    { name: 'PROFILE_TYPE', order: true, value: 'PROFILE_TYPE' },
    { name: 'LAST_UPDATE', order: true, value: 'LAST_UPDATE' },
  ];
  sortBy = 'ICCID';
  orderBy = 'ASC';
  lockStatus;
  filterArray = [];
  getProfileTypeList = [];
  profileType;
  select = false;
  selectAllIccid = false;
  @ViewChild('myName', { static: true }) myName;
  logList = [];
  profileSpinner = true;
  infoSpinner = true;
  selectedCount = 0;
  selectedIccidList = [];
  confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
  confirmCode = 'Fixed Confirmation Code';
  confirmationCodeFlgs = ['Yes', 'No'];
  confirmationCodeFlg = 'No';
  dispayOrCodeValueFlgs = ['QR Code', 'QR Code with logo'];
  dispayOrCodeValueFlg = '';
  confirmationCode: any = 1234;
  errMsg = false;
  resetOptions = ['Available', 'Unavailable'];
  resetOption = 'Available';
  qrCodeOptions = [
    { name: 'Text', value: '1$RSP-0002.OBERTHUR.NET$CUMQE-AV6WL-GPIJ0-XVHI1', checked: true },
    { name: 'QR Code', value: 'assets/images/qr-code.png', checked: false },
    { name: 'QR Code with logo', value: 'assets/images/qr-code-with-logo.png', checked: false }
  ];

  textQr = true;
  qrCode = false;
  qrCodeWithLogo = false;
  // lpaText = false;
  // qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
  qrCodeWithLogoImg;
  qrCodeWithCampaign = false;
  templateList = [];
  selectedTempateId;
  campaignImg;
  campaignFormat = ['PNG', 'PDF'];
  selectedCampaignFormat = 'PNG';
  iccidInModal;
  qrOnModal;
  qrWithLogoOnModal;
  qrWithCampaignOnModal;

  qrCodeOption = 'Text';
  errLength = false;
  disable = false;
  prepareRes;
  btnSpinner = false;
  disableReset = false;
  filteTerms;
  onintFlg = false;
  qrEmail = false;
  qrAdditionalEmail = false;
  qrDownload = true;
  eid;
  errEidMsg = false;
  fileToUpload;
  iccidEidObj = [];
  disableUploadCsv = true;
  errEmailDwn = false;
  profileLockStatus = [
    { name: 'Lock', checked: false },
    { name: 'Unlock', checked: false }
  ];
  // selectFlg = false;
  throttle = 700;
  scrollDistance = 1;
  scrollUpDistance = 2;
  lastIccid;
  tempIccid;
  profileInfiniteSpinner = false;
  profileCount = 0;
  apiList;
  profileSubscribe: Subscription;
  operationList;
  infoLogSubscriber: Subscription;
  infoOprSubscriber: Subscription;
  infoTacSubscriber: Subscription;
  noElement;
  apiDisplay = false;
  bulkQrCodeFlg = 'File Upload';
  bulkQrCodeFlgs = ['File Upload', 'Manual'];
  qrFile;
  bulkQrDownload = true;
  bulkQR: FormGroup;
  submitted = false;
  features;
  emailAddress;
  apiFeatures;
  user;
  additionalEmailErr = false;
  isprod;
  logosArray = [];
  logoId;
  logoImg;
  logoSelectErr = false;
  qrCodeDownloadErr = false;
  bulkQrCodeOptions = ['QR Code', 'QR Code With Logo', 'QR Code With Campaign'];
  qrOption = 'QR Code';
  downloadQrCodeIccid;
  fileSize = 1024 * 1024;
  optionsTypes = [
    {
      optionType:'Profile Type',
      profileTypes: [],
      groups: []
    }, {
      optionType:'Upload File'
    }, {
      optionType:'Count'
  }];
  selectedOption;
  prpSettingOptions = ['PRP_5', 'PRP_2a'];
  selectedPRPOption = 'PRP_5';
  prpCounter;
  prpCounterErr = false;
  customerId;
  syncProfileTypes = [];
  selectedIccidCount = 0;
  selectedProfileTypes = [];
  syncGroups = [];
  selectedGroups = [];
  profileTypeErr = false;
  countErr = false;
  groupErr = false;
  prpBtnSpinner = false;
  startingIccidErr = false;
  fileUploadErr = false;
  startingIccid;
  count;
  iccidList = [];
  syncPrpBtnSpinner = false;
  syncPrepareBtnSpinner = false;
  syncResetBtnSpinner = false;
  syncQrBtnSpinner = false;
  selectedProfileCount = 0;
  deselectedIccidList = [];
  logoSelected = false;
  defaultLogoId;
  tempLogoId;
  selectedLogoIndex;
  allLogoSpinner = false;
  tac_info = [];
  showTac = true;
  releaseMethod = [
    {label: 'With QR Code', value: 'withQR'},
    {label: 'Default SM-DP+', value: 'defaultSMDP'},
  ]
  selectedReleaseMethod = 'withQR';
  statusSpinner = false;
  statusList = [];
  eidLengthError = false;
  eidFileName;
  defaultSmdp = null;
  fileNameErr = false;
  disableQrCode = false;
  iccidInfoSubscriber: Subscription;
  downloadWithoutDeviceLoader = false;
  downloadWithDeviceLoader = false;
  prpBtnSpinnerDownload = false;
  selectedIccidPrpType;
  selectedIccidPPrpCounter;
  languageChange;
  fieldList = [
    { name: 'Type', selected: true },
    { name: 'Status', selected: true },
    { name: 'Time-stamp', selected: true },
    { name: 'PRP', selected: true },
    { name: 'PRP Count', selected: true },
    { name: 'EID', selected: true },
    { name: 'TAC', selected: true },
    { name: 'Device', selected: true },
  ];
  activeTableColumns = [];
  dynamicStyle =  {
    width: [
      { name: 'Type', width: '1.4fr' },
      { name: 'Status', width: '1fr' },
      { name: 'Time-stamp', width: '1.5fr' },
      { name: 'PRP', width: '0.8fr' },
      { name: 'PRP Count', width: '0.8fr' },
      { name: 'EID', width: '0.8fr' },
      { name: 'TAC', width: '0.8fr' },
      { name: 'Device', width: '0.8fr' },
    ]
  };
  lastSortedByValue;
  lpaString = "(LPA String)" //"LPA:1$RSP-0002.OBERTHUR.NET$CUMQE-AV6WL-GPIJ0-XVHI1";
  constructor(
    private profileService: ProfileService,
    private commnicationService: CommunicationService,
    private filterStore: Store<FilterState>,
    private apiService: ApiService,
    private userStroer: Store<UserState>,
    // dateTimeAdapter: DateTimeAdapter<any>,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService,
    private pdfService: PdfGeneratorService,
    private themeService: UiCustomizationService,
    private userService: UserService) { 

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      this.languageChange = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});

    this.isprod = (localStorage.getItem('isProd') === 'true');

    this.userStroer.select('user')
      .subscribe((res: any) => {
        if(res) {
          this.user = res.data;

          if(this.user.currentCustomer) {
            this.customerId = this.user.currentCustomer._id;
          } else {
            this.customerId = this.user.rootCustomer._id;
          }
        }

        if(res && res.featureRole && res.featureRole.length > 0) {
          this.features = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'Manage Profile')].features;
          this.apiFeatures = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'API')].features;
        }
        
        if (res && res.permissions && (res.permissions.indexOf('api') !== -1)) {
          this.apiDisplay = false;
          this.getDashboardApis();
        } else {
          this.apiDisplay = true;
        }
        
      });

    this.commnicationService.getRemovedCustomer()
      .subscribe((res: any) => {
        this.getProfiles();
      });
      
    this.filterArray.push(
      { name: 'Sort By', key: 'ICCID', value: 'ICCID', checked: true, group: 'sortBy' },
      { name: 'Order By', key: 'ASC', value: 'Ascending', checked: true, group: 'orderBy' }
    );
    
    this.userService.getFieldSection().subscribe((result) => {
        if (result['data'].length > 0) {
          this.activeTableColumns = result['data'].map(d => {
            if (d.selected) {
                return d.name;
              }
          });
          this.activeTableColumns = this.activeTableColumns.filter(i => i);
          this.fieldList = result['data'];
          const fieldListName = [];
          for (let i = 0; i < this.fieldList.length; i++) {
            fieldListName.push(this.fieldList[i].name);
          }
          let prpValue = this.fieldList.every(t => t.name !== 'PRP');
          if (prpValue) {
              this.fieldList.splice(3, 0, {name: 'PRP', selected: true});
          } else {
            if ( fieldListName[fieldListName.length - 1] === 'PRP') {
              this.fieldList.pop();
              this.fieldList.splice(3, 0, {name: 'PRP', selected: true});
            }
          }
        } else {
              this.activeTableColumns = ['Type', 'Status', 'Time-stamp', 'PRP', 'PRP Count', 'EID', 'TAC', 'Device'];
            }
      });

    this.filterStore.select('filter')
      .subscribe(res => {
        if(res && res['type']) {
          if(res['type'] == 'downloaded') {
            let indexArray = [5,6,7,8];
            indexArray.forEach(ele => {
              this.statusArray[ele].checked = true;
              // this.selectStatus(ele);
              this.selectedStatusArr.push(this.statusArray[ele].key.toUpperCase());
              this.filterArray.push({
                name: 'Status',
                key: this.statusArray[ele].key,
                value: this.statusArray[ele].key,
                group: 'status'
              });
            });
            // this.getProfiles();
          } 
          if(res['type'] == 'available'){
            let selectIndex = [0];
            selectIndex.forEach(ele => {
              this.statusArray[ele].checked = true;
              // this.selectStatus(ele);
              this.selectedStatusArr.push(this.statusArray[ele].key.toUpperCase());
              this.filterArray.push({
                name: 'Status',
                key: this.statusArray[ele].key,
                value: this.statusArray[ele].key,
                group: 'status'
              });
            });
          } 
          this.getProfiles();
        }
        else if (res) {
          this.profileType = res;
          const temp = JSON.parse(localStorage.getItem('filteTerms'));
          const tempObj = temp[0];
          this.sortBy = tempObj.sortBy;
          this.orderBy = tempObj.order;
          this.selectedStatusArr = tempObj.statuses;
          this.statusArray.forEach((ele: any) => {
            if (this.selectedStatusArr.indexOf(ele.key.toUpperCase()) !== -1) {
              ele.checked = true;
            }
          });
          this.fromDate = tempObj.lastUpdateSince;
          this.toDate = tempObj.lastUpdateUntil;
          this.lockStatus = tempObj.profileTypeLockFlag;
          this.profileLockStatus = (JSON.parse(localStorage.getItem('filteTerms')))[1];
          this.getProfiles();
        } else if (localStorage.getItem('filteTerms')) {
          const temp = JSON.parse(localStorage.getItem('filteTerms'));
          const tempObj = temp[0];
          this.sortBy = tempObj.sortBy;
          this.orderBy = tempObj.order;
          this.profileType = tempObj.profileTypesFilter;
          this.selectedStatusArr = tempObj.statuses;
          this.statusArray.forEach((ele: any) => {
            if (this.selectedStatusArr.indexOf(ele.key.toUpperCase()) !== -1) {
              ele.checked = true;
            }
          });
          this.fromDate = tempObj.lastUpdateSince;
          this.toDate = tempObj.lastUpdateUntil;
          this.lockStatus = tempObj.profileTypeLockFlag;
          this.profileLockStatus = (JSON.parse(localStorage.getItem('filteTerms')))[1];
          this.getProfiles();
        } else {
          this.getProfiles();
        }
      });

    this.commnicationService.getApiStatusToDashboard()
      .subscribe((apiStatus: any) => {
        if (apiStatus.status) {
          $(document).ready(() => {
              $('[data-toggle="tooltip"]').tooltip({
                container: '.api-tree-tooltip'
              });
          });
          this.apiList.unshift({
            apiId: apiStatus.id,
            apiName: apiStatus.name.
              replace(/([A-Z])/g, '$1')
              .replace(/^./, (str) => str.toUpperCase()),
            light: apiStatus.light
          });
        } else {
          $(document).ready(() => {
              $('[data-toggle="tooltip"]').tooltip({
                container: '.api-tree-tooltip'
              });
          });
          this.apiList.splice(this.apiList.findIndex(element => apiStatus.id === element.apiId), 1);
        }
      });
      
    this.commnicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

      let self = this;
      $('#prepareProfile').on('hidden.bs.modal', function () {
        self.logoId = '';
        self.qrCodeWithLogo = false;
        self.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
        self.qrOption = 'QR Code';
        self.qrCodeWithCampaign = false;
        self.selectedTempateId = '';
        self.selectedReleaseMethod = 'withQR';
        self.eidFileName = '';
        self.fileNameErr = false;
        self.disable = false;
        self.dispayOrCodeValueFlg = '';
      });

      $('#qrCode').on('hidden.bs.modal', function () {
        self.logoId = '';
        self.qrCodeWithLogo = false;
        self.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
        self.qrOption = 'QR Code';
        self.qrCodeWithCampaign = false;
        self.selectedTempateId = '';
        self.dispayOrCodeValueFlg = '';
      });

      $('#bulkQrCode').on('hidden.bs.modal', function () {
        self.logoId = '';
        self.qrCodeWithLogo = false;
        self.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
        self.qrOption = 'QR Code';
        self.selectedTempateId = '';
      });

      $('#selectLogoModal').on('hidden.bs.modal', function () {
        self.selectLogo();
      });

      $('#prpSetting').on('hidden.bs.modal', function () {
        self.selectedPRPOption = 'PRP_5';
        self.prpCounter = null;
        self.syncProfileTypes = [];
        self.selectedProfileTypes = [];
        self.syncGroups = [];
        self.selectedGroups = [];
        self.profileTypeErr = false;
        self.countErr = false;
        self.groupErr = false;
        self.prpCounterErr = false;
        $('[data-toggle="tooltip"]').tooltip();
        self.btnSpinner = false;
        self.prpBtnSpinner = false;
        self.prpBtnSpinnerDownload=false;
        self.startingIccidErr = false;
        self.fileToUpload = '';
        self.fileUploadErr = false;
        self.startingIccid = null;
        self.count = null;
        self.iccidList = [];
        self.selectedOption = 'Profile Type';
      });
  }

  getFeatureIndex(keyName, featureName) {
    if(featureName === 'api') { 
      return this.apiFeatures ? this.apiFeatures.findIndex(ele => ele.key === keyName) : '';
    } else {
      return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
    }
  }

  changeLanguage() {
    this.languageChange = localStorage.getItem('language');
    this.translate.use(this.languageChange);
  }

  getOffsetWidth() {
    this.noElement = Math.floor((this.apiElementView.nativeElement.offsetWidth - 15) / 60);

    if (this.noElement || (this.noElement === 0)) {
      this.apiDisplay = true;
      $(document).ready(() => {
          $('[data-toggle="tooltip"]').tooltip({
            container: '.api-tree-tooltip'
          });
      });
    }
  }

  getDashboardApis() {
    this.apiService.getDashboardApis()
      .subscribe((res: any) => {
        this.apiList = res;
        this.apiList.forEach(element => {
          element.apiName = element.apiName.
            replace(/([A-Z])/g, '$1')
            .replace(/^./, (str) => str.toUpperCase());
        });
        this.getOffsetWidth();
      }, err => {
        const cookieTrail = {
          screenName: 'Manage Profile',
          action: 'Get Dashboard APIs',
          error: err.error || 'Something went wrong!',
        };
    
        this.cookieTrailService.saveCookie(cookieTrail);
        this.apiDisplay = true;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  toggleFilter() {
    $('#filterBox').toggleClass('filter-show');
    $('#filterBtn').toggleClass('btn-filter-grey');
  }

  getProfiles() {
    this.profileList = [];
    if (this.profileSubscribe) {
      this.profileSubscribe.unsubscribe();
    }
    this.profileSpinner = true;
    this.tempIccid = null;
    this.lastIccid = null;
    this.lastSortedByValue = null;
    // this.selectFlg = false;

    setTimeout(() => {
      if ((this.profileLockStatus[0].checked === true && this.profileLockStatus[1].checked === true)
        || (this.profileLockStatus[0].checked === false && this.profileLockStatus[1].checked === false)) {
        this.lockStatus = null;
      } else if (this.profileLockStatus[0].checked === true) {
        this.lockStatus = true;
      } else if (this.profileLockStatus[1].checked === true) {
        this.lockStatus = false;
      }
      this.filteTerms = {
        sortBy: this.sortBy,
        order: this.orderBy,
        limit: '20',
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        statuses: this.selectedStatusArr,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastSortedByValue: this.lastSortedByValue,
        lastIccid: this.lastIccid,
        profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
        skip: 0
      };
      localStorage.setItem('filteTerms', JSON.stringify([this.filteTerms, this.profileLockStatus]));

      this.profileSubscribe = this.profileService.getProfiles(this.searchTerm, this.filteTerms)
        .subscribe((res: any) => {
          this.profileSpinner = false;
          this.profileList = res.profiles;
          this.profileCount = res.count;
          this.selectedProfileCount = 0;
          this.selectedIccidList = [];
          this.selectedCount = 0;
          this.select = false;
          this.selectAllIccid = false;
          this.deselectedIccidList = [];
          this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
          // if (this.selectedIccidList.length > 0) {
          //   // tslint:disable-next-line:prefer-for-of
          //   for (let i = 0; i < this.selectedIccidList.length; i++) {
          //     await this.findSelectIccid(i);
          //   }

          //   if (!this.selectFlg) {
          //     this.selectedCount = 0;
          //     this.selectedIccidList = [];
          //   }
          // } else
          if (this.profileList && this.profileList.length > 0) {
            // this.selectAllRows();
            this.lastIccid = this.profileList[this.profileList.length - 1].iccid;
            switch (this.sortBy) {
              case 'ICCID':
                this.lastSortedByValue = this.profileList[this.profileList.length - 1].iccid;
                break;
              case 'PROFILE_TYPE':
                console.log('called in PROFILE_TYPE')
                this.lastSortedByValue = this.profileList[this.profileList.length - 1].profileType;
                console.log(this.lastSortedByValue)
                break;
              case 'STATUS':
                this.lastSortedByValue = this.profileList[this.profileList.length - 1].state;
                break;
              case 'LAST_UPDATE':
                this.lastSortedByValue = this.profileList[this.profileList.length - 1].updatedOn;
                break;
              default:
                break;
            }
            this.profileList.forEach(ele => {
              ele.checked = false;
            });
          }
        }, err => {
          const cookieTrail = {
            screenName: 'Manage Profile',
            action: 'Get Profiles',
            body: {
              searchTerm: this.searchTerm,
              filteTerms: this.filteTerms
            },
            error: err.error || 'Something went wrong!',
          };
      
          this.cookieTrailService.saveCookie(cookieTrail);
          this.profileSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }, 5);
  }

  // findSelectIccid(i) {
  //   if (this.profileList.findIndex(element => this.selectedIccidList[i].iccid === element.iccid) !== -1) {
  //     this.profileList[this.profileList.findIndex(element => this.selectedIccidList[i].iccid === element.iccid)].checked = true;
  //     this.selectFlg = true;
  //   }
  // }

  getProfileTypes() {
    this.profileService.getProfileTypeList()
      .subscribe((res: any) => {
        this.getProfileTypeList = res.profileTypes;
        this.getProfileTypeList.forEach((e, index) => {
          e.id = index;
          e.checked = false;
        });
      }, err => {
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  selectStatus(index) {
    if (this.selectedStatusArr.indexOf(this.statusArray[index].key.toUpperCase()) === -1) {
      this.selectedStatusArr.push(this.statusArray[index].key.toUpperCase());
      this.filterArray.push({
        name: 'Status',
        key: this.statusArray[index].key,
        value: this.statusArray[index].key,
        group: 'status'
      });
      this.getProfiles();
    } else {
      this.selectedStatusArr.splice(this.selectedStatusArr.indexOf(this.statusArray[index].key.toUpperCase()), 1);
      this.filterArray.splice(this.filterArray.findIndex(i => i.key === this.statusArray[index].key), 1);
      this.getProfiles();
    }
  }

  seletDate(date) {
    
    if (date === 'fromDate') {
      if ((this.filterArray.findIndex(i => i.key === 'fromDate')) === -1) {
        this.filterArray.push({
          key: 'fromDate',
          name: 'From Date',
          value: moment(this.fromDate).format('DD-MM-YYYYT, hh:mm A'),
          group: 'date'
        });
      } else {
        this.filterArray[this.filterArray.findIndex(i => i.key === 'fromDate')].value
          = moment(this.fromDate).format('DD-MM-YYYYT, hh:mm A');
      }
    } else if (date === 'toDate') {
      if ((this.filterArray.findIndex(i => i.key === 'toDate')) === -1) {
        this.filterArray.push({
          key: 'toDate',
          name: 'To Date',
          value: moment(this.toDate).format('DD-MM-YYYYT, hh:mm A'),
          group: 'date'
        });
      } else {
        this.filterArray[this.filterArray.findIndex(i => i.key === 'toDate')].value
          = moment(this.toDate).format('DD-MM-YYYYT, hh:mm A');
      }
    }
  }

  selectAllRows() {
    this.selectedProfileCount = this.profileCount;
    if (this.select) {
      this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
      this.confirmCode = 'Fixed Confirmation Code';
      this.profileList.forEach(ele => {
        ele.checked = true;
      });
      this.selectedIccidList = [];
      this.deselectedIccidList = [];
    } else {
      this.profileList.forEach(ele => {
        ele.checked = false;
      });
      this.deselectedIccidList = [];
      this.selectedIccidList = [];
    }

  }

  selectCheck(check, iccid, state, prp, prpCounter) {
    let flg = 0;
    this.confirmCode = 'Fixed Confirmation Code';
    this.confirmationCode = 1234;
    if (!check) {
      this.selectedCount--;
      if(this.selectAllIccid) {
        this.selectedProfileCount--;
        this.deselectedIccidList.push(iccid);
      }
      this.selectedIccidList.splice(this.selectedIccidList.findIndex(i => i.iccid === iccid), 1);
      this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
      setTimeout(() => {
        if ((this.selectedIccidList.length === 1) && (this.selectedIccidList[0].state === 'AVAILABLE')) {
          this.disableReset = true;
        } else {
          this.disableReset = false;
        }

        if (this.iccidInfoSubscriber) {
          this.iccidInfoSubscriber.unsubscribe();
        }

        if(this.selectedIccidList.length === 1) {
          this.syncQrBtnSpinner = true;

          this.iccidInfoSubscriber = this.profileService.getICCIDInfo(this.selectedIccidList[0])
           .subscribe((res: any) => {
            if(res.defaultSmdp) {
              this.disableQrCode = true;
            } else {
              this.disableQrCode = false;
            }
            this.syncQrBtnSpinner = false;
          }, err =>{
            this.syncQrBtnSpinner = false;
            this.disableQrCode = false;
            this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
          });
        } else {
          this.disableQrCode = false;
          this.syncQrBtnSpinner = false;
        }
      }, 5);
    } else if (this.selectedIccidList.findIndex(i => i.iccid === iccid) === -1) {
      this.selectedCount++;
      if(this.selectAllIccid) {
        this.selectedProfileCount++;
        this.deselectedIccidList.splice(this.deselectedIccidList.findIndex(i => i.iccid === iccid), 1);
      }
      this.selectedIccidList.push({ iccid, state, prp, prpCounter });
      this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
      setTimeout(() => {
        if ((this.selectedIccidList.length === 1) && (this.selectedIccidList[0].state === 'AVAILABLE')) {
          this.disableReset = true;
        } else {
          this.disableReset = false;
        }
        this.syncQrBtnSpinner = true;
        if (this.iccidInfoSubscriber) {
          this.iccidInfoSubscriber.unsubscribe();
        }

        if(this.selectedIccidList.length === 1) {

          this.iccidInfoSubscriber = this.profileService.getICCIDInfo(this.selectedIccidList[0])
           .subscribe((res: any) => {
            if(res.defaultSmdp) {
              this.disableQrCode = true;
            } else {
              this.disableQrCode = false;
            }
            this.syncQrBtnSpinner = false;
          }, err =>{
            this.syncQrBtnSpinner = false;
            this.disableQrCode = false;
            this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
          });
        } else {
          this.disableQrCode = false;
          this.syncQrBtnSpinner = false;
        }
      }, 5);
    }
    this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
    this.confirmCode = 'Fixed Confirmation Code';

    setTimeout(() => {
      this.profileList.forEach(ele => {
        if (!ele.checked) {
          this.select = false;
          flg = 1;
          return;
        }
        if (flg === 0) {
          this.select = true;
        }
      });
    }, 5);
  }

  lockStatusFilter() {
    if (this.lockStatus === 'true') {
      if (this.filterArray.findIndex(i => i.key === 'lock') === -1) {
        this.filterArray.push({
          name: 'Lock Status',
          key: 'lock',
          value: 'Lock',
          group: 'lockStatus'
        });
      }
      if (this.filterArray.findIndex(i => i.key === 'unlock') !== -1) {
        this.filterArray.splice(this.filterArray.findIndex(i => i.key === 'unlock'), 1);
      }
    } else if (this.lockStatus === 'false') {
      if ((this.filterArray.findIndex(i => i.key === 'unlock')) === -1) {
        this.filterArray.push({
          name: 'Lock Status',
          key: 'unlock',
          value: 'Unlock',
          group: 'lockStatus'
        });
      }
      if (this.filterArray.findIndex(i => i.key === 'lock') !== -1) {
        this.filterArray.splice(this.filterArray.findIndex(i => i.key === 'lock'), 1);
      }
    }
  }

  profileTypeFilter() {
    this.getProfileTypeList.forEach(ele => {
      if (ele.checked && (this.filterArray.findIndex(i => i.key === ele.name) === -1)) {
        this.filterArray.push({
          name: 'Profile Type',
          key: ele.name,
          value: ele.name,
          group: 'proType'
        });
      } else if (!ele.checked && (this.filterArray.findIndex(i => i.key === ele.name) !== -1)) {
        this.filterArray.splice(this.filterArray.findIndex(i => i.key === ele.name), 1);
      }
    });
  }

  clearFilters() {
    this.statusArray.forEach(element => {
      element.checked = false;
    });
    this.selectedStatusArr = [];
    this.fromDate = null;
    this.toDate = null;
    this.lockStatus = '';
    this.sortBy = 'ICCID';
    this.orderBy = 'ASC';
    this.filterArray = [];
    this.selectedIccidList = [];
    this.selectedCount = 0;
    this.searchTerm = '';
    this.profileLockStatus = [
      { name: 'Lock', checked: false },
      { name: 'Unlock', checked: false }
    ];
    this.getProfiles();
  }

  getLogs(iccid, i, selectedTab) {
    this.logList = [];
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');
    


    if (this.infoOprSubscriber) {
      this.infoOprSubscriber.unsubscribe();
    }

    this.infoLogSubscriber = this.profileService.getLogs({ iccid })
      .subscribe((res: any) => {
        this.logList = res.logs;
        this.infoSpinner = false;
      }, err => {
        this.infoSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getICCIDInfo(iccid) {
    this.defaultSmdp = null;
    this.profileService.getICCIDInfo({iccid})
      .subscribe((res: any) => {
        this.defaultSmdp = res.defaultSmdp;
      }, err => {
        this.defaultSmdp = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getOperations(iccid, i, selectedTab) {
    this.operationList = [];
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');

    if (this.infoLogSubscriber) {
      this.infoLogSubscriber.unsubscribe();
    }

    this.infoOprSubscriber = this.profileService.getOperations({ iccid })
      .subscribe((res: any) => {
        this.operationList = res;
        this.infoSpinner = false;
      }, err => {
        this.infoSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }
  
  getTacInfo(iccid, i, selectedTab) {
    this.tac_info = [];
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');

    if (this.infoTacSubscriber) {
      this.infoTacSubscriber.unsubscribe();
    }

    this.infoTacSubscriber = this.profileService.getTacInfo({ iccid })
    .subscribe((res: any) => {
      this.tac_info = res;
      this.infoSpinner = false;
    }, err => {
      this.infoSpinner = false;
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    });
  }

  getStatus(iccid, i, selectedTab) {
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');
  }

  getSelectedCount() {
    this.disableReset = false;
    this.disableQrCode = false;
    // if (this.select) {
    //   this.selectedCount = 0;
    //   this.selectedIccidList = [];
    //   this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
    // } else {
    //   this.selectedCount = this.profileList.length;
    //   this.selectedIccidList = [];
    //   this.profileList.forEach(ele => {
    //     this.selectedIccidList.push({ iccid: ele.iccid, state: ele.state });
    //   });
    //   this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
    // }
  }

  getQrCode() {
    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Download QR Code',
      body: { 
        iccid: this.downloadQrCodeIccid,
        logoId: this.qrOption === 'QR Code With Logo' ? this.logoId : '',
        qrCodeWithLogo: this.qrOption === 'QR Code With Logo' ? true : false
       },
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    const logoId = this.qrOption === 'QR Code With Logo' ? this.logoId : '';
    const qrCodeWithLogo = this.qrOption === 'QR Code With Logo' ? true : false;

    this.profileService.getQrCode(this.downloadQrCodeIccid, logoId, qrCodeWithLogo)
      .subscribe((res: any) => {
        if (!res.data) {
          return;
        }

        const zip = new JSZip();
        const img = zip.folder(this.downloadQrCodeIccid);
        img.file(this.downloadQrCodeIccid + '.png',  res.data, { base64: true });

        if(res.qrCodeWithLogo) {
          const data = res.qrCodeWithLogo.split(',');
          img.file(this.downloadQrCodeIccid + '-logo.png', data[1], { base64: true });
        }

        zip.generateAsync({ type: 'blob' })
        .then((content) => {
          saveAs(content, `${this.downloadQrCodeIccid}.zip`);
        });

        $('#downloadQrCodeModal').modal('hide');
        this.qrCodeWithLogo = false;
      }, err => {
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  activeButton(selectedBtn) {

    if( (selectedBtn === 'op-prepareProfile' && !this.features[this.getFeatureIndex('prepare_multiple', 'profile')].edit && (this.selectedIccidList.length <= 0 && !this.selectAllIccid))) {
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'Please select iccid' });
      return;
    } else if ((selectedBtn !== 'op-qrCode' && selectedBtn !== 'op-prpSetting' && selectedBtn !== 'op-prepareProfile' && selectedBtn !== 'op-resetProfile') && (this.selectedIccidList.length <= 0 && !this.selectAllIccid)) {
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'Please select iccid' });
      return;
    }
    
    if(selectedBtn !== 'op-prpSetting') {
      this.campaignImg = '';
      this.getCampaignList();
      this.getDefaultLogo();
    }

    this.emailAddress = '';
    switch (selectedBtn) {
      case 'op-prepareProfile':

        if(this.selectAllIccid) {
          this.syncInventory('prepare');
        }

        this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
        this.confirmCode = 'Fixed Confirmation Code';
        this.confirmationCode = 1234;
        this.qrCodeOption = 'Text';
        this.textQr = true;
        this.qrCode = false;
        this.qrCodeWithLogo = false;
        // this.lpaText = false;
        this.eid = '';
        this.errEidMsg = false;
        this.errEmailDwn = false;
        this.qrDownload = true;
        this.qrEmail = false;
        this.qrAdditionalEmail = false;
        this.additionalEmailErr = false;
        this.logoSelectErr = false;
        this.qrCodeDownloadErr = false;
        // this.textQr = true;
        // this.qrCode = false;
        // this.qrCodeWithLogo = false;
        $('#op-prepareProfile').addClass('c-btn-action-active');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        $('#op-qrCode').removeClass('c-btn-action-active');
        $('#op-prpSetting').removeClass('c-btn-action-active');
        if(!this.selectAllIccid) { 
          ($('#prepareProfile') as any).modal({ backdrop: 'static', keyboard: false });
        }
        break;
      case 'op-resetProfile':
        if(this.selectAllIccid) {
          this.syncInventory('reset');
        }

        this.resetOption = 'Available';
        $('#op-prepareProfile').removeClass('c-btn-action-active');
        $('#op-resetProfile').addClass('c-btn-action-active');
        $('#op-qrCode').removeClass('c-btn-action-active');
        $('#op-prpSetting').removeClass('c-btn-action-active');
        if(!this.selectAllIccid) { 
          ($('#resetProfile') as any).modal({ backdrop: 'static', keyboard: false });
        }
        break;
      case 'op-qrCode':
        if(this.selectAllIccid) {
          this.syncInventory('qr');
        }

        this.qrCodeOption = 'Text';
        this.textQr = true;
        this.qrCode = false;
        this.qrCodeWithLogo = false;
        // this.lpaText = false;
        this.errEmailDwn = false;
        this.qrDownload = true;
        this.qrEmail = false;
        this.qrAdditionalEmail = false;
        this.additionalEmailErr = false;
        this.logoSelectErr = false;
        this.qrCodeDownloadErr = false;
        // this.textQr = true;
        // this.qrCode = false;
        // this.qrCodeWithLogo = false;
        $('#op-prepareProfile').removeClass('c-btn-action-active');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        $('#op-qrCode').addClass('c-btn-action-active');
        $('#op-prpSetting').removeClass('c-btn-action-active');
        if(this.isprod && this.user.currentCustomer && this.user.currentCustomer.plan === 'standard' && ((this.selectedIccidList.length <= 0 && this.selectedCount <= 0) && !this.selectAllIccid)) {
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'Please select iccid' });
          $('#op-qrCode').removeClass('c-btn-action-active');
        } else if(this.isprod && this.user.rootCustomer && this.user.rootCustomer.plan === 'standard' && ((this.selectedIccidList.length <= 0 && this.selectedCount <= 0) && !this.selectAllIccid)) {
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'Please select iccid' });
          $('#op-qrCode').removeClass('c-btn-action-active');
        } else if (!this.selectAllIccid && this.selectedIccidList.length > 0 && this.selectedCount > 0) {
          ($('#qrCode') as any).modal({ backdrop: 'static', keyboard: false });
        } else if (!this.selectAllIccid) {
          this.bulkQR = new FormGroup({
            iccid: new FormControl('', [this.validIccid]),
            amount: new FormControl('', [this.validAmount])
          });
          ($('#bulkQrCode') as any).modal({ backdrop: 'static', keyboard: false });
        }
        break;
      case 'op-prpSetting': 
        $('#op-prepareProfile').removeClass('c-btn-action-active');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        $('#op-qrCode').removeClass('c-btn-action-active');
        $('#op-prpSetting').addClass('c-btn-action-active');
        this.syncInventory('prp');
        break;
    }
  }

  openTab() {
    this.qrCodeOption='Text';
    this.qrDownload=true;
    this.qrEmail=false;
    this.emailAddress = '';
    this.qrAdditionalEmail = false;
    this.additionalEmailErr = false;
    this.textQr = true;
    this.qrCode = false;
    this.qrCodeWithLogo = false;
    // this.lpaText = false;
    this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
  }

  validIccid(control: any) {
    const iccid = String(control.value);
    if (/(89\d{16,18})/.test(iccid)) {
      return null;
    } else {
      return { validIccid: true };
    }
  }

  validAmount(control: any) {
    const amount = Number(control.value);
    if (amount > 0) {
      return null;
    } else {
      return { validAmount: true };
    }
  }

  get f() { return this.bulkQR.controls; }

  closeOperations() {
    $('#op-prepareProfile').removeClass('c-btn-action-active');
    $('#op-resetProfile').removeClass('c-btn-action-active');
    $('#op-qrCode').removeClass('c-btn-action-active');
    $('#op-prpSetting').removeClass('c-btn-action-active');
    this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
    this.confirmCode = 'Fixed Confirmation Code';

    this.errLength = false;
    this.errMsg = false;
    this.disable = false;
    this.qrCodeOption = 'Text';
    this.textQr = true;
    this.qrCode = false;
    this.qrCodeWithLogo = false;
    // this.lpaText = false;
    this.prepareRes = '';
    this.btnSpinner = false;
    this.qrEmail = false;
    this.qrDownload = true;
    this.eid = '';
    this.errEidMsg = false;
    this.errEmailDwn = false;
    this.confirmationCodeFlg = 'No';
    this.disableUploadCsv = true;
    this.bulkQrCodeFlg = 'File Upload';
    this.bulkQrDownload = true;
    this.submitted = false;
    this.emailAddress = '';
    this.qrAdditionalEmail = false;
    this.logoSelectErr = false;
    this.qrCodeDownloadErr = false;
    this.qrOption = 'QR Code';
    this.selectedReleaseMethod = 'withQR';
    this.fileNameErr = false;
  }

  openConfirmation() {
    $('#resetProfile').modal('hide');
  }

  resetProfile() {
    this.disable = false;
    let resetData;
    let tempIccid = [];

    if(!this.selectAllIccid) {
      if (this.selectedIccidList.length === 1) {
        resetData = {
          status: this.resetOption.toUpperCase(),
          iccidList: this.selectedIccidList.length === 1 ? [this.selectedIccidList[0].iccid] : ''
        };
      } else if (this.selectedIccidList.length > 1) {
        this.selectedIccidList.forEach((ele: any) => {
          tempIccid.push(ele.iccid);
        });
        resetData = {
          iccidList: tempIccid,
          status: this.resetOption.toUpperCase()
        };
      }
    } else {
      resetData = {
        status: this.resetOption.toUpperCase(),
        operation: 'RESET_M',
        deselectedICCID: this.deselectedIccidList,
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        statuses: this.selectedStatusArr,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        term: this.searchTerm
      }
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Reset Profile',
      body: resetData,
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.btnSpinner = true;

    // this.profileService.allIccidOperation(prepareData) 
      // .subscribe((res: any) => {

    if(this.selectAllIccid) {
      this.profileService.resetProfile(resetData, null)
      .subscribe((res: any) => {
        $('#resetProfile').modal('hide');
        $('#resetConfirmation').modal('hide');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        this.resetOption = 'Available';
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        this.selectedCount = 0;
        this.selectedIccidList = [];
        tempIccid = [];
        this.getProfiles();
      }, err => {
        $('#resetConfirmation').modal('hide');
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
    } else {
      this.profileService.resetProfile(resetData, this.selectedIccidList.length === 1 ? this.selectedIccidList[0].iccid : '')
      .subscribe((res: any) => {
        $('#resetProfile').modal('hide');
        $('#resetConfirmation').modal('hide');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        this.resetOption = 'Available';
        this.disable = false;
        this.btnSpinner = false;
        if(this.selectedIccidList.length === 1) {
          let msg;
        this.translate.get('profile is reset successfully!')
          .subscribe(res => {
            msg = res;
          })
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: `${ this.selectedIccidList[0].iccid + ' ' + msg }` });
        } else {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }
        
        this.selectedCount = 0;
        this.selectedIccidList = [];
        tempIccid = [];
        this.getProfiles();
      }, err => {
        $('#resetConfirmation').modal('hide');
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
    }
  }

  getBulkQrCode() {
    let data;
    let tempIccid = [];

    if (this.confirmationCodeFlg === 'Yes' && this.confirmCode === 'Fixed Confirmation Code') {
      if ((!this.confirmationCode || (this.confirmationCode === null))
        || (!this.confirmationCode.toString().trim())) {
        this.errMsg = true;
        if ((this.selectedIccidList.length === 1) && (!this.qrEmail && !this.qrDownload)) {
          this.errEmailDwn = true;
        }
        return;
      } else if ((this.confirmationCode.toString().length !== 4)) {
        this.errLength = true;
        return;
      }
    }

    if ((this.selectedIccidList.length === 1) && (!this.qrEmail && !this.qrDownload)) {
      this.errEmailDwn = true;
      return;
    }

    if(this.qrAdditionalEmail) {
      if(!this.emailAddress.trim()) {
        this.additionalEmailErr = true;
        return;
      }
    }

    if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
      this.qrCodeDownloadErr = true;
      return;
    }

    this.disable = true;
    this.btnSpinner = true;

    if(!this.selectAllIccid) {
      if (this.selectedIccidList.length === 1) {
        data = {
          qrText: this.textQr,
          qrCode: this.qrCode,
          qrCodeWithLogo: this.qrCodeWithLogo,
          logoId: this.qrCodeWithLogo ? this.logoId : '',
          email: this.qrEmail,
          confirmationCode: this.confirmationCode ? this.confirmationCode.toString() : '',
          ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
          emailAddress: this.emailAddress.trim(),
          iccidList: this.selectedIccidList.length === 1 ? [this.selectedIccidList[0].iccid] : '',
          campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
          campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
          campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
          random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
          // lpaText: this.lpaText
        };
      } else if (this.selectedIccidList.length > 1) {
        this.selectedIccidList.forEach((ele: any) => {
          tempIccid.push(ele.iccid);
        });
        data = {
          operation: 'QRCODE_M',
          random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
          iccidList: tempIccid,
          qrText: this.textQr,
          qrCode: this.qrCode,
          qrCodeWithLogo: this.qrCodeWithLogo,
          // lpaText: this.lpaText,
          logoId: this.qrCodeWithLogo ? this.logoId : '',
          confirmationCode: this.confirmationCode ? this.confirmationCode.toString() : '',
          ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
          emailAddress: this.emailAddress.trim(),
          campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
          campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
          campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId
        };
      }
    } else {
      data = {
        operation: 'QRCODE_M',
        random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
        qrText: this.textQr,
        qrCode: this.qrCode,
        // lpaText: this.lpaText,
        qrCodeWithLogo: this.qrCodeWithLogo,
        logoId: this.qrCodeWithLogo ? this.logoId : '',
        confirmationCode: this.confirmationCode ? this.confirmationCode.toString() : '',
        ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
        emailAddress: this.emailAddress.trim(),
        campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
        campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
        campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
        deselectedICCID: this.deselectedIccidList,
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        statuses: this.selectedStatusArr,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        term: this.searchTerm
      };
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'QR Code',
      body: data,
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.selectAllIccid) {
      this.profileService.allIccidOperation(data)
        .subscribe((res: any) => {
          $('#qrCode').modal('hide');
          $('#op-qrCode').removeClass('c-btn-action-active');
          this.disable = false;
          this.btnSpinner = false;
          this.errEmailDwn = false;
          this.confirmationCode = 1234;
          this.confirmationCodeFlg = 'No';
          tempIccid = [];
          this.emailAddress = '';
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
          this.selectedCount = 0;
          this.selectedIccidList = [];
          this.qrEmail = false;
          this.qrDownload = true;
          this.additionalEmailErr = false;
          this.getProfiles();
        }, err => {
          this.disable = false;
          this.btnSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        })
    } else {
      this.profileService.qrCode(data, this.selectedIccidList.length === 1 ? this.selectedIccidList[0].iccid : '')
      .subscribe((res: any) => {
        $('#qrCode').modal('hide');
        $('#op-qrCode').removeClass('c-btn-action-active');
        this.disable = false;
        this.btnSpinner = false;
        this.errEmailDwn = false;
        this.confirmationCode = 1234;
        this.confirmationCodeFlg = 'No';
        tempIccid = [];
        this.emailAddress = '';

        if(this.dispayOrCodeValueFlg === 'QR Code'){
          this.iccidInModal = res.iccid;
          this.qrOnModal = res.pngData;
          $('#displaQrCode').modal();
        } else if(this.dispayOrCodeValueFlg === 'QR Code with logo'){
          this.iccidInModal = res.iccid;
          this.qrWithLogoOnModal = res.qrCodeWithLogo;
          $('#displaQrCodeWithLogo').modal();
        }

        if (this.selectedIccidList.length === 1 && this.qrDownload) {

          const tempArr = [];

          if(this.textQr) {
            tempArr[0] = {
              iccid: res.iccid,
              format: 'txt',
              data: res.textData
            };
          }
    
          if(this.qrCode) {
            tempArr[1] = {
              iccid: res.iccid,
              format: 'png',
              data: res.pngData
            };
          }
    
          if(this.qrCodeWithLogo) {
            tempArr[4] = {
              iccid: res.iccid,
              format: 'png',
              data: res.qrCodeWithLogo
            };
          }

          if(this.qrCodeWithCampaign && this.selectedTempateId) {
            tempArr[5] = {
              iccid: res.iccid,
              format: this.selectedCampaignFormat === 'PNG' ? 'png' : 'pdf' ,
              data: this.selectedCampaignFormat === 'PNG' ? res.campaignPNG : res.campaignPdf
            };
          }
    
          tempArr[2] = {
            iccid: res.iccid,
            format: 'txt',
            data: res.acToken
          };

          if (res.confirmationCode) {
            tempArr[3] = {
              iccid: res.iccid,
              format: 'txt',
              data: res.confirmationCode
            };
          }

          // if (this.lpaText) {
          //   tempArr[7] = {
          //     iccid: res.iccid,
          //     format: 'txt',
          //     data: res.lpa
          //   };

          // }
          
          this.makeZip(res.iccid, tempArr);
          
        } else if (this.selectedIccidList.length > 1) {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }
        this.selectedCount = 0;
        this.selectedIccidList = [];
        this.qrEmail = false;
        this.qrDownload = true;
        this.additionalEmailErr = false;
        this.getProfiles();
      }, err => {
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
    }
    
  }

  downloadQrCode() {

    const tempArr = [];

    if(this.textQr) {
      if(this.selectedReleaseMethod === 'defaultSMDP') {
        tempArr[0] = {
          iccid: this.prepareRes.iccid,
          format: 'txt',
          data: this.prepareRes.smdpAddress
        };
      } else {
        tempArr[0] = {
          iccid: this.prepareRes.iccid,
          format: 'txt',
          data: this.prepareRes.textData
        };
      }
    }

    if(this.qrCode) {
      tempArr[1] = {
        iccid: this.prepareRes.iccid,
        format: 'png',
        data: this.prepareRes.pngData
      };
    }

    if(this.qrCodeWithLogo) {
      tempArr[4] = {
        iccid: this.prepareRes.iccid,
        format: 'png',
        data: this.prepareRes.qrCodeWithLogo
      };
    }

    if(this.qrCodeWithCampaign && this.selectedTempateId) {
      tempArr[5] = {
        iccid: this.prepareRes.iccid,
        format: this.selectedCampaignFormat === 'PNG' ? 'png' : 'pdf' ,
        data: this.selectedCampaignFormat === 'PNG' ? this.prepareRes.campaignPNG : this.prepareRes.campaignPdf
      };
    }

    if(this.prepareRes.acToken) {
      tempArr[2] = {
        iccid: this.prepareRes.iccid,
        format: 'txt',
        data: this.prepareRes.acToken
      };
    }

    if(this.prepareRes.eid) {
      tempArr[6] = {
        iccid: this.prepareRes.iccid,
        format: 'txt',
        data: this.prepareRes.eid
      };
    }

    if (this.prepareRes.confirmationCode) {
      tempArr[3] = {
        iccid: this.prepareRes.iccid,
        format: 'txt',
        data: this.prepareRes.confirmationCode
      };
    }

    // if (this.lpaText) {
    //   tempArr[7] = {
    //     iccid: this.prepareRes.iccid,
    //     format: 'txt',
    //     data: this.prepareRes.lpa
    //   };
    // }

    this.makeZip(this.prepareRes.iccid, tempArr);

    this.qrCodeOption = 'Text';
    this.textQr = true;
    this.qrCode = false;
    this.qrCodeWithLogo = false;
    // this.lpaText = false;
    this.qrEmail = false;
    this.qrDownload = true;
  }

  getConfirmationCode() {
    if (this.confirmCode === 'Random Confirmation Code') {
      this.confirmationCode = '####';
    } else {
      this.confirmationCode = '';
    }
  }

  makeZip(iccid, dataArr) {
    const zip = new JSZip();
    const img = zip.folder(iccid);
    if(dataArr[0]) {
      img.file(dataArr[0].iccid + '.' + dataArr[0].format, dataArr[0].data);
    }

    if(dataArr[1]) {
      img.file(dataArr[1].iccid + '.' + dataArr[1].format, dataArr[1].data, { base64: true });
    }

    if(dataArr[4]) {
      const data = dataArr[4].data.split(',');
      img.file(dataArr[4].iccid + '-logo' + '.' + dataArr[4].format, data[1], { base64: true });
    }

    if(dataArr[5] && this.selectedTempateId) {
      if(dataArr[5].format === 'png') {
        img.file(dataArr[5].iccid + '-campaign' + '.' + dataArr[5].format, dataArr[5].data, { base64: true });
      } else {
        img.file(dataArr[5].iccid + '-campaign' + '.' + dataArr[5].format, dataArr[5].data, { base64: true });
      }
    }

    if(dataArr[2]) {
      img.file(dataArr[2].iccid + '-acToken.' + dataArr[2].format, dataArr[2].data);
    }

    if(dataArr[6]) {
      img.file(dataArr[6].iccid + '-eid.' + dataArr[6].format, dataArr[6].data);
    }

    if (dataArr[3]) {
      img.file(dataArr[3].iccid + '-confirmationCode.' + dataArr[3].format, dataArr[3].data);
    }

    if(dataArr[7]) {
      img.file(dataArr[7].iccid + '-LPA.' + dataArr[7].format, dataArr[7].data);
    }

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, `${iccid}.zip`);
      });
  }

  downloadIccidCSV(type) {
    let tempList = [], fileData=[];
    if(this.selectAllIccid) {

      const data = {
        deselectedICCID: this.deselectedIccidList,
        statuses: this.selectedStatusArr,
        sortBy: this.sortBy,
        order: this.orderBy,
        limit: '20',
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastSortedByValue: this.lastSortedByValue,
        lastIccid: this.lastIccid,
        profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
        skip: 0
      }

      this.downloadWithoutDeviceLoader = true;

      this.profileService.downloadICCIDList(data, this.searchTerm, type)
        .subscribe((res: any) => {

          if(type === 'prp') {
            for(let i = 0; i< res.length; i++) {
              const obj = {
                iccid: res[i].iccid,
                profileType: res[i].profileType,
                prpType: res[i].prp,
                prpCount: res[i].prpCounter
              };
              tempList.push(obj);
            }
          } else {
            for(let i = 0; i< res.length; i++) {
              const obj = {
                iccid: res[i]
              };
              tempList.push(obj);
            }
          }

          papa.unparse(tempList);
          const fileName = `Selected Iccid.txt`;
          const blob = new Blob([papa.unparse(tempList)], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, fileName);
          this.downloadWithoutDeviceLoader = false;
        }, err => {
          this.downloadWithoutDeviceLoader = false;
        });
    } else {
      if(type === 'prepare') {
        for (let i = 0; i < this.selectedIccidList.length; i++) {
          const obj = {
            iccid: this.selectedIccidList[i].iccid,
          };
          fileData.push(obj);
        }
        papa.unparse(fileData);
        const fileName = `Selected Iccid.txt`;
        const blob = new Blob([papa.unparse(fileData)], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, fileName);
      } else {
        this.downloadWithoutDeviceLoader = true;
        for(let i = 0; i< this.selectedIccidList.length; i++) {
          const obj = {
            iccid: this.selectedIccidList[i].iccid
          };
          tempList.push(obj);
        }
        this.profileService.getICCIDPRPSetting(tempList)
          .subscribe((res: any) => {
            for (let i = 0; i < res.length; i++) {
              const obj = {
                iccid: res[i].iccid,
                profileType: res[i].profileType,
                prpType: res[i].prp,
                prpCount: res[i].prpCounter,
              };
              fileData.push(obj);
            }
            papa.unparse(fileData);
            const fileName = `Selected Iccid.txt`;
            const blob = new Blob([papa.unparse(fileData)], { type: 'text/plain;charset=utf-8' });
            this.downloadWithoutDeviceLoader = false;
            saveAs(blob, fileName);
        });
      }
    }
  }

  downloadProfileType(){
    let profileTypes=[],fileData=[],groupArray=[];
    this.prpBtnSpinnerDownload = true;
    if(this.optionsTypes[0].optionType === 'Profile Type'){
    for(let i = 0; i < this.optionsTypes[0].profileTypes.length; i++){
      if(this.optionsTypes[0].profileTypes[i].checked === true){
        profileTypes.push(this.optionsTypes[0].profileTypes[i].profileType);
      }
    }
    this.profileService.getProfileTypePRPSetting(profileTypes, 'profileType', this.customerId, groupArray).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        const obj = {
          iccid: res[i].iccid,
          profileType: res[i].profileType,
          prpType: res[i].prp,
          prpCount: res[i].prpCounter,
        };
        fileData.push(obj);
      }
      papa.unparse(fileData);
      const fileName = `Selected Iccid.txt`;
      const blob = new Blob([papa.unparse(fileData)], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
      this.prpBtnSpinnerDownload = false;
    });
  }else{
    for(let i = 0; i < this.optionsTypes[0].groups.length; i++){
      if(this.optionsTypes[0].groups[i].checked === true){
        groupArray.push(this.optionsTypes[0].groups[i]._id);
      }
    }
    this.profileService.getProfileTypePRPSetting(profileTypes, 'groups', this.customerId, groupArray).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        const obj = {
          iccid: res[i].iccid,
          profileType: res[i].profileType,
          prp: res[i].prp,
          prpCounter: res[i].prpCounter,
        };
        fileData.push(obj);
      }
      papa.unparse(fileData);
      const fileName = `Selected Iccid.txt`;
      const blob = new Blob([papa.unparse(fileData)], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
      this.prpBtnSpinnerDownload = false;
    });
  }
  }

  prepareProfile() {
    if (this.confirmationCodeFlg === 'Yes' && this.confirmCode === 'Fixed Confirmation Code') {
      if ((!this.confirmationCode || (this.confirmationCode === null))
        || (!this.confirmationCode.toString().trim())) {
        this.errMsg = true;
        if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
          this.qrCodeDownloadErr = true;  
        }
        if(!this.qrDownload && !this.qrEmail) {
          this.errEmailDwn = true;
        }
        return;
      } else if ((this.confirmationCode.toString().length !== 4)) {
        this.errLength = true;
        return;
      }
    }
    
    if(!this.qrDownload && !this.qrEmail && !this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
      this.qrCodeDownloadErr = true;
      this.errEmailDwn = true;
      return;
    }

    if ((this.selectedIccidList.length === 1) && (!this.qrDownload && !this.qrEmail)) {
      this.errEmailDwn = true;
      return;
    }
    
    if(this.qrAdditionalEmail) {
      if(!this.emailAddress.trim()) {
        this.additionalEmailErr = true;
        return;
      }
    }

    if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
      this.qrCodeDownloadErr = true;
      return;
    }
    
    this.btnSpinner = true;
    this.disable = true;
    let prepareData;
    let tempIccid = [];

    if (this.selectedIccidList.length === 1) {
      prepareData = {
        random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
        confirmationCode: this.confirmationCode ? this.confirmationCode.toString() : '',
        email: this.qrEmail,
        qrText: this.textQr,
        qrCode: this.qrCode,
        // lpaText: this.lpaText,
        qrCodeWithLogo: this.qrCodeWithLogo,
        logoId: this.qrCodeWithLogo ? this.logoId : '',
        ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
        emailAddress: this.emailAddress.trim(),
        iccidList: this.selectedIccidList.length === 1 ? [this.selectedIccidList[0].iccid] : '',
        campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
        campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
        campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId
      };
    } else if (this.selectedIccidList.length > 1 || this.selectAllIccid) {
      if(!this.selectAllIccid) {
        this.selectedIccidList.forEach((ele: any) => {
          tempIccid.push(ele.iccid);
        });
        prepareData = {
          operation: 'PREPARE_M',
          iccidList: tempIccid,
          qrText: this.textQr,
          qrCode: this.qrCode,
          qrCodeWithLogo: this.qrCodeWithLogo,
          // lpaText: this.lpaText,
          logoId: this.qrCodeWithLogo ? this.logoId : '',
          random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
          confirmationCode: this.confirmationCode.toString() ? this.confirmationCode.toString() : '',
          ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
          emailAddress: this.emailAddress.trim(),
          campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
          campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
          campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
        };
      } else {
        prepareData = { 
          operation: 'PREPARE_M',
          qrText: this.textQr,
          qrCode: this.qrCode,
          qrCodeWithLogo: this.qrCodeWithLogo,
          // lpaText: this.lpaText,
          logoId: this.qrCodeWithLogo ? this.logoId : '',
          random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
          confirmationCode: this.confirmationCode.toString() ? this.confirmationCode.toString() : '',
          ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
          emailAddress: this.emailAddress.trim(),
          campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
          campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
          campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
          deselectedICCID: this.deselectedIccidList,
          profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
          statuses: this.selectedStatusArr,
          lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
          lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
          term: this.searchTerm
        }
      }
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Prepare Without Device',
      body: prepareData
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.selectAllIccid) {
      this.profileService.allIccidOperation(prepareData) 
        .subscribe((res: any) => {
          this.btnSpinner = false;
          this.qrAdditionalEmail = false;
          this.additionalEmailErr = false;
          $('#prepareProfile').modal('hide');
          $('#op-prepareProfile').removeClass('c-btn-action-active');
          this.errLength = false;
          this.errMsg = false;
          this.disable = false;
          this.errEmailDwn = false;
          tempIccid = [];
          this.confirmationCode = 1234;
          this.confirmationCodeFlg = 'No';
          this.emailAddress = '';
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
          this.selectedCount = 0;
          this.selectedIccidList = [];
          this.getProfiles();
        }, err => {
          this.disable = false;
          this.btnSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    } else {
      this.profileService.prepareProfile(prepareData,
        this.selectedIccidList.length === 1 ? this.selectedIccidList[0].iccid : '')
        .subscribe((res: any) => {
          this.btnSpinner = false;
          this.prepareRes = res;
          this.qrAdditionalEmail = false;
          this.additionalEmailErr = false;
          $('#prepareProfile').modal('hide');
          $('#op-prepareProfile').removeClass('c-btn-action-active');
          this.errLength = false;
          this.errMsg = false;
          this.disable = false;
          this.errEmailDwn = false;
          tempIccid = [];
          this.confirmationCode = 1234;
          this.confirmationCodeFlg = 'No';
          this.emailAddress = '';
          if (this.selectedIccidList.length === 1 && this.qrDownload) {
            this.downloadQrCode();
          } else if (this.selectedIccidList.length > 1) {
            this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
          }
          this.selectedCount = 0;
          this.selectedIccidList = [];
          this.getProfiles();

          
          if(this.dispayOrCodeValueFlg === 'QR Code'){
            this.iccidInModal = res.iccid;
            this.qrOnModal = res.pngData;
            $('#displaQrCode').modal();
          } else if(this.dispayOrCodeValueFlg === 'QR Code with logo'){
            this.iccidInModal = res.iccid;
            this.qrWithLogoOnModal = res.qrCodeWithLogo;
            $('#displaQrCodeWithLogo').modal();
          }
          
        }, err => {
          this.disable = false;
          this.btnSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }

    
  }

  prepareProfileWithDevice() {
    
    if (this.selectedIccidList.length === 1) {
      if ((!this.eid || (this.eid === null))
        || (!this.eid.toString().trim())) {
        this.errEidMsg = true;
      } else if (this.eid && this.eid.trim().length !== 32) {
        this.eidLengthError = true;
        return;
      }

      if (!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
        this.qrCodeDownloadErr = true;
      }

      if ((this.selectedIccidList.length === 1) && (!this.qrDownload && !this.qrEmail)) {
        this.errEmailDwn = true;
      }
      if (this.confirmationCodeFlg === 'Yes' && this.confirmCode === 'Fixed Confirmation Code') {
        if ((!this.confirmationCode || (this.confirmationCode === null))
          || (!this.confirmationCode.toString().trim())) {
          this.errMsg = true;
          if (!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
            this.qrCodeDownloadErr = true;
          }
          return;

        } else if ((this.confirmationCode.toString().length !== 4)) {
          this.errLength = true;
          return;
        }
      }
    }
    if(this.qrAdditionalEmail) {
      if(!this.emailAddress.trim()) {
        this.additionalEmailErr = true;
        return;
      }
    }

    if (this.confirmationCodeFlg === 'Yes' && this.confirmCode === 'Fixed Confirmation Code') {
      if ((!this.confirmationCode || (this.confirmationCode === null))
        || (!this.confirmationCode.toString().trim())) {
        this.errMsg = true;
        if (!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
          this.qrCodeDownloadErr = true;
        }
        return;
      } else if ((this.confirmationCode.toString().length !== 4)) {
        this.errLength = true;
        return;
      }
    }
    if (!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
      this.qrCodeDownloadErr = true;
      return;
    }

    if (!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign && !this.qrDownload && !this.qrEmail) {
      this.qrCodeDownloadErr = true;
      this.errEmailDwn = true;
      return;
    }

    this.btnSpinner = true;
    this.disable = true;
    let prepareData;

    if (this.selectedIccidList.length === 1) {
      prepareData = {
        eid: this.eid,
        random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
        confirmationCode: this.confirmationCode ? this.confirmationCode.toString() : '',
        email: this.qrEmail,
        qrText: this.textQr,
        qrCode: this.qrCode,
        qrCodeWithLogo: this.qrCodeWithLogo,
        // lpaText: this.lpaText,
        logoId: this.qrCodeWithLogo ? this.logoId : '',
        ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
        emailAddress: this.emailAddress.trim(),
        iccidList: this.selectedIccidList.length === 1 ? [this.selectedIccidList[0].iccid] : '',
        campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
        campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
        campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId
      };
    } else if (this.selectedIccidList.length > 1 || this.selectAllIccid) {
      prepareData = {
        prepareList: this.iccidEidObj,
        random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
        confirmationCode: this.confirmationCode.toString() ? this.confirmationCode.toString() : '',
        qrText: this.textQr,
        qrCode: this.qrCode,
        qrCodeWithLogo: this.qrCodeWithLogo,
        // lpaText: this.lpaText,
        logoId: this.qrCodeWithLogo ? this.logoId : '',
        ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
        emailAddress: this.emailAddress.trim(),
        campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
        campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
        campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
        deselectedICCID: this.deselectedIccidList,
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        statuses: this.selectedStatusArr,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        term: this.searchTerm
      }; 

      if(this.selectedReleaseMethod === 'defaultSMDP') {
        prepareData.operation = 'PREPARE_EID_M';
      }
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Prepare With Device',
      body: prepareData,
    };

    this.cookieTrailService.saveCookie(cookieTrail);
    if (this.selectedIccidList.length === 1) {
      if(this.selectedReleaseMethod === 'defaultSMDP') {
        this.profileService.prepareProfileWithDeviceUsingSMDP(prepareData,
          this.selectedIccidList.length === 1 ? this.selectedIccidList[0].iccid : '')
          .subscribe((res: any) => {
            this.prepareRes = res;
            this.singleIccidWithEID();
          }, err => {
            this.disable = false;
            this.btnSpinner = false;
            this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          });
      } else {
        this.profileService.prepareProfileWithDevice(prepareData,
          this.selectedIccidList.length === 1 ? this.selectedIccidList[0].iccid : '')
          .subscribe((res: any) => {
            this.prepareRes = res;
            this.singleIccidWithEID();

            if(this.dispayOrCodeValueFlg === 'QR Code'){
              this.iccidInModal = res.iccid;
              this.qrOnModal = res.pngData;
              $('#displaQrCode').modal();
            } else if(this.dispayOrCodeValueFlg === 'QR Code with logo'){
              this.iccidInModal = res.iccid;
              this.qrWithLogoOnModal = res.qrCodeWithLogo;
              $('#displaQrCodeWithLogo').modal();
            }

          }, err => {
            this.disable = false;
            this.btnSpinner = false;
            this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          });
      } 
      
    } else if (this.selectedIccidList.length > 1 || this.selectAllIccid) {
      if(this.selectedReleaseMethod === 'defaultSMDP') {
        this.profileService.allIccidOperation(prepareData)
          .subscribe((res: any) => {
            this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
            this.multipleIccidWithEID();
          }, err => {
            this.disable = false;
            this.btnSpinner = false;
            this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          });
      } else {
        this.profileService.prepareProfileWithDevice(prepareData, '')
        .subscribe((res: any) => {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
          this.multipleIccidWithEID();
        }, err => {
          this.disable = false;
          this.btnSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
      }
    }
  }

  singleIccidWithEID() {
    this.btnSpinner = false;
    $('#prepareProfile').modal('hide');
    $('#op-prepareProfile').removeClass('c-btn-action-active');
    this.errLength = false;
    this.errMsg = false;
    this.disable = false;
    this.errEmailDwn = false;
    this.eid = '';
    this.confirmationCode = 1234;
    this.confirmationCodeFlg = 'No';
    if (this.selectedIccidList.length === 1 && this.qrDownload) {
      this.downloadQrCode();
    }
    this.selectedCount = 0;
    this.selectedIccidList = [];
    this.emailAddress = '';
    this.selectedReleaseMethod = 'withQR';
    this.disable = false;
    this.getProfiles();
  }

  multipleIccidWithEID() {
    this.btnSpinner = false;
    this.errLength = false;
    this.errMsg = false;
    $('#prepareProfile').modal('hide');
    $('#op-prepareProfile').removeClass('c-btn-action-active');
    this.disableUploadCsv = true;
    this.confirmationCode = 1234;
    this.qrCodeOption = 'Text';
    this.textQr = true;
    this.qrCode = false;
    this.qrCodeWithLogo = false;
    // this.lpaText = false;
    this.selectedCount = 0;
    this.selectedIccidList = [];
    this.emailAddress = '';
    this.selectedReleaseMethod = 'withQR';
    this.confirmationCodeFlg = 'No';
    this.eidFileName = '';
    this.fileNameErr = false;
    this.iccidEidObj = [];
    this.disable = false;
    this.getProfiles();
  }

  prepareBulkProfile() {

    if(!this.eidFileName) {
      this.fileNameErr = true;
      return;
    }

    if (this.confirmationCodeFlg === 'Yes' && this.confirmCode === 'Fixed Confirmation Code') {
      if ((!this.confirmationCode || (this.confirmationCode === null))
        || (!this.confirmationCode.toString().trim())) {
        this.errMsg = true;
        if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
          this.qrCodeDownloadErr = true;
        }
        return;
      } else if ((this.confirmationCode.toString().length !== 4)) {
        this.errLength = true;
        return;
      }
    }

    if(this.qrAdditionalEmail) {
      if(!this.emailAddress.trim()) {
        this.additionalEmailErr = true;
        return;
      }
    }

    if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
      this.qrCodeDownloadErr = true;
      return;
    }

    this.btnSpinner = true;
    this.disable = true;
    let prepareData;

    prepareData = {
      operation: 'PREPARE_M',
      iccidList: this.iccidEidObj,
      qrText: this.textQr,
      qrCode: this.qrCode,
      qrCodeWithLogo: this.qrCodeWithLogo,
      // lpaText: this.lpaText,
      logoId: this.qrCodeWithLogo ? this.logoId : '',
      random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
      confirmationCode: this.confirmationCode.toString() ? this.confirmationCode.toString() : '',
      ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
      emailAddress: this.emailAddress.trim(),
      campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
      campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
      campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
    };

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Prepare Bulk Without Device',
      body: prepareData
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.profileService.prepareProfile(prepareData, '')
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.prepareRes = res;
        this.qrAdditionalEmail = false;
        this.additionalEmailErr = false;
        $('#prepareProfile').modal('hide');
        $('#op-prepareProfile').removeClass('c-btn-action-active');
        this.errLength = false;
        this.errMsg = false;
        this.disable = false;
        this.errEmailDwn = false;
        this.confirmationCode = 1234;
        this.iccidEidObj = [];
        this.confirmationCodeFlg = 'No';
        this.emailAddress = '';
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        this.eidFileName = '';
        this.fileNameErr = false;
        this.getProfiles();
      }, err => {
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }
  resetBulkProfile() {
    if(!this.eidFileName) {
      this.fileNameErr = true;
      return;
    }
    this.btnSpinner = true;
    this.disable = true;
    let resetData, tempIccid = [];
    if(this.selectedIccidList.length === 1){
      resetData = {
        operation: 'RESET_M',
        iccidList: [this.selectedIccidList[0].iccid],
        status: this.resetOption.toUpperCase()
      };
    }else{
      resetData = {
        operation: 'RESET_M',
        iccidList: this.iccidEidObj,
        status: this.resetOption.toUpperCase()
      };
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Reset Profile',
      body: resetData
    };

    this.cookieTrailService.saveCookie(cookieTrail);
    console.log('2422',resetData);
    this.profileService.prepareProfile(resetData, '')
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.prepareRes = res;
        $('#resetProfile').modal('hide');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        this.errLength = false;
        this.errMsg = false;
        this.disable = false;
        this.errEmailDwn = false;
        this.iccidEidObj = [];
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        this.eidFileName = '';
        this.fileNameErr = false;
        this.getProfiles();
      }, err => {
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  onReleseMethodChange() {
    this.textQr = true;
    this.qrDownload = true;
    this.qrCode = false;
    this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
    // this.lpaText = false;
    this.logoId = '';
    this.qrCodeWithLogo = false;
    this.qrCodeWithCampaign = false;
    this.selectedTempateId = '';
    this.selectedCampaignFormat = 'PNG';
    this.emailAddress = '';
    this.eidFileName = '';
    this.fileNameErr = false;
    this.iccidEidObj = [];
  }

  prepareBulkProfileWithDevice() {

    if(!this.eidFileName) {
      this.fileNameErr = true;
      return;
    }

    if(this.qrAdditionalEmail) {
      if(!this.emailAddress.trim()) {
        this.additionalEmailErr = true;
        return;
      }
    }

    if (this.confirmationCodeFlg === 'Yes' && this.confirmCode === 'Fixed Confirmation Code') {
      if ((!this.confirmationCode || (this.confirmationCode === null))
        || (!this.confirmationCode.toString().trim())) {
        this.errMsg = true;
        if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
          this.qrCodeDownloadErr = true;
        } 
        return;
      } else if ((this.confirmationCode.toString().length !== 4)) {
        this.errLength = true;
        return;
      }
    }

    if(!this.textQr && !this.qrCode && !this.qrCodeWithLogo && !this.qrCodeWithCampaign) {
      this.qrCodeDownloadErr = true;
      return;
    }

    this.btnSpinner = true;
    this.disable = true;
    let prepareData;   
    
    prepareData = {
      prepareList: this.iccidEidObj,
      random: this.confirmCode === 'Fixed Confirmation Code' ? false : true,
      confirmationCode: this.confirmationCode.toString() ? this.confirmationCode.toString() : '',
      qrText: this.textQr,
      qrCode: this.qrCode,
      qrCodeWithLogo: this.qrCodeWithLogo,
      // lpaText: this.lpaText,
      logoId: this.qrCodeWithLogo ? this.logoId : '',
      ccFlag: this.confirmationCodeFlg === 'Yes' ? true : false,
      emailAddress: this.emailAddress.trim(),
      campaignId: this.qrCodeWithCampaign ? this.selectedTempateId : '',
      campaignType: this.qrCodeWithCampaign ? this.selectedCampaignFormat.toLowerCase() : '',
      campaignCheck: this.qrCodeWithCampaign && this.selectedTempateId,
      deselectedICCID: this.deselectedIccidList,
      profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
      statuses: this.selectedStatusArr,
      lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
      lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
    }; 

    if(this.selectedReleaseMethod === 'defaultSMDP') {
      prepareData.operation = 'PREPARE_EID_M';
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Prepare With Device',
      body: prepareData,
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.selectedReleaseMethod === 'defaultSMDP') {
      this.profileService.allIccidOperation(prepareData)
        .subscribe((res: any) => {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
          this.multipleIccidWithEID();
        }, err => {
          this.disable = false;
          this.btnSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    } else {
      this.profileService.prepareProfileWithDevice(prepareData, '')
      .subscribe((res: any) => {
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        this.multipleIccidWithEID();
      }, err => {
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
    }
  }

  downloadIccidEidCSV() {
    const tempList = [];
    if(this.selectAllIccid) {

      const data = {
        deselectedICCID: this.deselectedIccidList,
        statuses: this.selectedStatusArr,
        sortBy: this.sortBy,
        order: this.orderBy,
        limit: '20',
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastSortedByValue: this.lastSortedByValue,
        lastIccid: this.lastIccid,
        profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
        skip: 0
      }

      this.downloadWithDeviceLoader = true;

      this.profileService.downloadICCIDList(data, this.searchTerm, '')
        .subscribe((res: any) => {

          for(let i = 0; i< res.length; i++) {
            const obj = {
              iccid: res[i],
              eid: ''
            };
            tempList.push(obj);
          }

          papa.unparse(tempList);
          const fileName = `Selected Iccid.txt`;
          const blob = new Blob([papa.unparse(tempList)], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, fileName);
          this.downloadWithDeviceLoader = false;
        }, err => {
          this.downloadWithDeviceLoader = false;
        });
    } else {
      this.selectedIccidList.forEach((ele: any) => {
          const obj = {
            iccid: ele.iccid,
            eid: '',
          };
          tempList.push(obj);
        });
      papa.unparse(tempList);
      const fileName = `Selected Iccid.txt`;
      const blob = new Blob([papa.unparse(tempList)], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
    }
  }

  uploadProfilePic() {
    $('#pro-pic1').click();
  }

  uploadBulkProfile() {
    $('#bulk-profile').click();
  }

  uploadBulkProfileWithDevice() {
    $('#bulk-profile-with-device').click();
  }

  async onFileChange(event) {
    if (event.target.files.length > 0) {
      this.fileToUpload = event.target.files[0];
      this.eidFileName = this.fileToUpload.name;
      if(this.eidFileName) {
        this.fileNameErr = false;
      }
      this.iccidEidObj = [];
      event.target.value = '';
      return new Promise(resolve => {
        papa.parse(this.fileToUpload, {
          header: true,
          // newline: '\n',
          dynamicTyping: false,
          skipEmptyLines: true,
          transformHeader:function(h) {
            h = h.trim();
            h = h.toLowerCase();
            return h;
          },
          complete: results => {
            resolve(results.data.forEach((ele: any, i) => {
              if (i >= 0) {
                this.iccidEidObj.push({
                  iccid: ele.iccid,
                  eid: ele.eid
                });
              }
            }));
          }
        });
      });
    }
  }

  async onICCIDFileChange(event) {
    if (event.target.files.length > 0) {
      this.fileToUpload = event.target.files[0];
      this.eidFileName = this.fileToUpload.name;
      if(this.eidFileName) {
        this.fileNameErr = false;
      }
      this.iccidEidObj = [];
      event.target.value = '';
      return new Promise(resolve => {
        papa.parse(this.fileToUpload, {
          header: true,
          // newline: '\n',
          dynamicTyping: false,
          skipEmptyLines: true,
          transformHeader:function(h) {
            h = h.trim();
            h = h.toLowerCase();
            return h;
          },
          complete: results => {
            resolve(results.data.forEach((ele: any, i) => {
              if (i >= 0) {
                this.iccidEidObj.push(ele.iccid);
              }
            }));
          }
        });
      });
    }
  }

  async onRESETICCIDFileChange(event) {
    console.log('2690',event.target);
    if (event.target.files.length > 0) {
      this.fileToUpload = event.target.files[0];
      this.eidFileName = this.fileToUpload.name;
      if(this.eidFileName) {
        this.fileNameErr = false;
      }
      this.iccidEidObj = [];
      event.target.value = '';
      return new Promise(resolve => {
        papa.parse(this.fileToUpload, {
          header: true,
          // newline: '\n',
          dynamicTyping: false,
          skipEmptyLines: true,
          transformHeader:function(h) {
            h = h.trim();
            h = h.toLowerCase();
            return h;
          },
          complete: results => {
            resolve(results.data.forEach((ele: any, i) => {
              if (i >= 0) {
                this.iccidEidObj.push(ele.iccid);
              }
            }));
          }
        });
      });
    }
  }

  getConfirmationCodeFlg() {
    this.errMsg = false;
  }

  isEnter(evt) {

    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode === 13 && !evt.shiftKey) {
      this.getProfiles();
    }

  }

  onScrollDown() {

    if (this.tempIccid !== this.lastIccid) {
      this.tempIccid = this.lastIccid;
      this.profileInfiniteSpinner = true;
      setTimeout(() => {
        if ((this.profileLockStatus[0].checked === true && this.profileLockStatus[1].checked === true)
          || (this.profileLockStatus[0].checked === false && this.profileLockStatus[1].checked === false)) {
          this.lockStatus = null;
        } else if (this.profileLockStatus[0].checked === true) {
          this.lockStatus = true;
        } else if (this.profileLockStatus[1].checked === true) {
          this.lockStatus = false;
        }

        this.filteTerms = {
          sortBy: this.sortBy,
          order: this.orderBy,
          limit: '20',
          profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
          statuses: this.selectedStatusArr,
          lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
          lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
          lastSortedByValue: this.lastSortedByValue,
          lastIccid: this.lastIccid,
          profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
          skip: this.profileList.length
        };
        localStorage.setItem('filteTerms', JSON.stringify([this.filteTerms, this.profileLockStatus]));

        this.profileService.getProfiles(this.searchTerm, this.filteTerms)
          .subscribe((res: any) => {
            this.profileInfiniteSpinner = false;
            this.infiniteProfileList = res.profiles;
            // this.selectedIccidList = [];
            // this.selectedCount = 0;
            // this.select = false;
            if (this.infiniteProfileList && this.infiniteProfileList.length > 0) {
              this.lastIccid = this.infiniteProfileList[this.infiniteProfileList.length - 1].iccid;

              console.log(this.sortBy);
              console.log(this.lastSortedByValue);
              switch (this.sortBy) {
                case 'ICCID':
                  this.lastSortedByValue = this.infiniteProfileList[this.infiniteProfileList.length - 1].iccid;
                  break;
                case 'PROFILE_TYPE':
                  console.log('called in PROFILE_TYPE')
                  this.lastSortedByValue = this.infiniteProfileList[this.infiniteProfileList.length - 1].profileType;
                  console.log(this.lastSortedByValue)
                  break;
                case 'STATUS':
                  this.lastSortedByValue = this.infiniteProfileList[this.infiniteProfileList.length - 1].state;
                  break;
                case 'LAST_UPDATE':
                  this.lastSortedByValue = this.infiniteProfileList[this.infiniteProfileList.length - 1].updatedOn;
                  break;
                default:
                  break;
              }

              for(let i = 0; i < this.infiniteProfileList.length; i++) { 
                if(this.selectAllIccid) {
                  this.infiniteProfileList[i].checked = true;
                } else {
                  this.infiniteProfileList[i].checked = false;
                }
                this.profileList.push(this.infiniteProfileList[i]);
              }
            }

            if(this.select) {
              for(let i = 0; i < this.profileList.length; i++) {
                if(!this.profileList[i].checked) {
                  this.select = false;
                  break;
                }
              }
            }
            
          }, err => {
            this.profileInfiniteSpinner = false;
            this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          });
      }, 5);
    }

  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 47 && charCode < 58) {
      return true;
    }
    return false;
  }

  uploadQrFile() {
    $('#bulk-qr-file').click();
  }

  async onQrFileChange(evt) {
    if (evt.target.files.length > 0) {
      this.qrFile = evt.target.files[0];
      evt.target.value = '';
      this.bulkQrDownload = false;

      this.iccidEidObj = [];
      evt.target.value = '';
      papa.parse(this.qrFile, {
        newline: '\n',
        dynamicTyping: false,
        skipEmptyLines: true,
        complete: (results) => {
          results.data.forEach((ele: any, index) => {
            if (index) {
              this.iccidEidObj.push(ele[0]);
            }
          });
        }
      });
    }
  }

  generateBulkQrCode() {
    if (!this.qrFile) {
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'File is not selected.' });
      return;
    }

    this.btnSpinner = true;
    const formData: FormData = new FormData();
    formData.append('file', this.qrFile, this.qrFile.name);
    formData.append('logoId', this.qrOption === 'QR Code With Logo' ? this.logoId : '');
    formData.append('qrCodeWithLogo', this.qrOption === 'QR Code With Logo' ? 'true' : 'false');
    formData.append('campaignId', this.qrOption === 'QR Code With Campaign' ? this.selectedTempateId : '');
    formData.append('campaignType', this.qrOption === 'QR Code With Campaign' ? this.selectedCampaignFormat.toLowerCase() : '');
    formData.append('campaignCheck', (this.qrOption === 'QR Code With Campaign') && this.selectedTempateId ? 'true' : 'false');
    formData.append('operation', 'QRCODE_FILE');

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Generate Bulk QR code',
      body: {
        iccidList: this.iccidEidObj
      },
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.profileService.generateMultipleQrCode(formData)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.qrFile = null;
        $('#bulkQrCode').modal('hide');
        this.closeOperations();
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
      }, err => {
        this.btnSpinner = false;
        $('#bulkQrCode').modal('hide');
        this.closeOperations();
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  generateIncrementalQrCode() {
    this.submitted = true;
    if (this.bulkQR.invalid) {
      return;
    }

    const iccidList = this.generateIccid(this.bulkQR.value);
    this.btnSpinner = true;

    const data = {
      iccidList, 
      manual: true,
      logoId: this.qrOption === 'QR Code With Logo' ? this.logoId : '',
      qrCodeWithLogo: this.qrOption === 'QR Code With Logo' ? true : false,
      campaignId: this.qrOption === 'QR Code With Campaign' ? this.selectedTempateId : '',
      campaignType: this.qrOption === 'QR Code With Campaign' ? this.selectedCampaignFormat.toLowerCase() : '',
      campaignCheck: (this.qrOption === 'QR Code With Campaign') && this.selectedTempateId ? true : false,
      operation: 'QRCODE_FILE'
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Generate Incremental QR code',
      body: { iccidList, manual: true },
      timeStamp: new Date(),
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.profileService.generateMultipleQrCode(data)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.qrFile = null;
        $('#bulkQrCode').modal('hide');
        this.closeOperations();
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
      }, err => {
        this.btnSpinner = false;
        $('#bulkQrCode').modal('hide');
        this.closeOperations();
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  generateIccid(data) {
    const iccidArray = [];
    const iccid = BigInt(data.iccid);
    const length = Number(data.amount);
    for (let i = 0; i < length; i++) {
      iccidArray.push({ iccid: String(iccid + BigInt(i)) });
    }
    return iccidArray;
  }

  ngOnDestroy() {
    if (this.profileSubscribe) {
      this.profileSubscribe.unsubscribe();
    }
    $('[data-toggle="tooltip"]').tooltip('hide');
    this.filteTerms = {
      sortBy: this.sortBy,
      order: this.orderBy,
      limit: '20',
      profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
      statuses: [],
      lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
      lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
      lastSortedByValue: this.lastSortedByValue,
      lastIccid: this.lastIccid,
      profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
      skip: 0
    };
    localStorage.setItem('filteTerms', JSON.stringify([this.filteTerms, this.profileLockStatus])); 
  }

  checkadditionalEmailFlg() {
    if(!this.qrEmail) {
      this.additionalEmailErr = false;
      this.qrAdditionalEmail = false;
    }
  }

  checkEmailFlg() {
    if(this.qrAdditionalEmail) {
      this.qrEmail = true;
    } else {
      this.additionalEmailErr = false;
      this.emailAddress = '';
    }
  }

  checkAdditionalEmail() {
    if(this.emailAddress) {
      this.qrAdditionalEmail = true;
      this.qrEmail = true;
    }
  }

  getQrLogos() {
    this.logosArray = [];
    this.allLogoSpinner = true;
    this.profileService.getLogos()
      .subscribe((res: any) => {
        this.allLogoSpinner = false;
        this.logoSelected = false;
        this.selectedLogoIndex = '';
        if(res.message === 'No Logo Found!') {
          this.logosArray = [];
          // this.commnicationService.openAlert({alertType: 'error', alertMsg: 'No Logo Found!'});
        } else {
          // this.logoId = '';
          this.logosArray = res;
        }
        this.logoSelectErr = false;
        ($('#selectLogoModal') as any).modal({ backdrop: 'static', keyboard: false });
      }, err => {
        this.allLogoSpinner = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  selectLogo() {
    this.logoSelected = false;

    if(!this.logoId || (this.logoId === this.tempLogoId)) {
      return;
    }
    const data = {
      logo: this.logoImg,
      text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
    }
    this.pdfService.addLogoInQr(data)
        .subscribe((res: any) => {
            this.qrCodeWithLogoImg = res.qrCode;
            $('#selectLogoModal').modal('hide');
        }, err => {
          this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });
  }

  uploadQrCodeLogo() {
    $('#qr-code-logo').click();
  }

  uploadBulkQrCodeLogo() {
    $('#bulk-qr-code-logo').click();
  }

  uploadDownloadQrCodeLogo() {
    $('#download-qr-code-logo').click();
  }

  onQrLogoChange(evt) {

    if (evt.target.files[0].size > (1 * this.fileSize)) {
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'File size should not be more than 1mb.' });
      return;
    }

    const fileUpload = evt.target.files[0];
    const formData = new FormData();
    formData.set('file', fileUpload);
    evt.target.value = '';

    this.profileService.uploadQrLogo(formData)
      .subscribe((res: any) => {
        
        this.logosArray.push({
          _id: res.result._id,
          customer: res.result.customer,
          data: res.pngData
        });
      }, err => {
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });
  }

  bulkQrOptionChange() {
    // this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
    // this.logoId = '';
    this.logoSelectErr = false;
    this.qrCodeDownloadErr = false;
    this.qrOption = 'QR Code';
  }

  checkLimit(evt) {
    if(this.prpCounter === '0') {
      this.prpCounter = null;
      return false;
    }
    if(this.prpCounter <=0 || this.prpCounter > 1000) {
      this.prpCounter = this.prpCounter.substring(0,3);
      return false;
    }
  }

  // Sync Inventory (Iccid and Profile Types)
  syncInventory(model) {
    this.selectedIccidCount = 0;
    if(model === 'prp') {
      this.syncPrpBtnSpinner = true;
    } else if(model === 'prepare') {
      this.syncPrepareBtnSpinner = true;
    } else if(model === 'reset') {
      this.syncResetBtnSpinner = true;
    } else if(model === 'qr') {
      this.syncQrBtnSpinner = true;
    }

    $('[data-toggle="tooltip"]').tooltip('hide');

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Sync Inventory for prp',
      body: { customerId: this.customerId },
      timeStamp: new Date(),
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.profileService.syncInventory({ customerId: this.customerId, filterProfileTypes: this.profileType })
      .subscribe((res: any) => {
        this.syncPrepareBtnSpinner = false;
        this.syncResetBtnSpinner = false;
        this.syncQrBtnSpinner = false;
        this.syncPrpBtnSpinner = false;
        if(res.message && res.message.includes('There is database operation in progress for profileType')) {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        } else if(res.message === 'Groups are not assigned.') {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        } else {

          if(this.user.currentCustomer === null || this.user.currentCustomer.isParent) {
            this.optionsTypes[0].optionType = 'Profile Type'
            this.selectedOption = 'Profile Type';
            this.optionsTypes[0].profileTypes = [];
            res.profileTypes.forEach(element => {
              this.optionsTypes[0].profileTypes.push({
                profileType: element.profileType,
                count: element.count,
                checked: false
              });
            });
            this.selectedProfileTypes = [];
          } else {
            this.optionsTypes[0].optionType = 'Groups';
            this.selectedOption = 'Groups';
            this.optionsTypes[0].groups = [];
            res.forEach(element => {
              this.optionsTypes[0].groups.push({
                groupName: element.groupName,
                checkd: false,
                _id: element._id,
                count: element.count
              });
            });
            this.selectedGroups = [];
          }
          if(model === 'prp') {
            if(this.selectedIccidList.length === 1) {
              this.selectedIccidPrpType = 'NA';
              this.selectedIccidPPrpCounter = 'NA';
              const tempList = [];
              for(let i = 0; i< this.selectedIccidList.length; i++) {
                const obj = {
                  iccid: this.selectedIccidList[i].iccid
                };
                tempList.push(obj);
              }
              this.profileService.getICCIDPRPSetting(tempList)
                .subscribe((res: any) => {
                  this.syncPrpBtnSpinner = false;
                  this.selectedIccidPrpType = res[0].prp;
                  this.selectedIccidPPrpCounter = res[0].prpCounter;
                  $('#prpSetting').modal();
                }, err => {
                  this.syncPrpBtnSpinner = false;
                  this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
                });
            } else {
              $('#prpSetting').modal();
              this.syncPrpBtnSpinner = false;
            }
          } else if(model === 'prepare') {
            ($('#prepareProfile') as any).modal({ backdrop: 'static', keyboard: false });
          } else if(model === 'reset') {
            ($('#resetProfile') as any).modal({ backdrop: 'static', keyboard: false });
          } else if(model === 'qr') {
            ($('#qrCode') as any).modal({ backdrop: 'static', keyboard: false });
          }
        }
      }, err => {
        this.syncPrpBtnSpinner = false;
        this.syncPrepareBtnSpinner = false;
        this.syncResetBtnSpinner = false;
        this.syncQrBtnSpinner = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  profileTypeChanged(i) {

    if(this.optionsTypes[0].profileTypes[i].checked) {
      if(this.selectedProfileTypes.indexOf(this.optionsTypes[0].profileTypes[i].profileType) === -1) {
        this.selectedProfileTypes.push(this.optionsTypes[0].profileTypes[i].profileType);
        this.selectedIccidCount += this.optionsTypes[0].profileTypes[i].count;
      }
    } else {
      if(this.selectedProfileTypes.indexOf(this.optionsTypes[0].profileTypes[i].profileType) !== -1) {
        this.selectedProfileTypes.splice(this.selectedProfileTypes.indexOf(this.optionsTypes[0].profileTypes[i].profileType), 1);
        this.selectedIccidCount -= this.optionsTypes[0].profileTypes[i].count;
      }
    }

    this.profileTypeErr = false;
  }

  groupChanged(i) {
    if(this.optionsTypes[0].groups[i].checked) {
      if(this.selectedGroups.indexOf(this.optionsTypes[0].groups[i]._id) === -1) {
        this.selectedGroups.push(this.optionsTypes[0].groups[i]._id);
        this.selectedIccidCount += this.optionsTypes[0].groups[i].count;
      }
    } else {
      if(this.selectedGroups.indexOf(this.optionsTypes[0].groups[i]._id) !== -1) {
        this.selectedGroups.splice(this.selectedGroups.indexOf(this.optionsTypes[0].groups[i]._id), 1);
        this.selectedIccidCount -= this.optionsTypes[0].groups[i].count;
      }
    }

    this.groupErr = false;
  }

  uploadPrpSettings(){
    $('#setting-pic').click();
  }

  async onFilePRPChange(event) {
    if (event.target.files.length > 0) {
      this.fileToUpload = event.target.files[0];
      await this.parsePRPFile(this.fileToUpload);
      event.target.value = '';
    }
  }

  async parsePRPFile(rawFile) {
    return new Promise(resolve => {
      papa.parse(rawFile, {
        header: true,
        // newline: '\n',
        dynamicTyping: false,
        skipEmptyLines: true,
        transformHeader:function(h) {
          h = h.trim();
          h = h.toLowerCase();
          return h;
        },
        complete: (results) => {
          resolve(results.data.forEach((ele: any, i) => {
            if (i >= 0) {
              this.iccidList.push(ele.iccid);
            }
          }));
        }
      });
    });
  }

  //Change PRP
 async changePRPSetting() {

    if(this.selectedPRPOption === 'PRP_2a' && this.prpCounter <= 0) {
      this.prpCounterErr = true;
      return;
    }

    let data = {
      customerId: this.customerId,
      prpType: this.selectedPRPOption,
      option: "iccid",
      groupArray: [],
      iccidArray: [],
      profileType: [],
      prpCounter: this.prpCounter,
      operation: '',
      deselectedICCID: this.deselectedIccidList,
      profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
      statuses: this.selectedStatusArr,
      lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
      lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
    };

    // Parent level customer checking
    if(!this.selectAllIccid) {
      if(this.selectedIccidList.length === 0) {
        if(this.selectedOption === 'Profile Type') {
          if(this.selectedProfileTypes && this.selectedProfileTypes.length <= 0) {
            this.profileTypeErr = true;
            return;
          }
          data.option = 'profileType';
          data.profileType = this.selectedProfileTypes;
        } else if(this.selectedOption === 'Groups') {
          if(this.selectedGroups && this.selectedGroups.length <= 0) {
            this.groupErr = true;
            return;
          }
          data.option = 'groups';
          data.groupArray = this.selectedGroups;
        } else if(this.selectedOption === 'Upload File') {
          if(this.fileToUpload) {
            this.fileUploadErr = false;
          } else {
            this.fileUploadErr = true;
            return;
          }
          data.option = 'upload';
          data.iccidArray = this.iccidList;
        }  else if(this.selectedOption === 'Count') {
          if((this.count === undefined && this.startingIccid === undefined) || (!this.count && !this.startingIccid) || (this.count <= 0 && this.startingIccid <= 0)) {
            this.countErr = true;
            this.startingIccidErr = true;
            return;
          } else if(this.count <= 0) {
            this.countErr = true; 
            return;
          } else if(this.startingIccid <= 0) {
            this.startingIccidErr = true;
            return;
          }
    
          await this.generatePRPIccid({startingIccid: this.startingIccid, count: this.count})
          data.option = 'count';
          data.iccidArray = this.iccidList;
        }
      } else {
        data.option = 'iccid';
          this.selectedIccidList.forEach((ele: any) => {
            data.iccidArray.push(ele.iccid);
          });
      }
    } else {
      data.option = 'iccid';
      data.operation = 'PRP';
      data.deselectedICCID = this.deselectedIccidList;
      data.profileTypesFilter = (!this.profileType || this.profileType === null) ? [] : this.profileType;
      data.statuses = this.selectedStatusArr;
      data.lastUpdateSince = this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null;
      data.lastUpdateUntil = this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null;
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Change PRP',
      body: data,
      timeStamp: new Date(),
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.prpBtnSpinner = true;
    if(this.selectAllIccid) {
      this.profileService.allIccidOperation(data) 
      .subscribe((res: any) => {
        this.prpBtnSpinner = false;
        $('#prpSetting').modal('hide');
        this.commnicationService.openAlert({alertType: 'success', alertMsg: res.message});
      }, err => {
        this.prpBtnSpinner = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
    } else {
      this.profileService.changePRPSetting(data)
      .subscribe((res: any) => {
        this.prpBtnSpinner = false;
        $('#prpSetting').modal('hide');
        this.commnicationService.openAlert({alertType: 'success', alertMsg: res.message});
      }, err => {
        this.prpBtnSpinner = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      }); 
    }
    
  }

  async generatePRPIccid(data) {
    const iccid = BigInt(data.startingIccid);
    const length = Number(data.count);
    this.iccidList = [];
    for (let i = 0; i < length; i++) {
      await this.iccidList.push(String(iccid + BigInt(i)));
    }
  }

  onRadioChange() {
    this.profileTypeErr = false;
    this.countErr = false;
    this.startingIccidErr = false;
    this.prpCounter = '';
    this.fileUploadErr = false;
    this.fileToUpload = '';
    this.selectedProfileTypes = [];
    this.startingIccid = '';
    this.count = '';
    this.iccidList = [];
    this.selectedIccidCount = 0;

    if(this.selectedOption === 'Upload File' || this.selectedOption === 'Count') {
      if(this.user.currentCustomer === null || this.user.currentCustomer.isParent) {
        this.optionsTypes[0].profileTypes.forEach(ele => {
          ele.checked = false;
        });
        this.selectedProfileTypes = [];
      } else {
        this.optionsTypes[0].groups.forEach(ele => {
          ele.checked = false;
        });
        this.selectedGroups = [];
      }
    }
  }

  getCampaignList() {
    this.selectedTempateId = '';
    if(this.features && (this.features[this.getFeatureIndex('campaign_in_qrcode', 'profile')].edit || this.features[this.getFeatureIndex('campaign_in_prepare', 'profile')].edit)) {
      this.pdfService.getAllTemplates({'campaignType': 'iccid'})
      .subscribe((res: any) => {
        this.templateList = res;
        this.selectedTempateId = this.templateList[0]._id;
        if(this.selectedTempateId) {
          this.getSingleTemplate();
        }
      }, err => { 
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
    }
  }

  getSingleTemplate() {
    this.campaignImg = '';
    this.pdfService.getSingleTemplate(this.selectedTempateId)
      .subscribe((res: any) => {
        this.campaignImg = res.png;
      }, err => { 
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  toggleBorderClass(i) {
    if(this.selectedLogoIndex >= 0 && (this.selectedLogoIndex != i)) {
      $('#profile-single-logo-' + this.selectedLogoIndex).removeClass('img-logo-border');
    }
    $('#profile-single-logo-' + i).toggleClass('img-logo-border');
    if($('#profile-single-logo-' + i).hasClass('img-logo-border')) {
      this.logoSelected = true;
      this.selectedLogoIndex = i;
    } else {
      this.logoSelected = false;
      this.selectedLogoIndex = '';
    }
  }

  getDefaultLogo() {
    this.themeService.getDefaultLogo()
      .subscribe((res: any) => {
        if(res.message === 'No default logo found!') {
          this.logoId = '';
          this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
          this.defaultLogoId = '';
        } else {
          this.defaultLogoId = res._id;
          this.logoId = res._id;
          this.tempLogoId = this.logoId;
          const data = {
            logo: 'data:image/png;base64,' + res.data,
            text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
          }
          this.pdfService.addLogoInQr(data)
            .subscribe((res: any) => {
                this.qrCodeWithLogoImg = res.qrCode;
                $('#selectLogoModal').modal('hide');
            }, err => {
              this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
            });
        }
      });
  }

  setDefaultLogo() {
    if(!this.logoId) {
      this.logoSelectErr = true;
      return;
    }

    let tempDefaultLogoId = this.defaultLogoId;
    this.defaultLogoId = this.logoId;
    
    this.themeService.setDefaultLogo({logoId: this.logoId})
      .subscribe((res: any) => {
      }, err => {
        this.defaultLogoId = tempDefaultLogoId;
        this.commnicationService.openAlert({'alertType': 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  deleteLogo() {
    if(!this.logoId) {
      this.logoSelectErr = true;
      return;
    }

    this.themeService.deleteLogo({logoId: this.logoId})
      .subscribe((res: any) => {
        $('#profile-single-logo-' + this.selectedLogoIndex).removeClass('img-logo-border')
        if(this.defaultLogoId != this.logoId) {
          this.logoId = this.defaultLogoId;
        } else {
          this.logoId = '';
          this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
          this.defaultLogoId = '';
        }
        this.logoSelected = false;
        this.logosArray.splice(this.selectedLogoIndex, 1);
        this.selectedLogoIndex = '';
      }, err => {
        this.commnicationService.openAlert({'alertType': 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  updateTableColums(item) {
    item.selected = !item.selected;
    this.userService.updateFieldSection(this.fieldList).subscribe((result) => {
      this.fieldList = result['data'];
      this.activeTableColumns = result['data'].map(d => {
        if(d.selected) {
            return d.name;
          }
      });
      this.activeTableColumns = this.activeTableColumns.filter(i => i);
    });
  }  

  getStyles() {
    let selected = this.fieldList.filter(element => element.selected).length;
      let str = '50px 185px ' 
      this.dynamicStyle.width.forEach(st => {
        if(this.activeTableColumns.includes(st.name)) {
          str += selected <=4 ? `1fr ` : `${st.width} `
        }
      });
      str += '60px';
      return {
        'grid-template-columns': str
      }
  }

  checkDisplayQrOptions(){
    if(this.dispayOrCodeValueFlg === 'QR Code'){
      this.qrCode = true;
    } else if(this.dispayOrCodeValueFlg === 'QR Code with logo'){
      this.qrCodeWithLogo = true;
    } 
  }

  showList(e) {
    e.stopPropagation();
  }
}
