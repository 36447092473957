<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg mb-3">
                <div class="access-mgmt-heading-row">
                    <div class="d-flex align-items-center border-bottom pb-2 mb-3">
                        <div class="access-mgmt-heading access-mgmt-head-padding">Create New Profile Type Access</div>
                        <div class="d-flex ml-auto">
                            <button class="btn t-bgcolor mr-3 outline" (click)="saveRole()" *ngIf="!saveBtnSpinner">Save</button>
                            <div class="btn-spinner mr-3" *ngIf="saveBtnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <button class="btn access-mgmt-cancel-btn">Cancel</button>
                        </div>
                    </div>
                    <div class="row access-mgmt-head-padding">
                        <div class="col-lg-2">
                            <p class="mb-0 access-mgmt-role-name">Profile Type Access Name</p>
                        </div>
                        <div class="col-lg-4">
                            <input type="text" class="form-control t-input" [(ngModel)]="profileTypeName" (keyup)="profileTypeNameErr=false;" (keypress)="isSpace($event)">
                            <p class="small text-danger mt-2 mb-0" *ngIf="profileTypeNameErr">Please enter profile type name!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="face-container-inner-bg p-3">
                        <div class="mb-5">
                            <h5 class="mb-3">Production:</h5>
                            <div class="profile-type-row">
                                <div class="profile-type-checkbox-row">
                                    <div class="checkbox-perent-filter mr-3" *ngFor="let status of statusArray; let i = index">
                                        <p class="checkbox-perent-filter-inner" [ngClass]="{'checkbox-perent-filter-inner-checked': status.checked}" (click)="status.checked = !status.checked;selectStatus(i)"> {{status.key}} <span> <i class="fa fa-plus ml-2" *ngIf="!status.checked"></i> <i class="fa fa-check ml-2" *ngIf="status.checked"></i></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="mb-4">
                            <h5 class="mb-3">Pre-Production:</h5>
                            <div class="profile-type-row">
                                <div class="profile-type-checkbox-row">
                                    <div class="checkbox-perent-filter mr-3" *ngFor="let status of statusArray; let i = index">
                                        <p class="checkbox-perent-filter-inner" [ngClass]="{'checkbox-perent-filter-inner-checked': status.checked}" (click)="status.checked = !status.checked;selectStatus(i)"> {{status.key}} <span> <i class="fa fa-plus ml-2" *ngIf="!status.checked"></i> <i class="fa fa-check ml-2" *ngIf="status.checked"></i></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    
