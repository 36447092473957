import { User } from './user.model';
import * as UserAction from './user.action';

export interface UserState {
    readonly user: User;
}

export function userReducer(state: User, action: UserAction.UserActions) {

    switch (action.type) {

        case UserAction.GET_USER:
            return action.payload;

        default: return state;
    }
}
