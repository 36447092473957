import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { MousePosition, MCC } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

@Component({
  selector: 'app-mcc',
  templateUrl: './mcc.component.html',
  styleUrls: ['./mcc.component.scss']
})
export class MccComponent extends ShapeComponent implements OnInit {

  constructor() {
    super();
    //   console.log('TextComponent constructor');
    this.shape = new MCC();
    this.shapeType = ShapeType.MCC;
  }

  ngOnInit() {
    //   console.log('TextComponent ngOnInit');
  }

  updateShapeProperties(value: any) {
    if (this.shape instanceof MCC) {
      this.shape.shapeProperties.name = value.name;
      this.shape.value = value.value;
      this.shape.originX = value.x;
      this.shape.originY = value.y;
    }
  }

  setStyles() {
    const styles = {
      fill: this.shape.shapeProperties.strokeColor
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof MCC) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
      this.shape['font-family'] = 'Arial';
      this.shape['font-size'] = 16;
    }
    //   console.log('MCC startDrawing at ', beginPosition, ', ', this.shape);
  }
}

