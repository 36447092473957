import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route, UrlSegment } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { UiCustomizationComponent } from './ui-customization/ui-customization.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { NotificationComponent } from './notification/notification.component';
import { SvgEditorComponent } from './svg-editor/svg-editor.component';
import { CanDeactivateGuardService } from './shared/services/can-deactivate-guard.service';
import { SupportComponent } from './support/support.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { DashboardUserComponent } from './dashboard-user/dashboard-user.component';
import { SmartjacSigninComponent } from './auth/smartjac-signin/smartjac-signin.component';
import { SmartjacForgotPasswordComponent } from './auth/smartjac-forgot-password/smartjac-forgot-password.component';
import { SmartjacResetPasswordComponent } from './auth/smartjac-reset-password/smartjac-reset-password.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { DynamicDashboardComponent } from './dynamic-dashboard/dynamic-dashboard.component';
import { LoginScreenCustomiztionComponent } from './login-screen-customiztion/login-screen-customiztion.component';
import { DashboardRefreshTimeComponent } from './dashboard-refresh-time/dashboard-refresh-time.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  { path: 'signin', component: SigninComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'smartjac/signin', component: SmartjacSigninComponent },
  { path: 'smartjac/forgot-password', component: SmartjacForgotPasswordComponent },
  { path: 'smartjac/reset-password', component: SmartjacResetPasswordComponent },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['profile'] }]
  },
  {
    path: 'manage-blacklist',
    loadChildren: () => import('./manage-blacklist/manage-blacklist.module').then(m => m.ManageBlacklistModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['manage-blacklist'] }]
  },
  {
    path: 'manage-group-profile',
    loadChildren: () => import('./manage-group-profile/manage-group-profile.module').then(m => m.ManageGroupProfileModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['manage-group-profile'] }]
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['report'] }]
  },
  {
    path: 'api',
    loadChildren: () => import('./api-tree/api-tree.module').then(m => m.ApiTreeModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['api'] }]
  },
  {
    path: 'theme',
    component: UiCustomizationComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['theme'] }],
    canDeactivate: [CanDeactivateGuardService]
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['my-account'] }]
  },
  {
    path: 'notifications',
    component: NotificationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'governance-report',
    loadChildren: () => import('./governance-report/governance-report.module').then(m => m.GovernanceReportModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['governance-report'] }]
  },
  {
    path: 'access-management',
    loadChildren: () => import('./access-management/access-management.module').then(m => m.AccessManagementModule),
    canActivate: [AuthGuardService],
    data: [{ path: ['access-management'] }]
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['support'] }]
  },
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   canActivate: [AuthGuardService],
  //   data: [{ path: ['dashboard'] }]
  // },
  {
    path: 'dashboard',
    component: DynamicDashboardComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['dashboard'] }]
  },
  {
    path: 'dashboard-user',
    component: DashboardUserComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['dashboard-user'] }]
  },
  {
    path: 'campaign-editor',
    component: SvgEditorComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['campaign-editor'] }]
  },
  {
    path: 'email-setup',
    component: EmailConfigurationComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['email-setup'] }]
  },
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  },
  {
    path: 'customer/:custId',
    component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  },
  {
    path: 'customer/:pid/:custId', component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  }, {
    path: 'customer/:pid/:child1id/:custId', component: CustomerComponent,
    canActivate: [AuthGuardService],
  }, {
    path: 'customer/:pid/:child1id/:child2id/:custId', component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  },
  {
    path: 'customer/:pid/:child1id/:child2id/:child3id/:custId', component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  },
  {
    path: 'customer/:pid/:child1id/:child2id/:child3id/:child4id/:custId', component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  },
  {
    path: 'customer/:pid/:child1id/:child2id/:child3id/:child4id/:child5id/:custId', component: CustomerComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['customer'] }]
  },
  {
    path: 'login-theme',
    component: LoginScreenCustomiztionComponent,
    canActivate: [AuthGuardService],
    data: [{ path: ['login-theme'] }],
    // canDeactivate: [CanDeactivateGuardService]
  },
  {
    path: 'dashboard-refresh-time',
    component: DashboardRefreshTimeComponent,
    // canActivate: [AuthGuardService],
    // data: [{ path: ['dashboard-refresh-time'] }],
    // canDeactivate: [CanDeactivateGuardService]
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    {
      provide: 'canLoadTeamSection',
      useValue: (route: Route, segments: UrlSegment[]) => false
    }
  ]
})
export class AppRoutingModule { }
