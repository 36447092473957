import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { CommunicationService } from './communication.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuardService implements
  CanDeactivate<ComponentCanDeactivate> {

  canDeactivate(component: ComponentCanDeactivate) {

    if (!component.canDeactivate()) {

      if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
