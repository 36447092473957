<div class="device-analytics-main-container inner-main-profile">
    <div class="face-container-inner-bg">
		<div class="profile-manage-row result-not-found-row">
			<h4 class="result-not-found-row-heading">{{'Device Analytics Report' | translate}}</h4>
			<div class="d-flex ml-auto mr-3">
				<div class="align-items-center d-flex pr-2 mr-3">
					<div>
						<h6 class="pull-left mr-3 mb-0">{{'Timezone' | translate}} </h6>
					</div>
					<select class="custom-selection-dropdown date-range-dropdown outline" style="width: 345px;" [(ngModel)]="selectedTimezone" (ngModelChange)="getReportData();setTimezone();">
						<option value="{{tz.timezonekey}}" *ngFor="let tz of timezones">{{tz.timezoneName}}</option>
					</select>
					<span><i class="fa fa-caret-down caret-position"></i></span>
				</div>
				<div class="d-flex t-color active mr-3" *ngIf="features && features[getFeatureIndex('device_analytics')].edit">
					<div class="d-flex align-items-center">
						<div class="d-flex cursor-p operations-flex operations-flex-1" [ngClass]="{'disable-button': disableButton}" *ngIf="!mailSpinner" (click)="sendMailReport();">
							<svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="28.21" height="21.488" viewBox="0 0 28.21 21.488"><defs></defs><g transform="translate(0 -61)"><g transform="translate(0 61)"><path class="a" d="M25.731,61H2.479A2.484,2.484,0,0,0,0,63.479V80.009a2.483,2.483,0,0,0,2.479,2.479H25.731a2.483,2.483,0,0,0,2.479-2.479V63.479A2.483,2.483,0,0,0,25.731,61Zm-.342,1.653L14.158,73.884,2.83,62.653ZM1.653,79.667V63.814l7.961,7.892Zm1.169,1.169,7.966-7.966,2.791,2.767a.826.826,0,0,0,1.166,0l2.721-2.721,7.922,7.922Zm23.736-1.169-7.922-7.922,7.922-7.922Z" transform="translate(0 -61)"/></g></g></svg>
							<div>
								{{'Send Email' | translate}}
							</div>
						</div>
						<div class="btn-spinner mr-3" *ngIf="mailSpinner">
							<div class="loader quantum-spinner m-auto"></div>
						</div>
					</div>
				</div>
				<div class="t-color d-flex active" *ngIf="features && features[getFeatureIndex('device_analytics')].edit">
					<div class="d-flex align-items-center cursor-p operations-flex" [ngClass]="{'disable-button': disableButton}" *ngIf="!downloadSpinner" (click)="downloadCsv()">
						<svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><defs></defs><g transform="translate(-8 -8)"><path class="a" d="M25.5,8H22a.875.875,0,0,0,0,1.75h3.5a1.752,1.752,0,0,1,1.75,1.75v14a1.752,1.752,0,0,1-1.75,1.75h-14A1.752,1.752,0,0,1,9.75,25.5v-14A1.752,1.752,0,0,1,11.5,9.75H15A.875.875,0,1,0,15,8H11.5A3.5,3.5,0,0,0,8,11.5v14A3.5,3.5,0,0,0,11.5,29h14A3.5,3.5,0,0,0,29,25.5v-14A3.5,3.5,0,0,0,25.5,8Z"/><path class="a" d="M25.5,27.013l-2.006-2.006a.875.875,0,0,0-1.237,1.237l3.5,3.5a.875.875,0,0,0,1.237,0l3.5-3.5a.875.875,0,0,0-1.237-1.237L27.25,27.013V16.875a.875.875,0,1,0-1.75,0Z" transform="translate(-7.875 -4.5)"/></g></svg>
						<div>
							{{'Download' | translate}}
						</div>
					</div>
					<div class="btn-spinner mr-3" *ngIf="downloadSpinner">
						<div class="loader quantum-spinner m-auto"></div>
					</div>
				</div>
			</div>
		</div>
        <div class="report-head-section mb-3">
            <ul class="device-flex nav nav-pills mb-2 " id="pills-tab" role="tablist">
                <li class="nav-item" [ngClass]="{'active-link-parent': selectedTab ==='downloadTab'}">
                    <a class="nav-link" id="pills-download-report-tab" data-toggle="pill" href="#pills-download-report" role="tab" (click)="selectedTab='downloadTab';lastPage = 0;downloadLastPage = 0;getReportData();" aria-controls="pills-download-report" aria-selected="true">{{'Download Report' | translate}}</a>
                </li>
				<li class="nav-item" [ngClass]="{'active-link-parent': selectedTab ==='chartTab'}">
                    <a class="nav-link device-flex-title" id="pills-download-chart-tab" data-toggle="pill" href="#pills-download-chart" (click)="selectedTab='chartTab';getReportData();" role="tab" aria-controls="pills-download-chart" aria-selected="false">{{'Download Report Pie Chart' | translate}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active-link-parent': selectedTab ==='deletionTab'}">
                    <a class="nav-link device-flex-title" id="pills-deletion-report-tab" data-toggle="pill" href="#pills-deletion-report" (click)="selectedTab='deletionTab';lastPage = 0;deletionDownloadLastPage = 0;getReportData();" role="tab" aria-controls="pills-deletion-report" aria-selected="false">{{'Deletion Report' | translate}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active-link-parent': selectedTab ==='reEnableTab'}">
                    <a class="nav-link device-flex-title" id="pills-re-enable-report-tab" data-toggle="pill" href="#pills-re-enable-report" (click)="selectedTab='reEnableTab';lastPage = 0;reEnableDownloadLastPage = 0;getReportData();" role="tab" aria-controls="pills-re-enable-report" aria-selected="false">{{'Re-Download Report' | translate}}</a>
                </li>
            </ul>
        </div>
        
		
		<div class="border-bottom data-selection-container">
            <div >
                <div class="d-flex mb-3 pl-3 pr-3">
                    <div class="align-items-center pr-2 tac-input-width">
                        <label class="t-label pull-left mr-2">{{'TAC No.' | translate}} </label>
                        <input class="t-input form-control" type="text" placeholder="{{'Enter TAC No.' | translate}}" [(ngModel)]="tacNo" (ngModelChange)="getReportData()" [ngStyle]="{'cursor': selectedTab==='chartTab' ? 'not-allowed' : 'auto'}" [disabled]="selectedTab==='chartTab'">
                    </div>
					<div class="align-items-center pr-2 custom-width" *ngIf="show">
                        <label class="t-label pull-left mr-2">{{'Device Brand' | translate}} </label>
						<ng-multiselect-dropdown class="profile-type-select custom-multiselect-dropdown-arrow" id="profile-type-select" placeholder="{{'Select Brand' | translate}}..." [settings]="brandDropdownSettings" [data]="brandList" [(ngModel)]="selectedBrands" (ngModelChange)="getReportData();getModels();">
                        </ng-multiselect-dropdown>
                    </div>
					<div class="align-items-center pr-2 custom-width" *ngIf="show">
                        <label class="t-label pull-left mr-2">{{'Device Model' | translate}} </label>
						<ng-multiselect-dropdown class="profile-type-select device-model custom-multiselect-dropdown-arrow" id="profile-type-select" placeholder="{{'Select Model' | translate}}..." [settings]="modelDropdownSettings" [data]="modelList" [(ngModel)]="selectedModels" (ngModelChange)="getReportData();">
                        </ng-multiselect-dropdown>
                    </div>
					<div class="align-items-center pr-2">
						<div>
							<label class="t-label pull-left mr-2">{{'Date Range' | translate}} </label>
						</div>
                        <select class="custom-selection-dropdown date-range-dropdown outline" [ngClass]="{'date-range-fit': language === 'ru' && dateRange !== 'custom'}" [(ngModel)]="dateRange" (ngModelChange)="getReportData()">
							<option value="today">{{'Today' | translate}}</option>
                            <option value="yesterday">{{'Yesterday' | translate}}</option>
                            <option value="week">{{'Week-to-Date' | translate}}</option>
                            <option value="month">{{'Month-to-Date' | translate}}</option>
                            <option value="year">{{'Year-to-Date' | translate}}</option>
							<option value="custom">{{'Custom' | translate}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position" ></i></span>
                    </div>
					<div class="align-items-center pr-2" *ngIf="dateRange === 'custom'">
						<div>
							<label class="t-label pull-left mr-2">{{'Select Date'| translate}}</label>
						</div>
                        <input class="t-input report-date-range" style="width: 225px;" [(ngModel)]="selectedMoments" (ngModelChange)="getReportData()" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'">
                        <owl-date-time [pickerType]="'calendar'" #dt10></owl-date-time>
                    </div>
                    <div class="align-items-center pr-2 custom-width-search">
                        <label class="t-label pull-left mr-2">{{'Search EID' | translate}} </label>
                        <input class="t-input form-control" type="text" placeholder="{{'Search here...' | translate}}" [(ngModel)]="eid" (ngModelChange)="getReportData()" [ngStyle]="{'cursor': selectedTab==='chartTab' ? 'not-allowed' : 'auto'}" [disabled]="selectedTab==='chartTab'">
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content device-flex-body" id="pills-tabContent">
            <div class="tab-pane active" id="pills-download-report" role="tabpanel" aria-labelledby="pills-download-report-tab">
                <div class="p-2 mb-3">
                    <div class="row">
						<div class="col-lg-12">
							<div class="t-grid-table-new">
								<div class="t-grid-table-wrapper t-head-bg">
									<div class="t-box">{{'EID' | translate}}</div>
									<div class="t-box">{{'TAC No.' | translate}}</div>
									<div class="t-box">{{'Device Type' | translate}}</div>
									<div class="t-box">{{'Brand' | translate}}</div>
									<div class="t-box">{{'Model' | translate}}</div>
									<div class="t-box">{{'Downloaded' | translate}}</div>
									<div class="t-box">{{'Re-Downloaded' | translate}}</div>
									<div class="t-box">{{'Downloaded On' | translate}}</div>
									<div class="t-box"></div>
								</div>
								<div class="loader-flex-center" *ngIf="spinner">
									<div class="loader quantum-spinner m-auto"></div>
								</div>
								<div *ngIf="downloadReportList && downloadReportList.length <= 0 && !spinner && !infiniteSpinner">
									<div class="text-center mt-2 mb-2">
										<div class="text-center">{{'No Data Found' | translate}}.</div>
									</div>
								</div>
								<div class="t-body-overflow">
									<div class="t-grid-table-wrapper t-body-bg" *ngFor="let item of downloadReportList;let i = index;">
										<div class="t-box d t-font-light">{{item.eid}}</div>
										<div class="t-box d t-font-light">{{item.tac ? item.tac : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.type ? item.type : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.brand ? item.brand : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.model ? item.model : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.download}}</div>
										<div class="t-box d t-font-light">{{item.redownload}}</div>
										<div class="t-box d t-font-light">{{item.timestamp | date: "dd-MM-yyyy, h:mm:ss a"}}</div>
										<div class="t-box d t-font-light d-flex align-items-center inner-main-profile">
											<a class="c-color c-info py-0" (click)="openEidInfo(i)">
												<i class="fa fa-info mr-3" style="width: 16px;height: 16px;font-size: 11px;"></i>
											</a>
										</div>
									</div>
									<div class="btn-spinner py-3" *ngIf="infiniteSpinner">
										<div class="loader quantum-spinner m-auto"></div>
									</div>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show" id="pills-download-chart" role="tabpanel" aria-labelledby="pills-download-chart-tab">
                <div class="p-2 mb-3">
                    <div class="row">
						<!-- <span>download-chart</span> -->
						<div class="col-lg-12 canvas-device-analytics">
							<div class="d-flex pie-chart-overflow" *ngIf="!spinner && downloadChartReportList && downloadChartReportList.length > 0 && showChart">
								<div class="pie-chart-tab" >
	
									<canvas baseChart 
									[data]="pieChartData" 
									[labels]="pieChartLabels" 
									[chartType]="pieChartType"
									[options]="pieChartOptions"
									[legend]="pieChartLegend"
									[colors]="barChartColors"
									width="400" height="250"
									style="height: 353px;">
									</canvas>
								</div>
								<div class="canvas-text-section">
									<p class="mb-2 text-center h5">{{ 'Total Downloads' | translate }}</p>
									<div class="d-flex align-items-center mb-2" *ngFor="let item of downloadChartReportList;">
										<div [ngStyle]="{'background-color': item.selectedColor}" style="width: 40px; height: 30px; flex-shrink: 0; margin-right: 15px;"></div>
										<p class="mb-0 mr-5 canvas-device-analytics-item-name">{{selectedModels.length > 0 ? item.model : item.brand}}</p>
										<div class="d-flex align-items-center">
											<!-- {{'Total Download For' | translate}}  -->
<!-- <p class="canvas-device-analytics-item-count" style="margin-bottom: 0;">{{selectedModels.length > 0 ? item.model : item.brand}}: </p> -->
											<div><p style="margin-bottom: 0;padding-right: 5px;">{{item.count}}</p></div>
										</div>
									</div>
								</div>
							</div>
	
							<div class="col-lg-12">
								<div class="loader-flex-center" *ngIf="spinner">
									<div class="loader quantum-spinner m-auto"></div>
								</div>
								<div class="d-flex align-items-center justify-content-center" *ngIf="!spinner && downloadChartReportList && downloadChartReportList.length <= 0">
									<p>{{ 'No Data Found.' | translate }}</p>
								</div>
							</div>
						</div>
                        
						
					</div>

					<div class="row">
						<div class="col-lg-12 canvas-device-analytics">
							<div class="d-flex pie-chart-overflow" *ngIf="!spinner && downloadChartReportList && downloadChartReportList.length > 0">
								<canvas baseChart id="pieChart" 
										[data]="pieChartData" 
										[labels]="pieChartLabels2" 
										[chartType]="pieChartType"
										[options]="pieChartOptions2"
										[legend]="pieChartLegend"
										[colors]="barChartColors"
										width="400" height="250"
										style="height: 353px;display: none;">
										</canvas>
							</div>

						</div>
					</div>
					
					
                </div>
            </div>
            <div class="tab-pane fade show" id="pills-deletion-report" role="tabpanel" aria-labelledby="pills-deletion-report">
                <div class="p-2 mb-3">
                    <div class="row">
						<div class="col-lg-12">
							<div class="t-grid-table-new">
								<div class="t-grid-table-wrapper-deletion t-head-bg">
									<div class="t-box">{{'Device Type' | translate}}</div>
									<div class="t-box">{{'Brand' | translate}}</div>
									<div class="t-box">{{'Model' | translate}}</div>
									<div class="t-box">{{'EID' | translate}}</div>
									<div class="t-box">{{'ICCID' | translate}}</div>
									<div class="t-box">{{'TAC No.' | translate}}</div>
									<div class="t-box">{{'Deleted On' | translate}}</div>
								</div>
								<div class="loader-flex-center" *ngIf="spinner">
									<div class="loader quantum-spinner m-auto"></div>
								</div>
								<div *ngIf="deletionReportList && deletionReportList.length <= 0 && !spinner && !infiniteSpinner">
									<div class="text-center mt-2 mb-2">
										<div class="text-center">{{'No Data Found' | translate}}.</div>
									</div>
								</div>
								<div class="t-body-overflow">
									<div class="t-grid-table-wrapper-deletion t-body-bg" *ngFor="let item of deletionReportList">
										<div class="t-box d t-font-light">{{item.deviceType ? item.deviceType : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.brand ? item.brand : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.model ? item.model : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.eid}}</div>
										<div class="t-box d t-font-light">{{item.iccid}}</div>
										<div class="t-box d t-font-light">{{item.tacNo ? item.tacNo : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.deletionDate | date: "dd-MM-yyyy, h:mm:ss a"}}</div>
									</div>
									<div class="btn-spinner py-3" *ngIf="infiniteSpinner">
										<div class="loader quantum-spinner m-auto"></div>
									</div>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show" id="pills-re-enable-report" role="tabpanel" aria-labelledby="pills-re-enable-report-tab">
                <div class="p-2 mb-3">
                    <div class="row">
						<div class="col-lg-12">
							<div class="t-grid-table-new">
								<div class="t-grid-table-wrapper-re-enable t-head-bg">
									<div class="t-box">{{'Device Type' | translate}}</div>
									<div class="t-box">{{'Brand' | translate}}</div>
									<div class="t-box">{{'Model' | translate}}</div>
									<div class="t-box">{{'EID' | translate}}</div>
									<div class="t-box">{{'ICCID' | translate}}</div>
									<div class="t-box">{{'TAC No.' | translate}}</div>
									<div class="t-box">{{'Status' | translate}}</div>
									<div class="t-box">{{'Re-Downloaded On' | translate}}</div>
								</div>
								<div class="loader-flex-center" *ngIf="spinner">
									<div class="loader quantum-spinner m-auto"></div>
								</div>
								<div *ngIf="reEnableReportList && reEnableReportList.length <= 0 && !spinner && !infiniteSpinner">
									<div class="text-center mt-2 mb-2">
										<div class="text-center">{{'No Data Found' | translate}}.</div>
									</div>
								</div>
								<div class="t-body-overflow">
									<div class="t-grid-table-wrapper-re-enable t-body-bg" *ngFor="let item of reEnableReportList">
										<div class="t-box d t-font-light">{{item.deviceType ? item.deviceType : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.brand ? item.brand : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.model ? item.model : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.eid}}</div>
										<div class="t-box d t-font-light">{{item.iccid}}</div>
										<div class="t-box d t-font-light">{{item.tacNo ? item.tacNo : 'NA'}}</div>
										<div class="t-box d t-font-light">{{item.status}}</div>
										<div class="t-box d t-font-light">{{item.timestamp | date: "dd-MM-yyyy, h:mm:ss a"}}</div>
									</div>
									<div class="btn-spinner py-3" *ngIf="infiniteSpinner">
										<div class="loader quantum-spinner m-auto"></div>
									</div>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- eid info start -->

<div class="modal fade" id="eidInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "EID Information" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body device-flex-body">
                <div class="device-analytics-main-container">
					<div class="d-flex">
						<h5 class="mb-2 info-title"><span class="mr-2">EID:</span><span class="t-color">{{infoEid}}</span></h5>
					</div>
                    <div class="t-grid-table-new">
						<div class="t-grid-table-wrapper-eid-info t-head-bg">
							<div class="t-box a">{{'ICCID' | translate}}</div>
                            <div class="t-box b">{{'Downloaded On' | translate}}</div>
						</div>
						<div class="loader-flex-center" *ngIf="downloadReportInfoSpinner">
							<div class="loader quantum-spinner m-auto"></div>
						</div>
						<div *ngIf="downloadReportList && downloadReportList.length <= 0 && !downloadReportInfoSpinner">
							<div class="text-center mt-2 mb-2">
								<div class="text-center">{{'No Data Found' | translate}}.</div>
							</div>
						</div>
						<div class="eid-info-body-overflow">
							<div class="t-grid-table-wrapper-eid-info t-body-bg" *ngFor="let item of downloadReportInfoList">
								<div class="t-box d t-font-light">{{item.iccid}}</div>
                                <div class="t-box d t-font-light">{{item.timestamp | date: "dd-MM-yyyy, h:mm:ss a"}}</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- eid info end -->