import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { CookieTrailService } from 'src/app/shared/services/cookie-trail.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { UserState } from 'src/app/shared/stores/user/user.reducer';

@Component({
  selector: 'app-total-download',
  templateUrl: './total-download.component.html',
  styleUrls: ['./total-download.component.scss']
})
export class TotalDownloadComponent implements OnInit {

  totalDownload = 131
  features;
  formatter;
  socket;
  customerId;
  dashboardData;
  manualRefresh = false;
  isDashboardUser = false;

  constructor(private cookieTrailService: CookieTrailService,
    private appComponent: AppComponent,
    private userStore: Store<UserState>,
    private communicationService: CommunicationService,
    private dashboardService: DashboardService,
    private translate: TranslateService) { }
  ngOnInit(): void {
    this.cookieTrailService.saveCookie({});
    this.socket = this.appComponent.socket;

    this.formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,      
      maximumFractionDigits: 0,
    });

    localStorage.setItem('cookieTrail', JSON.stringify({}));
    
    this.userStore.select('user')
    .subscribe((user: any) => {
      if(user) {
        this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Dashboard')].features;
        this.getDashboardData();

        if(user.data.currentCustomer) {
          this.customerId = user.data.currentCustomer._id;
        } else {
          this.customerId = user.data.rootCustomer._id;
        }

        // if(this.features && this.features[this.getFeatureIndex('db_device_analytics')].view) {
        //   this.getDeviceAnalytics();
        // }
        
        this.socket.emit('csspuser', user.data._id);
        this.socket.on('bellnotification', (notiData) => {
         this.dashboardData.notification.unshift(notiData);
        });

        if(user.data.roleNames.includes('dashboard-user') && user.data.roleType === 'default') {
          this.isDashboardUser = true;
          this.setWidgetInvterval();
        }
      }
    });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    
    this.communicationService.getRefreshDashboardAction()
    .subscribe((res: any) => {
      this.getDashboardData();
    });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  setWidgetInvterval() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        
        if (res.refreshTimeSetting.length > 0) {
          let index = res.refreshTimeSetting.findIndex(ele => ele.name === 'eSIM installed on Device');
          if(res.refreshTimeSetting[index].time != 'manual') { 
            
            setInterval(() => {
              this.getDashboardData();
            }, Number(res.refreshTimeSetting[index].time));
            this.manualRefresh = false;
          } else {
            this.manualRefresh = true;
          }
        } else {
          setInterval(() => {
            this.getDashboardData();
          }, 60000 * 1)
        }
      });
  }

  getDashboardData() {
    this.dashboardService.getDashboardData()
    .subscribe((res: any) => {
      this.dashboardData = res;

      res.notification.forEach((element: any) => {
        if(element.message.includes('PRP Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('PRP Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Campaign Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Reset Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Reset Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Prepare Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QR Code Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QRCode - ')) {
          let msg1 = element.message.split('QRCode');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;
          this.translate.get('QRCode')
            .subscribe((res: any) => {
              qrCode = res;
              this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage = qrCode + msg2[0] + mailTo + msg2[1];
              });
            });
        } else if(element.message.includes('Prepare - ')) {
          let msg1 = element.message.split('Prepare');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;
          this.translate.get('Prepare')
            .subscribe((res: any) => {
              qrCode = res;
              this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage = qrCode + msg2[0] + mailTo + msg2[1];
              });
            });
        } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else {
          element.transMessage = element.message;
        }
      });
    }, err => {
      const cookieTrail = {
        screenName: 'Dashboard',
        action: 'Get Dashboard Data',
        error: err.error || 'Something went wrong!',
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);
      this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
    });
  }

  changeLanguage() { 

  }

  refresh() {
    this.getDashboardData();
  }
}
