<div class="inner-main-profile">
    <div class="face-container-inner-bg mb-3">
        <div class="border-bottom p-3 t-head-title">
            {{"All Notifications" | translate}}
        </div>
        <div class="col-lg-12 show-notification-overflow" *ngIf="notiSpinner">
            <div class="row border-bottom-light d-block py-3 p-2">
                <div class="loader-flex-center">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 show-notification-overflow" *ngIf="notificationList && notificationList.length <= 0 && !notiSpinner">
            <div class="row border-bottom-light d-block py-3 p-2">
                <div>
                    <div class="text-center">Notifications Not Found.</div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 show-notification-overflow" *ngIf="!notiSpinner">
            <div class="row border-bottom-light py-3 p-2" *ngFor="let notification of notificationList">
                <div class="col-lg-9">
                    {{ notification.transMessage | translate }}
                </div>
                <div class="col-lg-3 t-font-light">
                    {{ notification.created | date: "MMM d, y, hh:mm:ss a" }}
                </div>
            </div>
        </div>

    </div>
</div>