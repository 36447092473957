import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupMgmtService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  createGroup(data) {
    return this.http.post(`${this.serverUrl}/group`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileTypes() {
    return this.http.get(`${this.serverUrl}/profileType/fetchProfileTypes`, this.getHeader())
      .pipe(map(response => response));
  }

  getGroupProfileTypes() {
    return this.http.get(`${this.serverUrl}/groups/fetchProfileTypes`, this.getHeader())
      .pipe(map(response => response));
  }

  getAllGroups() {
    return this.http.post(`${this.serverUrl}/group/fetch-groups`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  getSingleGroup(id) {
    return this.http.get(`${this.serverUrl}/group/${id}`, this.getHeader())
      .pipe(map(response => response));
  }

  editGroup(data) {
    return this.http.put(`${this.serverUrl}/group/${data.id}`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getCustomerUserGroups() {
    return this.http.get(`${this.serverUrl}/group/customer-groups`, this.getHeader())
      .pipe(map(response => response));
  }

  getCustomerUserPFGroups() {
    return this.http.get(`${this.serverUrl}/group/customer-PFGroup-List`, this.getHeader())
      .pipe(map(response => response));
  }

  getUnassignedGroups() {
    return this.http.get(`${this.serverUrl}/group/unassigned-groups`, this.getHeader())
      .pipe(map(response => response));
  }

  getUnassignedGroupList(id) {
    return this.http.get(`${this.serverUrl}/group/unassigned-groups?groupingId=${id}`, this.getHeader()).pipe(map(response => response));
  }

  deleteGroup(id) {
    return this.http.delete(`${this.serverUrl}/group/${id}`, this.getHeader())
      .pipe(map(response => response));
  }
}
