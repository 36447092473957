<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">{{'Group Management' | translate}}</h4>
                    <div class="d-flex ml-auto" [routerLink]="['/access-management/group/create']">
                        <button class="btn t-bgcolor outline"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Create New Group' | translate}}</button>
                    </div>
                </div>
                
                <div class="t-grid-table-new">
                    <div class="t-grid-table-wrapper-group t-head-bg">
                        <div class="t-box a pl-2">{{'Group Name' | translate}}</div>
                        <div class="t-box b">{{'Group Setup' | translate}}</div>
                        <div class="t-box c">{{'Total ICCIDs' | translate}}</div>
                        <div class="t-box d"></div>
                    </div>
                    <div class="loader-flex-center" *ngIf="groupSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                    <div *ngIf="groupList && groupList.length <= 0 && !groupSpinner">
                        <div class="text-center mt-2 mb-2">
                            <div class="text-center">{{'No Groups Found' | translate}}.</div>
                        </div>
                    </div>
                    <div class="t-body-overflow" *ngIf="!groupSpinner && groupList && groupList.length > 0">
                        <div class="accordion" id="groupAccordian">
                            <ng-container *ngFor="let group of groupList; let i = index;">
                                <div class="t-grid-table-wrapper-group t-body-bg border-bt-ext-light">
                                    <div class="t-box d t-font-light pl-2">{{group.groupName}}</div>
                                    <div class="t-box d t-font-light">{{group.groupType}}</div>
                                    <div class="t-box d t-font-light">{{group.iccidCount}}</div>
                                    <div class="t-box d t-font-light">
                                        <div class="group-management-icon-container">
                                            <a data-toggle="collapse" href="#groupCollapseOne_{{i}}" aria-expanded="true" aria-controls="groupCollapseOne" (click)="getSingleGroup(group._id); iccidList=[];">
                                                <img src="assets/images/svg-editor/info.svg" alt="" class="">
                                            </a>
                                            <a aria-hidden="true" [routerLink]="['/access-management/group/edit', group._id]">
                                                <img src="assets/images/svg-editor/edit.svg" alt="" class="mr-2 ml-2">
                                            </a>
                                            <a data-toggle="modal" data-target="#deleteGroupModal" aria-hidden="true" (click)="selectedIndex = i;">
                                                <img src="assets/images/svg-editor/delete.svg" alt="" class="">
                                            </a>
                                            <!-- <a class="gm-icon-container-inner fa-info-parent mr-2" data-toggle="collapse" href="#groupCollapseOne_{{i}}" aria-expanded="true" aria-controls="groupCollapseOne" (click)="getSingleGroup(group._id); iccidList=[];">
                                                <i class="fa fa-info"></i>
                                            </a>
                                            <div class="gm-icon-container-inner ">
                                                <i class="fa fa-pencil-square-o t-color cursor-p mr-2" aria-hidden="true" [routerLink]="['/access-management/group/edit', group._id]"></i>
                                            </div>
                                            <div class="gm-icon-container-inner">
                                                <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteGroupModal" aria-hidden="true" (click)="selectedIndex = i;"></i>
                                            </div> -->
                                        </div>    
                                    </div>
                                </div>
                                <div id="groupCollapseOne_{{i}}" class="collapse" aria-labelledby="headingOneA" data-parent="#groupAccordian">
                                    <div colspan="5" class="py-0">
                                        <div id="collapseOne" class="collapse show" aria-labelledby="autoCollapseOne" data-parent="#autoAccordionExample1">
                                            <div class="card-body info-new-operation">
                                                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                    <li class="nav-item info-btn-container-individual" style="border-top-left-radius: 6px;border-top-right-radius: 6px;">
                                                        <a class="nav-link btn btn-logs active btn-log-active blacklist-border" id="info-blackhistory-tab_{{i}}" data-toggle="pill" href="#info-blacklist-history_{{i}}" role="tab" aria-controls="info-log" aria-selected="true">
                                                            {{'Group Details' | translate}}
                                                        </a>
                                                    </li>
                                                    <li class="download-n-close ml-auto">
                                                        <a class="filter-download c-color c-info" (click)="getIccidList(i)" *ngIf="iccidList && iccidList.length > 0"><i class="fa fa-download"></i> <span class="ml-2"> {{ "Download" | translate}}</span></a>
                                                        <a class="inner-filter-table-close ml-3 t-font-light" data-toggle="collapse" href="#groupCollapseOne_{{i}}">
                                                            X
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade show active" id="info-blacklist-history_{{i}}" role="tabpanel" aria-labelledby="info-log-tab">
                                                        <table class="table t-table t-filter-inner-table table-scroll">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">{{"ICCID" | translate}}</th>
                                                                    <th scope="col">{{"Profile Type" | translate}}</th>
                                                                    <th scope="col" *ngIf="!currentCustomer || !(currentCustomer && currentCustomer.isParent)">{{"Parent Group" | translate}}</th>
                                                                    <th scope="col">{{"Status" | translate}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                                <div class="loader quantum-spinner m-auto"></div>
                                                            </tbody>
                                                            <tbody *ngIf="iccidList && iccidList.length <= 0 && !infoSpinner">
                                                                <tr>
                                                                    <td colspan="5" class="text-center">{{'No ICCID Found' | translate}}.</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="!infoSpinner" style="max-height: calc(95vh - 435px);">
                                                                <tr *ngFor="let item of iccidList">
                                                                    <td>{{item.iccid}}</td>
                                                                    <td>{{item.profileType}}</td>
                                                                    <td *ngIf="!currentCustomer || !(currentCustomer && currentCustomer.isParent)">{{item.parentGroupName ? item.parentGroupName : 'NA'}}</td>
                                                                    <td>{{item.state}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>    
                            </ng-container>
                        </div>
                        
                    </div> 
                </div>

            </div>
        </div>
    </div>
</div> 

<div class="modal fade" id="deleteGroupModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delete Group Confirmation' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center p-4">
                <p>{{'Are you sure you want to delete' | translate}} '{{groupList && groupList[selectedIndex] ? ' ' + groupList[selectedIndex].groupName : ''}}'</p>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!deleteSpinner" (click)="deleteGroup()"> {{'Confirm' | translate}}</button>
                <div class="btn-spinner" *ngIf="deleteSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>