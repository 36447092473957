import { Action } from '@ngrx/store';
import { ManageGroupFilter } from './filter.model';

export const GET_MANAGE_GROUP_QR_FILTER = 'GET_MANAGE_GROUP_QR_FILTER';

export class GetManageGroupQrFilter implements Action {
    readonly type = GET_MANAGE_GROUP_QR_FILTER;

    constructor(public payload: ManageGroupFilter) { }
}

export type FilterActions = GetManageGroupQrFilter;
