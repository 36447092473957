import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../shared/services/report.service';
import { CommunicationService } from '../shared/services/communication.service';
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import * as papa from 'papaparse';
import { saveAs } from 'file-saver';
import { ProfileService } from '../shared/services/profile.service';
import { Color, Label } from 'ng2-charts';
import { Chart, ChartDataSets } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { UserState } from '../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { GroupMgmtService } from '../shared/services/group-mgmt.service';
import { environment } from '../../environments/environment';
import { timezones } from './report';
import { UserService } from '../shared/services/user.service';
import * as UserAction from '../shared/stores/user/user.action'
import { Subscription } from 'rxjs';
import { GroupProfileService } from '../shared/services/group-profile.service';
import { colors } from '../shared/assets/color';
import { ThemeState } from '../shared/stores/theme/theme.reducer';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
declare var $: any;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class ReportComponent implements OnInit {

  chartLabel = 'No. Of ICCID Downloaded';
  groupChartLabel = 'Total Downloaded';
  groupChartRemainingLabel = 'Remaining';
  totalLabel = 'Total Download';
  yAxisLabelString;
  tabId;
  inventoryList;
  dataSpinner = true;
  public selectedMoments = [
    moment(new Date()).subtract(14, 'day'),
    moment(new Date())
  ];
  profileTypeList;
  selectedProfileTypes;
  tempSelectedProfileTypes = [];
  profileDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'name',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  show = false;
  lineChartData: ChartDataSets[];
  prepareBarChartData: ChartDataSets[];
  resetBarChartData: ChartDataSets[];
  qrBarChartData: ChartDataSets[];
  qrlineChartData: ChartDataSets[];

  dateRange = 'month';

  public lineChartLabels: Label[];
  public lineChartColors: Color[] = [
    {
      borderColor: '#47e1af',
      backgroundColor: 'transparent',
    },
  ];
  public lineChartDownloadColors: Color[] = [
    {
      borderColor: '#FF9016',
      backgroundColor: 'transparent',
    },
  ];
  public barChartColors: Color[] = [
    {
      borderColor: '#FF9016',
      backgroundColor: '#FF9016',
    },
    {
      borderColor: '#FC5000',
      backgroundColor: '#FC5000',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public lineChartOptions;
  public tdChartOptions;

  public barChartOptions;

  filterBy = 'Day';
  downloadReportList;
  prepareReportList;
  resetReportList;
  qrReportList;
  reportSpinner = true;
  sendMailSpinner = false;
  features;
  isView = true;
  user;
  selectProfileFlg = false;
  groupList;
  selectedGroups;
  selectedIdGroups = [];
  groupDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'groupName',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  currentUrl;
  downloadResults = [];
  totalDownload = [];
  totalSum;
  selectedCount = 0;
  selectedList = [];
  select = false;
  option;
  selectedGroup = [];
  inventoryReport = [];
  downloadReportSpinner = false;

  timezones = timezones;
  selectedTimezone = 'Etc/UTC';
  timezoneSubscription: Subscription;
  inventoryListQR = [];
  groupNameDropdownSettings = {
    singleSelection: false,
    idField: 'groupName',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'groupName',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  groupNameList;
  selectedGroupNames = [];
  tempSelectedGroupNames = [];
  fromDate = moment(new Date()).startOf('month');
  toDate = new Date();
  groupProfileTypeList;
  selectedGroupProfileTypes;
  tempSelectedGroupProfileTypes = [];
  isParent;
  dynamicGraphs = [];
  checkboxDisable = false;
  dynamicQrData = [];
  dynamicQrLabels = [];

  isTotalDownloadPublic = true;
  isTotalDownloadRemainingCountPublic = true;
  totalDownlodGraphData = [];
  tdVsRCData = [];
  public lineChartLegendTD = false;
  ctx: any;
  tdChart;
  allDataObj = [];
  tooltipLabel = '';
  totalDownloadRemainingCouneSubscription: Subscription;
  totalDownloadubscription: Subscription;
  totalDownloadAllData;
  subPerf;
  prepareSubscription: Subscription;
  downloadReportSubscription: Subscription;
  resetReportSubscription: Subscription;
  inventoryReportSubscription: Subscription;
  load = false;
  max = moment();
  language;
  sendMailTitle = 20;
  public tdVsRclineChartLabels: Label[];
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private communicationService: CommunicationService,
    private profileService: ProfileService,
    private translate: TranslateService,
    private userStore: Store<UserState>,
    private cookieTrailService: CookieTrailService,
    private groupService: GroupMgmtService,
    private userService: UserService,
    private grpProfileService: GroupProfileService,
    private element: ElementRef,
    private router: Router,
    private themeStore: Store<ThemeState>) { 

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      // const browserLang = translate.getBrowserLang();
      this.language = localStorage.getItem('language');
      if (!this.language) {
        this.language = 'en';
        localStorage.setItem('language', this.language);
      }
      translate.use(this.language.match(environment.langRegex) ? this.language : 'en');
      this.translate.get('No of Downloads').subscribe((res: string) => { 
        this.yAxisLabelString = res;
        this.initializeGraphs();
      });
      translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.profileDropdownSettings.selectAllText = res;
        this.groupDropdownSettings.selectAllText = res;
        this.refreshDropSettings();
      });
      translate.get('Search').subscribe((res: string) => { 
        this.show = false;       
        this.profileDropdownSettings.searchPlaceholderText = res;
        this.groupDropdownSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });
      translate.get('No. Of ICCID Downloaded').subscribe((res: string) => { 
        this.chartLabel = res;
      });
      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.profileDropdownSettings.unSelectAllText = res;
        this.groupDropdownSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.profileDropdownSettings.noDataAvailablePlaceholderText = res;
        this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
        this.groupNameDropdownSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });
      translate.get('Total Downloaded').subscribe((res: string) => { 
        this.groupChartLabel = res;
        this.refreshDropSettings();
      });
      translate.get('Remaining').subscribe((res: string) => { 
        this.groupChartRemainingLabel = res;
        this.refreshDropSettings();
      });
      translate.get('Total Download').subscribe((res: string) => { 
        this.totalLabel = res;
        this.refreshDropSettings();
      });
    }

  ngOnInit() {

    this.currentUrl = window.location.pathname;
    this.cookieTrailService.saveCookie({});
    // this.getProfileTypes();
    if (window.innerWidth <= 1390) {
      this.sendMailTitle = 15;
    } else if(window.innerWidth <= 1600) {
      this.sendMailTitle = 20;
    } else {
      this.sendMailTitle = 34;
    }
    this.userStore.select('user')
      .subscribe((res: any) => {
        if(res) {
          this.user = Object.assign({}, res);
          this.selectedTimezone = res.data.timezone;
          if(!this.user.data.currentCustomer && (this.user.data.rootCustomer && this.user.data.rootCustomer.isParent)) {
            this.isParent = true;
            this.selectProfileFlg = true;
            this.load = false;
            this.getProfileTypes();
            this.getGroupProfileTypeList();
          } else if(this.user.data.currentCustomer && this.user.data.currentCustomer.isParent){
            this.isParent = true;
            this.selectProfileFlg = true;
            this.load = false;
            this.getProfileTypes();
            this.getGroupProfileTypeList();
          } else {
            this.isParent = false;
            this.selectProfileFlg = false;
            this.load = false;
            this.getAllCustomerGroup();
          }
        }
        
        if(res && res.featureRole && res.featureRole.length > 0) {
          this.features = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'Reports')].features;
          if(window.location.pathname.includes('download') && this.features && this.features[this.getFeatureIndex('download_report')].edit) {
            this.isView = true;
          } else if(window.location.pathname.includes('download') && this.features && !this.features[this.getFeatureIndex('download_report')].edit) {
            this.isView = false;
          }

          if(window.location.pathname.includes('prepare') && this.features && this.features[this.getFeatureIndex('prepare_report')].edit) {
            this.isView = true;
          } else if(window.location.pathname.includes('prepare') && this.features && !this.features[this.getFeatureIndex('prepare_report')].edit) {
            this.isView = false;
          }

          if(window.location.pathname.includes('reset') && this.features && this.features[this.getFeatureIndex('reset_report')].edit) {
            this.isView = true;
          } else if(window.location.pathname.includes('reset') && this.features && !this.features[this.getFeatureIndex('reset_report')].edit) {
            this.isView = false;
          }

          if(window.location.pathname.includes('grp-qr-code') && this.features && this.features[this.getFeatureIndex('qrcode_report')].edit) {
            this.isView = true;
          } else if(window.location.pathname.includes('grp-qr-code') && this.features && !this.features[this.getFeatureIndex('qrcode_report')].edit) {
            this.isView = false;
          }

          if(window.location.pathname.includes('inventory') && this.features && this.features[this.getFeatureIndex('inventory_report')].edit) {
            this.isView = true;
          } else if(window.location.pathname.includes('inventory') && this.features && !this.features[this.getFeatureIndex('inventory_report')].edit) {
            this.isView = false;
          }
        }
        this.activatedRoute.params
          .subscribe((params: any) => {
            if(this.user) {
              this.qrBarChartData = null;
              this.resetBarChartData = null;
              this.lineChartData = null;
              this.qrlineChartData = null;
              this.prepareBarChartData = null;
              this.tabId = params.id;
              const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
      
              this.tempSelectedProfileTypes = [];
              if(this.selectedProfileTypes && this.selectedProfileTypes.length > 0) {
                for(let i = 0; i < this.selectedProfileTypes.length; i++) {
                  this.createProfileTypes(this.selectedProfileTypes[i].name);
                }
              }
      
              if(window.location.pathname.includes('download') && this.features && this.features[this.getFeatureIndex('download_report')].edit) {
                this.isView = true;
              } else if(window.location.pathname.includes('download') && this.features && !this.features[this.getFeatureIndex('download_report')].edit) {
                this.isView = false;
              }
      
              if(window.location.pathname.includes('prepare') && this.features && this.features[this.getFeatureIndex('prepare_report')].edit) {
                this.isView = true;
              } else if(window.location.pathname.includes('prepare') && this.features && !this.features[this.getFeatureIndex('prepare_report')].edit) {
                this.isView = false;
              }
      
              if(window.location.pathname.includes('reset') && this.features && this.features[this.getFeatureIndex('reset_report')].edit) {
                this.isView = true;
              } else if(window.location.pathname.includes('reset') && this.features && !this.features[this.getFeatureIndex('reset_report')].edit) {
                this.isView = false;
              }
      
              if(window.location.pathname.includes('grp-qr-code') && this.features && this.features[this.getFeatureIndex('qrcode_report')].edit) {
                this.isView = true;
              } else if(window.location.pathname.includes('grp-qr-code') && this.features && !this.features[this.getFeatureIndex('qrcode_report')].edit) {
                this.isView = false;
              }
      
              if(window.location.pathname.includes('inventory') && this.features && this.features[this.getFeatureIndex('inventory_report')].edit) {
                this.isView = true;
              } else if(window.location.pathname.includes('inventory') && this.features && !this.features[this.getFeatureIndex('inventory_report')].edit) {
                this.isView = false;
              }

              if (this.tabId === 'inventory') {
                this.select = false;
                this.dataSpinner = true;
                this.inventoryList = '';
                this.getInventory();
              }
      
              if(this.load) {
                if (this.tabId === 'download') {

                  this.max = moment();
                  const data = {
                    fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
                    groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
                    dateRange: this.dateRange,
                    timezoneName,
                    timezonekey: this.selectedTimezone
                  };
        
                  this.getDownloadReport(data, this.filterBy);
                } else if (this.tabId === 'prepare') {
                  this.max = moment();
                  const data = {
                    fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
                    groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
                    dateRange: this.dateRange,
                    timezoneName,
                    timezonekey: this.selectedTimezone
                  };
        
                  this.getPrepareReport(data, this.filterBy);
                } else if (this.tabId === 'reset') {
                  this.max = moment();
                  const data = {
                    fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
                    groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
                    dateRange: this.dateRange,
                    timezoneName,
                    timezonekey: this.selectedTimezone
                  };
        
                  this.getResetReport(data, this.filterBy);
                } else if (this.tabId === 'grp-qr-code') {
                  if(this.dateRange === 'today') {
                    this.dateRange = 'month';
                  }

                  this.max = moment().subtract(1, 'days');
                  this.selectedMoments = [
                    moment(new Date()).subtract(14, 'day'),
                    moment(new Date()).subtract(1, 'day')
                  ];

                  this.dateRange = this.dateRange;
                  const data = {
                    from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
                    groupName: this.tempSelectedGroupNames,
                    dateRange: this.dateRange,
                    timezoneName: '(UTC +0) Etc/UTC',
                    timezonekey: 'Etc/UTC',
                  };
                  if(this.tempSelectedProfileTypes.length > 0) {
                    let data = {
                      profileTypes: this.tempSelectedProfileTypes
                    }
                    this.reportService.getGroupNames(data)
                      .subscribe((res: any) => {
                        this.groupNameList = res;
                    })
                  }
                  this.getQrReport(data, this.filterBy);
                  if(this.totalDownloadubscription) {
                    this.totalDownloadubscription.unsubscribe();
                  }
                  this.getTotalDownloadGraph({
                    dateRange: this.dateRange,
                    profileType: (this.selectProfileFlg && this.tempSelectedGroupProfileTypes && this.tempSelectedGroupProfileTypes.length > 0) ? this.tempSelectedGroupProfileTypes : (this.groupProfileTypeList && this.groupProfileTypeList.length > 0) ? this.groupProfileTypeList.map(gP => gP.name) : [],
                    timezoneName: '(UTC +0) Etc/UTC',
                    timezonekey: 'Etc/UTC',
                    groupName: this.tempSelectedGroupNames,
                    from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                  });
                  if(this.totalDownloadRemainingCouneSubscription) {
                    this.totalDownloadRemainingCouneSubscription.unsubscribe();
                  }
                  this.getTotalDownloadVsRemainingCount({
                    dateRange: this.dateRange,
                    profileType: (this.selectProfileFlg && this.tempSelectedGroupProfileTypes && this.tempSelectedGroupProfileTypes.length > 0) ? this.tempSelectedGroupProfileTypes : (this.groupProfileTypeList && this.groupProfileTypeList.length > 0) ? this.groupProfileTypeList.map(gP => gP.name) : [],
                    timezoneName: '(UTC +0) Etc/UTC',
                    timezonekey: 'Etc/UTC',
                    groupName: this.tempSelectedGroupNames,
                    from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                    to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
                  });
                }
              }
              
            }
          });
      });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      })
  }

  initializeTotalDownloadGraph() {
    setTimeout(() => {
      this.ctx = this.element.nativeElement.querySelector('#myChart').getContext('2d');
      if(this.ctx) {
        if(this.subPerf) {
          this.subPerf.destroy();
        }
        this.subPerf = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: this.lineChartLabels,
            datasets: this.lineChartData
          },
          options: {
            elements: {
              line: {
                  tension: 0
              }
            },
            scales: {
              xAxes: [{
                gridLines: { color: '#5A5A5A', borderDash: [6], display: true },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                }
              }],
              yAxes: [{
                gridLines: { color: '#5A5A5A', borderDash: [6], display: true },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  callback: function(label, index, labels) {
                    if (Math.floor(Number(label)) === label) {
                      return label;
                    }
                  },
                }
              }]
            },
            legend: {
              display: false
            },
            tooltips: {
              enabled: false
            },
            hover: {mode: null},
            plugins: {
              datalabels: {
                display: false
              }
            },
            responsive: true,
            onClick: (event) => {
              let point = Chart.helpers.getRelativePosition(event, this.subPerf);
              let xIndex = this.subPerf['scales']['x-axis-0'].getValueForPixel(point.x);
    
              var activePoints = this.subPerf.getElementsAtEvent(event);
    
              if (activePoints.length) {
                var mouse_position = Chart.helpers.getRelativePosition(event, this.subPerf);
                activePoints = $.grep(activePoints, function(activePoint, index) {
                  var leftX = activePoint._model.x - 5,
                      rightX = activePoint._model.x + 5,
                      topY = activePoint._model.y + 5,
                      bottomY = activePoint._model.y - 5;
      
                  return mouse_position.x >= leftX && mouse_position.x <=rightX && mouse_position.y >= bottomY && mouse_position.y <= topY;
                });
                this.allDataObj = [];
                this.tooltipLabel = '';
                  activePoints.forEach((element, index) => {
                    this.tooltipLabel = element['$outlabels'].label
                    this.allDataObj.push({
                      label: this.subPerf.data.datasets[element['_datasetIndex']].label,
                      data: this.subPerf.data.datasets[element['_datasetIndex']].data[element['_index']],
                      options: element['_options'],
                    });
                  });
                (<any>$('#myModal')).modal();
              }
            },
          },
        })
      }
    }, 1000);
  }

  getAllCustomerGroup() {
    this.groupService.getCustomerUserGroups()
      .subscribe((res: any) => {
        this.groupList = res;
        this.load = true;
      }, err => {
        this.load = true;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  initializeGraphs() {
    this.lineChartOptions = {
      responsive: true,
      scales: {
        beginAtZero: true,
        xAxes: [{
          gridLines: { color: '#5A5A5A', borderDash: [6] }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: this.yAxisLabelString
          },
          ticks: {
            precision: 0,
            beginAtZero: true,
            min: 0
          },
          gridLines: { color: '#5A5A5A', borderDash: [6] }
        }]
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
    };
  
    this.barChartOptions = {
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: { color: '#5A5A5A', borderDash: [6] }
        }],
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: this.yAxisLabelString
          },
          ticks: {
            precision: 0
          },
          gridLines: { color: '#5A5A5A', borderDash: [6] }
        }]
      }
    };
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  changeLanguage() {
    this.language = localStorage.getItem('language');
    this.translate.use(this.language);
    this.translate.get('No of Downloads').subscribe((res: string) => { 
      this.yAxisLabelString = res;
      this.initializeGraphs();
    });
    this.translate.get('Select All').subscribe((res: string) => {
      this.show = false;
      this.profileDropdownSettings.selectAllText = res;
      this.refreshDropSettings();
    });
    this.translate.get('Search').subscribe((res: string) => { 
      this.show = false;       
      this.profileDropdownSettings.searchPlaceholderText = res;
      this.refreshDropSettings();
    });
    this.translate.get('No. Of ICCID Downloaded').subscribe((res: string) => { 
      this.chartLabel = res;
    });
    this.translate.get('UnSelect All').subscribe((res: string) => {
      this.show = false;
      this.profileDropdownSettings.unSelectAllText = res;
      this.groupDropdownSettings.unSelectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('No data available').subscribe((res: string) => {
      this.show = false;
      this.profileDropdownSettings.noDataAvailablePlaceholderText = res;
      this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
      this.groupNameDropdownSettings.noDataAvailablePlaceholderText = res;
      this.refreshDropSettings();
    });
    this.translate.get('Total Downloaded').subscribe((res: string) => { 
      this.groupChartLabel = res;
      this.refreshDropSettings();
    });
    this.translate.get('Remaining').subscribe((res: string) => { 
      this.groupChartRemainingLabel = res;
      this.refreshDropSettings();
    });
    this.translate.get('Total Download').subscribe((res: string) => { 
      this.totalLabel = res;
      this.refreshDropSettings();
    });
  }

  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  getProfileTypes() {
    this.profileService.getProfileTypeListAssignedToGroupQR()
      .subscribe((res: any) => {
        this.profileTypeList = res.profileTypes;
        this.load = true;
      }, err => {
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get Profile Types',
          error: err.error || 'Something went wrong!',
        };
        this.load = true;
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getGroupProfileTypeList() {
    this.grpProfileService.getGroupProfileTypeList()
      .subscribe((res: any) => {
        this.groupProfileTypeList = res.profileTypes;
        this.selectedGroupProfileTypes = [];
        this.groupProfileTypeList.forEach(element => {
          this.selectedGroupProfileTypes.push(element);
        });
      });
  }

  getInventory() {
    this.reportSpinner = true;
    const cookieTrail = {
      screenName: 'Report',
      action: 'Get Inventory Report',
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.inventoryReportSubscription) {
      this.inventoryReportSubscription.unsubscribe();
    }

    this.inventoryReportSubscription = this.reportService.getInventory()
      .subscribe((res: any) => {
        this.reportSpinner = false;
        this.inventoryList = res;
        this.inventoryList.forEach(element => {
          element.data.forEach(ele => {
            element[Object.keys(ele).toString()] = Object.values(ele);
          });
        });
      }, err => {
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get Inventory Report',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.reportSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  selectAllRows() {
    if (this.inventoryList && this.inventoryList.length > 0) {
      if (this.select) {
        this.inventoryList.forEach(ele => {
            ele.checked = false;
        });
      } else {
        this.inventoryList.forEach(ele => {
          ele.checked = true;
        });
      }
    }
  }

  selectAllRowsGroupQr() {
    if (this.inventoryListQR && this.inventoryListQR.length > 0) {
      if (this.select) {
        this.checkboxDisable = true;
        let i = 0;
        this.inventoryListQR.forEach((ele: any, index) => {
            ele.checked = true;

            const data = {
              dateRange: this.dateRange,
              profileType: [ele.profileType],
              groupName:[ele.groupName],
              timezoneName: '(UTC +0) Etc/UTC',
              timezonekey: 'Etc/UTC',
              to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
              from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
            };

            this.reportService.getTotalDownloadVSRemainingCount(data)
              .subscribe((res: any) => {
                i++;
                if(this.inventoryListQR.length === i) {
                  this.checkboxDisable = false;
                }
                if (this.dynamicGraphs.findIndex(element => element.data.groupName === ele.groupName) === -1) {
                  res.mode = false;
                  this.dynamicGraphs.push(res);

                  let downloadData = [];
                  let remainingData = [];
                  let labels = [];
                  
                  if (ele.groupType === 'public') {
                    res.data.public.forEach(element => {
                      labels.push(this.getLabels(element));
                      
                      downloadData.push(element.downloadLimitCount);
                      // remainingData.push(element.remainingLimitCount);
                    });
                  } else if (ele.groupType === 'private') {
                    res.data.private.forEach(element => {
                      labels.push(this.getLabels(element));
  
                      downloadData.push(element.downloadLimitCount);
                      // remainingData.push(element.remainingLimitCount);
                    });
                  }
                  

                  this.dynamicQrLabels.push(labels);

                  this.dynamicQrData.push([
                    { data: downloadData, label: this.totalLabel, stack: 'a' },
                    // { data: remainingData, label: this.groupChartRemainingLabel, stack: 'a' },
                  ]);
                }
              }, err => {
                this.checkboxDisable = false;
              });
        });

      } else {
        this.inventoryListQR.forEach((ele: any) => {
          ele.checked = false;
        });
        this.dynamicGraphs = [];
        this.dynamicQrLabels = [];
        this.dynamicQrData = [];
      }
    }
  }

  getSelectedCount() {
    if (this.select) {
      this.selectedCount = 0;
      this.selectedList = [];
      this.selectedGroup = [];
    } else {
      this.selectedCount = this.inventoryList.length;
      this.selectedList = [];
      this.selectedGroup = [];
      this.inventoryList.forEach(ele => {
          this.selectedList.push(ele.profileType);
          this.selectedGroup.push(ele.groupId);
      });
    }
  }

  selectCheck(check: any, profileType: any, groupId: any) {
    let flg = 0;
    if (this.selectProfileFlg) {
      if (!check) {
        this.selectedCount--;
        this.selectedList.splice(this.selectedList.findIndex(ele => ele === profileType), 1);
      } else if (this.selectedList.findIndex(ele => ele === profileType) === -1) {
        this.selectedCount++;
        this.selectedList.push(profileType);
      }
    } else {
      if (!check) {
        this.selectedCount--;
        this.selectedGroup.splice(this.selectedGroup.findIndex(ele => ele === groupId), 1);
      } else if (this.selectedGroup.findIndex(ele => ele === groupId) === -1) {
        this.selectedCount++;
        this.selectedGroup.push(groupId);
      }
    }
    setTimeout(() => {
      this.inventoryList.forEach(ele => {
        if (!ele.checked) {
          this.select = false;
          flg = 1;
          return;
        }
        if (flg === 0) {
          this.select = true;
        }
      });
    }, 5);

  }

  selectCheckGroupQr(profileType, groupName, checked, grpType) {
    let flg = 0;
    setTimeout(() => {
      this.inventoryListQR.forEach((ele: any) => {
        if (!ele.checked) {
          this.select = false;
          flg = 1;
          return;
        }
        if (flg === 0) {
          this.select = true;
        }
      });
    }, 5);

    if (checked) {

      const data = {
        dateRange: this.dateRange,
        profileType: [profileType],
        groupName:[groupName],
        timezoneName: '(UTC +0) Etc/UTC',
        timezonekey: 'Etc/UTC',
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      };

      this.checkboxDisable = true;
        this.reportService.getTotalDownloadVSRemainingCount(data)
        .subscribe((res: any) => {
          this.checkboxDisable = false;
          if (this.dynamicGraphs.findIndex(ele => ele.data.groupName === groupName) === -1 && checked) {
            res.mode = false;
            this.dynamicGraphs.push(res);

            let downloadData = [];
            let remainingData = [];
            let labels = [];

            if(grpType === 'public') {
                res.data.public.forEach(element => {
                  
                  labels.push(this.getLabels(element));
    
                  downloadData.push(element.downloadLimitCount);
                  // remainingData.push(element.remainingLimitCount);
                });              
            } else if(grpType === 'private') {
              res.data.private.forEach(element => {
                
                labels.push(this.getLabels(element));

                downloadData.push(element.downloadLimitCount);
                // remainingData.push(element.remainingLimitCount);
              });
            }

            this.dynamicQrLabels.push(labels);

            this.dynamicQrData.push([
              { data: downloadData, label: this.totalLabel, stack: 'a' },
              // { data: remainingData, label: this.groupChartRemainingLabel, stack: 'a' },
            ]);
            
          }
        }, err => {
          this.checkboxDisable = false;
        });
    } else {
      this.dynamicGraphs.splice(this.dynamicGraphs.findIndex(ele => ele.groupName === groupName), 1);
      this.dynamicQrLabels.splice(this.dynamicGraphs.findIndex(ele => ele.groupName === groupName), 1);
      this.dynamicQrData.splice(this.dynamicGraphs.findIndex(ele => ele.groupName === groupName), 1);
    }
  }

  getLabels(element) {
    let labels;

    if (this.dateRange === 'month') {
      if (element.week === 1) {
        labels = '1st Week';
      } else if (element.week === 2) {
        labels = '2nd Week';
      } else if (element.week === 3) {
        labels = '3rd Week';
      } else if (element.week === 4) {
        labels = '4th Week';
      } else if (element.week === 5) {
        labels = '5th Week';
      }
    } else if(this.dateRange === 'year') {
      labels = element.month;
    } else if(this.dateRange === 'today' || this.dateRange === 'week' || this.dateRange === 'yesterday' || this.dateRange === 'custom') {
      labels = element.lable;
    }

    return labels;
  }

  inventoryReportDownload(){
    let inventoryData;
    this.downloadReportSpinner = true;
    if (this.selectProfileFlg){
      inventoryData = {
        fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: this.selectedList,
        dateRange: this.dateRange,
        option: 'profileType',
      };
    } else {
      inventoryData = {
        fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: [],
        dateRange: this.dateRange,
        option: 'groups',
        groupArray: this.selectedGroup
      };
    }
    this.reportService.downloadInventoryReport(inventoryData)
    .subscribe((res: any) => {
      this.downloadReportSpinner = false;
      this.inventoryReport = [];
      this.inventoryReport = res.iccids;
      this.downloadCSV();
    }, err => {
      this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    });
  }

  async downloadCSV() {
    const tempList = [];
    let fileName;
    if (this.tabId === 'inventory') {
        this.inventoryReport.forEach((ele: any) => {
          const obj = {
            ProfileType: ele.ProfileType,
            ICCID: ele.ICCID,
            STATUS : ele.Status,
            PRPType : ele.PRPType,
            PrpCount : ele.PrpCount,
            UpdatedOn: ele.updatedOn
          };
          tempList.push(obj);
        });
        fileName = `Inventory.txt`;
    } else if (this.tabId === 'download') {
      this.downloadReportList.results.forEach(element => {
        const obj = {
          profileType: element.profileType,
          totalDownload: element.subTotal,
          Average: element.averate,
        };
        tempList.push(obj);
      });
      fileName = `Download Report.csv`;
    } else if (this.tabId === 'prepare') {
      this.prepareReportList.result.forEach(element => {
        const obj = {
          profileType: element.profileType,
          RELEASED: element.RELEASED,
          FAILED: element.FAILED,
        };
        tempList.push(obj);
      });
      fileName = `Prepare Report.csv`;
    } else if (this.tabId === 'reset') {
      this.resetReportList.result.forEach(element => {
        const obj = {
          profileType: element.profileType,
          AVAILABLE: element.AVAILABLE,
          FAILED: element.FAILED,
        };
        tempList.push(obj);
      });
      fileName = `Reset Report.csv`;
    } else if (this.tabId === 'grp-qr-code') {

      let flg = false;
      if(this.inventoryListQR && this.inventoryListQR['data'] && this.inventoryListQR['data'].length <= 0) {
        const obj = {};
        tempList.push(obj);
      } else {
        for(let i = 0; i < this.inventoryListQR.length; i++) {
          if(this.inventoryListQR[i].checked) {
            flg = true;
            break;
          }
        }
  
        if(!flg) {
          this.inventoryListQR.forEach(element => {
            const obj = {
              'Profile Type': element.profileType,
              'Group QR Name': element.groupName,
              'Total Downloaded': element.downloadLimit,
              // 'Remaining': element.remainingLimit,
            };
            tempList.push(obj);
          });
        } else {
          this.inventoryListQR.forEach(element => {
            if(element.checked) {
              const obj = {
                'Profile Type': element.profileType,
                'Group QR Name': element.groupName,
                'Total Downloaded': element.downloadLimit,
                // 'Remaining': element.remainingLimit,
              };
              tempList.push(obj);
            }
          });
        }
  
      }

      
      
      fileName = `Group QR Code Report.csv`;
    }

    this.tempSelectedProfileTypes = [];
    if(this.selectedProfileTypes && this.selectedProfileTypes.length > 0) {
      for(let i = 0; i < this.selectedProfileTypes.length; i++) {
        await this.createProfileTypes(this.selectedProfileTypes[i].name);
      }
    }

    const cookieTrail = {
      screenName: 'Report: ' + this.tabId.toString().toUpperCase(),
      action: 'Download Report',
      body: {
          fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          profileType: this.selectedProfileTypes && this.selectedProfileTypes.length > 0 ? this.tempSelectedProfileTypes : [],
          downloadData: tempList
      },
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
    saveAs(blob, fileName);
  }

  removeitem(index) {
    this.selectedProfileTypes.splice(index, 1);
  }

  async createGroupId(index) {
    this.selectedIdGroups.push(this.selectedGroups[index]._id);
  }

  async createProfileTypes(name) {
    this.tempSelectedProfileTypes.push(name);
  }

  async createGroupProfileTypes(name) {
    this.tempSelectedGroupProfileTypes.push(name);
  }

  async changeData() {
    this.qrBarChartData = null;
    this.resetBarChartData = null;
    this.lineChartData = null;
    this.qrlineChartData = null;
    this.prepareBarChartData = null;
    this.selectedIdGroups = [];
    this.select = false;

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    
    if(this.selectedGroups && this.selectedGroups.length > 0 && this.tabId !== 'grp-qr-code') {
      for(let i = 0; i < this.selectedGroups.length; i++) {
        await this.createGroupId(i);
      }
    }

    this.tempSelectedProfileTypes = [];
    if(this.selectedProfileTypes && this.selectedProfileTypes.length > 0 && this.tabId !== 'grp-qr-code') {
      for(let i = 0; i < this.selectedProfileTypes.length; i++) {
        await this.createProfileTypes(this.selectedProfileTypes[i].name);
      }
    }

    this.tempSelectedGroupProfileTypes = [];
    if(this.selectedGroupProfileTypes && this.selectedGroupProfileTypes.length > 0 && this.tabId === 'grp-qr-code') {
      for(let i = 0; i < this.selectedGroupProfileTypes.length; i++) {
        await this.createGroupProfileTypes(this.selectedGroupProfileTypes[i].name);
      }
    }
    
    if (this.tabId === 'download') {
      let data;
      if ((this.selectedProfileTypes && (this.selectedProfileTypes.length > 0)) || 
      (this.selectedIdGroups && (this.selectedIdGroups.length > 0))) {

        data = {
          fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
          groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
          dateRange: this.dateRange,
          timezoneName,
          timezonekey: this.selectedTimezone
        };
      } else {
        data = {
          fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          dateRange: this.dateRange,
          timezoneName,
          timezonekey: this.selectedTimezone
        };
      }
      this.getDownloadReport(data, this.filterBy);
    } else if (this.tabId === 'prepare') {
      const data = {
        fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
        groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
        dateRange: this.dateRange,
        timezoneName,
        timezonekey: this.selectedTimezone
      };

      this.getPrepareReport(data, this.filterBy);
    } else if (this.tabId === 'reset') {
      const data = {
        fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: this.selectProfileFlg ? this.tempSelectedProfileTypes : [],
        groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
        dateRange: this.dateRange,
        timezoneName,
        timezonekey: this.selectedTimezone
      };

      this.getResetReport(data, this.filterBy);
    } else if (this.tabId === 'grp-qr-code') {
      this.max = moment().subtract(1, 'days');

      if (this.dateRange === 'custom') {
        if(moment(this.selectedMoments[1]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
          this.selectedMoments[1] = moment(new Date()).subtract(1, 'day');
        }
      }

      this.dynamicGraphs = [];
      this.dynamicQrData = [];
      this.dynamicQrLabels = [];
      let data = {
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: this.selectProfileFlg ? this.tempSelectedGroupProfileTypes : [],
        groupName: this.tempSelectedGroupNames,
        dateRange: this.dateRange,
        timezoneName: '(UTC +0) Etc/UTC',
        timezonekey: 'Etc/UTC',
      };
      if(this.tempSelectedGroupProfileTypes.length > 0) {
        let data1 = {
          profileTypes: this.tempSelectedGroupProfileTypes
        }
        this.reportService.getGroupNames(data1)
          .subscribe((res: any) => {
            this.groupNameList = res;
        })
      }
      this.getQrReport(data, this.filterBy);
      if(this.totalDownloadubscription) {
        this.totalDownloadubscription.unsubscribe();
      }
      this.getTotalDownloadGraph({
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: (this.selectProfileFlg && this.tempSelectedGroupProfileTypes && this.tempSelectedGroupProfileTypes.length > 0) ? this.tempSelectedGroupProfileTypes : (this.groupProfileTypeList && this.groupProfileTypeList.length > 0) ? this.groupProfileTypeList.map(gP => gP.name) : [],
        timezoneName: '(UTC +0) Etc/UTC',
        timezonekey: 'Etc/UTC',
        groupName: this.tempSelectedGroupNames,
      });
      if(this.totalDownloadRemainingCouneSubscription) {
        this.totalDownloadRemainingCouneSubscription.unsubscribe();
      }
      this.getTotalDownloadVsRemainingCount({
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: (this.selectProfileFlg && this.tempSelectedGroupProfileTypes && this.tempSelectedGroupProfileTypes.length > 0) ? this.tempSelectedGroupProfileTypes : (this.groupProfileTypeList && this.groupProfileTypeList.length > 0) ? this.groupProfileTypeList.map(gP => gP.name) : [],
        timezoneName: '(UTC +0) Etc/UTC',
        timezonekey: 'Etc/UTC',
        groupName: this.tempSelectedGroupNames,
      });
    }
  }

  getTotalDownloadGraph(data) {
    if(this.totalDownloadubscription) {
      this.totalDownloadubscription.unsubscribe();
    }
    this.totalDownloadubscription = this.reportService.getTotalDownload(data)
      .subscribe((res: any) => {
        this.lineChartData = [];
        this.totalDownloadAllData = res;
        this.totalDownlodGraphData = this.isTotalDownloadPublic ? this.totalDownloadAllData.data.public : this.totalDownloadAllData.data.private;
        this.setDate(true, false);
      })
  }

  getTotalDownloadVsRemainingCount(data) {
    if(this.totalDownloadRemainingCouneSubscription) {
      this.totalDownloadRemainingCouneSubscription.unsubscribe();
    }
    this.totalDownloadRemainingCouneSubscription = this.reportService.getTotalDownloadVSRemainingCount(data)
      .subscribe((res: any) => {
        this.tdVsRCData = res;
        this.setDate(false, true);
      })
  }

  setDate(tD=false, tDVsRC=false) {
    // this.qrBarChartData = null;
    this.resetBarChartData = null;
    // this.lineChartData = null;
    this.qrlineChartData = null;
    this.prepareBarChartData = null;
    if (this.tabId === 'download') {
      const lables = [];
      const data = [];
      if (this.downloadReportList.by === 'days') {
        this.downloadReportList.perDay.forEach(element => {
          lables.push(`${element.day}/${element.month}/${element.year}`);
          data.push(element.count);
        });
      } else if (this.downloadReportList.by === 'months') {
        this.downloadReportList.perMonth.forEach(element => {
          lables.push(`${element.month}/${element.year}`);
          data.push(element.count);
        });
      } else if (this.downloadReportList.by === 'years') {
        this.downloadReportList.perYear.forEach(element => {
          lables.push(`${element.year}`);
          data.push(element.count);
        });
      }

      this.lineChartLabels = lables;
      this.lineChartData = [
        { data, label: this.chartLabel, borderWidth: 2 },
      ];
    } else if (this.tabId === 'prepare') {
      const lables = [];
      const releasedData = [];
      const failedData = [];
      if (this.filterBy === 'Day') {
        this.prepareReportList.perDay.forEach(element => {
          lables.push(`${element.day}/${element.month}/${element.year}`);
          releasedData.push(element.RELEASED);
          failedData.push(element.FAILED);
        });
      } else if (this.filterBy === 'Month') {
        this.prepareReportList.perMonth.forEach(element => {
          lables.push(`${element.month}/${element.year}`);
          releasedData.push(element.RELEASED);
          failedData.push(element.FAILED);
        });
      } else if (this.filterBy === 'Year') {
        this.prepareReportList.perYear.forEach(element => {
          lables.push(`${element.year}`);
          releasedData.push(element.RELEASED);
          failedData.push(element.FAILED);
        });
      }

      this.lineChartLabels = lables;
      this.prepareBarChartData = [
        { data: releasedData, label: 'Released', stack: 'a' },
        { data: failedData, label: 'Failed', stack: 'a' },
      ];

    } else if (this.tabId === 'reset') {
      const lables = [];
      const availableData = [];
      const failedData = [];
      if (this.filterBy === 'Day') {
        this.resetReportList.perDay.forEach(element => {
          lables.push(`${element.day}/${element.month}/${element.year}`);
          availableData.push(element.AVAILABLE);
          failedData.push(element.FAILED);
        });
      } else if (this.filterBy === 'Month') {
        this.resetReportList.perMonth.forEach(element => {
          lables.push(`${element.month}/${element.year}`);
          availableData.push(element.AVAILABLE);
          failedData.push(element.FAILED);
        });
      } else if (this.filterBy === 'Year') {
        this.resetReportList.perYear.forEach(element => {
          lables.push(`${element.year}`);
          availableData.push(element.AVAILABLE);
          failedData.push(element.FAILED);
        });
      }

      this.lineChartLabels = lables;
      this.resetBarChartData = [
        { data: availableData, label: 'Available', stack: 'a' },
        { data: failedData, label: 'Failed', stack: 'a' },
      ];
    } else if (this.tabId === 'grp-qr-code') {
      if(tD || tDVsRC) {
        if(tD) {
          let tempLabelArr = [];
          if(this.totalDownlodGraphData && this.totalDownlodGraphData.length > 0) {
            this.totalDownlodGraphData.map((d) => {
              if(d.data && d.data.length > 0) {
                d.data.map((a) => tempLabelArr.push(a.lable))
              }
            })
          }
          this.lineChartLabels = [];
          this.lineChartLabels = [...new Set(tempLabelArr)];
          if(this.dateRange == 'month') {
            this.lineChartLabels = this.lineChartLabels.map((element: any) => {
              if (element === 1) {
                return '1st Week';
              } else if (element === 2) {
                return '2nd Week';
              } else if (element === 3) {
                return '3rd Week';
              } else if (element === 4) {
                return '4th Week';
              } else if (element === 5) {
                return '5th Week';
              }
            }
              );
          }
          this.lineChartData = [];
          this.lineChartColors = [];
          if(this.totalDownlodGraphData && this.totalDownlodGraphData.length > 0) {
            this.totalDownlodGraphData.forEach((sD, index) => {
              let sO = {
                label: sD.groupName,
                borderWidth: 2,
                data: sD.data.map(d => d.downloadLimitCount),
                fill: false,
                borderColor: `${colors[index]}`,
              }
              this.lineChartData.push(sO);
              this.lineChartColors.push({
                borderColor: colors[index],
                backgroundColor: 'transparent',
              });
            });
          }
          
          this.initializeTotalDownloadGraph();
        }
        if(tDVsRC) {
          let tempLabelArr1 = [];
          this.tdVsRclineChartLabels = [];
          let tempData = this.isTotalDownloadRemainingCountPublic ? this.tdVsRCData['data']['public'] : this.tdVsRCData['data']['private'];
          if(tempData && tempData.length > 0) {
            if(this.dateRange == 'year') {
              tempLabelArr1 = tempData.map((d) => d.month);
            } else if(this.dateRange == 'month') {
              tempData.forEach((element) => {
                if (element.week === 1) {
                  tempLabelArr1.push('1st Week');
                } else if (element.week === 2) {
                  tempLabelArr1.push('2nd Week');
                } else if (element.week === 3) {
                  tempLabelArr1.push('3rd Week');
                } else if (element.week === 4) {
                  tempLabelArr1.push('4th Week');
                } else if (element.week === 5) {
                  tempLabelArr1.push('5th Week');
                }
              });
            } else {
              tempLabelArr1 = tempData.map((d) => d.lable);
            }
          }
          this.tdVsRclineChartLabels = [...new Set(tempLabelArr1)];
          let totalDownloadArray = [];
          let remainingArray = [];
          if(tempData && tempData.length > 0) {
            tempData.forEach(ele => {
              totalDownloadArray.push(ele.downloadLimitCount);
              remainingArray.push(ele.remainingLimitCount);
            });
            this.qrBarChartData = [
              { data: totalDownloadArray, label: this.groupChartLabel, stack: 'a' },
              { data: remainingArray, label: this.groupChartRemainingLabel, stack: 'a' },
            ];
          }
        }
      }
    }
  }

  getDownloadReport(filterData, filterBy) {
    this.reportSpinner = true;
    const cookieTrail = {
      screenName: 'Report',
      action: 'Get Download Report',
      body: filterData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.downloadReportSubscription) {
      this.downloadReportSubscription.unsubscribe();
    }

    this.downloadReportSubscription = this.reportService.getDownloadReport(filterData)
      .subscribe((res: any) => {
        this.totalDownload = [];
        this.reportSpinner = false;
        this.downloadReportList = res;
        this.downloadResults = this.downloadReportList.results;
        this.downloadResults.forEach(data => {
          this.totalDownload.push(data.subTotal);
        });
        this.totalSum = 0;
        for (let i = 0; i < this.totalDownload.length; i++) {
          this.totalSum += this.totalDownload[i];
        }
        this.setDate();
      }, err => {
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get Download Report',
          body: filterData,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.reportSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getPrepareReport(filterData, filterBy) {
    this.reportSpinner = true;
    const cookieTrail = {
      screenName: 'Report',
      action: 'Get Prepare Report',
      body: filterData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.prepareSubscription) {
      this.prepareSubscription.unsubscribe();
    }
    this.prepareSubscription = this.reportService.getPrepareReport(filterData)
      .subscribe((res: any) => {
        this.reportSpinner = false;
        this.prepareReportList = res;
        this.setDate();
      }, err => {
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get Prepare Report',
          body: filterData,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.reportSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getResetReport(filterData, filterBy) {
    this.reportSpinner = true;
    const cookieTrail = {
      screenName: 'Report',
      action: 'Get Reset Report',
      body: filterData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    if(this.resetReportSubscription) {
      this.resetReportSubscription.unsubscribe();
    }

    this.resetReportSubscription = this.reportService.getResetReport(filterData)
      .subscribe((res: any) => {
        this.reportSpinner = false;
        this.resetReportList = res;
        this.setDate();
      }, err => {
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get Reset Report',
          body: filterData,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.reportSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getQrReport(filterData, filterBy) {
    this.reportSpinner = true;
    const cookieTrail = {
      screenName: 'Report',
      action: 'Get QR Report',
      body: filterData,
    };

    this.checkboxDisable = true;
    
    this.cookieTrailService.saveCookie(cookieTrail);
    this.reportService.getQrReport(filterData)
      .subscribe((res: any) => {
        this.reportSpinner = false;
        this.checkboxDisable = false;
        // this.qrReportList = res;
        this.inventoryListQR = res;

        (this.inventoryListQR && this.inventoryListQR.length > 0) ?? this.inventoryListQR.forEach((res: any) => {
          res.checked = false;
        });
        this.setDate();
      }, err => {
        this.checkboxDisable = false;
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get QR Report',
          body: filterData,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.reportSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  async sendEmail() {
    this.tempSelectedProfileTypes = [];
    const tempList = [];
    if(this.selectedProfileTypes && this.selectedProfileTypes.length > 0) {
      for(let i = 0; i < this.selectedProfileTypes.length; i++) {
        await this.createProfileTypes(this.selectedProfileTypes[i].name);
      }
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    let data;
    if(this.tabId != 'grp-qr-code') {
      data = {
        fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: this.selectedProfileTypes && this.selectedProfileTypes.length > 0 ? this.tempSelectedProfileTypes : [],
        dateRange: this.dateRange,
        timezonekey: this.selectedTimezone,
      };
    } else {
      let selectedGroupNames;
      let selectedProfileTypes;
      let flg = false;

      for(let i = 0; i < this.inventoryListQR.length; i++) {
        if(this.inventoryListQR[i].checked) {
          flg = true;
          break;
        }
      }

      if(!flg) {
        selectedGroupNames = this.tempSelectedGroupNames;
        selectedProfileTypes = this.selectedGroupProfileTypes && this.selectedGroupProfileTypes.length > 0 ? this.tempSelectedGroupProfileTypes : [];
      } else {
        selectedGroupNames = [];
        this.inventoryListQR.forEach(element => {
          if(element.checked) {
            selectedGroupNames.push(element.groupName);
            if(selectedProfileTypes && selectedProfileTypes.length > 0 && !selectedProfileTypes.include(element.profileType)) {
              selectedProfileTypes.push(element.profileType);
            }
          }
        });
      }

      data = {
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        profileType: this.selectedGroupProfileTypes && this.selectedGroupProfileTypes.length > 0 ? this.tempSelectedGroupProfileTypes : [],
        dateRange: this.dateRange,
        timezoneName: '(UTC +0) Etc/UTC',
        timezonekey: 'Etc/UTC',
        groupName: selectedGroupNames
      };
    }
    
    this.sendMailSpinner = true;
    const cookieTrail = {
      screenName: 'Report: ' + this.tabId.toString().toUpperCase(),
      action: 'Send Email',
      body: data,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    if (this.tabId === 'download') {
      this.reportService.sendDownloadMail(data)
        .subscribe((res: any) => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }, err => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    } else if (this.tabId === 'prepare') {
      this.reportService.sendPreparedMail(data)
        .subscribe((res: any) => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }, err => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    } else if (this.tabId === 'reset') {
      this.reportService.sendResetMail(data)
        .subscribe((res: any) => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }, err => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    } else if (this.tabId === 'grp-qr-code') {

      this.reportService.sendQrMail(data)
        .subscribe((res: any) => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }, err => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    } else if (this.tabId === 'inventory') {
      let inventoryData;
      if (this.selectProfileFlg){
        inventoryData = {
          fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          profileType: this.selectedList,
          dateRange: this.dateRange,
          option: 'profileType',
        };
      } else {
        inventoryData = {
          fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
          profileType: [],
          dateRange: this.dateRange,
          option: 'groups',
          groupArray: this.selectedGroup
        };
      }
      this.reportService.sendInventoryMail(inventoryData)
        .subscribe((res: any) => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }, err => {
          this.sendMailSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }
  }

  setTimezone() {
    if(this.timezoneSubscription) {
      this.timezoneSubscription.unsubscribe();
    }

    this.timezoneSubscription = this.userService.setTimezone({timezone: this.selectedTimezone})
      .subscribe((res: any) => {
        this.user.data = Object.assign({...this.user.data}, {timezone: this.selectedTimezone});
        this.userStore.dispatch(new UserAction.GetUser(this.user));
        this.changeData();
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  changeGroupName() {
    this.dynamicGraphs = [];
    this.dynamicQrData = [];
    this.dynamicQrLabels = [];
    this.select = false;
    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    this.tempSelectedGroupNames = this.selectedGroupNames.map(sgn => sgn.groupName)
    const data = {
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      profileType: this.selectProfileFlg ? this.tempSelectedGroupProfileTypes : [],
      // groups: this.selectProfileFlg ? [] : this.selectedIdGroups,
      groupName: this.tempSelectedGroupNames,
      dateRange: this.dateRange,
      timezoneName: '(UTC +0) Etc/UTC',
      timezonekey: 'Etc/UTC',
    };
    // if(this.tempSelectedProfileTypes.length > 0) {
    //   let data = {
    //     profileTypes: this.tempSelectedProfileTypes
    //   }
    //   this.reportService.getGroupNames(data)
    //     .subscribe((res: any) => {
    //       this.groupNameList = res;
    //   })
    // }
    this.getQrReport(data, this.filterBy);
    if(this.totalDownloadubscription) {
      this.totalDownloadubscription.unsubscribe();
    }
    this.getTotalDownloadGraph({
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      profileType: (this.selectProfileFlg && this.tempSelectedGroupProfileTypes && this.tempSelectedGroupProfileTypes.length > 0) ? this.tempSelectedGroupProfileTypes : (this.groupProfileTypeList && this.groupProfileTypeList.length > 0) ? this.groupProfileTypeList.map(gP => gP.name) : [],
      timezoneName: '(UTC +0) Etc/UTC',
      timezonekey: 'Etc/UTC',
      groupName: this.tempSelectedGroupNames,
    });
    if(this.totalDownloadRemainingCouneSubscription) {
      this.totalDownloadRemainingCouneSubscription.unsubscribe();
    }
    this.getTotalDownloadVsRemainingCount({
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      profileType: (this.selectProfileFlg && this.tempSelectedGroupProfileTypes && this.tempSelectedGroupProfileTypes.length > 0) ? this.tempSelectedGroupProfileTypes : (this.groupProfileTypeList && this.groupProfileTypeList.length > 0) ? this.groupProfileTypeList.map(gP => gP.name) : [],
      timezoneName: '(UTC +0) Etc/UTC',
      timezonekey: 'Etc/UTC',
      groupName: this.tempSelectedGroupNames,
    });
  }

  changeTypeTotalDownload() {
    this.isTotalDownloadPublic = !this.isTotalDownloadPublic;
    // this.totalDownlodGraphData = this.isTotalDownloadPublic ? this.totalDownloadAllData.data.public : this.totalDownloadAllData.data.private;
    this.totalDownlodGraphData = this.isTotalDownloadPublic ? this.totalDownloadAllData.data.public : this.totalDownloadAllData.data.private;
    this.setDate(true, false);
  }
  changeTypeRemainingCount() {
    this.isTotalDownloadRemainingCountPublic = !this.isTotalDownloadRemainingCountPublic;
    this.setDate(false, true);
  }

  hoverTotalGraph($event){
    $('#hover-container').toggleClass('active');
  }
}
