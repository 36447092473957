import {
    Component,
    ContentChild,
    TemplateRef,
    OnInit,
    ComponentFactoryResolver,
    ViewContainerRef,
    Injector,
    ElementRef,
    ViewChild,
} from '@angular/core';
// import { DynamicFormComponent } from 'dynaform';

import { ShapeComponent } from './components/shape/shape.component';
import { ShapeProperties, MousePosition } from './model/shape';
import { ShapeType, ToolType } from './model/shape-types';
import { ShapeService } from './service/shape.service';
import { LineComponent } from './components/line/line.component';
import { CircleComponent } from './components/circle/circle.component';
import { RectangleComponent } from './components/rectangle/rectangle.component';
import { SquareComponent } from './components/square/square.component';
import { EllipseComponent } from './components/ellipse/ellipse.component';
import { TextComponent } from './components/text/text.component';
import { ImageComponent } from './components/image/image.component';
import { PolyLineComponent } from './components/polyline/polyline.component';
import { PathComponent } from './components/path/path.component';
import { TriangleComponent } from './components/triangle/triangle.component';

// import { Field } from 'dynaform';
// import * as $ from 'jquery';
import { RoundedRactangleComponent } from './components/rounded-ractangle/rounded-ractangle.component';
import { QrcodeComponent } from './dynamic-components/qrcode/qrcode.component';
import { PdfGeneratorService } from '../shared/services/pdf-generator.service';
import { CommunicationService } from '../shared/services/communication.service';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';
import { LpaStringComponent } from './dynamic-components/lpa-string/lpa-string.component';
import { ActokenComponent } from './dynamic-components/actoken/actoken.component';
import { IccidComponent } from './dynamic-components/iccid/iccid.component';
import { Pin1Component } from './dynamic-components/pin1/pin1.component';
import { Pin2Component } from './dynamic-components/pin2/pin2.component';
import { Puk1Component } from './dynamic-components/puk1/puk1.component';
import { Puk2Component } from './dynamic-components/puk2/puk2.component';
import { MncComponent } from './dynamic-components/mnc/mnc.component';
import { MccComponent } from './dynamic-components/mcc/mcc.component';
import { BarcodeComponent } from './dynamic-components/barcode/barcode.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Store } from '@ngrx/store';
import { UserState } from '../shared/stores/user/user.reducer';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { WatermarkComponent } from './components/watermark/watermark.component';
import { QrcodeWithLogoComponent } from './dynamic-components/qrcodewithlogo/qrcode.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImsiComponent } from './dynamic-components/imsi/imsi.component';
import { ConfirmationCodeComponent } from './dynamic-components/confirmationcode/confirmationcode.component';
import { EidComponent } from './dynamic-components/eid/eid.component';
import { GroupProfileService } from '../shared/services/group-profile.service';
import { ProfileService } from '../shared/services/profile.service';

declare var $: any;

@Component({
    selector: 'app-svg-editor',
    templateUrl: './svg-editor.component.html',
    styleUrls: ['./svg-editor.component.scss'],
})
export class SvgEditorComponent implements OnInit {
    //   @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
    @ContentChild(TemplateRef, { static: true }) shapeTemplate: TemplateRef<any>;
    @ViewChild('menuView', { read: ElementRef, static: true })menuView: ElementRef;

    svg: any;
    currentPosition: MousePosition = new MousePosition();
    shapeProperties: ShapeProperties = new ShapeProperties();
    selectedShape: ShapeType;
    shapeValue: string;
    selectedTool: ToolType;
    selectedComponent: ShapeComponent;
    isDragging = false;
    isDrawing = false;
    isResizing = false;
    isSelectingPoints = false;
    fileToUpload: File = null;
    formFields = [];
    selectColor;
    isSelect = true;
    textSelected = false;
    fillSelected = false;
    templateName = '';
    submitted = false;
    tempPdfID;
    allTemplates;
    allActions = [];
    redoActions = [];
    fontList = [
        'PT Sans',
        'Fututra',
        'Seravek',
        'Helvetica',
        'Arial',
        'Times new',
    ];
    pageProps = {
        pageSizes: ['A4', 'Business Card'],
        pageBackground: '#fff',
        selctedPageSize: 'A4'
    };
    rectangleProps = {
        id: '',
        height: 0,
        width: 0,
        border: 0,
        borderRadius: 0,
        fillColor: '#FFF',
        boderColor: '#FFF',
    };
    textProperties = {
        font: '',
        value: '',
        fontSize: 10,
        fillColor: '#FFF',
        bold: false,
        italic: false,
        underLine: false,
    };
    iccidFile;
    btnSpinner = false;
    templateSelectionErr = false;
    fileSelectionErr = false;
    fileTypeSelectionErr = false;
    savedCampaign;
    deleteOn = false;
    x = 0;
    y = 0;
    isHidden = true;
    menu;
    svgHeight;
    svgWidth;
    zoom = false;
    allComponents = [[]];
    isdynamicCompSelected = false;
    isCircleSelected = false;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    imageToCrop: any = '';
    loadCropper = false;
    isImageSelected = false;
    qrWithLogo = false;
    campaignFormat = ['PNG', 'PDF'];
    selectedCampaignFormat = 'PNG';
    features;
    isDraw = true;
    isWatermark = false;
    pdfPngSpinner = false;

    backgroundImage = '';
    backgroundStyle = 'cover';
    customSizeForm: FormGroup;
    customSizeFormSubmitted = false;
    groupSelected = false;
    groupDisable = false;
    iccidDisable = false;
    grpProfileList = [];
    groupMatchingId = '';
    groupSelectionErr = false;
    rootCustomer;
    currentCustomer;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private shapeService: ShapeService,
        private pdfService: PdfGeneratorService,
        private communicationService: CommunicationService,
        private userStore: Store<UserState>,
        private cookieTrailService: CookieTrailService,
        private grpProfileService: GroupProfileService,
        private profileService: ProfileService) {}

    ngOnInit(): void {
    this.getGroupProfiles();

    this.cookieTrailService.saveCookie({});

    localStorage.removeItem('qrLogo');
    this.selectTool('Pointer');
    this.svg = document.querySelector('svg');
    this.svgWidth = $('.svg-editor-main-view-inner').width();
    this.svgHeight = Math.floor((this.svgWidth * 297) / 210);
    $('.svg-editor-main-view svg').css({
        height: this.svgHeight + 'px',
        width: this.svgWidth + 'px',
    });
    this.svg = document.querySelector('#campaignEditorSvg');

    const vm = this;

    let svgWidth = $('.svg-editor-main-view-inner').width();
    let svgHeight = $('.svg-editor-main-view').height();
    $('.svg-editor-main-view').css({width: (svgWidth - 215) + 'px' });
    $('.svg-editor-main-view-inner').css({width: $('.svg-editor-main-view').width() + 'px', height: svgHeight + 'px' });

    this.defaultPageSize();
    $(window).resize(() => {                    
        let svgWidth = $('.svg-editor-main-view-inner').width();
        let svgHeight = $('.svg-editor-main-view').height();
        $('.svg-editor-main-view').css({width: (svgWidth - 215) + 'px' });
        $('.svg-editor-main-view-inner').css({width: $('.svg-editor-main-view').width() + 'px', height: svgHeight + 'px' });                        
    });

    this.userStore.select('user')
        .subscribe((user: any) => {
            if(user) {
                this.rootCustomer = user.data.rootCustomer;
                this.currentCustomer = user.data.currentCustomer;
                this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Campaign Editor')].features;
                
                this.selectedShape = ShapeType.NoShape;
                if(this.features && this.features[this.getFeatureIndex('campaign')].edit) {
                    this.isDraw = true;
                    this.isSelect = true;
                } else {
                    this.isDraw = false;
                    this.isSelect = false;
                    this.pageProps = {
                        pageSizes: ['A4', 'Business Card'],
                        pageBackground: '#fff',
                        selctedPageSize: 'A4'
                    };
                    this.assignHeightWidth(this.pageProps.selctedPageSize);
                }
            }
        });
        this.customSizeForm = new FormGroup({
            customWidth: new FormControl(null, [Validators.required, Validators.min(100)]),
            customHeight: new FormControl(null, [Validators.required, Validators.min(100)]),
        });
    }

    get f() { return this.customSizeForm.controls; }

    getFeatureIndex(keyName) {
        return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
    }

    selectShape(shapeType: string): void {
        this.isSelect = false;
        this.fillSelected = false;
        this.selectColor = '#fff';

        if (shapeType === 'Image') {
            $('#updalodFile').click();
        }
        if(shapeType === 'Watermark') {
            $('#updalodWatermarkFile').click();
        }
        if(shapeType === 'Background') {
            $('#updalodBackgroundImage').click();
            return;
        }
        this.selectedShape = ShapeType[shapeType];
        this.shapeValue = ShapeType[this.selectedShape];
        this.isSelectingPoints = false;               
    }

    async assignUpdalodedImage(file) {
        if(file[0].type === 'application/pdf') {
            const formData = new FormData();
            formData.append('file', file[0]);

            this.pdfPngSpinner = true;
            this.pdfService.getPDFToPNG(formData)
                .subscribe( async (res: any) => {
                    const imageUrl = 'data:image/png;base64,' + res.image;
                    localStorage.setItem('imageUrl', imageUrl);
                    this.pdfPngSpinner = false;
                }, err => {
                    this.pdfPngSpinner = false;
                    this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
                });
        } else {
            const imageUrl = window.URL.createObjectURL(file[0]);
            localStorage.setItem('imageUrl', imageUrl);
            const reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = () => {
                localStorage.setItem('imageUrl', String(reader.result));
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
            };
        }
        return;
        
    }

    dataURItoBlob(dataURI) {
        let mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let binary = atob(dataURI.split(',')[1]);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
           array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: mime});
    }

    assignUpdalodedWatermark(file) {
        const watermarkUrl = window.URL.createObjectURL(file[0]);
        localStorage.setItem('watermarkUrl', watermarkUrl);
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = () => {
            localStorage.setItem('watermarkUrl', String(reader.result));
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }

    assignBackground(file) {

        if(file[0].type === 'application/pdf') {
            const formData = new FormData();
            formData.append('file', file[0]);

            this.pdfPngSpinner = true;
            this.pdfService.getPDFToPNG(formData)
                .subscribe( async (res: any) => {
                    this.backgroundImage = 'data:image/png;base64,' + res.image;
                    this.pdfPngSpinner = false;
                    $('.svg-editor-main-view-inner')
                        .find('svg')
                        .css({
                            background: `url(${this.backgroundImage})`,
                            'background-size': this.backgroundStyle,
                        });
                    $('#updalodBackgroundImage').val('');
                }, err => {
                    this.pdfPngSpinner = false;
                    this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
                });
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = () => {
                this.backgroundImage = String(reader.result);
                $('.svg-editor-main-view-inner')
                    .find('svg')
                    .css({
                        background: `url(${this.backgroundImage})`,
                        'background-size': this.backgroundStyle,
                    });
                $('#updalodBackgroundImage').val('');
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
            };
        }
    }

    removeBackground() {
        $('.svg-editor-main-view-inner')
        .find('svg')
        .css({
            background: '',
            'background-color': '#fff'
        });
        $('#updalodBackgroundImage').val('');
        this.backgroundImage = '';
        this.backgroundStyle = 'cover';
        this.pageProps.pageBackground = '#fff';
    }

    clearShapes(): void {
        this.shapeService.removeAllShapeComponents();
        this.selectedShape = ShapeType.NoShape;
        this.shapeValue = ShapeType[this.selectedShape];
        this.formFields = [];
    }

    getShapes(): ShapeComponent[] {
        return this.shapeService.getShapeComponents();
    }

    selectTool(toolType: string): void {
        this.isImageSelected = false;
        this.qrWithLogo = false;
        this.isSelect = true;
        this.selectedTool = ToolType[toolType];
        this.selectedShape = ShapeType.NoShape;
        this.shapeValue = ShapeType[this.selectedShape];
        if (this.selectedTool === ToolType.Pointer) {
            if (this.isSelectingPoints) {
                this.selectedComponent.endDrawing();
                this.isSelectingPoints = false;
            }
        }
    }

    getMousePosition(event: MouseEvent) {
        const CTM = this.svg.getScreenCTM();
        this.currentPosition.x = (event.clientX - CTM.e) / CTM.a;
        this.currentPosition.y = (event.clientY - CTM.f) / CTM.d;
    }

    private buildComponent(shapeType: ShapeType): any {   
        this.groupSelected = false;     
        switch (shapeType) {
            case ShapeType.Line:
                return LineComponent;
            case ShapeType.Circle:
                return CircleComponent;
            case ShapeType.Triangle:
                return TriangleComponent;
            case ShapeType.Rectangle:
                return RectangleComponent;
            case ShapeType.RoundedRactangle:
                return RoundedRactangleComponent;
            case ShapeType.Square:
                return SquareComponent;
            case ShapeType.Ellipse:
                return EllipseComponent;
            case ShapeType.TextBox:
                return TextComponent;
            case ShapeType.Image:
                return ImageComponent;
            case ShapeType.PolyLine:
                return PolyLineComponent;
            case ShapeType.Path:
                return PathComponent;
            case ShapeType.QRCode:
                return QrcodeComponent;
            case ShapeType.LPAString:
                return LpaStringComponent;
            case ShapeType.ACToken:
                return ActokenComponent;
            case ShapeType.ICCID:
                return IccidComponent;
            case ShapeType.PIN1:
                return Pin1Component;
            case ShapeType.PIN2:
                return Pin2Component;
            case ShapeType.PUK1:
                return Puk1Component;
            case ShapeType.PUK2:
                return Puk2Component;
            case ShapeType.MCC:
                return MccComponent;
            case ShapeType.MNC:
                return MncComponent;
            case ShapeType.Barcode:
                return BarcodeComponent;
            case ShapeType.Watermark:
                return WatermarkComponent;
            case ShapeType.QRCodeWithLogo:
                return QrcodeWithLogoComponent;
            case ShapeType.GroupQR:
                this.groupSelected = true;
                return QrcodeComponent;
            case ShapeType.GroupQRWithLogo:
                this.groupSelected = true;
                return QrcodeWithLogoComponent;
            case ShapeType.IMSI:
                return ImsiComponent;
            case ShapeType.ConfirmationCode:
                return ConfirmationCodeComponent;
            case ShapeType.EID:
                return EidComponent;
        }
        return null;
    }

    canSelectPoints(): boolean {
        if (
            this.selectedShape === ShapeType.PolyLine ||
            this.selectedShape === ShapeType.Path
        ) {
            return true;
        }
        return false;
    }

    deSelectComponents() {
        const shapes = this.getShapes();
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < shapes.length; i++) {
            shapes[i].isSelected = false;
        }
    }

    onMouseDown(event): void {
        this.isHidden = true;
        this.getMousePosition(event);
        
        let xPos = false;
        let yPos = false;

        if(this.selectedComponent && this.selectedComponent.shape.shapeProperties.name.includes('line')) {
            
            if(this.selectedComponent.shape.originX > this.selectedComponent.shape.x2) {
                if(this.selectedComponent.shape.originX > this.currentPosition.x && this.currentPosition.x > this.selectedComponent.shape.x2) {
                    xPos = true;
                } else {
                    xPos = false;
                }
            } else {
                if(this.selectedComponent.shape.x2 > this.currentPosition.x && this.currentPosition.x > this.selectedComponent.shape.originX) {
                    xPos = true;
                } else {
                    xPos = false;
                }
            }

            if(this.selectedComponent.shape.originY > this.selectedComponent.shape.y2) {
                if(this.selectedComponent.shape.originY > this.currentPosition.y && this.currentPosition.y > this.selectedComponent.shape.y2) {
                    yPos = true;
                } else {
                    yPos = false;
                }
            } else {
                if(this.selectedComponent.shape.y2 > this.currentPosition.y && this.currentPosition.y > this.selectedComponent.shape.originY) {
                    yPos = true;
                } else {
                    yPos = false;
                }
            }
        } 

        if(xPos && yPos) {
            this.isDragging = true;
            return;
        } else {
            this.deSelectComponents();
        }
        if (event.target.classList.contains('draggable') && this.isSelect) {
            this.selectedComponent = this.shapeService.findShapeComponent(
                event.target.id
            );
            if (this.selectedComponent) {

                this.selectedComponent.isSelected = true;
                this.shapeProperties = Object.assign(
                    {},
                    this.selectedComponent.shape.shapeProperties
                );
                this.formFields = this.selectedComponent.getFormFields();
                this.startDragging(event);
            }
        } else if (event.target.classList.contains('resize')) {
            this.selectedComponent = this.shapeService.findShapeComponent(
                event.target.id
            );
            if (this.selectedComponent) {
                this.isResizing = true;
            }
        } else if (
            this.selectedShape !== ShapeType.NoShape &&
            !this.isSelectingPoints
        ) {
            const injector = Injector.create(
                [],
                this.viewContainerRef.parentInjector
            );
            const factory = this.componentFactoryResolver.resolveComponentFactory(
                this.buildComponent(this.selectedShape)
            );
            const component = factory.create(injector);
            this.selectedComponent = component.instance as ShapeComponent;
            this.shapeService.setShapeComponent(this.selectedComponent);
            const cName = this.selectedComponent.shape.shapeProperties.name;
            if((cName.includes('qrcode') ||
            cName.includes('qrcodewithlogo') ||
                cName.includes('actoken') ||
                cName.includes('iccid') ||
                cName.includes('pin1') ||
                cName.includes('puk1') ||
                cName.includes('pin2') ||
                cName.includes('puk2') ||
                cName.includes('mcc') ||
                cName.includes('mnc') ||
                cName.includes('imsi') ||
                cName.includes('confirmationcode') ||
                cName.includes('eid'))&& !this.groupSelected) {
                this.groupDisable = true;
            };
            if((cName.includes('qrcode')  ||
            cName.includes('qrcodewithlogo'))&& this.groupSelected) {
                this.iccidDisable = true;
            };
            this.shapeProperties = new ShapeProperties();
            this.shapeProperties.name = this.selectedComponent.shape.shapeProperties.name;
            this.selectedComponent.shape.shapeProperties = Object.assign(
                {},
                this.shapeProperties
            );
            if (this.canSelectPoints()) {
                this.isSelectingPoints = true;
            } else {
                this.isDrawing = true;
                this.selectedComponent.startDrawing(this.currentPosition);
            }
        }
    }

    onMouseMove(event): void {
        this.getMousePosition(event);
        if (
            this.selectedComponent &&
            (this.isDrawing || this.isSelectingPoints)
        ) {
            this.selectedComponent.draw(this.currentPosition);
        } else if (this.selectedComponent && this.isDragging) {
            this.selectedComponent.drag(this.currentPosition);
        } else if (this.isResizing) {
            this.selectedComponent.resizeShape(this.currentPosition);
        }
    }

    resetShapes() {
        this.selectedShape = ShapeType.NoShape;
        this.shapeValue = ShapeType[this.selectedShape];
        this.isDrawing = false;
        this.isDragging = false;
        this.isResizing = false;
    }

    onMouseUp(event): void {
        this.checkActions();
        this.deleteOn = false;
        this.textSelected = false;
        this.isdynamicCompSelected = false;
        this.isCircleSelected = false;
        this.isImageSelected = false;
        this.qrWithLogo = false;
        $('.draggable').removeClass('selected-item');
        this.getMousePosition(event);
        this.isDrawing = false;
        this.isDragging = false;
        this.isResizing = false;
        if (this.isSelectingPoints) {
            this.selectedComponent.setPoint(this.currentPosition);
        }
        if (this.selectedShape) {
            this.isSelect = true;
            this.selectedComponent.isSelected = true;
            this.isDragging = false;
            $('#nav-tab .active').removeClass('active');
            $('#nav-tabContent .active').removeClass('active');
            $('#nav-profile-tab').addClass('active');
            $('#nav-profile').addClass('active show');
        } else {
            this.selectedComponent = undefined;
            this.rectangleProps = {
                id: '',
                height: 0,
                width: 0,
                border: 0,
                borderRadius: 0,
                fillColor: '#FFF',
                boderColor: '#FFF',
            };
            this.selectedComponent = this.shapeService.findShapeComponent(
                event.target.id
            );
        }
        if (!this.isSelect) {
            return;
        }
        if (!this.selectedComponent) {
            return;
        }
        this.selectTool('Pointer');

        this.shapeProperties = new ShapeProperties();
        this.shapeProperties.name = this.selectedComponent.shape.shapeProperties.name;
        $('svg')
            .find('#' + this.shapeProperties.name)
            .addClass('selected-item');
        this.deleteOn = true;
        if (
            this.shapeProperties.name.includes('text-') ||
            this.shapeProperties.name.includes('iccid-') ||
            this.shapeProperties.name.includes('pin1-') ||
            this.shapeProperties.name.includes('pin2-') ||
            this.shapeProperties.name.includes('puk1-') ||
            this.shapeProperties.name.includes('puk2-') ||
            this.shapeProperties.name.includes('lpastring-') ||
            this.shapeProperties.name.includes('actoken-') ||
            this.shapeProperties.name.includes('mcc-') ||
            this.shapeProperties.name.includes('mnc-')||
            this.shapeProperties.name.includes('confirmationcode-')||
            this.shapeProperties.name.includes('eid-')||
            this.shapeProperties.name.includes('imsi-')
        ) {
            if( !this.shapeProperties.name.includes('text-')) {
                this.isdynamicCompSelected = true;
            }
            this.textSelected = true;
            this.assignTextProps();
        } else {
            this.assignProperties();
            if( this.shapeProperties.name.includes('ellipse-') || this.shapeProperties.name.includes('circle-')) {
                this.isCircleSelected = true;
            }
            if( this.shapeProperties.name.includes('image-') || this.shapeProperties.name.includes('watermark-') || this.shapeProperties.name.includes('qrcode-')) {
                this.isImageSelected = true;
            }
            if( this.shapeProperties.name.includes('qrcodewithlogo-')) {
                this.isImageSelected = true;
                this.qrWithLogo = true;
                
            }
        }
    }

    assignTextProps() {
        const font = $('svg')
            .find('#' + this.shapeProperties.name)
            .css('font-size');
        this.textProperties.fillColor = $('svg')
            .find('#' + this.shapeProperties.name)
            .css('fill');
        this.textProperties.fontSize = font.replace(/\D/g, '');
        this.textProperties.font = $('svg')
            .find('#' + this.shapeProperties.name)
            .css('font-family');
        this.textProperties.value = this.selectedComponent.shape.value;
    }

    setTextProps() {
        $('svg')
            .find('#' + this.shapeProperties.name)
            .css({
                fill: this.textProperties.fillColor,
            });
        // $('svg').find('#' + this.shapeProperties.name).css('font-family', this.textProperties.font);
        // $('svg').find('#' + this.shapeProperties.name).css('font-size', this.textProperties.fontSize);
        this.selectedComponent.shape.value = this.textProperties.value;
        this.selectedComponent.shape[
            'font-size'
        ] = this.textProperties.fontSize;
        this.selectedComponent.shape['font-family'] = this.textProperties.font;
        this.selectedComponent.shape.fill = this.textProperties.fillColor;
        this.shapeService.updateShapeComponents(this.selectedComponent);
    }

    assignProperties() {
        this.imageToCrop = this.selectedComponent.shape.url;
        this.rectangleProps.id = '#' + this.shapeProperties.name;
        this.rectangleProps.boderColor = $('svg')
            .find('#' + this.shapeProperties.name)
            .css('stroke');
        this.rectangleProps.fillColor = $('svg')
            .find('#' + this.shapeProperties.name)
            .css('fill');
        this.rectangleProps.border = this.selectedComponent.shape.shapeProperties.strokeWidth;
        this.rectangleProps.width = $('svg')
            .find('#' + this.shapeProperties.name)
            .width();
        this.rectangleProps.height = $('svg')
            .find('#' + this.shapeProperties.name)
            .height();
        this.rectangleProps.borderRadius = this.selectedComponent.shape.shapeProperties.rx;
        this.selectedShape = ShapeType.NoShape;
        this.shapeValue = ShapeType[this.selectedShape];
        this.isDrawing = false;
        this.isDragging = false;
        this.isResizing = false;
    }

    setRectProps() {
        this.selectedComponent.shape.shapeProperties.strokeWidth = this.rectangleProps.border;
        $('svg')
            .find('#' + this.shapeProperties.name)
            .css({
                width: this.rectangleProps.width + 'px',
                height: this.rectangleProps.height + 'px',
                stroke: this.rectangleProps.boderColor,
                strokeWidth: this.rectangleProps.border + 'px',
                fill: this.rectangleProps.fillColor,
            });
        this.selectedComponent.shape.shapeProperties.fillColor = this.rectangleProps.fillColor;
        this.selectedComponent.shape.shapeProperties.strokeColor = this.rectangleProps.boderColor;
        this.selectedComponent.shape.height = this.rectangleProps.height;
        this.selectedComponent.shape.width = this.rectangleProps.width;
        if (
            this.shapeProperties.name.includes('square') ||
            this.shapeProperties.name.includes('rectangle')
        ) {
            this.selectedComponent.shape.shapeProperties.strokeWidth = this.rectangleProps.border;
            this.selectedComponent.shape.rx = this.rectangleProps.borderRadius;
            this.selectedComponent.shape.ry = this.rectangleProps.borderRadius;
            $('#' + this.shapeProperties.name).attr({
                rx: this.rectangleProps.borderRadius,
                ry: this.rectangleProps.borderRadius,
            });
        }
        this.shapeService.updateShapeComponents(this.selectedComponent);
    }

    startDragging(event): void {
        this.isDragging = true;
        // this.isHidden = true;
        // console.log('startDragging()');
        // Make sure the first transform on the element is a translate transform
    }

    dragComponent(event): void {
        // console.log('dragComponent()');
    }

    endDragging(): void {
        this.selectedComponent = null;
        // console.log('endDragging()');
    }

    rgbToHex(r, g, b) {
        if (r > 255 || g > 255 || b > 255) {
            throw new Error('Invalid color component');
        }
        // tslint:disable-next-line:no-bitwise
        return ((r << 16) | (g << 8) | b).toString(16);
    }

    submit(value: any) {
        this.selectedComponent.updateShapeProperties(value);
    }
    sidebarToggle() {
        $('#sidebar-toggle').toggleClass('expand-nav-desplay-none');
        $('.nav-pad').toggleClass('expand-nav-desplay-none');
        $('.svg-editor-perent').toggleClass('svg-editor-perent-editor-expand');
        this.assignHeightWidth(this.pageProps.selctedPageSize);
    }

    export() {
        const s = new XMLSerializer();
        const str = s.serializeToString(this.svg);
        const reg = new RegExp(
            'assets/images/svg-editor/sampleqrcode.png',
            'g'
        );
        str.replace(reg, '{{pngData}}');
    }

    getAllTemplates() {
        this.pdfService.getAllTemplates()
        .subscribe((res: any) => {
                this.allTemplates = res;
            },(err) => {
                const cookieTrail = {
                    screenName: 'Campaign Editor',
                    action: 'Get All Templates',
                    error: err.error || 'Something went wrong!',
                };
                  
                this.cookieTrailService.saveCookie(cookieTrail);
                this.communicationService.openAlert({
                    alertType: 'error',
                    alertMsg: err.error.message || 'Something went wrong!',
                });
            }
        );
    }

    saveTemplate() {
        this.submitted = true;
        $('.draggable').removeClass('selected-item');
        if (!this.templateName) {
            return;
        }

        if (this.getShapes().length <= 0) {
            return;
        }
        this.shapeService.addVariable();
        const components = this.shapeService.getShapeComponents();
        setTimeout(() => {
            const s = new XMLSerializer();
            const str = s.serializeToString(this.svg);
            const reg = new RegExp(
                'assets/images/svg-editor/sampleqrcode.png',
                'g'
            );
            const template = str.replace(reg, '{{pngData}}');
            // const template = str.replace('assets/images/svg-editor/sampleqrcode.png', '{{pngData}}');

            const templateData = {
                template,
                component: [],
                name: this.templateName,
                height: this.svgHeight,
                width: this.svgWidth,
                pageProps: this.pageProps,
                campaignType: this.iccidDisable ? 'group': 'iccid',
                logo: localStorage.getItem('qrLogo'),
                backgroundImage: this.backgroundImage,
                backgroundStyle: this.backgroundStyle,
            };
            components.forEach((element) => {
                const tcomp = {
                    formFields: element.formFields,
                    isSelected: element.isSelected,
                    selectionPoints: element.selectionPoints,
                    shapeType: element.shapeType,
                    shape: element.shape,
                };
                templateData.component.push(tcomp);
            });
            this.btnSpinner = true;
            const cookieTrail = {
                screenName: 'Campaign Editor',
                action: 'Save Campaign'
            };
              
            this.cookieTrailService.saveCookie(cookieTrail);
            this.pdfService.saveTemplate(templateData).subscribe(
                (res: any) => {
                    this.tempPdfID = res;
                    this.templateName = '';
                    this.submitted = false;
                    this.savedCampaign = res;
                    this.btnSpinner = false;
                    $('#saveTemplate').modal('hide');
                    this.communicationService.openAlert({
                        alertType: 'success',
                        alertMsg: 'Campaign saved successfully.',
                    });
                },
                (err) => {
                    this.btnSpinner = false;
                    $('#blacklistAlert').modal('hide');
                    this.communicationService.openAlert({
                        alertType: 'error',
                        alertMsg: err.error.message || 'Something went wrong!',
                    });
                }
            );
        }, 100);

        setTimeout(() => {
            this.shapeService.removeVariable();            
            if (localStorage.getItem('qrLogo')) {
                const data = {
                    logo: localStorage.getItem('qrLogo'),
                    text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
                }
                this.pdfService.addLogoInQr(data)
                .subscribe((res: any) => {
                    this.selectedComponent.shape.url = res.qrCode;
                    this.shapeService.updateLogo(res.qrCode);
                }, err => {
                    console.log(err);
                });
            }
        }, 150);
    }

    updateCampign() {
        if (!this.savedCampaign) {
            $('#saveTemplate').modal({ backdrop: 'static', keyboard: false });
            return;
        }
        $('.draggable').removeClass('selected-item');
        this.shapeService.addVariable();
        const components = this.shapeService.getShapeComponents();
        setTimeout(() => {
            const s = new XMLSerializer();
            const str = s.serializeToString(this.svg);
            const reg = new RegExp(
                'assets/images/svg-editor/sampleqrcode.png',
                'g'
            );
            const template = str.replace(reg, '{{pngData}}');
            const templateData = {
                template,
                component: [],
                name: this.savedCampaign.name,
                height: this.svgHeight,
                width: this.svgWidth,
                pageProps: this.pageProps,
                campaignType: this.iccidDisable ? 'group': 'iccid',
                logo: localStorage.getItem('qrLogo'),
                backgroundImage: this.backgroundImage,
                backgroundStyle: this.backgroundStyle,
            };
            components.forEach((element) => {
                const tcomp = {
                    formFields: element.formFields,
                    isSelected: element.isSelected,
                    selectionPoints: element.selectionPoints,
                    shapeType: element.shapeType,
                    shape: element.shape,
                };
                templateData.component.push(tcomp);
            });
            this.btnSpinner = true;

            const cookieTrail = {
                screenName: 'Campaign Editor',
                action: 'Update Campaign',
                body: {
                    templateId: this.tempPdfID._id,
                }
            };
              
            this.cookieTrailService.saveCookie(cookieTrail);

            this.pdfService
                .updateCampaign(this.tempPdfID._id, templateData)
                .subscribe(
                    (res: any) => {
                        this.tempPdfID = res;
                        this.savedCampaign = res;
                        this.templateName = '';
                        this.submitted = false;
                        $('#saveTemplate').modal('hide');
                        this.btnSpinner = false;
                        this.communicationService.openAlert({
                            alertType: 'success',
                            alertMsg: 'Campaign updated successfully.',
                        });
                    },
                    (err) => {
                        $('#blacklistAlert').modal('hide');
                        this.btnSpinner = false;
                        this.communicationService.openAlert({
                            alertType: 'error',
                            alertMsg:
                                err.error.message || 'Something went wrong!',
                        });
                    }
                );
        }, 100);

        setTimeout(() => {
            this.shapeService.removeVariable();
            if (localStorage.getItem('qrLogo')) {
                const data = {
                    logo: localStorage.getItem('qrLogo'),
                    text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
                }
                this.pdfService.addLogoInQr(data)
                .subscribe((res: any) => {
                    this.shapeService.updateLogo(res.qrCode);
                }, err => {
                    console.log(err);
                });
            }
        }, 150);
    }

    uploadQrFile() {
        $('#svg-bulk-qr-file').click();
    }

    onQrFileChange(evt) {
        if (evt.target.files.length > 0) {
            if (
                !(
                    evt.target.files[0].type === 'text/plain' ||
                    evt.target.files[0].type === 'text/csv'
                )
            ) {
                this.fileTypeSelectionErr = true;
                return;
            }

            this.fileTypeSelectionErr = false;
            this.fileSelectionErr = false;
            this.iccidFile = evt.target.files[0];
            evt.target.value = '';
        }
    }

    generatePdf() {
        console.log(this.tempPdfID);
        if (!this.tempPdfID && !this.iccidFile) {
            this.templateSelectionErr = true;
            this.fileSelectionErr = true;
            return;
        } else if (!this.tempPdfID) {
            this.templateSelectionErr = true;
            return;
        } else if (this.tempPdfID.campaignType === 'iccid' && !this.iccidFile) {
            this.fileSelectionErr = true;
            return;
        } else if (this.tempPdfID.campaignType === 'group' && !this.groupMatchingId) {
            this.groupSelectionErr = true;
            return;
        }
        console.log(this.tempPdfID.campaignType);
        // return;
        if (this.tempPdfID.campaignType === 'group') {
            this.generateGroupPdf();
        } else {
            this.generateIccidPdf();
        }
        this.btnSpinner = true;
    }
    
    generateIccidPdf(){
        const formData: FormData = new FormData();
        formData.append('file', this.iccidFile, this.iccidFile.name);
        formData.append('type', this.selectedCampaignFormat.toLowerCase());
        formData.append('campaignId', this.tempPdfID._id);
        formData.append('campaignType', this.selectedCampaignFormat.toLowerCase());
        formData.append('campaignCheck', 'true');
        formData.append('operation', 'QRCODE_FILE');

        this.btnSpinner = true;

        this.profileService.generateMultipleQrCode(formData)
            .subscribe((res: any) => {
                this.btnSpinner = false;
                $('#generatePdf').modal('hide');
                this.communicationService.openAlert({
                    alertType: 'success',
                    alertMsg: res.message,
                });
            }, err => {
                $('#generatePdf').modal('hide');
                this.btnSpinner = false;
                this.communicationService.openAlert({
                    alertType: 'error',
                    alertMsg: err.error.message || 'Something went wrong!',
                });
            });

        // this.pdfService.generateIccidPdf(this.tempPdfID._id, file).subscribe(
        //     (res: any) => {
        //         this.btnSpinner = false;
        //         $('#generatePdf').modal('hide');
        //         this.communicationService.openAlert({
        //             alertType: 'success',
        //             alertMsg: res.message,
        //         });
        //     },
        //     (err) => {
        //         $('#generatePdf').modal('hide');
        //         this.btnSpinner = false;
        //         this.communicationService.openAlert({
        //             alertType: 'error',
        //             alertMsg: err.error.message || 'Something went wrong!',
        //         });
        //     }
        // );
    }
    generateGroupPdf() {
        this.pdfService.generateGroupPdf(this.tempPdfID._id, { groupMatchingId: this.groupMatchingId }).subscribe(
            (res: any) => {
                this.btnSpinner = false;
                $('#generatePdf').modal('hide');
                this.communicationService.openAlert({
                    alertType: 'success',
                    alertMsg: res.message,
                });
            },
            (err) => {
                $('#generatePdf').modal('hide');
                this.btnSpinner = false;
                this.communicationService.openAlert({
                    alertType: 'error',
                    alertMsg: err.error.message || 'Something went wrong!',
                });
            }
        );
    }
    // removeElement() {

    // }

    undo() {        
        this.shapeService.removeAllShapeComponents();
        const tempView = [];
        let noOfComp = 1;
        if ( !this.redoActions.length || this.redoActions.length === 0 ) {
            noOfComp = 2;
        }
        this.allComponents[this.allComponents.length - 2].forEach(element => {
            tempView.push(JSON.parse(element));
        });
        this.loadView(tempView);
        setTimeout(() => {
            if(!this.allComponents[this.allComponents.length - 1].length) {
                return;
            }
            this.redoActions.push(this.allComponents[this.allComponents.length - 1]);
            this.allComponents.splice(this.allComponents.length - 1, 2);
            if( !this.allComponents[0]) {
                this.allComponents.push([]);
            }
        }, 100);
        this.resetTempProps();
    }

    redo() {        
        this.shapeService.removeAllShapeComponents();
        const tempView = [];
        this.redoActions[this.redoActions.length - 1].forEach(element => {
            tempView.push(JSON.parse(element));
        });
        this.loadView(tempView);
        setTimeout(() => {
            this.allComponents.push(this.redoActions[this.redoActions.length - 1]);
            this.redoActions.splice(this.redoActions.length - 1, 1)
        }, 100);
    }

    dragEnd(color) {
        // console.log(color);
    }
    addActions() {
        this.checkActions();
        // this.redoActions = [];
        // const shape = $('svg')
        //     .find('#' + this.shapeProperties.name)
        //     .clone();
        // this.allActions.push({
        //     name: this.shapeProperties.name,
        //     component: this.selectedComponent,
        //     shape,
        // });
    }

    checkActions() {
        this.redoActions = [];
        const components = this.shapeService.getShapeComponents();            
        const componentJSON = [];
        components.forEach((element) => {
            const tcomp = {
                formFields: element.formFields,
                isSelected: element.isSelected,
                selectionPoints: element.selectionPoints,
                shapeType: element.shapeType,
                shape: element.shape,
            };
            componentJSON.push(JSON.stringify(tcomp));
        });        
        if( this.allComponents.length > 1) {
            if(this.allComponents[this.allComponents.length - 1].join('') !== componentJSON.join('')) {
                this.allComponents.push(componentJSON);
            }
        } else {
            this.allComponents.push(componentJSON);
        }
    }

    newCampaign() {
        this.groupDisable = false;
        this.iccidDisable = false;
        localStorage.removeItem('qrLogo');
        this.removeBackground();
        this.shapeService.removeAllShapeComponents();
        this.allActions = [];
        this.allComponents = [];
        this.redoActions = [];
        this.isSelect = true;
        this.selectedComponent = null;
        this.textSelected = false;
        this.isSelect = false;
        this.isDrawing = false;
        this.isDragging = false;
        this.isResizing = false;
        this.savedCampaign = null;
        this.tempPdfID = null;
    }

    defaultPageSize() {
        this.pageProps = {
            pageSizes: ['A4', 'Business Card'],
            pageBackground: '#fff',
            selctedPageSize: 'A4'
        };
        this.assignHeightWidth('A4');
        $('.svg-editor-main-view-inner')
        .find('svg')
        .css({'background-color': this.pageProps.pageBackground});
        this.selectTool('Pointer');
    }

    loadView(components) {
        const injector = Injector.create(
            [],
            this.viewContainerRef.parentInjector
        );
        components.forEach((element) => {
            setTimeout(() => {
                const factory = this.componentFactoryResolver.resolveComponentFactory(
                    this.buildComponent(element.shapeType)
                );
                const component = factory.create(injector);
                this.selectedComponent = component.instance as ShapeComponent;
                this.shapeService.setShapeComponent(this.selectedComponent);
                this.selectedComponent.shape.shapeProperties = Object.assign(
                    {},
                    element.shape.shapeProperties
                );
                this.selectedComponent.shape.originX = element.shape.originX;
                this.selectedComponent.shape.originY = element.shape.originY;
                this.selectedComponent.shape.rx = element.shape.rx;
                this.selectedComponent.shape.ry = element.shape.ry;
                this.selectedComponent.shape.x2 = element.shape.x2;
                this.selectedComponent.shape.y2 = element.shape.y2;
                this.selectedComponent.shape.r = element.shape.r;
                this.selectedComponent.shape['font-style'] =
                    element.shape['font-style'];
                this.selectedComponent.shape['font-family'] =
                    element.shape['font-family'];
                this.selectedComponent.shape.fill = element.shape.fill;
                this.selectedComponent.shape['font-size'] =
                    element.shape['font-size'];
                this.selectedComponent.shape.value = element.shape.value;
                this.selectedComponent.shape.url = element.shape.url;
                this.selectedComponent.shape.width = element.shape.width;
                this.selectedComponent.shape.height = element.shape.height;
                this.applyjqueryProps(this.selectedComponent);
            }, 10);
        });
        setTimeout(() => {
            this.shapeService.removeVariable();
            const data = {
                    logo: localStorage.getItem('qrLogo'),
                    text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
            }
            this.pdfService.addLogoInQr(data)
            .subscribe((res: any) => {
                this.selectedComponent.shape.url = res.qrCode;
                this.shapeService.updateLogo(res.qrCode);
            }, err => {
                console.log(err);
            });
        }, 200);
    }

    applyjqueryProps(component) {
        setTimeout(() => {
            $('svg')
                .find('#' + component.shape.shapeProperties.name)
                .attr({ rx: component.shape.rx, ry: component.shape.ry });
            $('svg')
                .find('#' + component.shape.shapeProperties.name)
                .css({
                    fill: component.shape.fill,
                });
        }, 10);
    }

    openCampaign() {
        if (!this.tempPdfID) {
            this.templateSelectionErr = true;
            return;
        }

        this.btnSpinner = true;
        this.pdfService.getCampaign(this.tempPdfID._id).subscribe(
            (res: any) => {
                this.newCampaign();
                if (res.campaignType === 'group') {
                    this.iccidDisable = true;
                } else {
                    this.groupDisable = true;
                }
                $('#pageSize').modal('hide');
                this.pageProps.pageBackground = (res.pageProps && res.pageProps.pageBackground) ? res.pageProps.pageBackground : '#fff';
                this.pageProps.selctedPageSize = (res.pageProps && res.pageProps.selctedPageSize) ? res.pageProps.selctedPageSize : 'A4';
                this.assignHeightWidth(this.pageProps.selctedPageSize);
                this.tempPdfID = res;
                this.savedCampaign = res;
                localStorage.setItem('qrLogo', res.logo);
                this.loadView(res.component);
                this.backgroundImage = res.backgroundImage;
                this.backgroundStyle = res.backgroundStyle? res.backgroundStyle: 'cover';
                $('#openCampaign').modal('hide');
                this.btnSpinner = false;
                this.svgHeight = res.height;
                this.svgWidth = res.width;
                $('.svg-editor-main-view-inner')
                .find('svg')
                .css({
                    background: `url(${this.backgroundImage})`,
                    'background-size': this.backgroundStyle,
                    height: this.svgHeight,
                    width: this.svgWidth,
                    'background-color': this.pageProps.pageBackground
                });                
                const innerWidth = $('.svg-editor-main-view').width();
                const innerHeight = $('.svg-editor-main-view').height();
                this.svgWidth = innerWidth;
                $('.svg-editor-main-view-inner').css({height: `${innerHeight}px`, width: `${innerWidth}px`});
            },
            (err) => {
                const cookieTrail = {
                    screenName: 'Campaign Editor',
                    action: 'Open Campaign',
                    body: {
                        templateId: this.tempPdfID._id,
                    },
                    error: err.error || 'Something went wrong!',
                };
                  
                this.cookieTrailService.saveCookie(cookieTrail);
                this.btnSpinner = false;
                this.communicationService.openAlert({
                    alertType: 'error',
                    alertMsg: err.error.message || 'Something went wrong!',
                });
            }
        );
    }

    stringToHTML(str) {
        const parser = new DOMParser();
        const doc: any = parser.parseFromString(str, 'text/html');
        return doc.querySelector('svg');
    }

    isSpace(evt) {
        evt = evt ? evt : window.event;
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode === 32 && !this.templateName && this.templateName === '') {
            return false;
        }

        return true;
    }

    onRightClick(evt) {
        if(this.selectedComponent && this.selectedComponent.shape.shapeProperties.name.includes('watermark')) {
            this.isWatermark = true
        } else {
            this.isWatermark = false;
        }

        this.onMouseUp(evt);
        evt.stopPropagation();
        this.isHidden = false;
        this.isDragging = false;
        const origin = {
            left: this.currentPosition.x,
            top: this.currentPosition.y,
        };
        this.setPosition(origin);
        return false;
    }

    setPosition({ top, left }) {
        const windowHeight = this.svgHeight / 2;
        const windowWidth = this.svgWidth / 2;
        this.menu = document.querySelector('#menuView');

        if (top > windowHeight && left <= windowWidth) {
            this.menu.style.left = `${left}px`;
            this.menu.style.right = `auto`;
            $(document).ready(() => {
                const length = this.menuView.nativeElement.childNodes[1]
                    .childNodes[0].childElementCount;
                if (this.svgHeight - top >= length * 48) {
                    this.menu.style.bottom = `auto`;
                    this.menu.style.top = `${top}px`;
                } else {
                    this.menu.style.bottom = `${this.svgHeight - top}px`;
                    this.menu.style.top = `auto`;
                }
            });
        } else if (top > windowHeight && left > windowWidth) {
            if (this.svgWidth - left >= 190) {
                this.menu.style.left = `${left}px`;
                this.menu.style.right = `auto`;
            } else {
                this.menu.style.left = `auto`;
                this.menu.style.right = `${this.svgWidth - left}px`;
            }
            $(document).ready(() => {
                const length = this.menuView.nativeElement.childNodes[1]
                    .childNodes[0].childElementCount;
                if (this.svgHeight - top >= length * 48) {
                    this.menu.style.bottom = `auto`;
                    this.menu.style.top = `${top}px`;
                } else {
                    this.menu.style.bottom = `${this.svgHeight - top}px`;
                    this.menu.style.top = `auto`;
                }
            });
        } else if (top <= windowHeight && left <= windowWidth) {
            this.menu.style.left = `${left}px`;
            this.menu.style.right = `auto`;
            this.menu.style.bottom = `auto`;
            this.menu.style.top = `${top}px`;
        } else {
            if (this.svgWidth - left >= 190) {
                this.menu.style.left = `${left}px`;
                this.menu.style.right = `auto`;
            } else {
                this.menu.style.left = `auto`;
                this.menu.style.right = `${this.svgWidth - left}px`;
            }
            this.menu.style.bottom = `auto`;
            this.menu.style.top = `${top}px`;
        }
    }

    isNumber(evt) {
        evt = evt ? evt : window.event;
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 47 && charCode < 58) {
            return true;
        }
        return false;
    }

    rightClickFunctions(action) {    
        this.checkActions();    
        switch (action) {
            case 'removeElement': {
                if (!this.deleteOn) {
                    return;
                }
                this.shapeService.removeComponents(this.selectedComponent);
                this.textSelected = false;
                this.isSelect = false;
                this.isHidden = true;
                $('.draggable').removeClass('selected-item');
                this.isDrawing = false;
                this.isDragging = false;
                this.isResizing = false;
                this.selectedComponent = null;
                this.deleteOn = false;
                this.isSelect = true;
                break;
            }

            case 'bringToFront': {
                this.shapeService.bringToFront(this.selectedComponent);
                this.isHidden = true;
                break;
            }
        
            case 'bringForward': {
                this.shapeService.bringForward(this.selectedComponent);
                this.isHidden = true;
                break;
            }
        
            case 'sendBack': {
                this.shapeService.sendBack(this.selectedComponent);
                this.isHidden = true;
                break;
            }
        
            case 'sendBackward': {
                this.shapeService.sendBackward(this.selectedComponent);
                this.isHidden = true;
                break;
            }
        }
    }

    resetTempProps() {
        this.isImageSelected = false;
        this.qrWithLogo = false;
        this.rectangleProps = {
            id: '',
            height: 0,
            width: 0,
            border: 0,
            borderRadius: 0,
            fillColor: '#FFF',
            boderColor: '#FFF',
        };
        this.textProperties = {
            font: '',
            value: '',
            fontSize: 10,
            fillColor: '#FFF',
            bold: false,
            italic: false,
            underLine: false,
        };
    }


    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    saveCropped() {
        this.loadCropper = false;
        this.imageToCrop = this.croppedImage;
        this.selectedComponent.shape.url = this.croppedImage;
        $('#imageCropper').modal('hide');
        this.checkActions();
    }

    assignHeightWidth(size) {
        this.pageProps.selctedPageSize = size;
        const innerWidth = $('.svg-editor-main-view').width();
        const innerHeight = $('.svg-editor-main-view').height();
        this.svgWidth = innerWidth;
        $('.svg-editor-main-view-inner').css({height: `${innerHeight}px`, width: `${innerWidth}px`});
        switch (size) {
            case 'A4':
                this.svgHeight = (this.svgWidth * 297) / 210;
                break;
            case 'Business Card':
                this.svgHeight = (this.svgWidth * 55) / 85;
                break;
            case 'A5':
                this.svgHeight = (this.svgWidth * 210) / 148;
                break;
            case 'A7':
                this.svgHeight = (this.svgWidth * 105) / 74;
                break;
            case 'A8':
                this.svgHeight = (this.svgWidth * 74) / 52;
                break;
            case 'Story Size':
                this.svgHeight = this.svgWidth;
                break;
            case 'Envelope Size':
                this.svgHeight = (this.svgWidth * 7 ) / 5;
                break;
        
            default:
                this.svgHeight = (this.svgWidth * 297) / 210;
                break;
        }
        this.svgHeight = Math.floor(this.svgHeight);
        this.svgWidth = Math.floor(this.svgWidth);

        // this.selelectPageSize();
        $('.svg-editor-main-view-inner')
        .find('svg')
        .css({
            height: this.svgHeight,
            width: this.svgWidth,
        });
    }
    selelectPageSize() {
        // if (this.pageProps.selctedPageSize === 'A4') {
        //     this.svgHeight = (this.svgWidth * 297) / 210;
        // } else if (this.pageProps.selctedPageSize === 'Business Card') {
        //     this.svgHeight = (this.svgWidth * 55) / 85;
        // }
        $('#campaignEditorSvg').css({
            height: this.svgHeight + 'px',
        });
        this.svg = document.querySelector('#campaignEditorSvg');

        const vm = this;
        // $(window).resize(() => {
        //     vm.svgWidth = $('.svg-editor-main-view-inner').find('svg').width();
        //     vm.assignHeightWidth(this.pageProps.selctedPageSize);
        // });

        $('#pageSize').modal('hide');
    }

    clickUploadIcon(type) {
        $('#uploadLogo').click();
    }

    uploadLogo(file) {
        // const imageUrl = window.URL.createObjectURL(file[0]);
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = () => {
            const data = { text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE',
            logo: String(reader.result) };
            localStorage.setItem('qrLogo', String(reader.result));            
            this.pdfService.addLogoInQr(data)
            .subscribe((res: any) => {
                this.selectedComponent.shape.url = res.qrCode;
                this.shapeService.updateLogo(res.qrCode);
            }, err => {
                this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
            });
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        }; 
    }

    assignCustomSize() {
        this.customSizeFormSubmitted = true;
        if (this.customSizeForm.invalid) {
            return;
        }
        this.svgWidth = this.customSizeForm.get('customWidth').value,
        this.svgHeight = this.customSizeForm.get('customHeight').value
        $('.svg-editor-main-view-inner').find('svg').width(`${this.svgWidth}px`)
        $('.svg-editor-main-view-inner').find('svg').height(`${this.svgHeight}px`)
        $('#customSizeModal').modal('hide')
        $('.svg-editor-main-view-inner').css({height: `${this.svgHeight}px`, width: `${this.svgWidth}px`, background: '#FFF'});

    }

    resetForm() { 
        this.customSizeFormSubmitted = false;
        this.customSizeForm.reset();
    }

    getGroupProfiles() {
        this.grpProfileService.getProfileGroups()
          .subscribe((res: any) => {
            this.grpProfileList = res.profileGroups;
          }, err => {
            const cookieTrail = {
              screenName: 'Manage Group QR',
              action: 'Get Profile Groups',
              error: err.error || 'Something went wrong!',
            };        
            this.cookieTrailService.saveCookie(cookieTrail);
            this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
          });
    }
}
