<div class="inner-main-profile">
    <div class="face-container-inner-bg p-3 mb-3">
        <div class="date-n-search-row">
            <div class="t-head-title align-self-center flex-shrink-0 mr-5">
                {{'Governance Report' | translate}}
            </div>
            <div class="flex-shrink-0 username-select-dropdown" *ngIf="show">
                <label class="t-label pull-left mr-2" for="username-select">{{'User Name' | translate}}: </label>
                <ng-multiselect-dropdown class="custom-multiselect-dropdown-arrow" id="username-select" placeholder="{{'Search username here' | translate }}..." [settings]="userDropdownSettings" [data]="userList" [(ngModel)]="selectedUsers" (ngModelChange)="getGovernanceReport();">
                </ng-multiselect-dropdown>
            </div>
            <div class="d-flex">
                <div class="align-items-center pr-2">
                    <div>
                        <label class="t-label pull-left mr-2">{{'Select Date'| translate}}:</label>
                    </div>
                    <input class="t-input report-date-range" style="width: 325px;" [(ngModel)]="selectedMoments" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'" (ngModelChange)="getGovernanceReport()">
                    <owl-date-time #dt10 [hour12Timer]="true"></owl-date-time>
                </div>
                <!-- <div class="date-n-status-row-date-container-inner mr-2">
                    <label class="t-label pull-left mr-2" for="from-date-select">{{'From Date' | translate}}: </label>
                    <input class="t-input form-control" [(ngModel)]="fromDate" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" (ngModelChange)="getGovernanceReport()">
                    <owl-date-time #dt1></owl-date-time>
                </div>
                <div class="date-n-status-row-date-container-inner">
                    <label class="t-label pull-left mr-2" for="to-date-select">{{'To Date' | translate}}: </label>
                    <input class="t-input form-control" [(ngModel)]="toDate" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" (ngModelChange)="getGovernanceReport()">
                    <owl-date-time #dt2></owl-date-time>
                </div> -->
            </div>
        </div>
        <div class="filter-tags-row-new">
            <div class="profile-status-checkbox-row governance-filter">
                <div class="checkbox-perent-filter" *ngFor="let status of actionArray; let i = index">
                    <p class="checkbox-perent-filter-inner" [ngClass]="{'checkbox-perent-filter-inner-checked': status.checked}" (click)="status.checked = !status.checked;selectStatus(i)"> {{status.key}} <span> <i class="fa fa-plus ml-2" *ngIf="!status.checked"></i> <i class="fa fa-check ml-2" *ngIf="status.checked"></i></span></p>
                </div>
            </div>
        </div>
    </div>

    <div class="face-container-inner-bg mb-3">
        <div class="d-flex p-3 mb-2 mr-2" *ngIf="features && features[getFeatureIndex('gov_report')].view">
            <div class="ml-auto d-flex t-color active mr-3">
                <div class="d-flex align-items-center" *ngIf="features && features[getFeatureIndex('gov_email')].edit">
                    <div class="d-flex cursor-p" *ngIf="!mailSpinner" (click)="sendMail();">
                        <svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="28.21" height="21.488" viewBox="0 0 28.21 21.488"><defs></defs><g transform="translate(0 -61)"><g transform="translate(0 61)"><path class="a" d="M25.731,61H2.479A2.484,2.484,0,0,0,0,63.479V80.009a2.483,2.483,0,0,0,2.479,2.479H25.731a2.483,2.483,0,0,0,2.479-2.479V63.479A2.483,2.483,0,0,0,25.731,61Zm-.342,1.653L14.158,73.884,2.83,62.653ZM1.653,79.667V63.814l7.961,7.892Zm1.169,1.169,7.966-7.966,2.791,2.767a.826.826,0,0,0,1.166,0l2.721-2.721,7.922,7.922Zm23.736-1.169-7.922-7.922,7.922-7.922Z" transform="translate(0 -61)"/></g></g></svg>
                        <div>
                            {{'Send Email' | translate}}
                        </div>
                    </div>
                    <div class="btn-spinner mr-3" *ngIf="mailSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="t-color d-flex active mr-2" *ngIf="features && features[getFeatureIndex('gov_download')].edit">
                <div class="d-flex align-items-center cursor-p" *ngIf="!downloadSpinner" (click)="downloadReport();">
                    <svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><defs></defs><g transform="translate(-8 -8)"><path class="a" d="M25.5,8H22a.875.875,0,0,0,0,1.75h3.5a1.752,1.752,0,0,1,1.75,1.75v14a1.752,1.752,0,0,1-1.75,1.75h-14A1.752,1.752,0,0,1,9.75,25.5v-14A1.752,1.752,0,0,1,11.5,9.75H15A.875.875,0,1,0,15,8H11.5A3.5,3.5,0,0,0,8,11.5v14A3.5,3.5,0,0,0,11.5,29h14A3.5,3.5,0,0,0,29,25.5v-14A3.5,3.5,0,0,0,25.5,8Z"/><path class="a" d="M25.5,27.013l-2.006-2.006a.875.875,0,0,0-1.237,1.237l3.5,3.5a.875.875,0,0,0,1.237,0l3.5-3.5a.875.875,0,0,0-1.237-1.237L27.25,27.013V16.875a.875.875,0,1,0-1.75,0Z" transform="translate(-7.875 -4.5)"/></g></svg>
                    <div>
                        {{'Download' | translate}}
                    </div>
                </div>
                <div class="btn-spinner mr-3" *ngIf="downloadSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div> -->
        </div>

        <div class="governance-report-table p-2">
            <div class="t-grid-table-new">
                <div class="t-grid-table-wrapper t-head-bg">
                    <div class="t-box a">{{'User Name' | translate}}</div>
                    <div class="t-box b">{{'Timestamp' | translate}}</div>
                    <div class="t-box c">{{'Action' | translate}}</div>
                    <div class="t-box a">{{'User-Agent' | translate}}</div>
                </div>
                <div class="loader-flex-center" *ngIf="reportSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <div class="t-body-overflow" *ngIf="goverenceReportList && goverenceReportList.length <= 0 && !reportSpinner">
                    <div class="p-1 t-body-bg cursor-p text-center">
                        {{'No Data Found' | translate}}.
                    </div>
                </div>
                <div class="t-body-overflow" style="max-height: calc(95vh - 400px);" *ngIf="!reportSpinner">
                    <div class="t-grid-table-wrapper t-body-bg cursor-p" *ngFor="let item of goverenceReportList; let i = index;" (click)="getSingleUserReport(item._id)">
                        <div class="t-box d t-font-light">{{ item.email}}</div>
                        <div class="t-box d t-font-light">{{ item.created | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                        <div class="t-box d t-font-light">{{ item.action }}</div>
                        <div class="t-box d t-font-light">{{ item.useragent }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Singel user report modal -->

<div class="modal fade" id="userReport" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'User' | translate}} {{(singleUser ? singleUser.action : '') | titlecase | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="singleUser = '';">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0 device-flex-body" style="max-height: calc(95vh - 97px);overflow: auto;">
                <div class="loader-flex-center" *ngIf="userReportSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <div *ngIf="!singleUser && !userReportSpinner">
                    <div class="text-center">{{'No Data Found' | translate}}.</div>
                </div>
                <div *ngIf="singleUser && !userReportSpinner">
                    <div class="user-report-list d-flex t-font-light border-bottom-light">
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px">{{'User Name' | translate}}</div>
                        <div class="user-reposrt-2 flex-1 p-2">{{ singleUser.email }}</div>
                    </div>
                    <div class="user-report-list d-flex t-font-light border-bottom-light">
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px">{{'Timestamp' | translate}}</div>
                        <div class="user-reposrt-2 flex-1 p-2">{{ singleUser.created | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                    </div>
                    <div class="user-report-list d-flex t-font-light border-bottom-light">
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px">{{'Action' | translate}}</div>
                        <div class="user-reposrt-2 flex-1 p-2">{{ singleUser.action | titlecase }}</div>
                    </div>
                    <div class="user-report-list d-flex t-font-light border-bottom-light" *ngIf="singleUser && singleUser.subaction">
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px">{{'Operation' | translate}}</div>
                        <div class="user-reposrt-2 flex-1 p-2">{{ singleUser.subaction | titlecase }}</div>
                    </div>
                    <div class="user-report-list d-flex t-font-light border-bottom-light">
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px">{{'User-Agent' | translate}}</div>
                        <div class="user-reposrt-2 flex-1 p-2">{{ singleUser.useragent }}</div>
                    </div>
                    <div class="user-report-list d-flex t-font-light border-bottom-light" *ngFor="let item of singleUser.info">
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px" *ngIf="item.field === 'ip'">IP</div>
                        <div class="user-reposrt-1 flex-shrink-0 p-2 border-right-light" style="width: 150px" *ngIf="item.field !== 'ip'">{{ item.field | titlecase | translate }}</div>
                        <div class="user-reposrt-2 flex-1 p-2 api-tree-body-json-body-overflow" *ngIf="item.field === 'body'"><pre>{{ item.value | json}}</pre></div>
                        <div class="user-reposrt-2 flex-1 p-2" *ngIf="item.field !== 'body'">{{ item.value }}</div>
                    </div>
                    <div *ngIf="singleUser.iccidList && singleUser.iccidList.length > 0">
                        <div class="accordion" id="accordionExample">
                            <div class="card-detail-report">
                                <div class="detail-report-header t-head-bg d-flex" id="governanceDetailHeading">
                                    <h2 class="mb-0 text-center mx-auto">
                                        <button class="btn btn-link btn-block text-left govn-btn t-color collapsed" type="button" data-toggle="collapse" data-target="#governanceDetail" aria-expanded="true" aria-controls="governanceDetail">
                                    <span class="govn-see-more t-color">
                                        {{"See More" | translate}}
                                    </span>
                                    <span class="govn-see-less t-color">
                                        {{"See Less" | translate}}
                                    </span>
                                  </button>
                                    </h2>
                                </div>

                                <div id="governanceDetail" class="collapse governance-detail-report" aria-labelledby="governanceDetailHeading" data-parent="#accordionExample" *ngIf="singleUser.iccidList && singleUser.iccidList.length > 0">
                                    <div class="detail-report-body">
                                        <div class="t-grid-table-new">
                                            <div class="t-grid-table-wrapper border-bottom-light">
                                                <div class="t-box a px-2">ICCID</div>
                                                <div class="t-box b px-2">{{"State" | translate}}</div>
                                            </div>
                                            <div class="t-body-overflow">
                                                <div class="t-grid-table-wrapper t-body-bg cursor-p border-bottom-light" *ngFor="let item of singleUser.iccidList; let i = index;">
                                                    <div class="t-box d t-font-light" class="p-2">{{ item.iccid }}</div>
                                                    <div class="t-box d t-font-light" class="p-2">{{ item.state }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>