import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './report.component';
import { DetailDownloadComponent } from './detail-download/detail-download.component';
import { DeviceAnalyticsComponent } from './device-analytics/device-analytics.component';
import { ProfileDownloadComponent } from './profile-download/profile-download.component';

const routes: Routes = [
  {
    path: 'device-analytics', component: DeviceAnalyticsComponent
  },
  {
    path: 'device-analytics/:id', component: DeviceAnalyticsComponent
  },
  {
    path: 'detail-download', component: DetailDownloadComponent
  }, {
    path: 'profile-download', component: ProfileDownloadComponent
  },{
    path: ':id', component: ReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
