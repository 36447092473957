import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CircleComponent } from './circle/circle.component';
import { EllipseComponent } from './ellipse/ellipse.component';
import { GroupComponent } from './group/group.component';
import { ImageComponent } from './image/image.component';
import { LineComponent } from './line/line.component';
import { PathComponent } from './path/path.component';
import { PolyLineComponent } from './polyline/polyline.component';
import { RectangleComponent } from './rectangle/rectangle.component';
import { ShapeComponent } from './shape/shape.component';
import { SquareComponent } from './square/square.component';
import { TextComponent } from './text/text.component';
import { TriangleComponent } from './triangle/triangle.component';
import { DynamicSvgDirective } from '../directives/dynamic-svg.directive';
import { RoundedRactangleComponent } from './rounded-ractangle/rounded-ractangle.component';
import { QrcodeComponent } from '../dynamic-components/qrcode/qrcode.component';
import { LpaStringComponent } from '../dynamic-components/lpa-string/lpa-string.component';
import { ActokenComponent } from '../dynamic-components/actoken/actoken.component';
import { IccidComponent } from '../dynamic-components/iccid/iccid.component';
import { Pin1Component } from '../dynamic-components/pin1/pin1.component';
import { Pin2Component } from '../dynamic-components/pin2/pin2.component';
import { Puk1Component } from '../dynamic-components/puk1/puk1.component';
import { Puk2Component } from '../dynamic-components/puk2/puk2.component';
import { MccComponent } from '../dynamic-components/mcc/mcc.component';
import { MncComponent } from '../dynamic-components/mnc/mnc.component';
import { BarcodeComponent } from '../dynamic-components/barcode/barcode.component';
import { WatermarkComponent } from './watermark/watermark.component';
import { QrcodeWithLogoComponent } from '../dynamic-components/qrcodewithlogo/qrcode.component';

// import { DynamicSvgDirective } from '../directives/dynamic-svg.directive';

@NgModule({
  declarations: [
    CircleComponent,
    EllipseComponent,
    GroupComponent,
    ImageComponent,
    LineComponent,
    PathComponent,
    PolyLineComponent,
    RectangleComponent,
    ShapeComponent,
    SquareComponent,
    TextComponent,
    TriangleComponent,
    DynamicSvgDirective,
    RoundedRactangleComponent,
    QrcodeComponent,
    LpaStringComponent,
    ActokenComponent,
    IccidComponent,
    Pin1Component,
    Pin2Component,
    Puk1Component,
    Puk2Component,
    MccComponent,
    MncComponent,
    BarcodeComponent,
    WatermarkComponent,
    QrcodeWithLogoComponent
  ],
  imports: [
    BrowserModule
  ],
  entryComponents: [
    ShapeComponent,
    LineComponent,
    CircleComponent,
    RectangleComponent,
    SquareComponent,
    TriangleComponent,
    EllipseComponent,
    TextComponent,
    GroupComponent,
    ImageComponent,
    PolyLineComponent,
    PathComponent,
    RoundedRactangleComponent,
    QrcodeComponent,
    LpaStringComponent,
    ActokenComponent,
    IccidComponent,
    Pin1Component,
    Pin2Component,
    Puk1Component,
    Puk2Component,
    MccComponent,
    MncComponent,
    BarcodeComponent,
    WatermarkComponent,
    QrcodeWithLogoComponent
],
  exports: [
    CircleComponent,
    EllipseComponent,
    GroupComponent,
    ImageComponent,
    LineComponent,
    PathComponent,
    PolyLineComponent,
    RectangleComponent,
    ShapeComponent,
    SquareComponent,
    TextComponent,
    TriangleComponent,
    DynamicSvgDirective,
    RoundedRactangleComponent,
    QrcodeComponent,
    LpaStringComponent,
    ActokenComponent,
    IccidComponent,
    Pin1Component,
    Pin2Component,
    Puk1Component,
    Puk2Component,
    MccComponent,
    MncComponent,
    BarcodeComponent,
    WatermarkComponent,
    QrcodeWithLogoComponent 
  ],
  providers: [],
})
export class DrawComponentModule { }
