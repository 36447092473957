import { Component, OnInit } from '@angular/core';
import { RoleMgmtService } from '../../../shared/services/role-mgmt.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { UserState } from '../../../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import * as UserAction from '../../../shared/stores/user/user.action';
import { CookieTrailService } from '../../../shared/services/cookie-trail.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit {

    roleName;
    roleNameErr = false;
    leftFeatureArray = [];
    rightFeatureArray = [];
    nonFeatureArray = [];
    saveBtnSpinner = false;
    editOn = false;
    roleId;
    disableBookmark = false;

    constructor(
        private roleMgmtService: RoleMgmtService,
        private communicationService: CommunicationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private userStore: Store<UserState>,
        private cookieTrailService: CookieTrailService,
        private translate: TranslateService) {
            translate.addLangs(environment.languageArray);
            translate.setDefaultLang('en');
            let browserLang = localStorage.getItem('language');
            if (!browserLang) {
                browserLang = 'en';
                localStorage.setItem('language', browserLang);
            }
            translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
        }

    ngOnInit() {
        this.cookieTrailService.saveCookie({});

        this.activatedRoute.params
            .subscribe((params: any) => {
                if(params.id) {
                    this.editOn = true;
                    this.roleId = params.id
                    this.getSingleRoleMeta();
                } else {
                    this.getRoleMetaData();
                }
            });
    }

    changeLanguage() {
        const lang = localStorage.getItem('language');
        this.translate.use(lang);
    }

    getRoleMetaData() {
        this.roleMgmtService.getRoleMeta()
            .subscribe((res: any) => {
                res.metadata.forEach(role => {
                    if(role.name === 'Manage Profile') {
                        if(role.features[role.features.findIndex(ele => ele.key === 'prepare_multiple')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'prepare_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'reset_multiple')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'reset_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'qrcode_multiple')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'qrcode_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'logs')].view
                        || role.features[role.features.findIndex(ele => ele.key === 'operations')].view
                        || role.features[role.features.findIndex(ele => ele.key === 'tac_info')].view
                        || role.features[role.features.findIndex(ele => ele.key === 'download_qrcode_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'profile_email')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'qrcode_with_logo')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'prp_setting')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'prepare_smdp')].edit) {
                            this.disableBookmark = false;
                        } else {
                            this.disableBookmark = true;
                        }
                    } 

                    if(role.display) {
                        if(this.leftFeatureArray.length === this.rightFeatureArray.length) {
                            this.leftFeatureArray.push(role);
                        } else {
                            this.rightFeatureArray.push(role);
                        }
                    } else {
                        this.nonFeatureArray.push(role);
                    }
            });
        }, err => {
            const cookieTrail = {
                screenName: 'Access Management : Role Management',
                action: 'Get Role Data',
                error: err.error || 'Something went wrong!'
            };
              
            this.cookieTrailService.saveCookie(cookieTrail);
            this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    }

    getSingleRoleMeta() {
        this.roleMgmtService.getSingleRole(this.roleId)
            .subscribe((res: any) => {
                this.roleName = res.roleName;
                res.keys.forEach(role => {
                    if(role.name === 'Manage Profile') {
                        if(role.features[role.features.findIndex(ele => ele.key === 'prepare_multiple')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'prepare_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'reset_multiple')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'reset_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'qrcode_multiple')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'qrcode_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'logs')].view
                        || role.features[role.features.findIndex(ele => ele.key === 'operations')].view
                        || role.features[role.features.findIndex(ele => ele.key === 'tac_info')].view
                        || role.features[role.features.findIndex(ele => ele.key === 'download_qrcode_single')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'profile_email')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'qrcode_with_logo')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'prp_setting')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit
                        || role.features[role.features.findIndex(ele => ele.key === 'prepare_smdp')].edit) {
                            this.disableBookmark = false;
                        } else {
                            this.disableBookmark = true;
                        }
                    } 

                    if(role.display) {
                        if(this.leftFeatureArray.length === this.rightFeatureArray.length) {
                            this.leftFeatureArray.push(role);
                        } else {
                            this.rightFeatureArray.push(role);
                        }
                    } else {
                        this.nonFeatureArray.push(role);
                    }
                });
            }, err => {
                const cookieTrail = {
                    screenName: 'Access Management : Role Management',
                    action: 'Get Single Role Data',
                    body: {roleId: this.roleId},
                    error: err.error || 'Something went wrong!'
                };
                  
                this.cookieTrailService.saveCookie(cookieTrail);
                this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
            });
        }


    isSpace(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode === 32 && !this.roleName.trim()) {
            return false;
        }
    
        return true;
    }
    
    saveRole() {
        if(!this.roleName || (this.roleName && !this.roleName.trim())) {
            this.roleNameErr = true;
            return;
        }
        const temp = this.leftFeatureArray.concat(this.rightFeatureArray).concat(this.nonFeatureArray);

        const data = {
            roleName: this.roleName.trim(),
            metadata: temp
        };

        this.saveBtnSpinner = true;

        const cookieTrail = {
            screenName: 'Access Management : Role Management',
            action: 'Create Role',
            body: data
        };
          
        this.cookieTrailService.saveCookie(cookieTrail);

        this.roleMgmtService.saveRole(data)
            .subscribe((res: any) => {
                this.saveBtnSpinner = false;
                this.communicationService.openAlert({alertType: 'success', alertMsg: 'Role saved successfully!'});
                // this.router.navigate(['/access-management/role']);
            }, err => {
                this.saveBtnSpinner = false;
                this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
            });
    }

    editRole() {
        if(!this.roleName || (this.roleName && !this.roleName.trim())) {
            this.roleNameErr = true;
            return;
        }
        const temp = this.leftFeatureArray.concat(this.rightFeatureArray).concat(this.nonFeatureArray);

        const data = {
            roleName: this.roleName.trim(),
            keys: temp
        };

        this.saveBtnSpinner = true;

        const cookieTrail = {
            screenName: 'Access Management : Role Management',
            action: 'Update Role',
            body: data
        };
          
        this.cookieTrailService.saveCookie(cookieTrail);

        this.roleMgmtService.editRole(data, this.roleId)
            .subscribe((res: any) => {
                this.saveBtnSpinner = false;
                this.communicationService.openAlert({alertType: 'success', alertMsg: 'Role edited successfully!'});
                this.userService.getUser()
                    .subscribe((res: any) => {
                        this.userStore.dispatch(new UserAction.GetUser(res));
                        // setTimeout(() => {
                        //     this.router.navigate(['/access-management/role']);
                        // }, 5);
                    }, err => {
                        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
                    });
                // this.router.navigate(['/access-management/role']);
            }, err => {
                this.saveBtnSpinner = false;
                this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
            })
    }

    callFun(side, pIndex, index, featuer, featureName) {

        if(side === 'right') {
            if(featureName === 'profile' && (this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prepare_multiple')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prepare_single')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'reset_multiple')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'reset_single')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'qrcode_multiple')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'qrcode_single')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'logs')].view
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'operations')].view
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'tac_info')].view
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'download_qrcode_single')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'profile_email')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'qrcode_with_logo')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prp_setting')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit
            || this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prepare_smdp')].edit)) {
                this.disableBookmark = false;
            } else if(featureName === 'profile'){
                if(this.leftFeatureArray.findIndex(ele => ele.name === 'API') !== -1) {
                    this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'API')]
                        .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'API')].features.findIndex(ele => ele.key === 'api_bookmark')].view = false
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'API') !== -1) {
                    this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'API')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'API')].features.findIndex(ele => ele.key === 'api_bookmark')].view = false
                }
                this.disableBookmark = true;
            }

            if(this.rightFeatureArray[pIndex].features[index].key === 'group_qr_code' || this.rightFeatureArray[pIndex].features[index].key === 'groupqr_with_logo' || this.rightFeatureArray[pIndex].features[index].key === 'groupqr_with_campaign') {
                if(this.rightFeatureArray[pIndex].features[index].key === 'group_qr_code' && this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view === true) {
                    this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'profile_group')].view = true;
                } else if(this.rightFeatureArray[pIndex].features[index].key === 'group_qr_code') {
                    this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                    this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_logo')].edit = false;
                }
                if(this.rightFeatureArray[pIndex].features[index].edit) {
                    this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'profile_group')].view = true;
                    this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view = true;
                }
                // return;
            }

            if(this.rightFeatureArray[pIndex].features[index].key === 'groupqr_with_campaign') {
                if(this.leftFeatureArray.findIndex(ele => ele.name === 'Campaign Editor') !== -1) {
                    this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')]
                        .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')].features.findIndex(ele => ele.key === 'campaign')].view = true;
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'Campaign Editor') !== -1) {
                    this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')].features.findIndex(ele => ele.key === 'campaign')].view = true;
                }
            }

            if(this.rightFeatureArray[pIndex].features[index].key === 'profile_group') {
                if(featuer === 'view') {
                    if(!this.rightFeatureArray[pIndex].features[index].view) {
                        this.rightFeatureArray[pIndex].features[index].edit = false;
                        this.rightFeatureArray[pIndex].features[index].delete = false;
                        this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view = false;
                        this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                        this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_logo')].edit = false;
                    }
                }

                if(featuer === 'edit') {
                    if(!this.rightFeatureArray[pIndex].features[index].edit) {
                        if( this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view) {
                            this.rightFeatureArray[pIndex].features[index].view = true;
                        }
                    } else {
                        this.rightFeatureArray[pIndex].features[index].view = true;
                    }
                }

                if(featuer === 'delete') {
                    if(!this.rightFeatureArray[pIndex].features[index].delete) {
                        if( this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view) {
                            this.rightFeatureArray[pIndex].features[index].view = true;
                        }
                    } else {
                        this.rightFeatureArray[pIndex].features[index].view = true;
                    }
                }
                return;
            }

            if(this.rightFeatureArray[pIndex].features[index].key === 'campaign_generate') {
                if(this.rightFeatureArray[pIndex].features[index].view) {
                    this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign')].view = true;
                }
                return;
            }
    
            if(this.rightFeatureArray[pIndex].features[index].key === 'campaign') {

                if(this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile') !== -1) {
                    if(!this.rightFeatureArray[pIndex].features[index].view) {
                        this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                            .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit = false;

                            this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                            .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit = false;
                    }
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile') !== -1) {
                    if(!this.rightFeatureArray[pIndex].features[index].view) { 
                        this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit = false;
                    
                        this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit = false;
                    
                    }
                }

                if(this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Group QR') !== -1) {
                    if(!this.rightFeatureArray[pIndex].features[index].view) {
                        this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')]
                            .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                    }
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Group QR') !== -1) {
                    if(!this.rightFeatureArray[pIndex].features[index].view) { 
                        this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                    }
                }

                if(featuer === 'edit') {
                    if(!this.rightFeatureArray[pIndex].features[index].edit) {
                        if( this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign_generate')].view) {
                            this.rightFeatureArray[pIndex].features[index].view = true;
                        }
                    } else {
                        this.rightFeatureArray[pIndex].features[index].view = true;
                    }
                }
                return;
            }

            if(!this.rightFeatureArray[pIndex].features[index].edit && featuer === 'delete') {
                this.rightFeatureArray[pIndex].features[index].view = this.rightFeatureArray[pIndex].features[index].delete;
            } else if(!this.rightFeatureArray[pIndex].features[index].delete && featuer === 'edit') {
                this.rightFeatureArray[pIndex].features[index].view = this.rightFeatureArray[pIndex].features[index].edit;
            }

            console.log(this.rightFeatureArray[pIndex].features[index].key);

            if(this.rightFeatureArray[pIndex].features[index].key === 'troubleshoot_shoot' && this.rightFeatureArray[pIndex].features[index].view) {
                this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'notification_tab')].view = true;
            }

            if(this.rightFeatureArray[pIndex].features[index].key === 'notification_tab' && !this.rightFeatureArray[pIndex].features[index].view) {
                this.rightFeatureArray[pIndex].features[this.rightFeatureArray[pIndex].features.findIndex(ele => ele.key === 'troubleshoot_shoot')].view = false;
            }
        } else if(side === 'left') {
            if(featureName === 'profile' && (this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prepare_multiple')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prepare_single')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'reset_multiple')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'reset_single')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'qrcode_multiple')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'qrcode_single')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'logs')].view
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'operations')].view
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'tac_info')].view
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'download_qrcode_single')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'profile_email')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'qrcode_with_logo')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prp_setting')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit
            || this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'prepare_smdp')].edit)) {
                this.disableBookmark = false;
            } else if(featureName === 'profile'){
                if(this.leftFeatureArray.findIndex(ele => ele.name === 'API') !== -1) {
                    this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'API')]
                        .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'API')].features.findIndex(ele => ele.key === 'api_bookmark')].view = false;
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'API') !== -1) {
                    this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'API')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'API')].features.findIndex(ele => ele.key === 'api_bookmark')].view = false;
                }

                this.disableBookmark = true;
            }


            if(this.leftFeatureArray[pIndex].features[index].key === 'campaign_in_prepare' || this.leftFeatureArray[pIndex].features[index].key === 'campaign_in_qrcode' || this.leftFeatureArray[pIndex].features[index].key === 'groupqr_with_campaign') {
                if(this.leftFeatureArray.findIndex(ele => ele.name === 'Campaign Editor') !== -1) {
                    this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')]
                        .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')].features.findIndex(ele => ele.key === 'campaign')].view = true;
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'Campaign Editor') !== -1) {
                    this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')]
                        .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Campaign Editor')].features.findIndex(ele => ele.key === 'campaign')].view = true;
                }
            }

            if(this.leftFeatureArray[pIndex].features[index].key === 'group_qr_code' || this.leftFeatureArray[pIndex].features[index].key === 'groupqr_with_logo' || this.leftFeatureArray[pIndex].features[index].key === 'groupqr_with_campaign') {
                if(this.leftFeatureArray[pIndex].features[index].key === 'group_qr_code' && this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view) {
                    this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'profile_group')].view = true;
                } else if(this.leftFeatureArray[pIndex].features[index].key === 'group_qr_code'){
                    this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_logo')].edit = false;
                    this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                }
                if(this.leftFeatureArray[pIndex].features[index].edit) {
                    this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'profile_group')].view = true;
                    this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view = true;
                }
                // return;
            }

            if(this.leftFeatureArray[pIndex].features[index].key === 'profile_group') {
                if(featuer === 'view') {
                    if(!this.leftFeatureArray[pIndex].features[index].view) {
                        this.leftFeatureArray[pIndex].features[index].edit = false;
                        this.leftFeatureArray[pIndex].features[index].delete = false;
                        this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view = false;
                        this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_logo')].edit = false;
                        this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                    }
                }
                
                if(featuer === 'edit') {
                    if(!this.leftFeatureArray[pIndex].features[index].edit) {
                        if( this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view) {
                            this.leftFeatureArray[pIndex].features[index].view = true;
                        }
                    } else {
                        this.leftFeatureArray[pIndex].features[index].view = true;
                    }
                }

                if(featuer === 'delete') {
                    if(!this.leftFeatureArray[pIndex].features[index].delete) {
                        if( this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'group_qr_code')].view) {
                            this.leftFeatureArray[pIndex].features[index].view = true;
                        }
                    } else {
                        this.leftFeatureArray[pIndex].features[index].view = true;
                    }
                }
                return;
            }

            if(this.leftFeatureArray[pIndex].features[index].key === 'campaign_generate') {
                if(this.leftFeatureArray[pIndex].features[index].view) {
                    this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign')].view = true;
                }
                return;
            }
    
            if(this.leftFeatureArray[pIndex].features[index].key === 'campaign') {

                if(this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile') !== -1) { 
                    if(!this.leftFeatureArray[pIndex].features[index].view) {
                        this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                            .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit = false;

                        this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                            .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit = false;
                    }
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile') !== -1) { 
                    if(!this.leftFeatureArray[pIndex].features[index].view) { 
                        this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                            .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_prepare')].edit = false;
                    
                        this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')]
                            .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Profile')].features.findIndex(ele => ele.key === 'campaign_in_qrcode')].edit = false;
                    
                    }
                }

                if(this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Group QR') !== -1) { 
                    if(!this.leftFeatureArray[pIndex].features[index].view) {
                        this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')]
                            .features[this.leftFeatureArray[this.leftFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                    }
                } else if (this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Group QR') !== -1) { 
                    if(!this.leftFeatureArray[pIndex].features[index].view) { 
                        this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')]
                            .features[this.rightFeatureArray[this.rightFeatureArray.findIndex(ele => ele.name === 'Manage Group QR')].features.findIndex(ele => ele.key === 'groupqr_with_campaign')].edit = false;
                    }
                }

                if(featuer === 'edit') {
                    if(!this.leftFeatureArray[pIndex].features[index].edit) {
                        if( this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'campaign_generate')].view) {
                            this.leftFeatureArray[pIndex].features[index].view = true;
                        }
                    } else {
                        this.leftFeatureArray[pIndex].features[index].view = true;
                    }
                }
                return;
            }

            if(!this.leftFeatureArray[pIndex].features[index].edit && featuer === 'delete') {
                this.leftFeatureArray[pIndex].features[index].view = this.leftFeatureArray[pIndex].features[index].delete;
            } else if(!this.leftFeatureArray[pIndex].features[index].delete && featuer === 'edit') {
                this.leftFeatureArray[pIndex].features[index].view = this.leftFeatureArray[pIndex].features[index].edit;
            }

            if(this.leftFeatureArray[pIndex].features[index].key === 'troubleshoot_shoot' && this.leftFeatureArray[pIndex].features[index].view) {
                this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'notification_tab')].view = true;
            }

            if(this.leftFeatureArray[pIndex].features[index].key === 'notification_tab' && !this.leftFeatureArray[pIndex].features[index].view) {
                this.leftFeatureArray[pIndex].features[this.leftFeatureArray[pIndex].features.findIndex(ele => ele.key === 'troubleshoot_shoot')].view = false;
            }
        }
    }

}
