<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="row">
                <div class="col-lg-7">
                    <div class="face-container-inner-bg p-3" style="border-bottom-left-radius: 0;border-bottom-right-radius: 0;" *ngIf="user">
                        <div class="row mb-2 border-bottom">
                            <div class="col-lg-12">
                                <h4 class="mb-3">{{ "Open Support Case with 10T Tech" | translate}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="face-container-inner-bg mb-2 p-3" *ngIf="user" style="border-top-left-radius: 0;border-top-right-radius: 0;max-height: calc(100vh - 200px); overflow: auto;">
                        <div>
                            <div class="support-info-container mb-4">
                                <div class="support-title">{{"User Name" | translate}}:</div>
                                <div class="support-desc">{{user.firstName + ' ' + user.lastName}}</div>
                            </div>
                            <div class="support-info-container mb-4">
                                <div class="support-title">{{"Email Address" | translate}}:</div>
                                <div class="support-desc">{{user.email}}</div>
                            </div>
                            <div class="support-info-container mb-4">
                                <div class="support-title">{{"Phone Number" | translate}}:</div>
                                <div class="support-desc">{{user.phone}}</div>
                            </div>
                            <div class="support-info-container mb-4">
                                <div class="support-title">{{"Name of Company" | translate}}:</div>
                                <div class="support-desc">{{user.currentCustomer ? user.currentCustomer.name : user.rootCustomer.name }}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-12">
                                    <mat-form-field appearance="outline" style="width: 100%;">
                                        <mat-label style="transform: translateY(-30px);"> {{ 'Ticket Type' | translate }}</mat-label>
                                        <mat-select [(ngModel)]="selectedTicketType">
                                            <mat-option *ngFor="let type of ticketTypes" [value]="type">{{type | translate | titlecase}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-12 text-secondary mb-2 d-flex">
                                    <p class="mb-0">{{"Description" | translate}}</p>
                                    <p class="ml-auto mb-0">{{description ? description.length : 0}} / 1000</p>
                                </div>
                                <div class="col-lg-12">
                                    <textarea class="t-input w-100 p-2 support-description outline"  maxlength="1000" (keypress)="isSpace($event)" [(ngModel)]="description" name="" id="" cols="30" rows="10" (ngModelChange)="descErr = false;"></textarea>
                                    <p class="small text-danger mb-0" *ngIf="descErr">{{"Please Enter Description!" | translate}}</p>
                                </div>
                            </div>
                            <button class="btn t-bgcolor btn-block my-3 outline" (click)="ticketSubmmitConfirmation()"> {{'Submit' | translate}}</button>    
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="face-container-inner-bg">
                        <div class="face-container-inner-bg p-3">
                            <div class="row border-bottom">
                                <div class="col-lg-12">
                                    <h4 class="mb-3">{{"Ticket History" | translate}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="table-responsive">
                                    <table class="table t-table table-scroll" style="font-size: 15px;">
                                        <thead>
                                            <tr>
                                                <th>{{"Ticket Number" | translate}}</th>
                                                <th>{{"Raised Date" | translate}}</th>
                                                <th>{{"Ticket Type" | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="loader-flex-center" *ngIf="ticketSpinner">
                                            <div class="loader quantum-spinner m-auto"></div>
                                        </tbody>
                                        <tbody *ngIf="ticketList && ticketList.length <= 0 && !ticketSpinner">
                                            <tr>
                                                <td colspan="5" class="text-center">{{ "No Ticket Found" | translate}}.</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf=!ticketSpinner>
                                            <tr *ngFor="let ticket of ticketList">
                                                <td>{{ticket.ticketNo}}</td>
                                                <td>{{ticket.created | date:'dd-MM-yyyy'}}</td>
                                                <td>{{ticket.type | translate | titlecase}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>        
</div>

<div class="modal fade" id="ticketSubmitConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Ticket Confirmation' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body text-center p-4">
                <p>{{'Are you sure you want to submit the ticket' | translate}}?</p>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" (click)="submitTicket()"> {{'Submit' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>