import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataSets } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ThemeState } from 'src/app/shared/stores/theme/theme.reducer';
import { UserState } from 'src/app/shared/stores/user/user.reducer';

@Component({
  selector: 'app-download-forecast-graph',
  templateUrl: './download-forecast-graph.component.html',
  styleUrls: ['./download-forecast-graph.component.scss']
})
export class DownloadForecastGraphComponent implements OnInit {
  downloadLable = 'Downloads';
  forcastedLabel = 'Forecasted Download'
  lineChartData: ChartDataSets[] = [
    { data: [], label: this.downloadLable, fill: false },
    { data: [], label: this.forcastedLabel, fill: false }
  ];
  executed = false;
  lineChartLabels: Label[] = [];

  lineChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        gridLines: { color: '#5A5A5A', borderDash: [6] },
      }],
      yAxes: [{
        gridLines: { color: '#5A5A5A', borderDash: [6] },
        ticks: { beginAtZero: true, stepSize: 500 },
      }]
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: function(tooltipItem, data) {
          return moment().set('date', tooltipItem[0].label.slice(2, 4)).format('DD/MM/YYYY');
        },
        label: function(tooltipItem, data) {
          return tooltipItem.datasetIndex == 0 ? `No. Of ICCID Downloaded : ${tooltipItem.yLabel}` : `No. Of ICCID Forecasted : ${Math.round(tooltipItem.yLabel)}`;
        }
      }
    },
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#FF9016',
      backgroundColor: '#FF9016',
    },
    {
      borderColor: '#FC5000',
      backgroundColor: '#FC5000',
    }
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  show = false;
  manualRefresh = false;
  isDashboardUser = false;

  constructor(private dashboardService: DashboardService,
    private communicationService: CommunicationService,
    private translate: TranslateService,
    private themeStore: Store<ThemeState>,
    private userStore: Store<UserState>) {
      this.changeLanguage();
    }

  @ViewChild('baseChart') chart: BaseChartDirective;
  ngOnInit(): void {
    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
    });

    this.getForecastGraph();

    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          if(user.data.roleNames.includes('dashboard-user') && user.data.roleType === 'default') {
            this.isDashboardUser = true;
            this.setWidgetInvterval();
          }
        }
      });

    this.communicationService.getRefreshDashboardAction()
      .subscribe((res: any) => {
        this.getForecastGraph();
      });
  }

  getForecastGraph() {
    this.lineChartData = [
      { data: [], label: this.downloadLable, fill: false },
      { data: [], label: this.forcastedLabel, fill: false }
    ];
    
    this.lineChartLabels = [];
  
    this.dashboardService.getForecastGraph().subscribe((res: any) => {
      res.data.length > 0 && res.data.forEach((element) => {
        if(element) {
          this.lineChartData[0].data.push(element.count);
          this.lineChartLabels.push(`D ${element.day}`);
        }
      });
      res.downloadGraphData.length > 0 && res.downloadGraphData.forEach(element => {
        if(element) {
          this.lineChartData[1].data.push(element.count);
        }
        if(res.downloadGraphData.length !== this.lineChartLabels.length) {
          this.lineChartLabels.push(`D ${element.day}`);
        }
      });
      if(res.data.length > 0 && res.downloadGraphData.length) {
        let maxData = Math.max(...res.data.map(d => d.count));
        let maxGraphData = Math.round(Math.max(...res.downloadGraphData.map(d => d.count)));
        if(maxData > maxGraphData) {
          this.lineChartOptions.scales.yAxes[0].ticks['stepSize'] = Math.round(maxData / 5);
        } else {
          this.lineChartOptions.scales.yAxes[0].ticks['stepSize'] = Math.round(maxGraphData / 5);
        }
      } else if (res.data.length > 0 && res.downloadGraphData.length <= 0) {
        let maxData = Math.round(Math.max(...res.data.map(d => d.count)));
        this.lineChartOptions.scales.yAxes[0].ticks['stepSize'] = Math.round(maxData / 5);
      } else {
        let maxGraphData = Math.round(Math.max(...res.downloadGraphData.map(d => d.count)));
        this.lineChartOptions.scales.yAxes[0].ticks['stepSize'] = Math.round(maxGraphData / 5);
      }
      if(this.chart !== undefined){
        this.chart.ngOnDestroy();
        this.chart.chart = this.chart.getChartBuilder(this.chart.ctx); 
      }
    });
  }

  setWidgetInvterval() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        
        if (res.refreshTimeSetting.length > 0) {
          let index = res.refreshTimeSetting.findIndex(ele => ele.name === 'Forecast Graph');
          if(res.refreshTimeSetting[index].time != 'manual') { 
            setInterval(() => {
              this.getForecastGraph();
            }, Number(res.refreshTimeSetting[index].time));
            this.manualRefresh = false;
          } else {
            this.manualRefresh = true;
          }
        } else {
          setInterval(() => {
            this.getForecastGraph();
          }, 60000 * 1)
        }
      });
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
    this.translate.get('Downloads').subscribe((res: string) => { 
      this.show = false;
      this.downloadLable = res;
      this.lineChartData[0].label = this.downloadLable;
    });
    this.translate.get('Forecasted Download').subscribe((res: string) => { 
      this.show = false;
      this.forcastedLabel = res;
      this.lineChartData[1].label = this.forcastedLabel;
      this.refreshDropSettings();
    });
  }

  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  refresh() {
    this.getForecastGraph();
  }
}
