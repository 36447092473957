import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { CookieTrailService } from 'src/app/shared/services/cookie-trail.service';
import { GroupMgmtService } from 'src/app/shared/services/group-mgmt.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { UserState } from 'src/app/shared/stores/user/user.reducer';
import * as papa from 'papaparse';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { timezones } from '../report';
import * as UserAction from '../../shared/stores/user/user.action'
import { UserService } from 'src/app/shared/services/user.service';

declare var $:any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-profile-download',
  templateUrl: './profile-download.component.html',
  styleUrls: ['./profile-download.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class ProfileDownloadComponent implements OnInit, OnDestroy {

  dateRange = 'month';
  public selectedMoments = [
    moment(new Date()).subtract(14, 'day'),
    moment(new Date())
  ];
  profileDownloadSpinner = false;
  iccid;
  profileType = 'none';
  group = 'none';
  prpValue;
  customerId;
  profileDownloadReportList;
  infoIccid;
  profileDownloadReportInfoSpinner = false;
  profileDownloadReportInfoList;
  profileTypeList;
  groupList;
  user;
  selectProfileFlg = false;
  mailSpinner = false;
  features;

  downloadSpinner = false;
  lastPage = 0;
  totalPages;
  infiniteList;
  infiniteSpinner = false;
  
  profileDownloadLastPage = 0;
  downloadProfileList;
  infinitedownloadProfileList;
  profileDownloadTotalPage;

  throttle = 700;
  scrollDistance = 1;
  scrollUpDistance = 2;

  profileDownloadSubscribe: Subscription;
  downloadSubscribe: Subscription;

  timezones = timezones;
  selectedTimezone = 'Etc/UTC';
  timezoneSubscription: Subscription;
  language;
  constructor(
    private reportService: ReportService,
    private communicationService: CommunicationService,
    private userStore: Store<UserState>,
    private profileService: ProfileService,
    private cookieTrailService: CookieTrailService,
    private groupService: GroupMgmtService,
    private translate: TranslateService,
    private userService: UserService) { 
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
            // const browserLang = translate.getBrowserLang();
      this.language = localStorage.getItem('language');
      if (!this.language) {
        this.language = 'en';
        localStorage.setItem('language', this.language);
      }
      translate.use(this.language.match(environment.langRegex) ? this.language : 'en');
    }

  ngOnInit(): void {
    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.user = Object.assign({}, user);
          this.selectedTimezone = user.data.timezone;
          if(user.data.currentCustomer) {
            this.customerId = user.data.currentCustomer._id;
          } else {
            this.customerId = user.data.rootCustomer._id;
          }

          if(!this.user.data.currentCustomer && (this.user.data.rootCustomer && this.user.data.rootCustomer.isParent)) {
            this.selectProfileFlg = true;
            this.getProfileTypes();
          } else if(this.user.data.currentCustomer && this.user.data.currentCustomer.isParent){
            this.selectProfileFlg = true;
            this.getProfileTypes();
          } else {
            this.selectProfileFlg = false;
            this.getAllCustomerGroup();
          }

          if(user && user.featureRole && user.featureRole.length > 0) {
            this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Reports')].features;
          }

          this.getReportData();
        }
      });

      this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });
  }

  changeLanguage() {
    this.language = localStorage.getItem('language');
    this.translate.use(this.language);
  }
  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  getReportData() {
    let data;

    if(this.profileDownloadSubscribe) {
      this.profileDownloadSubscribe.unsubscribe();
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    if(this.selectProfileFlg) {
      data = {
        iccid: this.iccid,
        profileType: this.profileType === 'none' ? '' : this.profileType,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = 0,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    } else {
      data = {
        iccid: this.iccid,
        groupId: this.group === 'none' ? '' : this.group,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = 0,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    }

    this.profileDownloadReportList = [];
    this.profileDownloadSpinner = true;
    this.infiniteSpinner = false;

    this.profileDownloadSubscribe = this.reportService.getProfileDownloadReport(data)
      .subscribe((res: any) => {
        this.profileDownloadSpinner = false;
        this.infiniteList = res.result;
        this.profileDownloadReportList = res.result;
        this.totalPages = res.totalPages;

        if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
          this.onProfileDownloadScrollDown();
        }
        
      }, err => {
        this.profileDownloadSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  openIccidInfo(index) {
    $('#iccidInfoModal').modal();
    this.profileDownloadReportInfoList = [];
    
    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    
    this.profileDownloadReportInfoSpinner = true;
    this.infoIccid = this.profileDownloadReportList[index].iccid;
    const data = {
      dateRange: this.dateRange,
      iccid: this.infoIccid,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.reportService.getProfileDownloadInfoReport(data)
      .subscribe((res: any) => {
        this.profileDownloadReportInfoSpinner = false;
        this.profileDownloadReportInfoList = res;
      }, err => {
        this.profileDownloadReportInfoSpinner = false;
        $('#eidInfoModal').modal('hide');
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getProfileTypes() {
    this.profileService.getProfileTypeList()
      .subscribe((res: any) => {
        this.profileTypeList = res.profileTypes;
      }, err => {
        const cookieTrail = {
          screenName: 'Report',
          action: 'Get Profile Types',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getAllCustomerGroup() {
    this.groupService.getCustomerUserGroups()
      .subscribe((res: any) => {
        this.groupList = res;
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  sendReportData() {
    let data;

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    if(this.selectProfileFlg) {
      data = {
        iccid: this.iccid,
        profileType: this.profileType === 'none' ? '' : this.profileType,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    } else {
      data = {
        iccid: this.iccid,
        groupId: this.group === 'none' ? '' : this.group,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    }

    this.mailSpinner = true;

    this.reportService.sendProfileDownloadReportMail(data)
      .subscribe((res: any) => {
        this.mailSpinner = false;
        this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
      }, err => {
        this.mailSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  downloadProfileDownloadReport() {
    this.downloadSpinner = true;
    const tempList = [];
    let fileName;

    let data;

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    if(this.selectProfileFlg) {
      data = {
        iccid: this.iccid,
        profileType: this.profileType === 'none' ? '' : this.profileType,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.profileDownloadLastPage = 0,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    } else {
      data = {
        iccid: this.iccid,
        groupId: this.group === 'none' ? '' : this.group,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.profileDownloadLastPage = 0,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    }

    this.downloadSubscribe = this.reportService.getProfileDownloadReport(data)
        .subscribe((res: any) => {
          this.infinitedownloadProfileList = res.result;
          this.downloadProfileList = res.result;
          this.profileDownloadTotalPage = res.totalPages;

          if(this.infinitedownloadProfileList.length > 0 || this.profileDownloadLastPage < this.profileDownloadTotalPage) {
            this.getProfileDownloadRecords();
            return;
          } else {
            
            this.downloadProfileList.forEach(element => {
              const obj = {
                iccid: element.iccid,
                downloaded: element.download,
                reDownloaded: element.reDownload,
                profileType: element.profileType,
                'PRP Setting': element.prp,
                'PRP Count': element.prpCounter,
                'Current Status': element.status
              };
              tempList.push(obj);
            });
            fileName = `Profile Re-Download Report.txt`;
            const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
            saveAs(blob, fileName);
            this.downloadSpinner = false;
          }
        }, err => {
          this.downloadSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
  }

  getProfileDownloadRecords() {

    let data;

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    
    if(this.selectProfileFlg) {
      data = {
        iccid: this.iccid,
        profileType: this.profileType === 'none' ? '' : this.profileType,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.profileDownloadLastPage = this.profileDownloadLastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    } else {
      data = {
        iccid: this.iccid,
        groupId: this.group === 'none' ? '' : this.group,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.profileDownloadLastPage = this.profileDownloadLastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    }

    this.downloadSubscribe = this.reportService.getProfileDownloadReport(data)
      .subscribe((res: any) => {
        this.infinitedownloadProfileList = res.result;
        Array.prototype.push.apply(this.downloadProfileList, res.result);

        if(this.infinitedownloadProfileList.length > 0 || this.profileDownloadLastPage < this.profileDownloadTotalPage) {
          this.getProfileDownloadRecords();
        } else {
          const tempList = [];
          let fileName;
          this.downloadProfileList.forEach(element => {
            const obj = {
              iccid: element.iccid,
              downloaded: element.download,
              reDownloaded: element.reDownload,
              profileType: element.profileType,
              'PRP Setting': element.prp,
              'PRP Count': element.prpCounter,
              'Current Status': element.status
            };
            tempList.push(obj);
          });
          fileName = `Profile Re-Download Report.txt`;
          const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
          saveAs(blob, fileName);
          this.downloadSpinner = false;
        }
      }, err => {
        this.downloadSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  onProfileDownloadScrollDown() { 

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    
    if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
      let data;

    if(this.selectProfileFlg) {
      data = {
        iccid: this.iccid,
        profileType: this.profileType === 'none' ? '' : this.profileType,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = this.lastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    } else {
      data = {
        iccid: this.iccid,
        groupId: this.group === 'none' ? '' : this.group,
        prpValue: this.prpValue,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = this.lastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
    }
    this.infiniteSpinner = true;

    this.profileDownloadSubscribe = this.reportService.getProfileDownloadReport(data)
      .subscribe((res: any) => {
        this.infiniteList = res.result;
        Array.prototype.push.apply(this.profileDownloadReportList, res.result);
        this.infiniteSpinner = false;
        if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
          this.onProfileDownloadScrollDown();
        }
      }, err => {
        this.infiniteSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
    }
    
  }

  setTimezone() {
    if(this.timezoneSubscription) {
      this.timezoneSubscription.unsubscribe();
    }
    this.timezoneSubscription = this.userService.setTimezone({timezone: this.selectedTimezone})
      .subscribe((res: any) => {
        this.user.data = Object.assign({...this.user.data}, {timezone: this.selectedTimezone});
        this.userStore.dispatch(new UserAction.GetUser(this.user));
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  ngOnDestroy() {
    if(this.profileDownloadSubscribe) {
      this.profileDownloadSubscribe.unsubscribe();
    }

    if(this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    } 
  }
}
