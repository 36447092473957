import { Action } from '@ngrx/store';
import { Group } from './group.model';

export const GET_GROUP = 'GET_FILTER';

export class GetGroup implements Action {
    readonly type = GET_GROUP;

    constructor(public payload: Group) { }
}

export type GroupActions = GetGroup;