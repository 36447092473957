import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommunicationService } from './communication.service';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import jwtDecode from 'jwt-decode';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  err: false;
  refreshTokenInterval;
  defaultRoute = '/dashboard';
  features;

  constructor(
    private http: HttpClient,
    private router: Router,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    private userService: UserService,
    private translate: TranslateService) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    let httpOptions;
    if (authToken) {
      httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json',
        })
      };
    }

    return httpOptions;
  }

  signin(creds) {
    return this.http.post(`${environment.authUrl}/cssp/signin`, creds);
  }

  getMainFeatureIndex(name) {
    return this.features ? this.features.findIndex(ele => ele.name === name) : '';
  }

  forgot(creds, flg = true) {
    this.http.post(`${environment.authUrl}/auth/forgot`, creds)
      .subscribe((res: any) => {
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'An email has been sent to the user with further instructions.' });
        if (flg) {
          setTimeout(() => {
            this.router.navigate(['signin']);
          }, 2510);
        }
      }, err => {
        if (flg) {
          this.err = err.error.message;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
        } else {
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
        }
      });
  }

  reset(key, creds) {
    this.http.post(`${environment.authUrl}/auth/reset/${key}`, creds)
      .subscribe((res: any) => {
        this.communicationService.openAlert({
          alertType: 'success',
          alertMsg: 'Password updated successfully. Please signin to continue.'
        });
        setTimeout(() => {
          this.router.navigate(['/signin']);
        }, 2510);
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: this.commonService.passwordOptimize(err.error.message) });
        this.err = err.error.message;
      });
  }

  linkValidate(key) {
    return this.http.get(`${environment.authUrl}/auth/reset/${key}`)
      .pipe(map(response => response));
  }

  signout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('role');
    localStorage.removeItem('customerId');
    localStorage.removeItem('filteTerms');
    localStorage.removeItem('manageGroupQrfilteTerms');
    localStorage.removeItem('notificationMode');
    localStorage.removeItem('screenMode');
    localStorage.removeItem('env');
    localStorage.removeItem('isProd');
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('rootCustomer');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('language');
    localStorage.removeItem('cookieTrail');
    this.translate.use('en');
    clearInterval(this.refreshTokenInterval);
    
    this.userService.logout().subscribe(() => {
      window.location.href = `${environment.loginUrl}?customer_domain=${location.hostname}`; // forcefully redirect to new login page
    })
    // this.router.navigate(['/signin']);
  }

  getRefreshToken() {
    const now = Date.now();
    const token = localStorage.getItem('authToken');

    this.setRefreshTokenInterval();

    if (!token) {
      return;
    }
    const tokenData = jwtDecode(token);
    if (now / 1000 > parseInt(tokenData.exp, 10)) {
      this.signout();
    }
    if (10 * 60 > (parseInt(tokenData.exp, 10) - now / 1000) && (parseInt(tokenData.exp, 10) - now / 1000) > 0) {
      const refreshToken = localStorage.getItem('refreshToken');
      this.http.post(`${environment.authUrl}/refreshtoken`, { refreshToken }, this.getHeader())
        .subscribe((res: any) => {
          localStorage.setItem('refreshToken', res.refreshToken);
          localStorage.setItem('authToken', res.token);
        }, (err: any) => {
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }
  }

  setRefreshTokenInterval() {
    this.refreshTokenInterval = setInterval(() => {
      const now = Date.now();
      const token = localStorage.getItem('authToken');
      if (!token) {
        return;
      }
      const tokenData = jwtDecode(token);
      if (now / 1000 > parseInt(tokenData.exp, 10)) {
        this.signout();
      }
      if (10 * 60 > (parseInt(tokenData.exp, 10) - now / 1000) && (parseInt(tokenData.exp, 10) - now / 1000) > 0) {
        const refreshToken = localStorage.getItem('refreshToken');
        this.http.post(`${environment.authUrl}/refreshtoken`, { refreshToken }, this.getHeader())
          .subscribe((res: any) => {
            localStorage.setItem('refreshToken', res.refreshToken);
            localStorage.setItem('authToken', res.token);
          }, (err: any) => {
            this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          });
      }
    }, 3 * 60 * 1000);
  }

  getLoginScreenTheme(data) {
    return this.http.post(`${environment.serverUrl}/single/distribution`, data)
      .pipe(map(response => response));
  }
}
