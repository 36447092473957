import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { QRCodeWithLogo, MousePosition } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

@Component({
  selector: 'app-qrcode-with-logo',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeWithLogoComponent extends ShapeComponent implements OnInit {

  formFields = [
    {
        name: 'x',
        label: 'X:',
        type: 'input',
        inputType: 'text',
        value: ''
    },
];

constructor() {
    super();
    this.shape = new QRCodeWithLogo();
    this.shapeType = ShapeType.QRCodeWithLogo;
}

ngOnInit() {
    // console.log('QRCodeComponent ngOnInit');
}

startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof QRCodeWithLogo) {
        this.shape.originX = beginPosition.x;
        this.shape.originY = beginPosition.y;
    }
    // console.log('QRCodeComponent startDrawing at ', beginPosition, ', ', this.shape);
}

draw(currentPosition: MousePosition): void {
    // console.log('QRCodeComponent draw');
    if (this.shape instanceof QRCodeWithLogo) {
        this.shape.width = Math.abs(currentPosition.x - this.shape.originX);
        this.shape.height = Math.abs(currentPosition.y - this.shape.originY);
    }
}
}
