import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CommunicationService } from './communication.service';
import { UserState } from '../stores/user/user.reducer';
import * as UserAction from '../stores/user/user.action';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(
    private http: HttpClient,
    private communicationService: CommunicationService,
    private userStore: Store<UserState>) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getUser() {
    this.serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
    return this.http.post(`${this.serverUrl}/user/me`, {}, this.getHeader())
      .pipe(map(response => response));
    
  }

  updateFieldSection(data) {
    this.serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
    return this.http.post(`${this.serverUrl}/user/dynamicSearch`, data, this.getHeader())
      .pipe(map(response => response));
  }
  
  getFieldSection() {
    this.serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
    return this.http.get(`${this.serverUrl}/user/dynamicSearch`, this.getHeader())
      .pipe(map(response => response));
  }

  getTokenFromCookie() {
    this.serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
    // return this.http.post(`${this.serverUrl}/auth/getTokenFromCookie`, {})
    return this.http.post(`${environment.userServiceAPIUrl}/auth/getTokenFromCookie`, {}, {withCredentials: true})
      .pipe(map(response => response));
  }

  logout() {
    this.serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
    // return this.http.post(`${this.serverUrl}/auth/getTokenFromCookie`, {})
    return this.http.post(`${environment.userServiceAPIUrl}/auth/logout`, {}, {withCredentials: true})
      .pipe(map(response => response));
  }

  getUserPermissions() {
    this.serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
    return this.http.post(`${this.serverUrl}/user/permissions`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  addUser(userData) {
    return this.http.post(`${this.serverUrl}/user/signup`, userData, this.getHeader())
      .pipe(map(response => response));
  }

  // edit user
  editUser(userData, userId) {
    return this.http.put(`${this.serverUrl}/user/${userId}`, userData, this.getHeader())
      .pipe(map(response => response));
  }

  // delete user
  deleteUsers(userId) {
    return this.http.delete(`${this.serverUrl}/user/${userId}`, this.getHeader())
      .pipe(map(response => response));
  }

  deleteSelectedUser(data) {
    const authToken = localStorage.getItem('authToken');
    const header = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete(`${this.serverUrl}/user/delete/bulk`, header)
      .pipe(map(response => response));
  }

  getUsers(customerId) {
    return this.http.get(`${this.serverUrl}/user/getUserByCustomer/${customerId}`, this.getHeader())
      .pipe(map(response => response));
  }

  changePassword(data) {
    return this.http.put(`${environment.authUrl}/cssp/password`, data, this.getHeader())
      .pipe(map(response => response));
  }

  addBulkUsers(data) {
    const isProd = localStorage.getItem('isProd');
    return this.http.post(`${environment.authUrl}/customer/user?isProd=${isProd}`, data, this.getHeader())
      .pipe(map(response => response));
  }

  setLanguage(data) {
    return this.http.post(`${this.serverUrl}/user/language`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getAllRoles() {
    return this.http.get(`${this.serverUrl}/user/roles`, this.getHeader())
      .pipe(map(response => response));
  }

  editBulkUser(data) {
    return this.http.post(`${this.serverUrl}/user/edit-bulk-user`, data, this.getHeader())
      .pipe(map(response => response));
  }

  firstTimeLoginUser({}) {
    return this.http.post(`${this.serverUrl}/user/firstTimeLogin`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  setTimezone(timezone) {
    return this.http.post(`${this.serverUrl}/user/timezone`, timezone, this.getHeader())
      .pipe(map(response => response));
  }
}
