import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MustMatch } from 'src/app/shared/validators/must-match.validators';

@Component({
  selector: 'app-smartjac-reset-password',
  templateUrl: './smartjac-reset-password.component.html',
  styleUrls: ['./smartjac-reset-password.component.scss']
})
export class SmartjacResetPasswordComponent implements OnInit {
  resetPassForm: FormGroup;
  submitted = false;
  err = false;
  key;
  langSelected = 'en';
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      this.key = params.token;
      if (this.key) {
      }
    });

    if(localStorage.getItem('language')) {
      this.langSelected = localStorage.getItem('language')
    } else {
      this.langSelected = 'en';
    }

    this.resetPassForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() { return this.resetPassForm.controls; }

  checkLinkValidation(key) {
    this.authService.linkValidate(key)
      .subscribe((res: any) => {
      }, err => {
        this.router.navigate(['/forgot-password']);
      });
  }

  onResetPass() {
    this.submitted = true;
    if (this.resetPassForm.invalid) {
      return;
    }

    const passwordData = {
      newPassword: this.resetPassForm.get('password').value,
      verifyPassword: this.resetPassForm.get('confirmPassword').value,
    };
    this.authService.reset(this.key, passwordData);
  }

  changeLanguage() {
    localStorage.setItem('language', this.langSelected);
    this.translate.use(this.langSelected);
  }

}
