<ng-template #shapeTemplate>
    <!-- <svg:g *ngIf="isSelected">
        <svg:rect attr.x="{{ shape.originX - shape.rx - 2 }}" attr.y="{{ shape.originY - shape.ry - 2 }}" width="4"
            height="4" style="stroke: green; stroke-width: 1; fill:green" />
        <svg:rect attr.x="{{ shape.originX + shape.rx - 2 }}" attr.y="{{ shape.originY - shape.ry - 2 }}" width="4"
            height="4" style="stroke: green; stroke-width: 1; fill:green" />
        <svg:rect attr.x="{{ shape.originX - shape.rx - 2 }}" attr.y="{{ shape.originY + shape.ry - 2 }}" width="4"
            height="4" style="stroke: green; stroke-width: 1; fill:green" />
        <svg:rect attr.x="{{ shape.originX + shape.rx - 2 }}" attr.y="{{ shape.originY + shape.ry - 2 }}" width="4"
            height="4" style="stroke: green; stroke-width: 1; fill:green" />
        <svg:rect *ngIf="isSelected" attr.x="{{ shape.originX - shape.rx }}" attr.y="{{ shape.originY - shape.ry }}"
            attr.width="{{ shape.rx * 2 }}" attr.height="{{ shape.ry * 2 }}" style="stroke: green; stroke-width: 1; fill:none" />
    </svg:g> -->
    <svg:ellipse attr.id="{{shape.shapeProperties.name}}" class="draggable" attr.cx="{{ shape.originX }}" attr.cy="{{ shape.originY }}" attr.rx="{{ shape.rx }}" attr.ry="{{ shape.ry }}" [ngStyle]="setStyles()">
        <title>{{ shape.shapeProperties.name }}</title>
    </svg:ellipse>
</ng-template>