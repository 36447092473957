<div class="inner-main-profile user-dashboard">
    <div class="result-row">
        <div class="result-table">
            <div class="row mt-3">
                <div class="col-lg-9">
                    <div class="face-container-inner-bg">
                        <div class="border-bottom-light">
                            <div class="p-3 d-flex">
								<p class="mb-0 t-color">{{'Inventory' | translate}}</p>
								<div class="ml-auto t-color cursor-p" (click)="getInventoryData(); refreshSpinner = true"><i class="fa fa-refresh mr-2"></i>{{ 'Refresh' | translate}}</div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table t-table table-scroll mb-0">
                                <thead>
                                    <tr>
										<th class="col-md-4">{{'Profile Type Name' | translate}}</th>
                                        <th>{{'Type' | translate}}</th>
                                        <th>{{'Total Profile' | translate}}</th>
                                        <th>{{'Download' | translate}}</th>
                                        <th>{{'Available' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody class="loader-flex-center" *ngIf="userDataSpinner || refreshSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </tbody>
                                <tbody class="inventory-overflow" *ngIf="userDashboardData && userDashboardData.inventory && userDashboardData.inventory.length <= 0 && !userDataSpinner && !refreshSpinner">
                                    <tr>
                                        <td colspan="5" class="text-center">{{'No Inventory Found' | translate}}.</td>
                                    </tr>
                                </tbody>
                                <tbody class="inventory-overflow" *ngIf="userDashboardData && userDashboardData.inventory && userDashboardData.inventory.length > 0 && !userDataSpinner && !refreshSpinner">
									<tr *ngFor="let profileType of userDashboardData.inventory; let i = index">
										<td class="col-md-4">{{ profileType.profileType }}</td>
										<td>{{ profileType.type ? profileType.type : 'NA' }}</td>
										<td>{{profileType.total ? profileType.total : 0}}</td>
										<td>{{profileType.DOWNLOADED ? profileType.DOWNLOADED : 0}}</td>
                                        <td>{{profileType.AVAILABLE ? profileType.AVAILABLE : 0}}</td>
									</tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-7">
                            <div class="total-download-graph-container">
                                <div class="face-container-inner-bg p-3">
                                    <div class="total-login-user-container">
                                        <div class="d-flex filters-container">
                                            <p class="col-md-5">{{ 'Total Download' | translate }}</p>
                                            <div class="col-md-7 p-0">
                                                <span class="pr-2">{{'Show By' | translate}}</span>
                                                <select class="custom-selection-dropdown outline" name="filterBy" [(ngModel)]="filterBy" (ngModelChange)='setDefaults()'>
                                                    <option value="today">{{'Current Date' | translate}}</option>
                                                    <option value="7days">{{'Current Week' | translate}}</option>
                                                    <option value="1month">{{'Previous Month' | translate}}</option>
                                                    <!-- <option value="today">{{'Today' | translate}}</option>
                                                    <option value="yesterday">{{'Yesterday' | translate}}</option>
                                                    <option value="7days">{{'Last 7 days' | translate}}</option>
                                                    <option value="1month">{{'Previous month' | translate}}</option>
                                                    <option value="6months">{{'Last 6 months' | translate}}</option>
                                                    <option value="1Year">{{'Last 1 year' | translate}}</option> -->
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="lineChartData">
                                            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" [options]="lineChartOptions">
                                          </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="total-download-container face-container-inner-bg">
                                <div>
                                    <div class="p-3 d-flex">
                                        <p class="mb-0 t-color">{{'Total Download' | translate}}</p>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table t-table table-scroll">
                                        <thead>
                                            <tr>
                                                <th class="border-bottom-0 p-2">{{'Duration' | translate}}</th>
                                                <th class="border-bottom-0 p-2 pr-4 text-left pl-4">{{'Download' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="loader-flex-center" *ngIf="userDataSpinner">
                                            <div class="loader quantum-spinner m-auto"></div>
                                        </tbody>
                                        <tbody style="max-height: calc(100vh - 630px); overflow: unset;" *ngIf="!userDataSpinner">
                                            <tr>
                                                <td>Current Date</td>
                                                <td class="text-left pl-3">{{ downloadData && downloadData.currentDayDownload ? downloadData.currentDayDownload : 0 }}</td>
                                            </tr>
                                            <tr>
                                                <td>Current Week</td>
                                                <td class="text-left pl-3">{{ downloadData && downloadData.currentWeekDownload ? downloadData.currentWeekDownload : 0 }}</td>
                                            </tr>
                                            <tr>
                                                <td>Previous month</td>
                                                <td class="text-left pl-3">{{ downloadData && downloadData.previousMonthDownload ? downloadData.previousMonthDownload : 0}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="face-container-inner-bg mt-3" *ngIf="features && features[getFeatureIndex('db_device_analytics')].view">
                        <div class="border-bottom-light">
                            <div class="p-3 d-flex justify-content-between">
                                <h4 class="mr-4 mb-0">{{"Device Analytics" | translate}}</h4>
                                <div class="d-flex align-items-center">
                                    <label class="t-label pull-left mr-3 mb-0">{{'Selection' | translate}} </label>
                                    <select class="custom-selection-dropdown outline" [(ngModel)]="groupBy" (ngModelChange)="getDeviceAnalytics()">
                                        <option value="brand">Brand</option>
                                        <option value="group">Series</option>
                                        <option value="model">Model</option>
                                    </select>
                                </div>
                                <div class="d-flex align-items-center">
                                    <label class="t-label pull-left mr-3 mb-0">{{'Date Range' | translate}} </label>
                                    <select class="custom-selection-dropdown outline" [(ngModel)]="dateRange" (ngModelChange)="getDeviceAnalytics()">
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="week">Week-to-Date</option>
                                        <option value="month">Month-to-Date</option>
                                        <option value="year">Year-to-Date</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="tac-info-tables">
                            <div class="table-lg-1 pr-2">
                                <div class="dahboard-tac-table">
                                    <div class="t-grid-table-new">
                                        <div class="t-grid-table-wrapper t-head-bg dashboard-header">
                                            <div class="t-box a">{{(groupBy === 'brand' ? 'Brand' : groupBy === 'group' ? 'Series' : 'Model') | translate}}</div>
                                            <div class="t-box b">{{'%' | translate}}</div>
                                            <div class="t-box c">{{'Download' | translate}}</div>
                                            <div class="t-box a">{{'TAC' | translate}}</div>
                                        </div>
                                        <div class="loader-flex-center" *ngIf="deviceAnalyticsListSpinner">
                                            <div class="loader quantum-spinner m-auto"></div>
                                        </div>
                                        <div class="t-body-overflow" *ngIf="deviceAnalyticsList && deviceAnalyticsList.length <= 0 && !deviceAnalyticsListSpinner">
                                            <div class="p-1 t-body-bg cursor-p text-center">
                                                {{'No Data Found' | translate}}.
                                            </div>
                                        </div>
                                        <div class="t-body-overflow">
                                            <div class="t-grid-table-wrapper t-body-bg border-bt-ext-light" *ngFor="let item of deviceAnalyticsList; let i = index;">
                                                <div class="t-box d t-font-light" title="{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model}}">{{(groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model) | limitTo: limitLength}}</div>
                                                <div class="t-box d t-font-light">{{item.percentDownload  | number: '1.0-0' }}%</div>
                                                <div class="t-box d t-font-light" style="text-align: center;">{{item.totalDownload}}</div>
                                                <div class="t-box d t-font-light d-flex align-items-center" style="text-align: center;">
                                                    <a class="t-font-color c-info py-0 c-color" (click)="openTacInfo(i);">
                                                        <i class="fa fa-info" style="width: 15px;height: 15px;font-size: 10px;"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-lg-2 border-rt-ext-light border-lf-ext-light px-2">
                                <div class="dahboard-tac-table">
                                    <div class="t-grid-table-new">
                                        <div class="t-grid-table-wrapper-profile-download t-head-bg dashboard-header">
                                            <div class="t-box a">{{(groupBy === 'brand' ? 'Brand' : groupBy === 'group' ? 'Series' : 'Model') | translate}}</div>
                                            <div class="t-box b">{{'Profile' | translate}} <br/> {{'Re-Download' | translate}}</div>
                                        </div>
                                        <div class="loader-flex-center" *ngIf="deviceAnalyticsListSpinner">
                                            <div class="loader quantum-spinner m-auto"></div>
                                        </div>
                                        <div class="t-body-overflow" *ngIf="deviceAnalyticsProfileReDownload && deviceAnalyticsProfileReDownload.length <= 0 && !deviceAnalyticsListSpinner">
                                            <div class="p-1 t-body-bg cursor-p text-center">
                                                {{'No Data Found' | translate}}.
                                            </div>
                                        </div>
                                        <div class="t-body-overflow">
                                            <div class="t-grid-table-wrapper-profile-download t-body-bg border-bt-ext-light" *ngFor="let item of deviceAnalyticsProfileReDownload; let i = index;">
                                                <div class="t-box d t-font-light" title="{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model}}">{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model | limitTo: limitProfileEid}}</div>
                                                <div class="t-box d t-font-light" style="text-align: center;">{{item.iccidRedownload}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-lg-3 pl-2">
                                <div class="dahboard-tac-table">
                                    <div class="t-grid-table-new">
                                        <div class="t-grid-table-wrapper-profile-download t-head-bg dashboard-header">
                                            <div class="t-box a">{{(groupBy === 'brand' ? 'Brand' : groupBy === 'group' ? 'Series' : 'Model') | translate}}</div>
                                            <div class="t-box b">{{'EID' | translate}} <br/> {{'Re-Download' | translate}}</div>
                                        </div>
                                        <div class="loader-flex-center" *ngIf="deviceAnalyticsListSpinner">
                                            <div class="loader quantum-spinner m-auto"></div>
                                        </div>
                                        <div class="t-body-overflow" *ngIf="deviceAnalyticsEidReDownload && deviceAnalyticsEidReDownload.length <= 0 && !deviceAnalyticsListSpinner">
                                            <div class="p-1 t-body-bg cursor-p text-center">
                                                {{'No Data Found' | translate}}.
                                            </div>
                                        </div>
                                        <div class="t-body-overflow">
                                            <div class="t-grid-table-wrapper-profile-download t-body-bg border-bt-ext-light" *ngFor="let item of deviceAnalyticsEidReDownload; let i = index;">
                                                <div class="t-box d t-font-light" title="{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model}}">{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model | limitTo: limitProfileEid}}</div>
                                                <div class="t-box d t-font-light" style="text-align: center;">{{item.eidRedownload}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				
                <div class="col-lg-3">
                    <div *ngIf="userDashboardData" class="face-container-inner-bg p-3 mb-2 d-flex justify-content-center align-items-center dcard user-dashboard-card">
                        <div>
                            <img src="assets/images/dashboard/lu.png" class="dashboard-cards" alt="">
                        </div>
                        <div class="text-right ml-auto">
                            <h4 class="dtitle">{{ userDashboardData.loginUser ? userDashboardData.loginUser : 0 }}</h4>
                            <p class="t-color mb-0">{{ 'Total Login Users' | translate }}</p>
                        </div>    
                    </div>
                    <div *ngIf="userDashboardData" class="face-container-inner-bg p-3 mb-2 d-flex justify-content-center align-items-center dcard user-dashboard-card">
                        <div>
                            <img src="assets/images/dashboard/tu.png" class="dashboard-cards" alt="">
                        </div>
                        <div class="text-right ml-auto">
                            <h4 class="dtitle">{{ userDashboardData.totalUser ? userDashboardData.totalUser : 0 }}</h4>
                            <p class="t-color mb-0">{{'Total Users' | translate}}</p>
                        </div>    
                    </div>
                    <div *ngIf="userDashboardData" class="face-container-inner-bg p-3 mb-2 d-flex justify-content-center align-items-center dcard user-dashboard-card">
                        <div>
                            <img src="assets/images/dashboard/user.png" class="dashboard-cards" alt="">
                        </div>
                        <div class="text-right ml-auto">
                            <h4 class="dtitle">{{ (groupQrData && groupQrData.activeQr ) ? groupQrData.activeQr : 0 }}</h4>
                            <p class="t-color mb-0">{{'No.Of Active Group QR Profiles' | translate }}</p>
                        </div>    
                    </div>
                    <div class="face-container-inner-bg">
                        <div class="border-bottom-light">
                            <div class="p-3">
                                <h4 class="mb-0">{{'Action Alerts' | translate}}</h4>
                            </div>
                        </div>
                        <div class="loader-flex-center" *ngIf="userDataSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div *ngIf="userDashboardData && userDashboardData.notification && userDashboardData.notification.length <= 0 && !userDataSpinner">
                            <div class="main-action-alerts">
                                <p class="text-center pb-3 pt-3">{{'There are no notifications' | translate}}.</p>
                            </div>
                        </div>
                        <div class="action-alert-overflow" *ngIf="userDashboardData && userDashboardData.notification && userDashboardData.notification.length > 0 && !userDataSpinner">
                            <div class="main-action-alerts" *ngFor="let notification of userDashboardData.notification; let i = index">
                                <div class="action-alerts border-bottom-light pt-3 pb-3">
                                    <div class="action-alert-border"></div>
                                    <div class="w-100">
                                        <div class="text-right" style="opacity: .8;">
                                            <p class="mb-0">{{notification.created | date: "MMM d, y, hh:mm:ss a"}}</p>
                                        </div>
                                        <p class="mb-0 ml-3">{{notification.transMessage | translate | limitTo: 100}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        </div>
    </div>
</div>

<!-- tac info start -->

<div class="modal fade" id="tacInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "TAC Information" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="dahboard-tac-table">
                    <div class="t-grid-table-new">
                        <div class="t-grid-table-wrapper-tac-info t-head-bg">
                            <div class="t-box a">{{'TAC Number' | translate}}</div>
                            <div class="t-box b">{{'Device Name' | translate}}</div>
                            <div class="t-box c">{{'Download' | translate}}</div>
                        </div>
                        <div class="loader-flex-center" *ngIf="deviceInfoListSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="t-body-overflow" *ngIf="deviceInfoList && deviceInfoList.length <= 0 && !deviceInfoListSpinner">
                            <div class="p-1 t-body-bg cursor-p text-center">
                                {{'No Data Found' | translate}}.
                            </div>
                        </div>
                        <div class="tac-info-body-overflow">
                            <div class="t-grid-table-wrapper-tac-info t-body-bg border-bt-ext-light" *ngFor="let item of deviceInfoList; let i = index;">
                                <div class="t-box d t-font-light">{{item.tac}}</div>
                                <div class="t-box d t-font-light">{{item.name}}</div>
                                <div class="t-box d t-font-light" style="text-align: center;">{{item.totalDownload}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- tac info end -->