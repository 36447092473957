<div class="api-tree inner-main-profile">
    <div class="api-tree-head-section face-container-inner-bg p-2 mb-3" *ngIf="!apiUser">
        <div class="get-prof-status">
            <div class="get-prof-status-left" [ngClass]="{'flex-grow-1': apiUser}">
                <p class="mb-0 mr-3" *ngIf="apiName" title="{{apiName}}">{{apiName | limitTo: 17}}</p>
            </div>
            <div class="get-post-entry" *ngIf="!apiUser">
                <div class="api-method py-2">
                    Post
                </div>
                <input class="t-input" type="text" placeholder="https://es2plus-preprod.10ttech.com/gsma/rsp2/es2plus/getProfileStatus" [(ngModel)]="url">
                <button class="btn get-post-entry-btn t-bgcolor outline" (click)="sendApiCall()" *ngIf="!responseSpinner">Execute</button>
                <div class="btn get-post-entry-btn api-send-btn btn-spinner" *ngIf="responseSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div *ngIf="features && features[getFeatureIndex('api_user')].edit && features[getFeatureIndex('api_developer')].edit">
                    <a class="nav-link py-0 search-input-box pr-0 toggle-button">
                        <div class="switch cursor-p">
                            <span class="switcher switcher-1 cursor-p">
                                <input class="cursor-p" type="checkbox" id="switcher-1" [(ngModel)]="apiUser">
                                <label class="cursor-p" for="switcher-1"></label>
                             </span>
                        </div>
                    </a>
                </div>
                <button class="btn t-bgcolor ml-2 api-tree-toggle-btn mb-1 outline" *ngIf="features && !features[getFeatureIndex('api_developer')].edit && features[getFeatureIndex('api_user')].edit">User</button>
                <button class="btn t-bgcolor ml-2 api-tree-toggle-btn mb-1 outline" *ngIf="features && !features[getFeatureIndex('api_user')].edit && features[getFeatureIndex('api_developer')].edit">Developer</button>
            </div>
        </div>
    </div>

    <div class="api-tree-body" *ngIf="!apiUser && features && features[getFeatureIndex('api_developer')].edit">
        <div class="row">
            <div class="col-lg-6 col-lg-6-modified-left">
                <div class="face-container-inner-bg api-tree-body-json">
                    <div class="api-tree-body-json-head border-bottom-light p-3 d-flex align-items-center justify-content-between">
                        <p class="mb-0"> <span class="t-color">Request -</span> Body</p>
                        <div class="d-flex align-items-center">
                            <svg class="svg-t-color-primary svg-t-size cursor-p" *ngIf="requestObject" (click)="copyToClipboard('requestArea')" xmlns="http://www.w3.org/2000/svg" width="24.388" height="29.445" viewBox="0 0 24.388 29.445"><defs></defs><g transform="translate(-43.968)"><g transform="translate(53.229 6.212)"><path class="a" d="M214.3,108.016h-8.161a1.15,1.15,0,1,0,0,2.3H214.3a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -108.016)"/></g><g transform="translate(53.229 10.814)"><path class="a" d="M214.3,188.027h-8.161a1.15,1.15,0,0,0,0,2.3H214.3a1.15,1.15,0,0,0,0-2.3Z" transform="translate(-204.992 -188.027)"/></g><g transform="translate(53.229 15.415)"><path class="a" d="M209.651,268.039h-3.509a1.15,1.15,0,1,0,0,2.3h3.509a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -268.039)"/></g><g transform="translate(43.968)"><path class="a" d="M67.206,15.645a1.15,1.15,0,0,0,1.15-1.15V4.6a4.607,4.607,0,0,0-4.6-4.6H53.171a4.607,4.607,0,0,0-4.6,4.6,4.607,4.607,0,0,0-4.6,4.6V24.844a4.607,4.607,0,0,0,4.6,4.6H59.728a4.607,4.607,0,0,0,4.6-4.6v-.035a4.609,4.609,0,0,0,4.026-4.566,1.15,1.15,0,1,0-2.3,0,2.3,2.3,0,0,1-2.3,2.3l-10.583,0h0a2.3,2.3,0,0,1-2.3-2.3V4.6a2.3,2.3,0,0,1,2.3-2.3H63.755a2.3,2.3,0,0,1,2.3,2.3v9.893A1.15,1.15,0,0,0,67.206,15.645Zm-14.035,9.2h0l8.857,0a2.3,2.3,0,0,1-2.3,2.3H48.57a2.3,2.3,0,0,1-2.3-2.3V9.2a2.3,2.3,0,0,1,2.3-2.3V20.246a4.6,4.6,0,0,0,4.6,4.6Z" transform="translate(-43.968)"/></g></g></svg>
                            <p class="mb-0 t-color ml-3">JSON</p>
                        </div>
                    </div>
                    <div class="api-tree-body-json-body p-3">

                        <div class="loader-flex-center" *ngIf="apiResponseSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="api-tree-body-json-body-overflow" style="overflow: hidden;" *ngIf="!apiResponseSpinner">
                            <textarea class="t-input" style="height: 25vh;" cols="80" rows="10" id="requestArea" *ngIf="requestObject" [ngModel]="requestObject | json"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-lg-6-modified-right">
                <div class="face-container-inner-bg api-tree-body-json">
                    <div class="api-tree-body-json-head border-bottom-light p-3 d-flex align-items-center justify-content-between">
                        <p class="mb-0"> <span class="t-color">Request -</span> Body</p>
                        <div class="d-flex align-items-center">
                            <svg class="svg-t-color-primary svg-t-size cursor-p" *ngIf="requestSchemaObject" (click)="copyToClipboard('reqSchema')" xmlns="http://www.w3.org/2000/svg" width="24.388" height="29.445" viewBox="0 0 24.388 29.445"><defs></defs><g transform="translate(-43.968)"><g transform="translate(53.229 6.212)"><path class="a" d="M214.3,108.016h-8.161a1.15,1.15,0,1,0,0,2.3H214.3a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -108.016)"/></g><g transform="translate(53.229 10.814)"><path class="a" d="M214.3,188.027h-8.161a1.15,1.15,0,0,0,0,2.3H214.3a1.15,1.15,0,0,0,0-2.3Z" transform="translate(-204.992 -188.027)"/></g><g transform="translate(53.229 15.415)"><path class="a" d="M209.651,268.039h-3.509a1.15,1.15,0,1,0,0,2.3h3.509a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -268.039)"/></g><g transform="translate(43.968)"><path class="a" d="M67.206,15.645a1.15,1.15,0,0,0,1.15-1.15V4.6a4.607,4.607,0,0,0-4.6-4.6H53.171a4.607,4.607,0,0,0-4.6,4.6,4.607,4.607,0,0,0-4.6,4.6V24.844a4.607,4.607,0,0,0,4.6,4.6H59.728a4.607,4.607,0,0,0,4.6-4.6v-.035a4.609,4.609,0,0,0,4.026-4.566,1.15,1.15,0,1,0-2.3,0,2.3,2.3,0,0,1-2.3,2.3l-10.583,0h0a2.3,2.3,0,0,1-2.3-2.3V4.6a2.3,2.3,0,0,1,2.3-2.3H63.755a2.3,2.3,0,0,1,2.3,2.3v9.893A1.15,1.15,0,0,0,67.206,15.645Zm-14.035,9.2h0l8.857,0a2.3,2.3,0,0,1-2.3,2.3H48.57a2.3,2.3,0,0,1-2.3-2.3V9.2a2.3,2.3,0,0,1,2.3-2.3V20.246a4.6,4.6,0,0,0,4.6,4.6Z" transform="translate(-43.968)"/></g></g></svg>
                            <p class="mb-0 t-color ml-3">JSON Schema</p>
                        </div>
                    </div>
                    <div class="api-tree-body-json-body p-3">
                        <div class="loader-flex-center" *ngIf="apiResponseSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="api-tree-body-json-body-overflow" *ngIf="!apiResponseSpinner">
                            <pre *ngIf="requestSchemaObject" id="reqSchema" style="max-width: 550px;">{{requestSchemaObject | json}}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-lg-6-modified-left">
                <div class="face-container-inner-bg">
                    <div class="api-tree-body-json-head border-bottom-light p-3 d-flex align-items-center justify-content-between">
                        <p class="mb-0"> <span class="t-color">Response -</span> Body</p>
                        <div class="d-flex align-items-center">
                            <svg class="svg-t-color-primary svg-t-size cursor-p" *ngIf="responseObject" (click)="copyToClipboard('resBody')" xmlns="http://www.w3.org/2000/svg" width="24.388" height="29.445" viewBox="0 0 24.388 29.445"><defs></defs><g transform="translate(-43.968)"><g transform="translate(53.229 6.212)"><path class="a" d="M214.3,108.016h-8.161a1.15,1.15,0,1,0,0,2.3H214.3a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -108.016)"/></g><g transform="translate(53.229 10.814)"><path class="a" d="M214.3,188.027h-8.161a1.15,1.15,0,0,0,0,2.3H214.3a1.15,1.15,0,0,0,0-2.3Z" transform="translate(-204.992 -188.027)"/></g><g transform="translate(53.229 15.415)"><path class="a" d="M209.651,268.039h-3.509a1.15,1.15,0,1,0,0,2.3h3.509a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -268.039)"/></g><g transform="translate(43.968)"><path class="a" d="M67.206,15.645a1.15,1.15,0,0,0,1.15-1.15V4.6a4.607,4.607,0,0,0-4.6-4.6H53.171a4.607,4.607,0,0,0-4.6,4.6,4.607,4.607,0,0,0-4.6,4.6V24.844a4.607,4.607,0,0,0,4.6,4.6H59.728a4.607,4.607,0,0,0,4.6-4.6v-.035a4.609,4.609,0,0,0,4.026-4.566,1.15,1.15,0,1,0-2.3,0,2.3,2.3,0,0,1-2.3,2.3l-10.583,0h0a2.3,2.3,0,0,1-2.3-2.3V4.6a2.3,2.3,0,0,1,2.3-2.3H63.755a2.3,2.3,0,0,1,2.3,2.3v9.893A1.15,1.15,0,0,0,67.206,15.645Zm-14.035,9.2h0l8.857,0a2.3,2.3,0,0,1-2.3,2.3H48.57a2.3,2.3,0,0,1-2.3-2.3V9.2a2.3,2.3,0,0,1,2.3-2.3V20.246a4.6,4.6,0,0,0,4.6,4.6Z" transform="translate(-43.968)"/></g></g></svg>
                            <p class="mb-0 t-color ml-3">JSON</p>
                        </div>
                    </div>
                    <div class="api-tree-body-json-body p-3">
                        <div class="loader-flex-center" *ngIf="apiResponseSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="loader-flex-center" *ngIf="responseSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="api-tree-body-json-body-overflow" *ngIf="!(apiResponseSpinner || responseSpinner)">
                            <pre *ngIf="responseObject" id="resBody" style="max-width: 50vw">{{responseObject | json}}</pre>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-lg-6-modified-right">
                <div class="face-container-inner-bg">
                    <div class="api-tree-body-json-head border-bottom-light p-3 d-flex align-items-center justify-content-between">
                        <p class="mb-0"> <span class="t-color">Response -</span> Body</p>
                        <div class="d-flex align-items-center">
                            <svg class="svg-t-color-primary svg-t-size cursor-p" *ngIf="responseSchemaObject" (click)="copyToClipboard('resSchema')" xmlns="http://www.w3.org/2000/svg" width="24.388" height="29.445" viewBox="0 0 24.388 29.445"><defs></defs><g transform="translate(-43.968)"><g transform="translate(53.229 6.212)"><path class="a" d="M214.3,108.016h-8.161a1.15,1.15,0,1,0,0,2.3H214.3a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -108.016)"/></g><g transform="translate(53.229 10.814)"><path class="a" d="M214.3,188.027h-8.161a1.15,1.15,0,0,0,0,2.3H214.3a1.15,1.15,0,0,0,0-2.3Z" transform="translate(-204.992 -188.027)"/></g><g transform="translate(53.229 15.415)"><path class="a" d="M209.651,268.039h-3.509a1.15,1.15,0,1,0,0,2.3h3.509a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -268.039)"/></g><g transform="translate(43.968)"><path class="a" d="M67.206,15.645a1.15,1.15,0,0,0,1.15-1.15V4.6a4.607,4.607,0,0,0-4.6-4.6H53.171a4.607,4.607,0,0,0-4.6,4.6,4.607,4.607,0,0,0-4.6,4.6V24.844a4.607,4.607,0,0,0,4.6,4.6H59.728a4.607,4.607,0,0,0,4.6-4.6v-.035a4.609,4.609,0,0,0,4.026-4.566,1.15,1.15,0,1,0-2.3,0,2.3,2.3,0,0,1-2.3,2.3l-10.583,0h0a2.3,2.3,0,0,1-2.3-2.3V4.6a2.3,2.3,0,0,1,2.3-2.3H63.755a2.3,2.3,0,0,1,2.3,2.3v9.893A1.15,1.15,0,0,0,67.206,15.645Zm-14.035,9.2h0l8.857,0a2.3,2.3,0,0,1-2.3,2.3H48.57a2.3,2.3,0,0,1-2.3-2.3V9.2a2.3,2.3,0,0,1,2.3-2.3V20.246a4.6,4.6,0,0,0,4.6,4.6Z" transform="translate(-43.968)"/></g></g></svg>
                            <p class="mb-0 t-color ml-3">JSON Schema</p>
                        </div>
                    </div>
                    <div class="api-tree-body-json-body p-3">
                        <div class="loader-flex-center" *ngIf="apiResponseSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="api-tree-body-json-body-overflow" *ngIf="!apiResponseSpinner">
                            <pre *ngIf="responseSchemaObject" id="resSchema" style="max-width: 550px;">{{responseSchemaObject | json}}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="apiUser && features && features[getFeatureIndex('api_user')].edit">
        <div class="col-lg-6 col-lg-6-modified-left">
            <div class="api-tree-head-section face-container-inner-bg p-2 mb-3">
                <div class="get-prof-status">
                    <div class="get-prof-status-left" [ngClass]="{'flex-grow-1': apiUser}">
                        <p class="mb-0 mr-3" *ngIf="apiName" title="{{apiName}}">{{apiName | limitTo: 27}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <div *ngIf="features && features[getFeatureIndex('api_user')].edit && features[getFeatureIndex('api_developer')].edit">
                            <a class="nav-link py-0 search-input-box pr-0 toggle-button">
                                <div class="switch cursor-p">
                                    <span class="switcher switcher-1 cursor-p">
                                        <input class="cursor-p" type="checkbox" id="switcher-1" [(ngModel)]="apiUser">
                                        <label class="cursor-p" for="switcher-1"></label>
                                     </span>
                                </div>
                            </a>
                        </div>
                        <button class="btn t-bgcolor ml-2 api-tree-toggle-btn mb-1 outline" *ngIf="features && !features[getFeatureIndex('api_developer')].edit && features[getFeatureIndex('api_user')].edit">User</button>
                        <button class="btn t-bgcolor ml-2 api-tree-toggle-btn mb-1 outline" *ngIf="features && !features[getFeatureIndex('api_user')].edit && features[getFeatureIndex('api_developer')].edit">Developer</button>
                    </div>
                </div>
                <div *ngIf="apiUser" class="api-user-up-scrn">
                    <div class="col-lg-12">
                        <div class="row api-user-up-scrn-up-row py-2">
                            <div class="col-lg-5 pl-5">
                                <p class="mb-0">Key</p>
                            </div>
                            <div class="col-lg-4">
                                <p class="mb-0">Value</p>
                            </div>
                            <div class="col-lg-3 text-right">
                                <button class="btn t-bgcolor py-0 outline" *ngIf="apiUser && !userResponseSpinner" (click)="executeApi()">Execute</button>
                                <div class="btn btn-spinner p-0" *ngIf="apiUser && userResponseSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="api-user-up-scrn-overflow">
                        <div class="row py-2 border-bottom-light" *ngFor="let field of apiFileds">
                            <div class="col-lg-5 pl-3 flex-column justify-content-center d-flex t-font-medium">
                                <div class="d-flex">
                                    <div class="filter-box-checkbox-container">
                                        <mat-checkbox [(ngModel)]="field.isChecked" *ngIf="!field.isRequired"></mat-checkbox>
                                    </div>
                                    <p class="mb-0">{{field.name}} <i class="fa fa-star t-color" *ngIf="field.isRequired" style="font-size: 8px;transform: translateY(-7px);"></i></p>
                                </div>
                                <p class="small mt-2 mb-0 text-danger" *ngIf="field.isError && field.isRequired">{{field.name}} is required!</p>
                                <!-- <p class="small text-danger text-center" *ngIf="uploadCsvErr">
                                    Please upload csv file.
                                </p> -->
                            </div>
                            <div class="col-lg-7" *ngIf="field.name !== 'iccidList'">
                                <input type="text" class="t-input bg-light-grey border-0 form-control t-font-medium" [(ngModel)]="field.value" (ngModelChange)="!field.value.trim() ? field.isError = true : field.isError = false;">
                            </div>
                            <div class="col-lg-7" *ngIf="field.name === 'iccidList'">
                                <button class="btn t-bgcolor py-0 mr-5 mb-2 t-font-medium outline" (click)="uploadCSV()">
                                    <i class="fa fa-upload mr-2 outline" aria-hidden="true">
                                        <input id="apiIccidCsv" type="file" style="display: none" accept=".csv" (change)="onFileChange($event)">
                                    </i> Upload ICCID CSV
                                </button>
                                <a href="assets/csv/sample-iccid.csv" class="btn t-bgcolor py-0 t-font-medium outline"><i class="fa fa-download mr-2"></i> Sample ICCID CSV</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-lg-6-modified-right">
            <div class="api-tree-body" *ngIf="apiUser">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="face-container-inner-bg api-tree-body-json">
                            <div class="api-tree-body-json-head border-bottom-light p-3 d-flex align-items-center justify-content-between">
                                <p class="mb-0"> <span class="t-color">Response -</span> Body</p>
                                <div class="d-flex align-items-center">
                                    <svg class="svg-t-color-primary cursor-p svg-t-size" *ngIf="userResponseBody" (click)="copyToClipboard('userResponse')" xmlns="http://www.w3.org/2000/svg" width="24.388" height="29.445" viewBox="0 0 24.388 29.445"><defs></defs><g transform="translate(-43.968)"><g transform="translate(53.229 6.212)"><path class="a" d="M214.3,108.016h-8.161a1.15,1.15,0,1,0,0,2.3H214.3a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -108.016)"/></g><g transform="translate(53.229 10.814)"><path class="a" d="M214.3,188.027h-8.161a1.15,1.15,0,0,0,0,2.3H214.3a1.15,1.15,0,0,0,0-2.3Z" transform="translate(-204.992 -188.027)"/></g><g transform="translate(53.229 15.415)"><path class="a" d="M209.651,268.039h-3.509a1.15,1.15,0,1,0,0,2.3h3.509a1.15,1.15,0,1,0,0-2.3Z" transform="translate(-204.992 -268.039)"/></g><g transform="translate(43.968)"><path class="a" d="M67.206,15.645a1.15,1.15,0,0,0,1.15-1.15V4.6a4.607,4.607,0,0,0-4.6-4.6H53.171a4.607,4.607,0,0,0-4.6,4.6,4.607,4.607,0,0,0-4.6,4.6V24.844a4.607,4.607,0,0,0,4.6,4.6H59.728a4.607,4.607,0,0,0,4.6-4.6v-.035a4.609,4.609,0,0,0,4.026-4.566,1.15,1.15,0,1,0-2.3,0,2.3,2.3,0,0,1-2.3,2.3l-10.583,0h0a2.3,2.3,0,0,1-2.3-2.3V4.6a2.3,2.3,0,0,1,2.3-2.3H63.755a2.3,2.3,0,0,1,2.3,2.3v9.893A1.15,1.15,0,0,0,67.206,15.645Zm-14.035,9.2h0l8.857,0a2.3,2.3,0,0,1-2.3,2.3H48.57a2.3,2.3,0,0,1-2.3-2.3V9.2a2.3,2.3,0,0,1,2.3-2.3V20.246a4.6,4.6,0,0,0,4.6,4.6Z" transform="translate(-43.968)"/></g></g></svg>
                                    <p class="mb-0 t-color ml-3">JSON</p>
                                </div>
                            </div>
                            <div class="api-tree-body-json-body p-3">
                                <div class="api-tree-body-json-body-overflow-user" *ngIf="!userResponseSpinner">
                                    <pre id="userResponse">{{userResponseBody | json}}</pre>
                                </div>
                                <div class="loader-flex-center" *ngIf="userResponseSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>