import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ResizeEvent } from 'angular-resizable-element';
import { ELEMENT_DATA } from './profile';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;
import { UserState } from '../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import { ProfileTroubleshootingService } from '../shared/services/profile-troubleshooting.service';
import { CommunicationService } from '../shared/services/communication.service';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { environment } from '../../environments/environment'; 
@Component({
  selector: 'app-profile-troubleshooting',
  templateUrl: './profile-troubleshooting.component.html',
  styleUrls: ['./profile-troubleshooting.component.scss']
})
export class ProfileTroubleshootingComponent implements OnInit, AfterViewInit {

  socket;
  flg = true;
  test = 'test';
  public style: object = {};
  @ViewChild(MatTable, { read: ElementRef, static: true }) private matTableRef: ElementRef;

  columns: any[] = [
    { field: 'notificationType', width: 125 },
    { field: 'iccid', width: 110 },
    { field: 'eid', width: 170 },
    { field: 'completionTimestamp', width: 40 },
    { field: 'notificationEvent', width: 80 },
    { field: 'imei', width: 90 },
    { field: 'operationStatus', width: 90 },
    { field: 'created_date', width: 130 },
    { field: 'mnoid', width: 50 },
    { field: 'profileType', width: 140 }
  ];

  displayedColumns: string[] = ['notificationType'];

  pressed = false;
  currentResizeIndex: number;
  startX: number;
  startWidth: number;
  isResizingRight: boolean;
  resizableMousemove: () => void;
  resizableMouseup: () => void;

  notificationCheck = false;
  downloadCheck = false;
  handleNotificationArray = [];
  handleDownloadArray = [];
  allData = ELEMENT_DATA;
  dataSource = new MatTableDataSource(this.allData);
  interval;
  user;
  notificationMode;
  notificationArr = [];
  searchTxt;
  iccidData: any = [];
  features;
  mainFeature;
  bulkJobList = [];
  bulkJobSpinner = false;
  infiniteBulkJobSpinner = false;
  lastDate;
  tempDate;
  infiniteBulkJobList = [];
  tab = 'notification';
  throttle = 100;
  scrollDistance = 2;
  scrollUpDistance = 0;
  remark;
  isAdmin;

  constructor(
    private renderer: Renderer2,
    private userStore: Store<UserState>,
    private proTrouble: ProfileTroubleshootingService,
    private communicationService: CommunicationService,
    private appComponent: AppComponent,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService) { 

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');    
    }

  ngOnInit() {
    this.socket = this.appComponent.socket;

    this.setDisplayedColumns();
    this.getNotificationMode();
    this.getLogs();
    this.getBulkJob();

    this.communicationService.getIccidList()
      .subscribe((res: any) => {
        this.dataSource.data = [];
        this.iccidData = [];
        res.iccidList.forEach(ele => {
          this.iccidData.push(ele.iccid);
        });

        this.getLogs();
      });

    this.userStore.select('user')
      .subscribe((user: any) => {
        if (user) {
          this.user = user;

          this.isAdmin = this.user.data.roleNames.some(ele => ele === 'admin');

          if(this.user) {
            this.mainFeature = this.user.featureRole;
          }
          if(user.featureRole && user.featureRole.length > 0) {
            this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Troubleshoot')] ? user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Troubleshoot')].features : [];
          }

          this.selectTab();

          this.socket.emit('csspuser', this.user.data._id);
          this.socket.on('notification', (notiData) => {
            if (notiData && !this.notificationMode) {
              if (notiData.notificationType === 'HandleDownloadProgressInfo') {
                notiData.operationStatus = notiData.notificationPointStatus.status;
                notiData.notificationEvent = notiData.notificationPointId;
              }
              this.dataSource.data.splice(1, 0,
                notiData
              );
              this.dataSource._updateChangeSubscription();
              this.refresh();
            } else if (notiData && this.notificationMode) {
              this.notificationArr.push(notiData);
              localStorage.setItem('notificationArr', JSON.stringify(this.notificationArr));
            }
          });
        }
      });
    this.communicationService.getLanguage()
    .subscribe((res: any) => {
      this.changeLanguage();
    });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : false;
  }

  getMainFeatureIndex(keyName) {
    return this.mainFeature ? this.mainFeature.findIndex(ele => ele.name === keyName) : false;
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
  }

  ngAfterViewInit() {
    this.setTableResize(this.matTableRef?.nativeElement?.clientWidth);
  }

  clearIntervalTime() {
    clearInterval(this.interval);
  }

  selectTab() {
    if(this.features && this.features[this.getFeatureIndex('troubleshoot_shoot')].view) {
      $(`#pills-notification-tab`).addClass('active');
      $(`#pills-notification`).addClass('show active');
      $(`#pills-job-tab`).removeClass('active');
      $(`#pills-job`).removeClass('show active');
    } else if(this.features && this.features[this.getFeatureIndex('job_tab')].view) {
      $(`#pills-notification-tab`).removeClass('active');
      $(`#pills-notification`).removeClass('show active');
      $(`#pills-job-tab`).addClass('active');
      $(`#pills-job`).addClass('show active');
    }
  }

  getLogs() {
    this.proTrouble.getLogs(this.iccidData, this.searchTxt)
      .subscribe((res: any) => {
        res.unshift({
          notificationType: '',
          iccid: '',
          eid: '',
          completionTimestamp: '',
          notificationEvent: '',
          imei: '',
          operationStatus: '',
          created_date: '',
          mnoid: '',
          profileType: ''
        });
        res.forEach(element => {
          if (element.notificationType === 'HandleDownloadProgressInfo') {
            element.operationStatus = element.notificationPointStatus.status;
            element.notificationEvent = element.notificationPointId;
          }
        });
        this.dataSource.data = [];
        this.dataSource.data = res;
        this.dataSource._updateChangeSubscription();
        this.refresh();
      }, err => {
        const cookieTrail = {
          screenName: 'Manage Profile',
          action: 'Download QR Code',
          body: {
            iccidList: this.iccidData,
            searchTerm: this.searchTxt
          },
          err: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
      });
  }

  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.field;
    });
  }

  onResizeColumn(event: any, index: number) {
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if ((index === 0) || (Math.abs(event.pageX - cellData.right) < cellData.width / 2 && index !== this.columns.length - 1)) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow = this.matTableRef.nativeElement.children[0];
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this.renderer.listen('document', 'mousemove', (event) => {
      if (this.pressed && event.buttons) {
        const dx = (this.isResizingRight) ? (event.pageX - this.startX) : (-event.pageX + this.startX);
        const width = this.startWidth + dx;
        if (this.currentResizeIndex === index && width > 50) {
          this.setColumnWidthChanges(index, width);
        }
      }
    });
    this.resizableMouseup = this.renderer.listen('document', 'mouseup', (event) => {
      if (this.pressed) {
        this.pressed = false;
        this.currentResizeIndex = -1;
        this.resizableMousemove();
        this.resizableMouseup();
      }
    });
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = (this.isResizingRight) ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(document.getElementsByClassName('mat-column-' + column.field));
    columnEls.forEach((el: HTMLDivElement) => {
      el.style.width = column.width + 'px';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setTableResize(this.matTableRef?.nativeElement?.clientWidth);
  }

  validate(event: ResizeEvent): boolean {
    if (event.rectangle.height < 710 && event.rectangle.height > 40) {
      return true;
    }
    if (event.rectangle.height < 40) {
      return false;
    }
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      position: 'fixed',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: event.rectangle.height + 'px',
      transition: 'unset'
    };

    $('.commom-console').css('padding-bottom', event.rectangle.height + 10 + 'px');
    $('.console-table-body').css('height', event.rectangle.height - 150 + 'px');
  }

  addHeight() {
    $('.rectangle').css('height', '400px');
    $('.resize-handle-image').css('transform', 'translateY(-22px)');
    $('.commom-console').css('padding-bottom', '410px');
    $('.console-table-body').css('height', '250px');
    $('#rectangle').addClass('rectangle');
    this.flg = false;
  }

  closeTroubleShooting(event: ResizeEvent) {
    $('.rectangle').css('height', '0');
    this.style = {
      transition: 'all 0.3s ease-in-out 0s'
    };
    $('.resize-handle-image').css('transform', 'translateY(0px)');
    $('.commom-console').css('padding-bottom', '0px');
    $('.console-table-body').css('height', '0px');
    this.searchTxt = '';
    this.getLogs();
  }

  hideRow(type) {
    if (this.notificationCheck && !this.downloadCheck) {
      if (type === 'HandleNotification') {
        return false;
      } else if (type === 'HandleDownloadProgressInfo') {
        return true;
      }
    } else if (!this.notificationCheck && this.downloadCheck) {
      if (type === 'HandleNotification') {
        return true;
      } else if (type === 'HandleDownloadProgressInfo') {
        return false;
      }
    } else {
      return false;
    }
  }

  refresh() {
    this.dataSource.data.forEach((ele, index) => {
      if (index !== 0) {
        this.setTableResize(this.matTableRef?.nativeElement?.clientWidth);
      }
    });
  }

  getNotificationMode() {
    if (localStorage.getItem('notificationMode')) {
      this.notificationMode = (localStorage.getItem('notificationMode') === 'true');
    } else {
      this.notificationMode = false;
      localStorage.setItem('notificationMode', this.notificationMode);
    }
  }

  changeNotificationMode() {
    localStorage.setItem('notificationMode', this.notificationMode);

    if (!this.notificationMode) {
      if (JSON.parse(localStorage.getItem('notificationArr')) && JSON.parse(localStorage.getItem('notificationArr')).length > 0) {
        const temp = JSON.parse(localStorage.getItem('notificationArr'));
        temp.forEach(element => {
          if (element.notificationType === 'HandleDownloadProgressInfo') {
            element.operationStatus = element.notificationPointStatus.status;
            element.notificationEvent = element.notificationPointId;
          }
          this.dataSource.data.splice(1, 0,
            element
          );
          this.dataSource._updateChangeSubscription();
          this.refresh();
        });

        this.notificationArr = [];
        localStorage.removeItem('notificationArr');
      }
    }
  }

  isEnter(evt) {

    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode === 13 && !evt.shiftKey) {
      this.getLogs();
    }

  }

  chooseTab(type) {
    this.tab = type;
    if(type === 'job') {
      this.getBulkJob();
    }
  }

  getBulkJob() {
    this.bulkJobList = [];
    this.bulkJobSpinner = true;
    this.proTrouble.getBulkJob()
      .subscribe((res: any) => {
        this.bulkJobList = res;
        if(this.bulkJobList && this.bulkJobList.length > 0){
          this.lastDate = this.bulkJobList[this.bulkJobList.length - 1].created;
        }
        
        this.bulkJobSpinner = false;
      }, err => {
        this.bulkJobSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
      });
  }

  playandpauseQueue(data) {
    const queue = {
      loggerHeadId: data.loggerHeadId,
      status: data.status === 'Pause' ? 'PLAY' : data.status
    };

    this.proTrouble.playandpauseQueue(queue)
      .subscribe((res: any) => {
        if(res && res.message) {
          this.bulkJobList[data.index].status = res.currentStatus;
          if(res.message === 'Status of jobs has been updated.') {
            this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
          } else {
            this.communicationService.openAlert({alertType: 'error', alertMsg: res.message});
          }
        }
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  cancelQueue(data) {
    const queue = {
      loggerHeadId: data.loggerHeadId
    };

    this.proTrouble.cancelQueue(queue)
      .subscribe((res: any) => {
        if(res && res.message) {
          this.bulkJobList[data.index].status = 'Cancel';
          this.communicationService.openAlert({alertType: 'error', alertMsg: res.message});
        }
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  generateLink(jobUID) {
    const data = {
      jobUID
    }

    this.proTrouble.generateLink(data)
      .subscribe((res: any) => {
        if(res && res.message) {
          this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
        }
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }
}



