import { Group } from './group.model';
import * as GroupAction from './group.action';

export interface GroupState {
    readonly group: Group;
}

export function groupReducer(state: Group, action: GroupAction.GroupActions) {

    switch (action.type) {

        case GroupAction.GET_GROUP:
            return action.payload;

        default: return state;
    }
}