<div class="inner-main-profile profile-download-main-container">
    <div class="face-container-inner-bg">
		<div class="profile-manage-row result-not-found-row border-bottom mb-3">
			<h4 class="result-not-found-row-heading">{{'Profile Re-Download Report' | translate}}</h4>
			<div class="d-flex ml-auto mr-3">
				<div class="align-items-center d-flex pr-2 mr-3">
					<div>
						<h6 class="pull-left mr-3 mb-0">{{'Timezone' | translate}} </h6>
					</div>
					<select class="custom-selection-dropdown date-range-dropdown outline" style="width: 345px;" [(ngModel)]="selectedTimezone" (ngModelChange)="getReportData();setTimezone();">
						<option value="{{tz.timezonekey}}" *ngFor="let tz of timezones">{{tz.timezoneName}}</option>
					</select>
					<span><i class="fa fa-caret-down caret-position"></i></span>
				</div>
				<div class="d-flex t-color active mr-3" *ngIf="features && features[getFeatureIndex('profile_download')].edit">
					<div class="d-flex align-items-center">
						<div class="d-flex cursor-p operations-flex operations-flex-1" *ngIf="!mailSpinner" (click)="sendReportData();">
							<svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="28.21" height="21.488" viewBox="0 0 28.21 21.488"><defs></defs><g transform="translate(0 -61)"><g transform="translate(0 61)"><path class="a" d="M25.731,61H2.479A2.484,2.484,0,0,0,0,63.479V80.009a2.483,2.483,0,0,0,2.479,2.479H25.731a2.483,2.483,0,0,0,2.479-2.479V63.479A2.483,2.483,0,0,0,25.731,61Zm-.342,1.653L14.158,73.884,2.83,62.653ZM1.653,79.667V63.814l7.961,7.892Zm1.169,1.169,7.966-7.966,2.791,2.767a.826.826,0,0,0,1.166,0l2.721-2.721,7.922,7.922Zm23.736-1.169-7.922-7.922,7.922-7.922Z" transform="translate(0 -61)"/></g></g></svg>
							<div>
							{{'Send Email' | translate}}
							</div>
						</div>
						<div class="btn-spinner mr-3" *ngIf="mailSpinner">
							<div class="loader quantum-spinner m-auto"></div>
						</div>
					</div>
				</div>
				<div class="t-color d-flex active" *ngIf="features && features[getFeatureIndex('profile_download')].edit">
					<div class="d-flex align-items-center cursor-p operations-flex" *ngIf="!downloadSpinner" (click)="downloadProfileDownloadReport();">
						<svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><defs></defs><g transform="translate(-8 -8)"><path class="a" d="M25.5,8H22a.875.875,0,0,0,0,1.75h3.5a1.752,1.752,0,0,1,1.75,1.75v14a1.752,1.752,0,0,1-1.75,1.75h-14A1.752,1.752,0,0,1,9.75,25.5v-14A1.752,1.752,0,0,1,11.5,9.75H15A.875.875,0,1,0,15,8H11.5A3.5,3.5,0,0,0,8,11.5v14A3.5,3.5,0,0,0,11.5,29h14A3.5,3.5,0,0,0,29,25.5v-14A3.5,3.5,0,0,0,25.5,8Z"/><path class="a" d="M25.5,27.013l-2.006-2.006a.875.875,0,0,0-1.237,1.237l3.5,3.5a.875.875,0,0,0,1.237,0l3.5-3.5a.875.875,0,0,0-1.237-1.237L27.25,27.013V16.875a.875.875,0,1,0-1.75,0Z" transform="translate(-7.875 -4.5)"/></g></svg>
						<div>
							{{'Download' | translate}}
						</div>
					</div>
					<div class="btn-spinner mr-3" *ngIf="downloadSpinner">
						<div class="loader quantum-spinner m-auto"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="border-bottom profile-select-options">
            <div >
                <div class="d-flex mb-3 pl-3 pr-3">
                    <div class="align-items-center pr-2 custom-iccid-width">
                        <label class="t-label pull-left mr-2">{{'ICCID' | translate}}: </label>
                        <input class="t-input form-control" type="text" placeholder="{{'Enter ICCID' | translate}}" [(ngModel)]="iccid" (ngModelChange)="getReportData()">
                    </div>
					<div class="align-items-center pr-2 custom-profile-width" style="position: relative;" *ngIf="!selectProfileFlg">
						<div>
							<label class="t-label pull-left mr-2">{{'Group' | translate}}: </label>
						</div>
						<select class="custom-selection-dropdown" style="height: calc(1.5em + .75rem + 2px);width: 100%;" [(ngModel)]="group" (ngModelChange)="getReportData()">
							<option value="none">{{'Select Group' | translate}}</option>
							<option value="{{group._id}}" *ngFor="let group of groupList">{{group.groupName}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position" style="top: 41px; position: absolute;"></i></span>
                    </div>
					<div class="align-items-center pr-2 custom-profile-width" style="position: relative;" *ngIf="selectProfileFlg">
						<div>
							<label class="t-label pull-left mr-2">{{'Profile Type' | translate}}: </label>
						</div>
						<select class="custom-selection-dropdown" style="height: calc(1.5em + .75rem + 2px);width: 100%;" [(ngModel)]="profileType" (ngModelChange)="getReportData()">
							<option value="none">{{'Select Profile Type' | translate}}</option>
							<option value="{{profileType.name}}" *ngFor="let profileType of profileTypeList">{{profileType.name}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position" style="top: 41px; position: absolute;"></i></span>
                    </div>
					<div class="align-items-center pr-2 custom-range-width">
                        <label class="t-label pull-left mr-2">{{'PRP Value' | translate}}: </label>
                        <input class="t-input form-control" type="text" placeholder="{{'Enter PRP Value' | translate}}" [(ngModel)]="prpValue" (ngModelChange)="getReportData()">
                    </div>
					<div class="align-items-center pr-2" style="position: relative;">
                        <div>
							<label class="t-label pull-left mr-2">{{'Date Range' | translate}} </label>
						</div>
                        <select class="custom-selection-dropdown date-range-dropdown" [ngClass]="{'fit-to-language': language === 'ru' && dateRange !== 'custom'}" [(ngModel)]="dateRange" (ngModelChange)="getReportData()">
							<option value="today">{{'Today' | translate}}</option>
                            <option value="yesterday">{{'Yesterday' | translate}}</option>
                            <option value="week">{{'Week-to-Date' | translate}}</option>
                            <option value="month">{{'Month-to-Date' | translate}}</option>
                            <option value="year">{{'Year-to-Date' | translate}}</option>
							<option value="custom">{{'Custom' | translate}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position" style="position: absolute;top: 40px;"></i></span>
                    </div>
					<div class="align-items-center pr-2 custom-date-width" *ngIf="dateRange === 'custom'">
                        <div>
							<label class="t-label pull-left mr-2">{{'Select Date'| translate}}</label>
						</div>
                        <input class="t-input" style="padding: 6px; border-radius: 5px;width: 100%;" [(ngModel)]="selectedMoments" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'" (ngModelChange)="getReportData()">
                        <owl-date-time [pickerType]="'calendar'" #dt10></owl-date-time>
                    </div>
                </div>
            </div>
        </div>
		<!-- <div class="table-responsive"> -->

			<div class="row profile-download-report">
				<div class="col-lg-12">
					<div class="t-grid-table-new">
						<div class="t-grid-table-wrapper t-head-bg">
							<div class="t-box">{{'ICCID' | translate}}</div>
							<div class="t-box">{{'Downloaded' | translate}}</div>
							<div class="t-box">{{'Re-Downloaded' | translate}}</div>
							<div class="t-box">{{'Profile Type' | translate}}</div>
							<!-- <div class="t-box">{{'PRP Setting' | translate}}</div> -->
							<div class="t-box d-flex prp-info"><div class="mr-2">{{'PRP Count' | translate}}</div>
							<div class="c-color c-info py-0" tooltip="{{'PRP count shows real-time value and will not change when date range is selected' | translate}}" [tooltipAnimation]="true" placement="top">
								<i class=" c-color c-info py-0 fa fa-info mr-3 icon-count-class"></i>
								</div>
							</div>
							<div class="t-box">{{'Current Status' | translate}}</div>
							<div class="t-box"></div>
						</div>
						<div class="loader-flex-center" *ngIf="profileDownloadSpinner">
							<div class="loader quantum-spinner m-auto"></div>
						</div>
						<div *ngIf="profileDownloadReportList && profileDownloadReportList.length <= 0 && !profileDownloadSpinner && !infiniteSpinner">
							<div class="text-center mt-2 mb-2">
								<div class="text-center">{{'No Data Found' | translate}}.</div>
							</div>
						</div>
						<div class="t-body-overflow">
							<div class="t-grid-table-wrapper t-body-bg" *ngFor="let item of profileDownloadReportList;let i = index;">
								<div class="t-box d t-font-light">{{item.iccid}}</div>
								<div class="t-box d t-font-light">{{item.download}}</div>
								<div class="t-box d t-font-light">{{item.reDownload}}</div>
								<div class="t-box d t-font-light">{{item.profileType}}</div>
								<!-- <div class="t-box d t-font-light">{{item.prp}}</div> -->
								<div class="t-box d t-font-light">{{item.prpCounter}}</div>
								<div class="t-box d t-font-light">{{item.status}}</div>
								<div class="t-box d t-font-light d-flex">
									<a class="c-color c-info py-0" (click)="openIccidInfo(i)">
										<i class="fa fa-info mr-3" style="width: 16px;height: 16px;font-size: 10px;"></i>
									</a>
								</div>
							</div>
							<div class="btn-spinner py-3" *ngIf="infiniteSpinner">
								<div class="loader quantum-spinner m-auto"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		<!-- </div> -->
    </div>
</div>

<!-- iccid info start -->

<div class="modal fade" id="iccidInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 670px !important;" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "ICCID Information" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body device-flex-body">
                <div class="profile-download-report">
					<div class="d-flex">
						<h5 class="mb-2 info-title"><span class="mr-2">ICCID:</span><span class="t-color">{{infoIccid}}</span></h5>
					</div>
                    <div class="t-grid-table-new">
						<div class="t-grid-table-wrapper-iccid-info t-head-bg">
							<div class="t-box a">{{'EID' | translate}}</div>
                            <div class="t-box b">{{'Downloaded On' | translate}}</div>
						</div>
						<div class="loader-flex-center" *ngIf="profileDownloadReportInfoSpinner">
							<div class="loader quantum-spinner m-auto"></div>
						</div>
						<div *ngIf="profileDownloadReportInfoList && profileDownloadReportInfoList.length <= 0 && !profileDownloadReportInfoSpinner">
							<div class="text-center mt-2 mb-2">
								<div class="text-center">{{'No Data Found' | translate}}.</div>
							</div>
						</div>
						<div class="eid-info-body-overflow">
							<div class="t-grid-table-wrapper-iccid-info t-body-bg" *ngFor="let item of profileDownloadReportInfoList">
								<div class="t-box d t-font-light">{{item.eid}}</div>
                                <div class="t-box d t-font-light">{{item.timestamp | date: "dd-MM-yyyy, h:mm:ss a"}}</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- iccid info end -->