import { ManageGroupFilter } from './filter.model';
import * as ManageGroupFilterAction from './filter.action';

export interface ManageGroupQrFilterState {
    readonly manageGroupQrFilter: ManageGroupFilter;
}

export function manageGroupQrFilterReducer(state: ManageGroupFilter, action: ManageGroupFilterAction.FilterActions) {

    switch (action.type) {

        case ManageGroupFilterAction.GET_MANAGE_GROUP_QR_FILTER:
            return action.payload;

        default: return state;
    }
}
