import { Action } from '@ngrx/store';
import { Dashboard } from './dashboard.model';

export const GET_SIDEBAR = 'GET_SIDEBAR';
export const ADD_WIDGET = 'ADD_WIDGET';
export const GET_WIDGETS = 'GET_WIDGETS';
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const SAVE_TYPE = 'SAVE_TYPE';

export class GetSidebar implements Action {
    readonly type = GET_SIDEBAR;
    constructor(public payload: Dashboard) { }
}

export class AddWidget implements Action {
    readonly type = ADD_WIDGET;
    constructor(public payload: Dashboard) { }
}

export class RemoveWidget implements Action {
    readonly type = REMOVE_WIDGET;
    constructor(public payload: Dashboard) { }
}

export class GetWidgets implements Action {
    readonly type = GET_WIDGETS;
    constructor(public payload: Dashboard) { }
}

export class SaveWidgetType implements Action {
    readonly type = SAVE_TYPE;
    constructor(public payload: Dashboard) { }
}

export type DashboardActions = AddWidget | GetSidebar | GetWidgets | RemoveWidget | SaveWidgetType;
// export type DashboardActions = GetSidebar |  | GetWidgets;
