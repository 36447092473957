import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getFileHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${authToken}`,
      })
    };
    return httpOptions;
  }

  saveTemplate(pdfData) {
    // console.log(pdfData);
    return this.http.post(`${this.serverUrl}/pdfgen`, pdfData, this.getFileHeader())
      .pipe(map(response => response));
  }

  updateCampaign(id, pdfData) {
    // console.log(pdfData);
    return this.http.put(`${this.serverUrl}/pdfgen/${id}`, pdfData, this.getFileHeader())
      .pipe(map(response => response));
  }

  generateIccidPdf(id, file) {
    return this.http.post(`${this.serverUrl}/pdfgen/${id}`, file, this.getFileHeader())
      .pipe(map(response => response));
  }

  generateGroupPdf(id, file) {
    return this.http.post(`${this.serverUrl}/group-campaign/${id}`, file, this.getFileHeader())
      .pipe(map(response => response));
  }

  getCampaign(id) {
    return this.http.get(`${this.serverUrl}/pdfgen/${id}`, this.getFileHeader())
      .pipe(map(response => response));
  }

  getAllTemplates(data = {campaignType: ''}) {
    if(data && data.campaignType) {
      return this.http.get(`${this.serverUrl}/pdfgen?campaignType=${data.campaignType}`, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.get(`${this.serverUrl}/pdfgen`, this.getHeader())
        .pipe(map(response => response));
    }
  }

  getSingleTemplate(id) {
    return this.http.get(`${this.serverUrl}/pdfgen/single/${id}`, this.getHeader())
      .pipe(map(response => response));
  }

  addLogoInQr(data) {
    return this.http.post(`${this.serverUrl}/pdfgen-logo`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getPDFToPNG(data) {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
      })
    };

    return this.http.post(`${this.serverUrl}/pdfgen/convert/pdf`, data, httpOptions)
      .pipe(map(response => response));
  }
}
