export let colors = [
	'#98c379', 
	'#ae7070', 
	'#b2c8ff', 
	'#950245', 
	'#830433', 
	'#826488', 
	'#6c3c70',
	'#073110',
	'#8b764f',
	'#accad4',
	'#6978c6',
	'#4c5b4b',
	'#9ab29f',
	'#2d4b09',
	'#bdc73c',
	'#093f15', 
	"#4c0f0b",
	"#291a1e",
	"#282e54",
	"#9fb65f",
	"#5e694c",
	"#744700",
	"#966a4c",
	'#0b4d1a',
	"#cc8c4f",
	"#b2773f",
	"#716115",
	"#846f22",
	"#2f3136",
	"#444444",
	"#ab8c5a",
	'#0e5b1e',
	'#5e694c',
	'#dcbd5e',
	'#f37553',
	'#876944',
	'#452500',
	'#a38554',
	'#05324b',
	'#106a23', 
	'#074e67',
	'#5a175d',
	'#67074e',
	'#dd9933',
	'#07695b',
	'#0a6496',
	'#f1b199',
	'#127828', 
	'#14862d', 
	'#169431',
	'#05230b'
]