import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieTrailService {

  constructor() { }

  saveCookie(cookieTrailObj) {
    localStorage.setItem('cookieTrail', JSON.stringify({}));

    const cookieTrail = {
      environment: localStorage.getItem('isProd') === 'true' ? 'production' : 'pre-production',
      screenName: cookieTrailObj.screenName,
      action: cookieTrailObj.action,
      body: cookieTrailObj.body,
      timeStamp: new Date()
    };

    localStorage.setItem('cookieTrail', JSON.stringify(cookieTrail));
  }
}
