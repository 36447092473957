export const ELEMENT_DATA = [
    {
        notificationType: '',
        iccid: '',
        status: '',
        eid: '',
        completionTimestamp: '',
        notificationEvent: '',
        imei: '',
        operationStatus: '',
        created_date: '',
        mnoid: '',
        profileType: '',
        isSeen: false,
        isHide: false
    }
];


// ,
//     {
//         NotificationType: 'HandleNotification',
//         ICCID: '89445005041632000065',
//         Status: 'Released',
//         Eid: '89049032004008882600016698204368',
//         CompletionTimeStamp: '2020-02-27T10:22:07Z',
//         NotificationEvent: '2',
//         imei: '123456789012345',
//         OperationStatus: 'Executed_Success',
//         Created_Date: '2020-02-27T10:22:07Z',
//         mnoid: 'Venta_Mobile',
//         __V: '0',
//         Type: '10T-Cloud_GlobalPlus_T01',
//         isSeen: false,
//         isHide: false
//     },
//     {
//         NotificationType: 'HandleDownloadProgressInfo',
//         ICCID: '89445005041632000066',
//         Status: 'Released',
//         Eid: '89049032004008882600016698204368',
//         CompletionTimeStamp: '2020-02-27T10:22:07Z',
//         NotificationEvent: '2',
//         imei: '123456789012345',
//         OperationStatus: 'Executed_Success',
//         Created_Date: '2020-02-27T10:22:07Z',
//         mnoid: 'Venta_Mobile',
//         __V: '0',
//         Type: '10T-Cloud_GlobalPlus_T01',
//         isSeen: false,
//         isHide: false
//     },
//     {
//         NotificationType: 'HandleDownloadProgressInfo',
//         ICCID: '89445005041632000067',
//         Status: 'Released',
//         Eid: '89049032004008882600016698204368',
//         CompletionTimeStamp: '2020-02-27T10:22:07Z',
//         NotificationEvent: '2',
//         imei: '123456789012345',
//         OperationStatus: 'Executed_Success',
//         Created_Date: '2020-02-27T10:22:07Z',
//         mnoid: 'Venta_Mobile',
//         __V: '0',
//         Type: '10T-Cloud_GlobalPlus_T01',
//         isSeen: false,
//         isHide: false
//     },
//     {
//         NotificationType: 'HandleNotification',
//         ICCID: '89445005041632000068',
//         Status: 'Released',
//         Eid: '89049032004008882600016698204368',
//         CompletionTimeStamp: '2020-02-27T10:22:07Z',
//         NotificationEvent: '2',
//         imei: '123456789012345',
//         OperationStatus: 'Executed_Success',
//         Created_Date: '2020-02-27T10:22:07Z',
//         mnoid: 'Venta_Mobile',
//         __V: '0',
//         Type: '10T-Cloud_GlobalPlus_T01',
//         isSeen: false,
//         isHide: false
//     },
//     {
//         NotificationType: 'HandleDownloadProgressInfo',
//         ICCID: '89445005041632000069',
//         Status: 'Released',
//         Eid: '89049032004008882600016698204368',
//         CompletionTimeStamp: '2020-02-27T10:22:07Z',
//         NotificationEvent: '2',
//         imei: '123456789012345',
//         OperationStatus: 'Executed_Success',
//         Created_Date: '2020-02-27T10:22:07Z',
//         mnoid: 'Venta_Mobile',
//         __V: '0',
//         Type: '10T-Cloud_GlobalPlus_T01',
//         isSeen: false,
//         isHide: false
//     },
//     {
//         NotificationType: 'HandleNotification',
//         ICCID: '89445005041632000070',
//         Status: 'Released',
//         Eid: '89049032004008882600016698204368',
//         CompletionTimeStamp: '2020-02-27T10:22:07Z',
//         NotificationEvent: '2',
//         imei: '123456789012345',
//         OperationStatus: 'Executed_Success',
//         Created_Date: '2020-02-27T10:22:07Z',
//         mnoid: 'Venta_Mobile',
//         __V: '0',
//         Type: '10T-Cloud_GlobalPlus_T01',
//         isSeen: false,
//         isHide: false
//     },
