export const timezones = [
    // {
    //     "timezoneName": "(UTC -12) Etc/GMT+12",
    //     "timezonekey": "Etc/GMT+12"
    // },
    {
        "timezoneName": "(UTC -11) Pacific/Niue",
        "timezonekey": "Pacific/Niue"
    },
    {
        "timezoneName": "(UTC -11) Pacific/Pago Pago",
        "timezonekey": "Pacific/Pago_Pago"
    },
    // {
    //     "timezoneName": "(UTC -10) HST",
    //     "timezonekey": "HST"
    // },
    {
        "timezoneName": "(UTC -10) Pacific/Honolulu",
        "timezonekey": "Pacific/Honolulu"
    },
    {
        "timezoneName": "(UTC -10) Pacific/Rarotonga",
        "timezonekey": "Pacific/Rarotonga"
    },
    {
        "timezoneName": "(UTC -10) Pacific/Tahiti",
        "timezonekey": "Pacific/Tahiti"
    },
    {
        "timezoneName": "(UTC -10) America/Adak",
        "timezonekey": "America/Adak"
    },
    {
        "timezoneName": "(UTC -9.5) Pacific/Marquesas",
        "timezonekey": "Pacific/Marquesas"
    },
    {
        "timezoneName": "(UTC -9) Pacific/Gambier",
        "timezonekey": "Pacific/Gambier"
    },
    {
        "timezoneName": "(UTC -9) America/Anchorage",
        "timezonekey": "America/Anchorage"
    },
    {
        "timezoneName": "(UTC -9) America/Juneau",
        "timezonekey": "America/Juneau"
    },
    {
        "timezoneName": "(UTC -9) America/Metlakatla",
        "timezonekey": "America/Metlakatla"
    },
    {
        "timezoneName": "(UTC -9) America/Nome",
        "timezonekey": "America/Nome"
    },
    {
        "timezoneName": "(UTC -9) America/Sitka",
        "timezonekey": "America/Sitka"
    },
    {
        "timezoneName": "(UTC -9) America/Yakutat",
        "timezonekey": "America/Yakutat"
    },
    {
        "timezoneName": "(UTC -8) Pacific/Pitcairn",
        "timezonekey": "Pacific/Pitcairn"
    },
    {
        "timezoneName": "(UTC -8) America/Los Angeles",
        "timezonekey": "America/Los_Angeles"
    },
    {
        "timezoneName": "(UTC -8) America/Tijuana",
        "timezonekey": "America/Tijuana"
    },
    {
        "timezoneName": "(UTC -8) America/Vancouver",
        "timezonekey": "America/Vancouver"
    },
    {
        "timezoneName": "(UTC -7) America/Boise",
        "timezonekey": "America/Boise"
    },
    {
        "timezoneName": "(UTC -7) America/Cambridge Bay",
        "timezonekey": "America/Cambridge_Bay"
    },
    {
        "timezoneName": "(UTC -7) America/Chihuahua",
        "timezonekey": "America/Chihuahua"
    },
    {
        "timezoneName": "(UTC -7) America/Creston",
        "timezonekey": "America/Creston"
    },
    {
        "timezoneName": "(UTC -7) America/Dawson",
        "timezonekey": "America/Dawson"
    },
    {
        "timezoneName": "(UTC -7) America/Dawson Creek",
        "timezonekey": "America/Dawson_Creek"
    },
    {
        "timezoneName": "(UTC -7) America/Denver",
        "timezonekey": "America/Denver"
    },
    {
        "timezoneName": "(UTC -7) America/Edmonton",
        "timezonekey": "America/Edmonton"
    },
    {
        "timezoneName": "(UTC -7) America/Fort Nelson",
        "timezonekey": "America/Fort_Nelson"
    },
    {
        "timezoneName": "(UTC -7) America/Hermosillo",
        "timezonekey": "America/Hermosillo"
    },
    {
        "timezoneName": "(UTC -7) America/Inuvik",
        "timezonekey": "America/Inuvik"
    },
    {
        "timezoneName": "(UTC -7) America/Mazatlan",
        "timezonekey": "America/Mazatlan"
    },
    {
        "timezoneName": "(UTC -7) America/Ojinaga",
        "timezonekey": "America/Ojinaga"
    },
    {
        "timezoneName": "(UTC -7) America/Phoenix",
        "timezonekey": "America/Phoenix"
    },
    {
        "timezoneName": "(UTC -7) America/Whitehorse",
        "timezonekey": "America/Whitehorse"
    },
    {
        "timezoneName": "(UTC -7) America/Yellowknife",
        "timezonekey": "America/Yellowknife"
    },
    // {
    //     "timezoneName": "(UTC -7) MST",
    //     "timezonekey": "MST"
    // },
    // {
    //     "timezoneName": "(UTC -7) PST8PDT",
    //     "timezonekey": "PST8PDT"
    // },
    {
        "timezoneName": "(UTC -6) America/Bahia Banderas",
        "timezonekey": "America/Bahia_Banderas"
    },
    {
        "timezoneName": "(UTC -6) America/Belize",
        "timezonekey": "America/Belize"
    },
    {
        "timezoneName": "(UTC -6) America/Chicago",
        "timezonekey": "America/Chicago"
    },
    {
        "timezoneName": "(UTC -6) America/Costa Rica",
        "timezonekey": "America/Costa_Rica"
    },
    {
        "timezoneName": "(UTC -6) America/El Salvador",
        "timezonekey": "America/El_Salvador"
    },
    {
        "timezoneName": "(UTC -6) America/Guatemala",
        "timezonekey": "America/Guatemala"
    },
    {
        "timezoneName": "(UTC -6) America/Indiana/Knox",
        "timezonekey": "America/Indiana/Knox"
    },
    {
        "timezoneName": "(UTC -6) America/Indiana/Tell City",
        "timezonekey": "America/Indiana/Tell_City"
    },
    {
        "timezoneName": "(UTC -6) America/Managua",
        "timezonekey": "America/Managua"
    },
    {
        "timezoneName": "(UTC -6) America/Matamoros",
        "timezonekey": "America/Matamoros"
    },
    {
        "timezoneName": "(UTC -6) America/Menominee",
        "timezonekey": "America/Menominee"
    },
    {
        "timezoneName": "(UTC -6) America/Merida",
        "timezonekey": "America/Merida"
    },
    {
        "timezoneName": "(UTC -6) America/Mexico City",
        "timezonekey": "America/Mexico_City"
    },
    {
        "timezoneName": "(UTC -6) America/Monterrey",
        "timezonekey": "America/Monterrey"
    },
    {
        "timezoneName": "(UTC -6) America/North Dakota/Beulah",
        "timezonekey": "America/North_Dakota/Beulah"
    },
    {
        "timezoneName": "(UTC -6) America/North Dakota/Center",
        "timezonekey": "America/North_Dakota/Center"
    },
    {
        "timezoneName": "(UTC -6) America/North Dakota/New_Salem",
        "timezonekey": "America/North_Dakota/New_Salem"
    },
    {
        "timezoneName": "(UTC -6) America/Rainy River",
        "timezonekey": "America/Rainy_River"
    },
    {
        "timezoneName": "(UTC -6) America/Rankin Inlet",
        "timezonekey": "America/Rankin_Inlet"
    },
    {
        "timezoneName": "(UTC -6) America/Regina",
        "timezonekey": "America/Regina"
    },
    {
        "timezoneName": "(UTC -6) America/Resolute",
        "timezonekey": "America/Resolute"
    },
    {
        "timezoneName": "(UTC -6) America/Swift Current",
        "timezonekey": "America/Swift_Current"
    },
    {
        "timezoneName": "(UTC -6) America/Tegucigalpa",
        "timezonekey": "America/Tegucigalpa"
    },
    {
        "timezoneName": "(UTC -6) America/Winnipeg",
        "timezonekey": "America/Winnipeg"
    },
    // {
    //     "timezoneName": "(UTC -6) MST7MDT",
    //     "timezonekey": "MST7MDT"
    // },
    {
        "timezoneName": "(UTC -6) Pacific/Galapagos",
        "timezonekey": "Pacific/Galapagos"
    },
    {
        "timezoneName": "(UTC -5) Pacific/Easter",
        "timezonekey": "Pacific/Easter"
    },
    {
        "timezoneName": "(UTC -5) America/Atikokan",
        "timezonekey": "America/Atikokan"
    },
    {
        "timezoneName": "(UTC -5) America/Bogota",
        "timezonekey": "America/Bogota"
    },
    {
        "timezoneName": "(UTC -5) America/Cancun",
        "timezonekey": "America/Cancun"
    },
    {
        "timezoneName": "(UTC -5) America/Detroit",
        "timezonekey": "America/Detroit"
    },
    {
        "timezoneName": "(UTC -5) America/Eirunepe",
        "timezonekey": "America/Eirunepe"
    },
    {
        "timezoneName": "(UTC -5) America/Fort Wayne",
        "timezonekey": "America/Fort_Wayne"
    },
    {
        "timezoneName": "(UTC -5) America/Grand Turk",
        "timezonekey": "America/Grand_Turk"
    },
    {
        "timezoneName": "(UTC -5) America/Guayaquil",
        "timezonekey": "America/Guayaquil"
    },
    {
        "timezoneName": "(UTC -5) America/Havana",
        "timezonekey": "America/Havana"
    },
    {
        "timezoneName": "(UTC -5) America/Indiana/Marengo",
        "timezonekey": "America/Indiana/Marengo"
    },
    {
        "timezoneName": "(UTC -5) America/Indiana/Petersburg",
        "timezonekey": "America/Indiana/Petersburg"
    },
    {
        "timezoneName": "(UTC -5) America/Indiana/Vevay",
        "timezonekey": "America/Indiana/Vevay"
    },
    {
        "timezoneName": "(UTC -5) America/Indiana/Vincennes",
        "timezonekey": "America/Indiana/Vincennes"
    },
    {
        "timezoneName": "(UTC -5) America/Indiana/Winamac",
        "timezonekey": "America/Indiana/Winamac"
    },
    {
        "timezoneName": "(UTC -5) America/Iqaluit",
        "timezonekey": "America/Iqaluit"
    },
    {
        "timezoneName": "(UTC -5) America/Jamaica",
        "timezonekey": "America/Jamaica"
    },
    {
        "timezoneName": "(UTC -5) America/Kentucky/Louisville",
        "timezonekey": "America/Kentucky/Louisville"
    },
    {
        "timezoneName": "(UTC -5) America/Kentucky/Monticello",
        "timezonekey": "America/Kentucky/Monticello"
    },
    {
        "timezoneName": "(UTC -5) America/Lima",
        "timezonekey": "America/Lima"
    },
    {
        "timezoneName": "(UTC -5) America/Nassau",
        "timezonekey": "America/Nassau"
    },
    {
        "timezoneName": "(UTC -5) America/New York",
        "timezonekey": "America/New_York"
    },
    {
        "timezoneName": "(UTC -5) America/Nipigon",
        "timezonekey": "America/Nipigon"
    },
    {
        "timezoneName": "(UTC -5) America/Pangnirtung",
        "timezonekey": "America/Pangnirtung"
    },
    {
        "timezoneName": "(UTC -5) America/Pangnirtung",
        "timezonekey": "America/Port_of_Spain"
    },
    {
        "timezoneName": "(UTC -5) America/Port-au-Prince",
        "timezonekey": "America/Port-au-Prince"
    },
    {
        "timezoneName": "(UTC -5) America/Panama",
        "timezonekey": "America/Panama"
    },
    {
        "timezoneName": "(UTC -5) America/Rio Branco",
        "timezonekey": "America/Rio_Branco"
    },
    {
        "timezoneName": "(UTC -4) America/Thunder Bay",
        "timezonekey": "America/Thunder_Bay"
    },
    {
        "timezoneName": "(UTC -4) America/Toronto",
        "timezonekey": "America/Toronto"
    },
    // {
    //     "timezoneName": "(UTC -5) CST6CDT",
    //     "timezonekey": "CST6CDT"
    // },
    // {
    //     "timezoneName": "(UTC -5) EST",
    //     "timezonekey": "EST"
    // },
    {
        "timezoneName": "(UTC -4) America/Asuncion",
        "timezonekey": "America/Asuncion"
    },
    {
        "timezoneName": "(UTC -4) America/Barbados",
        "timezonekey": "America/Barbados"
    },
    {
        "timezoneName": "(UTC -4) America/Blanc-Sablon",
        "timezonekey": "America/Blanc-Sablon"
    },
    {
        "timezoneName": "(UTC -4) America/Boa Vista",
        "timezonekey": "America/Boa_Vista"
    },
    {
        "timezoneName": "(UTC -4) America/Campo Grande",
        "timezonekey": "America/Campo_Grande"
    },
    {
        "timezoneName": "(UTC -4) America/Caracas",
        "timezonekey": "America/Caracas"
    },
    {
        "timezoneName": "(UTC -4) America/Cuiaba",
        "timezonekey": "America/Cuiaba"
    },
    {
        "timezoneName": "(UTC -4) America/Curacao",
        "timezonekey": "America/Curacao"
    },
    {
        "timezoneName": "(UTC -4) America/Glace Bay",
        "timezonekey": "America/Glace_Bay"
    },
    {
        "timezoneName": "(UTC -4) America/Goose Bay",
        "timezonekey": "America/Goose_Bay"
    },
    {
        "timezoneName": "(UTC -4) America/Guyana",
        "timezonekey": "America/Guyana"
    },
    {
        "timezoneName": "(UTC -4) America/Halifax",
        "timezonekey": "America/Halifax"
    },
    {
        "timezoneName": "(UTC -4) America/La Paz",
        "timezonekey": "America/La_Paz"
    },
    {
        "timezoneName": "(UTC -4) America/Manaus",
        "timezonekey": "America/Manaus"
    },
    {
        "timezoneName": "(UTC -4) America/Martinique",
        "timezonekey": "America/Martinique"
    },
    {
        "timezoneName": "(UTC -4) America/Moncton",
        "timezonekey": "America/Moncton"
    },
    {
        "timezoneName": "(UTC -4) America/Porto Velho",
        "timezonekey": "America/Porto Velho"
    },
    {
        "timezoneName": "(UTC -4) America/Puerto Rico",
        "timezonekey": "America/Puerto_Rico"
    },
    {
        "timezoneName": "(UTC -4) America/Santiago",
        "timezonekey": "America/Santiago"
    },
    {
        "timezoneName": "(UTC -4) America/Santo Domingo",
        "timezonekey": "America/Santo_Domingo"
    },
    {
        "timezoneName": "(UTC -4) America/Thule",
        "timezonekey": "America/Thule"
    },
    {
        "timezoneName": "(UTC -4) Atlantic/Bermuda",
        "timezonekey": "Atlantic/Bermuda"
    },
    // {
    //     "timezoneName": "(UTC -4) EST5EDT",
    //     "timezonekey": "EST5EDT"
    // },
    {
        "timezoneName": "(UTC -3.5) America/St Johns",
        "timezonekey": "America/St_Johns"
    },
    {
        "timezoneName": "(UTC -3) America/Araguaina",
        "timezonekey": "America/Araguaina"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Buenos Aires",
        "timezonekey": "America/Argentina/Buenos_Aires"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Catamarca",
        "timezonekey": "America/Argentina/Catamarca"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Cordoba",
        "timezonekey": "America/Argentina/Cordoba"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Jujuy",
        "timezonekey": "America/Argentina/Jujuy"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/La Rioja",
        "timezonekey": "America/Argentina/La_Rioja"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Mendoza",
        "timezonekey": "America/Argentina/Mendoza"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Rio Gallegos",
        "timezonekey": "America/Argentina/Rio_Gallegos"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Salta",
        "timezonekey": "America/Argentina/Salta"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/San Juan",
        "timezonekey": "America/Argentina/San_Juan"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/San Luis",
        "timezonekey": "America/Argentina/San_Luis"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Tucuman",
        "timezonekey": "America/Argentina/Tucuman"
    },
    {
        "timezoneName": "(UTC -3) America/Argentina/Ushuaia",
        "timezonekey": "America/Argentina/Ushuaia"
    },
    {
        "timezoneName": "(UTC -3) America/Bahia",
        "timezonekey": "America/Bahia"
    },
    {
        "timezoneName": "(UTC -3) America/Belem",
        "timezonekey": "America/Belem"
    },
    {
        "timezoneName": "(UTC -3) America/Cayenne",
        "timezonekey": "America/Cayenne"
    },
    {
        "timezoneName": "(UTC -3) America/Fortaleza",
        "timezonekey": "America/Fortaleza"
    },
    {
        "timezoneName": "(UTC -3) America/Godthab",
        "timezonekey": "America/Godthab"
    },
    {
        "timezoneName": "(UTC -3) America/Maceio",
        "timezonekey": "America/Maceio"
    },
    {
        "timezoneName": "(UTC -3) America/Miquelon",
        "timezonekey": "America/Miquelon"
    },
    {
        "timezoneName": "(UTC -3) America/Montevideo",
        "timezonekey": "America/Montevideo"
    },
    {
        "timezoneName": "(UTC -3) America/Paramaribo",
        "timezonekey": "America/Paramaribo"
    },
    {
        "timezoneName": "(UTC -3) America/Punta Arenas",
        "timezonekey": "America/Punta_Arenas"
    },
    {
        "timezoneName": "(UTC -3) America/Recife",
        "timezonekey": "America/Recife"
    },
    {
        "timezoneName": "(UTC -3) America/Santarem",
        "timezonekey": "America/Santarem"
    },
    {
        "timezoneName": "(UTC -3) America/Sao Paulo",
        "timezonekey": "America/Sao_Paulo"
    },
    {
        "timezoneName": "(UTC -3) Antarctica/Palmer",
        "timezonekey": "Antarctica/Palmer"
    },
    {
        "timezoneName": "(UTC -3) Antarctica/Rothera",
        "timezonekey": "Antarctica/Rothera"
    },
    {
        "timezoneName": "(UTC -3) Atlantic/Stanley",
        "timezonekey": "Atlantic/Stanley"
    },
    {
        "timezoneName": "(UTC -2) America/Noronha",
        "timezonekey": "America/Noronha"
    },
    {
        "timezoneName": "(UTC -2) Atlantic/South Georgia",
        "timezonekey": "Atlantic/South_Georgia"
    },
    {
        "timezoneName": "(UTC -1) America/Scoresbysund",
        "timezonekey": "America/Scoresbysund"
    },
    {
        "timezoneName": "(UTC -1) Atlantic/Azores",
        "timezonekey": "Atlantic/Azores"
    },
    {
        "timezoneName": "(UTC -1) Atlantic/Cape Verde",
        "timezonekey": "Atlantic/Cape_Verde"
    },
    {
        "timezoneName": "(UTC +0) Africa/Abidjan",
        "timezonekey": "Africa/Abidjan"
    },
    {
        "timezoneName": "(UTC +0) Africa/Accra",
        "timezonekey": "Africa/Accra"
    },
    {
        "timezoneName": "(UTC +0) Africa/Bissau",
        "timezonekey": "Africa/Bissau"
    },
    {
        "timezoneName": "(UTC +0) Africa/Monrovia",
        "timezonekey": "Africa/Monrovia"
    },
    {
        "timezoneName": "(UTC +0) Africa/Sao Tome",
        "timezonekey": "Africa/Sao_Tome"
    },
    {
        "timezoneName": "(UTC +0) America/Danmarkshavn",
        "timezonekey": "America/Danmarkshavn"
    },
    {
        "timezoneName": "(UTC +0) Antarctica/Troll",
        "timezonekey": "Antarctica/Troll"
    },
    {
        "timezoneName": "(UTC +0) Atlantic/Canary",
        "timezonekey": "Atlantic/Canary"
    },
    {
        "timezoneName": "(UTC +0) Atlantic/Faroe",
        "timezonekey": "Atlantic/Faroe"
    },
    {
        "timezoneName": "(UTC +0) Atlantic/Madeira",
        "timezonekey": "Atlantic/Madeira"
    },
    {
        "timezoneName": "(UTC +0) Atlantic/Reykjavik",
        "timezonekey": "Atlantic/Reykjavik"
    },
    {
        "timezoneName": "(UTC +0) Europe/Dublin",
        "timezonekey": "Europe/Dublin"
    },
    {
        "timezoneName": "(UTC +0) Europe/Lisbon",
        "timezonekey": "Europe/Lisbon"
    },
    {
        "timezoneName": "(UTC +0) Europe/London",
        "timezonekey": "Europe/London"
    },
    {
        "timezoneName": "(UTC +0) Etc/UTC",
        "timezonekey": "Etc/UTC"
    },
    {
        "timezoneName": "(UTC +1) Africa/Algiers",
        "timezonekey": "Africa/Algiers"
    },
    {
        "timezoneName": "(UTC +1) Africa/Casablanca",
        "timezonekey": "Africa/Casablanca"
    },
    {
        "timezoneName": "(UTC +1) Africa/Ceuta",
        "timezonekey": "Africa/Ceuta"
    },
    {
        "timezoneName": "(UTC +1) Africa/El Aaiun",
        "timezonekey": "Africa/El_Aaiun"
    },
    {
        "timezoneName": "(UTC +1) Africa/Lagos",
        "timezonekey": "Africa/Lagos"
    },
    {
        "timezoneName": "(UTC +1) Africa/Ndjamena",
        "timezonekey": "Africa/Ndjamena"
    },
    {
        "timezoneName": "(UTC +1) Africa/Tunis",
        "timezonekey": "Africa/Tunis"
    },
    {
        "timezoneName": "(UTC +1) Europe/Amsterdam",
        "timezonekey": "Europe/Amsterdam"
    },
    {
        "timezoneName": "(UTC +1) Europe/Andorra",
        "timezonekey": "Europe/Andorra"
    },
    {
        "timezoneName": "(UTC +1) Europe/Belgrade",
        "timezonekey": "Europe/Belgrade"
    },
    {
        "timezoneName": "(UTC +1) Europe/Berlin",
        "timezonekey": "Europe/Berlin"
    },
    {
        "timezoneName": "(UTC +1) Europe/Brussels",
        "timezonekey": "Europe/Brussels"
    },
    {
        "timezoneName": "(UTC +1) Europe/Budapest",
        "timezonekey": "Europe/Budapest"
    },
    {
        "timezoneName": "(UTC +1) Europe/Copenhagen",
        "timezonekey": "Europe/Copenhagen"
    },
    {
        "timezoneName": "(UTC +1) Europe/Gibraltar",
        "timezonekey": "Europe/Gibraltar"
    },
    {
        "timezoneName": "(UTC +1) Europe/Luxembourg",
        "timezonekey": "Europe/Luxembourg"
    },
    {
        "timezoneName": "(UTC +1) Europe/Madrid",
        "timezonekey": "Europe/Madrid"
    },
    {
        "timezoneName": "(UTC +1) Europe/Monaco",
        "timezonekey": "Europe/Monaco"
    },
    {
        "timezoneName": "(UTC +1) Europe/Oslo",
        "timezonekey": "Europe/Oslo"
    },
    {
        "timezoneName": "(UTC +1) Europe/Paris",
        "timezonekey": "Europe/Paris"
    },
    {
        "timezoneName": "(UTC +1) Europe/Prague",
        "timezonekey": "Europe/Prague"
    },
    {
        "timezoneName": "(UTC +1) Europe/Rome",
        "timezonekey": "Europe/Rome"
    },
    {
        "timezoneName": "(UTC +1) Europe/Stockholm",
        "timezonekey": "Europe/Stockholm"
    },
    {
        "timezoneName": "(UTC +1) Europe/Tirane",
        "timezonekey": "Europe/Tirane"
    },
    {
        "timezoneName": "(UTC +1) Europe/Vienna",
        "timezonekey": "Europe/Vienna"
    },
    {
        "timezoneName": "(UTC +1) Europe/Warsaw",
        "timezonekey": "Europe/Warsaw"
    },
    {
        "timezoneName": "(UTC +1) Europe/Zurich",
        "timezonekey": "Europe/Zurich"
    },
    // {
    //     "timezoneName": "(UTC +1) WET",
    //     "timezonekey": "WET"
    // },
    {
        "timezoneName": "(UTC +2) Asia/Amman",
        "timezonekey": "Asia/Amman"
    },
    {
        "timezoneName": "(UTC +2) Asia/Beirut",
        "timezonekey": "Asia/Beirut"
    },
    {
        "timezoneName": "(UTC +2) Asia/Damascus",
        "timezonekey": "Asia/Damascus"
    },
    {
        "timezoneName": "(UTC +3) Asia/Famagusta",
        "timezonekey": "Asia/Famagusta"
    },
    {
        "timezoneName": "(UTC +2) Asia/Gaza",
        "timezonekey": "Asia/Gaza"
    },
    {
        "timezoneName": "(UTC +2) Asia/Hebron",
        "timezonekey": "Asia/Hebron"
    },
    {
        "timezoneName": "(UTC +2) Asia/Jerusalem",
        "timezonekey": "Asia/Jerusalem"
    },
    {
        "timezoneName": "(UTC +2) Asia/Nicosia",
        "timezonekey": "Asia/Nicosia"
    },
    {
        "timezoneName": "(UTC +2) Africa/Cairo",
        "timezonekey": "Africa/Cairo"
    },
    {
        "timezoneName": "(UTC +2) Africa/Johannesburg",
        "timezonekey": "Africa/Johannesburg"
    },
    {
        "timezoneName": "(UTC +2) Africa/Khartoum",
        "timezonekey": "Africa/Khartoum"
    },
    {
        "timezoneName": "(UTC +2) Africa/Maputo",
        "timezonekey": "Africa/Maputo"
    },
    {
        "timezoneName": "(UTC +2) Africa/Tripoli",
        "timezonekey": "Africa/Tripoli"
    },
    {
        "timezoneName": "(UTC +2) Africa/Windhoek",
        "timezonekey": "Africa/Windhoek"
    },
    // {
    //     "timezoneName": "(UTC +2) CET",
    //     "timezonekey": "CET"
    // },
    {
        "timezoneName": "(UTC +2) Europe/Athens",
        "timezonekey": "Europe/Athens"
    },
    {
        "timezoneName": "(UTC +2) Europe/Bucharest",
        "timezonekey": "Europe/Bucharest"
    },
    {
        "timezoneName": "(UTC +2) Europe/Chisinau",
        "timezonekey": "Europe/Chisinau"
    },
    {
        "timezoneName": "(UTC +2) Europe/Helsinki",
        "timezonekey": "Europe/Helsinki"
    },
    {
        "timezoneName": "(UTC +2) Europe/Kaliningrad",
        "timezonekey": "Europe/Kaliningrad"
    },
    {
        "timezoneName": "(UTC +2) Europe/Kiev",
        "timezonekey": "Europe/Kiev"
    },
    {
        "timezoneName": "(UTC +2) Europe/Malta",
        "timezonekey": "Europe/Malta"
    },
    {
        "timezoneName": "(UTC +2) Europe/Riga",
        "timezonekey": "Europe/Riga"
    },
    {
        "timezoneName": "(UTC +2) Europe/Sofia",
        "timezonekey": "Europe/Sofia"
    },
    {
        "timezoneName": "(UTC +2) Europe/Tallinn",
        "timezonekey": "Europe/Tallinn"
    },
    {
        "timezoneName": "(UTC +2) Europe/Uzhgorod",
        "timezonekey": "Europe/Uzhgorod"
    },
    {
        "timezoneName": "(UTC +2) Europe/Vilnius",
        "timezonekey": "Europe/Vilnius"
    },
    {
        "timezoneName": "(UTC +2) Europe/Zaporozhye",
        "timezonekey": "Europe/Zaporozhye"
    },
    // {
    //     "timezoneName": "(UTC +2) MET",
    //     "timezonekey": "MET"
    // },
    {
        "timezoneName": "(UTC +3) Africa/Juba",
        "timezonekey": "Africa/Juba"
    },
    {
        "timezoneName": "(UTC +3) Africa/Nairobi",
        "timezonekey": "Africa/Nairobi"
    },
    {
        "timezoneName": "(UTC +3) Antarctica/Syowa",
        "timezonekey": "Antarctica/Syowa"
    },
    {
        "timezoneName": "(UTC +3) Asia/Baghdad",
        "timezonekey": "Asia/Baghdad"
    },
    {
        "timezoneName": "(UTC +3) Asia/Qatar",
        "timezonekey": "Asia/Qatar"
    },
    {
        "timezoneName": "(UTC +3) Asia/Riyadh",
        "timezonekey": "Asia/Riyadh"
    },
    // {
    //     "timezoneName": "(UTC +3) EET",
    //     "timezonekey": "EET"
    // },
    {
        "timezoneName": "(UTC +3) Europe/Istanbul",
        "timezonekey": "Europe/Istanbul"
    },
    {
        "timezoneName": "(UTC +3) Europe/Kirov",
        "timezonekey": "Europe/Kirov"
    },
    {
        "timezoneName": "(UTC +3) Europe/Minsk",
        "timezonekey": "Europe/Minsk"
    },
    {
        "timezoneName": "(UTC +3) Europe/Moscow",
        "timezonekey": "Europe/Moscow"
    },
    {
        "timezoneName": "(UTC +3) Europe/Simferopol",
        "timezonekey": "Europe/Simferopol"
    },
    {
        "timezoneName": "(UTC +4) Asia/Baku",
        "timezonekey": "Asia/Baku"
    },
    {
        "timezoneName": "(UTC +4) Asia/Dubai",
        "timezonekey": "Asia/Dubai"
    },
    {
        "timezoneName": "(UTC +4) Asia/Tbilisi",
        "timezonekey": "Asia/Tbilisi"
    },
    {
        "timezoneName": "(UTC +4) Asia/Yerevan",
        "timezonekey": "Asia/Yerevan"
    },
    {
        "timezoneName": "(UTC +4) Europe/Astrakhan",
        "timezonekey": "Europe/Astrakhan"
    },
    {
        "timezoneName": "(UTC +4) Europe/Samara",
        "timezonekey": "Europe/Samara"
    },
    {
        "timezoneName": "(UTC +4) Europe/Saratov",
        "timezonekey": "Europe/Saratov"
    },
    {
        "timezoneName": "(UTC +4) Europe/Ulyanovsk",
        "timezonekey": "Europe/Ulyanovsk"
    },
    {
        "timezoneName": "(UTC +4) Europe/Volgograd",
        "timezonekey": "Europe/Volgograd"
    },
    {
        "timezoneName": "(UTC +4) Indian/Mahe",
        "timezonekey": "Indian/Mahe"
    },
    {
        "timezoneName": "(UTC +4) Indian/Mauritius",
        "timezonekey": "Indian/Mauritius"
    },
    {
        "timezoneName": "(UTC +4) Indian/Reunion",
        "timezonekey": "Indian/Reunion"
    },
    {
        "timezoneName": "(UTC +4.5) Asia/Kabul",
        "timezonekey": "Asia/Kabul"
    },
    {
        "timezoneName": "(UTC +4.5) Asia/Tehran",
        "timezonekey": "Asia/Tehran"
    },
    {
        "timezoneName": "(UTC +5) Antarctica/Mawson",
        "timezonekey": "Antarctica/Mawson"
    },
    {
        "timezoneName": "(UTC +5) Asia/Aqtau",
        "timezonekey": "Asia/Aqtau"
    },
    {
        "timezoneName": "(UTC +5) Asia/Aqtobe",
        "timezonekey": "Asia/Aqtobe"
    },
    {
        "timezoneName": "(UTC +5) Asia/Ashgabat",
        "timezonekey": "Asia/Ashgabat"
    },
    {
        "timezoneName": "(UTC +5) Asia/Atyrau",
        "timezonekey": "Asia/Atyrau"
    },
    {
        "timezoneName": "(UTC +5) Asia/Dushanbe",
        "timezonekey": "Asia/Dushanbe"
    },
    {
        "timezoneName": "(UTC +5) Asia/Karachi",
        "timezonekey": "Asia/Karachi"
    },
    {
        "timezoneName": "(UTC +5) Asia/Oral",
        "timezonekey": "Asia/Oral"
    },
    {
        "timezoneName": "(UTC +5) Asia/Qyzylorda",
        "timezonekey": "Asia/Qyzylorda"
    },
    {
        "timezoneName": "(UTC +5) Asia/Samarkand",
        "timezonekey": "Asia/Samarkand"
    },
    {
        "timezoneName": "(UTC +5) Asia/Tashkent",
        "timezonekey": "Asia/Tashkent"
    },
    {
        "timezoneName": "(UTC +5) Asia/Yekaterinburg",
        "timezonekey": "Asia/Yekaterinburg"
    },
    {
        "timezoneName": "(UTC +5) Indian/Kerguelen",
        "timezonekey": "Indian/Kerguelen"
    },
    {
        "timezoneName": "(UTC +5) Indian/Maldives",
        "timezonekey": "Indian/Maldives"
    },
    {
        "timezoneName": "(UTC +5.5) Asia/Colombo",
        "timezonekey": "Asia/Colombo"
    },
    {
        "timezoneName": "(UTC +5.5) Asia/Kolkata",
        "timezonekey": "Asia/Kolkata"
    },
    {
        "timezoneName": "(UTC +5.75) Asia/Kathmandu",
        "timezonekey": "Asia/Kathmandu"
    },
    {
        "timezoneName": "(UTC +6) Antarctica/Vostok",
        "timezonekey": "Antarctica/Vostok"
    },
    {
        "timezoneName": "(UTC +6) Asia/Almaty",
        "timezonekey": "Asia/Almaty"
    },
    {
        "timezoneName": "(UTC +6) Asia/Bishkek",
        "timezonekey": "Asia/Bishkek"
    },
    {
        "timezoneName": "(UTC +6) Asia/Dhaka",
        "timezonekey": "Asia/Dhaka"
    },
    {
        "timezoneName": "(UTC +6) Asia/Omsk",
        "timezonekey": "Asia/Omsk"
    },
    {
        "timezoneName": "(UTC +6) Asia/Qostanay",
        "timezonekey": "Asia/Qostanay"
    },
    {
        "timezoneName": "(UTC +6) Asia/Thimphu",
        "timezonekey": "Asia/Thimphu"
    },
    {
        "timezoneName": "(UTC +6) Asia/Urumqi",
        "timezonekey": "Asia/Urumqi"
    },
    {
        "timezoneName": "(UTC +6) Indian/Chagos",
        "timezonekey": "Indian/Chagos"
    },
    {
        "timezoneName": "(UTC +6.5) Asia/Rangoon",
        "timezonekey": "Asia/Rangoon"
    },
    {
        "timezoneName": "(UTC +6.5) Indian/Cocos",
        "timezonekey": "Indian/Cocos"
    },
    {
        "timezoneName": "(UTC +7) Antarctica/Davis",
        "timezonekey": "Antarctica/Davis"
    },
    {
        "timezoneName": "(UTC +7) Asia/Bangkok",
        "timezonekey": "Asia/Bangkok"
    },
    {
        "timezoneName": "(UTC +7) Asia/Barnaul",
        "timezonekey": "Asia/Barnaul"
    },
    {
        "timezoneName": "(UTC +7) Asia/Ho Chi_Minh",
        "timezonekey": "Asia/Ho_Chi_Minh"
    },
    {
        "timezoneName": "(UTC +7) Asia/Hovd",
        "timezonekey": "Asia/Hovd"
    },
    {
        "timezoneName": "(UTC +7) Asia/Jakarta",
        "timezonekey": "Asia/Jakarta"
    },
    {
        "timezoneName": "(UTC +7) Asia/Krasnoyarsk",
        "timezonekey": "Asia/Krasnoyarsk"
    },
    {
        "timezoneName": "(UTC +7) Asia/Novokuznetsk",
        "timezonekey": "Asia/Novokuznetsk"
    },
    {
        "timezoneName": "(UTC +7) Asia/Novosibirsk",
        "timezonekey": "Asia/Novosibirsk"
    },
    {
        "timezoneName": "(UTC +7) Asia/Pontianak",
        "timezonekey": "Asia/Pontianak"
    },
    {
        "timezoneName": "(UTC +7) Asia/Tomsk",
        "timezonekey": "Asia/Tomsk"
    },
    {
        "timezoneName": "(UTC +7) Indian/Christmas",
        "timezonekey": "Indian/Christmas"
    },
    {
        "timezoneName": "(UTC +8) Antarctica/Casey",
        "timezonekey": "Antarctica/Casey"
    },
    {
        "timezoneName": "(UTC +8) Asia/Brunei",
        "timezonekey": "Asia/Brunei"
    },
    {
        "timezoneName": "(UTC +8) Asia/Choibalsan",
        "timezonekey": "Asia/Choibalsan"
    },
    {
        "timezoneName": "(UTC +8) Asia/Hong Kong",
        "timezonekey": "Asia/Hong_Kong"
    },
    {
        "timezoneName": "(UTC +8) Asia/Irkutsk",
        "timezonekey": "Asia/Irkutsk"
    },
    {
        "timezoneName": "(UTC +8) Asia/Kuala Lumpur",
        "timezonekey": "Asia/Kuala_Lumpur"
    },
    {
        "timezoneName": "(UTC +8) Asia/Kuching",
        "timezonekey": "Asia/Kuching"
    },
    {
        "timezoneName": "(UTC +8) Asia/Macau",
        "timezonekey": "Asia/Macau"
    },
    {
        "timezoneName": "(UTC +8) Asia/Makassar",
        "timezonekey": "Asia/Makassar"
    },
    {
        "timezoneName": "(UTC +8) Asia/Manila",
        "timezonekey": "Asia/Manila"
    },
    {
        "timezoneName": "(UTC +8) Asia/Shanghai",
        "timezonekey": "Asia/Shanghai"
    },
    {
        "timezoneName": "(UTC +8) Asia/Singapore",
        "timezonekey": "Asia/Singapore"
    },
    {
        "timezoneName": "(UTC +8) Asia/Taipei",
        "timezonekey": "Asia/Taipei"
    },
    {
        "timezoneName": "(UTC +8) Asia/Ulaanbaatar",
        "timezonekey": "Asia/Ulaanbaatar"
    },
    {
        "timezoneName": "(UTC +8) Australia/Perth",
        "timezonekey": "Australia/Perth"
    },
    {
        "timezoneName": "(UTC +8.75) Australia/Eucla",
        "timezonekey": "Australia/Eucla"
    },
    {
        "timezoneName": "(UTC +9) Asia/Chita",
        "timezonekey": "Asia/Chita"
    },
    {
        "timezoneName": "(UTC +9) Asia/Dili",
        "timezonekey": "Asia/Dili"
    },
    {
        "timezoneName": "(UTC +9) Asia/Jayapura",
        "timezonekey": "Asia/Jayapura"
    },
    {
        "timezoneName": "(UTC +9) Asia/Khandyga",
        "timezonekey": "Asia/Khandyga"
    },
    {
        "timezoneName": "(UTC +9) Asia/Pyongyang",
        "timezonekey": "Asia/Pyongyang"
    },
    {
        "timezoneName": "(UTC +9) Asia/Seoul",
        "timezonekey": "Asia/Seoul"
    },
    {
        "timezoneName": "(UTC +9) Asia/Tokyo",
        "timezonekey": "Asia/Tokyo"
    },
    {
        "timezoneName": "(UTC +9) Asia/Yakutsk",
        "timezonekey": "Asia/Yakutsk"
    },
    {
        "timezoneName": "(UTC +9) Pacific/Palau",
        "timezonekey": "Pacific/Palau"
    },
    {
        "timezoneName": "(UTC +9.5) Australia/Broken Hill",
        "timezonekey": "Australia/Broken_Hill"
    },
    {
        "timezoneName": "(UTC +9.5) Australia/Darwin",
        "timezonekey": "Australia/Darwin"
    },
    {
        "timezoneName": "(UTC +10) Antarctica/DumontDUrville",
        "timezonekey": "Antarctica/DumontDUrville"
    },
    {
        "timezoneName": "(UTC +10) Asia/Ust-Nera",
        "timezonekey": "Asia/Ust-Nera"
    },
    {
        "timezoneName": "(UTC +10) Asia/Vladivostok",
        "timezonekey": "Asia/Vladivostok"
    },
    {
        "timezoneName": "(UTC +10) Australia/Brisbane",
        "timezonekey": "Australia/Brisbane"
    },
    {
        "timezoneName": "(UTC +10) Australia/Lindeman",
        "timezonekey": "Australia/Lindeman"
    },
    {
        "timezoneName": "(UTC +10) Pacific/Chuuk",
        "timezonekey": "Pacific/Chuuk"
    },
    {
        "timezoneName": "(UTC +10) Pacific/Guam",
        "timezonekey": "Pacific/Guam"
    },
    {
        "timezoneName": "(UTC +10) Pacific/Port Moresby",
        "timezonekey": "Pacific/Port_Moresby"
    },
    {
        "timezoneName": "(UTC +10.5) Australia/Adelaide",
        "timezonekey": "Australia/Adelaide"
    },
    {
        "timezoneName": "(UTC +11) Antarctica/Macquarie",
        "timezonekey": "Antarctica/Macquarie"
    },
    {
        "timezoneName": "(UTC +11) Asia/Magadan",
        "timezonekey": "Asia/Magadan"
    },
    {
        "timezoneName": "(UTC +11) Asia/Sakhalin",
        "timezonekey": "Asia/Sakhalin"
    },
    {
        "timezoneName": "(UTC +11) Asia/Srednekolymsk",
        "timezonekey": "Asia/Srednekolymsk"
    },
    {
        "timezoneName": "(UTC +11) Australia/Currie",
        "timezonekey": "Australia/Currie"
    },
    {
        "timezoneName": "(UTC +11) Australia/Hobart",
        "timezonekey": "Australia/Hobart"
    },
    {
        "timezoneName": "(UTC +11) Australia/Lord Howe",
        "timezonekey": "Australia/Lord_Howe"
    },
    {
        "timezoneName": "(UTC +11) Australia/Melbourne",
        "timezonekey": "Australia/Melbourne"
    },
    {
        "timezoneName": "(UTC +11) Australia/Sydney",
        "timezonekey": "Australia/Sydney"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Bougainville",
        "timezonekey": "Pacific/Bougainville"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Efate",
        "timezonekey": "Pacific/Efate"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Guadalcanal",
        "timezonekey": "Pacific/Guadalcanal"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Kosrae",
        "timezonekey": "Pacific/Kosrae"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Norfolk",
        "timezonekey": "Pacific/Norfolk"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Noumea",
        "timezonekey": "Pacific/Noumea"
    },
    {
        "timezoneName": "(UTC +11) Pacific/Pohnpei",
        "timezonekey": "Pacific/Pohnpei"
    },
    {
        "timezoneName": "(UTC +12) Asia/Anadyr",
        "timezonekey": "Asia/Anadyr"
    },
    {
        "timezoneName": "(UTC +12) Asia/Kamchatka",
        "timezonekey": "Asia/Kamchatka"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Funafuti",
        "timezonekey": "Pacific/Funafuti"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Kwajalein",
        "timezonekey": "Pacific/Kwajalein"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Majuro",
        "timezonekey": "Pacific/Majuro"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Nauru",
        "timezonekey": "Pacific/Nauru"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Tarawa",
        "timezonekey": "Pacific/Tarawa"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Wake",
        "timezonekey": "Pacific/Wake"
    },
    {
        "timezoneName": "(UTC +12) Pacific/Wallis",
        "timezonekey": "Pacific/Wallis"
    },
    {
        "timezoneName": "(UTC +12.75) Pacific/Chatham",
        "timezonekey": "Pacific/Chatham"
    },
    {
        "timezoneName": "(UTC +13) Pacific/Auckland",
        "timezonekey": "Pacific/Auckland"
    },
    {
        "timezoneName": "(UTC +13) Pacific/Enderbury",
        "timezonekey": "Pacific/Enderbury"
    },
    {
        "timezoneName": "(UTC +13) Pacific/Fakaofo",
        "timezonekey": "Pacific/Fakaofo"
    },
    {
        "timezoneName": "(UTC +13) Pacific/Fiji",
        "timezonekey": "Pacific/Fiji"
    },
    {
        "timezoneName": "(UTC +13) Pacific/Tongatapu",
        "timezonekey": "Pacific/Tongatapu"
    },
    {
        "timezoneName": "(UTC +14) Pacific/Apia",
        "timezonekey": "Pacific/Apia"
    },
    {
        "timezoneName": "(UTC +14) Pacific/Kiritimati",
        "timezonekey": "Pacific/Kiritimati"
    }
];