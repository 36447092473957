import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { CommunicationService } from '../shared/services/communication.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MustMatch } from '../shared/validators/must-match.validators';
import { CommonService } from '../shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { environment } from '../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  user;
  btnSpinner = false;
  changePasswordSpinner = false;
  changePasswordForm;
  submitted = false;
  languageList = environment.languageList;
  selectedLanguage = 'en';
  userInfoForm: FormGroup;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  userFormSubmitted = false;

  constructor(
    private userService: UserService,
    private communicationService: CommunicationService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService) { }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.userInfoForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl({value: null, disabled: true}, [Validators.required, Validators.email]),
      phone: new FormControl(null, Validators.required),
      language: new FormControl(this.languageList[0].key, Validators.required)
    });

    this.userService.getUser()
      .subscribe((user: any) => {
        this.user = user.data;
        this.userInfoForm.reset();
          const phoneObject = {
            countryCode: this.user.countryCode,
            dialCode: this.user.dialCode,
            number: String(this.user.phone),
          };
          this.userInfoForm = new FormGroup({
            firstName: new FormControl(this.user.firstName, Validators.required),
            lastName: new FormControl(this.user.lastName, Validators.required),
            email: new FormControl({value: this.user.email, disabled: true}, [Validators.required, Validators.email]),
            phone: new FormControl(phoneObject, Validators.required),
            language: new FormControl(this.user.language || 'en', Validators.required),
          });
      }, err => {
        const cookieTrail = {
          screenName: 'My Account',
          action: 'Get User',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        if(this.user) {
          this.user.language = res;
          this.f1.language.setValue(this.languageList[this.languageList.findIndex(ele => ele.key === res)].key);
        }
      });
  }

  get f() { return this.changePasswordForm.controls; }

  get f1() { return this.userInfoForm.controls; }

  isSpace(evt, field) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (field === 'fName') {
      if (charCode === 32 && (!this.userInfoForm.get('firstName').value && this.userInfoForm.get('firstName').value === '')) {
        return false;
      }
    } else if (field === 'lName') {
      if (charCode === 32 && (!this.userInfoForm.get('lastName').value && this.userInfoForm.get('lastName').value === '')) {
        return false;
      }
    }

    return true;
  }

  updateUser() {

    if (!(this.user.firstName && this.user.firstName.trim()) || !(this.user.lastName && this.user.lastName.trim())
      || !(this.user.phone)) {
      return;
    }

    this.userFormSubmitted = true;

    if (this.userInfoForm.invalid) {
      return;
    }

    const phoneObject = this.userInfoForm.get('phone').value;
    const userData = {
      firstName: this.userInfoForm.get('firstName').value,
      lastName: this.userInfoForm.get('lastName').value,
      // phone: this.userInfoForm.get('phone').value
      phone: phoneObject ? phoneObject.number.replace(/[^\w\s]/gi, '').split(" ").join("") : '',
      countryCode: phoneObject ? phoneObject.countryCode : '',
      dialCode: phoneObject ? phoneObject.dialCode : '',
      language: this.userInfoForm.get('language').value
    };

    this.btnSpinner = true;
    const cookieTrail = {
      screenName: 'My Account',
      action: 'Update User',
      body: userData
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    this.userService.editUser(userData, this.user._id)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.translate.use(res.language);
        localStorage.setItem('language', res.language);
        this.communicationService.setLanguage(res.language);
        this.communicationService.setUserName(res.firstName + ' ' + res.lastName);
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'Account Updated Successfully!' });
      }, err => {
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  openModal() {
    this.closeModal();
    ($('#changePassword') as any).modal({ backdrop: 'static', keyboard: false });
  }

  closeModal() {
    this.submitted = false;
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  changePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.changePasswordSpinner = true;

    const data = {
      currentPassword: this.changePasswordForm.get('currentPassword').value,
      verifyPassword: this.changePasswordForm.get('password').value,
      newPassword: this.changePasswordForm.get('confirmPassword').value
    };

    const cookieTrail = {
      screenName: 'My Account',
      action: 'Update User',
      body: data
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.userService.changePassword(data)
      .subscribe((res: any) => {
        this.changePasswordSpinner = false;
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'Password Changed Successfully!' });
        this.submitted = false;
        $('#changePassword').modal('hide');
        this.closeModal();
      }, err => {
        this.changePasswordSpinner = false;
        if (err.error.message.includes('The password must')) {
          this.communicationService.openAlert({ alertType: 'error', alertMsg: this.commonService.passwordOptimize(err.error.message) });
        } else {
          if(err.error.message === 'Current password is incorrect') {
            this.communicationService.openAlert({ alertType: 'error', alertMsg: 'Current Password is incorrect!' });
          } else {
            this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          }
        }
      });
  }

}
