import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { MousePosition, PUK2 } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

@Component({
    selector: 'app-puk2',
    templateUrl: './puk2.component.html',
    styleUrls: ['./puk2.component.scss']
})
export class Puk2Component extends ShapeComponent implements OnInit {

    formFields = [
        {
            name: 'name',
            label: 'Name:',
            type: 'input',
            inputType: 'text',
        },
        {
            name: 'x',
            label: 'X:',
            type: 'input',
            inputType: 'text',
        },
        {
            name: 'y',
            label: 'Y:',
            type: 'input',
            inputType: 'text',
        },
        {
            name: 'value',
            label: 'Text:',
            type: 'input',
            inputType: 'text',
        },
    ];

    constructor() {
        super();
        //   console.log('TextComponent constructor');
        this.shape = new PUK2();
        this.shapeType = ShapeType.PIN1;
    }

    ngOnInit() {
        //   console.log('TextComponent ngOnInit');
    }

    updateShapeProperties(value: any) {
        if (this.shape instanceof PUK2) {
            this.shape.shapeProperties.name = value.name;
            this.shape.value = value.value;
            this.shape.originX = value.x;
            this.shape.originY = value.y;
        }
    }

    setStyles() {
        const styles = {
            fill: this.shape.shapeProperties.strokeColor
        };
        return styles;
    }

    startDrawing(beginPosition: MousePosition): void {
        if (this.shape instanceof PUK2) {
            this.shape.originX = beginPosition.x;
            this.shape.originY = beginPosition.y;
            this.shape['font-family'] = 'Arial';
            this.shape['font-size'] = 16;
            this.shape.fill = 'black';
        }
        //   console.log('PIN1 startDrawing at ', beginPosition, ', ', this.shape);
    }
}
