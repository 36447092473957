<div class="dark-content" id="mainContainer" *ngIf="currentUrl && ((currentUrl === '/profile') || (currentUrl === '/user-management') || (currentUrl === '/manage-blacklist/auto-blacklist')
|| (currentUrl === '/manage-blacklist/blacklist-eid' || (currentUrl === '/manage-group-profile') || (currentUrl.includes('/report')) || (currentUrl.includes('/api')) || (currentUrl === '/theme')
|| (currentUrl === '/page-not-found') || (currentUrl === '/my-account') || (currentUrl === '/support') || (currentUrl === '/notifications') || (currentUrl === '/campaign-editor') || (currentUrl === '/governance-report')
|| (currentUrl.includes('/access-management') || (currentUrl === '/dashboard') || (currentUrl.includes('/customer')) || (currentUrl === '/dashboard-user') || (currentUrl === '/email-setup')
|| (currentUrl === '/manage-group-profile/profile-inventory') || (currentUrl === '/login-theme') || (currentUrl === '/dashboard-refresh-time'))))">
    <div class="main-container bg-inner-app" *ngIf="readyToLoad">
        <div class="sidebar bg-sidebar toggle-sidebar-on-powered" id="sidebar-toggle">
            <app-sidebar></app-sidebar>
        </div>
        <div class="show-content w-100 bg-inner-app">
            <app-navbar></app-navbar>
            <div class="commom-console">
                <router-outlet></router-outlet>
            </div>
            <app-alert></app-alert>
        </div>
    </div>
    <ng-container *ngIf="!readyToLoad">
        <app-alert></app-alert>
    </ng-container>

    <div *ngIf="readyToLoad">
        <app-profile-troubleshooting></app-profile-troubleshooting>
    </div>
</div>

<div *ngIf="currentUrl && (currentUrl === '/forgot-password' || currentUrl === '/signin' || currentUrl === '/reset-password' || currentUrl === '/smartjac/signin' || currentUrl === '/smartjac/forgot-password' || currentUrl === '/smartjac/reset-password')">
    <app-alert></app-alert>
    <router-outlet></router-outlet>
</div>

<div class="modal fade" id="inProgressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content t-modal">
            <div class="modal-body text-center p-4">
                <button type="button" class="close ml-auto t-color" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <img src="assets/images/icons/problem.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p>This feature is under development.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="envAccess" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content t-modal">
            <div class="modal-body text-center p-4">
                You do not have access of any environment. <br /> Please contact administrator.
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 outline" style="background-color: #1DBC8E; width: 65px;" (click)="signout()">OK</button>
            </div>
        </div>
    </div>
</div>