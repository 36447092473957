<section class="new-auth-section" *ngIf="load === 'default'">
    <section class="bg-onboarding">
        <div class="signin-page" data-theme='dark'>
            <div class="signin-inner" data-theme='dark' [ngClass]="{'signin-inner-domain': !subdomain}">
                <div class="row" *ngIf="apiResponse" [ngClass]="{'row-domain': !subdomain}">
                    <div class="col-lg-6 d-flex flex-column left-signin-inner" *ngIf="subdomain">
                        <div *ngIf="loginScreenObj && (loginScreenObj.length === 0)">
                            <img class="auth-trance-logo" src="assets/images/Subtraction.png" alt="">
                        </div>
                        <div class="logo mt-auto mx-auto pb-5">
                            <img class="img-logo" src="{{logoUrl}}">
                        </div>
                        <div class="ml-3 mb-3 t-powered-parent">
                            <span class="mr-2">{{ "Powered by" | translate}}</span> <img class="t-powered-logo" src="https://s3.amazonaws.com/subdomain-logos.10ttech.com/10t-new.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-7 d-flex flex-column left-signin-inner px-0" *ngIf="!subdomain" [ngClass]="{'left-signin-inner-domain': !subdomain}">
                        <div id="signInCarousel" class="carousel slide" data-ride="carousel" data-interval="4000">
                          
                            <!-- The slideshow -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="img-fluid" src="/assets/images/signIn/tent-carousel-one.png" alt="">
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid" src="/assets/images/signIn/tent-carousal-two.png" alt="Chicago">
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid" src="/assets/images/signIn/tent-carousel-three.png" alt="New York">
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid" src="/assets/images/signIn/tent-carousel-four.png" alt="New York">
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid" src="/assets/images/signIn/tent-carousel-five.png" alt="New York">
                                </div>
                            </div>

                            <a class="carousel-control-prev" href="#signInCarousel" data-slide="prev">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="87" height="87" viewBox="0 0 87 87">
                                        <defs>
                                          <filter id="Ellipse_270" x="0" y="0" width="87" height="87" filterUnits="userSpaceOnUse">
                                            <feOffset input="SourceAlpha"/>
                                            <feGaussianBlur stdDeviation="7.5" result="blur"/>
                                            <feFlood flood-opacity="0.29"/>
                                            <feComposite operator="in" in2="blur"/>
                                            <feComposite in="SourceGraphic"/>
                                          </filter>
                                        </defs>
                                        <g id="Group_1022" data-name="Group 1022" transform="translate(-251.5 -496.5)">
                                          <g transform="matrix(1, 0, 0, 1, 251.5, 496.5)" filter="url(#Ellipse_270)">
                                            <circle id="Ellipse_270-2" data-name="Ellipse 270" cx="21" cy="21" r="21" transform="translate(22.5 22.5)" fill="#fff" opacity="0.648"/>
                                          </g>
                                          <g id="arrow-down-sign-to-navigate_1_" data-name="arrow-down-sign-to-navigate (1)" transform="translate(395.862 531.716) rotate(90)">
                                            <path id="Path_785" data-name="Path 785" d="M8.283,106.583a1.157,1.157,0,0,1-.82-.34L.341,99.121A1.16,1.16,0,1,1,1.981,97.48l6.3,6.3,6.3-6.3a1.16,1.16,0,0,1,1.64,1.641L9.1,106.244A1.157,1.157,0,0,1,8.283,106.583Z" transform="translate(0)"/>
                                          </g>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                            <a class="carousel-control-next" href="#signInCarousel" data-slide="next">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="87" height="87" viewBox="0 0 87 87">
                                    <defs>
                                      <filter id="Ellipse_270" x="0" y="0" width="87" height="87" filterUnits="userSpaceOnUse">
                                        <feOffset input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="7.5" result="blur"/>
                                        <feFlood flood-opacity="0.29"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                      </filter>
                                    </defs>
                                    <g id="Group_1023" data-name="Group 1023" transform="translate(338.5 583.5) rotate(180)">
                                      <g transform="matrix(-1, 0, 0, -1, 338.5, 583.5)" filter="url(#Ellipse_270)">
                                        <circle id="Ellipse_270-2" data-name="Ellipse 270" cx="21" cy="21" r="21" transform="translate(64.5 64.5) rotate(180)" fill="#fff" opacity="0.648"/>
                                      </g>
                                      <g id="arrow-down-sign-to-navigate_1_" data-name="arrow-down-sign-to-navigate (1)" transform="translate(395.862 531.716) rotate(90)">
                                        <path id="Path_785" data-name="Path 785" d="M8.283,106.583a1.157,1.157,0,0,1-.82-.34L.341,99.121A1.16,1.16,0,1,1,1.981,97.48l6.3,6.3,6.3-6.3a1.16,1.16,0,0,1,1.64,1.641L9.1,106.244A1.157,1.157,0,0,1,8.283,106.583Z" transform="translate(0)"/>
                                      </g>
                                    </g>
                                </svg>
                              </span>
                            </a>
                          
                        </div>
                    </div>
                    <div class="right-signin-inner" [ngClass]="{'col-lg-6': subdomain, 'col-lg-5': !subdomain}">
                        <div class="d-flex justify-content-end mt-3 mr-3 main-lang-dropdown">
                            <select class="px-2 t-input auth-lang-dropdown" style="border-radius: 30px; height: 35px; outline: none;" [(ngModel)]="langSelected" (ngModelChange)="changeLanguage()">
                                <option value="en">ENG</option>
                                <option value="ru">RU</option>
                                <option value="pt">PT</option>
                                <option value="zh">ZH</option>
                                <option value="sv">SV</option>
                                <option value="bm">BM</option>
                                <option value="mr">MR</option>
                              </select>
                            <i class="fa fa-angle-down "></i>
                        </div>
                        <div class="createaccount-form" [ngClass]="{'createaccount-form-domain': !subdomain}">
                            <div class="sign-in-logo-div" *ngIf="!subdomain">
                                <img class="img-fluid" src="/assets/images/signIn/tent-logo-signin.png" alt="">
                            </div>
                            <form [formGroup]="signinForm" (ngSubmit)="onSignin()">
                                <div>
                                    <p class="sign-in-heading montserrat-font-bold" *ngIf="!subdomain">Sign In</p>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="mb-0" [ngClass]="{'montserrat-font-regular': !subdomain}">{{ "Email Address" | translate}}</label>
                                        <!-- [ngClass]="{'submitted': submitted}" -->
                                        <input type="email" [ngClass]="{'submitted': submitted, 'montserrat-font-regular': !subdomain}" class="form-control onboarding-form-input" formControlName="email">
                                        <div>
                                            <p class="text-danger text-center" *ngIf="err">{{ "The username or password you have entered is incorrect." | translate}}</p>
                                            <span class="help-block ng-invalid text-danger" *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)">
                                          <span class="small" *ngIf="f.email.errors.required" [ngClass]="{'montserrat-font-regular': !subdomain}">
                                            {{ "Email is required!" | translate}}
                                          </span>
                                            </span>
                                            <span class="help-block ng-invalid text-danger" *ngIf="f.email.errors && submitted">
                                          <span class="small" *ngIf="f.email.errors.email" [ngClass]="{'montserrat-font-regular': !subdomain}">
                                              {{ "Enter valid email!" | translate}}
                                          </span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="mb-0" [ngClass]="{'montserrat-font-regular': !subdomain}">{{ "Password" | translate}}</label>
                                        <input type="password" [ngClass]="{'submitted': submitted, 'montserrat-font-regular': !subdomain}" class="form-control onboarding-form-input" formControlName="password">
                                        <p class="help-block ng-invalid text-danger mb-0" *ngIf="(!f.password.valid && !f.password.pristine) || (f.password.errors && submitted)">
                                            <span class="small" *ngIf="f.password.errors.required" [ngClass]="{'montserrat-font-regular': !subdomain}">
                                                {{ "Password is required!" | translate}}
                                              </span>
                                        </p>
                                        <label for="forget-password" class="mt-3"> <a href="#" routerLink="/forgot-password" class="forget-pass-link" [ngClass]="{'montserrat-font-regular': !subdomain}">{{ "Forgot Password?" | translate}}</a>
                                          </label>
                                        <button class="btn btn-block signin-inner-btn outline" type="submit" [ngClass]="{'montserrat-font-regular': !subdomain , 'signin-btn-domain': !subdomain}"> {{ "Sign In" | translate}}</button>
                                    </div>
                                    <p class="text-center signin-bottom-text" *ngIf="!subdomain" [ngClass]="{'montserrat-font-regular': !subdomain}">By continuing, you acknowledge that you have read, understood and agree to our terms and conditions</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<div class="smartjac-main" *ngIf="load === 'smartjac'">
    <section class="new-auth-section">
        <section class="bg-onboarding">
            <div class="signin-page" data-theme='dark'>
                <div class="signin-inner" data-theme='dark'>
                    <div class="row">
                        <div class="col-lg-6 d-flex flex-column left-signin-inner">
                            <div class="logo mt-auto mx-auto pb-5">
                                <img class="img-logo" src="../../../assets/images/smartjac/logo/SquareLogo.png">
                            </div>
                            <!-- <div class="logo mt-auto mx-auto pb-5">
                                <img class="img-logo" src="../../assets/images/10T-Logo-Test3.png">
                            </div> -->
                            <div class="ml-3 mb-3 t-powered-parent">
                                <span class="mr-2">{{ "Powered by" | translate}}</span> <img class="t-powered-logo" src="assets/images/powered-by-light.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6 right-signin-inner">
                            <div>
                                <form [formGroup]="signinForm" (ngSubmit)="onSignin()">
                                    <div class="createaccount-form">

                                        <div class="form-group mb-4">
                                            <label for="exampleInputEmail1" class="mb-0">{{ "Email address" | translate}}</label>
                                            <input type="email" [ngClass]="{'submitted': submitted}" class="form-control onboarding-form-input" formControlName="email" placeholder="{{'Email' | translate}}">
                                            <div>
                                                <p class="text-danger text-center" *ngIf="err">{{ "The username or password you have entered is incorrect." | translate}}</p>
                                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)">
                                              <span class="small" *ngIf="f.email.errors.required">
                                                {{ "Email is required!" | translate}}
                                              </span>
                                                </span>
                                                <span class="help-block ng-invalid text-danger" *ngIf="f.email.errors && submitted">
                                              <span class="small" *ngIf="f.email.errors.email">
                                                  {{ "Enter valid email!" | translate}}
                                              </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="exampleInputEmail1" class="mb-0">{{ "Password" | translate}}</label>
                                            <input type="password" [ngClass]="{'submitted': submitted}" class="form-control onboarding-form-input" formControlName="password" placeholder="{{'Password' | translate}}">
                                            <p class="help-block ng-invalid text-danger mb-0" *ngIf="(!f.password.valid && !f.password.pristine) || (f.password.errors && submitted)">
                                                <span class="small" *ngIf="f.password.errors.required">
                                                    {{ "Password is required!" | translate}}
                                                  </span>
                                            </p>
                                            <label for="forget-password" class="mt-3"> <a href="#" routerLink="/forgot-password" class="forget-pass-link">{{ "Forgot password?" | translate}}</a>
                                              </label>
                                            <button class="btn btn-block signin-inner-btn outline" type="submit"> {{ "Sign In" | translate}}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</div>

<section class="new-auth-section" *ngIf="load === 'tunetalk'">
    <section class="bg-onboarding">
        <div class="signin-page tunetalk-signin" data-theme='dark'>
            <div class="signin-inner" data-theme='dark'>
                <div class="row">
                    <div class="col-lg-6 d-flex flex-column left-signin-inner">
                        <div>
                            <img src="assets/images/tunetalk-logo/tune-talk-background.png" alt="">
                        </div>
                        <div class="logo mt-auto mx-auto pb-5">
                            <img class="tune-logo" src="../../assets/images/tunetalk-logo/tune-talk-logo.png">
                        </div>
                        <div class="ml-3 mb-3 t-powered-parent">
                            <span class="mr-2">{{ "Powered by" | translate}}</span> <img class="t-powered-logo" src="assets/images/powered-by-dark.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6 tune-right-signin">
                        <div class="d-flex justify-content-end mt-3 mr-3 main-lang-dropdown">
                            <select class="px-2 t-input auth-lang-dropdown tune-forget-select" style="border-radius: 30px; height: 35px; outline: none;" [(ngModel)]="langSelected" (ngModelChange)="changeLanguage()">
                                <option value="en">ENG</option>
                                <option value="ru">RU</option>
                                <option value="mr">MR</option>
                              </select>
                            <i class="fa fa-angle-down "></i>
                        </div>
                        <div>
                            <form [formGroup]="signinForm" (ngSubmit)="onSignin()">
                                <div class="createaccount-form">

                                    <div class="form-group mb-4">
                                        <label for="exampleInputEmail1" class="mb-0">{{ "Email address" | translate}}</label>
                                        <!-- [ngClass]="{'submitted': submitted}" -->
                                        <input type="email" [ngClass]="{'submitted': submitted}" class="form-control onboarding-form-input tune-talk-onboard" formControlName="email" placeholder="{{'Email' | translate}}">
                                        <div>
                                            <p class="text-danger text-center" *ngIf="err">{{ "The username or password you have entered is incorrect." | translate}}</p>
                                            <span class="help-block ng-invalid text-danger" *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)">
                                          <span class="small" *ngIf="f.email.errors.required">
                                            {{ "Email is required!" | translate}}
                                          </span>
                                            </span>
                                            <span class="help-block ng-invalid text-danger" *ngIf="f.email.errors && submitted">
                                          <span class="small" *ngIf="f.email.errors.email">
                                              {{ "Enter valid email!" | translate}}
                                          </span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="mb-0">{{ "Password" | translate}}</label>
                                        <input type="password" [ngClass]="{'submitted': submitted}" class="form-control onboarding-form-input tune-talk-onboard" formControlName="password" placeholder="{{'Password' | translate}}">
                                        <p class="help-block ng-invalid text-danger mb-0" *ngIf="(!f.password.valid && !f.password.pristine) || (f.password.errors && submitted)">
                                            <span class="small" *ngIf="f.password.errors.required">
                                                {{ "Password is required!" | translate}}
                                              </span>
                                        </p>
                                        <label for="forget-password" class="mt-3"> <a href="#" routerLink="/forgot-password" class="forget-pass-link tune-forget-link">{{ "Forgot password?" | translate}}</a>
                                          </label>
                                        <button class="btn btn-block signin-inner-btn outline" type="submit"> {{ "Sign In" | translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<div id="download" hidden></div>

<a href="{{url}}" id="downloadLink" hidden>Test</a>

<!-- alert modal -->
<div class="modal fade" id="downloadLinkModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content t-modal">
            <button type="button" class="close t-color text-right mt-3 mr-3" style="outline: none;" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/problem.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p>{{"Download link has expired." | translate }}</p>
                <button class="btn t-bgcolor outline" (click)="generateNewLink()" type="button" style="width: 170px; background: #47e1af !important; margin-bottom: 1rem;">{{"Generate New Link" | translate }}</button>
                <p>{{"Link will be sent via email, and will expire in 48 hours." | translate }}</p>
            </div>
        </div>
    </div>
</div>