<!-- <div class="download-trend-container">
	<div class="download-trend-header">
		<p>download-trend-graph works!</p>
	</div>
	<div class="">
		<div class="chart-wrapper">
			<canvas baseChart
				[datasets]="lineChartData"
				[labels]="lineChartLabels"
				[options]="lineChartOptions"
				[colors]="lineChartColors"
				[legend]="lineChartLegend"
				[chartType]="lineChartType"
				[plugins]="lineChartPlugins">
			</canvas>
		</div>
	</div>
</div> -->
<div class="total-download-graph-container">
	<div class="face-container-inner-bg p-2" style="height: 100vh;">
		<div class="total-login-user-container">
			<div class="filters-container mb-2">
				<!-- d-flex  -->
				<div class="d-flex">
					<p class="mb-0 p-2">{{ 'Download Trend Graph' | translate }}</p>
					<div class="ml-auto pt-2" *ngIf="manualRefresh && isDashboardUser">
						<i class="fa fa-refresh mr-2 cursor-p" style="font-size: 1vw;" (click)="refresh()"></i>
					</div>
				</div>
				
				<!-- class="col-md-5" -->
				<div class="d-flex">
					<!-- pl-2 align-items-center -->
					<!-- col-md-7  -->
					<div class="d-flex align-items-center download-trend-graph-tooltip-parent" [ngClass]="{'custom-width-full': filterBy === 'custom', 'custom-width': filterBy != 'custom'}">
						<!-- d-flex align-items-center -->
						<span class="show-lable" style="white-space: nowrap;">{{'Show By' | translate}}</span>
						<select class="custom-selection-dropdown outline gridster-ignore-drag ml-1 range-selector flex-grow-1" tooltip="{{filterValue | translate}}" [tooltipAnimation]="false" placement="top"   style="padding-right: 20px;" [ngClass]="{'date-range-custom': filterBy == 'custom', 'w-auto': filterBy !== 'custom'}" name="filterBy" [(ngModel)]="filterBy" (ngModelChange)='setDefaults(); setFilterValue()'>
							<!-- Today, Week-to-date, Month-to-date, Year-to-date and custom -->
							<option value="today">{{'Today' | translate}}</option>
							<option value="7days">{{'Week-to-Date' | translate}}</option>
							<option value="1month">{{'Month-to-Date' | translate}}</option>
							<option value="1year">{{'Year-to-Date' | translate}}</option>
							<option value="custom">{{'Custom' | translate}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position"></i></span>
					</div>
					<div [ngClass]="{'custom-date-range': filterBy === 'custom'}">
						<section class="ml-2 align-items-center d-flex downlod-trend-graph-date-range-tooltip" *ngIf="filterBy === 'custom'">
							<label class="m-0 show-lable text-right" style="white-space: nowrap;">{{'Date Range' | translate }}</label>
							<div class="">
								<input class="t-input form-control date-range-selector ml-1 mr-4 pl-1 pr-1" tooltip="{{dateRangeOne + ' ~ ' + dateRangeTwo}}" [tooltipAnimation]="false" placement="top" [(ngModel)]="selectedMoments" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'" (ngModelChange)="setDefaults();onDateChange();">
								<owl-date-time [pickerType]="'calendar'" #dt10></owl-date-time>
							</div>
						</section>
					</div>
				</div>
			</div>
			<div class="loader-flex-center" *ngIf="trendSpinner">
				<div class="loader quantum-spinner m-auto"></div>
			</div>
			<div class="t-body-overflow" *ngIf="noDataFound && !trendSpinner">
				<div class="p-1 t-body-bg cursor-p text-center">
					{{'No Data Found' | translate}}.
				</div>
			</div>
			<div *ngIf="!noDataFound && !trendSpinner">
				<canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" [options]="lineChartOptions">
			  </canvas>
			</div>
		</div>
	</div>
</div>