<div class="inner-main-profile" *ngIf="currentUrl !== '/report/device-analytics'">

    <div class="d-flex mb-3 mt-2">
        <div class="ml-auto">
            <div class="align-items-center d-flex pr-2">
                <div>
                    <h6 class="pull-left mr-3" style="margin-top: 8px; margin-bottom: 8px;" *ngIf="tabId === 'grp-qr-code'">{{'Timezone' | translate}}: (UTC +0) Etc/UTC</h6>
                    <h6 class="pull-left mr-3 mb-0" *ngIf="tabId != 'grp-qr-code'">{{'Timezone' | translate}}: </h6>
                </div>

                <select *ngIf="tabId !== 'grp-qr-code'" class="custom-selection-dropdown date-range-dropdown outline" style="width: 345px;" [(ngModel)]="selectedTimezone" (ngModelChange)=" setTimezone();" [disabled]="tabId === 'inventory'">
                    <option value="{{tz.timezonekey}}" *ngFor="let tz of timezones">{{tz.timezoneName}}</option>
                </select>
                <span  *ngIf="tabId !== 'grp-qr-code'" ><i class="fa fa-caret-down caret-position"></i></span>
            </div>
        </div>
    </div>

    <div class="face-container-inner-bg">
        <div class="report-head-section mb-3">
            <ul class="device-flex nav nav-pills mb-2 " id="pills-tab" role="tablist">
                <li class="nav-item" *ngIf="features && features[getFeatureIndex('download_report')].view">
                    <a class="nav-link" routerLink="/report/download" [ngClass]="{'active': tabId === 'download'}" id="pills-download-report-tab" data-toggle="pill" href="#pills-download-report" role="tab" aria-controls="pills-download-report" aria-selected="true">{{'Download Report' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="features && features[getFeatureIndex('prepare_report')].view">
                    <a class="nav-link device-flex-title" routerLink="/report/prepare" [ngClass]="{'active': tabId === 'prepare'}" id="pills-prepare-report-tab" data-toggle="pill" href="#pills-prepare-report" role="tab" aria-controls="pills-prepare-report" aria-selected="false">{{'Prepare Report' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="features && features[getFeatureIndex('reset_report')].view">
                    <a class="nav-link device-flex-title" routerLink="/report/reset" [ngClass]="{'active': tabId === 'reset'}" id="pills-reset-report-tab" data-toggle="pill" href="#pills-reset-report" role="tab" aria-controls="pills-reset-report" aria-selected="false">{{'Reset Report' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="features && features[getFeatureIndex('qrcode_report')].view && isParent">
                    <a class="nav-link device-flex-title" routerLink="/report/grp-qr-code" [ngClass]="{'active': tabId === 'grp-qr-code'}" id="pills-grp-qr-code-tab" data-toggle="pill" href="#pills-grp-qr-code" role="tab" aria-controls="pills-grp-qr-code" aria-selected="false">{{'Group QR Code' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="features && features[getFeatureIndex('inventory_report')].view">
                    <a class="nav-link device-flex-title" routerLink="/report/inventory" [ngClass]="{'active': tabId === 'inventory'}" id="pills-inventory-report-tab" data-toggle="pill" href="#pills-inventory-report" role="tab" aria-controls="pills-inventory-report" aria-selected="false">{{'Inventory Report' | translate}}</a>
                </li>
            </ul>
        </div>
        <div class="border-bottom">
            <div>
                <div class="d-flex mb-3">
                    <div class="align-items-center ml-2 mr-2 profileFits" [ngClass]="{'profileFit': language !== 'en'}" *ngIf="tabId !== 'inventory' && tabId !== 'grp-qr-code' && show && selectProfileFlg">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Profile Type' | translate}}: </label>
                        <ng-multiselect-dropdown class="profile-type-select custom-multiselect-dropdown-arrow" id="profile-type-select" placeholder="{{'Search profile type...' | translate}}" [settings]="profileDropdownSettings" [data]="profileTypeList" [(ngModel)]="selectedProfileTypes" (ngModelChange)="changeData();">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="align-items-center ml-2 mr-2 profile-type-dropdown" *ngIf="tabId !== 'inventory' && tabId === 'grp-qr-code' && show && selectProfileFlg">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Profile Type' | translate}}: </label>
                        <ng-multiselect-dropdown class="profile-type-select custom-multiselect-dropdown-arrow" id="profile-type-select" placeholder="{{'Search profile type...' | translate}}" [settings]="profileDropdownSettings" [data]="groupProfileTypeList" [(ngModel)]="selectedGroupProfileTypes" (ngModelChange)="changeData();">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="align-items-center ml-2 mr-2" style="min-width: 310px;" *ngIf="tabId !== 'inventory' && show && !selectProfileFlg">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Groups' | translate}}: </label>
                        <ng-multiselect-dropdown class="profile-type-select custom-multiselect-dropdown-arrow" id="profile-type-select" placeholder="{{'Search group here' | translate}}..." [settings]="groupDropdownSettings" [data]="groupList" [(ngModel)]="selectedGroups" (ngModelChange)="changeData();">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="align-items-center mr-2 screenFit" *ngIf="tabId === 'grp-qr-code'">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Group QR Name' | translate}}: </label>
                        <ng-multiselect-dropdown class="profile-type-select group-qr-multiselect custom-multiselect-dropdown-arrow" id="profile-type-select" placeholder="{{'Search group' | translate}}..." [settings]="groupNameDropdownSettings" [data]="groupNameList" [(ngModel)]="selectedGroupNames" (ngModelChange)="changeGroupName();">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="align-items-center pr-2 " *ngIf="tabId !== 'inventory'" style="position: relative;" [ngStyle]="{'width': dateRange == 'custom' ? '130px' : '149px'}">
						<div>
							<label class="t-label pull-left mr-2" style="white-space: nowrap;">{{'Date Range' | translate}}: </label>
						</div>
                        <select class="custom-selection-dropdown date-range-dropdown outline w-100" [ngClass]="{'fit-to-langage-sv': language === 'sv' && dateRange !== 'custom', 'fit-to-langage': language ==='pt' && dateRange !== 'custom', 'fit-to-langage-ru' : language === 'ru' && dateRange !== 'custom', 'fit-to-select-date': language === 'ru' && dateRange === 'custom'}" style="padding-right: 20px;" [(ngModel)]="dateRange" (ngModelChange)="changeData()">
                            <option value="today" *ngIf="tabId !== 'grp-qr-code'">{{'Today' | translate}}</option>
                            <option value="yesterday">{{'Yesterday' | translate}}</option>
                            <option value="week">{{'Week-to-Date' | translate}}</option>
                            <option value="month">{{'Month-to-Date' | translate}}</option>
                            <option value="year">{{'Year-to-Date' | translate}}</option>
							<option value="custom">{{'Custom' | translate}}</option>
						</select>
                        <span>
                            <i class="fa fa-caret-down caret-position" [ngClass]="{'fit-to-sv-month': language === 'sv' && dateRange === 'month', 'fit-to-sv-week': language === 'sv' && dateRange === 'week', 'fit-to-caret-ru': language === 'ru' && dateRange !== 'custom', 'fit-to-sv-year': language === 'sv' && dateRange !== 'custom' && dateRange != 'week' && dateRange != 'month', 'fit-to-sv': language === 'sv' && dateRange !== 'custom', 'fit-to-pt': language === 'pt' && dateRange !== 'custom', 'fit-to-pt-week': language === 'pt' && dateRange === 'week' && dateRange !== 'custom', 'fit-to-caret-custom': language === 'ru' && dateRange === 'custom'}" style="top: 42px; position: absolute;">
                            </i>
                        </span>
                    </div>
                    <div class="align-items-center pr-2" [ngClass]="{'fit-to-ru-date': language === 'ru' && dateRange === 'custom'}" *ngIf="dateRange === 'custom' && tabId !== 'inventory'">
						<div>
							<label class="t-label pull-left mr-2">{{'Select Date'| translate}}:</label>
						</div>
                        <input class="t-input report-date-range screenFitInputs" [ngClass]="{'screenFitInput': browserLang !== 'en'}" [max]="max" [(ngModel)]="selectedMoments" (ngModelChange)="changeData()" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10" [selectMode]="'range'">
                        <owl-date-time [pickerType]="'calendar'" #dt10></owl-date-time>
                    </div>
                    <div class="d-flex ml-auto">

                    <div class="d-flex t-color active" >
                        <div class="d-flex align-items-center" *ngIf="isView">
                            <div class="d-flex cursor-p prp-info send-email-tooltip operations-flex operations-flex-1 w-100" [ngClass]="{'fit-to-screens': language === 'ru' && tabId === 'inventory'}" *ngIf="!sendMailSpinner" (click)="sendEmail()">
                                <svg class="svg-t-color app-inner-svg-size flex flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="28.21" height="21.488" viewBox="0 0 28.21 21.488"><defs></defs><g transform="translate(0 -61)"><g transform="translate(0 61)"><path class="a" d="M25.731,61H2.479A2.484,2.484,0,0,0,0,63.479V80.009a2.483,2.483,0,0,0,2.479,2.479H25.731a2.483,2.483,0,0,0,2.479-2.479V63.479A2.483,2.483,0,0,0,25.731,61Zm-.342,1.653L14.158,73.884,2.83,62.653ZM1.653,79.667V63.814l7.961,7.892Zm1.169,1.169,7.966-7.966,2.791,2.767a.826.826,0,0,0,1.166,0l2.721-2.721,7.922,7.922Zm23.736-1.169-7.922-7.922,7.922-7.922Z" transform="translate(0 -61)"/></g></g></svg>
                                <div class="mr-4 wordWrap" tooltip="{{'Send As Email' | translate}}" [tooltipAnimation]="true" placement="top">
                                    {{'Send As Email' | translate | limitTo: sendMailTitle}}
                                </div>
                            </div>
                            <div class="btn-spinner mr-3" *ngIf="sendMailSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="t-color d-flex active mr-4" *ngIf="tabId === 'download'">
                        <div class="d-flex align-items-center cursor-p c-info operations-flex" [routerLink]="['/report/detail-download']">
                            <i class="fa fa-info mr-1"></i>
                            <div>
                                {{'Detailed Report' | translate}}
                            </div>
                        </div>
                    </div> -->
                    <div class="t-color d-flex active mr-4" *ngIf="tabId === 'download'">
                        <div class="d-flex align-items-center cursor-p c-info operations-flex w-auto" [routerLink]="['/report/detail-download']">
                            <i class="fa fa-info mr-1"></i>
                            <div>
                                {{'Detailed Report' | translate}}
                            </div>
                        </div>
                    </div>

                    <div class="t-color d-flex active mr-2" *ngIf="isView">
                        <div class="btn-spinner mr-3" *ngIf="downloadReportSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div *ngIf="tabId !== 'inventory'" class="d-flex align-items-center cursor-p operations-flex" (click)="downloadCSV()">
                            <svg class="svg-t-color app-inner-svg-size" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><defs></defs><g transform="translate(-8 -8)"><path class="a" d="M25.5,8H22a.875.875,0,0,0,0,1.75h3.5a1.752,1.752,0,0,1,1.75,1.75v14a1.752,1.752,0,0,1-1.75,1.75h-14A1.752,1.752,0,0,1,9.75,25.5v-14A1.752,1.752,0,0,1,11.5,9.75H15A.875.875,0,1,0,15,8H11.5A3.5,3.5,0,0,0,8,11.5v14A3.5,3.5,0,0,0,11.5,29h14A3.5,3.5,0,0,0,29,25.5v-14A3.5,3.5,0,0,0,25.5,8Z"/><path class="a" d="M25.5,27.013l-2.006-2.006a.875.875,0,0,0-1.237,1.237l3.5,3.5a.875.875,0,0,0,1.237,0l3.5-3.5a.875.875,0,0,0-1.237-1.237L27.25,27.013V16.875a.875.875,0,1,0-1.75,0Z" transform="translate(-7.875 -4.5)"/></g></svg>
                            <div>
                                {{'Download' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="selected-profile-types ml-2 mb-2 d-flex">
                <div class="d-flex flex-wrap" style="width: 80%;" *ngIf="tabId !== 'inventory' && tabId !== 'grp-qr-code' && selectedProfileTypes && selectedProfileTypes.length > 0">
                    <div class="selected-profile-types-selected-btns" *ngFor="let pType of selectedProfileTypes; index as i">
                        <span>{{pType.name}}</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap" style="width: 80%;" *ngIf="tabId !== 'inventory' && tabId === 'grp-qr-code' && selectedGroupProfileTypes && selectedGroupProfileTypes.length > 0">
                    <div class="selected-profile-types-selected-btns" *ngFor="let pType of selectedGroupProfileTypes; index as i">
                        <span>{{pType.name}}</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap" style="width: 80%;" *ngIf="tabId !== 'inventory' && tabId !== 'grp-qr-code' && selectedGroups && selectedGroups.length > 0">
                    <div class="selected-profile-types-selected-btns" *ngFor="let group of selectedGroups; index as i">
                        <span>{{group.groupName}}</span>
                    </div>
                </div>
                <p class="downloadSum" *ngIf="tabId === 'download'">{{'Total Download (Selected)' | translate}}: {{totalSum}}</p>
            </div>
        </div>

        <div class="tab-content device-flex-body" [ngClass]="{'download-flex-body': tabId === 'download' || tabId === 'grp-qr-code' }" id="pills-tabContent">
            <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'download'}" id="pills-download-report" role="tabpanel" aria-labelledby="pills-download-report-tab">
                <div class="p-2 mb-3">
                    <div class="row">
                        <div class="col-lg-6 border-right">
                            <div class="d-flex mb-3">
                                <div>
                                    {{'Profile Download Report' | translate}}
                                </div>
                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && lineChartData && show">
                                <canvas baseChart width="400" height="250" [datasets]="lineChartData" [labels]="lineChartLabels" [colors]="lineChartDownloadColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" style="height: 353px;" [options]="lineChartOptions">
                                </canvas>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="t-grid-table-new">
                                <div class="t-grid-table-wrapper-download-report t-head-bg">
                                    <div class="t-box a pl-2">{{'Profile Type' | translate}}</div>
                                    <div class="t-box b">{{'Total Download' | translate}}</div>
                                    <div class="t-box d-flex prp-info avg-tooltip align-items-center">
                                        <div class="mr-2">{{'Average' | translate}}</div>
                                        <div class="c-color c-info py-0" tooltip="{{'Average download per day calculated from date range selected' | translate}}" [tooltipAnimation]="true" placement="top">
                                            <i class="c-color c-info py-0 fa fa-info mr-3 icon-count-class"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="loader-flex-center" *ngIf="reportSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </div>
                                <div *ngIf="downloadReportList && downloadReportList.results && downloadReportList.results.length <= 0 && !reportSpinner">
                                    <div class="text-center mt-2 mb-2">
                                        <div class="text-center">{{'No Data Found' | translate}}.</div>
                                    </div>
                                </div>
                                <div class="t-body-overflow-report" *ngIf="!reportSpinner && downloadReportList && downloadReportList.results">
                                    <div class="t-grid-table-wrapper-download-report t-body-bg" *ngFor="let item of downloadReportList.results">
                                        <div class="t-box d t-font-light pl-2">{{item.profileType}}</div>
                                        <div class="t-box d t-font-light">{{item.subTotal}}</div>
                                        <div class="t-box d t-font-light">{{item.averate | number:'1.0-1'}}</div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'prepare'}" id="pills-prepare-report" role="tabpanel" aria-labelledby="pills-prepare-report-tab">
                <div class="p-2 mb-3">
                    <div class="row">
                        <div class="col-lg-6 border-right">
                            <div class="d-flex mb-3">
                                <div>
                                    {{'Prepare Report' | translate}}
                                </div>
                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && prepareBarChartData">
                                <canvas baseChart [chartType]="'bar'" width="400" height="250" [datasets]="prepareBarChartData" [labels]="lineChartLabels" [colors]="barChartColors" [legend]="lineChartLegend" [plugins]="lineChartPlugins" style="height: 353px;" [options]="lineChartOptions">
                                </canvas>
                            </div>
                        </div>
                        <div class="col-lg-6 ">
                            <div class="t-grid-table-new">
                                <div class="t-grid-table-wrapper-report t-head-bg">
                                    <div class="t-box a pl-2">{{'Profile Type' | translate}}</div>
                                    <div class="t-box b">{{'Released' | translate}}</div>
                                    <div class="t-box c">{{'Failed' | translate}}</div>
                                </div>
                                <div class="loader-flex-center" *ngIf="reportSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </div>
                                <div *ngIf="prepareReportList && prepareReportList.result.length <= 0 && !reportSpinner">
                                    <div class="text-center mt-2 mb-2">
                                        <div class="text-center">{{'No Data Found' | translate}}.</div>
                                    </div>
                                </div>
                                <div class="t-body-overflow-report" *ngIf="!reportSpinner && prepareReportList && prepareReportList.result">
                                    <div class="t-grid-table-wrapper-report t-body-bg" *ngFor="let item of prepareReportList.result">
                                        <div class="t-box d t-font-light pl-2">{{item.profileType}}</div>
                                        <div class="t-box d t-font-light">{{item.RELEASED}}</div>
                                        <div class="t-box d t-font-light">{{item.FAILED}}</div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'reset'}" id="pills-reset-report" role="tabpanel" aria-labelledby="pills-reset-report-tab">
                <div class="p-2 mb-3">
                    <div class="row">
                        <div class="col-lg-6 border-right">
                            <div class="d-flex mb-3">
                                <div>
                                    {{'Reset Report' | translate}}
                                </div>
                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && resetBarChartData">
                                <canvas baseChart [chartType]="'bar'" width="400" height="250" [datasets]="resetBarChartData" [labels]="lineChartLabels" [colors]="barChartColors" [legend]="lineChartLegend" [plugins]="lineChartPlugins" style="height: 353px;" [options]="barChartOptions">
                                </canvas>
                            </div>
                        </div>
                        <div class="col-lg-6 ">
                            <div class="t-grid-table-new">
                                <div class="t-grid-table-wrapper-report t-head-bg">
                                    <div class="t-box a pl-2">{{'Profile Type' | translate}}</div>
                                    <div class="t-box b">{{'Available' | translate}}</div>
                                    <div class="t-box c">{{'Failed' | translate}}</div>
                                </div>
                                <div class="loader-flex-center" *ngIf="reportSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </div>
                                <div *ngIf="resetReportList && resetReportList.result.length <= 0 && !reportSpinner">
                                    <div class="text-center mt-2 mb-2">
                                        <div class="text-center">{{'No Data Found' | translate}}.</div>
                                    </div>
                                </div>
                                <div class="t-body-overflow-report" *ngIf="!reportSpinner && resetReportList && resetReportList.result">
                                    <div class="t-grid-table-wrapper-report t-body-bg" *ngFor="let item of resetReportList.result">
                                        <div class="t-box d t-font-light pl-2">{{item.profileType}}</div>
                                        <div class="t-box d t-font-light">{{item.AVAILABLE}}</div>
                                        <div class="t-box d t-font-light">{{item.FAILED}}</div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'inventory'}" id="pills-inventory-report" role="tabpanel" aria-labelledby="pills-inventory-report-tab">
                <div class="t-grid-table-new inventory-table">
                    <div class="t-grid-table-wrapper t-head-bg">
                        <div class="t-box a filter-box-checkbox-container pl-2 overflow-hidden">
                            <mat-checkbox [(ngModel)]="select" (click)="selectAllRows();getSelectedCount();"></mat-checkbox>
                        </div>
                        <div class="t-box a overflow-auto">{{'Profile Type' | translate}}</div>
                        <div class="t-box c">{{'ICCID' | translate}}</div>
                        <div class="t-box b overflow-auto">{{'Available' | translate}}</div>
                        <div class="t-box c">{{'Allocated' | translate}}</div>
                        <div class="t-box a">{{'Linked' | translate}}</div>
                        <div class="t-box b">{{'Confirmed' | translate}}</div>
                        <div class="t-box c">{{'Released' | translate}}</div>
                        <div class="t-box a">{{'Downloaded' | translate}}</div>
                        <div class="t-box b">{{'Installed' | translate}}</div>
                        <div class="t-box c">{{'Enabled' | translate}}</div>
                        <div class="t-box a">{{'Deleted' | translate}}</div>
                        <div class="t-box b">{{'Error' | translate}}</div>
                        <div class="t-box c">{{'Unavailable' | translate}}</div>
                    </div>
                    <div class="loader-flex-center" *ngIf="reportSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                    <div *ngIf="inventoryList && inventoryList.length <= 0 && !reportSpinner">
                        <div class="text-center mt-2 mb-2">
                            <div class="text-center">{{'No Data Found' | translate}}.</div>
                        </div>
                    </div>
                    <div class="t-body-overflow" *ngIf="!reportSpinner">
                        <div class="t-grid-table-wrapper t-body-bg" *ngFor="let item of inventoryList">
                            <div class="t-box d t-font-light filter-box-checkbox-container pl-2 overflow-hidden">
                                <mat-checkbox [(ngModel)]="item.checked" (ngModelChange)="selectCheck(item.checked, item.profileType, item.groupId);"></mat-checkbox>
                            </div>
                            <div class="t-box d t-font-light overflow-auto" title="{{item.profileType}}">{{item.profileType | limitTo: 21}}</div>
                            <div class="t-box d t-font-light">{{item.total && item.total[0] ? item.total[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.AVAILABLE && item.AVAILABLE[0] ? item.AVAILABLE[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.ALLOCATED && item.ALLOCATED[0] ? item.ALLOCATED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.LINKED && item.LINKED[0] ? item.LINKED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.CONFIRMED && item.CONFIRMED[0] ? item.CONFIRMED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.RELEASED && item.RELEASED[0] ? item.RELEASED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.DOWNLOADED && item.DOWNLOADED[0] ? item.DOWNLOADED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.INSTALLED && item.INSTALLED[0] ? item.INSTALLED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.ENABLED && item.ENABLED[0] ? item.ENABLED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.DELETED && item.DELETED[0] ? item.DELETED[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.ERROR && item.ERROR[0] ? item.ERROR[0] : 0}}</div>
                            <div class="t-box d t-font-light">{{item.UNAVAILABLE && item.UNAVAILABLE[0] ? item.UNAVAILABLE[0] : 0}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'grp-qr-code'}" id="pills-inventory-report" role="tabpanel" aria-labelledby="pills-inventory-report-tab" style="overflow-y: auto;height: 60vh;">
                <div class="t-grid-table-new inventory-table">
                    <div class="t-grid-table-wrapper-2 t-head-bg" [ngStyle]="{'padding-right': inventoryListQR.length > 3 ? '4.5px !important;' : '0px !important;'}">
                        <div class="t-box a filter-box-checkbox-container pl-2 overflow-hidden">
                            <mat-checkbox [(ngModel)]="select" (ngModelChange)="selectAllRowsGroupQr();" [disabled]="checkboxDisable"></mat-checkbox>
                        </div>
                        <div class="t-box a overflow-auto">{{'Profile Type' | translate}}</div>
                        <div class="t-box c">{{'Group QR Name' | translate}}</div>
                        <div class="t-box b">{{'Status' | translate}}</div>
                        <div class="t-box c">{{'Group Type' | translate}}</div>
                        <div class="t-box a">{{'Total Downloaded' | translate}}</div>
                        <div class="t-box b">{{'Remaining' | translate  }}</div>
                    </div>
                    <div class="loader-flex-center" *ngIf="reportSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                    <div *ngIf="inventoryListQR && inventoryListQR.length <= 0 && !reportSpinner">
                        <div class="text-center mt-2 mb-2">
                            <div class="text-center">{{'No Data Found' | translate}}.</div>
                        </div>
                    </div>

                    <div *ngIf="!reportSpinner" class="groupQrScroll" >
                        <div class="t-grid-table-wrapper-2 t-body-bg" *ngFor="let item of inventoryListQR">
                            <div class="t-box d t-font-light filter-box-checkbox-container pl-2 overflow-hidden">
                                <mat-checkbox [(ngModel)]="item.checked" [disabled]="checkboxDisable" (ngModelChange)="selectCheckGroupQr(item.profileType, item.groupName, item.checked, item.groupType);"></mat-checkbox>
                            </div>
                            <div class="t-box d t-font-light overflow-auto" title="{{item.profileType}}">{{item.profileType}}</div>
                            <div class="t-box d t-font-light overflow-auto">{{item.groupName}}</div>
                            <div class="t-box d t-font-light">{{item.status}}</div>
                            <div class="t-box d t-font-light">{{item.groupType}}</div>
                            <div class="t-box d t-font-light">{{item.downloadLimit}}</div>
                            <div class="t-box d t-font-light">{{item.remainingLimit}}</div>
                        </div>
                    </div>
                </div>
                <div class="border-bottom"></div>

                <div class="mb-3 graph-lists">
                    <div class="row" style="margin-bottom: 55px;">
                        <div class="col-lg-6 border-right-light border-bottom-light p-4">
                            <div class="d-flex mb-3">
                                <ul class="d-flex w-100 justify-content-between align-items-center position-relative" style="list-style: none;">
                                    <li class="d-flex">
                                        <div class="">
                                            {{'Total Downloaded' | translate}}
                                        </div>
                                        <div class="c-color group-qr-tooltip-parent c-info py-0" id="hover-container">
                                            <i class="c-color c-info py-0 fa fa-info ml-3 icon-count-class" (mouseover)="hoverTotalGraph($event)" (mouseout)="hoverTotalGraph($event)"></i>
                                            <div class="group-qr-tooltip">
                                                <p class="mb-0 text-white">{{'Click on each graph point to learn more on Group QR and its download count' | translate}}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item my-auto">
                                        <div id="env-toggle" class="environment-toggle" [ngClass]="{'environment-toggle-active': isTotalDownloadPublic}">
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': isTotalDownloadPublic}">
                                                <p class="sg-font-small-regular">{{'Public'  | translate}}</p>
                                            </div>
                        
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" (click)="changeTypeTotalDownload()" [(ngModel)]="isTotalDownloadPublic">
                                                    <span class="slider round" [ngClass]="{'cursor-not-allowed': toggleDisable}"></span>
                                                </label>
                                            </div>
                        
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': !isTotalDownloadPublic}">
                                                <p class="sg-font-small-regular">{{'Private' | translate}}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div style="width:100%;">
                                    <canvas id="myChart" width="400" height="250" #mychart></canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 p-4 border-bottom-light">
                            <div class="d-flex mb-3">
                                <ul class="d-flex w-100 justify-content-between align-items-center" style="list-style: none;">
                                    <li>{{'Total Downloaded vs Remaining Count' | translate}}</li>
                                    <li class="nav-item my-auto">
                                        <div id="env-toggle" class="environment-toggle" [ngClass]="{'environment-toggle-active': isTotalDownloadRemainingCountPublic}">
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': isTotalDownloadRemainingCountPublic}">
                                                <p class="sg-font-small-regular">{{'Public'  | translate}}</p>
                                            </div>
                        
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" (click)="changeTypeRemainingCount()" [(ngModel)]="isTotalDownloadRemainingCountPublic">
                                                    <span class="slider round" [ngClass]="{'cursor-not-allowed': toggleDisable}"></span>
                                                </label>
                                            </div>
                        
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': !isTotalDownloadRemainingCountPublic}">
                                                <p class="sg-font-small-regular">{{'Private' | translate }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && qrBarChartData">
                                <canvas baseChart [chartType]="'bar'" width="400" height="250" [datasets]="qrBarChartData" [labels]="tdVsRclineChartLabels" [colors]="barChartColors" [legend]="lineChartLegend" [plugins]="lineChartPlugins" style="height: 353px;" [options]="lineChartOptions">
                                </canvas>
                            </div>
                        </div>
                        <div class="col-lg-6 p-4 border-right-light border-bottom-light" *ngFor="let item of dynamicGraphs;let i = index;">
                            <div class="d-flex mb-3">
                                <div style="word-break: break-all;" class="mr-3">
                                    {{'Total Downloaded vs Remaining Count' | translate}} :- {{item.data.groupName}}
                                </div>

                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && dynamicQrLabels[i]">
                                <canvas baseChart [chartType]="'bar'" width="400" height="250" [datasets]="dynamicQrData[i]" [labels]="dynamicQrLabels[i]" [colors]="barChartColors" [legend]="lineChartLegend" [plugins]="lineChartPlugins" style="height: 353px;" [options]="lineChartOptions">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'grp-qr-code'}" id="pills-inventory-report" role="tabpanel" aria-labelledby="pills-inventory-report-tab" style="overflow-y: auto;height: 60vh;">
                <div class="t-grid-table-new inventory-table">
                    <div class="t-grid-table-wrapper-2 t-head-bg" [ngStyle]="{'padding-right': inventoryListQR.length > 3 ? '4.5px !important;' : '0px !important;'}">
                        <div class="t-box a filter-box-checkbox-container pl-2 overflow-hidden">
                            <mat-checkbox [(ngModel)]="select" (ngModelChange)="selectAllRowsGroupQr();" [disabled]="checkboxDisable"></mat-checkbox>
                        </div>
                        <div class="t-box a overflow-auto">{{'Profile Type' | translate}}</div>
                        <div class="t-box c">{{'Group QR Name' | translate}}</div>
                        <div class="t-box b">{{'Status' | translate}}</div>
                        <div class="t-box c">{{'Group Type' | translate}}</div>
                        <div class="t-box a">{{'Total Downloaded' | translate}}</div>
                        <!-- <div class="t-box b">{{'Remaining' | translate  }}</div> -->
                    </div>
                    <div class="loader-flex-center" *ngIf="reportSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                    <div *ngIf="inventoryListQR && inventoryListQR.length <= 0 && !reportSpinner">
                        <div class="text-center mt-2 mb-2">
                            <div class="text-center">{{'No Data Found' | translate}}.</div>
                        </div>
                    </div>

                    <div *ngIf="!reportSpinner && inventoryListQR && inventoryListQR.length > 0" class="groupQrScroll" >
                        <div class="t-grid-table-wrapper-2 t-body-bg" *ngFor="let item of inventoryListQR">
                            <div class="t-box d t-font-light filter-box-checkbox-container pl-2 overflow-hidden">
                                <mat-checkbox [(ngModel)]="item.checked" [disabled]="checkboxDisable" (ngModelChange)="selectCheckGroupQr(item.profileType, item.groupName, item.checked, item.groupType);"></mat-checkbox>
                            </div>
                            <div class="t-box d t-font-light overflow-auto" title="{{item.profileType}}">{{item.profileType}}</div>
                            <div class="t-box d t-font-light overflow-auto">{{item.groupName}}</div>
                            <div class="t-box d t-font-light">{{item.status}}</div>
                            <div class="t-box d t-font-light">{{item.groupType}}</div>
                            <div class="t-box d t-font-light">{{item.downloadLimit}}</div>
                            <!-- <div class="t-box d t-font-light">{{item.remainingLimit}}</div> -->
                        </div>
                    </div>
                </div>
                <div class="border-bottom"></div>

                <div class="mb-3 graph-lists">
                    <div class="row" style="margin-bottom: 55px;">
                        <div class="col-lg-12 border-right-light border-bottom-light p-4">
                            <div class="d-flex mb-3">
                                <ul class="d-flex w-100 justify-content-between align-items-center position-relative" style="list-style: none;">
                                    <li class="d-flex">
                                        <div class="">
                                            {{'Total Downloaded' | translate}}
                                        </div>
                                        <div class="c-color group-qr-tooltip-parent c-info py-0" id="hover-container">
                                            <i class="c-color c-info py-0 fa fa-info ml-3 icon-count-class" (mouseover)="hoverTotalGraph($event)" (mouseout)="hoverTotalGraph($event)"></i>
                                            <div class="group-qr-tooltip">
                                                <p class="mb-0 text-white">{{'Click on each graph point to learn more on Group QR and its download count' | translate}}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item my-auto">
                                        <div id="env-toggle" class="environment-toggle" [ngClass]="{'environment-toggle-active': isTotalDownloadPublic}">
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': isTotalDownloadPublic}">
                                                <p class="sg-font-small-regular">{{'Public'  | translate}}</p>
                                            </div>
                        
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" (click)="changeTypeTotalDownload()" [(ngModel)]="isTotalDownloadPublic">
                                                    <span class="slider round" [ngClass]="{'cursor-not-allowed': toggleDisable}"></span>
                                                </label>
                                            </div>
                        
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': !isTotalDownloadPublic}">
                                                <p class="sg-font-small-regular">{{'Private' | translate}}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div style="width:100%;">
                                    <canvas id="myChart" width="400" height="250" #mychart></canvas>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 p-4 border-bottom-light">
                            <div class="d-flex mb-3">
                                <ul class="d-flex w-100 justify-content-between align-items-center" style="list-style: none;">
                                    <li>{{'Total Downloaded vs Remaining Count' | translate}}</li>
                                    <li class="nav-item my-auto">
                                        <div id="env-toggle" class="environment-toggle" [ngClass]="{'environment-toggle-active': isTotalDownloadRemainingCountPublic}">
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': isTotalDownloadRemainingCountPublic}">
                                                <p class="sg-font-small-regular">{{'Public'  | translate}}</p>
                                            </div>
                        
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" (click)="changeTypeRemainingCount()" [(ngModel)]="isTotalDownloadRemainingCountPublic">
                                                    <span class="slider round" [ngClass]="{'cursor-not-allowed': toggleDisable}"></span>
                                                </label>
                                            </div>
                        
                                            <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': !isTotalDownloadRemainingCountPublic}">
                                                <p class="sg-font-small-regular">{{'Private' | translate }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && qrBarChartData">
                                <canvas baseChart [chartType]="'bar'" width="400" height="250" [datasets]="qrBarChartData" [labels]="tdVsRclineChartLabels" [colors]="barChartColors" [legend]="lineChartLegend" [plugins]="lineChartPlugins" style="height: 353px;" [options]="lineChartOptions">
                                </canvas>
                            </div>
                        </div> -->
                        <div class="col-lg-6 p-4 border-right-light border-bottom-light" *ngFor="let item of dynamicGraphs;let i = index;">
                            <div class="d-flex mb-3">
                                <div style="word-break: break-all;" class="mr-3">
                                    {{'Total Downloaded Count' | translate}} :- {{item.data.groupName}}
                                </div>
                            </div>
                            <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="!reportSpinner && dynamicQrLabels[i]">
                                <canvas baseChart [chartType]="'bar'" width="400" height="250" [datasets]="dynamicQrData[i]" [labels]="dynamicQrLabels[i]" [colors]="barChartColors" [legend]="lineChartLegend" [plugins]="lineChartPlugins" style="height: 353px;" [options]="lineChartOptions">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content t-modal">
                <div class="modal-header text-center border-bottom-light">
                    <h5 class="modal-title" id="exampleModalLabel">{{ tooltipLabel | translate }} - {{'Group QR Name' | translate}}</h5>
                    <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center p-3" style="max-height: 20vh; overflow-y: scroll; overflow-x: hidden;">
                    <div class="d-flex align-items-center pb-2 pt-2 justify-content-around" *ngFor="let item of allDataObj">
                        <div class="color-box" style="width: 3%; height: 10px; background-color: white; border: '2px solid';" [ngStyle]="{'border-color': item.options.borderColor }"></div>
                        <div class="text-left" style="width: 85%; overflow-x: auto; white-space: nowrap;">
                            <p class="m-0 ml-2">{{item.label}} : {{item.data}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>