import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { AuthService } from '../../shared/services/auth.service';
import { UserState } from '../../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import papa from 'papaparse';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { CookieTrailService } from '../../shared/services/cookie-trail.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupMgmtService } from 'src/app/shared/services/group-mgmt.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-user-mgmt',
  templateUrl: './user-mgmt.component.html',
  styleUrls: ['./user-mgmt.component.scss']
})
export class UserMgmtComponent implements OnInit {

  addUserForm: FormGroup;
  roleList;
  submitted = false;
  chooseRole = 'user';
  userList;
  editOn = false;
  index;
  fname;
  lname;
  userSpinner = true;
  user;
  fileToUpload;
  result;
  validJson = [];
  invalidJson = [];
  successJson = [];
  error = false;
  lineNos = [];
  fileName;
  enableSuccess = true;
  btnSpinner = false;
  successArr = [];
  failArr = [];
  languageList = environment.languageList;
  groupDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'groupName',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };

  profileDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'name',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };

  roleSettings = {
    singleSelection: false,
    idField: '_id',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'roleName',
    allowSearchFilter: true,
    itemsShowLimit: 3,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available',
    enableCheckAll: false
  };
  groupList;
  profileTypeList;
  profileTypeListWithGroupQR;
  selectProfileFlg = false;
  selectedProfileTypes = [];
  selectedGroups;
  selectedIdGroups;
  selectedRoles = [];
  selectedIdRoles;
  roleErr: any = false;
  createModal = false;
  selectedCount = 0;
  selectedUserList = [];
  select = false;
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedNewProfileTypes = [];
  show = false;
  envError;
  currentUser;
  sortOrderOption = [
    { name: 'FIRSTNAME', order: true, value: 'FIRSTNAME' },
    { name: 'LASTNAME', order: true, value: 'LASTNAME' },
    { name: 'EMAIL', order: true, value: 'EMAIL' },
    { name: 'ENVIRONMENT', order: true, value: 'ENVIRONMENT' },
    { name: 'ROLE', order: true, value: 'ROLE' },
  ];
  sortBy = 'FIRSTNAME';
  orderBy = 'ASC';
  searchTerm = '';
  userData;
  userArray = [];
  search = [];
  selectedDeleteUser;
  bulkDeleteBtnSpinner = false;
  profileList = [];
  selectedProfiles = [];

  constructor(
    private userService: UserService,
    private communicationService: CommunicationService,
    private authService: AuthService,
    private userStore: Store<UserState>,
    private cookieTrailService: CookieTrailService,
    private translate: TranslateService,
    private groupService: GroupMgmtService,
    private profileService: ProfileService) {
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');

      this.translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.selectAllText = res;
        this.profileDropdownSettings.selectAllText = res;
        this.roleSettings.selectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('Search').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.searchPlaceholderText = res;
        this.profileDropdownSettings.searchPlaceholderText = res;
        this.roleSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });

      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.unSelectAllText = res;
        this.profileDropdownSettings.unSelectAllText = res;
        this.roleSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
        this.profileDropdownSettings.noDataAvailablePlaceholderText = res;
        this.roleSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });
     }

  ngOnInit() {
    
    this.cookieTrailService.saveCookie({});

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.userStore.select('user')
      .subscribe((user: any) => {
        if (user) {
          this.user = user;
          this.getAllRoles();
          this.getAllGroups();
          this.getAllCustomerGroup();
          this.getAllProfileTypes();
          this.getAllCustomerPFGroup();

          if(!this.user.data.currentCustomer && (this.user.data.rootCustomer && this.user.data.rootCustomer.isParent)) {
            this.selectProfileFlg = true;
          } else if(this.user.data.currentCustomer && this.user.data.currentCustomer.isParent){
            this.selectProfileFlg = true;
          } else {
            this.selectProfileFlg = false;
          }

          this.userService.getUsers(user.data.customerId)
            .subscribe((res: any) => {
              this.userSpinner = false;
              this.userList =  JSON.parse(JSON.stringify(res));
              this.userData =  JSON.parse(JSON.stringify(res));
              this.sortList(this.sortBy, this.orderBy);
              this.userList.forEach(element => {
                element.editOn = false;
                element.checked = false;
              });
            }, err => {
              const cookieTrail = {
                screenName: 'Access Management : User Management',
                action: 'Get All users',
                body: { 
                  customerId: user.data.customerId
                },
                error: err.error || 'Something went wrong!'
              };
              
              this.cookieTrailService.saveCookie(cookieTrail);
              this.userSpinner = false;
              this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
            });
        }
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    // this.sortList(this.sortBy, this.orderBy);
    // this.getAllRoles();
    // this.getAllGroups();
    // this.getAllCustomerGroup();
    // this.getAllProfileTypes();
  }

  sortList(sortBy, orderBy){
    if (sortBy === 'FIRSTNAME'){
      if ( orderBy === 'ASC') {
        const sortedUsers = this.userList.sort((a, b) => {
          return   orderBy === 'ASC' ? a.firstName.localeCompare( b.firstName) : -( a.firstName.localeCompare(  b.firstName));
          });
      } else {
        const sortedUsers = this.userList.sort((a, b) => {
          return   orderBy === 'DESC' ? b.firstName.localeCompare( a.firstName) : -( b.firstName.localeCompare(  a.firstName));
        });
      }
    }
    if (sortBy === 'LASTNAME'){
      if ( orderBy === 'ASC') {
        const sortedUsers = this.userList.sort((a, b) => {
          return   orderBy === 'ASC' ? a.lastName.localeCompare( b.lastName) : -( a.lastName.localeCompare(  b.lastName));
          });
      } else {
        const sortedUsers = this.userList.sort((a, b) => {
          return   orderBy === 'DESC' ? b.lastName.localeCompare( a.lastName) : -( b.lastName.localeCompare(  a.lastName));
        });
      }
    }
    if (sortBy === 'EMAIL'){
      if ( orderBy === 'ASC') {
        const sortedUsers = this.userList.sort((a, b) => {
          return   orderBy === 'ASC' ? a.email.localeCompare( b.email) : -( a.email.localeCompare(  b.email));
          });
      } else {
        const sortedUsers = this.userList.sort((a, b) => {
          return   orderBy === 'DESC' ? b.email.localeCompare( a.email) : -( b.email.localeCompare(  a.email));
        });
      }
    }
  }

  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  selectCheck(check: any, userId: any) {
    let flg = 0;
    if (!check) {
      this.selectedCount--;
      this.selectedUserList.splice(this.selectedUserList.findIndex(ele => ele === userId), 1);
    } else if (this.selectedUserList.findIndex(ele => ele === userId) === -1) {
      this.selectedCount++;
      this.selectedUserList.push(userId);
    }

    setTimeout(() => {
      this.userList.forEach(ele => {
        if (!ele.checked) {
          this.select = false;
          flg = 1;
          return;
        }
        if (flg === 0) {
          this.select = true;
        }
      });
    }, 5);
  }

  deleteSelectedUser() {
    this.selectedDeleteUser = {
      userIds: this.selectedUserList
    };
    
    $('#deletBulkUserModal').modal();
  }

  deleteBulkUser() {
    this.bulkDeleteBtnSpinner = true;
    this.userService.deleteSelectedUser(this.selectedDeleteUser)
    .subscribe((res: any) => {
      this.bulkDeleteBtnSpinner = false;
      this.userList = this.userList.filter(x => !this.selectedUserList.includes(x.userId));
      this.userData = this.userData.filter(x => !this.selectedUserList.includes(x.userId));
      this.select = false;
      this.selectedUserList = [];
      $('#deletBulkUserModal').modal('hide');
      this.communicationService.openAlert({ alertType: 'success', alertMsg: 'User Deleted Successfully' });
    }, err => {
      this.bulkDeleteBtnSpinner = false;
      this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
    });
  }

  searchUser(userList, searchTerm) {
    if (!searchTerm){
        this.userList = JSON.parse(JSON.stringify(this.userData));
        return this.userList;
    }
    this.userList = userList.filter(
      (row) => row.firstName.toLowerCase().indexOf(searchTerm) > -1 || row.lastName.toLowerCase().indexOf(searchTerm) > -1
      || row.email.toLowerCase().indexOf(searchTerm) > -1);
    return this.userList;
  }

  selectAllRows() {
    if (this.select) {
      this.userList.forEach(ele => {
          ele.checked = false;
      });
    } else {
      this.userList.forEach(ele => {
        ele.checked = true;
      });
    }
  }

  getSelectedCount() {
    if (this.select) {
      this.selectedCount = 0;
      this.selectedUserList = [];
    } else {
      this.selectedCount = this.userList.length;
      this.selectedUserList = [];
      this.userList.forEach(ele => {
        if (this.user.data._id !== ele.userId){
          this.selectedUserList.push(ele.userId);
        }
      });
    }
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);

    this.translate.get('Select All').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.selectAllText = res;
      this.profileDropdownSettings.selectAllText = res;
      this.roleSettings.selectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('Search').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.searchPlaceholderText = res;
      this.profileDropdownSettings.searchPlaceholderText = res;
      this.roleSettings.searchPlaceholderText = res;
      this.refreshDropSettings();
    });

    this.translate.get('UnSelect All').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.unSelectAllText = res;
      this.profileDropdownSettings.unSelectAllText = res;
      this.roleSettings.unSelectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('No data available').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
      this.profileDropdownSettings.noDataAvailablePlaceholderText = res;
      this.roleSettings.noDataAvailablePlaceholderText = res;
      this.refreshDropSettings();
    });
  }
  
  getAllRoles() {
    this.userService.getAllRoles()
      .subscribe((res: any) => {
        this.roleList = res;
        this.initializeForm();
      }, err => {
        const cookieTrail = {
          screenName: 'Access Management : User Management',
          action: 'Get All Roles',
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getAllGroups() {
    // this.groupService.getAllGroups()
    //   .subscribe((res: any) => {
    //     this.groupList = res;
    //   }, err => {
    //     this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    //   })
  }

  getAllCustomerGroup() {
    this.groupService.getCustomerUserGroups()
      .subscribe((res: any) => {
        this.groupList = res;
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getAllCustomerPFGroup() {
    this.groupService.getCustomerUserPFGroups()
      .subscribe((res: any) => {
        this.profileList = res.profileTypes;
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getAllProfileTypes(includeGroupQR: boolean = false) {
    this.profileService.getProfileTypeList(includeGroupQR)
      .subscribe((res: any) => {
        if (includeGroupQR) {
          this.profileTypeListWithGroupQR = res.profileTypes;
        } else {
          this.profileTypeList = res.profileTypes;
        }
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  openCreateModal() {
    this.f.language.setValue(this.languageList[0].key);
    this.createModal = true;
    this.selectedRoles=[];
    this.roleErr = false;
    this.SearchCountryField = SearchCountryField;
	  this.TooltipLabel = TooltipLabel;
	  this.CountryISO = CountryISO;
    this.createModal = true;
    $('#createEditUser').modal();
  }

  initializeForm() {
    this.submitted = false;

    const phoneObject = {
      countryCode: '',
      dialCode: '',
      number: '',
    };

    if(this.roleList && this.roleList.length > 0) {

      this.addUserForm = new FormGroup({
        fname: new FormControl(null, Validators.required),
        lname: new FormControl(null, Validators.required),
        email: new FormControl(null, [Validators.required, Validators.email]),
        phoneNo: new FormControl(phoneObject, Validators.required),
        language: new FormControl(this.languageList[0].key, Validators.required),
        prod: new FormControl(false),
        preProd: new FormControl(false),
        // chooseRole: new FormControl(this.roleList[0]._id, Validators.required)
      });
    }
    this.getAllGroups();
    this.getAllProfileTypes();
  }

  resetForm() { 
    this.submitted = false;
    this.addUserForm.reset();
    this.getAllGroups();
  }

  getProfileTypesWithGroupQR() {
    this.submitted = false;
    this.addUserForm.reset();
    this.getAllGroups();
    this.getAllProfileTypes(true);
  }

  get f() { return this.addUserForm.controls; }

  getUser(index) {
    this.currentUser = this.userList[index];
    this.index = index;
    this.roleErr = false;
    this.addUserForm.reset();

    let userRole;
    if (this.userList[index].roles[0] === 'user') {
      userRole = 'User';
    } else if (this.userList[index].roles[0] === 'admin') {
      userRole = 'Admin';
    } else if (this.userList[index].roles[0] === 'developer') {
      userRole = 'Developer';
    } else if (this.userList[index].roles[0] === 'support') {
      userRole = 'Support';
    }

    const phoneObject = {
      countryCode: this.userList[index].countryCode,
      dialCode: this.userList[index].dialCode,
      number: String(this.userList[index].phone),
    }

    this.addUserForm.patchValue({fname: this.userList[index].firstName});
    this.addUserForm.patchValue({lname: this.userList[index].lastName});
    this.addUserForm.patchValue({email: this.userList[index].email});
    this.addUserForm.patchValue({phoneNo: phoneObject});
    this.addUserForm.patchValue({language: this.userList[index].language || 'en'});
    this.addUserForm.patchValue({prod: this.userList[index].prod});
    this.addUserForm.patchValue({preProd: this.userList[index].preProd});
    
    this.selectedRoles = this.userList[index].roleId && this.userList[index].roleId.length > 0 ? this.userList[index].roleId : [];

    this.selectedProfileTypes = [];
    if(this.userList[index].profileTypes && this.userList[index].profileTypes.length > 0) {
      for(let i = 0; i < this.userList[index].profileTypes.length; i++) {
        this.selectedProfileTypes.push({name: this.userList[index].profileTypes[i]});
      }
    } else {
      this.selectedProfileTypes = [];
    }
    this.selectedGroups = this.userList[index].groups && this.userList[index].groups.length > 0 ? this.userList[index].groups : [];

    ($('#createEditUser') as any).modal({ backdrop: 'static', keyboard: false });
  }

  addUser() {
    this.submitted = true;

    if (this.addUserForm.invalid) {
      // if (!this.addUserForm.value.prod && !this.addUserForm.value.preProd) {
      //   this.envError = 'Please select at least one environment';
      // }
      if(this.selectedRoles && this.selectedRoles.length <= 0) {
        this.roleErr = 'Please select role!';
      }
      return;
    }

    // (!this.addUserForm.value.prod && !this.addUserForm.value.preProd) || 

    if ((this.selectedRoles && this.selectedRoles.length <= 0)) {
      // if (!this.addUserForm.value.prod && !this.addUserForm.value.preProd) {
      //   this.envError = 'Please select at least one environment';
      // }
      if(this.selectedRoles && this.selectedRoles.length <= 0) {
        this.roleErr = 'Please select role!';
      }
      return;
    }

    this.btnSpinner = true;
    this.selectedIdGroups = [];
    this.selectedIdRoles = [];
    this.selectedNewProfileTypes = [];

    if(this.selectedGroups && this.selectedGroups.length > 0) {
      for(let i = 0; i < this.selectedGroups.length; i++) {
        this.selectedIdGroups.push(this.selectedGroups[i]._id);
      }
    }

    if(this.selectedProfileTypes && this.selectedProfileTypes.length > 0) {
      for(let i = 0; i < this.selectedProfileTypes.length; i++) {
        this.selectedNewProfileTypes.push(this.selectedProfileTypes[i].name);
      }
    }
    
    for(let i = 0; i < this.selectedRoles.length; i++) {
      this.selectedIdRoles.push(this.selectedRoles[i]._id);
    }

    const phoneObject = this.addUserForm.get('phoneNo').value
    const userData = {
      customerId: this.user.customerId,
      firstName: this.addUserForm.get('fname').value,
      lastName: this.addUserForm.get('lname').value,
      email: this.addUserForm.get('email').value,
      // phone: this.addUserForm.get('phoneNo').value,
      phone: phoneObject.number.replace(/[^\w\s]/gi, '').split(" ").join(""),
      countryCode: phoneObject.countryCode,
      dialCode: phoneObject.dialCode,
      language: this.addUserForm.get('language').value,
      // roles: [this.addUserForm.get('chooseRole').value.toLowerCase()],
      // roleId: this.addUserForm.get('chooseRole').value,
      roleId: this.selectedIdRoles,
      preProdProfileTypes: localStorage.getItem('isProd') === 'false' ? this.selectedNewProfileTypes : [],
      prodProfileTypes: localStorage.getItem('isProd') === 'true' ? this.selectedNewProfileTypes : [],
      preProdGroup: localStorage.getItem('isProd') === 'false' ? this.selectedIdGroups : [],
      prodGroup: localStorage.getItem('isProd') === 'true' ? this.selectedIdGroups : [],
      prod: this.addUserForm.get('prod').value,
      preProd: this.addUserForm.get('preProd').value,
    };

    const cookieTrail = {
      screenName: 'Access Management : User Management',
      action: 'Create User',
      body: userData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.userService.addUser(userData)
      .subscribe((res: any) => {
        this.submitted = false;
        res.editOn = false;
        this.userList.push(res);
        this.btnSpinner = false;
        $('#createEditUser').modal('hide');
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'User Added Successfully!' });
        this.createModal = false;
      }, err => {
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
      });
  }

  editUser() {
    this.submitted = true;
    if (this.addUserForm.invalid) {
      if(this.selectedRoles && this.selectedRoles.length <= 0) {
        this.roleErr = 'Please select role!';
      }
      // if (!this.addUserForm.value.prod && !this.addUserForm.value.preProd) {
      //   this.envError = 'Please select at least one environment';
      // }
      return;
    }

    // (!this.addUserForm.value.prod && !this.addUserForm.value.preProd) || 

    if ((this.selectedRoles && this.selectedRoles.length <= 0)) {
      // if (!this.addUserForm.value.prod && !this.addUserForm.value.preProd) {
      //   this.envError = 'Please select at least one environment';
      // }
      if(this.selectedRoles && this.selectedRoles.length <= 0) {
        this.roleErr = 'Please select role!';
      }
      return;
    }

    this.selectedIdGroups = [];
    this.selectedIdRoles = [];
    this.selectedNewProfileTypes = [];

    if(this.selectedGroups && this.selectedGroups.length > 0) { 
      for(let i = 0; i < this.selectedGroups.length; i++) {
        this.selectedIdGroups.push(this.selectedGroups[i]._id);
      }
    }

    if(this.selectedProfileTypes && this.selectedProfileTypes.length > 0) {
      for(let i = 0; i < this.selectedProfileTypes.length; i++) {
        this.selectedNewProfileTypes.push(this.selectedProfileTypes[i].name);
      }
    }
    
    for(let i = 0; i < this.selectedRoles.length; i++) {
      this.selectedIdRoles.push(this.selectedRoles[i]._id);
    }

    $('#userConfirmationModal').modal();
  }

  confirmEditUser() {
    this.btnSpinner = true;
    const phoneObject = this.addUserForm.get('phoneNo').value;

    const userData = {
      customerId: this.user.customerId,
      firstName: this.addUserForm.get('fname').value,
      lastName: this.addUserForm.get('lname').value,
      email: this.addUserForm.get('email').value,
      phone: phoneObject.number.replace(/[^\w\s]/gi, '').split(" ").join(""),
      countryCode: phoneObject.countryCode,
      dialCode: phoneObject.dialCode,
      language: this.addUserForm.get('language').value,
      roleId: this.selectedIdRoles,
      preProdProfileTypes: localStorage.getItem('isProd') === 'false' ? this.selectedNewProfileTypes : [],
      prodProfileTypes: localStorage.getItem('isProd') === 'true' ? this.selectedNewProfileTypes : [],
      preProdGroup: localStorage.getItem('isProd') === 'false' ? this.selectedIdGroups : [],
      prodGroup: localStorage.getItem('isProd') === 'true' ? this.selectedIdGroups : [],
      prod: this.addUserForm.get('prod').value,
      preProd: this.addUserForm.get('preProd').value,
    };

    const cookieTrail = {
      screenName: 'Access Management : User Management',
      action: 'Update User',
      body: userData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.userService.editUser(userData, this.userList[this.index].userId)
      .subscribe((res: any) => {
        this.submitted = false;
        this.userList[this.index] = res;
        this.btnSpinner = false;
        $('#createEditUser').modal('hide');
        $('#userConfirmationModal').modal('hide');
        this.editOn = false;
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'User Edited Successfully!' });
      }, err => {
        this.btnSpinner = false;
        $('#userConfirmationModal').modal('hide');
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
      });
  }

  deleteUser() {
    this.btnSpinner = true;

    const cookieTrail = {
      screenName: 'Access Management : User Management',
      action: 'Delete User',
      body: {userId: this.userList[this.index].userId},
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    let userId = this.userList[this.index].userId;

    this.userService.deleteUsers(this.userList[this.index].userId)
      .subscribe((res: any) => {
        this.userList.splice(this.index, 1);
        this.userData.splice(this.userData.findIndex(ele => ele.userId === userId), 1);
        this.btnSpinner = false;
        this.select = false;
        this.selectedUserList = [];
        this.index = '';
        $('#deletUserModal').modal('hide');
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'User Deleted Successfully!' });
      }, err => {
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
      });
  }

  getRoleName(roleId) {
    return this.roleList[this.roleList.findIndex(ele => ele.roleId === roleId)].roleName;
  }

  forgrtPassword(email) {
    const userData = {
      email
    };

    const cookieTrail = {
      screenName: 'Access Management : User Management',
      action: 'Change Password',
      body: userData,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.authService.forgot(userData, false);
  }

  uploadProfilePic() {
    $('#upload-user-csv').click();
  }

  async parseFile(rawFile) {
    return new Promise(resolve => {
      papa.parse(rawFile, {
        header: true,
        newline: '\n',
        dynamicTyping: false,
        skipEmptyLines: true,
        complete: results => {
          resolve(results.data);
        }
      });
    });
  }

  async onFileChange(event) {
    if (event.target.files.length > 0) {
      this.validJson = [];
      this.invalidJson = [];
      this.lineNos = [];
      this.error = false;
      this.fileName = '';

      this.fileToUpload = event.target.files[0];
      event.target.value = '';
      this.fileName = this.fileToUpload.name;
      this.result = await this.parseFile(this.fileToUpload);
      await this.getValidJson();

      if (this.validJson.length === this.result.length) {
        this.enableSuccess = false;
      }
    }
  }

  async getValidJson() {
    const temp = JSON.stringify(this.result).replace(/\\r/gm, '');
    this.result = JSON.parse(temp);
    for (let i = 0; i < this.result.length; i++) {
      if (!this.result[i].firstName.trim() || !this.result[i].lastName.trim() ||
        !this.result[i].email.trim() || !this.result[i].phone.trim() || !this.result[i].countryCode.trim()
        || !this.result[i].dialCode.trim() || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.result[i].email.trim()))) {
        this.invalidJson.push(this.result[i]);
        this.lineNos.push(i + 1);
        this.error = true;
      } else {
        this.result[i].countryCode = this.result[i].countryCode.toUpperCase();
        const patt = new RegExp("^[\+].*$");
        if(!patt.test(this.result[i].dialCode)) {
          this.result[i].dialCode = '+' + this.result[i].dialCode;
        }
        this.validJson.push(this.result[i]);
      }
    }
  }

  submitBulkUsers() {
    this.btnSpinner = true;
    
    if (this.validJson.length === this.result.length) {
      this.userService.addBulkUsers(this.result)
        .subscribe((res: any) => {
          this.enableSuccess = true;

          res.success.forEach(element => {
            element.userId = element._id;

            this.successArr.push({
              firstName: element.firstName,
              lastName: element.lastName,
              email: element.email,
              phone: element.phone,
              dialCode: element.dialCode,
              countryCode: element.countryCode
            });

            this.userList.push(element);
          });

          res.failed.forEach(element => {
            this.failArr.push({
              firstName: element.firstName,
              lastName: element.lastName,
              email: element.email,
              phone: element.phone,
              dialCode: element.dialCode,
              countryCode: element.countryCode
            });
          });

          this.btnSpinner = false;
          this.makeZip();
          $('#createBulkUsers').modal('hide');
          $('#createBulkUserSuccessModal').modal();
        }, err => {
          this.enableSuccess = true;
          this.btnSpinner = false;
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
        });
    }
  }

  makeZip() {
    const zip = new JSZip();
    const img = zip.folder('BulkUserCreationResponse');
    img.file('success.csv', papa.unparse(this.successArr));
    img.file('failed.csv', papa.unparse(this.failArr));

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, `BulkUserCreationResponse.zip`);
      });
  }

  selectRoles() {
    
    if(!this.createModal) {
      if(this.selectedRoles && this.selectedRoles.length <= 0) {
        this.roleErr = 'Please select role!';
      } else {
        this.roleErr = false;
      }
    }
  }

  selectUpdateRole() {
    if(this.selectedRoles && this.selectedRoles.length > 0) {
      const index = this.selectedRoles.findIndex(ele => ele.roleName === 'dashboard-user');
      if(index === -1) {
        const section = (document.querySelector(`#update-bulk-user-role input[aria-label='dashboard-user']`) as HTMLInputElement);
        section.parentElement.style.pointerEvents = 'none';
        section.parentElement.style.backgroundColor = '#9a999961';
      } else {
        this.roleList.forEach(element => {
          if(element.roleName != 'dashboard-user') {
            const section = (document.querySelector(`#update-bulk-user-role input[aria-label='${element.roleName}']`) as HTMLInputElement);
            section.parentElement.style.pointerEvents = 'none';
            section.parentElement.style.backgroundColor = '#9a999961';
          }
        });
      }
    } else {
      if(this.roleList && this.roleList.length > 0) {
        this.roleList.forEach(element => {
          const section = (document.querySelector(`#update-bulk-user-role input[aria-label='${element.roleName}']`) as HTMLInputElement);
          section.parentElement.style.pointerEvents = 'unset';
          section.parentElement.style.backgroundColor = 'unset';
        });
      }
    }
  }

  selectRole() {
    if(this.selectedRoles && this.selectedRoles.length > 0) {
      const index = this.selectedRoles.findIndex(ele => ele.roleName === 'dashboard-user');
      if(index === -1) {
        const section = (document.querySelector(`#user-role input[aria-label='dashboard-user']`) as HTMLInputElement);
        section.parentElement.style.pointerEvents = 'none';
        section.parentElement.style.backgroundColor = '#9a999961';
      } else {
        this.roleList.forEach(element => {
          if(element.roleName != 'dashboard-user') {
            const section = (document.querySelector(`#user-role input[aria-label='${element.roleName}']`) as HTMLInputElement);
            section.parentElement.style.pointerEvents = 'none';
            section.parentElement.style.backgroundColor = '#9a999961';
          }
        });
      }
    } else {
      if(this.roleList && this.roleList.length > 0) {
        this.roleList.forEach(element => {
          const section = (document.querySelector(`#user-role input[aria-label='${element.roleName}']`) as HTMLInputElement);
          section.parentElement.style.pointerEvents = 'unset';
          section.parentElement.style.backgroundColor = 'unset';
        });
      }
    }
  }
  
  assignUsers() {

    this.selectedIdGroups = [];
    this.selectedIdRoles = [];

    if(this.selectedGroups && this.selectedGroups.length > 0) { 
      for(let i = 0; i < this.selectedGroups.length; i++) {
        this.selectedIdGroups.push(this.selectedGroups[i]._id);
      }
    }
    
    for(let i = 0; i < this.selectedRoles.length; i++) {
      this.selectedIdRoles.push(this.selectedRoles[i]._id);
    }

    let userObject = [];
    let profileTypesLists=[];
    for(let i = 0; i < this.selectedUserList.length; i++) {
      if(this.userList.findIndex(ele => ele.userId === this.selectedUserList[i]) !== -1) {
        const user = Object.assign({}, this.userList[this.userList.findIndex(ele => ele.userId === this.selectedUserList[i])]);
        user.roleId = user.roleId.map(function(i) {
                                      return i._id;
                                    });
        user.roleId = user.roleId.concat(this.selectedIdRoles);
        user.roleId = user.roleId.filter((v, i, a) =>a.indexOf(v) === i);
                                  
        if(user.preProdGroup && user.preProdGroup.length > 0) {
          user.preProdGroup = user.preProdGroup.map(function(i) {
            return i._id;
          });
        }

        if(user.prodGroup && user.prodGroup.length > 0) {
          user.prodGroup = user.prodGroup.map(function(i) {
            return i._id;
          });
        }
      if(this.selectedProfiles){
        for(let i=0; i<this.selectedProfiles.length; i++){
          profileTypesLists.push(this.selectedProfiles[i].name);
        }
      }
       if(this.user.data.currentCustomer.isParent){
          if(localStorage.getItem('isProd') === 'true') {
            user.prodProfileTypes = profileTypesLists;
          } else if(localStorage.getItem('isProd') === 'false') {
            if(profileTypesLists.length > 0){
                  user.preProdProfileTypes = profileTypesLists;
            }
          }
       }
       else{
          if(localStorage.getItem('isProd') === 'true') {
            user.prodGroup = this.selectedIdGroups;
            user.prodGroup = user.prodGroup.filter((v, i, a) =>a.indexOf(v) === i);
          } else if(localStorage.getItem('isProd') === 'false') {
            if(this.selectedIdGroups.length > 0){
                  user.preProdGroup = this.selectedIdGroups;
                  user.preProdGroup = user.preProdGroup.filter((v, i, a) =>a.indexOf(v) === i);
            }
          }
         }
        userObject.push(user);
      }
    }
    const data = {
      userList: userObject,
      roles: this.selectedRoles
    };

    const cookieTrail = {
      screenName: 'Access Management : User Management',
      action: 'Bulk User Assignment',
      body: data,
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.btnSpinner = true;
    this.userService.editBulkUser(data)
      .subscribe((res: any) => {
        $('#assignBulkUsers').modal('hide');
        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'User Updated Successfully!'});
        this.getUpdateduserList();
        this.select = false;
        this.selectedUserList = [];
      }, err => {

        const cookieTrail = {
          screenName: 'Access Management : User Management',
          action: 'Bulk User Assignment',
          body: data,
          err: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.btnSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getUpdateduserList() {
    this.userService.getUsers(this.user.data.customerId)
            .subscribe((res: any) => {
              this.userSpinner = false;
              this.userList = JSON.parse(JSON.stringify(res));
              this.userData = JSON.parse(JSON.stringify(res));
              this.userList.forEach(element => {
                element.editOn = false;
                element.checked = false;
              });
            }, err => {
              const cookieTrail = {
                screenName: 'Access Management : User Management',
                action: 'Get All users',
                body: { 
                  customerId: this.user.data.customerId
                },
                error: err.error || 'Something went wrong!'
              };
              
              this.cookieTrailService.saveCookie(cookieTrail);
              this.userSpinner = false;
              this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
            });
  }

  findRoleIndex() {
    if(this.selectedRoles.findIndex(ele => ele.roleName === 'admin') === -1) {
      return true;
    } else {
      this.selectedProfileTypes = [];
      return false;
    }
  }
}
