<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg mb-3">
                <div class="access-mgmt-heading-row">
                    <div class="d-flex align-items-center border-bottom pb-2 mb-2">
                        <div class="access-mgmt-heading access-mgmt-head-padding">{{(editOn ? 'Edit Role' : 'Create New Role') | translate}}</div>
                        <div class="d-flex ml-auto">
                            <button class="btn t-bgcolor mr-3 outline" (click)="editOn ? editRole() : saveRole()" *ngIf="!saveBtnSpinner">{{"Save" | translate}}</button>
                            <div class="btn-spinner mr-3" *ngIf="saveBtnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <button class="btn access-mgmt-cancel-btn" [routerLink]="['/access-management/role']">{{"Cancel" | translate}}</button>
                        </div>
                    </div>
                    <!-- <div class="row access-mgmt-head-padding">
                        <div class="col-lg-1">
                            <p class="mb-0 access-mgmt-role-name">{{"Role Name" | translate}}</p>
                        </div>
                        <div class="col-lg-4">
                            <input type="text" class="form-control t-input" [(ngModel)]="roleName" (keyup)="roleNameErr=false;" (keypress)="isSpace($event)">
                            <p class="small text-danger mt-2 mb-0" *ngIf="roleNameErr">{{"Please enter role name" | translate}}!</p>
                        </div>
                    </div> -->
                    <div class="row access-mgmt-role-flex access-mgmt-head-padding">
                        <div class="access-mgmt-role-name-container">
                            <p class="mb-0 access-mgmt-role-name">{{"Role Name" | translate}}</p>
                        </div>
                        <div class="access-mgmt-role-input-container">
                            <input type="text" class="form-control t-input" [(ngModel)]="roleName" (keyup)="roleNameErr=false;" (keypress)="isSpace($event)">
                            <p class="small text-danger mt-2 mb-0" *ngIf="roleNameErr">{{"Please enter role name" | translate}}!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="face-container-inner-bg">
                        <div class="role-mgmt-table">
                            <div class="t-grid-table-new">
                                <div class="t-grid-table-wrapper border-bottom-light">
                                    <div class="t-box a px-2 border-right-0">{{"Features" | translate}}</div>
                                    <div class="t-box b px-2 text-center border-right-0">{{"View" | translate}}</div>
                                    <div class="t-box b px-2 text-center border-right-0">{{"Add / Edit" | translate}}</div>
                                    <div class="t-box b px-2 text-center">{{"Delete" | translate}}</div>
                                </div>
                                <div class="t-body-overflow">
                                    <div class="t-grid-table-new-body" *ngFor="let role of leftFeatureArray; let j = index;">
                                        <div *ngIf="role.display">
                                            <div class="t-grid-table-wrapper cursor-p border-bottom-light feature-head">
                                                <div class="t-box d" style="cursor: auto;">{{role.name | translate}}</div>
                                                <div class="t-box d" style="cursor: auto;"></div>
                                                <div class="t-box d" style="cursor: auto;"></div>
                                                <div class="t-box d" style="cursor: auto;"></div>
                                            </div>
                                            <div class="t-grid-table-wrapper t-body-bg cursor-p border-bottom-light" *ngFor="let feature of role.features; let i = index;">
                                                <div class="t-box d t-font-light" *ngIf="feature.display" style="cursor: auto;">{{feature.name | translate}}</div>
                                                <div class="t-box d t-font-light text-center" *ngIf="feature.display">
                                                    <div class="filter-box-checkbox-container">
                                                        <mat-checkbox [(ngModel)]="feature.view" *ngIf="feature.displayView" (ngModelChange)="callFun('left', j, i, 'view', (role.name === 'Manage Profile' ? 'profile' : ''))" [disabled]="(feature.key === 'api_bookmark') && disableBookmark"></mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="t-box d t-font-light text-center" *ngIf="feature.display">
                                                    <div class="filter-box-checkbox-container">
                                                        <mat-checkbox [(ngModel)]="feature.edit" *ngIf="feature.displayEdit" (ngModelChange)="callFun('left', j, i, 'edit', (role.name === 'Manage Profile' ? 'profile' : ''))"></mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="t-box d t-font-light text-center" *ngIf="feature.display">
                                                    <div class="filter-box-checkbox-container">
                                                        <mat-checkbox [(ngModel)]="feature.delete" *ngIf="feature.dispalyDelete" (ngModelChange)="callFun('left', j, i, 'delete', (role.name === 'Manage Profile' ? 'profile' : ''))"></mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="face-container-inner-bg">
                        <div class="role-mgmt-table">
                            <div class="t-grid-table-new">
                                <div class="t-grid-table-wrapper border-bottom-light">
                                    <div class="t-box a px-2 border-right-0">{{"Features" | translate}}</div>
                                    <div class="t-box b px-2 text-center border-right-0">{{"View" | translate}}</div>
                                    <div class="t-box b px-2 text-center border-right-0">{{"Add / Edit" | translate}}</div>
                                    <div class="t-box b px-2 text-center">{{"Delete" | translate}}</div>
                                </div>
                                <div class="t-body-overflow">
                                    <div class="t-grid-table-new-body" *ngFor="let role of rightFeatureArray; let j = index;">
                                        <div *ngIf="role.display">
                                            <div class="t-grid-table-wrapper cursor-p border-bottom-light feature-head">
                                                <div class="t-box d" style="cursor: auto;">{{role.name | translate}}</div>
                                                <div class="t-box d" style="cursor: auto;"></div>
                                                <div class="t-box d" style="cursor: auto;"></div>
                                                <div class="t-box d" style="cursor: auto;"></div>
                                            </div>
                                            <div class="t-grid-table-wrapper t-body-bg cursor-p border-bottom-light" *ngFor="let feature of role.features; let i = index;">
                                                <div class="t-box d t-font-light" *ngIf="feature.display" style="cursor: auto;">{{feature.name | translate}}</div>
                                                <div class="t-box d t-font-light text-center" *ngIf="feature.display">
                                                    <div class="filter-box-checkbox-container">
                                                        <mat-checkbox [(ngModel)]="feature.view" *ngIf="feature.displayView" (ngModelChange)="callFun('right', j, i, 'view', (role.name === 'Manage Profile' ? 'profile' : ''))" [disabled]="(feature.key === 'api_bookmark') && disableBookmark"></mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="t-box d t-font-light text-center" *ngIf="feature.display">
                                                    <div class="filter-box-checkbox-container">
                                                        <mat-checkbox [(ngModel)]="feature.edit" *ngIf="feature.displayEdit" (ngModelChange)="callFun('right', j, i, 'edit', (role.name === 'Manage Profile' ? 'profile' : ''))"></mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="t-box d t-font-light text-center" *ngIf="feature.display">
                                                    <div class="filter-box-checkbox-container">
                                                        <mat-checkbox [(ngModel)]="feature.delete" *ngIf="feature.dispalyDelete" (ngModelChange)="callFun('right', j, i, 'delete', (role.name === 'Manage Profile' ? 'profile' : ''))"></mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>