import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { Barcode, MousePosition } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent extends ShapeComponent implements OnInit {

  constructor() {
      super();
      this.shape = new Barcode();
      this.shapeType = ShapeType.Barcode;
  }

  ngOnInit() {
  }

  startDrawing(beginPosition: MousePosition): void {
      if (this.shape instanceof Barcode) {
          this.shape.originX = beginPosition.x;
          this.shape.originY = beginPosition.y;
      }
  }

  draw(currentPosition: MousePosition): void {
      if (this.shape instanceof Barcode) {
          this.shape.width = Math.abs(currentPosition.x - this.shape.originX);
          this.shape.height = Math.abs(currentPosition.y - this.shape.originY);
      }
  }
}
