<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="row">
                <div class="col-lg-5">
                    <div class="face-container-inner-bg p-3 pb-0" style="border-bottom-left-radius: 0;border-bottom-right-radius: 0;">
                        <div class="row mb-2 border-bottom">
                            <div class="col-lg-12">
                                <h5 class="mb-3">{{editOn ? ("Edit Group" | translate) : ("Create New Group" | translate)}}</h5>
                            </div>
                        </div>
                        <div class="mb-4 mt-4">
                            <label for="" class="t-font-light t-font-medium my-acc-label">{{'Group Name' | translate}}</label>
                            <input type="text" class="t-input p-1 form-control" [(ngModel)]="groupName" (keyup)="groupNameErr=false;" (keypress)="isSpace($event)">
                            <p class="small text-danger mt-2 mb-0" *ngIf="groupNameErr">{{"Please enter group name" | translate}}!</p>
                        </div>
                        <div class="mb-4">
                            <label for="" class="t-font-light t-font-medium my-acc-label">{{'Group Description' | translate}}</label>
                            <textarea class="t-input w-100 p-2 support-description outline" [(ngModel)]="groupDesc" cols="30" rows="5"></textarea>
                            <!-- <p class="small text-danger mt-2 mb-0" *ngIf="groupDescErr">{{"Please enter role name" | translate}}!</p> -->
                        </div>
                        <div class="mb-4">
                            <div class="form-group mt-3 mb-0">
                                <label for="" class="t-font-light t-font-medium my-acc-label">{{'Group Setup' | translate}}</label>

                                <div class="form-group mb-0 confirmation-code-inner-radio cc-flag pl-2 ml-3">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="groupSetup" (ngModelChange)="clearProfileTypes();">
                                        <mat-radio-button class="example-radio-button mr-1" [value]="groupSetupFlgs[0]" [disabled]="editOn">
                                            
                                        </mat-radio-button><span class="mr-4">{{groupSetupFlgs[0] | translate}}</span>
                                        <mat-radio-button class="example-radio-button mr-1" [value]="groupSetupFlgs[1]" [disabled]="editOn">
                                           
                                        </mat-radio-button><span class="mr-4">{{groupSetupFlgs[1] | translate}}</span>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="face-container-inner-bg pl-3 pr-3 pb-1 pt-3" style="border-bottom-left-radius: 0;border-bottom-right-radius: 0;">
                        <div class="row mb-2 border-bottom">
                            <div class="col-lg-12 d-flex">
                                <!-- (group && (!group.id && !group.editId)) || (editOn && (singleGroup && singleGroup.level === 0)) ? "Select Profile Types" : (group.id || group.editIdb ? "Select Groups" : '') | translate -->
                                <h5 class="mb-3">{{ selectTitle | translate}}</h5>
                                <div class="d-flex ml-auto mb-2">
                                    <button class="btn t-bgcolor outline py-0" style="width: 110px;" (click)="confirmGroup()" [disabled]="(groupList && groupList.length <= 0) || (profileTypes && profileTypes.length <= 0)">{{'Submit' | translate}}</button>
                                    <!-- <div class="btn-spinner mr-3" *ngIf="saveBtnSpinner">
                                        <div class="loader quantum-spinner m-auto"></div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="face-container-inner-bg mb-2 pl-3 pr-3 pb-3 pt-1" style="max-height: calc(100vh - 200px); overflow: auto;border-top-left-radius: 0;border-top-right-radius: 0;" *ngIf="dataSpinner">
                        <div class="loader-flex-center">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                    </div>
                    <div class="face-container-inner-bg mb-2 pl-3 pr-3 pb-3 pt-1" style="max-height: calc(100vh - 200px); overflow: auto;border-top-left-radius: 0;border-top-right-radius: 0;" *ngIf="!dataSpinner && profileSyncMsg">
                        <div class="row mt-4 border-bottom-light pl-3 pr-3 pb-3 d-block text-center">
                            {{"Profiles Syncing is in Progress." | translate}}
                        </div>
                    </div>
                    <div class="face-container-inner-bg mb-2 pl-3 pr-3 pb-3 pt-1" style="max-height: calc(100vh - 200px); overflow: auto;border-top-left-radius: 0;border-top-right-radius: 0;" *ngIf="!dataSpinner && !profileSyncMsg && profileTypes && profileTypes.length <= 0">
                        <div class="row mt-4 border-bottom-light pl-3 pr-3 pb-3 d-block text-center">
                            {{"No profile types found" | translate}}.
                        </div>
                    </div>
                    <div class="face-container-inner-bg mb-2 pl-3 pr-3 pb-3 pt-1" style="max-height: calc(100vh - 200px); overflow: auto;border-top-left-radius: 0;border-top-right-radius: 0;" *ngIf="!dataSpinner && groupList && groupList.length <= 0">
                        <div class="row mt-4 border-bottom-light pl-3 pr-3 pb-3 d-block text-center">
                            {{"No groups found" | translate}}.
                        </div>
                    </div>
                    <div class="face-container-inner-bg mb-2 pl-3 pr-3 pb-3 pt-1" style="max-height: calc(100vh - 200px); overflow: auto;border-top-left-radius: 0;border-top-right-radius: 0;" *ngIf="profileTypes && profileTypes.length > 0">
                        <div class="row mt-4 border-bottom-light pl-3 pr-3 pb-3" *ngFor="let profile of profileTypes; let i = index;">
                            <div class="filter-box-checkbox-container mb-1 d-flex w-100 flex-wrap">
                                <div class="d-flex">
                                    <mat-checkbox [(ngModel)]="profile.checked" class="mr-5" (click)="checkGroupSetUp(i)"></mat-checkbox>
                                    <h5 class="mb-0 ml-2 mr-3 ml-xl-n3">{{ profile.profileType}}</h5>
                                </div>
                                <div class="form-group mb-0 confirmation-code-inner-radio cc-flag pl-2 ml-3 ml-xl-auto mt-3 mt-xl-0 group-add-margin-small-screen">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="profile.selectedMode" (ngModelChange)="profile.selectedModeErr = false;">
                                        <mat-radio-button class="example-radio-button mr-1" [value]="iccidModes[0]" [disabled]="!profile.checked">
                                            
                                        </mat-radio-button><span class="mr-4">{{iccidModes[0] | translate}}</span>
                                        <mat-radio-button class="example-radio-button mr-1" [value]="iccidModes[1]" [disabled]="!profile.checked">
                                           
                                        </mat-radio-button><span class="mr-4">{{iccidModes[1] | translate}}</span>
                                    </mat-radio-group>
                                    <p class="small text-danger mt-2 mb-0" *ngIf="profile.selectedModeErr">{{"Please select mode" | translate}}!</p>
                                </div>
                            </div>
                            <div class="mt-3 d-flex ml-3 w-100" *ngIf="profile.checked && profile.selectedMode === 'File Upload'">
                                <div class="ml-5">
                                    <label for="" class="t-font-light my-acc-label">{{'Upload ICCIDS' | translate}}:</label>
                                </div>
                                <div class="qr-code-file-upload-btn ml-5 mr-5 d-flex">
                                    <a class="mr-5" href="assets/csv/sample-iccid.txt" download>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                    </a>
                                    <p class="mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="uploadFile(i);" class="svg-btn-color svg-t-size cursor-p" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs></defs><g transform="translate(28.768 29.233) rotate(180)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                        <input id="groupFile_{{i}}" type="file" style="display: none" accept=".txt" (change)="onFileChange($event, i);profile.fileUploadErr = false;">
                                    </p>
                                </div>
                                <div class="t-font-light ml-5" *ngIf="profile.fileUpload">
                                    {{profile.fileUpload.name}}
                                </div>
                                <p class="small text-danger mt-2 mb-0" *ngIf="profile.fileUploadErr">{{"Please upload file" | translate}}!</p>
                            </div>
                            <div class="mt-3 d-flex ml-3 w-100" *ngIf="profile.checked && profile.selectedMode === 'Count'">
                                <div class="ml-5 mr-3">
                                    <label for="" class="t-font-light t-font-medium my-acc-label">{{'Count' | translate}}:</label>
                                    <input type="text" class="t-input p-1 form-control" (keypress)="isNumber($event)" (keyup)="profile.countErr=false;" style="width:190px;" [(ngModel)]="profile.count">
                                    <p class="small text-danger mt-2 mb-0" *ngIf="profile.countErr">{{"Count should be greater than 0" | translate}}!</p>
                                </div>
                                <div class="ml-5 flex-grow-1">
                                    <label for="" class="t-font-light t-font-medium my-acc-label">{{'Starting ICCID' | translate}}:</label>
                                    <input type="text" class="t-input p-1 form-control" [placeholder]="'Optional' | translate" [(ngModel)]="profile.startingIccid">
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div class="face-container-inner-bg mb-2 pl-3 pr-3 pb-3 pt-1" style="max-height: calc(100vh - 200px); overflow: auto;border-top-left-radius: 0;border-top-right-radius: 0;" *ngIf="groupList && groupList.length > 0">
                        <div class="row mt-4 border-bottom-light pl-3 pr-3 pb-3" *ngFor="let group of groupList; let i = index;">
                            <div class="filter-box-checkbox-container mb-1 d-flex w-100">
                                <div class="d-flex">
                                    <mat-checkbox [(ngModel)]="group.checked" class="mr-5" (click)="checkGroupSetUp(i)"></mat-checkbox>
                                    <h5 class="mb-0 ml-2 mr-3">{{ group.groupName }}</h5>
                                </div>
                                <div class="form-group mb-0 confirmation-code-inner-radio cc-flag pl-2 ml-3 ml-auto">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="group.selectedMode" (ngModelChange)="group.selectedModeErr = false;">
                                        <mat-radio-button class="example-radio-button mr-1" [value]="iccidModes[0]" [disabled]="!group.checked">
                                            
                                        </mat-radio-button><span class="mr-4">{{iccidModes[0] | translate}}</span>
                                        <mat-radio-button class="example-radio-button mr-1" [value]="iccidModes[1]" [disabled]="!group.checked">
                                           
                                        </mat-radio-button><span class="mr-4">{{iccidModes[1] | translate}}</span>
                                    </mat-radio-group>
                                    <p class="small text-danger mt-2 mb-0" *ngIf="group.selectedModeErr">{{"Please select mode" | translate}}!</p>
                                </div>
                            </div>
                            <div class="mt-3 d-flex ml-3 w-100" *ngIf="group.checked && group.selectedMode === 'File Upload'">
                                <div class="ml-5">
                                    <label for="" class="t-font-light my-acc-label">{{'Upload ICCIDS' | translate}}:</label>
                                </div>
                                <div class="qr-code-file-upload-btn ml-5 mr-5 d-flex">
                                    <a class="mr-5" href="assets/csv/sample-iccid.txt" download>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                    </a>
                                    <p class="mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="uploadFile(i)" class="svg-btn-color svg-t-size cursor-p" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs></defs><g transform="translate(28.768 29.233) rotate(180)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                        <input id="groupFile_{{i}}" type="file" style="display: none" accept=".txt" (change)="onFileChange($event, i);group.fileUploadErr = false;">
                                    </p>
                                </div>
                                <div class="t-font-light ml-5" *ngIf="group.fileUpload">
                                    {{group.fileUpload.name}}
                                </div>
                                <p class="small text-danger mt-2 mb-0" *ngIf="group.fileUploadErr">{{"Please upload file" | translate}}!</p>
                            </div>
                            <div class="mt-3 d-flex ml-3 w-100" *ngIf="group.checked && group.selectedMode === 'Count'">
                                <div class="ml-5 mr-3">
                                    <label for="" class="t-font-light t-font-medium my-acc-label">{{'Count' | translate}}:</label>
                                    <input type="text" class="t-input p-1 form-control" (keypress)="isNumber($event)" (keyup)="group.countErr=false;" style="width:190px;" [(ngModel)]="group.count">
                                    <p class="small text-danger mt-2 mb-0" *ngIf="group.countErr">{{"Count should be greater than 0" | translate}}!</p>
                                </div>
                                <div class="ml-5 flex-grow-1">
                                    <label for="" class="t-font-light t-font-medium my-acc-label">{{'Starting ICCID' | translate}}:</label>
                                    <input type="text" class="t-input p-1 form-control" placeholder="Optional" [(ngModel)]="group.startingIccid">
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="groupConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered create-group" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{editOn? ('Confirm Edit Group' | translate) : ('Confirm Create Group' | translate)}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" aria-label="Close" (click)="saveBtnSpinner = false;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="group-confirmation" *ngIf="groupData">
                    <div class="group-info">
                        <div class="group-info-title">{{"Group Name" | translate}}: </div>
                        <div class="group-info-desc">{{groupData.groupName}}</div>
                    </div>
                    <div class="group-info">
                        <div class="group-info-title">{{"Description" | translate}}: </div>
                        <div class="group-info-desc">{{groupData.description}}</div>
                    </div>
                    <div class="group-info">
                        <div class="group-info-title">{{"Group Setup" | translate}}: </div>
                        <div class="group-info-desc">{{groupData.groupType}}</div>
                    </div>

                    <div *ngIf="profileTypes && profileTypes.length > 0">
                        <div *ngFor="let profile of groupData.profileTypes">
                            <div class="group-info">
                                <div class="group-info-title" tooltip="{{profile.profileType}}" placement="bottom">{{"Profile Type" | translate}}: </div>
                                <div class="group-info-desc" style="margin-right: 51px;">{{profile.profileType | limitTo: 23}}</div>
                                <div class="d-flex"><span class="t-font-light my-acc-label ml-5 mr-4">{{"ICCID Count" | translate}}: </span>{{profile.iccidList.length}}</div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="groupList && groupList.length > 0">
                        <div *ngFor="let group of groupData.groups">
                            <div class="d-flex mb-3">
                                <label for="" class="my-acc-label mr-4 d-flex" style="width: 290px; word-break: break-all;" tooltip="{{group.groupName}}" placement="bottom"><span class="t-font-light my-acc-label" style="margin-right: 91px;">{{"Group" | translate}}: </span>{{ group.groupName | limitTo:18}}</label>
                                <div class="d-flex" style="width: 345px;" *ngIf="group.selectedMode === 'Count' && group.startingIccid" tooltip="{{group.startingIccid}}" placement="bottom"><span class="t-font-light my-acc-label ml-3 mr-4">{{"Starting ICCID" | translate}}: </span>{{group.startingIccid | limitTo: 20}}</div>
                                <div class="d-flex"><span class="t-font-light my-acc-label ml-5 mr-4">{{"ICCID Count" | translate}}: </span>{{group.iccidList.length}}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn t-bgcolor my-3 outline" style="width:240px;" (click)="editOn ? editGroup() : createGroup()" *ngIf="!saveBtnSpinner">{{'Submit' | translate}}</button>
                    <div class="btn-spinner" *ngIf="saveBtnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="groupCreationSuccess" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content t-modal">
            <button type="button" class="close t-color text-right mt-3 mr-3" style="outline: none;" data-dismiss="modal" id="closeDeleteCustomer" [routerLink]="['/access-management/group']" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/checked.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p>{{editOn ? ('Group edited successfully' | translate) : ('Group created successfully' | translate) }}!</p>

                <div class="mt-3 group-detail-report">
                    <div class="detail-report-body">
                        <div class="t-grid-table-new">
                            <div class="t-grid-table-wrapper t-head-bg border-bottom-light">
                                <div class="t-box a px-2">{{"ICCID" | translate}}</div>
                                <div class="t-box b px-2">{{"Status" | translate}}</div>
                            </div>
                            <div class="t-body-overflow">
                                <div class="t-grid-table-wrapper t-body-bg cursor-p border-bottom-light" *ngFor="let item of iccidList; let i = index;">
                                    <div class="t-box d t-font-light" class="p-2">{{ item.iccid }}</div>
                                    <div class="t-box d t-font-light" class="p-2">{{ item.state }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>