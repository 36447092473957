<div class="download-forecast-container face-container-inner-bg" style="height: 100vh;">
	<div class="download-forecast-header d-flex">
		<p class="mb-0 p-2">{{ 'Download Forecast Graph' | translate}}</p>
		<div class="ml-auto pt-2" *ngIf="manualRefresh && isDashboardUser">
			<i class="fa fa-refresh mr-3 cursor-p" style="font-size: 1vw;" (click)="refresh()"></i>
		</div>
	</div>
	<div class="">
		<div class="chart-wrapper" *ngIf="show">
			<canvas baseChart #baseChart="base-chart"
				[datasets]="lineChartData"
				[labels]="lineChartLabels"
				[options]="lineChartOptions"
				[colors]="lineChartColors"
				[legend]="lineChartLegend"
				[chartType]="lineChartType"
				[plugins]="lineChartPlugins">
			</canvas>
		</div>
	</div>
</div>