import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private communicationService: CommunicationService) { }

  passwordOptimize(err, page = 'signup') {
    const arr = err.split('.');
    arr.pop(arr.length - 1);
    let errMsg = '';
    let errOther = '';

    let i = 1;
    arr.forEach((element = []) => {
      if (arr.length !== 1 && arr.length === i) {
        errMsg += ' and ';
      } else if (i === 1) {
        errMsg += 'The password must contain at least ';
      }

      if (element.includes('10 characters long')) {
        if (i !== 1 && arr.length !== i) {
          errMsg += ', ';
        }
        errMsg += '10 characters';
      } else if (element.includes('one lowercase letter')) {
        if (i !== 1 && arr.length !== i) {
          errMsg += ', ';
        }
        errMsg += 'one lowercase letter';
      } else if (element.includes('one number')) {
        if (i !== 1 && arr.length !== i) {
          errMsg += ', ';
        }
        errMsg += 'one number';
      } else if (element.includes('one special character')) {
        if (i !== 1 && arr.length !== i) {
          errMsg += ', ';
        }
        errMsg += 'one special character';
      } else if (element.includes('one uppercase letter')) {
        if (i !== 1 && arr.length !== i) {
          errMsg += ', ';
        }
        errMsg += 'one uppercase letter';
      } else {
        errOther += '\n' + element + '.';
      }
      i++;
    });

    if (errMsg === 'The password must contain at least ') {
      errMsg = '';
    }

    if (errMsg) {
      errMsg += '.' + errOther;
    } else {
      errMsg += errOther;
    }

    return errMsg;
  }

  isValidJSON(str, location) {
    try {
      JSON.parse(str);
    } catch (e) {
      this.communicationService.openAlert({ alertType: 'error', alertMsg: `${location} JSON is invalid.` });
      return false;
    }
    return true;
  }
}
