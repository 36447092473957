import { Hierarchy } from './hierarchy.model';
import * as HierarchyAction from './hierarchy.action';

export interface HierarchyState {
    readonly hierarchy: Hierarchy;
}

export function hierarchyReducer(state: Hierarchy[] = [], action: HierarchyAction.HierarchyActions) {
    switch (action.type) {
        case HierarchyAction.GET_HIERARCHY:
            return action.payload;
		case HierarchyAction.CLEAR_HIERARCHY:
			return action.payload;
        default: return state;
    }
}
