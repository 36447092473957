import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private alert = new Subject<any>();
  private iccidList = new Subject<any>();
  private apiStatus = new Subject<any>();
  private file = new Subject<any>();
  private screenMode = new Subject<any>();
  private customerName = new Subject<any>();
  private language = new Subject<any>();
  private customer = new Subject<any>();
  private customerList = new Subject<any>();
  private username = new Subject<any>();
  private dashboardInventory = new Subject<any>();
  private dashboard = new Subject<any>();

  openAlert(alertData: { alertType: string, alertMsg: string }) {
    this.alert.next(alertData);
  }

  getAlertStatus(): Observable<any> {
    return this.alert.asObservable();
  }

  sendIccidList(iccidList: { iccidList: any[] }) {
    this.iccidList.next(iccidList);
  }

  getIccidList(): Observable<any[]> {
    return this.iccidList.asObservable();
  }

  sendApiStatusToDashboard(apiStatus) {
    this.apiStatus.next(apiStatus);
  }

  getApiStatusToDashboard(): Observable<any[]> {
    return this.apiStatus.asObservable();
  }

  sendPreviewClick(file) {
    this.file.next(file);
  }

  getPreviewClick(): Observable<any[]> {
    return this.file.asObservable();
  }

  sendScreenMode(mode) {
    this.screenMode.next(mode);
  }

  getScreenMode(): Observable<any[]> {
    return this.screenMode.asObservable();
  }

  sendLogo(file) {
    this.screenMode.next(file);
  }

  getLogo(): Observable<any[]> {
    return this.screenMode.asObservable();
  }

  sendCustomer(customer) {
    this.customerName.next(customer);
  }

  getCustomer(): Observable<any[]> {
    return this.customerName.asObservable();
  }

  setLanguage(language) {
    this.language.next(language);
  }

  getLanguage(): Observable<any[]> {
    return this.language.asObservable();
  }

  setUserName(username) {
    this.username.next(username);
  }

  getUserName(): Observable<any[]> {
    return this.username.asObservable();
  }

  sendRemovedCustomer() {
    this.customer.next();
  }

  getRemovedCustomer(): Observable<any[]> {
    return this.customer.asObservable();
  }

  sendCustomerList() {
    this.customerList.next();
  }

  getCustomerList(): Observable<any[]> {
    return this.customerList.asObservable();
  }

  setDashboardInventory(data) {
    this.dashboardInventory.next(data);
  }

  getDashboardInventory(): Observable<any[]> {
    return this.dashboardInventory.asObservable();
  }

  sendRefreshDashboardAction() {
    this.dashboard.next();
  }

  getRefreshDashboardAction(): Observable<any[]> {
    return this.dashboard.asObservable();
  }
}
