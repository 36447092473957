import { Action } from '@ngrx/store';
import { Hierarchy } from './hierarchy.model';

export const GET_HIERARCHY = 'GET_HIERARCHY';
export const CLEAR_HIERARCHY = 'CLEAR_HIERARCHY';

export class GetHierarchy implements Action {
    readonly type = GET_HIERARCHY;
    constructor(public payload: Hierarchy) { }
}

export class ClearHierarchy implements Action {
	readonly type = CLEAR_HIERARCHY;
	constructor(public payload: Hierarchy) { }
}
export type HierarchyActions = GetHierarchy | ClearHierarchy;