<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">{{'Role Management' | translate}}</h4>
                    <div class="d-flex ml-auto">
                        <button class="btn t-bgcolor outline" [routerLink]="['/access-management/role/create']"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Create New Role' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table t-table table-scroll">
                        <thead>
                            <tr>
                                <th>{{'Role Name' | translate}}</th>
                                <th>{{'Created Name' | translate}}</th>
                                <th>{{'No. of Users' | translate}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="loader-flex-center" *ngIf="roleSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </tbody>
                        <tbody *ngIf="roleList && roleList.length <= 0 && !roleSpinner">
                            <tr>
                                <td colspan="5" class="text-center">{{'No Role Found' | translate}}.</td>
                            </tr>
                        </tbody>
                        <tbody style="max-height: calc(95vh - 200px);">
                            <tr *ngFor="let role of roleList; let i = index">
                                <td>{{role.roleName}}</td>
                                <td>{{role.name}}</td>
                                <td>{{role.userCount}}</td>
                                <td class="profile-manage-icon-row">
                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" [routerLink]="['/access-management/role/edit', role.roleId]"></i>
                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deletRoleModal" (click)="selectedRole = { roleName: role.roleName, roleId: role.roleId, index: i}"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deletRoleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delete Confirmation' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/delete.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p *ngIf="selectedRole">{{'Are you sure you want to delete' | translate}} '{{selectedRole.roleName}}'?</p>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" (click)="deleteRole()"> {{'Delete Now' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>