import { Action } from '@ngrx/store';
import { Logo } from './logo.model';

export const GET_LOGO = 'GET_LOGO';

export class GetLogo implements Action {
    readonly type = GET_LOGO;

    constructor(public payload: Logo) { }
}

export type LogoActions = GetLogo;
