import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getApiList() {
    return this.http.get(`${this.serverUrl}/api`, this.getHeader())
      .pipe(map(response => response));
  }

  getSingleApi(id) {
    return this.http.get(`${this.serverUrl}/api/${id}`, this.getHeader())
      .pipe(map(response => response));
  }

  sendApiCall(id, body) {
    return this.http.post(`${this.serverUrl}/api/${id}`, body, this.getHeader())
      .pipe(map(response => response));
  }

  executeApi(id, body) {
    return this.http.post(`${this.serverUrl}/api/execute/${id}`, body, this.getHeader())
      .pipe(map(response => response));
  }

  getDashboardApis() {
    return this.http.get(`${this.serverUrl}/apidashboard`, this.getHeader())
      .pipe(map(response => response));
  }

  addApiToDashboard(id) {
    return this.http.post(`${this.serverUrl}/apidashboard`, { apiId: id }, this.getHeader())
      .pipe(map(response => response));
  }
}
