<ng-template #shapeTemplate>
    <svg:line *ngIf="hasPoints" attr.x1="{{ shape.points[0].x }}" attr.y1="{{ shape.points[0].y }}" attr.x2="{{ lastPoint.x }}"
        attr.y2="{{ lastPoint.y }}" [ngStyle]="setStyles()">
    </svg:line>

    <svg:g *ngIf="isSelected || isMoving">
        <svg:circle attr.cx="{{ shape.points[0].x }}" attr.cy="{{ shape.points[0].y }}" r="4" stroke="black"
            stroke-width="1" fill="green">
        </svg:circle>
        <svg:circle attr.cx="{{ shape.points[1].x }}" attr.cy="{{ shape.points[1].y }}" r="4" stroke="black"
            stroke-width="1" fill="green">
        </svg:circle>
        <svg:circle attr.cx="{{ controlPoint.x }}" attr.cy="{{ controlPoint.y }}" r="4" stroke="black" stroke-width="1"
            fill="green">
        </svg:circle>
    </svg:g>

    <svg:path attr.id="{{ shape.shapeProperties.name }}" attr.d="{{ value }}" class="draggable" [ngStyle]="setStyles()">
        <title>{{ shape.shapeProperties.name }}</title>
    </svg:path>
</ng-template>