import { Component, OnInit } from '@angular/core';
import { RoleMgmtService } from '../../shared/services/role-mgmt.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { UserState } from '../../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import * as UserAction from '../../shared/stores/user/user.action';
import { UserService } from '../../shared/services/user.service';
import { CookieTrailService } from 'src/app/shared/services/cookie-trail.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-role-mgmt',
  templateUrl: './role-mgmt.component.html',
  styleUrls: ['./role-mgmt.component.scss']
})
export class RoleMgmtComponent implements OnInit {

    roleList;
    roleSpinner = true;
    selectedRole;
    btnSpinner = false;

    constructor(
        private roleMgmtService: RoleMgmtService,
        private communicationService: CommunicationService,
        private userService: UserService,
        private userStore: Store<UserState>,
        private cookieTrailService: CookieTrailService,
        private translate: TranslateService) {
            translate.addLangs(environment.languageArray);
            translate.setDefaultLang('en');
            let browserLang = localStorage.getItem('language');
            if (!browserLang) {
                browserLang = 'en';
                localStorage.setItem('language', browserLang);
            }
            translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
        }

    ngOnInit() {
        this.cookieTrailService.saveCookie({});
        this.getAllRoles();

        this.communicationService.getLanguage()
            .subscribe((res: any) => {
                this.changeLanguage();
            });
    }

    changeLanguage() {
        const lang = localStorage.getItem('language');
        this.translate.use(lang);
    }

    getAllRoles() {
        this.roleSpinner = true;

        this.roleMgmtService.getAllRoles()
            .subscribe((res: any) => {
                this.roleSpinner = false;
                this.roleList = res;
            }, err => {
                const cookieTrail = {
                    screenName: 'Access Management : Role Management',
                    action: 'Get All Roles',
                    error: err.error || 'Something went wrong!'
                };
                  
                this.cookieTrailService.saveCookie(cookieTrail);
                this.roleSpinner = true;
                this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
            });
    }

    deleteRole() {
        this.btnSpinner = true;

        const cookieTrail = {
            screenName: 'Access Management : Role Management',
            action: 'Delete Role',
            body: {roleId: this.selectedRole.roleId}
        };
          
        this.cookieTrailService.saveCookie(cookieTrail);

        this.roleMgmtService.deleteRole(this.selectedRole.roleId)
            .subscribe((res: any) => {
                this.btnSpinner = false;
                $('#deletRoleModal').modal('hide');
                this.communicationService.openAlert({alertType: 'success', alertMsg: 'Role deleted successfully!'});
                this.roleList.splice(this.selectedRole.index, 1);
                this.userService.getUser()
                    .subscribe((res: any) => {
                        this.userStore.dispatch(new UserAction.GetUser(res));
                    }, err => {
                        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
                    });
            }, err => {
                this.btnSpinner = false;
                this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
            })
    }
}
