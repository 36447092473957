<div class="inner-main-profile">
    <div class="face-container-inner-bg p-3 mb-3">
        <div class="date-n-search-row">
            <div class="search-container">
                <div class="search-inner-container" style="min-width: 700px;">
                    <input type="text" class="form-control c-input-serch sg-font-regular" [(ngModel)]="searchTerm" placeholder="{{'Search by ICCID / EID / TAC number' | translate}}" (keypress)="isEnter($event)">
                    <button class="btn c-btn profile-info-tabs flex-shrink-0 sg-font-regular" (click)="getProfiles()"><i class="fa fa-search mr-2"></i> {{ "Search" | translate}}</button>
                </div>
            </div>
            <div class="right-btn-container" [ngClass]="{'right-btn-container-padding-right': !(features[getFeatureIndex('prp_setting', 'profile')].edit && features && features[getFeatureIndex('reset_single', 'profile')].edit)}" *ngIf="features && (features[getFeatureIndex('prepare_single', 'profile')].edit || features[getFeatureIndex('reset_single', 'profile')].edit || features[getFeatureIndex('qrcode_single', 'profile')].edit)">
                <button class="btn c-btn-action outline" id="op-prepareProfile" tooltip="{{'Prepare and Release Profile' | translate}}" [tooltipAnimation]="false" placement="top" (click)="activeButton('op-prepareProfile')" *ngIf="!syncPrepareBtnSpinner && features && features[getFeatureIndex('prepare_single', 'profile')].edit">
                    <img src="assets/images/csap-icons/test-w.png" alt="">
                </button>
                <div class="btn-spinner" *ngIf="syncPrepareBtnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <button class="btn c-btn-action mr-3 outline" id="op-prpSetting" *ngIf="!syncPrpBtnSpinner && features && features[getFeatureIndex('prp_setting', 'profile')].edit" tooltip="{{'Profile Reuse Policy Setting' | translate}}" [tooltipAnimation]="false" placement="top" (click)="activeButton('op-prpSetting')">
                    <img src="assets/images/csap-icons/prp.png" alt="">
                </button>
                <div class="btn-spinner" *ngIf="syncPrpBtnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <button class="btn c-btn-action mr-3 outline" id="op-resetProfile" tooltip="{{'Reset Profile' | translate}}" [tooltipAnimation]="false" placement="top" (click)="activeButton('op-resetProfile')"  *ngIf="!syncResetBtnSpinner && features && features[getFeatureIndex('reset_single', 'profile')].edit">
                    <img src="assets/images/csap-icons/undo-w.png" alt="">
                </button>
                <div class="btn-spinner" *ngIf="syncResetBtnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <button class="btn c-btn-action mr-3 outline" id="op-qrCode" tooltip="{{'Get QR Code' | translate}}" [tooltipAnimation]="false" placement="top" (click)="activeButton('op-qrCode')" [disabled]="disableQrCode" *ngIf="!syncQrBtnSpinner && features && features[getFeatureIndex('qrcode_single', 'profile')].edit">
                    <img src="assets/images/csap-icons/qr-code-w.png" alt="">
                </button>
                <div class="btn-spinner" *ngIf="syncQrBtnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <!-- <div class="dropdown api-tree-dropdown">
                    <button class="btn c-btn-action" id="op-qrCode" data-toggle="tooltip" data-placement="top" title="Api Tree" id="apiTreeDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg class="ml-auto api-tree-title" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg>                
                        <i class="fa fa-ellipsis-v t-gray-api"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="apiTreeDropdown">
                        <a class="dropdown-item mb-2" href="#">
                            All Added APIs
                        </a>
                        <div class="api-tree-dropdown-overflow">
                            <a class="dropdown-item" [routerLink]="['/api', api.apiId]" *ngFor="let api of apiList">
                                <svg class="ml-auto api-tree-title" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg>
                                <span>{{api.apiName}}</span>
                            </a>
                        </div>
                    </div>
                </div> -->

                <!-- <button class="btn c-btn-action" data-toggle="tooltip" data-placement="top" title="Group"> 
                <img data-toggle="modal" href="#inProgressModal" src="assets/images/csap-icons/group-w.png" alt="">
            </button> -->
            </div>
            <div class="d-flex flex-wrap justify-content-end api-tree-tooltip" style="max-width: 1000px;
            margin-bottom: 15px;margin-top: 4px;margin-right:0px;" *ngIf="apiFeatures && apiFeatures[getFeatureIndex('api_bookmark', 'api')].view">
                <div *ngFor="let api of apiList; let i = index;">
                    <!-- <button class="btn btn-primary api-tree-btn d-flex justify-content-center align-items-cente" [routerLink]="['/api', api.apiId]" *ngIf="(i + 1) <= noElement"> -->
                    <!-- <svg class="api-tree-title mr-2" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg>  -->
                    <!-- <span>{{api.apiName | limitTo: 13}}</span> -->
                    <!-- <img src="assets/images/csap-icons/qr-code-w.png" alt="">
                    </button> -->

                    <button style="width: 45px;" data-toggle="tooltip" data-placement="top" [title]="api.apiName" [routerLink]="['/api', api.apiId]" class="btn api-tree-btn d-flex justify-content-center align-items-cente t-bgcolor" id="op-qrCode" *ngIf="(i + 1) <= noElement">
                        <img src="{{api.light}}" style="width: 20px;" alt="" *ngIf="api.light">
                        <svg class=""  style="width: 24px;" *ngIf="!api.light" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg> 
                    </button>
                </div>

            </div>
            <div class="btn btn-block btn-spinner" [ngStyle]="{'flex': !apiDisplay ? '1' : '0', 'display': !apiDisplay ? 'block' : 'none'}" style="flex:1" id="apiElementView" #apiElementView>
                <div class="loader quantum-spinner m-auto" *ngIf="!apiDisplay"></div>
            </div>
            <div>
                <div class="dropdown api-tree-dropdown" style="margin-top: 4px;
                background: gray;
                border: 1px solid gray;
                border-radius: 4px;" *ngIf="apiDisplay && apiList && (apiList.length > noElement) && apiFeatures && apiFeatures[getFeatureIndex('api_bookmark', 'api')].view">
                    <button class="btn c-btn-action p-0" style="box-shadow: none;box-shadow: none;
                    width: 45px;
                    height: 39px;
                    font-size: 25px;" id="op-qrCode" data-toggle="tooltip" data-placement="top" title="Api Tree" id="apiTreeDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <!-- <svg class="ml-auto api-tree-title" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg>                 -->
                        <!-- <i class="fa fa-ellipsis-v t-gray-api"></i> -->
                        <i class="fa fa-angle-double-down t-gray-api"></i>
                    </button>
                    <div class="dropdown-menu" style="width: 50px; min-width: 60px; transform: translate3d(-15px, 44px, 0px) !important;" aria-labelledby="apiTreeDropdown">
                        <!-- <a class="dropdown-item mb-2" href="#">
                            All Added APIs
                        </a>
                        <div class="api-tree-dropdown-overflow">
                            <div *ngFor="let api of apiList; let i = index;">
                                <a class="dropdown-item" [routerLink]="['/api', api.apiId]" *ngIf="i >= noElement">
                                    <svg class="ml-auto api-tree-title" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg>
                                    <span>{{api.apiName}}</span>
                                </a>
                            </div>
                        </div> -->
                        <div class="api-tree-dropdown-overflow">
                            <div *ngFor="let api of apiList; let i = index;">
                                <a class="dropdown-item btn t-bgcolor" data-toggle="tooltip" data-placement="left" [title]="api.apiName" [routerLink]="['/api', api.apiId]" *ngIf="i >= noElement" style="padding: 0px;
                                    width: 45px;
                                    height: 39px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-bottom:10px;
                                    margin-left: 6px;">
                                    <!-- <svg class="ml-auto api-tree-title" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg> -->
                                    <!-- <span>{{api.apiName}}</span> -->
                                    <img src="{{api.light}}" style="width: 20px;" alt="" *ngIf="api.light">
                                    <svg class="" style="width: 24px;" *ngIf="!api.light" xmlns="http://www.w3.org/2000/svg" width="29.191" height="25" viewBox="0 0 29.191 25"><defs><style>.a{fill:#fff;stroke:gray;}</style></defs><g transform="translate(0.5 0.5)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path class="a" d="M25.231,73.56H2.96A2.963,2.963,0,0,0,0,76.52V94.6a2.963,2.963,0,0,0,2.96,2.96H25.231a2.963,2.963,0,0,0,2.96-2.96V76.52A2.963,2.963,0,0,0,25.231,73.56Zm.987,21.04a.988.988,0,0,1-.987.987H2.96a.988.988,0,0,1-.987-.987V80.427H26.218Zm0-16.146H1.973V76.52a.988.988,0,0,1,.987-.987H25.231a.988.988,0,0,1,.987.987Z" transform="translate(0 -73.56)"/></g></g><g transform="translate(5.363 9.452)"><g transform="translate(0 0)"><path class="a" d="M126.42,217.264a3.2,3.2,0,0,0-3.191,3.191v4.306a.987.987,0,1,0,1.973,0v-1.87h2.435v1.87a.987.987,0,0,0,1.973,0v-4.306A3.194,3.194,0,0,0,126.42,217.264Zm1.218,3.653H125.2v-.462h0a1.218,1.218,0,0,1,2.435,0Z" transform="translate(-123.229 -217.264)"/></g></g><g transform="translate(13.109 9.452)"><g transform="translate(0 0)"><path class="a" d="M244.569,217.264h-2.582a.987.987,0,0,0-.987.987v6.51a.987.987,0,1,0,1.973,0v-1.87h1.6a2.813,2.813,0,1,0,0-5.626Zm0,3.653h-1.6v-1.68h1.6a.84.84,0,1,1,0,1.68Z" transform="translate(-241 -217.264)"/></g></g><g transform="translate(20.855 9.452)"><g transform="translate(0 0)"><path class="a" d="M359.758,217.264a.987.987,0,0,0-.987.987v6.51a.987.987,0,0,0,1.973,0v-6.51A.987.987,0,0,0,359.758,217.264Z" transform="translate(-358.771 -217.264)"/></g></g></g></svg>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="filter-tags-row-new">
            <div class="profile-status-checkbox-row">
                <div class="checkbox-perent-filter" *ngFor="let status of statusArray; let i = index">
                    <p class="checkbox-perent-filter-inner sg-font-small-regular" [ngClass]="{'checkbox-perent-filter-inner-checked': status.checked}" (click)="status.checked = !status.checked;selectStatus(i)"> {{status.key}} <span> <i class="fa fa-plus ml-2" *ngIf="!status.checked"></i> <i class="fa fa-check ml-2" *ngIf="status.checked"></i></span></p>
                </div>
            </div>
        </div>
        <div class="date-n-status-row">
            <div class="date-n-status-row-date-container mr-5">
                <div class="date-n-status-row-date-container-inner">
                    <div class="date-n-status-row-date-container-inner-subtitle">
                        <p class="t-font-light sg-font-regular">{{ "From" | translate}}: </p>
                    </div>
                    <input class="c-acc-input sg-font-regular" [(ngModel)]="fromDate" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" (ngModelChange)="getProfiles();seletDate('fromDate')">
                    <owl-date-time #dt1 [hour12Timer]='flg'></owl-date-time>
                </div>
                <div class="date-n-status-row-date-container-inner">
                    <div class="date-n-status-row-date-container-inner-subtitle">
                        <p class="t-font-light sg-font-regular">{{ "To" | translate}}: </p>
                    </div>
                    <input class="c-acc-input sg-font-regular" [(ngModel)]="toDate" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" (ngModelChange)="getProfiles();seletDate('toDate')">
                    <owl-date-time #dt2 [hour12Timer]='flg'></owl-date-time>
                </div>
                <div class="py-1 c-color cursor-p sg-font-regular" (click)="fromDate = '';toDate = '';getProfiles();">
                    {{ "Clear Date" | translate}}
                </div>
            </div>
            <!-- <div class="profile-type-lock-status py-1">
                <div class="profile-status-title mr-2 t-font-light">
                    Profile Type Lock Status:
                </div>
                <div class="filter-box-checkbox-container d-flex t-font-light">
                    <div *ngFor="let status of profileLockStatus" class="profile-lock-status">
                        <mat-checkbox [(ngModel)]="status.checked" (click)="getProfiles();">{{status.name}}</mat-checkbox>
                    </div>
                </div>
            </div> -->
            <div class="ml-auto c-color cursor-p sg-font-regular" (click)="clearFilters()">
                {{ "Clear Filter" | translate}}
            </div>
        </div>
    </div>
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg profile-manage-table">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading sg-font-regular">
                        {{ "Result" | translate}}: {{profileCount}} {{ "Found" | translate}}
                        <span class="ml-4 text-muted t-font-light sg-font-regular">{{ "Selected" | translate}}: {{ selectAllIccid ? selectedProfileCount : selectedIccidList.length}}</span>
                    </h4>
                    <div class="ml-auto t-color cursor-p" (click)="getProfiles()"><i class="fa fa-refresh mr-2"></i>{{ "Refresh" | translate}}</div>
                    <div class="dropdown field-selection ml-3">
                        <button class="btn dropdown-toggle t-font-light field-types-container" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="mr-3 sg-font-regular">{{ "Field Selection" | translate}}</span></button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="showList($event)">
                            <a class="dropdown-item pl-2 sg-font-regular" *ngFor="let item of fieldList" (click)="updateTableColums(item)" [ngClass]="{'t-color': !item.selected, 'text-white': item.selected}">
                                <i class="fa cursor-p mr-3" [ngClass]="{'fa-plus t-color': !item.selected, 'fa-check text-white': item.selected}" aria-hidden="true"></i>{{ item.name | translate }}
                            </a>
                        </div>
                      </div>
                </div>
                <div class="t-grid-table-new">
                    <div class="t-grid-table-wrapper t-head-bg border-bottom-light" [ngClass]="{'t-grid-table-wrapper-remove-tac': !showTac}" [ngStyle]="getStyles()">
                        <div class="t-box b">
                            <div class="filter-box-checkbox-container">
                                <mat-checkbox *ngIf="profileList && (profileList.lenght <= 0)" [disabled]="true"></mat-checkbox>
                                <mat-checkbox [(ngModel)]="select" (ngModelChange)="selectAllIccid = select; selectAllRows();getSelectedCount();" *ngIf="profileList && (profileList.length > 0)"></mat-checkbox>
                            </div>
                        </div>
                        <div class="t-box b">
                            <span class="mr-2">{{ "ICCID" | translate}}</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'ICCID' && orderBy === 'ASC'}" (click)="sortBy = 'ICCID'; orderBy = 'ASC'; getProfiles();"></i><i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'ICCID' && orderBy === 'DESC'}" (click)="sortBy = 'ICCID'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Type')">
                            <span class="mr-2">{{ "Type" | translate}}</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'PROFILE_TYPE' && orderBy === 'ASC'}" (click)="sortBy = 'PROFILE_TYPE'; orderBy = 'ASC'; getProfiles();"></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'PROFILE_TYPE' && orderBy === 'DESC'}" (click)="sortBy = 'PROFILE_TYPE'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Status')">
                            <span class="mr-2">{{ "Status" | translate}}</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'STATUS' && orderBy === 'ASC'}" (click)="sortBy = 'STATUS'; orderBy = 'ASC'; getProfiles();"></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'STATUS' && orderBy === 'DESC'}" (click)="sortBy = 'STATUS'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('Time-stamp')">
                            <span class="mr-2">{{ "Update On" | translate}} (GMT)</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'LAST_UPDATE' && orderBy === 'ASC'}" (click)="sortBy = 'LAST_UPDATE'; orderBy = 'ASC'; getProfiles();"></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'LAST_UPDATE' && orderBy === 'DESC'}" (click)="sortBy = 'LAST_UPDATE'; orderBy = 'DESC';getProfiles();"></i>
                        </div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('PRP')">{{'PRP' | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('PRP Count')">{{'PRP Count' | translate}}</div>
                        <div class="t-box c" *ngIf="activeTableColumns.includes('EID')">{{'EID' | translate}}</div>
                        <div class="t-box c" *ngIf="showTac && activeTableColumns.includes('TAC')">{{'TAC' | translate}}</div>
                        <div class="t-box c" *ngIf="showTac && activeTableColumns.includes('Device')">{{'Device Model' | translate}}</div>
                        <div class="t-box c"></div>
                    </div>
                    <div class="loader-flex-center" *ngIf="profileSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                    <div *ngIf="profileList && profileList.length <= 0 && !profileSpinner">
                        <div class="text-center mt-2 mb-2">
                            <div class="text-center">{{'No Data Found' | translate}}.</div>
                        </div>
                    </div>
                    <div class="t-body-overflow accordion profile-table-tbody" id="profileAccordian" *ngIf="!profileSpinner" infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()"
                    [scrollWindow]="false">
                        <ng-container *ngFor="let profile of profileList;let i = index;">
                            <div class="t-grid-table-wrapper t-body-bg border-bottom-light" [ngClass]="{'t-grid-table-wrapper-remove-tac': !showTac}" [ngStyle]="getStyles()">
                                <div class="t-box d t-font-light filter-box-checkbox-container dynamic-table">
                                    <mat-checkbox [(ngModel)]="profile.checked" (ngModelChange)="selectCheck(profile.checked, profile.iccid, profile.state, profile.prp, profile.prpCounter);"></mat-checkbox>
                                </div>
                                <div class="t-box d t-font-light dynamic-table">{{profile.iccid}}</div>
                                <div class="t-box d t-font-light dynamic-tablet" *ngIf="activeTableColumns.includes('Type')">{{profile.profileType}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="activeTableColumns.includes('Status')">{{profile.state}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="activeTableColumns.includes('Time-stamp')">{{profile.updatedOn}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="activeTableColumns.includes('PRP')">{{profile.prp}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="activeTableColumns.includes('PRP Count')">{{profile.prpCounter}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="activeTableColumns.includes('EID')">{{profile.eid}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="showTac && activeTableColumns.includes('TAC')">{{profile.tac}}</div>
                                <div class="t-box d t-font-light dynamic-table" *ngIf="showTac && activeTableColumns.includes('Device')">{{profile.model}}</div>
                                <div class="t-box d t-color d-flex dynamic-table">
                                    <!-- <a class="t-font-color c-info py-0 c-color"><i class="fa fa-info mr-2"></i> </a>{{ "info" | translate}} -->
                                    <a class="t-font-color c-info py-0 c-color" data-toggle="collapse" href="#collapseOne_{{i}}" aria-expanded="true" aria-controls="collapseOne" (click)="features && features[getFeatureIndex('logs', 'profile')].view ? getLogs(profile.iccid, i, 'log') : features[getFeatureIndex('operations', 'profile')].view ? getOperations(profile.iccid, i, 'opr') : features[getFeatureIndex('tac_info', 'profile')].view ? getTacInfo(profile.iccid, i, 'tac') : features[getFeatureIndex('prepare_smdp', 'profile')].edit ? getStatus(profile.iccid, i, 'status') : ''; logList = []; getICCIDInfo(profile.iccid)">
                                        <i class="fa fa-info mr-2"></i>
                                    </a>
                                </div>
                            </div>
                            <div id="collapseOne_{{i}}" class="collapse" aria-labelledby="headingOneA" data-parent="#profileAccordian">
                                <div colspan="5" class="py-0 d-flex">
                                    <div id="collapseOne" class="collapse show accordian-info" aria-labelledby="headingOne" data-parent="#accordionExample1">
                                        <div class="card-body info-new-operation">

                                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                <li class="nav-item info-btn-container-individual" style="border-top-left-radius: 5px;" 
                                                [ngStyle]="{'border-top-right-radius': features && !(features[getFeatureIndex('operations', 'profile')].view || features[getFeatureIndex('tac_info', 'profile')].view || features[getFeatureIndex('prepare_smdp', 'profile')].edit) ? '5px' : '0'}" (click)="getLogs(profile.iccid, i, 'log')" *ngIf="features && features[getFeatureIndex('logs', 'profile')].view">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-log-tab_{{i}}" data-toggle="pill" href="#info-log_{{i}}" role="tab" aria-controls="info-log" aria-selected="true">
                                                        {{ "Log" | translate}}
                                                    </a>
                                                </li>
                                                <li class="nav-item info-btn-container-individual" 
                                                [ngStyle]="{'border-top-left-radius': features && !features[getFeatureIndex('logs', 'profile')].view ? '5px' : '0', 'border-top-right-radius': features && !(features[getFeatureIndex('tac_info', 'profile')].view || features[getFeatureIndex('prepare_smdp', 'profile')].edit) ? '5px' : '0'}" (click)="getOperations(profile.iccid, i, 'opr')" *ngIf="features && features[getFeatureIndex('operations', 'profile')].view">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-opr-tab_{{i}}" data-toggle="pill" href="#info-opr_{{i}}" role="tab" aria-controls="info-opr" aria-selected="false">
                                                        {{ "Operations" | translate}}
                                                    </a>
                                                </li>
                                                <li class="nav-item info-btn-container-individual" 
                                                [ngStyle]="{'border-top-left-radius': features && !(features[getFeatureIndex('logs', 'profile')].view || features[getFeatureIndex('operations', 'profile')].view) ? '5px' : '0px', 'border-top-right-radius': features && !features[getFeatureIndex('prepare_smdp', 'profile')].edit ? '5px' : '0'}" (click)="getTacInfo(profile.iccid, i, 'tac')" *ngIf="features && features[getFeatureIndex('tac_info', 'profile')].view && showTac">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-tac-tab_{{i}}" data-toggle="pill" href="#info-tac_{{i}}" role="tab" aria-controls="info-tac" aria-selected="false">
                                                        {{ "TAC Info" | translate}}
                                                    </a>
                                                </li>
                                                <li class="nav-item info-btn-container-individual"
                                                [ngStyle]="{'border-top-left-radius': features && !(features[getFeatureIndex('logs', 'profile')].view || features[getFeatureIndex('operations', 'profile')].view || features[getFeatureIndex('tac_info', 'profile')].view) ? '5px' : '0px'}"
                                                style="border-top-right-radius: 5px;" *ngIf="features && features[getFeatureIndex('prepare_smdp', 'profile')].edit">
                                                    <a class="nav-link btn btn-logs profile-info-tabs" id="info-status-tab_{{i}}" data-toggle="pill" href="#info-status_{{i}}" role="tab" aria-controls="info-status" aria-selected="false" (click)="getStatus(profile.iccid, i, 'status')">
                                                        {{ "Status" | translate}}
                                                    </a>
                                                </li>
                                                <li class="download-n-close ml-auto">
                                                    <a class="filter-download c-color c-info" (click)="downloadQrCodeIccid = profile.iccid;getDefaultLogo();" data-toggle="modal" data-target="#downloadQrCodeModal" *ngIf="(defaultSmdp != null && !defaultSmdp) && profile.state === 'RELEASED' && features && features[getFeatureIndex('download_qrcode_single', 'profile')].edit"><i class="fa fa-download"></i> <span class="ml-2"> {{ "Download QR code" | translate}}</span></a>
                                                    <a class="inner-filter-table-close ml-3 t-font-light" data-toggle="collapse" href="#collapseOne_{{i}}">
                                                        X
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade" id="info-log_{{i}}" role="tabpanel" aria-labelledby="info-log-tab" *ngIf="features && features[getFeatureIndex('logs', 'profile')].view">
                                                    <table class="table t-table t-filter-inner-table table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{{ "Time Stamp" | translate}}</th>
                                                                <th scope="col">{{ "Action" | translate}}</th>
                                                                <th scope="col">{{ "Action Status" | translate}}</th>
                                                                <th scope="col">{{ "EID" | translate}}</th>
                                                                <th scope="col" *ngIf="showTac">{{ "TAC" | translate}}</th>
                                                                <th scope="col" *ngIf="showTac">{{ "Device Model" | translate}}</th>
                                                                <th scope="col">{{ "Notification Event" | translate}}</th>
                                                                <th scope="col">{{ "Result Code" | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                            <div class="loader quantum-spinner m-auto"></div>
                                                        </tbody>
                                                        <tbody *ngIf="logList && logList.length <= 0 && !infoSpinner">
                                                            <tr>
                                                                <td colspan="5" class="text-center">{{ "No Data Found" | translate}}.</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="!infoSpinner">
                                                            <tr *ngFor="let log of logList">
                                                                <td>{{log.date}}</td>
                                                                <td>{{log.action}}</td>
                                                                <td>{{log.data.status}}</td>
                                                                <td>{{log.data.eid}}</td>
                                                                <td *ngIf="showTac">{{log.data.tac}}</td>
                                                                <td *ngIf="showTac">{{log.data.model}}</td>
                                                                <td>{{log.data.notificationEvent}}</td>
                                                                <td>{{log.data.resultCode}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="tab-pane fade" id="info-tac_{{i}}" role="tabpanel" aria-labelledby="info-tac-tab" *ngIf="features && features[getFeatureIndex('tac_info', 'profile')].view">
                                                    <table class="table t-table t-filter-inner-table table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{{ "TAC" | translate}}</th>
                                                                <th scope="col">{{ "Brand" | translate}}</th>
                                                                <th scope="col">{{ "Manufacturer" | translate}}</th>
                                                                <th scope="col">{{ "Model" | translate}}</th>
                                                                <th scope="col">{{ "Name" | translate}}</th>
                                                                <th scope="col">{{ "NFC" | translate}}</th>
                                                                <th scope="col">{{ "OS" | translate}}</th>
                                                                <th scope="col">{{ "Type" | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                            <div class="loader quantum-spinner m-auto"></div>
                                                        </tbody>
                                                        <tbody *ngIf="tac_info && tac_info.length <= 0 && !infoSpinner">
                                                            <tr>
                                                                <td colspan="5" class="text-center">{{ "No Data Found" | translate}}.</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="!infoSpinner">
                                                            <tr *ngFor="let info of tac_info">
                                                                <td>{{info.tac}}</td>
                                                                <td>{{info.brand}}</td>
                                                                <td>{{info.manufacturer}}</td>
                                                                <td>{{info.model}}</td>
                                                                <td>{{info.name}}</td>
                                                                <td>{{info.nfc}}</td>
                                                                <td>{{info.os}}</td>
                                                                <td>{{info.type}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="tab-pane fade" id="info-opr_{{i}}" role="tabpanel" aria-labelledby="info-opr-tab" *ngIf="features && features[getFeatureIndex('operations', 'profile')].view">
                                                    <table class="table t-table t-filter-inner-table table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{{ "Time Stamp" | translate}}</th>
                                                                <th scope="col">{{ "Action" | translate}}</th>
                                                                <th scope="col">{{ "User" | translate}}</th>
                                                                <th scope="col">{{ "Status" | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="loader-flex-center" *ngIf="infoSpinner">
                                                            <div class="loader quantum-spinner m-auto"></div>
                                                        </tbody>
                                                        <tbody *ngIf="operationList && operationList.length <= 0 && !infoSpinner">
                                                            <tr>
                                                                <td colspan="5" class="text-center">{{ "No Data Found" | translate}}.</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="!infoSpinner">
                                                            <tr *ngFor="let opr of operationList">
                                                                <td>{{opr.created}}</td>
                                                                <td>{{opr.action}}</td>
                                                                <td>{{opr.email}}</td>
                                                                <td>{{opr.status}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="tab-pane fade" id="info-status_{{i}}" role="tabpanel" aria-labelledby="status-tac-tab">
                                                    <div class="profile-manage-table">
                                                        <div class="t-grid-table-new">
                                                            <div class="t-grid-status-table-wrapper t-head-bg">
                                                                <div class="t-box a border-lf-ext-light">{{'Status' | translate}}</div>
                                                                <div class="t-box b border-lf-ext-light border-rt-ext-light">{{'Release Type' | translate}}</div>
                                                            </div>
                                                            <div class="t-profile-status-overflow">
                                                                <div class="t-grid-status-table-wrapper t-body-bg border-bt-ext-light">
                                                                    <div class="t-box d t-font-light border-lf-ext-light">{{profile.state | titlecase}}</div>
                                                                    <div class="t-box d t-font-light border-lf-ext-light border-rt-ext-light">
                                                                        <span *ngIf="(profile.state === 'RELEASED' || profile.state === 'DOWNLOADED' || profile.state === 'INSTALLED' || profile.state === 'ENABLED') && (defaultSmdp != null && defaultSmdp)">Default SM-DP+</span>
                                                                        <span *ngIf="(profile.state === 'RELEASED' || profile.state === 'DOWNLOADED' || profile.state === 'INSTALLED' || profile.state === 'ENABLED') && (defaultSmdp != null && !defaultSmdp)">With QRCode</span>
                                                                        <span *ngIf="!((profile.state === 'RELEASED' || profile.state === 'DOWNLOADED' || profile.state === 'INSTALLED' || profile.state === 'ENABLED'))">NA</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="btn-spinner py-3" *ngIf="profileInfiniteSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="prepareProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title ml-2" id="exampleModalLabel">{{ "Prepare Profile" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4">
                <div>
                    <p *ngIf="selectedIccidList.length === 1">{{ "ICCID" | translate}}: {{selectedIccidList[0].iccid}}</p>
                    <p *ngIf="selectedIccidList.length > 1 || selectAllIccid">{{ "ICCID" | translate}}: {{selectAllIccid ? selectedProfileCount : selectedIccidList.length}} {{ "Selected" | translate}}</p>
                    <ul class="device-flex nav nav-pills mb-4 text-center" id="pills-tab" role="tablist">
                        <li class="nav-item" (click)="openTab();">
                            <a class="nav-link active" id="pills-without-device-tab" data-toggle="pill" style="height:100%;" href="#pills-without-device" role="tab" aria-controls="pills-without-device" aria-selected="true" (click)="qrCodeDownloadErr = false; errEmailDwn= false; errEidMsg=false; errMsg=false;">
                                <!-- <img class="device-img-title" src="assets/images/icons/without-device.png" alt="">  -->
                                <svg id="Capa_1" data-name="Capa 1" width="25" height="22" style="fill: #FFFFFF;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380.93 512">
                                    <path d="M291.01,0H86.21c-28.22,0-51.2,22.98-51.2,51.2V460.8c0,28.24,22.98,51.2,51.2,51.2h204.8c28.24,0,51.2-22.96,51.2-51.2V51.2c0-28.22-22.96-51.2-51.2-51.2Zm30.72,460.8c0,16.94-13.78,30.72-30.72,30.72H86.21c-16.94,0-30.72-13.78-30.72-30.72V51.2c0-16.94,13.78-30.72,30.72-30.72h204.8c16.94,0,30.72,13.78,30.72,30.72V460.8h0Z"/>
                                    <path d="M209.09,40.96h-40.96c-5.65,0-10.24,4.59-10.24,10.24s4.59,10.24,10.24,10.24h40.96c5.65,0,10.24-4.59,10.24-10.24s-4.59-10.24-10.24-10.24Z"/>
                                    <path d="M188.61,450.56c-5.65,0-10.24,4.59-10.24,10.24s4.59,10.24,10.24,10.24,10.24-4.59,10.24-10.24-4.59-10.24-10.24-10.24Z"/>
                                    <path d="M331.97,81.92H45.25c-5.65,0-10.24,4.59-10.24,10.24s4.59,10.24,10.24,10.24H331.97c5.65,0,10.24-4.59,10.24-10.24,0-5.65-4.59-10.24-10.24-10.24Z"/>
                                    <path d="M331.97,409.6H45.25c-5.65,0-10.24,4.59-10.24,10.24s4.59,10.24,10.24,10.24H331.97c5.65,0,10.24-4.59,10.24-10.24s-4.59-10.24-10.24-10.24Z"/>
                                    <rect x="180.74" y="-19.16" width="19.45" height="530.65" rx="9.73" ry="9.73" transform="translate(229.85 -62.58) rotate(45)"/>
                                </svg>
                                {{ "Without Device" | translate}}
                            </a>
                        </li>
                        <li class="nav-item" (click)="openTab()">
                            <a class="nav-link device-flex-title" id="pills-with-device-tab" data-toggle="pill" href="#pills-with-device" role="tab" aria-controls="pills-with-device" aria-selected="false" (click)="qrCodeDownloadErr = false; errEmailDwn= false; errEidMsg=false; errMsg=false;">
                                <!-- <img class="device-img-title" src="assets/images/icons/with-device.png" alt="">  -->
                                <svg version="1.1" id="Capa_1" width="25" height="22" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill: #FFFFFF;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <path d="M358.4,0H153.6c-28.221,0-51.2,22.979-51.2,51.2v409.6c0,28.242,22.979,51.2,51.2,51.2h204.8
                                                c28.242,0,51.2-22.958,51.2-51.2V51.2C409.6,22.979,386.642,0,358.4,0z M389.12,460.8c0,16.937-13.783,30.72-30.72,30.72H153.6
                                                c-16.937,0-30.72-13.783-30.72-30.72V51.2c0-16.937,13.783-30.72,30.72-30.72h204.8c16.937,0,30.72,13.783,30.72,30.72V460.8z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M276.48,40.96h-40.96c-5.653,0-10.24,4.588-10.24,10.24s4.588,10.24,10.24,10.24h40.96c5.652,0,10.24-4.588,10.24-10.24
                                                S282.132,40.96,276.48,40.96z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M256,450.56c-5.652,0-10.24,4.588-10.24,10.24s4.588,10.24,10.24,10.24s10.24-4.588,10.24-10.24
                                                S261.652,450.56,256,450.56z"/>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M399.36,81.92H112.64c-5.653,0-10.24,4.588-10.24,10.24c0,5.653,4.587,10.24,10.24,10.24h286.72
                                                c5.652,0,10.24-4.588,10.24-10.24C409.6,86.508,405.012,81.92,399.36,81.92z"/>
                                        </g>
                                    </g>
                                    <g>
                                    <g>
                                        <path d="M399.36,409.6H112.64c-5.653,0-10.24,4.588-10.24,10.24s4.587,10.24,10.24,10.24h286.72c5.652,0,10.24-4.588,10.24-10.24
                                            S405.012,409.6,399.36,409.6z"/>
                                    </g>
                                    </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                                </svg>
                                {{ "With Device" | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content device-flex-body" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-without-device" role="tabpanel" aria-labelledby="pills-without-device-tab">
                        <!-- without device single start -->
                        <div *ngIf="selectedIccidList.length === 1">
                            <div class="confirmation-code-container-modal border-bt-ext-light">
                                <div class="form-group d-flex mt-3 mb-2 mb-0">
                                    <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                                    <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                                    <div class="form-group confirmation-code-inner">
                                        <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                                        <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                            [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                                        <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                                        <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                                            {{ "Confirmation code is required" | translate}}!
                                    </span>
                                        <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                                            {{ "Confirmation code must be 4 digits" | translate}}.
                                    </span>
                                    </div>
                                    <div class="form-group confirmation-code-inner confirmation-code-inner-radio mb-0">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                            <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                                {{code | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-code-dnld-option">
                                <p class="mt-3">
                                    {{ "QR Code Download Options" | translate}}:
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                                    <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group example-radio-group-modified" [(ngModel)]="qrCodeOption"> -->
                                        <div class="d-flex mb-3 align-items-center">
                                            <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                            <div class="pl-2 ml-4 mr-2">
                                                {{ 'Text' | translate}}
                                            </div>
                                            <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                                        </div>

                                        <div class="d-flex mb-3 align-items-center">
                                            <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                            <div class="pl-2 ml-4 mr-2">
                                                {{ 'QR Code' | translate}}
                                            </div>
                                            <div class="text-font-radio-top" *ngIf="qrCode"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                                        </div>
                                        
                                        <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                            <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                            <div class="pl-2 ml-4 mr-2">
                                                {{ 'QR Code with logo' | translate}}
                                            </div>
                                            <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                                            <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                                        </div>

                                        <!-- <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                            <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                            <div class="pl-2 ml-4 mr-2">
                                                {{ 'Display QR Code' | translate}}
                                            </div>
                                        </div> -->

                                        <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_prepare', 'profile')].edit">
                                            <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                            <div class="pl-2 ml-4 mr-3">
                                                {{ 'Campaign' | translate}}
                                            </div>
                                            <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                                <mat-form-field appearance="outline" style="width: 80px;">
                                                    <mat-select [(ngModel)]="selectedCampaignFormat">
                                                        <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                                            {{format}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="qrCodeWithCampaign">
                                                <mat-form-field appearance="outline" style="width: 170px;">
                                                    <mat-label>{{"Select Name" | translate}}</mat-label>
                                                    <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                                        <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                            {{template.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                                        </div>

                                        <!-- <div class="d-flex mb-3 align-items-center">
                                            <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                            <div class="pl-2 ml-4 mr-2">
                                                {{ 'LPA' | translate}}
                                            </div>
                                            <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                                        </div> -->

                                        <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>


                                    <!-- </mat-radio-group> -->
                                </div>
                                <p class="mt-3">
                                    {{ "Display QR Code Options" | translate}}:
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">

                                    <div class="d-flex">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" style="flex-direction: column;" [(ngModel)]="dispayOrCodeValueFlg" (ngModelChange)="checkDisplayQrOptions()">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of dispayOrCodeValueFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                                <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                                <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                    <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                </div>
                            </div> -->
                            <p class="mt-3">
                                {{ "QR Code Delivery Option:" | translate}}
                            </p>
                            <div class="filter-box-checkbox-container mb-4 d-flex">
                                <mat-checkbox [(ngModel)]="qrEmail" class="mr-4" (ngModelChange)="checkadditionalEmailFlg();errEmailDwn = false;"></mat-checkbox>{{ "Email" | translate}}
                                <p class="mb-0 ml-4 t-color" *ngIf="qrEmail" style="word-break: break-all;">{{user.email}}</p>
                            </div>
                            <div class="filter-box-checkbox-container mb-4 d-flex" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <mat-checkbox [(ngModel)]="qrAdditionalEmail" class="mr-4" (ngModelChange)="checkEmailFlg();errEmailDwn = false;"></mat-checkbox><label style="width: 215px;">{{ "Email Address of QR Recipient" | translate}}</label>
                                <div class="ml-5 w-75">
                                    <input type="email" class="t-input form-control" (keyup)="additionalEmailErr=false;" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" (ngModelChange)="checkAdditionalEmail()" #firstEmail="ngModel">
                                    <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                        <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                    </div>
                                    <small class="text-danger" *ngIf="additionalEmailErr">{{"Please provide email address" | translate}}</small>
                                </div>
                            </div>
                            <div class="filter-box-checkbox-container mb-3">
                                <mat-checkbox [(ngModel)]="qrDownload" (ngModelChange)="errEmailDwn = false;" class="mr-4"></mat-checkbox>{{ "Download" | translate}}
    
                            </div>

                            <span class="small text-danger" *ngIf="errEmailDwn">
                                {{ "Please select at least one option" | translate}}.
                            </span>
                            
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block profile-info-tabs mt-4" (click)="prepareProfile();" [disabled]="disable || (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <!-- without device single end -->
                        <!-- without device multiple start -->
                        <div *ngIf="selectedIccidList.length > 1 || selectAllIccid">
                            <div class="border-bt-ext-light">
                                <button class="btn device-flex-bodydevice-flex-body c-btn my-3 profile-info-tabs" style="white-space: nowrap;" *ngIf="!downloadWithoutDeviceLoader" (click)="downloadIccidCSV('prepare')"><i class="fa fa-download mr-2"></i>{{ "Download ICCID List" | translate}}</button>
                                <div class="btn-spinner" *ngIf="downloadWithoutDeviceLoader">
                                    <div class="loader quantum-spinner my-3" style="margin-left: 75px;"></div>
                                </div>
                            </div>
                            <div class="confirmation-code-container-modal border-bt-ext-light">
                                <div class="form-group d-flex mt-3 mb-2 mb-0">
                                    <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                                    <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                                    <div class="form-group confirmation-code-inner">
                                        <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                                        <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                            [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                                        <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                                        <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                                            {{ "Confirmation code is required" | translate}}!
                                    </span>
                                        <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                                            {{ "Confirmation code must be 4 digits" | translate}}.
                                    </span>
                                    </div>
                                    <div class="form-group confirmation-code-inner confirmation-code-inner-radio mb-0">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                            <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                                {{code | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-code-dnld-option">
                                <p class="mt-3">
                                    {{ "QR Code Download Options" | translate}}:
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'Text' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="qrCode"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                                    </div>
                                    
                                    <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code with logo' | translate}}
                                        </div>
                                        <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_prepare', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-3">
                                            {{ 'Campaign' | translate}}
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                            <mat-form-field appearance="outline" style="width: 80px;">
                                                <mat-select [(ngModel)]="selectedCampaignFormat">
                                                    <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                                        {{format}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign">
                                            <mat-form-field appearance="outline" style="width: 170px;">
                                                <mat-label>{{"Select Name" | translate}}</mat-label>
                                                <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                                    <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                        {{template.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                                    </div>

                                    <!-- <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'LPA' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                                    </div> -->

                                    <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>
                                </div>
                            </div>
                            <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                                <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                                <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                    <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                </div>
                            </div> -->
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block mt-4 profile-info-tabs" (click)="prepareProfile();" [disabled]="disable || (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <!-- without device multiple end -->
                        <!-- without iccid selection start -->
                        <div *ngIf="selectedIccidList.length === 0 && !selectAllIccid">
                            <div class="border-bt-ext-light">
                                <div class="with-divice-upload-btns">
                                    <div class="mr-2">
                                        <a class="btn btn-block device-flex-bodydevice-flex-body c-btn my-3 profile-info-tabs mr-2" style="white-space: nowrap;" href="assets/csv/sample-iccid.txt" download>
                                            <i class="fa fa-download mr-2"></i>{{ "Download Sample File" | translate}}
                                        </a>
                                    </div>
                                    <div class="mr-2">
                                        <button class="btn btn-block device-flex-bodydevice-flex-body c-btn w-100 mt-3 mb-1 d-block profile-info-tabs" style="white-space: nowrap;" (click)="uploadBulkProfile()">
                                            <i class="fa fa-upload" aria-hidden="true">
                                                <input id="bulk-profile" type="file" style="display: none" accept=".txt" (change)="onICCIDFileChange($event)">
                                            </i> {{ "Upload ICCID" | translate}}
                                        </button>
                                        <div class="mb-3" style="word-break: break-all;" *ngIf="fileNameErr">
                                            <span class="small text-danger">
                                                {{ "Please upload file" | translate}}.
                                            </span>
                                        </div>
                                        <div class="mb-3" style="word-break: break-all;">
                                            <p class="mb-0 text-center">{{eidFileName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="confirmation-code-container-modal border-bt-ext-light">
                                <div class="form-group d-flex mt-3 mb-2 mb-0">
                                    <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                                    <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                                    <div class="form-group confirmation-code-inner">
                                        <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                                        <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                            [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                                        <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                                        <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                                            {{ "Confirmation code is required" | translate}}!
                                    </span>
                                        <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                                            {{ "Confirmation code must be 4 digits" | translate}}.
                                    </span>
                                    </div>
                                    <div class="form-group confirmation-code-inner confirmation-code-inner-radio mb-0">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                            <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                                {{code | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-code-dnld-option">
                                <p class="mt-3">
                                    {{ "QR Code Download Options" | translate}}:
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'Text' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="qrCode"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                                    </div>
                                    
                                    <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code with logo' | translate}}
                                        </div>
                                        <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_prepare', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-3">
                                            {{ 'Campaign' | translate}}
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                            <mat-form-field appearance="outline" style="width: 80px;">
                                                <mat-select [(ngModel)]="selectedCampaignFormat">
                                                    <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                                        {{format}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign">
                                            <mat-form-field appearance="outline" style="width: 170px;">
                                                <mat-label>{{"Select Name" | translate}}</mat-label>
                                                <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                                    <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                        {{template.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                                    </div>

                                    <!-- <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'LPA' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                                    </div> -->

                                    <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>
                                </div>
                            </div>
                            <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                                <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                                <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                    <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                </div>
                            </div> -->
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block mt-4 profile-info-tabs" (click)="prepareBulkProfile();" [disabled]="disable || (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <!-- without iccid selection end -->
                    </div>
                    <div class="tab-pane fade" id="pills-with-device" role="tabpanel" aria-labelledby="pills-with-device-tab">
                        <!-- with device single start -->
                        <div class="form-group d-flex release-method-container pb-3 border-bt-ext-light mb-0" *ngIf="features && (features[getFeatureIndex('prepare_smdp', 'profile')].edit)">
                            <p class="mb-0 mr-3">{{ "Release Method:" | translate}}</p>
    
                            <div class="form-group mb-0">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedReleaseMethod" (ngModelChange)="onReleseMethodChange(); errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                    <mat-radio-button class="example-radio-button ml-2 mr-2" *ngFor="let rm of releaseMethod" [value]="rm.value">
                                        {{rm.label | translate}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div *ngIf="selectedIccidList.length === 1">
                            <div class="border-bt-ext-light pb-3 pt-2">
                                <label class="device-flex-label" for=" ">{{ "EID" | translate}}</label>
                                <input type="text" class="device-flex-input form-control" [(ngModel)]="eid" (keyup)="errEidMsg = false;eidLengthError = false" (keypress)="isNumber($event)">
                                <span class="small text-danger" *ngIf="errEidMsg">
                                    {{ "EID is required" | translate}}!
                                </span>
                                <span class="small text-danger" *ngIf="eidLengthError">
                                    {{ "EID length should be 32 digits!" | translate}}
                                </span>
                            </div>
                            <div class="confirmation-code-container-modal border-bt-ext-light">
                                <div class="form-group d-flex mt-3 mb-2 mb-0">
                                    <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                                    <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                                    <div class="form-group confirmation-code-inner">
                                        <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                                        <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                            [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                                        <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                                        <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                                        {{ "Confirmation code is required" | translate}}!
                                    </span>
                                        <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                                        {{ "Confirmation code must be 4 digits" | translate}}.
                                    </span>
                                    </div>
                                    <div class="form-group confirmation-code-inner confirmation-code-inner-radio mb-0">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                            <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                                {{code | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-code-dnld-option" *ngIf="selectedReleaseMethod === 'withQR'">
                                <p class="mt-3">
                                    {{ "QR Code Download Option:" | translate}}
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'Text' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="qrCode"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                                    </div>
                                    
                                    <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code with logo' | translate}}
                                        </div>
                                        <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_prepare', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-3">
                                            {{ 'Campaign' | translate}}
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                            <mat-form-field appearance="outline" style="width: 80px;">
                                                <mat-select [(ngModel)]="selectedCampaignFormat">
                                                    <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                                        {{format}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign">
                                            <mat-form-field appearance="outline" style="width: 170px;">
                                                <mat-label>{{"Select Name" | translate}}</mat-label>
                                                <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                                    <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                        {{template.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                                    </div>

                                    <!-- <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'LPA' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                                    </div> -->

                                    <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>
                                </div>
                            </div>
                            <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                                <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                                <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                    <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                </div>
                            </div> -->
                            <p class="mt-3">
                                {{ "Text Download Option:" | translate}}
                            </p>
                            <div class="filter-box-checkbox-container mb-1">
                                <div class="filter-box-checkbox-container mb-4 d-flex">
                                    <mat-checkbox [(ngModel)]="qrEmail" class="mr-4" (ngModelChange)="checkadditionalEmailFlg();errEmailDwn = false;"></mat-checkbox>{{ "Email" | translate}}
                                    <p class="mb-0 ml-4 t-color" *ngIf="qrEmail" style="word-break: break-all;">{{user.email}}</p>
                                </div>
                                <div class="filter-box-checkbox-container mb-4 d-flex" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                    <mat-checkbox [(ngModel)]="qrAdditionalEmail" class="mr-4" (ngModelChange)="checkEmailFlg();errEmailDwn = false;"></mat-checkbox><label style="width: 215px;">{{ (selectedReleaseMethod === 'withQR' ? "Email Address of QR Recipient" : "Email Address of Recipient") | translate}}</label>
                                    <div class="ml-5 w-75">
                                        <input type="email" class="t-input form-control" (keyup)="additionalEmailErr=false;" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" (ngModelChange)="checkAdditionalEmail()" #firstEmail="ngModel">
                                        <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                            <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                        </div>
                                        <small class="text-danger" *ngIf="additionalEmailErr">{{"Please provide email address" | translate}}</small>
                                    </div>
                                </div>
                                <div class="filter-box-checkbox-container mb-3">
                                    <mat-checkbox [(ngModel)]="qrDownload" (ngModelChange)="errEmailDwn = false;" class="mr-4"></mat-checkbox>{{ "Download" | translate}}
                                </div>
                            </div>
                            <span class="small text-danger" *ngIf="errEmailDwn">
                                {{ "Please select at least one option" | translate}}.
                            </span>
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block profile-info-tabs mt-4" (click)="prepareProfileWithDevice();" [disabled]="disable || (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <!-- with device single end -->
                        <!-- with device multiple start -->
                        <div *ngIf="selectedIccidList.length > 1 || selectAllIccid">
                            <div class="border-bt-ext-light">
                                <div class="with-divice-upload-btns">
                                    <div class="mr-2">
                                        <button class="btn btn-block device-flex-bodydevice-flex-body c-btn my-3 mr-4 d-block profile-info-tabs" style="white-space: nowrap;" *ngIf="!downloadWithDeviceLoader" (click)="downloadIccidEidCSV(); disableUploadCsv = false;"><i class="fa fa-download mr-2"></i>{{ "Download ICCID List" | translate}}</button>
                                        <div class="btn-spinner" *ngIf="downloadWithDeviceLoader">
                                            <div class="loader quantum-spinner my-3" style="margin-left: 75px;"></div>
                                        </div>
                                    </div>
                                    <div class="mr-2">
                                        <button class="btn btn-block device-flex-bodydevice-flex-body c-btn w-100 mt-3 mb-1 d-block profile-info-tabs" style="white-space: nowrap;"  (click)="uploadProfilePic()" [disabled]="disableUploadCsv">
                                            <i class="fa fa-upload" aria-hidden="true">
                                                <input id="pro-pic1" type="file" style="display: none" accept=".txt" (change)="onFileChange($event)">
                                            </i> {{ "Upload File" | translate}}
                                        </button>
                                        <div class="mb-3" style="word-break: break-all;">
                                            <p class="mb-0 text-center">{{eidFileName}}</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div class="confirmation-code-container-modal border-bt-ext-light">
                                <div class="form-group d-flex mt-3 mb-2 mb-0">
                                    <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                                    <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                                    <div class="form-group confirmation-code-inner">
                                        <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                                        <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                            [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                                        <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                                        <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                                        {{ "Confirmation code is required" | translate}}!
                                    </span>
                                        <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                                        {{ "Confirmation code must be 4 digits" | translate}}.
                                    </span>
                                    </div>
                                    <div class="form-group confirmation-code-inner confirmation-code-inner-radio mb-0">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                            <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                                {{code | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-code-dnld-option" *ngIf="selectedReleaseMethod === 'withQR'">
                                <p class="mt-3">
                                    {{ "QR Code Download Options" | translate}}:
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'Text' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                                    </div>
                                    
                                    <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code with logo' | translate}}
                                        </div>
                                        <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_prepare', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-3">
                                            {{ 'Campaign' | translate}}
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                            <mat-form-field appearance="outline" style="width: 80px;">
                                                <mat-select [(ngModel)]="selectedCampaignFormat">
                                                    <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                                        {{format}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign">
                                            <mat-form-field appearance="outline" style="width: 170px;">
                                                <mat-label>{{"Select Name" | translate}}</mat-label>
                                                <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                                    <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                        {{template.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                                    </div>

                                    <!-- <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'LPA' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                                    </div> -->

                                    <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>
                                </div>
                            </div>
                            <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                                <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                                <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                    <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                </div>
                            </div> -->
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block mt-4 profile-info-tabs" (click)="prepareProfileWithDevice();" [disabled]="disable && (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <!-- with device multiple end -->
                        <!-- without iccid selection start -->
                        <div *ngIf="selectedIccidList.length === 0 && !selectAllIccid">
                            <div class="border-bt-ext-light">
                                <div class="with-divice-upload-btns">
                                    <div class="mr-2">
                                        <a class="btn btn-block device-flex-bodydevice-flex-body c-btn my-3 profile-info-tabs mr-2" style="white-space: nowrap;" href="assets/csv/sample-iccid-eid.txt" download>
                                            <i class="fa fa-download mr-2"></i>{{ "Download Sample File" | translate}}
                                        </a>
                                    </div>
                                    <div class="mr-2">
                                        <button class="btn btn-block device-flex-bodydevice-flex-body c-btn w-100 mt-3 mb-1 d-block profile-info-tabs" style="white-space: nowrap;"  (click)="uploadBulkProfileWithDevice()" >
                                            <i class="fa fa-upload" aria-hidden="true">
                                                <input id="bulk-profile-with-device" type="file" style="display: none" accept=".txt" (change)="onFileChange($event)">
                                            </i> {{ "Upload ICCID With EID" | translate}}
                                        </button>
                                        <div class="mb-3" style="word-break: break-all;" *ngIf="fileNameErr">
                                            <span class="small text-danger">
                                                {{ "Please upload file" | translate}}.
                                            </span>
                                        </div>
                                        <div class="mb-3" style="word-break: break-all;">
                                            <p class="mb-0 text-center">{{eidFileName}}</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div class="confirmation-code-container-modal border-bt-ext-light">
                                <div class="form-group d-flex mt-3 mb-2 mb-0">
                                    <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                                    <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                            <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                                {{flg | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                                    <div class="form-group confirmation-code-inner">
                                        <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                                        <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                            [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                                        <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                                        <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                                        {{ "Confirmation code is required" | translate}}!
                                    </span>
                                        <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                                        {{ "Confirmation code must be 4 digits" | translate}}.
                                    </span>
                                    </div>
                                    <div class="form-group confirmation-code-inner confirmation-code-inner-radio mb-0">
                                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                            <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                                {{code | translate}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="qr-code-dnld-option" *ngIf="selectedReleaseMethod === 'withQR'">
                                <p class="mt-3">
                                    {{ "QR Code Download Options" | translate}}:
                                </p>
                                <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'Text' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                                    </div>
                                    
                                    <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'QR Code with logo' | translate}}
                                        </div>
                                        <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                                    </div>

                                    <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_prepare', 'profile')].edit">
                                        <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-3">
                                            {{ 'Campaign' | translate}}
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                            <mat-form-field appearance="outline" style="width: 80px;">
                                                <mat-select [(ngModel)]="selectedCampaignFormat">
                                                    <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                                        {{format}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="qrCodeWithCampaign">
                                            <mat-form-field appearance="outline" style="width: 170px;">
                                                <mat-label>{{"Select Name" | translate}}</mat-label>
                                                <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                                    <mat-option *ngFor="let template of templateList" [value]="template._id">
                                                        {{template.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                                    </div>

                                    <!-- <div class="d-flex mb-3 align-items-center">
                                        <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                                        <div class="pl-2 ml-4 mr-2">
                                            {{ 'LPA' | translate}}
                                        </div>
                                        <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                                    </div> -->

                                    <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>
                                </div>
                            </div>
                            <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                                <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                                <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                                <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                    <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                                </div>
                            </div> -->
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block mt-4 profile-info-tabs" (click)="prepareBulkProfileWithDevice();" [disabled]="disable && (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <!-- without iccid selection end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- reset profile start -->

<div class="modal fade" id="resetProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Reset Profile" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="tab-content device-flex-body" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-without-device" role="tabpanel" aria-labelledby="pills-without-device-tab">
                        <div *ngIf="selectedIccidList.length === 0 && !selectAllIccid">
                            <div class="border-bt-ext-light">
                                <div class="with-divice-upload-btns">
                                    <div class="mr-2">
                                        <a class="btn btn-block device-flex-bodydevice-flex-body c-btn my-3 profile-info-tabs mr-2" style="white-space: nowrap;" href="assets/csv/sample-iccid.txt" download>
                                            <i class="fa fa-download mr-2"></i>{{ "Download Sample File" | translate}}
                                        </a>
                                    </div>
                                    <div class="mr-2">
                                        <button class="btn btn-block device-flex-bodydevice-flex-body c-btn w-100 mt-3 mb-1 d-block profile-info-tabs" style="white-space: nowrap;" (click)="uploadBulkProfile()">
                                            <i class="fa fa-upload" aria-hidden="true">
                                                <input id="bulk-profile" type="file" style="display: none" accept=".txt" (change)="onRESETICCIDFileChange($event)">
                                            </i> {{ "Upload ICCID" | translate}}
                                        </button>
                                        <div class="mb-3" style="word-break: break-all;" *ngIf="fileNameErr">
                                            <span class="small text-danger">
                                                {{ "Please upload file" | translate}}.
                                            </span>
                                        </div>
                                        <div class="mb-3" style="word-break: break-all;">
                                            <p class="mb-0 text-center">{{eidFileName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-body p-4 device-flex-body">
                                <div class="form-group mb-0">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="resetOption">
                                        <mat-radio-button class="example-radio-button mr-4" *ngFor="let option of resetOptions" [value]="option">
                                            {{option}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block profile-info-tabs" (click)="resetBulkProfile();" [disabled]="disable && (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Submit" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                        <div *ngIf="selectedIccidList.length === 1 || selectedIccidList.length > 1 || selectAllIccid">
                            <!-- <div class="modal-body device-flex-body">
                                <div class="form-group mb-0">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="resetOption">
                                        <mat-radio-button class="example-radio-button mr-4" *ngFor="let option of resetOptions" [value]="option">
                                            {{option}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block profile-info-tabs" (click)="resetBulkProfile();" [disabled]="disable || (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Confirm and Release" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div> -->
                            <div class="modal-body-reset device-flex-body">
                                <p *ngIf="selectedIccidList.length === 1">{{ "ICCID" | translate}}: {{selectedIccidList[0].iccid}}</p>
                                <p *ngIf="selectedIccidList.length > 1 || selectAllIccid">{{ "ICCID" | translate}}: {{selectAllIccid ? selectedProfileCount : selectedIccidList.length }} {{ "selected" | translate}}</p>
                                <div class="form-group">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="resetOption">
                                        <mat-radio-button class="example-radio-button mr-4" *ngFor="let option of resetOptions" [value]="option">
                                            {{option}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 profile-info-tabs" data-toggle="modal" data-target="#resetConfirmation" (click)="openConfirmation()">{{ "Reset" | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
</div>

<div class="modal fade" id="resetConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Reset Profile Confirmation" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <p>{{ "Do you want to confirm to Reset to" | translate}} {{resetOption}}?</p>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 profile-info-tabs" (click)="resetProfile()" [disabled]="disable" *ngIf="!btnSpinner">{{ "Confirm Reset" | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- reset profile end -->

<!-- qrCode start -->
<div class="modal fade" id="qrCode" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "QR Code" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <!-- <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p> -->
                <p class="text-muted" *ngIf="selectedIccidList.length === 1">{{ "ICCID" | translate}}: {{selectedIccidList[0].iccid}}</p>
                <p class="text-muted" *ngIf="selectedIccidList.length > 1 || selectAllIccid">{{ "ICCID" | translate}}: {{selectAllIccid ? selectedProfileCount : selectedIccidList.length}} {{ "selected" | translate}}</p>
                <div class="confirmation-code-container-modal border-bt-ext-light">
                    <div class="form-group d-flex mt-3 mb-0">
                        <p class="mb-0">{{ "Confirmation Code" | translate}}:</p>

                        <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmationCodeFlg" (ngModelChange)="getConfirmationCodeFlg();errEmailDwn=false; qrCodeDownloadErr=false; errEidMsg=false; errMsg=false;">
                                <mat-radio-button class="example-radio-button mr-4" *ngFor="let flg of confirmationCodeFlgs" [value]="flg">
                                    {{flg | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="d-flex" *ngIf="confirmationCodeFlg === 'Yes'">
                        <div class="form-group confirmation-code-inner">
                            <label class="device-flex-label" for=" ">{{ "Confirmation Code" | translate}}</label>
                            <input type="number" min="0" pattern="/^-?\d+\.?\d*$/" (keyup)="errMsg = false; errLength = false;" onKeyPress=" if(this.value.length==4) return false;return event.charCode >= 48;" class="device-flex-input form-control" name=" " id=" " [disabled]="confirmCode === 'Random Confirmation Code'"
                                [(ngModel)]="confirmationCode" *ngIf="confirmationCode !== '####'">
                            <input type="text" class="device-flex-input form-control" [(ngModel)]="confirmationCode" *ngIf="confirmationCode === '####'" [disabled]="true">
                            <span class="small text-danger" *ngIf="errMsg && (confirmCode === 'Fixed Confirmation Code')">
                            {{ "Confirmation code is required" | translate}}!
                        </span>
                            <span class="small text-danger" *ngIf="errLength && (confirmCode === 'Fixed Confirmation Code')">
                            {{ "Confirmation code must be 4 digits" | translate}}.
                        </span>
                        </div>
                        <div class="form-group confirmation-code-inner confirmation-code-inner-radio">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="confirmCode" (ngModelChange)="getConfirmationCode()">
                                <mat-radio-button class="example-radio-button mr-4" *ngFor="let code of confirmationCodes" [value]="code">
                                    {{code | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="qr-code-dnld-option">
                    <p class="mt-3">
                        {{ "QR Code Download Options" | translate}}:
                    </p>
                    <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">
                        <div class="d-flex mb-3 align-items-center">
                            <mat-checkbox class="" [(ngModel)]="textQr" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                            <div class="pl-2 ml-4 mr-2">
                                {{ 'Text' | translate}}
                            </div>
                            <div class="text-font-radio-top" *ngIf="textQr">{{ lpaString }}</div>
                        </div>

                        <div class="d-flex mb-3 align-items-center">
                            <mat-checkbox class="" [(ngModel)]="qrCode" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                            <div class="pl-2 ml-4 mr-2">
                                {{ 'QR Code' | translate}}
                            </div>
                            <div class="text-font-radio-top" *ngIf="qrCode"><img class="radio-qr-img" src="assets/images/qr-code.png" alt=""></div>
                        </div>
                        
                        <div class="d-flex mb-3 align-items-center" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                            <mat-checkbox class="" [(ngModel)]="qrCodeWithLogo" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                            <div class="pl-2 ml-4 mr-2">
                                {{ 'QR Code with logo' | translate}}
                            </div>
                            <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();" *ngIf="qrCodeWithLogo"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                            <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithLogo"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                        </div>

                        <div class="d-flex mb-3 align-items-baseline" *ngIf="features && features[getFeatureIndex('campaign_in_qrcode', 'profile')].edit">
                            <mat-checkbox class="" [(ngModel)]="qrCodeWithCampaign" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                            <div class="pl-2 ml-4 mr-3">
                                {{ 'Campaign' | translate}}
                            </div>
                            <div *ngIf="qrCodeWithCampaign" class="mr-2">
                                <mat-form-field appearance="outline" style="width: 80px;">
                                    <mat-select [(ngModel)]="selectedCampaignFormat">
                                        <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                            {{format}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="qrCodeWithCampaign">
                                <mat-form-field appearance="outline" style="width: 170px;">
                                    <mat-label>{{"Select Name" | translate}}</mat-label>
                                    <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                        <mat-option *ngFor="let template of templateList" [value]="template._id">
                                            {{template.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="text-font-radio-top ml-3" *ngIf="qrCodeWithCampaign"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                        </div>

                        <!-- <div class="d-flex mb-3 align-items-center">
                            <mat-checkbox class="" [(ngModel)]="lpaText" (ngModelChange)="qrCodeDownloadErr = false;"></mat-checkbox>
                            <div class="pl-2 ml-4 mr-2">
                                {{ 'LPA' | translate}}
                            </div>
                            <div class="text-font-radio-top" *ngIf="lpaText">{{ lpaString }}</div>
                        </div> -->
                        
                        <p class="text-danger small" *ngIf="qrCodeDownloadErr">{{"Please select at least one QR Code Download option" | translate}}.</p>
                    </div>
                    <div *ngIf="selectedIccidList.length === 1">
                        <p class="mt-3">
                            {{ "Display QR Code Options" | translate}}:
                        </p>
                        <div class="form-group border-bt-ext-light filter-box-checkbox-container success-prepare-profile-radio">

                            <div class="d-flex">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" style="flex-direction: column;" [(ngModel)]="dispayOrCodeValueFlg" (ngModelChange)="checkDisplayQrOptions()">
                                    <mat-radio-button class="example-radio-button mr-4" style="margin-bottom: 0px !important;" *ngFor="let flg of dispayOrCodeValueFlgs" [value]="flg">
                                        {{flg | translate}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-2 pb-3 border-bt-ext-light" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit && selectedIccidList.length > 1">
                    <label class="device-flex-label" for=" ">{{ "Email Address" | translate}}</label>
                    <input type="email" class="device-flex-input form-control" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" #firstEmail="ngModel">
                    <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                        <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                    </div>
                </div> -->

                <p class="mt-3" *ngIf="selectedIccidList.length === 1">
                    {{ "Text Download Option:" | translate}}
                </p>
                <div class="filter-box-checkbox-container" *ngIf="selectedIccidList.length === 1">
                    <!-- <mat-checkbox [(ngModel)]="qrEmail" class="mr-5"> {{ "Email" | translate}}</mat-checkbox>
                    <mat-checkbox [(ngModel)]="qrDownload"> {{ "Download" | translate}}</mat-checkbox> -->
                    <div class="filter-box-checkbox-container mb-4 d-flex">
                        <mat-checkbox [(ngModel)]="qrEmail" class="mr-4" (ngModelChange)="checkadditionalEmailFlg();"></mat-checkbox>{{ "Email" | translate}}
                        <p class="mb-0 ml-4 t-color" *ngIf="qrEmail" style="word-break: break-all;">{{user.email}}</p>
                    </div>
                    <div class="filter-box-checkbox-container mb-4 d-flex" *ngIf="features && features[getFeatureIndex('profile_email', 'profile')].edit">
                        <mat-checkbox [(ngModel)]="qrAdditionalEmail" class="mr-4" (ngModelChange)="checkEmailFlg();"></mat-checkbox><label style="width: 175px;">{{ "Email Address of QR Recipient" | translate}}</label>
                        <div class="ml-4">
                            <input type="email" class="t-input form-control" (keyup)="additionalEmailErr=false;" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" [class.is-invalid]="firstEmail && firstEmail.invalid && firstEmail.touched" [(ngModel)]="emailAddress" (ngModelChange)="checkAdditionalEmail()" #firstEmail="ngModel">
                            <div *ngIf="firstEmail && firstEmail.errors && (firstEmail.invalid || firstEmail.touched)">
                                <small class="text-danger" *ngIf="firstEmail.errors.pattern">{{"Please provide a valid email address" | translate}}</small>
                            </div>
                            <small class="text-danger" *ngIf="additionalEmailErr">{{"Please provide email address" | translate}}</small>
                        </div>
                    </div>
                    <div class="filter-box-checkbox-container mb-4">
                        <mat-checkbox [(ngModel)]="qrDownload" class="mr-4"></mat-checkbox>{{ "Download" | translate}}
                    </div>
                </div>
                <span class="small text-danger" *ngIf="errEmailDwn">
                    {{ "Please select at least one option" | translate}}.
                </span>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 profile-info-tabs" (click)="getBulkQrCode()" [disabled]="disable || (firstEmail && firstEmail.errors)" *ngIf="!btnSpinner">{{ "Submit" | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- qrCode end -->

<!-- Bulk & Incremental qr code start-->
<div class="modal fade" id="bulkQrCode" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 641px;">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Get Multiple QR Code" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="confirmation-code-container-modal">
                    <div class="form-group d-flex mt-3 mb-0">
                        <p class="mb-0">{{ "Input Method" | translate}}</p>
                        <div class="form-group mb-0 confirmation-code-inner-radio cc-flag">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="bulkQrCodeFlg" (ngModelChange)="bulkQrOptionChange()">
                                <mat-radio-button class="example-radio-button mr-3" *ngFor="let flg of bulkQrCodeFlgs" [value]="flg">
                                    {{flg | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="mt-4 pb-3 border-bt-ext-light" *ngIf="bulkQrCodeFlg === 'File Upload'">
                    <div class="d-flex">
                        <p class="mb-0">{{ "Please upload list of ICCID here" | translate}}</p>
                        <div class="qr-code-file-upload-btn ml-5 d-flex">
                            <p class="mr-5 mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size cursor-p" (click)="uploadQrFile()" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs></defs><g transform="translate(28.768 29.233) rotate(180)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                <input id="bulk-qr-file" type="file" style="display: none" accept=".txt" (change)="onQrFileChange($event)">
                            </p>
                            <a href="assets/csv/sample-iccid.txt" download>
                                <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                            </a>
                        </div>
                    </div>
                    <div class="t-font-light mt-5 small" *ngIf="qrFile">
                        {{ "File Name" | translate}}: {{qrFile.name}}
                    </div>
                </div>
                <div class="mt-3" *ngIf="bulkQrCodeFlg === 'File Upload'">
                    <mat-radio-group aria-labelledby="example-radio-group-label" style="justify-content: space-between; padding: 0px 20px;" class="example-radio-group d-flex" [(ngModel)]="qrOption">
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[0]">
                            {{bulkQrCodeOptions[0] | translate}}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[1]" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                            {{bulkQrCodeOptions[1] | translate}}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[2]" *ngIf="features && features[getFeatureIndex('campaign_in_qrcode', 'profile')].edit">
                            {{bulkQrCodeOptions[2] | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="text-center" *ngIf="qrOption === 'QR Code' && bulkQrCodeFlg === 'File Upload'">
                    <img src="assets/images/qr-code.png" alt="" style="width: 85px;margin-top: 7px;">
                </div>
                <div class="d-flex mt-4 align-items-center justify-content-around" *ngIf="qrOption==='QR Code With Logo' && bulkQrCodeFlg === 'File Upload'" style="justify-content: space-between;">
                    <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                    <div class="text-font-radio-top ml-3"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                </div>
                <div class="d-flex mt-4" *ngIf="qrOption==='QR Code With Campaign' && bulkQrCodeFlg === 'File Upload'" style="justify-content: space-between;">
                    <div>
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{"Select Name" | translate}}</mat-label>
                            <mat-select [(ngModel)]="selectedTempateId" (ngModelChange)="getSingleTemplate();">
                                <mat-option *ngFor="let template of templateList" [value]="template._id">
                                    {{template.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="mr-2">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{"Select Format" | translate}}</mat-label>
                            <mat-select [(ngModel)]="selectedCampaignFormat">
                                <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                    {{format}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="text-font-radio-top ml-3"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                </div>
                <div *ngIf="bulkQrCodeFlg === 'File Upload'">
                    <div class="mt-5">
                        <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 profile-info-tabs" (click)="generateBulkQrCode()" *ngIf="!btnSpinner" [disabled]="bulkQrDownload">{{ "Submit" | translate}}</button>
                        <div class="btn-spinner" *ngIf="btnSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                    </div>
                </div>

                <div class="mt-4" *ngIf="bulkQrCodeFlg === 'Manual'">
                    <form [formGroup]="bulkQR" (ngSubmit)="generateIncrementalQrCode()" style="width: 100%">
                        <div class="row">
                            <div class="col-lg-4 t-font-light">
                                {{ "ICCID" | translate}}
                            </div>
                            <div class="col-lg-8">
                                <input type="text" class="form-control t-input" formControlName="iccid" required>
                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.iccid.valid && !f.iccid.pristine) || (f.iccid.errors && submitted)">
                                    <span class="small"  *ngIf="f.iccid.errors && f.iccid.errors.required">
                                        {{ "ICCID is required!" | translate}}
                                    </span>
                                <span class="small" *ngIf="f.iccid.errors && f.iccid.errors.validIccid && !f.iccid.errors.required">
                                    {{ "Invalid ICCID!" | translate}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-4 pb-3 border-bt-ext-light">
                            <div class="col-lg-4 t-font-light">
                                {{ "No. Of ICCID" | translate}}
                            </div>
                            <div class="col-lg-8">
                                <input type="text" class="form-control t-input" formControlName="amount" (keypress)="isNumber($event)" required>
                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.amount.valid && !f.amount.pristine) || (f.amount.errors && submitted)">
                                    <span class="small" *ngIf="f.amount.errors.required">
                                        {{ "No. Of ICCID is required!" | translate}}
                                    </span>
                                <span class="small" *ngIf="f.amount.errors && f.amount.errors.validAmount && !f.amount.errors.required">
                                    {{ "No. Of ICCID should be greater than 0!" | translate}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="mt-3" *ngIf="bulkQrCodeFlg === 'Manual'">
                            <mat-radio-group aria-labelledby="example-radio-group-label" style="justify-content: space-between; padding: 0px 20px;" class="example-radio-group d-flex" [ngModelOptions]="{standalone: true}" [(ngModel)]="qrOption">
                                <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[0]">
                                    {{bulkQrCodeOptions[0] | translate}}
                                </mat-radio-button>
                                <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[1]" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                                    {{bulkQrCodeOptions[1] | translate}}
                                </mat-radio-button>
                                <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[2]" *ngIf="features && features[getFeatureIndex('campaign_in_qrcode', 'profile')].edit">
                                    {{bulkQrCodeOptions[2] | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="text-center" *ngIf="qrOption === 'QR Code' && bulkQrCodeFlg === 'Manual'">
                            <img src="assets/images/qr-code.png" alt="" style="width: 85px;margin-top: 7px;">
                        </div>
                        <div class="d-flex mt-4 align-items-center justify-content-around" *ngIf="qrOption==='QR Code With Logo' && bulkQrCodeFlg === 'Manual'" style="justify-content: space-between;">
                            <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                            <div class="text-font-radio-top ml-3"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                        </div>
                        <div class="d-flex mt-4" *ngIf="qrOption==='QR Code With Campaign' && bulkQrCodeFlg === 'Manual'" style="justify-content: space-between;">
                            <div>
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>{{"Select Name" | translate}}</mat-label>
                                    <mat-select [(ngModel)]="selectedTempateId" [ngModelOptions]="{standalone: true}" (ngModelChange)="getSingleTemplate();">
                                        <mat-option *ngFor="let template of templateList" [value]="template._id">
                                            {{template.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="mr-2">
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>{{"Select Format" | translate}}</mat-label>
                                    <mat-select [(ngModel)]="selectedCampaignFormat" [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                            {{format}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="text-font-radio-top ml-3"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                        </div>
                        <div class="mt-5">
                            <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 profile-info-tabs" *ngIf="!btnSpinner">{{ "Submit" | translate}}</button>
                            <div class="btn-spinner" *ngIf="btnSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Bulk and incremental QR code end -->

<!-- download qr code start -->

<div class="modal fade" id="downloadQrCodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Download QR Code" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="logoId = '';closeOperations();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4  device-flex-body">
                <div> 
                    <mat-radio-group aria-labelledby="example-radio-group-label" style="justify-content: space-between; padding: 0px 20px;" class="example-radio-group d-flex" [(ngModel)]="qrOption">
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[0]">
                            {{bulkQrCodeOptions[0] | translate}}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button mr-4" [value]="bulkQrCodeOptions[1]" *ngIf="features && features[getFeatureIndex('qrcode_with_logo', 'profile')].edit">
                            {{bulkQrCodeOptions[1] | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="text-center mt-4" *ngIf="qrOption==='QR Code'">
                    <img src="assets/images/qr-code.png" style="width: 85px;" alt="">
                </div>

                <div class="d-flex mt-4 align-items-center justify-content-around" *ngIf="qrOption==='QR Code With Logo'" style="justify-content: space-between;">
                    <div class="pl-1 mr-2 t-color cursor-p" (click)="getQrLogos();"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                    <div class="text-font-radio-top ml-3"><img class="radio-qr-img" src="{{qrCodeWithLogoImg}}" alt=""></div>
                </div>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3" (click)="getQrCode()">{{ "Download" | translate}}</button>
            </div>
        </div>
    </div>
</div>

<!-- download qr code end -->

<!-- select logo start -->

<div class="modal fade" id="selectLogoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Select Logo" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="selectLogo();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="small text-danger" *ngIf="logoSelectErr">{{"Please select logo" | translate}}</div>
                <div *ngIf="logosArray && logosArray.length <= 0 && !allLogoSpinner">{{'No Logo Found' | translate}}!</div>
                <div class="main-logo-list" *ngIf="logosArray && logosArray.length > 0">
                    <div class="single-logo" *ngFor="let item of logosArray;let i = index;">
                        <div *ngIf="defaultLogoId" [ngClass]="{'default-icon': item._id === defaultLogoId}">
                            <i [ngClass]="{'fa fa-check': item._id === defaultLogoId}"></i>
                        </div>
                        <img src="{{'data:image/png;base64,' + item.data}}" id="profile-single-logo-{{i}}" class="img-logo cursor-p" (keypress)="logoSelectErr = false;" (click)="logoId = item._id; logoImg = 'data:image/png;base64,' + item.data; logoSelected = logoId === item._id ? true : false;toggleBorderClass(i);" alt="">
                    </div>
                </div>
                <div class="d-flex manage-logo">
                    <a class="btn logo-btn my-3 d-block d-flex mr-3" [ngClass]="{'logo-btn': !logoSelected, 'disable-logo-btn': logoSelected }" (click)="uploadQrCodeLogo()">
                        {{ "Upload" | translate}}
                        <input id="qr-code-logo" type="file" style="display: none" accept=".png,.jpg" (change)="onQrLogoChange($event)">
                        <svg class="ml-2 svg-t-size cursor-p" xmlns="http://www.w3.org/2000/svg" width="13.26" height="22.595" viewBox="0 0 19.26 22.595"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-37.781)"><g transform="translate(46.692)"><g transform="translate(0)"><path class="a" d="M240.577,0a.862.862,0,0,0-.862.862V14.546a.862.862,0,0,0,1.725,0V.862A.862.862,0,0,0,240.577,0Z" transform="translate(-239.715 0)"/></g></g><g transform="translate(41.92 0)"><path class="a" d="M142.577,4.688,137.805.232a.863.863,0,0,0-1.177,0l-4.772,4.456a.862.862,0,1,0,1.177,1.261l4.183-3.906L141.4,5.949a.862.862,0,0,0,1.177-1.261Z" transform="translate(-131.582 0)"/></g><g transform="translate(37.781 7.359)"><g transform="translate(0)"><path class="a" d="M56.179,166.758h-4.6a.862.862,0,1,0,0,1.725h3.737v11.786H39.506V168.483h3.737a.862.862,0,0,0,0-1.725h-4.6a.862.862,0,0,0-.862.862v13.511a.862.862,0,0,0,.862.862H56.179a.862.862,0,0,0,.862-.862V167.62A.862.862,0,0,0,56.179,166.758Z" transform="translate(-37.781 -166.758)"/></g></g></g></svg>
                    </a>
                    <button class="btn logo-btn btn-block my-3 mr-3" style="width: 655px;" [ngClass]="{'logo-btn': logoSelected, 'disable-logo-btn': !logoSelected }" (click)="setDefaultLogo();">{{ "Set As Default" | translate}}<i class="fa fa-check ml-2"></i></button>
                    <button class="btn logo-btn btn-block my-3" [ngClass]="{'logo-btn': logoSelected, 'disable-logo-btn': !logoSelected }" (click)="deleteLogo();">{{ "Delete" | translate}}<i class="fa fa-trash ml-2"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- select logo end -->

<!-- prp setting start -->

<div class="modal fade" id="prpSetting" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title ml-3" id="exampleModalLabel">{{ "Change PRP Setting" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body device-flex-body prp-main-section">
                <div class="border-bt-ext-light">
                    <div class="m-3" *ngIf="profileTypeErr || prpCounterErr || countErr || groupErr || startingIccidErr || fileUploadErr">
                        <div class="text-danger small text-left mb-2" *ngIf="profileTypeErr">{{"Please select profile type" | translate}}.</div>
                        <div class="text-danger small text-left mb-2" *ngIf="groupErr">{{"Please select group" | translate}}.</div>
                        <div class="text-danger small text-left mb-2" *ngIf="prpCounterErr">{{"Please enter prp counter" | translate}}.</div>
                        <div class="text-danger small text-left mb-2" *ngIf="countErr">{{"Please enter count" | translate}}.</div>
                        <div class="text-danger small text-left mb-2" *ngIf="startingIccidErr">{{"Please enter starting iccid" | translate}}.</div>
                        <div class="text-danger small text-left mb-2" *ngIf="fileUploadErr">{{"Please upload iccid file" | translate}}.</div>
                    </div>
                    <div class="prp-sub-section" *ngIf="selectedIccidList.length === 1">
                        <p><span class="t-label">{{ "ICCID" | translate}}:</span>&nbsp; {{selectedIccidList[0].iccid}}</p>
                        <div class="container">
                            <div class="row">
                                <p><span class="t-label">{{ "PRP Type" | translate}}:</span>&nbsp;&nbsp; {{selectedIccidPrpType}}</p>
                                <p><span class="t-label" style="margin-left: 80px;">{{ "PRP Count" | translate}}:</span>&nbsp;&nbsp; {{selectedIccidPPrpCounter}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="prp-sub-section" *ngIf="selectedIccidList.length > 1 || selectAllIccid">
                        <p class="t-label mb-0">{{selectAllIccid ? selectedProfileCount : selectedIccidList.length}} {{"ICCID Selected" | translate}}</p>
                        <div *ngIf="!downloadWithoutDeviceLoader">
                            <button class="btn device-flex-bodydevice-flex-body c-btn my-3 outline" (click)="downloadIccidCSV('prp')" ><i class="fa fa-download mr-2"></i>{{ "Download ICCID Status List" | translate}}</button>
                        </div>
                        <div class="btn-spinner my-2"  *ngIf= "downloadWithoutDeviceLoader" >
                            <div class="loader quantum-spinner m-right-auto" style="margin-left: 45px;"></div>
                        </div>
                    </div>
                    <div class="prp-sub-section ml-3" *ngIf="!selectAllIccid && selectedIccidList.length === 0 && user">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedOption" (ngModelChange)="onRadioChange();">
                            <div class="mb-2" *ngFor="let option of optionsTypes; let i=index;">
                                <div [ngClass]="{'d-flex': (option.optionType !== 'Profile Type' && option.optionType !== 'Groups')}">
                                        <mat-radio-button class="example-radio-button mr-4 flex-grow" [value]="option.optionType" [ngClass]="{'col-md-12': (option.optionType === 'Profile Type' || option.optionType === 'Groups'), 'col-md-3 mt-4 mb-3': (option.optionType !== 'Profile Type' && option.optionType !== 'Groups')}">
                                            {{option.optionType | translate}}           
                                        </mat-radio-button>
                                    <ul *ngIf="option.optionType === 'Profile Type' && option.profileTypes && option.profileTypes.length > 0" class="prp-setting-options-list ml-5">
                                        <li *ngFor="let sub of option.profileTypes; let i = index;" class="text-left mt-1 mb-2 filter-box-checkbox-container">
                                            <button class="btn t-color my-3 ml-auto outline qr-code-file-upload-btn" (click)="downloadProfileType()" *ngIf="i >= 0 && !prpBtnSpinnerDownload" [ngClass]="{'d-none': !sub.checked}" style="position: absolute;top: 0;right: 40px;">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg> Download</button>
                                                
                                                <div class="btn-spinner my-3" *ngIf="i===0 && prpBtnSpinnerDownload" style="position: absolute;top: 0;right: 40px;">
                                                    <div class="loader quantum-spinner m-auto"></div>
                                                </div>
                                            <mat-checkbox class="mr-4"  [(ngModel)]="sub.checked" (change)="profileTypeChanged(i)" [disabled]="selectedOption !== 'Profile Type'"></mat-checkbox>{{sub.profileType}}
                                        </li>
                                    </ul>
                                    <ul *ngIf="option.optionType === 'Groups' && option.groups && option.groups.length > 0" class="prp-setting-options-list ml-5">
                                        <li *ngFor="let sub of option.groups; let i = index;" class="text-left mt-1 mb-2 filter-box-checkbox-container qr-code-file-upload-btn">
                                                <button class="btn t-color my-3 ml-auto outline" (click)="downloadProfileType()" *ngIf=" i >= 0 && !prpBtnSpinnerDownload" [ngClass]="{'d-none': !sub.checked}" style="position: absolute;top: 0;right: 40px;">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg> Download</button>
                                                <div class="btn-spinner my-3" *ngIf="i===0 && prpBtnSpinnerDownload" style="position: absolute;top: 0;right: 40px;">
                                                    <div class="loader quantum-spinner m-auto"></div>
                                                </div>
                                            <mat-checkbox class="mr-4"  [(ngModel)]="sub.checked" (change)="groupChanged(i)" [disabled]="selectedOption !== 'Groups'"></mat-checkbox>{{sub.groupName}}
                                        </li>
                                    </ul>
                                    
                                    <div *ngIf="option.optionType === 'Upload File'" class="prp-setting-upload-section prp-setting-upload-section-modified qr-code-file-upload-btn">
                                        <a class="mr-2" href="assets/csv/sample-iccid-qr.txt"  [ngStyle]="{'pointer-events': selectedOption === 'Upload File' ? 'auto' : 'none'}" download>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                        </a>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" [ngClass]="{'cursor-p': selectedOption === 'Upload File'}" (click)="uploadPrpSettings()" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs></defs><g transform="translate(28.768 29.233) rotate(180)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                        <input id="setting-pic" type="file" style="display: none" accept=".txt" [disabled]="selectedOption != 'Upload File'" (change)="onFilePRPChange($event);fileUploadErr=false;">
                                    </div>
                                    <div class="t-font-light file-upload-title" *ngIf="option.optionType === 'Upload File' && fileToUpload">
                                        {{fileToUpload.name}}
                                    </div>
                                    <div *ngIf="option.optionType === 'Count'" class="d-flex">
                                        <div class="col-md-4">
                                            <p for="" class="mb-1 text-left t-font-light t-font-medium">{{"Count" | translate}}</p>
                                            <input class="w-100 prp-setting-count-input profile-info-tabs" (keypress)="isNumber($event);" [disabled]="selectedOption != 'Count'" (keyup)="countErr=false;selectedIccidCount=count;" [(ngModel)]="count" type="text" name="count">
                                        </div>
                                        <div class="col-md-5 px-1">
                                            <p for="" class="mb-1 text-left t-font-light t-font-medium" style="width: 190px;">{{"Starting ICCID" | translate}}</p>
                                            <input type="text" name="" (keypress)="isNumber($event);" (keyup)="startingIccidErr=false;" [disabled]="selectedOption != 'Count'" [(ngModel)]="startingIccid" class="prp-setting-count-input input-width profile-info-tabs" name="startingiccid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="mt-2 mb-3">
                    <div class="prp-sub-section">
                        <p>{{"PRP Setting" | translate}}</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedPRPOption" (ngModelChange)="prpCounter='';prpCounterErr=false;">
                            <div class="d-flex" *ngFor="let prpSetting of prpSettingOptions">
                                <mat-radio-button class="example-radio-button mt-1 mb-4 ml-4" [value]="prpSetting" [ngClass]="{'col-md-12': prpSetting !== 'PRP_2a', 'col-md-8': prpSetting === 'PRP_2a'}">
                                    {{prpSetting}}                  
                                    <span class="small text-left mb-0 ml-2 t-font-light" *ngIf="prpSetting !== 'PRP_2a'">({{ 'Disable re-use of profile' | translate }})</span>
                                    <span class="small text-left mb-0 ml-2 t-font-light" *ngIf="prpSetting === 'PRP_2a'">({{ 'Allow re-use of profile' | translate }})</span>                 
                                </mat-radio-button>
                                    <div *ngIf="prpSetting === 'PRP_2a' && selectedPRPOption ==='PRP_2a'">
                                        <div *ngIf="languageChange === 'en' || languageChange === 'zh' || languageChange === 'mr' || languageChange === 'pt'">
                                            <div class="d-flex flex-column mt-1">
                                                <p for="" class="mb-1 value-count t-font-light mt-lg-n4 mr-2">{{"Value" | translate}} (1-1000)</p>
                                                <input class="prp-setting-count-input t-input mr-2 profile-info-tabs" (keypress)="isNumber($event);" (keyup)="prpCounterErr=false;checkLimit($event);" [(ngModel)]="prpCounter" [disabled]="selectedPRPOption ==='PRP_2a' ? false : true" type="text">
                                            </div>    
                                        </div>
                                        <div *ngIf="languageChange === 'ru' || languageChange ==='bm' || languageChange === 'sv'">
                                            <div style="margin-top: 60px; margin-left: -183px;">
                                                <p for="" class="mb-1 value-count t-font-light mt-lg-n4 mr-2">{{"Value" | translate}} (1-1000)</p>
                                                <input class="prp-setting-count-input t-input mr-2 profile-info-tabs" (keypress)="isNumber($event);" (keyup)="prpCounterErr=false;checkLimit($event);" [(ngModel)]="prpCounter" [disabled]="selectedPRPOption ==='PRP_2a' ? false : true" type="text">
                                            </div>    
                                        </div>
                                    </div>                                
                                </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="prp-sub-section">
                    <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-3 profile-info-tabs" [disabled]="disable" (click)="changePRPSetting()" *ngIf="!prpBtnSpinner">{{ "Submit" | translate}}</button>
                    <div class="btn-spinner my-3" *ngIf="prpBtnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- prp setting end -->


<!-- display QR popup -->

<div class="modal fade" id="displaQrCode" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-body device-flex-body prp-main-section">
                <button type="button" class="close t-color outline pr-3 pt-2" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="display-qr-code-inner p-4 text-center">
                    <p class="mb-3">ICCID: {{iccidInModal}}</p>
                    <div class="text-center" *ngIf="qrOnModal">
                        <img src="{{'data:image/png;base64,' + qrOnModal}}" style="width: 200px;" alt="">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- display QR with logo popup -->

<div class="modal fade" id="displaQrCodeWithLogo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-body device-flex-body prp-main-section">
                <button type="button" class="close t-color outline pr-3 pt-2" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="closeOperations()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="display-qr-code-inner p-4 text-center">
                    <p class="mb-3">ICCID: {{iccidInModal}}</p>
                    <div class="text-center">
                        <img src="{{qrWithLogoOnModal}}" style="width: 200px;" alt="">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>