import { Theme } from './theme.model';
import * as ThemeAction from './theme.action';

export interface ThemeState {
    readonly theme: Theme;
}

export function themeReducer(state: Theme, action: ThemeAction.ThemeActions) {

    switch (action.type) {

        case ThemeAction.GET_THEME:
            return action.payload;

        default: return state;
    }
}
