import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { MousePosition, ICCID } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

@Component({
  selector: 'app-iccid',
  templateUrl: './iccid.component.html',
  styleUrls: ['./iccid.component.scss']
})
export class IccidComponent extends ShapeComponent implements OnInit {

  formFields = [
    {
      name: 'name',
      label: 'Name:',
      type: 'input',
      inputType: 'text',
    },
    {
      name: 'x',
      label: 'X:',
      type: 'input',
      inputType: 'text',
    },
    {
      name: 'y',
      label: 'Y:',
      type: 'input',
      inputType: 'text',
    },
    {
      name: 'value',
      label: 'Text:',
      type: 'input',
      inputType: 'text',
    },
  ];

  constructor() {
    super();
    // console.log('ICCID Component constructor');
    this.shape = new ICCID();
    // this.shape.value = 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE';
    this.shapeType = ShapeType.ICCID;
  }

  ngOnInit() {
    // console.log('ICCID Component ngOnInit');
  }

  updateShapeProperties(value: any) {
    if (this.shape instanceof ICCID) {
      this.shape.shapeProperties.name = value.name;
      this.shape.value = value.value;
      this.shape.originX = value.x;
      this.shape.originY = value.y;
    }
  }

  setStyles() {
    const styles = {
      fill: this.shape.shapeProperties.strokeColor
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof ICCID) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
      this.shape['font-family'] = 'Arial';
      this.shape['font-size'] = 16;
    }
    // console.log('ICCID Component startDrawing at ', beginPosition, ', ', this.shape);
  }

}
