import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { flatMap } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  submitted = false;
  err = false;
  langSelected = 'en';
  load = 'default';
  loginScreenObj;
  logoUrl;
  singleSubdomain;
  subdomain = false;
  apiResponse = false;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private communicationService: CommunicationService) { }

  ngOnInit() {

    this.getLoginScreenTheme();

    const hostname = window.location.host;
//smartjac.my.10ttech.com/ 
    if(hostname.includes('smartjac')){
      this.load = 'smartjac'
    }

    if(localStorage.getItem('language')) {
      this.langSelected = localStorage.getItem('language')
    } else {
      this.langSelected = 'en';
    }

    this.forgotPassForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  get f() { return this.forgotPassForm.controls; }

  onForgotPass() {
    this.submitted = true;
    if (this.forgotPassForm.invalid) {
      return;
    }

    const userData = {
      email: this.forgotPassForm.get('email').value
    };
    this.authService.forgot(userData, true);
  }

  changeLanguage() {
    localStorage.setItem('language', this.langSelected);
    this.translate.use(this.langSelected);
  }

  getLoginScreenTheme() {
    console.log(window.location.host);
    const data = {
      subdomainURL: window.location.host
    };

    this.apiResponse = false;
    this.authService.getLoginScreenTheme(data)
      .subscribe((res: any) => {
        this.loginScreenObj = res;
        this.apiResponse = true;
        if (this.loginScreenObj.length > 0) {

          this.subdomain = true;
          
          setTimeout(() => {
            $('.createaccount-form').css('padding', '140px 50px');
            $('.signin-inner').css('border', '1px solid rgb(116, 114, 114)');
            $('.signin-inner').css('border-radius', '15px');
            $('.onboarding-form-input').css('border-radius', '0.25rem');
            $('.onboarding-form-input').css('background-color', 'transparent');
            $('.signin-inner-btn').css('border-radius', '0.25rem');
            $('.signin-inner-cancel-btn').css('border-radius', '0.25rem');
          }, 10);
          
          this.singleSubdomain = this.loginScreenObj[0];

          this.logoUrl = this.singleSubdomain.logoUrl;

          document.documentElement.style.setProperty('--p-main-login-background', this.singleSubdomain.background);
          document.documentElement.style.setProperty('--p-main-login-leftSide', this.singleSubdomain.leftColor);
          document.documentElement.style.setProperty('--p-main-login-rightSide', this.singleSubdomain.rightColor);
          document.documentElement.style.setProperty('--p-main-login-btn', this.singleSubdomain.button);
          document.documentElement.style.setProperty('--p-main-login-btn-text', this.singleSubdomain.buttonTextColor);
          document.documentElement.style.setProperty('--p-main-login-cancel-btn',  this.singleSubdomain.cancelButton);
          document.documentElement.style.setProperty('--p-main-login-cancel-btn-text',  this.singleSubdomain.cancelButtonTextColor);
          document.documentElement.style.setProperty('--p-main-login-font', this.singleSubdomain.font);
        } else {
          this.subdomain = false;
          this.logoUrl = 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/image-10t.png';

          // document.documentElement.style.setProperty('--p-main-login-background', 'black');
          // document.documentElement.style.setProperty('--p-main-login-leftSide', '');
          // document.documentElement.style.setProperty('--p-main-login-rightSide', '#00bc90');
          // document.documentElement.style.setProperty('--p-main-login-btn', '#000000');
          // document.documentElement.style.setProperty('--p-main-login-btn-text', '#ffffff');
          document.documentElement.style.setProperty('--p-main-login-cancel-btn', '#004533');
          document.documentElement.style.setProperty('--p-main-login-cancel-btn-text', '#ffffff');
          // document.documentElement.style.setProperty('--p-main-login-font', 'rgb(54, 54, 54)');

          document.documentElement.style.setProperty('--p-main-login-background', '#fff');
          document.documentElement.style.setProperty('--p-main-login-leftSide', '');
          document.documentElement.style.setProperty('--p-main-login-rightSide', '#fff');
          document.documentElement.style.setProperty('--p-main-login-btn', '#000');
          document.documentElement.style.setProperty('--p-main-login-btn-text', '#fff');
          document.documentElement.style.setProperty('--p-main-login-font', 'rgb(54, 54, 54)');

          setTimeout(() => {
            $('.carousel').carousel({
              interval: 4000
            });
            $('.carousel-control.right').trigger('click');
          }, 10);
        }
      }, err => {
        this.apiResponse = true;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }
}
