import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../../services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  alertType;
  alertMessage;

  constructor(
    private communicationService: CommunicationService,
    private translate: TranslateService) { 
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      // const browserLang = translate.getBrowserLang();
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {
    this.communicationService.getAlertStatus()
      .subscribe(alert => {
        this.alertType = alert.alertType;
        this.alertMessage = alert.alertMsg;
        if (this.alertMessage.includes('Invalid email or password')) {
          const tempMessage = this.alertMessage.split(' (');
          this.alertMessage = tempMessage[0];
          // console.log(this.alertMessage.trim());
          // this.alertMessage = "Hi, I am Here";
          
        }
        ($('#alertModal') as any).modal({ backdrop: 'static', keyboard: false });
      });
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
  }
}
