import { Injectable } from '@angular/core';
import { ShapeComponent } from '../components/shape/shape.component';

@Injectable({
    providedIn: 'root'
})
export class ShapeService {

    private shapesComponents: ShapeComponent[] = [];

    private selectedComponent: ShapeComponent;

    constructor() {
        // console.log('ShapeService constructor() :', this.selectedComponent);
    }

    getShapeComponents(): ShapeComponent[] {
        return this.shapesComponents;
    }

    removeAllShapeComponents(): void {
        this.shapesComponents = [];
    }

    setShapeComponent(component: ShapeComponent): void {
        console.log('I am here ha ha');
        
        this.selectedComponent = component;
        if(this.selectedComponent.shape.shapeProperties.name.includes('watermark')) {
            this.shapesComponents.unshift(component);
        } else {
            this.shapesComponents.push(component);
        }
    }

    getShapeComponent(): ShapeComponent {
        return this.selectedComponent;
    }

    updateShapeComponents(value) {
        const index = this.shapesComponents.findIndex(x => x.isSelected === true);
        this.shapesComponents[index] = value;
    }

    findShapeComponent(name: string): ShapeComponent {
        return this.shapesComponents.find(x => x.shape.shapeProperties.name === name);
    }

    findShapeComponentIndex(name: string) {
        return this.shapesComponents.findIndex(x => x.shape.shapeProperties.name === name);
    }

    removeComponents(value) {
        const index = this.shapesComponents.findIndex(x => x.isSelected === true);
        this.shapesComponents.splice(index, 1);
        // [index] = value;
    }

    undoShape() {

    }

    updateLogo(url) {
        this.shapesComponents.forEach((shape) => {
            if (shape.shape.shapeProperties.name.includes('qrcodewithlogo-')) {                
                shape.shape.value = url;
                shape.shape.url = url;
            }
        })
    }
    addVariable() {
        this.shapesComponents.forEach((shape) => {
            if (shape.shape.shapeProperties.name.includes('pin1-')) {
                shape.shape.value = '{{pin1}}';
            }
            if (shape.shape.shapeProperties.name.includes('pin2-')) {
                shape.shape.value = '{{pin2}}';
            }
            if (shape.shape.shapeProperties.name.includes('puk1-')) {
                shape.shape.value = '{{puk1}}';
            }
            if (shape.shape.shapeProperties.name.includes('puk2-')) {
                shape.shape.value = '{{puk2}}';
            }
            if (shape.shape.shapeProperties.name.includes('iccid-')) {
                shape.shape.value = '{{iccid}}';
            }
            if (shape.shape.shapeProperties.name.includes('actoken-')) {
                shape.shape.value = '{{acToken}}';
            }
            if (shape.shape.shapeProperties.name.includes('lpastring-')) {
                shape.shape.value = '{{lpaString}}';
            }
            if (shape.shape.shapeProperties.name.includes('mcc-')) {
                shape.shape.value = '{{mcc}}';
            }
            if (shape.shape.shapeProperties.name.includes('mnc-')) {
                shape.shape.value = '{{mnc}}';
            }
            if (shape.shape.shapeProperties.name.includes('barcode-')) {
                shape.shape.value = '{{barcode}}';
                shape.shape.url = '{{barcode}}';
            }
            if (shape.shape.shapeProperties.name.includes('confirmationcode-')) {
                shape.shape.value = '{{confirmationcode}}';
            }
            if (shape.shape.shapeProperties.name.includes('eid-')) {
                shape.shape.value = '{{eid}}';
            }
            if (shape.shape.shapeProperties.name.includes('imsi-')) {
                shape.shape.value = '{{imsi}}';
            }
            if (shape.shape.shapeProperties.name.includes('qrcodewithlogo-')) {
                shape.shape.value = '{{qrcodewithlogo}}';
                shape.shape.url = '{{qrcodewithlogo}}';
            }
        });
    }

    removeVariable() {
        this.shapesComponents.forEach((shape) => {
            if (shape.shape.shapeProperties.name.includes('pin1-')) {
                shape.shape.value = '1234';
            }
            if (shape.shape.shapeProperties.name.includes('pin2-')) {
                shape.shape.value = '1234';
            }
            if (shape.shape.shapeProperties.name.includes('puk1-')) {
                shape.shape.value = '12345678';
            }
            if (shape.shape.shapeProperties.name.includes('puk2-')) {
                shape.shape.value = '12345678';
            }
            if (shape.shape.shapeProperties.name.includes('iccid-')) {
                shape.shape.value = '891010101010101010';
            }
            if (shape.shape.shapeProperties.name.includes('actoken-')) {
                shape.shape.value = 'ABCDE-ABCDE-ABCDE-ABCDE';
            }
            if (shape.shape.shapeProperties.name.includes('lpastring-')) {
                shape.shape.value = 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE';
            }
            if (shape.shape.shapeProperties.name.includes('mcc-')) {
                shape.shape.value = '123';
            }
            if (shape.shape.shapeProperties.name.includes('mnc-')) {
                shape.shape.value = '12';
            }
            if (shape.shape.shapeProperties.name.includes('confirmationcode-')) {
                shape.shape.value = '1234567890';
            }
            if (shape.shape.shapeProperties.name.includes('eid-')) {
                shape.shape.value = '12345678123456781234567812345678';
            }
            if (shape.shape.shapeProperties.name.includes('imsi-')) {
                shape.shape.value = '1234567890';
            }
            if (shape.shape.shapeProperties.name.includes('barcode-')) {
                shape.shape.value = 'assets/images/svg-editor/barcode.png';
                shape.shape.url = 'assets/images/svg-editor/barcode.png';
            }

            if (shape.shape.shapeProperties.name.includes('qrcodewithlogo-')) {
                shape.shape.value = 'assets/images/svg-editor/with-logo.png';
                shape.shape.url = 'assets/images/svg-editor/with-logo.png';
            }
        });
    }

    bringToFront(selectedComponent) {
        if (this.findShapeComponentIndex(selectedComponent.shape.shapeProperties.name) + 1 !== this.getShapeComponents().length) {
            this.getShapeComponents().splice(this.findShapeComponentIndex(selectedComponent.shape.shapeProperties.name), 1);
            this.getShapeComponents().push(selectedComponent);
        }
    }

    bringForward(selectedComponent) {
        const tempIndex = this.findShapeComponentIndex(selectedComponent.shape.shapeProperties.name);
        if (tempIndex + 1 !== this.getShapeComponents().length) {
            this.getShapeComponents().splice(tempIndex, 1);
            this.getShapeComponents().splice(tempIndex + 1, 0, selectedComponent);
        }
    }

    sendBack(selectedComponent) {
        if (this.findShapeComponentIndex(selectedComponent.shape.shapeProperties.name) !== 0) {
            this.getShapeComponents().splice(this.findShapeComponentIndex(selectedComponent.shape.shapeProperties.name), 1);
            this.getShapeComponents().unshift(selectedComponent);
        }
    }

    sendBackward(selectedComponent) {
        const tempIndex = this.findShapeComponentIndex(selectedComponent.shape.shapeProperties.name);
        if (tempIndex !== 0) {
            this.getShapeComponents().splice(tempIndex, 1);
            this.getShapeComponents().splice(tempIndex - 1, 0, selectedComponent);
        }
    }
}
