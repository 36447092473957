import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../../../shared/services/communication.service';
import { GroupMgmtService } from '../../../shared/services/group-mgmt.service';
import * as papa from 'papaparse';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../shared/services/customer.service';
import { UserState } from '../../../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../../../shared/services/cookie-trail.service';
import { UserService } from '../../../shared/services/user.service';
import * as UserAction from '../../../shared/stores/user/user.action';
import { environment } from '../../../../environments/environment';

declare var $: any;
type BigInt = number;
declare const BigInt: typeof Number;

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit {

  groupSetupFlgs = ['Single Profile Type', 'Multiple Profile Type'];
  groupSetup = 'Single Profile Type';
  iccidModes = ['File Upload', 'Count'];
  profileTypes;
  prodMnoId;
  groupName;
  groupDesc;
  groupNameErr = false;
  saveBtnSpinner = false;
  customerId;
  modifiedProfileTypes;
  group;
  groupList;
  modifiedGroupList;
  groupData;
  singleGroup;
  editOn = false;
  tempGroup;
  selectTitle;
  level = 0;
  user;
  groupSelectionErr = false;
  profileTypeSelectionErr = false;
  dataSpinner = false;
  profileSyncMsg: any = false;
  validationErr = false;
  iccidList;
  custId;

  constructor(
    private communicationService: CommunicationService,
    private groupService: GroupMgmtService,
    private activeRoute: ActivatedRoute,
    private customerService: CustomerService,
    private userStore: Store<UserState>,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService,
    private userService: UserService) {
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.activeRoute.params
      .subscribe((param: any) => {
        this.group = param;
        if(this.group.editId) {
          this.editOn = true;
          this.getSingleGroup(this.group.editId);
          this.userStore.select('user')
            .subscribe((user: any) => {
              if(user && user.data.currentCustomer && window.location.pathname.includes('/access-management/group/edit')) {
                this.user = user;
                if(user.data.currentCustomer.isParent) {
                  this.getProfileTypes();
                } else {
                  this.custId = user.data.currentCustomerId;
                  this.getSingleCustomer(user.data.currentCustomerId);
                }
              }
            });
        } else {
          this.userStore.select('user')
            .subscribe((user: any) => {
              if(user && user.data.currentCustomer && window.location.pathname === '/access-management/group/create') {
                this.user = user;
                if(user.data.currentCustomer.isParent) {
                  this.getProfileTypes();
                } else {
                  this.custId = user.data.currentCustomerId;
                  this.getSingleCustomer(user.data.currentCustomerId);
                }
              }
            });
        }
        
        // else if(this.group.editId) {
        //   this.getSingleGroup(this.group.editId);
        //   this.editOn = true;
        // } else {
        //   if(JSON.parse(localStorage.getItem('groupCustomer')).custId) {
        //     this.getSingleCustomer();
        //   } else {
        //     this.getProfileTypes();
        //   }
        // }
      });
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
  }

  getSingleCustomer(id) {
    this.selectTitle = 'Select Groups';
    this.dataSpinner = true;
    this.customerService.getGroupsOfCustomer(id)
      .subscribe((res: any) => {
        this.groupList = [];
        this.dataSpinner = false;
        this.level = res.groups && res.groups > 0 ? res.groups[0].level + 1 : 0;
        res.groups.forEach(element => {
          this.groupList.push({
            id: element._id,
            groupName: element.groupName,
            checked: false,
            selectedMode: '',
            iccidList: [],
            fileUpload: '',
            startingIccid: '',
            count: '',
            countErr: false,
            fileUploadErr: false,
            selectedModeErr: false
          });
        });

      }, err => {
        const cookieTrail = {
          screenName: 'Access Management - Single Group',
          action: 'Get Single Customer',
          body: id,
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.dataSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getSingleGroup(id) {
    this.groupService.getSingleGroup(id)
      .subscribe((res) => {
        this.singleGroup = res;
        if(this.editOn) {
          this.groupName = this.singleGroup.groupName;
          this.groupDesc = this.singleGroup.description;
          this.groupSetup = this.singleGroup.groupType === 'single' ? 'Single Profile Type' : 'Multiple Profile Type';
          this.tempGroup = {
            name: this.singleGroup.groupName,
            description: this.singleGroup.description
          };
        }
      }, err => {
        const cookieTrail = {
          screenName: 'Access Management - Single Group',
          action: 'Get Single Group',
          body: id,
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getProfileTypes() {
    this.selectTitle = 'Select Profile Types';
    this.dataSpinner = true;
    this.profileSyncMsg = false;
    this.groupService.getGroupProfileTypes()
      .subscribe((res: any) => {
        this.profileTypes = [];
        this.dataSpinner = false;
        if(res.message === 'Profiles Syncing is in Progress.') {
          this.profileSyncMsg = 'Profiles Syncing is in Progress.';
          this.communicationService.openAlert({alertType: 'error', alertMsg:'Profiles Syncing is in Progress.'});
        } else {
          res.profileTypes.forEach(element => {
            this.profileTypes.push({
              profileType: element,
              checked: false,
              selectedMode: '',
              iccidList: [],
              fileUpload: '',
              startingIccid: '',
              count: '',
              countErr: false,
              fileUploadErr: false,
              selectedModeErr: false
            });
          });
        }
      }, err => {

        const cookieTrail = {
          screenName: 'Access Management - Single Group',
          action: 'Get Profile Types',
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.dataSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getAllGroups() {
    this.groupService.getAllGroups()
      .subscribe((res: any) => {
        this.groupList = [];
        res.forEach(element => {
          this.groupList.push({
            id: element._id,
            groupName: element.groupName,
            checked: false,
            selectedMode: '',
            iccidList: [],
            fileUpload: '',
            startingIccid: '',
            count: '',
            countErr: false,
            fileUploadErr: false,
            selectedModeErr: false
          });
        });
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  isSpace(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 32 && !this.groupName.trim()) {
        return false;
    }
    return true;
  }

  uploadFile(index) {
    $('#groupFile_' + index).click();
  }

  async onFileChange(event, index) {
    if (event.target.files.length > 0) {
      if(this.groupList && this.groupList.length > 0) {
        this.groupList[index].fileUpload = event.target.files[0];
        this.groupList[index].iccidList = [];
      } else {
        this.profileTypes[index].fileUpload = event.target.files[0];
        this.profileTypes[index].iccidList = [];
      }
      event.target.value = '';
    }
  }

  checkValidation() {
    this.validationErr = false;
    if(this.groupList && this.groupList.length > 0) { 
      for(let i = 0; i < this.groupList.length; i++) { 
        if(this.groupList[i].checked) {
          if(this.groupList[i].selectedMode) {
            this.groupList[i].selectedModeErr = false;
            if(this.groupList[i].selectedMode === 'Count') {
              if(this.groupList[i].count > 0) {
                this.groupList[i].countErr = false;
              } else {
                this.groupList[i].countErr = true;
                this.validationErr = true;
              }
            } else if(this.groupList[i].selectedMode === 'File Upload') {
              if(this.groupList[i].fileUpload) {
                this.groupList[i].fileUploadErr = false;
              } else {
                this.groupList[i].fileUploadErr = true;
                this.validationErr = true;
              }
            }
          } else {
            this.groupList[i].selectedModeErr = true;
            this.validationErr = true;
          }
        }
      }
    } else if(this.profileTypes && this.profileTypes.length > 0) {
      for(let i = 0; i < this.profileTypes.length; i++) { 
        if(this.profileTypes[i].checked) {
          if(this.profileTypes[i].selectedMode) {
            this.profileTypes[i].selectedModeErr = false;
            if(this.profileTypes[i].selectedMode === 'Count') {
              if(this.profileTypes[i].count > 0) {
                this.profileTypes[i].countErr = false;
              } else {
                this.profileTypes[i].countErr = true;
                this.validationErr = true;
              }
            } else if(this.profileTypes[i].selectedMode === 'File Upload') {
              if(this.profileTypes[i].fileUpload) {
                this.profileTypes[i].fileUploadErr = false;
              } else {
                this.profileTypes[i].fileUploadErr = true;
                this.validationErr = true;
              }
            }
          } else {
            this.profileTypes[i].selectedModeErr = true;
            this.validationErr = true;
          }
        }
      }
    }
  }

  async confirmGroup() {
    if(!this.groupName || (this.groupName && !this.groupName.trim())) {
      this.groupNameErr = true;
      return;
    }

    await this.checkValidation();

    if(this.validationErr) {
      return;
    }

    if(!this.editOn) {
      if(this.groupList && this.groupList.length > 0) {
        for(let i = 0; i < this.groupList.length; i++) {
          if(this.groupList[i].checked) {
            this.groupSelectionErr = true;
          }
        }
      } else if(this.profileTypes && this.profileTypes.length > 0) {
        for(let i = 0; i < this.profileTypes.length; i++) { 
          if(this.profileTypes[i].checked) { 
            this.profileTypeSelectionErr = true;
          }
        }
      }
    }

    if(this.groupList && this.groupList.length > 0 && !this.groupSelectionErr && !this.editOn) {
      this.communicationService.openAlert({alertType: 'error', alertMsg: 'Please select group'});
      return;
    } else if(this.profileTypes && this.profileTypes.length > 0 && !this.profileTypeSelectionErr && !this.editOn) {
      this.communicationService.openAlert({alertType: 'error', alertMsg: 'Please select profile type'});
      return;
    }

    this.saveBtnSpinner = false;
    this.modifiedProfileTypes = [];
    this.modifiedGroupList = [];

    if(this.groupList && this.groupList.length > 0) {
      for(let i = 0; i < this.groupList.length; i++) {
        if(this.groupList[i].checked) {
          if(this.groupList[i].selectedMode === 'File Upload') {
            await this.groupParseFile(i);
          } else if(this.groupList[i].selectedMode === 'Count') {
            await this.generateIccid({startingIccid: this.groupList[i].startingIccid, count: this.groupList[i].count, index: i})
          }
        }
      }
    } else if(this.profileTypes && this.profileTypes.length > 0){
      for(let i = 0; i < this.profileTypes.length; i++) {
        if(this.profileTypes[i].checked) {
          if(this.profileTypes[i].selectedMode === 'File Upload') {
            await this.profileTypeParseFile(i);
          } else if(this.profileTypes[i].selectedMode === 'Count') {
            await this.generateIccid({startingIccid: this.profileTypes[i].startingIccid, count: this.profileTypes[i].count, index: i})
          }
        }
      }
    }
    
    if(this.groupList && this.groupList.length > 0) {
      for(let i = 0; i < this.groupList.length; i++) {
        if(this.groupList[i].checked) {
          await this.createGroupArray(i);
        }
      }
    } else {
      for(let i = 0; i < this.profileTypes.length; i++) {
        if(this.profileTypes[i].checked) {
          await this.createProfileTypeArray(i);
        }
      }
    }

    if(this.groupList && this.groupList.length > 0) {
      this.groupData = {
        groupName: this.groupName,
        description: this.groupDesc,
        groupType: this.groupSetup === 'Single Profile Type' ? 'single' : 'multiple',
        level: this.level,
        // parentGroup: this.group && this.group.id ? this.group.id : '',
        groups: this.modifiedGroupList,
        profileTypes: [],
        id: this.group && this.group.editId ? this.group.editId : ''
      };
    } else {
      this.groupData = {
        groupName: this.groupName,
        description: this.groupDesc,
        groupType: this.groupSetup === 'Single Profile Type' ? 'single' : 'multiple',
        level: 0,
        groups: [],
        profileTypes: this.modifiedProfileTypes,
        id: this.group && this.group.editId ? this.group.editId : ''
      };
    }

    $('#groupConfirmationModal').modal();
  }

  createGroup() {
    this.saveBtnSpinner = true;

    const cookieTrail = {
      screenName: 'Access Management - Single Group',
      action: 'Create Group',
      body: this.groupData
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    
    this.groupService.createGroup(this.groupData)
      .subscribe((res: any) => {
        this.groupData = '';
        this.saveBtnSpinner = false;
        $('#groupConfirmationModal').modal('hide');
        this.iccidList = [];
        res.iccidList.forEach(element => {
          this.iccidList.push({
            iccid: element.iccid,
            state: element.state,
          })
        });

        this.getUser();

        ($('#groupCreationSuccess') as any).modal({ backdrop: 'static', keyboard: false });
        this.groupName = '';
        this.groupDesc = '';
        this.groupSetup = 'Single Profile Type';
        // this.groupList = [];
        // this.profileTypes = [];
        // if(this.groupList && this.groupList.length > 0) {
        //   this.getSingleCustomer(this.custId);
        // } else {
        //   this.getProfileTypes();
        // }
        // this.router.navigate(['/access-management/group']);
      }, err => {
        const cookieTrail = {
          screenName: 'Access Management - Single Group',
          action: 'Create Group',
          body: this.groupData,
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.saveBtnSpinner = false;
        this.groupData = '';
        $('#groupConfirmationModal').modal('hide');
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });  
  }

  getUser() {
    this.userService.getUser()
      .subscribe((res: any) => {
        this.userStore.dispatch(new UserAction.GetUser(res));
      });
  }

  editGroup() {

    // if((this.tempGroup.name.trim() != this.groupName.trim()) || (this.tempGroup.description.trim() != this.groupDesc.trim())) {
    //   let groupData;

    //   if((this.tempGroup.name.trim() != this.groupName.trim()) && (this.tempGroup.description.trim() != this.groupDesc.trim())) {
    //     groupData = {
    //       id: this.singleGroup._id,
    //       groupName: this.groupName,
    //       description: this.groupDesc
    //     };
    //   } else if(this.tempGroup.name.trim() != this.groupName.trim()) {
    //     groupData = {
    //       id: this.singleGroup._id,
    //       groupName: this.groupName
    //     };
    //   } else if(this.tempGroup.description.trim() != this.groupDesc.trim()) {
    //     groupData = {
    //       id: this.singleGroup._id,
    //       description: this.groupDesc
    //     };
    //   }

      const cookieTrail = {
        screenName: 'Access Management - Single Group',
        action: 'Edit Group',
        body: this.groupData,
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);

      this.groupService.editGroup(this.groupData)
        .subscribe((res: any) => {
          this.saveBtnSpinner = false;
          $('#groupConfirmationModal').modal('hide');
          this.iccidList = [];
          res.iccidList.forEach(element => {
            this.iccidList.push({
              iccid: element.iccid,
              state: element.state,
            })
          });

          ($('#groupCreationSuccess') as any).modal({ backdrop: 'static', keyboard: false });
          this.groupName = '';
          this.groupDesc = '';
          this.groupSetup = 'Single Profile Type';
          // this.groupList = [];
          // this.profileTypes = [];
          // if(this.groupList && this.groupList.length > 0) {
          //   this.getSingleCustomer(this.custId);
          // } else {
          //   this.getProfileTypes();
          // }
        }, err => {
          const cookieTrail = {
            screenName: 'Access Management - Single Group',
            action: 'Edit Group',
            body: this.groupData,
            error: err.error || 'Something went wrong!'
          };
          
          this.cookieTrailService.saveCookie(cookieTrail);
          this.saveBtnSpinner = false;
          this.groupData = '';
          $('#groupConfirmationModal').modal('hide');
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    // }
  }

  async createProfileTypeArray(index) {
    await this.modifiedProfileTypes.push({
      profileType: this.profileTypes[index].profileType,
      iccidList: this.profileTypes[index].iccidList,
      selectedMode: this.profileTypes[index].selectedMode,
      startingIccid: this.profileTypes[index].startingIccid,
      count: this.profileTypes[index].count
    });
  }

  async createGroupArray(index) {
    await this.modifiedGroupList.push({
      groupId: this.groupList[index].id,
      groupName: this.groupList[index].groupName,
      iccidList: this.groupList[index].iccidList,
      selectedMode: this.groupList[index].selectedMode,
      startingIccid: this.groupList[index].startingIccid,
      count: this.groupList[index].count
    });
  }

  async profileTypeParseFile(index) {
    this.profileTypes[index].iccidList = [];
    return new Promise(resolve => {
      papa.parse(this.profileTypes[index].fileUpload, {
        header: true,
        // newline: '\n',
        dynamicTyping: false,
        skipEmptyLines: true,
        transformHeader:function(h) {
          h = h.trim();
          h = h.toLowerCase();
          return h;
        },
        complete: results => {
          resolve(results.data.forEach((ele: any, i) => {
            if (i >= 0) {
              this.profileTypes[index].iccidList.push(ele.iccid);
            }
          }));
        }
      });
    });
  }

  async groupParseFile(index) {
    this.groupList[index].iccidList = [];
    return new Promise(resolve => {
      papa.parse(this.groupList[index].fileUpload, {
        header: true,
        // newline: '\n',
        dynamicTyping: false,
        skipEmptyLines: true,
        transformHeader:function(h) {
          h = h.trim();
          h = h.toLowerCase();
          return h;
        },
        complete: results => {
          resolve(results.data.forEach((ele: any, i) => {
            if (i >= 0) {
              this.groupList[index].iccidList.push(ele.iccid);
            }
          }));
        }
      });
    });
  }

  async generateIccid(data) {
    const iccid = BigInt(data.startingIccid);
    const length = Number(data.count);

    if(this.groupList && this.groupList.length > 0) {
      this.groupList[data.index].iccidList = [];
      for (let i = 0; i < length; i++) {
        await this.groupList[data.index].iccidList.push(String(iccid + BigInt(i)));
      }
    } else {
      this.profileTypes[data.index].iccidList = [];
      for (let i = 0; i < length; i++) {
        await this.profileTypes[data.index].iccidList.push(String(iccid + BigInt(i)));
      }
    }
    
  }

  checkGroupSetUp(index) {
    if(this.groupSetup === 'Single Profile Type') {
      if(this.groupList && this.groupList.length > 0) {
        this.groupList.forEach((ele: any, i) => {
          if(i !== index) {
            ele.checked = false;
            ele.selectedMode = false;
            ele.fileUpload = '';
            ele.count = '';
            ele.countErr = false;
            ele.fileUploadErr = false;
            ele.selectedModeErr = false;
          }
        });
      } else {
        this.profileTypes.forEach((ele: any, i) => {
          if(i !== index) {
            ele.checked = false;
            ele.selectedMode = false;
            ele.fileUpload = '';
            ele.count = '';
            ele.countErr = false;
            ele.fileUploadErr = false;
            ele.selectedModeErr = false;
          }
        });
      }
    }

    setTimeout(() => {
      if(this.groupList && this.groupList.length > 0) { 
        if(!this.groupList[index].checked) {
          this.groupList[index].selectedMode = '';
          this.groupList[index].fileUpload = '';
          this.groupList[index].count = '';
          this.groupList[index].countErr = false;
          this.groupList[index].fileUploadErr = false;
          this.groupList[index].selectedModeErr = false;
        }
      } else if(this.profileTypes && this.profileTypes.length > 0) {
        if(!this.profileTypes[index].checked) {
          this.profileTypes[index].selectedMode = '';
          this.profileTypes[index].fileUpload = '';
          this.profileTypes[index].count = '';
          this.profileTypes[index].countErr = false;
          this.profileTypes[index].fileUploadErr = false;
          this.profileTypes[index].selectedModeErr = false;
        }
      }
    }, 10);
    
  }

  clearProfileTypes() {
    if(this.groupList && this.groupList.length > 0) { 
      this.groupList && this.groupList.forEach((ele: any) => {
        ele.checked = false;
        ele.selectedMode = '';
        ele.fileUpload = '';
        ele.count = '';
        ele.countErr = false;
        ele.fileUploadErr = false;
        ele.selectedModeErr = false;
      });
    } else {
      this.profileTypes && this.profileTypes.forEach((ele: any) => {
        ele.checked = false;
        ele.selectedMode = '';
        ele.fileUpload = '';
        ele.count = '';
        ele.countErr = false;
        ele.fileUploadErr = false;
        ele.selectedModeErr = false;
      });
    }
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 47 && charCode < 58) {
        return true;
    }
    return false;
  }
}
