import { Logo } from './logo.model';
import * as LogoAction from './logo.action';

export interface LogoState {
    readonly logo: Logo;
}

export function logoReducer(state: Logo, action: LogoAction.LogoActions) {

    switch (action.type) {

        case LogoAction.GET_LOGO:
            return action.payload;

        default: return state;
    }
}
