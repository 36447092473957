import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { UserMgmtComponent } from './user-mgmt/user-mgmt.component';
import { AccessManagementRoutingModule } from './access-management-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from '../shared/component/component.module';
import { HttpClient } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { RoleMgmtComponent } from './role-mgmt/role-mgmt.component';
import { RoleDetailComponent } from './role-mgmt/role-detail/role-detail.component';
import { ProfiletypeManagementComponent } from './profiletype-management/profiletype-management.component';
import { ProfiletypeDetailComponent } from './profiletype-management/profiletype-detail/profiletype-detail.component';
import { GroupMgmtComponent } from './group-mgmt/group-mgmt.component';
import { GroupDetailComponent } from './group-mgmt/group-detail/group-detail.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SingleGroupComponent } from './group-mgmt/single-group/single-group.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [
        UserMgmtComponent,
        RoleMgmtComponent,
        RoleDetailComponent,
        ProfiletypeManagementComponent,
        ProfiletypeDetailComponent,
        GroupMgmtComponent,
        GroupDetailComponent,
        SingleGroupComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        NgxIntlTelInputModule,
        MatCheckboxModule,
        MatRadioModule,
        AccessManagementRoutingModule,
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
            }
          }),
          TooltipModule
    ],
    exports: [
        UserMgmtComponent,
        RoleMgmtComponent,
        RoleDetailComponent
    ]
})
export class AccessManagementModule { }