import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { MousePosition, ConfirmationCode } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';

@Component({
  selector: 'app-confirmationcode',
  templateUrl: './confirmationcode.component.html',
  styleUrls: ['./confirmationcode.component.scss']
})
export class ConfirmationCodeComponent extends ShapeComponent implements OnInit {

  constructor() {
    super();
    this.shape = new ConfirmationCode();
    this.shapeType = ShapeType.MCC;
  }

  ngOnInit() {
    //   console.log('TextComponent ngOnInit');
  }

  updateShapeProperties(value: any) {
    if (this.shape instanceof ConfirmationCode) {
      this.shape.shapeProperties.name = value.name;
      this.shape.value = value.value;
      this.shape.originX = value.x;
      this.shape.originY = value.y;
    }
  }

  setStyles() {
    const styles = {
      fill: this.shape.shapeProperties.strokeColor
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof ConfirmationCode) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
      this.shape['font-family'] = 'Arial';
      this.shape['font-size'] = 16;
    }
  }
}

