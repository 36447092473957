<div class="inner-main-profile" style="overflow: auto; max-height: calc(100vh - 90px);" *ngIf="showScreen">
    <div class="email-config-row col-lg-12 p-0">
        <div class="email-config-container">
            <div class="face-container-inner-bg pb-4">
                <div class="email-config-header profile-manage-row result-not-found-row border-bottom-light pl-3">
                    <h4 class="result-not-found-row-heading">{{ 'External Email Setup' | translate }}</h4>
                    <div class="edit-theme-head-right edit-navbar-head-right">
                        <div class="email-custom-toggle">
                            <button class="btn btn-t-default" [ngClass]="{'btn-custom-active': isDefault}" (click)="changeSettingType()">{{"Default" | translate}}</button>
                            <button class="btn btn-custom" [ngClass]="{'btn-custom-active': !isDefault}" (click)="changeSettingType()">{{"Custom" | translate}}</button>
                        </div>
                    </div>
                </div>
                <div class="config-container p-3">
                    <form [formGroup]="serverForm" class="external-email-form">
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3">
                                    <label class="t-label col-lg-3 p-0">{{ 'Outgoing Server' | translate}}</label>
                                    <div class="col-lg-8">
                                        <input type="text" class="form-control t-input" formControlName="outingServer" [ngClass]="{'default-setting': isDefault}">
                                        <div>
                                            <span class="help-block ng-invalid text-danger" *ngIf="(!f.outingServer.valid && !f.outingServer.pristine) || (f.outingServer.errors && submitted)">
                                                <span class="small" *ngIf="submitted && f.outingServer.errors && f.outingServer.errors.required">
                                                    {{'Outgoing Server is required!' | translate}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3">
                                    <label class="t-label col-lg-3 p-0">{{ 'Port No' | translate }}</label>
                                    <div class="col-lg-8">
                                        <input type="text" class="form-control t-input" formControlName="portNo" [ngClass]="{'default-setting': isDefault}">
                                        <span class="help-block ng-invalid text-danger" *ngIf="(!f.portNo.valid && !f.portNo.pristine) || (f.portNo.errors && submitted)">
                                            <span class="small" *ngIf="submitted && f.portNo.errors && f.portNo.errors.required">
                                            {{'Port Number is required!' | translate}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3">
                                    <label class="t-label col-lg-3 p-0">{{ 'Secure' | translate }}</label>
                                    <div class="col-lg-8 filter-box-checkbox-container">
                                        <mat-checkbox formControlName="secure"></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="config-auth-container col-lg-6 mt-3">
                                <h5 class="mb-4">{{'Authentication' | translate }}:</h5>
                                <div class="col-lg-12 d-flex p-0 mt-3 mb-3">
                                    <label class="t-label col-lg-3 p-0">{{ 'User Name' | translate }}</label>
                                    <div class="col-lg-8"> 
                                        <input type="text" class="form-control t-input" formControlName="userName" [ngClass]="{'default-setting': isDefault}">
                                        <span class="help-block ng-invalid text-danger" *ngIf="(!f.userName.valid && !f.userName.pristine) || (f.userName.errors && submitted)">
                                            <span class="small" *ngIf="submitted && f.userName.errors.required">
                                                {{ "User Name required!" | translate}}
                                            </span>
                                        </span>
                                        <span class="help-block ng-invalid text-danger" *ngIf="f.userName.errors && submitted">
                                            <span class="small" *ngIf="submitted && f.userName.errors.email">
                                                {{ "Enter valid User Name!" | translate}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3">
                                    <label class="t-label col-lg-3 p-0">{{ 'Password' | translate }}</label>
                                    <div class="col-lg-8">
                                        <input type="password" class="form-control t-input" formControlName="password" [ngClass]="{'default-setting': isDefault}">
                                        <span class="help-block ng-invalid text-danger" *ngIf="(!f.password.valid && !f.password.pristine) || (f.password.errors && submitted)">
                                            <span class="small" *ngIf="submitted && f.password.errors && f.password.errors.required">
                                            {{'Password is required' | translate}}!
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3">
                                    <label class="t-label col-lg-3 p-0">{{ 'Alias (Optional)' | translate }}</label>
                                    <div class="col-lg-8">
                                        <input type="text" class="form-control t-input" formControlName="aliasEmail" [ngClass]="{'default-setting': isDefault}">
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3">
                                    <button class="btn t-bgcolor mt-0 outline d-flex ml-auto justify-content-center" style="width: 170px;" *ngIf="!isDefault && !testBtnSpinner" type="button" (click)="testEmailServerSetup();">{{'Test' | translate}}</button>
                                    <div class="btn p-0 btn-spinner mt-0 d-flex ml-auto justify-content-center" style="width: 170px;" *ngIf="testBtnSpinner">
                                        <div class="loader quantum-spinner m-auto"></div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-3 border-bottom-light"></div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <h5 class="mb-4 pt-3">{{'Message Content' | translate }}</h5>
                            </div>

                            <div class="col-lg-12 mb-4">
                                <div class="row align-items-center">
                                    <label class="t-label pl-3" style="width: 212px;">{{ 'Logo' | translate}}</label>
                                    <div class="col-lg-8 d-flex align-items-center">
                                        <button class="btn py-1 mr-5 t-font-medium bg-light-grey text-secondary outline" [ngStyle]="{'pointer-events': isDefault ? 'none' : 'auto'}" (click)="clickUploadIcon()">
                                            <input id="emailLogo" type="file" style="display: none" accept="image/x-png,image/gif,image/jpeg" (change)="onChangeIcon($event)">
                                            {{'Choose File' | translate}}
                                        </button>
                                        <p class="mb-0" *ngIf="emailLogoName">
                                            {{emailLogoName}}
                                        </p>
                                        <div class="ml-5" style="width: 120px;">
                                            <img id="email-logo" class="img-fluid" style="max-height: 65px;" alt="">
                                        </div>
                                        <div class="ml-5 pt-1"> 
                                            <label class="t-label p-0">(
                                            {{'We suggest a logo size of 300px height. File should be in JPG or PNG format' | translate}}.)</label>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            
                            <div class="col-lg-6">
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3 align-items-center">
                                    <label class="t-label p-0" style="width: 212px;">{{ 'Sent By' | translate}}</label>
                                    <div class="col-lg-8 pl-0">
                                        <input type="text" class="form-control t-input" formControlName="sentBy" [ngClass]="{'default-setting': isDefault}">
                                        <div>
                                            <span class="help-block ng-invalid text-danger" *ngIf="(!f.sentBy.valid && !f.sentBy.pristine) || (f.sentBy.errors && submitted)">
                                                <span class="small" *ngIf="!isTest && submitted && f.sentBy.errors && f.sentBy.errors.required">
                                                    {{'Sent By is required' | translate}}!
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="col-lg-12 d-flex p-0 mt-4 mb-3 align-items-center">
                                    <!-- <label class="t-label col-lg-3 p-0">{{ 'Support Email' | translate}}</label> -->
                                    <div class="t-box col-lg-3 p-0 d-flex prp-info support-email-tooltip align-items-center">
                                        <div class="mr-2 t-label">{{'Support Email' | translate}}</div>
                                        <div class="c-color c-info py-0" tooltip="{{'The support email address will appear on some system generated emails for users to reach out for queries or information' | translate}}" [tooltipAnimation]="true" placement="top">
                                            <i class="c-color c-info py-0 fa fa-info mr-3 icon-count-class"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-8">
                                        <input type="text" class="form-control t-input" formControlName="supportEmail" [ngClass]="{'default-setting': isDefault}">
                                        <div>
                                            <span class="help-block ng-invalid text-danger" *ngIf="(!f.supportEmail.valid && !f.supportEmail.pristine) || (f.supportEmail.errors && submitted)">
                                                <span class="small" *ngIf="!isTest && submitted && f.supportEmail.errors && f.supportEmail.errors.required">
                                                    {{'Support Email is required' | translate}}!
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex ml-auto"> 
                            <div class="mt-4 ml-auto">
                                <div class="d-flex align-item-center">
                                    <button class="btn t-bgcolor outline" style="width: 170px;" type="button" (click)="customServerSetup();" *ngIf="!isDefault && !customBtnSpinner">{{'Save' | translate}}</button>
                                    <div class="btn-spinner p-0" style="width: 170px;" *ngIf="customBtnSpinner">
                                        <div class="loader quantum-spinner m-auto"></div>
                                    </div>
                                </div>
                                
                                <button class="btn t-bgcolor outline" type="button" style="width: 170px;" (click)="defaultServerSetup();" *ngIf="isDefault && !defaultBtnSpinner">{{'Save' | translate}}</button>
                                <div class="btn-spinner mr-4" *ngIf="defaultBtnSpinner">
                                    <div class="loader quantum-spinner m-auto"></div>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
