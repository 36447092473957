import { Action } from '@ngrx/store';
import { User } from './user.model';

export const GET_USER = 'GET_USER';

export class GetUser implements Action {
    readonly type = GET_USER;

    constructor(public payload: User) { }
}

export type UserActions = GetUser;
