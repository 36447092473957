import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GovernanceReportRoutingModule } from './governance-report-routing.module';
import { GovernanceReportComponent } from './governance-report.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    GovernanceReportComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GovernanceReportRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    GovernanceReportComponent
  ]
})
export class GovernanceReportModule { }
