<div class="svg-editor-perent">
    <div class="row">
        <div class="col-lg-12">
            <div class="svg-editor-navbar">
                <ul class="svg-editor-nav-item-list mb-0">
                    <li>
                        <a>
                            <div class="svg-editor-logo">
                                <img src="assets/images/logo/small-light-logo-new.png" alt="">
                            </div>
                        </a>
                    </li>
                    <li class="svg-editor-nav-item" *ngIf="features && (features[getFeatureIndex('campaign')].view)">
                        <a class="svg-editor-nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="btnSpinner = false;isHidden=true;" [disabled]="pdfPngSpinner">
                            {{ "File" | translate}}
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" data-toggle="modal" (click)="newCampaign();defaultPageSize();" *ngIf="features && features[getFeatureIndex('campaign')].edit">{{ "New Campaign" | translate}}</a>
                            <a class="dropdown-item" data-toggle="modal" data-backdrop="static" data-keyboard="false" href="#openCampaign" (click)="getAllTemplates();" *ngIf="features && features[getFeatureIndex('campaign')].view">{{ "Open" | translate}}</a>
                            <button class="dropdown-item" data-toggle="modal" (click)="updateCampign(); selectedComponent=null;" [disabled]="getShapes().length <= 0" *ngIf="features && features[getFeatureIndex('campaign')].edit">{{ "Save" | translate}}</button>
                            <button class="dropdown-item" data-toggle="modal" data-target="#saveTemplate" data-backdrop="static" data-keyboard="false" (click)="isSelect=false;" [disabled]="getShapes().length <= 0" *ngIf="features && features[getFeatureIndex('campaign')].edit">{{ "Save As" | translate}}...</button>
                            <a class="dropdown-item" data-toggle="modal" href="#generatePdf" (click)="getAllTemplates();tempPdfID='';iccidFile='';templateSelectionErr=false;fileSelectionErr=false;fileTypeSelectionErr=false;selectedCampaignFormat='PNG'" *ngIf="features && features[getFeatureIndex('campaign_generate')].view">{{ "Generate Campaign" | translate}}</a>
                        </div>
                    </li>
                    <li class="ng-star-inserted svg-editor-nav-item px-0 pr-4 ml-auto">
                        <p class="svg-editor-nav-link" style="margin-bottom: unset;">{{'Page Size' | translate}}: {{svgWidth}} * {{svgHeight}} px</p>
                    </li>
                    <li class="svg-editor-nav-item px-0 pr-2">
                        <a class="svg-editor-nav-link" (click)="sidebarToggle();zoom = !zoom">
                            <img src="assets/images/svg-editor/expand.svg" alt="" style="width: 25px;">
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="svg-editor-main-box">
                <div class="svg-editor-toolbox-perent">
                    <div class="svg-editor-toolbox">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active toolbox-outer mt-2" id="nav-profile-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner ? 'auto' : 'none'}" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="true" (click)="selectTool('Pointer')">
                                <div class="svg-editor-toolbox-inner-icon">
                                    <img src="assets/images/svg-editor/cursor.svg" alt="" style="height: 20px;">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Select" | translate}}</span>
                                </div>
                            </a>
                            <a class="nav-item nav-link toolbox-outer" id="nav-text-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" data-toggle="tab" href="#nav-text" role="tab" aria-controls="nav-text" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/typography.svg" alt="">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Text" | translate}}</span>
                                </div>
                            </a>
                            <a class="nav-item nav-link toolbox-outer" id="nav-image-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" data-toggle="tab" href="#nav-image" role="tab" aria-controls="nav-image" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/picture.svg" alt="">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Image" | translate}}</span>
                                </div>
                            </a>
                            <a class="nav-item nav-link toolbox-outer" id="nav-shapes-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" data-toggle="tab" href="#nav-shapes" role="tab" aria-controls="nav-shapes" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon">
                                    <!-- <img src="assets/images/svg-editor/shapes.png" alt=""> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="32.001" viewBox="0 0 34 32.001">
                                        <g id="Group_542" data-name="Group 542" transform="translate(-35 -450.999)">
                                          <path id="Subtraction_30" data-name="Subtraction 30" d="M-3432.064-10225H-3442a5.006,5.006,0,0,1-5-5v-15a5.006,5.006,0,0,1,5-5h15a5.006,5.006,0,0,1,5,5v7.37a9.454,9.454,0,0,0-1.5-.121,9.4,9.4,0,0,0-1,.054v-7.3a2.5,2.5,0,0,0-2.5-2.5h-15a2.5,2.5,0,0,0-2.5,2.5v15a2.5,2.5,0,0,0,2.5,2.5h9.3a9.2,9.2,0,0,0,.631,2.5Z" transform="translate(3482 10701)"/>
                                          <g id="Rectangle_432" data-name="Rectangle 432" transform="translate(48 462)" fill="#FF9016" stroke="#000" stroke-width="2.5">
                                            <rect width="21" height="21" rx="10.5" stroke="none"/>
                                            <rect x="1.25" y="1.25" width="18.5" height="18.5" rx="9.25" fill="none"/>
                                          </g>
                                        </g>
                                    </svg>
                                      
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Shapes" | translate}}</span>
                                </div>
                            </a>
                            <a class="nav-item nav-link toolbox-outer" id="nav-watermark-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" data-toggle="tab" href="#nav-watermark" role="tab" aria-controls="nav-watermark" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/watermark.png" alt="">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Watermark" | translate}}</span>
                                </div>
                            </a>
                            <a class="nav-item nav-link toolbox-outer" id="nav-background-image-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" data-toggle="tab" href="#nav-background" role="tab" aria-controls="nav-background" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/editor-icons/web-image.png" alt="">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Background" | translate}} <br> {{ "Image" | translate | limitTo: 9}}</span>
                                </div>
                            </a>
                            <a class="nav-item nav-link toolbox-outer" id="nav-dynamic-c-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" role="tab" data-toggle="tab" href="#nav-dynamic-c" aria-controls="nav-dynamic-c" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/qr-code.svg" alt="">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Dynamic" | translate}} <br> {{ "Component" | translate | limitTo: 9}}</span>
                                </div>
                            </a>

                            <a class="nav-item nav-link toolbox-outer" id="nav-page-size-tab" [ngStyle]="{'pointer-events': isDraw && !pdfPngSpinner  ? 'auto' : 'none'}" role="tab" data-toggle="tab" href="#nav-page-size" aria-controls="nav-page-size" aria-selected="false">
                                <div class="svg-editor-toolbox-inner-icon"><img src="/assets/images/svg-editor/page-open.png" alt="">
                                    <span class="svg-editor-toolbox-inner-icon-text"> {{ "Page Size" | translate}}</span>
                                </div>
                            </a>
                        </div>
                        <div class="nav nav-tabs mt-auto" [ngClass]="{'mb-3': !zoom, 'mb-5': zoom}" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link" [ngClass]="{'isDisabled': allComponents.length === 0 }" [ngStyle]="{'pointer-events': isDraw ? 'auto' : 'none'}" id="nav-dynamic-c-tab" (click)="undo()" *ngIf="!pdfPngSpinner">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/undo.svg" alt="" style="transform: rotate(180deg) scaleY(-1);">
                                </div>
                            </a>
                            <a class="nav-item nav-link isDisabled" id="nav-dynamic-c-tab" *ngIf="pdfPngSpinner">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/undo.svg" alt="" style="transform: rotate(180deg) scaleY(-1);">
                                </div>
                            </a>
                            <a class="nav-item nav-link" [ngClass]="{'isDisabled': redoActions.length === 0 }" [ngStyle]="{'pointer-events': isDraw ? 'auto' : 'none'}" id="nav-dynamic-c-tab" (click)="redo()" *ngIf="!pdfPngSpinner">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/undo.svg" alt="">
                                </div>
                            </a>
                            <a class="nav-item nav-link isDisabled" id="nav-dynamic-c-tab" *ngIf="pdfPngSpinner">
                                <div class="svg-editor-toolbox-inner-icon"><img src="assets/images/svg-editor/undo.svg" alt="">
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="svg-editor-toolbox-expand">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show" id="nav-shapes" role="tabpanel" aria-labelledby="nav-shapes-tab">
                                <div class="tab-content-inner">
                                    <div class="tab-content-inner-patch">
                                        <div>
                                            <button (click)="selectShape('Line')" type="button" class="btn outline"><img
                                                src="assets/images/editor-icons/line.png" width="24" height="24"></button>
                                            <button (click)="selectShape('Path')" type="button" class="btn isDisabled"><img
                                                    src="assets/images/editor-icons/path.png" width="24" height="24"></button>
                                            <button (click)="selectShape('Circle')" type="button" class="btn outline"><img
                                                src="assets/images/editor-icons/circle.png" width="24" height="24"></button>
                                        </div>
                                    </div>
                                    <div class="tab-content-inner-patch">
                                        <div>
                                            <button (click)="selectShape('Ellipse')" type="button" class="btn outline"><img
                                                src="assets/images/editor-icons/ellipse.png" width="24" height="24"></button>
                                            <button (click)="selectShape('Square')" type="button" class="btn outline"><img
                                                src="assets/images/editor-icons/square.png" width="24" height="24"></button>
                                            <button (click)="selectShape('Rectangle')" type="button" class="btn outline"><img
                                                    src="assets/images/editor-icons/rectangle.png" width="24" height="24"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade active show svg-editor-select" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div class="tab-content-inner" *ngIf="textSelected">
                                    <textarea [(ngModel)]="textProperties.value" (ngModelChange)="setTextProps()" [disabled]="isdynamicCompSelected"></textarea>
                                </div>
                                <div class="tab-content-inner" *ngIf="isImageSelected">
                                    <button type="button" class="btn outline" data-toggle="modal" data-target="#imageCropper" (click)="loadCropper = true"><img
                                        src="assets/images/svg-editor/crop.svg" width="24" height="24"></button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-text" role="tabpanel" aria-labelledby="nav-text-tab">
                                <div class="tab-content-inner">
                                    <button (click)="selectShape('TextBox')" type="button" class="btn outline"><img
                                        src="assets/images/editor-icons/text.png" width="24" height="24"></button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-image" role="tabpanel" aria-labelledby="nav-image-tab">
                                <div class="tab-content-inner">
                                    <button (click)="selectShape('Image');pdfPngSpinner=false;" type="button" class="btn outline"><img
                                        src="assets/images/editor-icons/image.png" width="24" height="24"></button>
                                    <input id="updalodFile" type="file" hidden accept=".png, .jpeg, .jpg, .pdf" (change)="assignUpdalodedImage($event.target.files)">
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-link" role="tabpanel" aria-labelledby="nav-link-tab">
                                <div class="tab-content-inner">
                                    <p class="text-info">link</p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-crop" role="tabpanel" aria-labelledby="nav-crop-tab">
                                <div class="tab-content-inner">
                                    <p class="text-info">crop</p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-fill-color" role="tabpanel" aria-labelledby="nav-fill-color-tab">
                                <div class="tab-content-inner">
                                    <p class="text-info">fill-color</p>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-watermark" role="tabpanel" aria-labelledby="nav-watermark-tab">
                                <div class="tab-content-inner">
                                    <button (click)="selectShape('Watermark')" type="button" class="btn outline"><img
                                        src="assets/images/editor-icons/image.png" width="24" height="24"></button>
                                    <input id="updalodWatermarkFile" type="file" accept=".png, .jpeg, .jpg, .pdf" hidden (change)="assignUpdalodedWatermark($event.target.files)">
                                </div>
                            </div>
                            <div class="tab-pane fade menus-window" id="nav-dynamic-c" role="tabpanel" aria-labelledby="nav-dynamic-c-tab">
                                <div class="dynamic-component tab-content-inner">
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('QRCode')">{{ "QR Code" | translate}}</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('QRCodeWithLogo')">{{ "QR Code With Logo" | translate}}</p>
                                    <p *ngIf="(currentCustomer && currentCustomer.isParent) || (!currentCustomer && rootCustomer && rootCustomer.isParent)" class="dc-icon" [ngClass]=" groupDisable? 'disable-comp': 'pointer'" (click)="selectShape('GroupQR')">{{ "Group QR" | translate}}</p>
                                    <p *ngIf="(currentCustomer && currentCustomer.isParent) || (!currentCustomer && rootCustomer && rootCustomer.isParent)" class="dc-icon" [ngClass]=" groupDisable? 'disable-comp': 'pointer'" (click)="selectShape('GroupQRWithLogo')">{{ "Group QR With Logo" | translate}}</p>
                                    <p class="dc-icon pointer" (click)="selectShape('LPAString')">{{ "LPA String" | translate}}</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('ICCID')">{{ "ICCID" | translate}}</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('ACToken')">{{ "Activation Token" | translate}}</p>
                                    <!-- <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('PIN1')">PIN1</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('PIN2')">PIN2</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('PUK1')">PUK1</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('PUK2')">PUK2</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('MCC')">{{ "MCC" | translate}}</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('MNC')">{{ "MNC" | translate}}</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('IMSI')">IMSI</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('ConfirmationCode')">{{ "Confirmation Code" | translate}}</p>
                                    <p class="dc-icon" [ngClass]=" iccidDisable? 'disable-comp': 'pointer'" (click)="selectShape('EID')">{{ "EID" | translate}}</p> -->

                                    <!-- <p class="dc-icon pointer" (click)="selectShape('Barcode')">Barcode</p> -->
                                </div>
                            </div>
                            <div class="tab-pane fade background-image-style" id="nav-background" role="tabpanel" aria-labelledby="nav-background-image-tab">
                                <div class="tab-content-inner d-flex align-items-center">
                                    <img class="mr-1" src="assets/images/editor-icons/add-background.png" width="24" height="24">
                                    <button (click)="selectShape('Background')" type="button" class="btn outline"> {{ 'Add Background Image' | translate }}</button>
                                    <input id="updalodBackgroundImage" type="file" hidden accept=".png, .jpeg, .jpg, .pdf" (change)="assignBackground($event.target.files)">
                                </div>
                                <div class="tab-content-inner d-flex align-items-center">
                                    <img class="mr-1" src="assets/images/editor-icons/remove-background.png" width="24" height="24">
                                    <button (click)="removeBackground()" type="button" class="btn outline">{{ 'Remove Background Image' | translate }}</button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="nav-page-size" role="tabpanel" aria-labelledby="nav-page-size-tab">
                                <div class="tab-content-inner">
                                    <div class="tab-content-inner style-label page-size-selection">
                                        <div class="tab-content-inner-patch row">
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('A4')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'A4'}"> <img src="assets/images/editor-icons/a4-size.png" width="42"><p>A4 {{'Size' | translate}}</p></button>
                                            </div>
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('A5')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'A5'}"> <img src="assets/images/editor-icons/a4-size.png" width="42"> <p>A5 {{'Size' | translate}}</p></button>
                                            </div>
                                        </div>
                                        <div class="tab-content-inner-patch row">
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('A7')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'A7'}"> <img src="assets/images/editor-icons/a4-size.png" width="42"><p>A7 {{'Size' | translate}}</p></button>
                                            </div>
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('A8')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'A8'}"> <img src="assets/images/editor-icons/a4-size.png" width="42"> <p>A8 {{'Size' | translate}}</p></button>
                                            </div>
                                        </div>
                                        <div class="tab-content-inner-patch row">
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('Business Card')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'Business Card'}"> <img src="assets/images/editor-icons/card-size.png" width="42"> <p>{{'Card Size' | translate}}</p></button>
                                            </div>
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('Story Size')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'Story Size'}"> <img src="assets/images/editor-icons/story-size.png" width="42"> <p class="breakWord">{{'Story Size' | translate}}</p></button>
                                            </div>
                                        </div>
                                        <div class="tab-content-inner-patch row">
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('Envelope Size')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'Envelope Size'}"> <img src="assets/images/editor-icons/letter.png" width="42"> <p>{{'Envelope Size' | translate}}</p></button>
                                            </div>
                                            <!-- here -->
                                            <div class="col-6">
                                                <button (click)="assignHeightWidth('Custom')" type="button" class="btn outline" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'Custom'}" data-toggle="modal" data-target="#customSizeModal"> <img src="assets/images/editor-icons/custom-size.png" width="42"> <p class="breakWord">{{'Custom Size' | translate}}</p></button>
                                            </div>
                                            <!-- here -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="svg-editor-main-view">
                    <div class="svg-editor-main-view-inner" style="position: relative; overflow: scroll;">
                        <svg xmlns="http://www.w3.org/2000/svg" id="campaignEditorSvg" xmlns:xlink="http://www.w3.org/1999/xlink" (contextmenu)="onRightClick($event)" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)" style="overflow: auto;"
                            [ngClass]="{'pointer-none': pdfPngSpinner, 'color-pointer': fillSelected, 'crosshair': selectedShape, 'select-pointer': isSelect }" [ngStyle]="{'background': pageProps.pageBackground}">
                                <ng-container *ngFor="let shape of getShapes()">
                                    {{shape}}
                                    <ng-container DynamicSvg [component]="shape"></ng-container>
                                </ng-container>
                            </svg>
                        <div class="btn-spinner pdf-png-absolute-center" *ngIf="pdfPngSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>

                        <div class="editor-right-click-menu" id="menuView" #menuView>
                            <div class="under-menu" *ngIf="!isHidden && isDraw">
                                <ul class="list-group" id="ulElement">
                                    <li class="list-group-item list-group-item-action" [ngClass]="{'cursor-p': this.deleteOn }" (click)="rightClickFunctions('removeElement')">
                                        <div [ngClass]="{'isDisabled': !this.deleteOn }">{{ "Delete" | translate}}</div>
                                    </li>
                                    <li class="list-group-item list-group-item-action" [ngClass]="{'cursor-p': this.deleteOn || isWatermark }" (click)="rightClickFunctions('bringToFront')">
                                        <div [ngClass]="{'isDisabled': !this.deleteOn || isWatermark}">{{ "Bring to Front" | translate}}</div>
                                    </li>
                                    <li class="list-group-item list-group-item-action" [ngClass]="{'cursor-p': this.deleteOn || isWatermark }" (click)="rightClickFunctions('bringForward')">
                                        <div [ngClass]="{'isDisabled': !this.deleteOn || isWatermark }">{{ "Bring Forward" | translate}}</div>
                                    </li>
                                    <li class="list-group-item list-group-item-action" [ngClass]="{'cursor-p': this.deleteOn || isWatermark }" (click)="rightClickFunctions('sendBackward')">
                                        <div [ngClass]="{'isDisabled': !this.deleteOn || isWatermark }">{{ "Send Backward" | translate}}</div>
                                    </li>
                                    <li class="list-group-item list-group-item-action" [ngClass]="{'cursor-p': this.deleteOn || isWatermark }" (click)="rightClickFunctions('sendBack')">
                                        <div [ngClass]="{'isDisabled': !this.deleteOn || isWatermark }">{{ "Send Back" | translate}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="svg-editor-properties">
                    <h5 class="properties-head mb-4">{{ "Properties" | translate}}</h5>
                    <div class="properties-inner border-bottom" *ngIf="!isImageSelected">
                        <div class="fill-color-row" *ngIf="!textSelected">
                            <input class="fill-color-square" [cpPosition]="'left'" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" [(colorPicker)]="rectangleProps.fillColor" [style.background]="rectangleProps.fillColor" (colorPickerChange)="setRectProps()" (cpSliderDragStart)="addActions()"
                                [disabled]="!rectangleProps.id" />
                            <p class="mb-0 tool-head ml-2">{{ "Fill Color" | translate}}</p>
                            <img class="ml-auto fill-color-img" src="assets/images/svg-editor/color-picker.svg" alt="">
                        </div>
                        <div class="fill-color-row" *ngIf="textSelected">
                            <input class="fill-color-square" [cpPosition]="'left'" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" [(colorPicker)]="textProperties.fillColor" [style.background]="textProperties.fillColor" (colorPickerChange)="setTextProps()" (cpSliderDragStart)="addActions()"
                            />
                            <p class="mb-0 tool-head ml-2">{{ "Text Color" | translate}}</p>
                            <img class="ml-auto fill-color-img" src="assets/images/svg-editor/color-picker.svg" alt="">
                        </div>
                        <div class="fill-color-row" *ngIf="!textSelected">
                            <input class="fill-color-square" [cpPosition]="'left'" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" [(colorPicker)]="rectangleProps.boderColor" [style.background]="rectangleProps.boderColor" (colorPickerChange)="setRectProps()" cpPosition="right"
                                (cpSliderDragStart)="addActions()" [disabled]="!rectangleProps.id" />
                            <p class="mb-0 tool-head ml-2">{{ "Border Color" | translate}}</p>
                            <img class="ml-auto fill-color-img" src="assets/images/svg-editor/color-picker.svg" alt="">
                        </div>
                        <div class="fill-color-row">
                            <input class="fill-color-square" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" [cpPosition]="'left'" [(colorPicker)]="pageProps.pageBackground" [style.background]="pageProps.pageBackground" (colorPickerChange)="setRectProps()" cpPosition="right"
                                (cpSliderDragStart)="addActions()" *ngIf="pageProps" [disabled]="!isDraw" />
                            <p class="mb-0 tool-head ml-2" style="width: 133px;">{{ "Background Color" | translate}}</p>
                            <img class="ml-auto fill-color-img" src="assets/images/svg-editor/color-picker.svg" alt="">
                        </div>
                    </div>
                    <div class="properties-inner border-bottom">
                        <div class="fill-size-row" *ngIf="!textSelected">
                            <p class="d-flex" *ngIf="!isCircleSelected"><span class="tool-head">{{ "Height" | translate}}: </span> <input type="text" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" class="tool-head-dark input-dark ml-2" style="width: 50px;" (keypress)="isNumber($event)"
                                    [(ngModel)]="rectangleProps.height" (ngModelChange)="setRectProps(); addActions()" [disabled]="!rectangleProps.id">
                                <span class="tool-head-dark ml-1"> px</span>
                            </p>
                            <p class="d-flex" *ngIf="!isCircleSelected"><span class="tool-head">{{ "Width" | translate}}: </span> <input type="text" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" class="tool-head-dark input-dark ml-2" style="width: 50px;" (keypress)="isNumber($event)"
                                    [(ngModel)]="rectangleProps.width" (ngModelChange)="setRectProps(); addActions()" [disabled]="!rectangleProps.id">
                                <span class="tool-head-dark ml-1"> px</span>
                            </p>
                            <p class="d-flex" *ngIf="!isImageSelected"><span class="tool-head">{{ "Border Width" | translate}}: </span> <input type="text" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" class="tool-head-dark input-dark ml-2" style="width: 50px;" (keypress)="isNumber($event)"
                                    [(ngModel)]="rectangleProps.border" (ngModelChange)="setRectProps(); addActions()" [disabled]="!rectangleProps.id">
                                <span class="tool-head-dark ml-1"> px</span>
                            </p>
                            <p class="d-flex mb-0" *ngIf="!isCircleSelected && !isImageSelected"><span class="tool-head">{{ "Corner Radius" | translate}}: </span> <input type="text" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" class="tool-head-dark input-dark ml-2 align-self-center"
                                    style="width: 50px;" (keypress)="isNumber($event)" [(ngModel)]="rectangleProps.borderRadius" (ngModelChange)="setRectProps(); addActions()" [disabled]="!rectangleProps.id"><span class="tool-head-dark ml-1"> px</span></p>
                        </div>

                        <div class="fill-size-row" *ngIf="textSelected">
                            <p>
                                <label for="svg-editor-font" class="tool-head mb-1">{{ "Font" | translate}} {{textProperties.font}}</label>
                                <select name="" class="input-dark custom-select" id="svg-editor-font" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" aria-placeholder="Select Font" [(ngModel)]="textProperties.font" (ngModelChange)="setTextProps()">
                                    <!-- <option [value]="fontList[0]" selected="selected">Arial</option> -->
                                    <option *ngFor="let font of fontList" [value]="font">{{font}}</option>
                                </select>
                            </p>

                            <div class="d-flex justify-content-between">
                                <div>
                                    <p class="mb-1 small tool-head">{{ "Size" | translate}}</p>
                                    <div class="d-flex">
                                        <input type="text" class="tool-head-dark input-dark" style="width: 60px;" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" [(ngModel)]="textProperties.fontSize" (ngModelChange)="setTextProps(); addActions();" (keypress)="isNumber($event)">
                                        <span class="tool-head-dark ml-1"> px</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="fill-size-row" *ngIf="qrWithLogo">
                            <!-- <div class="mb-4 pt-1">
                                {{'We suggest a logo size of 300px height. File should be in JPG or PNG format. You can use short logo or symbol' | translate}}.
                            </div> -->
                            <div class="row">
                                <div class="col-lg-6 d-flex align-items-center">
                                    <p class="mb-0"><span class="tool-head">{{ 'Upload Logo' | translate}}:</span></p>
                                </div>
                                <div class="col-lg-6 d-flex align-items-center">
                                    <button class="btn py-0 t-font-medium bg-light-grey text-secondary" [ngStyle]="{'pointer-events': pdfPngSpinner ? 'none' : 'auto' }" (click)="clickUploadIcon('small')">
                                        <input id="uploadLogo" type="file" style="display: none" accept=".png, .jpeg, .jpg, .pdf" (change)="uploadLogo($event.target.files)">
                                        {{'Choose File' | translate}}
                                    </button>
                                    <!-- <div class="ml-5" style="width: 120px;">
                                        <img id="small-app-icon" style="max-height: 65px;" class="img-fluid" alt="">
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="saveTemplate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Save This Campaign" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" aria-label="Close" (click)="btnSpinner = false;" *ngIf="!btnSpinner">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4 device-flex-body">

                <div class="px-4">
                    <div class="form-group mt-3">
                        <label for="" class="text-secondary">{{ "Campaign Name" | translate}}:</label>
                        <input class="form-control t-input" type="text" (keypress)="isSpace($event)" [(ngModel)]="templateName">
                        <span class="small text-danger" *ngIf="!templateName && submitted">
                            {{ "Campaign name is required" | translate}}!
                        </span>
                    </div>
                    <button class="btn t-bgcolor btn-block mb-3 mt-4 outline" (click)="saveTemplate()" *ngIf="!btnSpinner"> {{ "Submit" | translate}}</button>
                    <div class="btn-spinner" *ngIf="btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="generatePdf" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Generate Campaign" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4 device-flex-body">
                <div class="px-4">
                    <div class="form-group mt-3">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ "Select Campaign" | translate}}</mat-label>
                            <mat-select [(ngModel)]="tempPdfID" (ngModelChange)="templateSelectionErr = false;">
                                <mat-option *ngFor="let template of allTemplates" [value]="template">
                                    {{template.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>
                                <span class="text-danger" *ngIf="templateSelectionErr">{{ "Please select campaign" | translate}}!</span>
                            </mat-hint>
                        </mat-form-field>
                        <div class="d-flex">
                            <mat-label class="mr-5">{{ "Campaign Format" | translate}}</mat-label>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedCampaignFormat">
                                <mat-radio-button class="example-radio-button mr-4" *ngFor="let format of campaignFormat" [value]="format">
                                    {{format}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <mat-form-field appearance="outline" style="width: 100%;" *ngIf="tempPdfID && tempPdfID.campaignType === 'group'">
                            <mat-label>{{ "Select Group" | translate}}</mat-label>
                            <mat-select [(ngModel)]="groupMatchingId" (ngModelChange)="groupSelectionErr = false;">
                                <mat-option *ngFor="let group of grpProfileList" [value]="group.groupMatchingId">
                                    {{group.groupName}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>
                                <span class="text-danger" *ngIf="groupSelectionErr">{{ "Please select group" | translate}}!</span>
                            </mat-hint>
                        </mat-form-field>

                        <div class="d-flex mt-3" *ngIf="!tempPdfID || tempPdfID.campaignType !== 'group'">
                            <p class="mb-0">{{ "Please upload list of ICCID here" | translate}}</p>
                            <div class="qr-code-file-upload-btn ml-5 d-flex">
                                <p class="mr-5 mb-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size cursor-p" (click)="uploadQrFile()" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs></defs><g transform="translate(28.768 29.233) rotate(180)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                    <input id="svg-bulk-qr-file" type="file" style="display: none" accept=".txt,.csv" (change)="onQrFileChange($event)">
                                </p>
                                <a href="assets/csv/sample-iccid-qr.txt" download>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="svg-btn-color svg-t-size" width="28.279" height="29.233" viewBox="0 0 28.279 29.233"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-0.489)"><path class="a" d="M24.842,19.1v6.678H4.415V19.1H.489v8.641a1.962,1.962,0,0,0,1.966,1.962H26.8a1.962,1.962,0,0,0,1.966-1.962V19.1Z" transform="translate(0 -0.475)"/><path class="a" d="M14.468,17.968,8.847,11.176s-.855-.807.072-.807h3.167V.482S11.961,0,12.686,0h4.458c.523,0,.511.406.511.406v9.759h2.924c1.125,0,.278.845.278.845s-4.782,6.348-5.449,7.014A.624.624,0,0,1,14.468,17.968Z" transform="translate(-0.199)"/></g></svg>
                                </a>
                            </div>
                        </div>
                        <span class="text-danger small" *ngIf="fileTypeSelectionErr">{{ "File should be in .txt or .csv format" | translate}}.</span>
                        <span class="text-danger small" *ngIf="fileSelectionErr && tempPdfID && tempPdfID.campaignType !== 'group'">{{ "Please select file" | translate}}!</span>
                        <div class="t-font-light mt-3 small" *ngIf="iccidFile && tempPdfID && tempPdfID.campaignType !== 'group'">
                            {{ "File Name" | translate}}: {{iccidFile.name}}
                        </div>
                    </div>
                    <button class="btn t-bgcolor btn-block mb-3 mt-4 outline" (click)="generatePdf()" *ngIf="!btnSpinner"> {{ "Submit" | translate}}</button>
                    <div class="btn-spinner" *ngIf="btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="openCampaign" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Open Campaign" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" aria-label="Close" (click)="btnSpinner = false;" *ngIf="!btnSpinner">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4 device-flex-body">
                <div class="px-4">
                    <div class="form-group mt-3">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>{{ "Select Campaign" | translate}}</mat-label>
                            <mat-select [(ngModel)]="tempPdfID" (ngModelChange)="templateSelectionErr = false;">
                                <mat-option *ngFor="let template of allTemplates" [value]="template">
                                    {{template.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>
                                <span class="text-danger" *ngIf="templateSelectionErr">{{ "Please select campaign" | translate}}!</span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <button class="btn t-bgcolor btn-block mb-3 mt-4 outline" (click)="openCampaign();" *ngIf="!btnSpinner"> {{ "Open" | translate}}</button>
                    <div class="btn-spinner" *ngIf="btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="imageCropper" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Crop" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" aria-label="Close" (click)="loadCropper = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4 device-flex-body" *ngIf="loadCropper">
                <image-cropper [imageBase64]="imageToCrop" [maintainAspectRatio]="false" [aspectRatio]="4 / 3" format="png, jpeg, webp, bmp, ico" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                <button class="btn t-bgcolor btn-block mb-3 mt-4 outline" (click)="saveCropped();" *ngIf="!btnSpinner"> {{ "Crop" | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <image-cropper [imageBase64]="imageToCrop" [maintainAspectRatio]="false" [aspectRatio]="4 / 3" format="png, jpeg, webp, bmp, ico" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper> -->

<div class="modal fade" id="pageSize" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal" style="background-color: #fff;">
            <div class="modal-header text-center border-bottom">
                <h5 class="modal-title" id="exampleModalLabel" style="color: #000;">{{ "Create New Page" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" aria-label="Close" (click)="btnSpinner = false;" *ngIf="!btnSpinner">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4 device-flex-body">
                <div class="px-4">
                    <div class="form-group mt-3 p-3 page-selection">
                        <div class="page-size" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'A4'}" (click)="pageProps.selctedPageSize = 'A4'">
                            <img src="/assets/images/svg-editor/A4-size.png" alt="">
                            <h5 class="mb-0 mt-2" style="color: #000;">{{'A4 Size' | translate}}</h5>
                        </div>
                        <div class="page-size" [ngClass]="{'active-page-border': pageProps.selctedPageSize === 'Business Card'}" (click)="pageProps.selctedPageSize = 'Business Card'">
                            <img src="/assets/images/svg-editor/card-size.png" alt="">
                            <h5 class="mb-0 mt-2" style="color: #000;">{{'Card Size' | translate}}</h5>
                        </div>
                    </div>
                    <button class="btn t-bgcolor btn-block mb-3 mt-4 outline" (click)="assignHeightWidth(pageProps.selctedPageSize);" *ngIf="!btnSpinner"> {{ "Submit" | translate}}</button>
                    <div class="btn-spinner" *ngIf="btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="customSizeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h5 class="modal-title text-dark" id="exampleModalLabel">{{ "Custom Size" | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" aria-label="Close" (click)="resetForm()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="customSizeForm" (ngSubmit)="assignCustomSize()">
                <div class="modal-body py-4 device-flex-body">

                    <div class="px-4">
                        <div class="form-group mt-3">
                            <label for="" class="text-secondary">{{ "Page Width" | translate}}:</label>
                            <div class="d-flex align-items-center">
                                <input class="form-control text-secondary" type="text" formControlName="customWidth">
                                <label for="" class="text-secondary ml-2">px</label>
                            </div>
                            
                            <span class="help-block ng-invalid text-danger" *ngIf="((!f.customWidth.valid && !f.customWidth.pristine) || f.customWidth.errors) && customSizeFormSubmitted">
                                <span class="small" *ngIf="f.customWidth.errors.required">
                                    {{ "width is required!" | translate}}
                                </span>
                            <span class="small" *ngIf="f.customWidth.errors.min">
                                    {{ "width should be min 100px" | translate}}
                                </span>
                            </span>
                        </div>
                        <div class="form-group mt-3">
                            <label for="" class="text-secondary">{{ "Page Height" | translate}}:</label>
                            <div class="d-flex align-items-center">
                                <input class="form-control text-secondary" type="text" formControlName="customHeight">
                                <label for="" class="text-secondary ml-2">px</label>
                            </div>
                            <span class="help-block ng-invalid text-danger" *ngIf="((!f.customHeight.valid && !f.customHeight.pristine) || f.customHeight.errors) && customSizeFormSubmitted">
                                <span class="small" *ngIf="f.customHeight.errors.required">
                                    {{ "height is required!" | translate}}
                                </span>
                            <span class="small" *ngIf="f.customHeight.errors.min">
                                    {{ "height should be min 100px" | translate}}
                                </span>
                            </span>
                        </div>
                        <button type="submit" class="btn t-bgcolor btn-block mb-3 mt-4 outline"> {{ "Submit" | translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>