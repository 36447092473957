import { ShapeType, State } from './shape-types';

/*
 * The MousePosition object
 */
export class MousePosition {
    x: number;
    y: number;

    constructor() {
        this.x = 0;
        this.y = 0;
    }
}

/*
 * The ShapeProperties object
 */
export class ShapeProperties {
    fillColor: string;
    strokeColor: string;
    strokeWidth: number;
    fill: boolean;
    stroke: boolean;
    shapeType: ShapeType;
    name: string;
    visible: boolean;
    rx: number;
    ry: number;
    constructor() {
        this.fill = false;
        this.fillColor = '#fff';
        this.stroke = true;
        this.strokeColor = '#000';
        this.strokeWidth = 1;
        this.name = 'unknown';
        this.visible = true;
    }
}

/*
 * Interface for the shapes
 */
// tslint:disable-next-line:no-empty-interface
export interface BaseShape {
}

/*
 * The Shape object
 */
export class Shape implements BaseShape {
    public shapeProperties: ShapeProperties;
    public originX: number;
    public originY: number;
    public x1: number;
    public y1: number;
    public x2: number;
    public y2: number;
    public value: string;
    public width: number;
    public height: number;
    public shapeType: number;
    public rx: number;
    public ry: number;
    public r: number;
    public url: string;
    public 'font-style': string;
    public 'font-family': string;
    public fill: string;
    public 'font-size': number;
    constructor(name: string) {
        this.shapeProperties = new ShapeProperties();
        this.originX = this.originY = 0;
        this.shapeProperties.name = name;
    }
}

/*
 * The Line class.
 */
export class Line extends Shape {

    private static id = 0;

    public x2: number;
    public y2: number;

    constructor() {
        super('line-' + Line.id++);
        this.x2 = this.y2 = 0;
        // console.log('Line constructor ', this);
    }
}

/*
 * The Circle class.
 */
export class Circle extends Shape {
    private static id = 0;

    public r: number;

    constructor() {
        super('circle-' + Circle.id++);
        this.r = 0;
        // console.log('Circle constructor ', this);
    }
}

/*
 * The Rectangle class.
 */
export class Rectangle extends Shape {
    private static id = 0;

    public width: number;
    public height: number;

    constructor() {
        super('rectangle-' + Rectangle.id++);
        this.width = this.height = 0;
        // console.log('Rectangle constructor ', this);
    }
}

/*
 * The Triangle class.
 */
export class Triangle extends Shape {
    private static id = 0;

    public width: number;
    public height: number;
    public x1: number;
    public y1: number;
    public x2: number;
    public y2: number;
    public x3: number;
    public y3: number;

    constructor() {
        super('Triangle-' + Triangle.id++);
        this.width = this.height = 0;
        // console.log('Triangle constructor ', this);
    }
}

/*
 * The Square class.
 */
export class Square extends Shape {
    private static id = 0;

    public width: number;

    constructor() {
        super('square-' + Square.id++);
        this.width = 0;
        // console.log('Rectangle constructor ', this);
    }
}

/*
 * The Ellipse class.
 */
export class Ellipse extends Shape {
    private static id = 0;

    public rx: number;
    public ry: number;

    constructor() {
        super('ellipse-' + Ellipse.id++);
        this.rx = this.ry = 0;
        // console.log('Ellipse constructor ', this);
    }
}

/*
 * The TextBox class.
 */
export class TextBox extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('text-' + TextBox.id++);
        this.value = 'Some text';
        // console.log('Text constructor ', this);
    }
}

/*
 * The ACToken class.
 */
export class LPAString extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('lpastring-' + LPAString.id++);
        this.value = 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE';
        // console.log('LPAString constructor ', this);
    }
}

/*
 * The ACToken class.
 */
export class ACToken extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('actoken-' + ACToken.id++);
        this.value = 'ABCDE-ABCDE-ABCDE-ABCDE';
        // console.log('ACToken constructor ', this);
    }
}

/*
 * The iccid class.
 */
export class ICCID extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('iccid-' + ICCID.id++);
        this.value = '891010101010101010';
        // console.log('ICCID constructor ', this);
    }
}

/*
 * The PIN1 class.
 */
export class PIN1 extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('pin1-' + PIN1.id++);
        this.value = '1234';
        // console.log('PIN1 constructor ', this);
    }
}

/*
 * The PIN2 class.
 */
export class PIN2 extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('pin2-' + PIN2.id++);
        this.value = '1234';
        // console.log('PIN2 constructor ', this);
    }
}

/*
 * The PUK1 class.
 */
export class PUK1 extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('puk1-' + PUK1.id++);
        this.value = '12345678';
        // console.log('PUK1 constructor ', this);
    }
}

/*
 * The PUK2 class.
 */
export class PUK2 extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('puk2-' + PUK2.id++);
        this.value = '12345678';
        // console.log('PUK2 constructor ', this);
    }
}

/*
 * The MCC class.
 */
export class MCC extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('mcc-' + MCC.id++);
        this.value = '123';
        // console.log('MCC constructor ', this);
    }
}

/*
 * The MNC class.
 */
export class MNC extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('mnc-' + MNC.id++);
        this.value = '12';
        // console.log('MNC constructor ', this);
    }
}

/*
 * The IMSI class.
 */
export class IMSI extends Shape {
    private static id = 0;

    public value: string;

    constructor() {
        super('imsi-' + IMSI.id++);
        this.value = '1234567890';
    }
}

/*
 * The IMSI class.
 */
export class ConfirmationCode extends Shape {
    private static id = 0;
    public value: string;
    constructor() {
        super('confirmationcode-' + ConfirmationCode.id++);
        this.value = '1234567890';
    }
}

/*
 * The IMSI class.
 */
export class EID extends Shape {
    private static id = 0;
    public value: string;
    constructor() {
        super('eid-' + EID.id++);
        this.value = '12345678123456781234567812345678';
    }
}

/*
 * The Image class.
 */
export class ImageBox extends Shape {
    private static id = 0;

    public width: number;
    public height: number;
    public url: string;

    constructor() {
        super('image-' + ImageBox.id++);
        this.width = this.height = 0;
        this.url = localStorage.getItem('imageUrl') || 'assets/pictures/new-test.png';
        // console.log('Image constructor ', this);
    }
}

export class WatermarkBox extends Shape {
    private static id = 0;

    public width: number;
    public height: number;
    public url: string;

    constructor() {
        super('watermark-' + WatermarkBox.id++);
        this.width = this.height = 0;
        this.url = localStorage.getItem('watermarkUrl') || 'assets/pictures/new-test.png';
        // console.log('Image constructor ', this);
    }
}

/*
 * The Image class.
 */
export class QRCodeBox extends Shape {
    private static id = 0;

    public width: number;
    public height: number;
    public url: string;

    constructor() {
        super('qrcode-' + QRCodeBox.id++);
        this.width = this.height = 0;
        this.url = 'assets/images/svg-editor/sampleqrcode.png';
        // console.log('Image constructor ', this);
    }
}

/*
 * The qr-code-with-logo class.
 */
export class QRCodeWithLogo extends Shape {
    private static id = 0;

    public width: number;
    public height: number;
    public url: string;

    constructor() {
        super('qrcodewithlogo-' + QRCodeWithLogo.id++);
        this.width = this.height = 0;
        this.url = 'assets/images/svg-editor/with-logo.png';
        // console.log('Image constructor ', this);
    }
}
/*
 * The Image class.
 */
export class Barcode extends Shape {
    private static id = 0;

    public width: number;
    public height: number;
    public url: string;

    constructor() {
        super('barcode-' + Barcode.id++);
        this.width = this.height = 0;
        this.url = 'assets/images/svg-editor/barcode.png';
        // console.log('Image constructor ', this);
    }
}

/*
 * The PolyLine class.
 */
export class PolyLine extends Shape {
    private static id = 0;

    public points: MousePosition[];

    constructor() {
        super('polyline-' + PolyLine.id++);
        this.points = [];
        // console.log('PolyLine constructor ', this);
    }
}

/*
 * The Path class.
 */
export class Path extends Shape {
    private static id = 0;

    public points: MousePosition[];
    public state: State;

    constructor() {
        super('path-' + Path.id++);
        this.points = [];
        this.state = State.None;
        // console.log('Path constructor ', this);
    }
}
