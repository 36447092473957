import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UiCustomizationService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getTheme(mode) {
    return this.http.get(`${this.serverUrl}/theme?mode=${mode}`, this.getHeader())
      .pipe(map(response => response));
  }

  saveTheme(data) {
    return this.http.post(`${this.serverUrl}/theme`, data, this.getHeader())
      .pipe(map(response => response));
  }

  saveLogo(mode, type, data) {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
      })
    };

    return this.http.post(`${this.serverUrl}/logo?mode=${mode}&type=${type}`, data, httpOptions)
      .pipe(map(response => response));
  }

  getLogos() {
    return this.http.get(`${this.serverUrl}/logos`, this.getHeader())
      .pipe(map(response => response));
  }

  factoryResetTheme() {
    return this.http.delete(`${this.serverUrl}/theme`, this.getHeader())
      .pipe(map(response => response));
  }

  resetToPreviousTheme(mode) {
    return this.http.put(`${this.serverUrl}/theme?mode=${mode}`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  getDefaultLogo() {
    return this.http.get(`${this.serverUrl}/logos/default`, this.getHeader())
      .pipe(map(response => response));
  }
  
  setDefaultLogo(data) {
    return this.http.post(`${this.serverUrl}/logos/default`, data, this.getHeader())
      .pipe(map(response => response));
  }

  deleteLogo(data) {
    return this.http.post(`${this.serverUrl}/logos/delete`, data, this.getHeader())
      .pipe(map(response => response));
  }
}
