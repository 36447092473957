import { Action } from '@ngrx/store';
import { Theme } from './theme.model';

export const GET_THEME = 'GET_THEME';

export class GetTheme implements Action {
    readonly type = GET_THEME;

    constructor(public payload: Theme) { }
}

export type ThemeActions = GetTheme;
