<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">{{group.groupName}}</h4>
                    <div class="d-flex ml-auto" [routerLink]="['/access-management/group/create/', group._id]">
                        <button class="btn t-bgcolor outline"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Create Sub Group' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table t-table table-scroll">
                        <thead>
                            <tr>
                                <th>{{'Group Name' | translate}}</th>
                                <th>{{'Group Setup' | translate}}</th>
                                <th>{{"No. Of ICCID's" | translate}}</th>
                                <th>{{"Parent Group" | translate}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="loader-flex-center" *ngIf="groupSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </tbody>
                        <tbody *ngIf="group && group.children && group.children.length <= 0 && !groupSpinner">
                            <tr>
                                <td colspan="5" class="text-center">{{'No Sub Groups Found' | translate}}.</td>
                            </tr>
                        </tbody>
                        <tbody style="max-height: calc(95vh - 200px);" id="groupAccordian" *ngIf="!groupSpinner">
                            <tr *ngFor="let group of group.children; let i = index">
                                <td [routerLink]="['/access-management/group/', group._id]" class="cursor-p">{{group.groupName}}</td>
                                <td>{{group.groupType === 'single' ? 'Single Group' : 'Multiple Group'}}</td>
                                <td>{{group.iccidCount}}</td>
                                <td *ngFor="let signleGroup of group.groups">
                                    <span>{{signleGroup.groupName}}</span>
                                </td>
                                <td class="profile-manage-icon-row">
                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" [routerLink]="['/access-management/group/edit', group._id]"></i>
                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deletUserModal" aria-hidden="true"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> 
