import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Color, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { UserState } from 'src/app/shared/stores/user/user.reducer';
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as pluginPiechartOutlabels from 'chartjs-plugin-piechart-outlabels';
import * as papa from 'papaparse';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { timezones } from '../report';
import { UserService } from 'src/app/shared/services/user.service';
import * as UserAction from '../../shared/stores/user/user.action'

declare var $:any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-device-analytics',
  templateUrl: './device-analytics.component.html',
  styleUrls: ['./device-analytics.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class DeviceAnalyticsComponent implements OnInit, OnDestroy {

  tabId;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    rotation: (-0.5 * Math.PI) - (90/180 * Math.PI),
    legend: {
      display: false,
      position: 'right',
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          return data.labels[tooltipItem.index] + ': ' + Number(data.datasets[0].data[tooltipItem.index]).toFixed(2) + '%'
        }
      }
    },
    layout: {
      padding: {
        top: 70,
        right: 15,
        bottom: 25,
        left: 15
      }
    },
    plugins: {
      pluginDataLabels,
      pluginPiechartOutlabels,
      outlabels: {
        stretch: 17,
        text: '%l - %p',
        font: {
          resizable: true,
          minSize: 12,
          maxSize: 13
        }
      },
      datalabels: {
        color: 'white',
        anchor: 'end',
        align: 'end',
        display: function(context) {
          return false;
        },
        font: {
          weight: 'bold'
        },
        formatter: function(value, context) {
          // context.chart.data.labels[context.dataIndex] + ': ' + 
          return (value).toFixed(2) + '%';
        }
      }
    }
  };

  public pieChartOptions2: ChartOptions = {
    responsive: true,
    rotation: (-0.5 * Math.PI) - (90/180 * Math.PI),
    legend: {
      display: false,
      position: 'right',
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          return data.labels[tooltipItem.index] + ': ' + Number(data.datasets[0].data[tooltipItem.index]).toFixed(2) + '%'
        }
      }
    },
    layout: {
      padding: {
        top: 70,
        right: 15,
        bottom: 25,
        left: 15
      }
    },
    plugins: {
      pluginDataLabels,
      pluginPiechartOutlabels,
      outlabels: {
        stretch: 17,
        text: '%l - %p',
        font: {
          resizable: true,
          minSize: 12,
          maxSize: 13
        }
      },
      datalabels: {
        color: 'white',
        anchor: 'end',
        align: 'end',
        display: function(context) {
          return false;
        },
        font: {
          weight: 'bold'
        },
        formatter: function(value, context) {
          // context.chart.data.labels[context.dataIndex] + ': ' + 
          return (value).toFixed(2) + '%';
        }
      }
    }
  };

  public pieChartOptions1: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right',
      labels: {
        fontColor: 'white'
      }
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      pluginDataLabels,
      datalabels: {
        color: 'white',
        display: function(context) {
          return true;
        },
        font: {
          weight: 'bold'
        },
        formatter: function(value, context) {
          return (value).toFixed(2) + '%';
        }
      }
    }
  };

  throttle = 1000;
  scrollDistance = 1;
  scrollUpDistance = 2;

  public pieChartLabels: Label[] = ['SciFi', 'Drama', 'Comedy', 'SciFi', 'Drama', 'Comedy', 'SciFi', 'Drama', 'Comedy', 'Comedy'];
  public pieChartData: SingleDataSet = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
  public pieChartLabels2: Label[] = [];
  public pieChartType: ChartType = 'pie';
  public pieStartPosition = 90;
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public barChartColors: Color[] = [
    {
      // backgroundColor: ['#1DBC8E', '#03A77B', '#039870', '#078966', '#077658', '#036C50', '#065F47', '#004533', '#01382A', '#002E22'],
      backgroundColor : ['#FF9016', '#F48000', '#FF6E03', '#FF610D', '#FC5000', '#FC3000', '#C42500', '#9F2205', '#811A02', '#6A1400']
    }
  ];

  // backgroundColors = ['#1DBC8E', '#03A77B', '#039870', '#078966', '#077658', '#036C50', '#065F47', '#004533', '#01382A', '#002E22'];
  backgroundColors= ['#FF9016', '#F48000', '#FF6E03', '#FF610D', '#FC5000', '#FC3000', '#C42500', '#9F2205', '#811A02', '#6A1400'];

  selectedTab = 'downloadTab';
  spinner = false;
  customerId;
  deletionReportList;
  reEnableReportList;
  downloadReportList;
  downloadChartReportList;
  downloadReportInfoList;
  downloadReportInfoSpinner = false;
  tacNo;
  brand;
  model;
  eid;
  dateRange = 'month';
  public selectedMoments = [
    moment(new Date()).subtract(14, 'day'),
    moment(new Date())
  ];
  infoEid;
  mailSpinner = false;
  downloadSpinner = false;
  features;
  brandDropdownSettings = {
    singleSelection: false,
    idField: 'brand',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'brand',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };

  modelDropdownSettings = {
    singleSelection: false,
    idField: 'model',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'model',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };

  brandList = [];
  modelList = [];
  deviceInfoList = [];
  selectedBrands = [];
  selectedModels = [];
  lastPage = 0;
  infiniteList;
  infiniteSpinner = false;
  
  reEnableDownloadLastPage = 0;
  downloadReEnableList;
  infinitedownloadReEnableList;
  reDownloadTotalPages;

  deletionDownloadLastPage = 0;
  downloadDeletionList;
  infinitedownloadDeletionList;
  deletionTotalPages;

  downloadLastPage = 0;
  downloadList;
  infinitedownloadList;
  downloadTotalPages;

  show = false;
  showChart = false;

  downloadSubscribe: Subscription;
  pieChartSubscribe: Subscription;
  deletionSubscribe: Subscription;
  reDownloadSubscribe: Subscription;

  devicedownloadSubscribe: Subscription;
  deletionDownloadSubscribe: Subscription;
  reDownloadDownloadSubscribe: Subscription;

  totalPages;

  timezones = timezones;
  selectedTimezone = 'Etc/UTC';
  user;
  timezoneSubscription: Subscription;
  language;
  disableButton = false;

  constructor(
    private reportService: ReportService,
    private communicationService: CommunicationService,
    private userStore: Store<UserState>,
    private translate: TranslateService,
    private userService: UserService) {
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      // const browserLang = translate.getBrowserLang();
      this.language = localStorage.getItem('language');
      if (!this.language) {
        this.language = 'en';
        localStorage.setItem('language', this.language);
      }
      translate.use(this.language.match(environment.langRegex) ? this.language : 'en');

      this.translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.modelDropdownSettings.selectAllText = res;
        this.brandDropdownSettings.selectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('Search').subscribe((res: string) => {
        this.show = false;
        this.modelDropdownSettings.searchPlaceholderText = res;
        this.brandDropdownSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });

      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.modelDropdownSettings.unSelectAllText = res;
        this.brandDropdownSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.modelDropdownSettings.noDataAvailablePlaceholderText = res;
        this.brandDropdownSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });
    }

  ngOnInit(): void {
    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.user = Object.assign({}, user);
          this.selectedTimezone = user.data.timezone;
          if(user.data.currentCustomer) {
            this.customerId = user.data.currentCustomer._id;
          } else {
            this.customerId = user.data.rootCustomer._id;
          }
          this.getReportData();

          if(user && user.featureRole && user.featureRole.length > 0) {
            this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Reports')].features;
          }
        }
      });

    this.getBrands();

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      })
  }

  changeLanguage() {
    this.language = localStorage.getItem('language');
    this.translate.use(this.language);

    this.translate.get('Select All').subscribe((res: string) => {
      this.show = false;
      this.modelDropdownSettings.selectAllText = res;
      this.brandDropdownSettings.selectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('Search').subscribe((res: string) => {
      this.show = false;
      this.modelDropdownSettings.searchPlaceholderText = res;
      this.brandDropdownSettings.searchPlaceholderText = res;
      this.refreshDropSettings();
    });

    this.translate.get('UnSelect All').subscribe((res: string) => {
      this.show = false;
      this.modelDropdownSettings.unSelectAllText = res;
      this.brandDropdownSettings.unSelectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('No data available').subscribe((res: string) => {
      this.show = false;
      this.modelDropdownSettings.noDataAvailablePlaceholderText = res;
      this.brandDropdownSettings.noDataAvailablePlaceholderText = res;
      this.refreshDropSettings();
    });
  }

  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  getBrands() {
    this.reportService.getBrands()
      .subscribe((res: any) => {
        this.brandList = res;

        let modelArray  = [];
        if(this.brandList && this.brandList.length > 0) {
          this.brandList.forEach(element => {
            element.models.forEach(ele => {
              modelArray.push({model: ele});
            });
          });
          this.modelList = modelArray;
        }
      });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  getReportData() {
    $('.multiselect-dropdown').css({cursor: 'auto', });
    $('.dropdown-btn').css({'pointer-events': 'auto', });

    this.lastPage = 0;

    let brands = [];
    let models = [];
    this.deletionReportList = [];
    this.reEnableReportList = [];
    this.downloadReportList = [];
    this.infiniteSpinner = false;

    for(let i = 0; i < this.selectedBrands.length; i++) {
      brands.push(this.selectedBrands[i].brand);
    }

    for(let i = 0; i < this.selectedModels.length; i++) {
      models.push(this.selectedModels[i].model);
    }

    if(this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    }

    if(this.pieChartSubscribe) {
      this.pieChartSubscribe.unsubscribe();
    }

    if(this.deletionSubscribe) {
      this.deletionSubscribe.unsubscribe();
    }

    if(this.reDownloadSubscribe) {
      this.reDownloadSubscribe.unsubscribe();
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
   
    const data = {
      tacNo: this.tacNo,
      brands,
      models,
      eid: this.eid,
      customerId: this.customerId,
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.spinner = true;
    this.disableButton = false;

    if(this.selectedTab==='downloadTab') {
      data['lastPageNo'] = this.lastPage;
      this.downloadSubscribe = this.reportService.getDownloadAnalyticsReport(data)
        .subscribe((res: any) => {
          this.spinner = false;
          this.infiniteList = res.result;
          this.downloadReportList = res.result;
          this.totalPages = res.totalPages;

          if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
            this.onDownloadScrollDown();
          }
        }, err => {
          this.spinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='deletionTab') {
      data['lastPageNo'] = this.lastPage;
      this.deletionSubscribe = this.reportService.getDeletionReport(data)
        .subscribe((res: any) => {
          this.spinner = false;
          this.infiniteList = res.result;
          this.deletionReportList = res.result;
          this.totalPages = res.totalPages;

          if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
            this.onDeletionScrollDown();
          }
        }, err => {
          this.spinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='reEnableTab') {
      data['lastPageNo'] = this.lastPage;
      this.reDownloadSubscribe = this.reportService.getReEnableReport(data)
        .subscribe((res: any) => {
          this.spinner = false;
          this.infiniteList = res.result;
          this.reEnableReportList = res.result;
          this.totalPages = res.totalPages;

          if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
            this.onReEnableScrollDown();
          }

        }, err => {
          this.spinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='chartTab') {
      this.tacNo = '';
      this.eid = '';
      this.showChart = false;
      this.pieChartSubscribe = this.reportService.getDownloadChartReport(data)
        .subscribe((res: any) => {
          this.spinner = false;
          this.downloadChartReportList = res;
          if(this.downloadChartReportList && this.downloadChartReportList.length <= 0) {
            this.disableButton = true;
          } else {
            this.disableButton = false;
          }
          this.pieChartData = [];
          this.pieChartLabels = [];
          this.pieChartLabels2 = [];

          this.pieChartOptions.rotation = this.downloadChartReportList.length == 1 ? (-0.5 * Math.PI) - (90/180 * Math.PI): (-0.5 * Math.PI);
          if(this.selectedBrands.length <= 0) {
            $('.device-model .multiselect-dropdown').css({cursor: 'not-allowed', });
            $('.device-model .dropdown-btn').css({'pointer-events': 'none', });
          } else {
            $('.device-model .multiselect-dropdown').css({cursor: 'auto', });
            $('.device-model .dropdown-btn').css({'pointer-events': 'auto', });
          }
          this.downloadChartReportList.forEach((element, i) => {
            this.pieChartData.push(element.pDownload);
            this.pieChartLabels.push(this.selectedModels.length > 0 ? element.model : element.brand);
            this.pieChartLabels2.push(this.selectedModels.length > 0 ? element.model + ' - ' + element.count : element.brand + ' - ' + element.count);
            element.selectedColor = this.backgroundColors[i];
          });
          this.showChart = true;
        }, err => {
          this.spinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    }
  }

  openEidInfo(index) {
    $('#eidInfoModal').modal();
    this.downloadReportInfoList = [];

    this.downloadReportInfoSpinner = true;
    this.infoEid = this.downloadReportList[index].eid;
    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    const data = {
      dateRange: this.dateRange,
      eid: this.infoEid,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.reportService.getDownloadAnalyticsInfoReport(data)
      .subscribe((res: any) => {
        this.downloadReportInfoSpinner = false;
        this.downloadReportInfoList = res;
      }, err => {
        this.downloadReportInfoSpinner = false;
        $('#eidInfoModal').modal('hide');
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  sendMailReport() {
    let brands = [];
    let models = [];

    for(let i = 0; i < this.selectedBrands.length; i++) {
      brands.push(this.selectedBrands[i].brand);
    }

    for(let i = 0; i < this.selectedModels.length; i++) {
      models.push(this.selectedModels[i].model);
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    const data = {
      tacNo: this.tacNo,
      brands,
      models,
      eid: this.eid,
      customerId: this.customerId,
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.mailSpinner = true;

    if(this.selectedTab==='downloadTab') {
      this.reportService.sendDeviceDownloadReportMail(data)
        .subscribe((res: any) => {
          this.mailSpinner = false;
          this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
        }, err => {
          this.mailSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='deletionTab') {
      this.reportService.sendDeviceDeleionReportMail(data)
        .subscribe((res: any) => {
          this.mailSpinner = false;
          this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
        }, err => {
          this.mailSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='reEnableTab') {
      this.reportService.sendDeviceReenableReportMail(data)
        .subscribe((res: any) => {
          this.mailSpinner = false;
          this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
        }, err => {
          this.mailSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='chartTab') {
       
      let data;
      if(this.downloadChartReportList && this.downloadChartReportList.length > 0) {
        data = {
          baseChartURL: this.getChartImage(),
        }
      }

        this.reportService.sendDeviceDownloadPieReportMail(data)
          .subscribe((res: any) => {
            this.mailSpinner = false;
            this.communicationService.openAlert({alertType: 'success', alertMsg: res.message});
          }, err => {
            this.mailSpinner = false;
            this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
          });
    }
  }

  downloadCsv() {
    let brands = [];
    let models = [];

    for(let i = 0; i < this.selectedBrands.length; i++) {
      brands.push(this.selectedBrands[i].brand);
    }

    for(let i = 0; i < this.selectedModels.length; i++) {
      models.push(this.selectedModels[i].model);
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    
    const data = {
      tacNo: this.tacNo,
      brands,
      models,
      eid: this.eid,
      customerId: this.customerId,
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    const tempList = [];
    let fileName;

    if(this.selectedTab==='downloadTab') {
      data['lastPageNo'] = this.deletionDownloadLastPage = 0;
      this.downloadSpinner = true;
      this.devicedownloadSubscribe = this.reportService.getDownloadAnalyticsReport(data)
        .subscribe((res: any) => {
          this.infinitedownloadList = res.result;
          this.downloadList = res.result;
          this.downloadTotalPages = res.totalPages;
          if(this.infinitedownloadList.length > 0 || this.deletionDownloadLastPage < this.downloadTotalPages) {
            this.getDownloadRecords();
            return;
          } else {
            
            this.downloadList.forEach(element => {
              const obj = {
                eid: element.eid,
                tacNo: element.tac,
                deviceType: element.type,
                brand: element.brand,
                model: element.model,
                downloaded: element.download,
                redownloaded: element.redownload,
                downloaded_on: element.timestamp
              };
              tempList.push(obj);
            });
            fileName = `Device Analytics Download Report.txt`;
            const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
            saveAs(blob, fileName);
            this.downloadSpinner = false;
          }
        }, err => {
          this.downloadSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else if(this.selectedTab==='deletionTab') {

      data['lastPageNo'] = this.deletionDownloadLastPage = 0;
      this.downloadSpinner = true;
      this.deletionDownloadSubscribe = this.reportService.getDeletionReport(data)
        .subscribe((res: any) => {
          this.infinitedownloadDeletionList = res.result;
          this.downloadDeletionList = res.result;
          this.deletionTotalPages = res.totalPages;
          if(this.infinitedownloadDeletionList.length > 0 || this.deletionDownloadLastPage < this.deletionTotalPages) {
            this.getDownloadDeletionRecords();
            return;
          } else {
            
            this.downloadDeletionList.forEach(element => {
              const obj = {
                deviceType: element.deviceType,
                brand: element.brand,
                model: element.model,
                eid: element.eid,
                iccid: element.iccid,
                tacNo: element.tacNo,
                deleted_on: element.deletionDate
              };
              tempList.push(obj);
            });
            fileName = `Device Analytics Deletion Report.txt`;
            const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
            saveAs(blob, fileName);
            this.downloadSpinner = false;
          }
        }, err => {
          this.downloadSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });

    } else if(this.selectedTab==='reEnableTab') {
      data['lastPageNo'] = this.reEnableDownloadLastPage = 0;
      this.downloadSpinner = true;
      this.reDownloadDownloadSubscribe = this.reportService.getReEnableReport(data)
        .subscribe((res: any) => {
          this.spinner = false;
          this.infinitedownloadReEnableList = res.result;
          this.downloadReEnableList = res.result;
          this.reDownloadTotalPages = res.totalPages;
          if(this.infinitedownloadReEnableList.length > 0 || this.reEnableDownloadLastPage < this.reDownloadTotalPages) {
            this.getDownloadReEnabelRecords();
            return;
          } else {
            
            this.downloadReEnableList.forEach(element => {
                const obj = {
                  deviceType: element.deviceType,
                  brand: element.brand,
                  model: element.model,
                  eid: element.eid,
                  iccid: element.iccid,
                  tacNo: element.tacNo,
                  status: element.status,
                  re_downloaded_on: element.timestamp
                };
                tempList.push(obj);
              });
            fileName = `Device Analytics Re-Download Report.txt`;
            const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
            saveAs(blob, fileName);
            this.downloadSpinner = false;
          }
        }, err => {
          this.downloadSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });

    } else if(this.selectedTab==='chartTab') {
      let dataURL = this.getChartImage();
      saveAs(dataURL, 'Device Analytics Pie Chart Report.png');
    }

    if(this.selectedTab!='chartTab' && !(this.selectedTab==='reEnableTab' || this.selectedTab==='deletionTab' || this.selectedTab==='downloadTab')) {
      const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
      saveAs(blob, fileName);
    }
  }

  getDownloadReEnabelRecords() {
    let brands = [];
    let models = [];

    for(let i = 0; i < this.selectedBrands.length; i++) {
      brands.push(this.selectedBrands[i].brand);
    }

    for(let i = 0; i < this.selectedModels.length; i++) {
      models.push(this.selectedModels[i].model);
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;
    
    const data = {
      tacNo: this.tacNo,
      brands,
      models,
      eid: this.eid,
      customerId: this.customerId,
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      lastPageNo: this.reEnableDownloadLastPage = this.reEnableDownloadLastPage + 1,
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.reDownloadDownloadSubscribe = this.reportService.getReEnableReport(data)
      .subscribe((res: any) => {
        this.spinner = false;
        this.infinitedownloadReEnableList = res.result;
        Array.prototype.push.apply(this.downloadReEnableList, res.result);

        if(this.infinitedownloadReEnableList.length > 0 || this.reEnableDownloadLastPage < this.reDownloadTotalPages) {
          this.getDownloadReEnabelRecords();
        } else {
          const tempList = [];
          let fileName;
          this.downloadReEnableList.forEach(element => {
              const obj = {
                deviceType: element.deviceType,
                brand: element.brand,
                model: element.model,
                eid: element.eid,
                iccid: element.iccid,
                tacNo: element.tacNo,
                status: element.status,
                re_downloaded_on: element.timestamp
              };
              tempList.push(obj);
            });
          fileName = `Device Analytics Re-Download Report.txt`;
          const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
          saveAs(blob, fileName);
          this.downloadSpinner = false;
        }
      }, err => {
        this.downloadSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getDownloadDeletionRecords() {
    let brands = [];
    let models = [];

    for(let i = 0; i < this.selectedBrands.length; i++) {
      brands.push(this.selectedBrands[i].brand);
    }

    for(let i = 0; i < this.selectedModels.length; i++) {
      models.push(this.selectedModels[i].model);
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    const data = {
      tacNo: this.tacNo,
      brands,
      models,
      eid: this.eid,
      customerId: this.customerId,
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      lastPageNo: this.deletionDownloadLastPage = this.deletionDownloadLastPage + 1,
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.deletionDownloadSubscribe = this.reportService.getDeletionReport(data)
      .subscribe((res: any) => {
        this.infinitedownloadDeletionList = res.result;
        Array.prototype.push.apply(this.downloadDeletionList, res.result);

        if(this.infinitedownloadDeletionList.length > 0 || this.deletionDownloadLastPage < this.deletionTotalPages) {
          this.getDownloadDeletionRecords();
        } else {
          const tempList = [];
          let fileName;
          this.downloadDeletionList.forEach(element => {
            const obj = {
              deviceType: element.deviceType,
              brand: element.brand,
              model: element.model,
              eid: element.eid,
              iccid: element.iccid,
              tacNo: element.tacNo,
              deleted_on: element.deletionDate
            };
            tempList.push(obj);
          });
          fileName = `Device Analytics Deletion Report.txt`;
          const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
          saveAs(blob, fileName);
          this.downloadSpinner = false;
        }
      }, err => {
        this.downloadSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getDownloadRecords() {
    let brands = [];
    let models = [];

    for(let i = 0; i < this.selectedBrands.length; i++) {
      brands.push(this.selectedBrands[i].brand);
    }

    for(let i = 0; i < this.selectedModels.length; i++) {
      models.push(this.selectedModels[i].model);
    }

    const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

    const data = {
      tacNo: this.tacNo,
      brands,
      models,
      eid: this.eid,
      customerId: this.customerId,
      dateRange: this.dateRange,
      from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      lastPageNo: this.downloadLastPage = this.downloadLastPage + 1,
      timezoneName,
      timezonekey: this.selectedTimezone
    };

    this.devicedownloadSubscribe = this.reportService.getDownloadAnalyticsReport(data)
      .subscribe((res: any) => {
        this.infinitedownloadList = res.result;
        Array.prototype.push.apply(this.downloadList, res.result);

        if(this.infinitedownloadList.length > 0 || this.downloadLastPage < this.downloadTotalPages) {
          this.getDownloadRecords();
        } else {
          const tempList = [];
          let fileName;
          this.downloadList.forEach(element => {
            const obj = {
              eid: element.eid,
              tacNo: element.tac,
              deviceType: element.type,
              brand: element.brand,
              model: element.model,
              downloaded: element.download,
              redownloaded: element.redownload,
              downloaded_on: element.timestamp
            };
            tempList.push(obj);
          });
          fileName = `Device Analytics Download Report.txt`;
          const blob = new Blob([papa.unparse(tempList)], { type: 'text/csv' });
          saveAs(blob, fileName);
          this.downloadSpinner = false;
        }
      }, err => {
        this.downloadSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getChartImage() {
    let canvas = document.getElementById('pieChart') as HTMLCanvasElement;
    let context =canvas.getContext("2d");
    let w = canvas.width;
    let h = canvas.height;
    let data1;
    data1 = context.getImageData(0, 0, w, h);
    let compositeOperation = context.globalCompositeOperation;
    context.globalCompositeOperation = "destination-over";
    context.fillStyle = 'black';
    context.fillRect(0,0,w,h);
    let url_base64jp = (document.getElementById("pieChart") as HTMLCanvasElement).toDataURL("image/png");
    context.clearRect (0,0,w,h);
    context.putImageData(data1, 0,0);		
    context.globalCompositeOperation = compositeOperation;

    return url_base64jp;
  }

  getModels() {
    let modelArray = [];
    this.selectedModels = [];
    
    if(this.selectedBrands && this.selectedBrands.length > 0) {
      this.selectedBrands.forEach((element: any) => {
        if(this.brandList.findIndex(ele => ele.brand === element.brand) !== -1) {
          this.brandList[this.brandList.findIndex(ele => ele.brand === element.brand)].models.forEach(model => {
            modelArray.push({model});
          });
        }
      });
    } else {
      if(this.brandList && this.brandList.length > 0) {
        this.brandList.forEach(element => {
          element.models.forEach(ele => {
            modelArray.push({model: ele});
          });
        });
      }
    }

    this.modelList = modelArray;
  }

  onReEnableScrollDown() { 

    if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
      let brands = [];
      let models = [];

      for(let i = 0; i < this.selectedBrands.length; i++) {
        brands.push(this.selectedBrands[i].brand);
      }

      for(let i = 0; i < this.selectedModels.length; i++) {
        models.push(this.selectedModels[i].model);
      }

      const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

      const data = {
        tacNo: this.tacNo,
        brands,
        models,
        eid: this.eid,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = this.lastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
      this.infiniteSpinner = true;

      this.reDownloadSubscribe = this.reportService.getReEnableReport(data)
        .subscribe((res: any) => {
          this.infiniteList = res.result;
          Array.prototype.push.apply(this.reEnableReportList, res.result);
          this.infiniteSpinner = false;
          if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
            this.onReEnableScrollDown();
          }
        }, err => {
          this.infiniteSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    }
    
  }

  onDeletionScrollDown() { 

    if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
      let brands = [];
      let models = [];

      for(let i = 0; i < this.selectedBrands.length; i++) {
        brands.push(this.selectedBrands[i].brand);
      }

      for(let i = 0; i < this.selectedModels.length; i++) {
        models.push(this.selectedModels[i].model);
      }

      const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

      const data = {
        tacNo: this.tacNo,
        brands,
        models,
        eid: this.eid,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = this.lastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
      
      this.infiniteSpinner = true;

      this.deletionSubscribe = this.reportService.getDeletionReport(data)
        .subscribe((res: any) => {
          this.infiniteList = res.result;
          Array.prototype.push.apply(this.deletionReportList, res.result);
          this.infiniteSpinner = false;

          if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
            this.onDeletionScrollDown();
          }
        }, err => {
          this.infiniteSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    }
    
  }

  onDownloadScrollDown() { 

    if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
      let brands = [];
      let models = [];

      for(let i = 0; i < this.selectedBrands.length; i++) {
        brands.push(this.selectedBrands[i].brand);
      }

      for(let i = 0; i < this.selectedModels.length; i++) {
        models.push(this.selectedModels[i].model);
      }

      const timezoneName = this.timezones[this.timezones.findIndex(e => e.timezonekey === this.selectedTimezone)].timezoneName;

      const data = {
        tacNo: this.tacNo,
        brands,
        models,
        eid: this.eid,
        customerId: this.customerId,
        dateRange: this.dateRange,
        from: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        to: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        lastPageNo: this.lastPage = this.lastPage + 1,
        timezoneName,
        timezonekey: this.selectedTimezone
      };
      
      this.infiniteSpinner = true;

      this.downloadSubscribe = this.reportService.getDownloadAnalyticsReport(data)
        .subscribe((res: any) => {
          this.infiniteList = res.result;
          Array.prototype.push.apply(this.downloadReportList, res.result);
          this.infiniteSpinner = false;

          if(this.infiniteList.length > 0 || this.lastPage < this.totalPages) {
            this.onDownloadScrollDown();
          }
        }, err => {
          this.infiniteSpinner = false;
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    }
    
  }

  setTimezone() {
    if(this.timezoneSubscription) {
      this.timezoneSubscription.unsubscribe();
    }

    this.timezoneSubscription = this.userService.setTimezone({timezone: this.selectedTimezone})
      .subscribe((res: any) => {
        this.user.data = Object.assign({...this.user.data}, {timezone: this.selectedTimezone});
        this.userStore.dispatch(new UserAction.GetUser(this.user));
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  ngOnDestroy() {
    if(this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    }

    if(this.pieChartSubscribe) {
      this.pieChartSubscribe.unsubscribe();
    }

    if(this.deletionSubscribe) {
      this.deletionSubscribe.unsubscribe();
    }

    if(this.reDownloadSubscribe) {
      this.reDownloadSubscribe.unsubscribe();
    }

    if(this.devicedownloadSubscribe) { 
      this.devicedownloadSubscribe.unsubscribe();
    }

    if(this.deletionDownloadSubscribe) {
      this.deletionDownloadSubscribe.unsubscribe();
    }

    if(this.reDownloadDownloadSubscribe) {
      this.reDownloadDownloadSubscribe.unsubscribe();
    }
  }
}