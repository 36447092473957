<div class="main-console-class" style="transform: translateY(22px);" *ngIf="flg && mainFeature && mainFeature.length > 0 && mainFeature[getMainFeatureIndex('Troubleshoot')].display">
    <div class="text-center">
        <!-- <img class="console-bottom-btn-img" style="transform: translateY(-22px);" src="assets/images/bottomToggleIcon.png" alt="" (click)="addHeight();"> -->
        <svg xmlns="http://www.w3.org/2000/svg"  style="transform: translateY(-22px);height: 20px;" (click)="addHeight();selectTab();" width="155.901" height="26.001" viewBox="0 0 155.901 26.001">
            <g id="Group_289" data-name="Group 289" transform="translate(-882.051 -1016)">
                <path id="Subtraction_4" data-name="Subtraction 4" d="M-14770.048-3912h-155.9a9.975,9.975,0,0,1,3.217-6.4A9.98,9.98,0,0,1-14916-3921h136a9.977,9.977,0,0,1,6.732,2.607,9.982,9.982,0,0,1,3.219,6.4Z" transform="translate(15808 4954)" fill="var(--t-font-color)"/>
                <path id="Polygon_21" data-name="Polygon 21" d="M20.533,3.283a4,4,0,0,1,5.935,0L40.96,19.318A4,4,0,0,1,37.993,26H9.007A4,4,0,0,1,6.04,19.318Z" transform="translate(937 1016)" fill="var(--t-font-color)"/>
                <g id="Group_288" data-name="Group 288" transform="translate(710.031 1382.621) rotate(-90)">
                    <rect id="Rectangle_7" data-name="Rectangle 7" width="12.151" height="2.278" rx="1.139" transform="translate(348.611 241.782) rotate(45)"/>
                    <rect id="Rectangle_9" data-name="Rectangle 9" width="12.151" height="2.278" rx="1.139" transform="translate(347 257.21) rotate(-45)"/>
                </g>
            </g>
        </svg>
    </div>
</div>

<div class="rectangle" id="rectangle" [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 50, right: 50 }" (resizeEnd)="onResizeEnd($event)">
    <div class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }">
        <div class="text-center">
            <!-- <img class="console-bottom-btn-img resize-handle-image" src="assets/images/bottomToggleIcon.png" alt=""> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="console-bottom-btn-img resize-handle-image" width="155.901" height="26.001" viewBox="0 0 155.901 26.001" (click)="selectTab()">
                <g id="Group_289" data-name="Group 289" transform="translate(-882.051 -1016)">
                    <path id="Subtraction_4" data-name="Subtraction 4" d="M-14770.048-3912h-155.9a9.975,9.975,0,0,1,3.217-6.4A9.98,9.98,0,0,1-14916-3921h136a9.977,9.977,0,0,1,6.732,2.607,9.982,9.982,0,0,1,3.219,6.4Z" transform="translate(15808 4954)" fill="var(--t-font-color)"/>
                    <path id="Polygon_21" data-name="Polygon 21" d="M20.533,3.283a4,4,0,0,1,5.935,0L40.96,19.318A4,4,0,0,1,37.993,26H9.007A4,4,0,0,1,6.04,19.318Z" transform="translate(937 1016)" fill="var(--t-font-color)"/>
                    <g id="Group_288" data-name="Group 288" transform="translate(710.031 1382.621) rotate(-90)">
                        <rect id="Rectangle_7" data-name="Rectangle 7" width="12.151" height="2.278" rx="1.139" transform="translate(348.611 241.782) rotate(45)"/>
                        <rect id="Rectangle_9" data-name="Rectangle 9" width="12.151" height="2.278" rx="1.139" transform="translate(347 257.21) rotate(-45)"/>
                    </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="resize-handle-inner resize-handle-scrollbar">
        <div class="mat-table-upper-head" style="background-color: var(--bg-sidebar) !important;">
            <div class="d-flex">
                <ul class="nav nav-pills mb-3 device-flex" id="pills-tab" role="tablist">
                    <li class="nav-item" (click)="chooseTab('notification')" style="width: 250px;" *ngIf="features && features[getFeatureIndex('troubleshoot_shoot')].view">
                        <a class="nav-link" id="pills-notification-tab" data-toggle="pill" href="#pills-notification" role="tab" aria-controls="pills-notification" aria-selected="true">
                            {{ "Notification" | translate}}
                        </a>
                    </li>
                    <li class="nav-item" style="width: 250px;" (click)="chooseTab('job')" *ngIf="features && features[getFeatureIndex('job_tab')].view">
                        <a class="nav-link" id="pills-job-tab" data-toggle="pill" href="#pills-job" role="tab" aria-controls="pills-job" aria-selected="false">
                            {{ "Jobs" | translate}}
                        </a>
                    </li>
                </ul>
                <button type="button" class="close mr-2 ml-auto" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close" (click)="closeTroubleShooting($event);flg=true;"> 
                    <span aria-hidden="true" class="t-color" style="font-weight: 100;font-size: 2.3rem;">&times;</span> 
                </button>
            </div>
            <div *ngIf="tab === 'notification' && features && features[getFeatureIndex('troubleshoot_shoot')].view">
                <div class="d-flex align-items-center mb-3">
                    <div class="filter-box-checkbox-container mr-4" style="font-size: 15px;">
                        <mat-checkbox style="margin-right: 60px;" [(ngModel)]="notificationCheck">HandleNotification</mat-checkbox>
                        <mat-checkbox [(ngModel)]="downloadCheck">HandleDownloadProgressInfo</mat-checkbox>
                    </div>
                    <button class="btn ml-auto" *ngIf="!notificationMode" (click)="notificationMode = !notificationMode; changeNotificationMode()"> 
                        <i class="fa fa-pause t-color mr-2" aria-hidden="true"></i>  
                        <span class="t-normal-color">{{'Pause' | translate}}</span> 
                    </button>
                    <button class="btn ml-auto" *ngIf="notificationMode" (click)="notificationMode = !notificationMode; changeNotificationMode()"> 
                        <i class="fa fa-play t-color mr-2" aria-hidden="true"></i>  
                        <span class="t-normal-color">{{'Play' | translate}}</span> 
                    </button>
                    <div class="ml-3 mr-4 console-search" *ngIf="features && features[getFeatureIndex('troubleshoot_shoot')].view">
                        <div class="search-inner-container d-flex align-items-center">
                            <input type="text" class="form-control c-input-serch border-light t-input" placeholder="{{'Filter by ICCID / IMEI / EID number' | translate}}" [(ngModel)]="searchTxt" (keypress)="isEnter($event)">
                            <button class="console-search-close t-color" (click)="searchTxt = ''; getLogs()"><i class="fa fa-close"></i></button>
                            <button class="btn c-btn d-flex align-items-center t-bgcolor outline" (click)="getLogs()"><i class="fa fa-search mr-2"></i> {{'Filter' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" id="pills-notification" role="tabpanel" aria-labelledby="pills-notification-tab"  *ngIf="features && features[getFeatureIndex('troubleshoot_shoot')].view">
                <div style="position: relative; top: 108px;">
                    <!-- <div class="d-flex align-items-center mb-3">
                        <div class="filter-box-checkbox-container mr-4" style="font-size: 15px;">
                            <mat-checkbox style="margin-right: 60px;" [(ngModel)]="notificationCheck">HandleNotification</mat-checkbox>
                            <mat-checkbox [(ngModel)]="downloadCheck">HandleDownloadProgressInfo</mat-checkbox>
                        </div>
                        <button class="btn ml-auto" *ngIf="!notificationMode" (click)="notificationMode = !notificationMode; changeNotificationMode()"> 
                            <i class="fa fa-pause t-color mr-2" aria-hidden="true"></i>  
                            <span class="t-normal-color">{{'Pause' | translate}}</span> 
                        </button>
                        <button class="btn ml-auto" *ngIf="notificationMode" (click)="notificationMode = !notificationMode; changeNotificationMode()"> 
                            <i class="fa fa-play t-color mr-2" aria-hidden="true"></i>  
                            <span class="t-normal-color">{{'Play' | translate}}</span> 
                        </button>
                        <div class="ml-3 mr-4 console-search" *ngIf="features && features[getFeatureIndex('troubleshoot_shoot')].view">
                            <div class="search-inner-container d-flex align-items-center">
                                <input type="text" class="form-control c-input-serch border-light t-input" placeholder="{{'Filter by ICCID / IMEI / EID number' | translate}}" [(ngModel)]="searchTxt" (keypress)="isEnter($event)">
                                <button class="console-search-close t-color" (click)="searchTxt = ''; getLogs()"><i class="fa fa-close"></i></button>
                                <button class="btn c-btn d-flex align-items-center t-bgcolor outline" (click)="getLogs()"><i class="fa fa-search mr-2"></i> {{'Filter' | translate}}</button>
                            </div>
                        </div>
                    </div> -->
                    <div class="console-table">
                        <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="font-size:11px !important" cdkDropListGroup cdkScrollable #matTableRef>
                            <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field">
                                <mat-header-cell class="text-capitalize" *matHeaderCellDef (mousedown)="onResizeColumn($event, i)">
                                    {{ i !== 4 ? column.field : ((notificationCheck && downloadCheck) || (!notificationCheck && !downloadCheck) ? 'NotificationEvent-NotificationPointId' : (notificationCheck ? 'NotificationEvent' : 'NotificationPointId'))}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{ row[column.field] }} </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]='hideRow(row.notificationType)'></mat-row>
                        </mat-table>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-job" role="tabpanel" aria-labelledby="pills-job-tab" *ngIf="features && features[getFeatureIndex('job_tab')].view">
                <div style="position: relative; top: 45px;">
                    <div class="job-table p-2">
                        <div class="t-grid-table-new">
                            <div class="t-grid-table-wrapper-jobs" [ngClass]="{'t-grid-table-wrapper-admin': isAdmin, 't-grid-table-wrapper': !isAdmin}">
                                <div class="t-box a">{{'Job Name' | translate}}</div>
                                <div class="t-box b" *ngIf="isAdmin">{{'User' | translate}}</div>
                                <div class="t-box c">{{'Job Scheduled Time' | translate}}</div>
                                <div class="t-box c">{{'Job Start Time' | translate}}</div>
                                <div class="t-box a">{{'Job Estimated End Time' | translate}}</div>
                                <div class="t-box b">{{'% of completion' | translate}}</div>
                                <div class="t-box b">{{'Remaining time' | translate}}</div>
                                <div class="t-box b">{{'Status' | translate}}</div>
                                <div class="t-box b">{{'Action' | translate}}</div>
                            </div>
                            <!-- <div class="loader-flex-center" *ngIf="reportSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div class="t-body-overflow" *ngIf="goverenceReportList && goverenceReportList.length <= 0 && !reportSpinner">
                                <div class="p-1 t-body-bg cursor-p text-center">
                                    {{'No Data Found' | translate}}.
                                </div>
                            </div> -->
                            <div class="t-body-overflow table-height">
                                <div class="t-body-bg cursor-p" *ngFor="let job of bulkJobList; let i = index;"  [ngClass]="{'t-grid-table-wrapper-admin': isAdmin, 't-grid-table-wrapper': !isAdmin}">
                                    <div class="t-box d t-font-light">{{ job.jobType === 'PREPARE_M' ? 'Prepare' :  job.jobType === 'QRCODE_M' ? 'Qrcode' : job.jobType === 'RESET_M' ? 'Reset' : job.jobType === 'PRP' ? 'Prp' :  job.jobType }}</div>
                                    <div class="t-box d t-font-light" *ngIf="isAdmin">{{ job.user }}</div>
                                    <div class="t-box d t-font-light">{{ job.created }}</div>
                                    <div class="t-box d t-font-light">{{ job.jobStartTime }}</div>
                                    <div class="t-box d t-font-light">{{ job.jobEndTime }}</div>
                                    <div class="t-box d t-font-light">{{ job.jobCompletionPercentage | number:'1.0-2'}}%</div>
                                    <div class="t-box d t-font-light">{{ job.remainingTime === 'NA' ? 'NA' : job.remainingTime + 'm' }}</div>
                                    <div class="t-box d t-font-light">{{ job.status === 'Pending' ? 'In Queue' : job.status === 'Completed' ? 'Successful' : job.status === 'FAILED' ? 'Failed' : job.status}}</div>
                                    <div class="t-box d t-font-light">
                                        <div class="bulc-job-actions custom-tooltip custom-tooltip-bulk-job" style="display: flex;
                                        align-items: center;">
                                            <i class="fa fa-times-circle t-color cursor-p mr-2" [ngClass]="{'pointer-disable': job.status != 'Pending' && job.status != 'Pause' && job.status != 'Running' }" [tooltipAnimation]="true" placement="top" (click)="cancelQueue({loggerHeadId: job._id, index: i});" tooltip="Cancel the bulk job"></i>
                                            <i class="fa fa-play t-color cursor-p mr-2" tooltip="Resume the bulk job" [ngClass]="{'pointer-disable': job.status === 'Completed' || job.status === 'FAILED' }" *ngIf="job.status === 'Pause'" (click)="playandpauseQueue({loggerHeadId: job._id, index: i, status: job.status});" [tooltipAnimation]="true" placement="top"></i>
                                            <i class="fa fa-pause t-color cursor-p mr-2" tooltip="Pause the bulk job" [ngClass]="{'pointer-disable': job.status === 'Completed' || job.status === 'FAILED' }" *ngIf="job.status === 'Pending' || job.status === 'Running'" (click)="playandpauseQueue({loggerHeadId: job._id, index: i, status: job.status});" [tooltipAnimation]="true" placement="top"></i>
                                            <i class="fa fa-envelope-o t-color cursor-p" tooltip="Resend the link" (click)="generateLink(job.jobUID)" [tooltipAnimation]="true" placement="top" *ngIf="job.status === 'Completed'"></i>
                                            <i data-toggle="modal" data-target="#infoErrorModal" class="c-color c-info py-0 fa fa-info icon-count-class" (click)="remark=job.remarks" style="cursor: pointer;" *ngIf="job.status === 'FAILED'"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="infoErrorModal" tabindex="-1" role="dialog" aria-labelledby="infoErrorModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      
        <div class="modal-content t-modal">
          <div class="text-center mr-3">
            <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
            <div class="modal-body p-0 pl-3 device-flex-body">
                <div class="text-center">
                    <svg id="problem" xmlns="http://www.w3.org/2000/svg" class="svg-t-color" style="margin-bottom: 30px" width="80.148" height="70.756" viewBox="0 0 80.148 70.756">
                        <path id="Path_66" data-name="Path 66" d="M77.665,45.628,54.028,7.746a16.441,16.441,0,0,0-27.907,0L2.483,45.628A16.44,16.44,0,0,0,16.437,70.756H63.712A16.44,16.44,0,0,0,77.665,45.628ZM63.712,66.06H16.437A11.743,11.743,0,0,1,6.468,48.115L30.105,10.232a11.744,11.744,0,0,1,19.938,0L73.681,48.115A11.743,11.743,0,0,1,63.712,66.06Zm0,0" transform="translate(0 0)"/>
                        <path id="Path_67" data-name="Path 67" d="M96.667,63.326a7.049,7.049,0,0,0-11.97,0L61.06,101.208A6.919,6.919,0,0,0,60,104.927a7.052,7.052,0,0,0,7.044,7.044h47.275a7.052,7.052,0,0,0,7.044-7.044,6.925,6.925,0,0,0-1.059-3.719Zm17.652,43.949H67.044a2.351,2.351,0,0,1-2.348-2.348,2.267,2.267,0,0,1,.347-1.233L88.681,65.812a2.352,2.352,0,0,1,4,0l23.638,37.882a2.264,2.264,0,0,1,.347,1.233A2.351,2.351,0,0,1,114.319,107.275Zm0,0" transform="translate(-50.608 -50.608)"/>
                        <path id="Path_68" data-name="Path 68" d="M241,165h4.7v16.593H241Zm0,0" transform="translate(-203.274 -139.171)"/>
                        <path id="Path_69" data-name="Path 69" d="M241,301h4.7v4.7H241Zm0,0" transform="translate(-203.274 -253.881)"/>
                    </svg>
                    <p>{{remark}}</p>
                </div>
              
            </div>
        </div>
    </div>
  </div>