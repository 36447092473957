import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { ShapeType } from '../../model/shape-types';
import { MousePosition, Rectangle } from '../../model/shape';

@Component({
  selector: 'app-rounded-ractangle',
  templateUrl: './rounded-ractangle.component.html',
  styleUrls: ['./rounded-ractangle.component.scss']
})
export class RoundedRactangleComponent extends ShapeComponent implements OnInit {

  formFields = [
    {
        name: 'x',
        label: 'X:',
        type: 'input',
        inputType: 'text',
        value: ''
    },
    {
        name: 'y',
        label: 'Y:',
        type: 'input',
        inputType: 'text',
        value: ''
    },
];

constructor() {
    super();
    // console.log('RectangleComponent constructor');
    this.shape = new Rectangle();
    this.shapeType = ShapeType.Rectangle;
}

ngOnInit() {
    // console.log('RectangleComponent ngOnInit');
}

setStyles() {
    const styles = {
        stroke: this.shape.shapeProperties.strokeColor,
        fill: this.shape.shapeProperties.fillColor,
        'stroke-width': this.shape.shapeProperties.strokeWidth
    };
    return styles;
}

startDrawing(beginPosition: MousePosition): void {
    // console.log('RectanleComponent startDrawing at ', beginPosition);
    if (this.shape instanceof Rectangle) {
        this.shape.originX = beginPosition.x;
        this.shape.originY = beginPosition.y;
    }
}

draw(currentPosition: MousePosition): void {
    // console.log('RectangleComponent draw');
    if (this.shape instanceof Rectangle) {
        this.shape.width = Math.abs(currentPosition.x - this.shape.originX);
        this.shape.height = Math.abs(currentPosition.y - this.shape.originY);
    }
}
}
