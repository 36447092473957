import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/services/dashboard.service';

@Component({
  selector: 'app-dashboard-refresh-time',
  templateUrl: './dashboard-refresh-time.component.html',
  styleUrls: ['./dashboard-refresh-time.component.scss']
})
export class DashboardRefreshTimeComponent implements OnInit {

  widgetRefreshTimeSetting = [
    { name: 'Profile Inventory', time: 60000},
    { name: 'eSIM installed on Device', time: 60000},
    { name: 'Device Analytics', time: 60000},
    { name: 'Trend Graph', time: 60000},
    { name: 'Forecast Graph', time: 60000}
  ];

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getWidgetRefreshTime();
  }

  getWidgetRefreshTime() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        console.log(res);
        if (res.refreshTimeSetting.length > 0) {
          this.widgetRefreshTimeSetting = res.refreshTimeSetting;
        }
      }, err => {
        console.log(err);
      });
  }

  setWidgetRefreshTime() {
    this.dashboardService.setWidgetRefreshTime({refreshTimeSetting: this.widgetRefreshTimeSetting})
      .subscribe((res: any) => {
        console.log(res);
      }, err => {
        console.log(err);
      });
  }

}
