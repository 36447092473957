import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profiletype-detail',
  templateUrl: './profiletype-detail.component.html',
  styleUrls: ['./profiletype-detail.component.scss']
})
export class ProfiletypeDetailComponent implements OnInit {

    profileTypeName;
    profileTypeNameErr = false;
    saveBtnSpinner = false;
    statusArray = [
        { checked: false, key: 'Linksfield_3HK_T01' },
        { checked: false, key: 'Linksfield_HKCU_T01' },
        { checked: false, key: 'Linksfield_MANXVDF_T01' },
        { checked: false, key: 'Linksfield_MFM_T01' },
        { checked: false, key: 'Linksfield_MANX_T01' },
        { checked: false, key: '10T_Linksfields_KPN_T01' },
        { checked: false, key: '10T_Linksfields_3HK_T01' },
        { checked: false, key: '10T_Linksfield_SFR_T01' },
        { checked: false, key: '10T_Linksfields_MANX_T01' },
        { checked: false, key: '10T_Linksfield_SFR_T01' },
        { checked: false, key: '10T_Linksfield_T01' },
        { checked: false, key: '10T_Linksfields_PARTNER_T01' }
      ];

    constructor() { }

    ngOnInit() {
    }

    isSpace(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode === 32) {
            return false;
        }

        return true;
    }

}
