import * as DashboardAction from './dashboard.action';
import { Dashboard } from './dashboard.model';

export interface DashboardState {
     dashboard: Dashboard;
}

export function DashboardReducer(state: Dashboard = {}, action: DashboardAction.DashboardActions) {
    switch (action.type) {
        case DashboardAction.GET_SIDEBAR:
            return action.payload;
            
        case DashboardAction.ADD_WIDGET:
            return action.payload;

        case DashboardAction.GET_WIDGETS:
            return action.payload;

        case DashboardAction.REMOVE_WIDGET:
            return action.payload;

        case DashboardAction.SAVE_TYPE:
            return action.payload;
            
        default: return state;
    }
}