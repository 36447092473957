import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiTreeComponent } from './api-tree.component';

const routes: Routes = [
    {
        path: '', component: ApiTreeComponent,
    },
    {
        path: ':id',
        component: ApiTreeComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ApiTreeRoutingModule { }
