import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/services/notification.service';
import { UserState } from '../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
import { AppComponent } from '../app.component';
import { CommunicationService } from '../shared/services/communication.service';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  notificationList = [];
  socket;
  notiSpinner = true;

  constructor(
    private notificationService: NotificationService,
    private userStore: Store<UserState>,
    private appComponent: AppComponent,
    private communicationService: CommunicationService,
    private cookieTrailService: CookieTrailService,
    private translate: TranslateService) {

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      // const browserLang = translate.getBrowserLang();
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});
    this.socket = this.appComponent.socket;

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.notificationService.getNotifications()
      .subscribe((res: any) => {
        this.notiSpinner = false;
        this.notificationList = res;
        this.notificationList.forEach((element: any) => {
          if(element.message.includes('PRP Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('PRP Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('Campaign Generation Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('Reset Profile Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('Reset Profile Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('Prepare Profile Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('QR Code Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('QR Code Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('QRCode - ')) {
            let msg1 = element.message.split('QRCode');
            let msg2 = msg1[1].split('has been mailed to');
            let qrCode;
            let mailTo;
            this.translate.get('QRCode')
            .subscribe((res: any) => {
              qrCode = res;
              element.transMessage = qrCode + msg2[0];
            });
          
            this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage += mailTo + ' ' + msg2[1];
              });
          } else if(element.message.includes('Prepare - ')) {
            let msg1 = element.message.split('Prepare');
            let msg2 = msg1[1].split('has been mailed to');
            let qrCode;
            let mailTo;
            this.translate.get('Prepare')
              .subscribe((res: any) => {
                qrCode = res;
                element.transMessage = qrCode + msg2[0];
              });
          
            this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage += mailTo + ' ' + msg2[1];
              });
          } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('QR Code Generation Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else {
            element.transMessage = element.message;
          }
        });
      }, err => {
        const cookieTrail = {
          screenName: 'Notification',
          action: 'Get Notifications',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.notiSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    this.userStore.select('user')
      .subscribe((user: any) => {
        if (user) {
          this.socket.emit('csspuser', user.data._id);
          this.socket.on('bellnotification', (notiData) => {
            this.notificationList.unshift(notiData);
          });
        }
      });
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);

    this.notificationList.forEach((element: any) => {
      if(element.message.includes('PRP Report has been mailed to')) {
        let message = element.message.split('to');
        this.translate.get('PRP Report has been mailed to')
          .subscribe((res: any) => {
            element.transMessage = res + message[1];
          });
      } else 
      if(element.message.includes('Campaign Generation Report has been mailed to')) {
        let message = element.message.split(' to ');
        this.translate.get('Campaign Generation Report has been mailed to')
          .subscribe((res: any) => {
            element.transMessage = res + ' ' + message[1];
          });
      } else if(element.message.includes('Reset Profile Report has been mailed to')) {
        let message = element.message.split(' to ');
        this.translate.get('Reset Profile Report has been mailed to')
          .subscribe((res: any) => {
            element.transMessage = res + ' ' + message[1];
          });
      } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
        let message = element.message.split(' to ');
        this.translate.get('Prepare Profile Report has been mailed to')
          .subscribe((res: any) => {
            element.transMessage = res + ' ' + message[1];
          });
      } else if(element.message.includes('QR Code Report has been mailed to')) {
        let message = element.message.split(' to ');
        this.translate.get('QR Code Report has been mailed to')
          .subscribe((res: any) => {
            element.transMessage = res + ' ' + message[1];
          });
      } else if(element.message.includes('QRCode - ')) {
        let msg1 = element.message.split('QRCode');
        let msg2 = msg1[1].split('has been mailed to');
        let qrCode;
        let mailTo;
        this.translate.get('QRCode')
          .subscribe((res: any) => {
            qrCode = res;
            element.transMessage = qrCode + msg2[0];
          });
          
        this.translate.get('has been mailed to')
          .subscribe((res: any) => {
            mailTo = res;
            element.transMessage += mailTo + ' ' + msg2[1];
          });
      } else if(element.message.includes('Prepare - ')) {
        let msg1 = element.message.split('Prepare');
        let msg2 = msg1[1].split('has been mailed to');
        let qrCode;
        let mailTo;
        this.translate.get('Prepare')
          .subscribe((res: any) => {
            qrCode = res;
            element.transMessage = qrCode + msg2[0];
          });
          
        this.translate.get('has been mailed to')
          .subscribe((res: any) => {
            mailTo = res;
            element.transMessage += mailTo + ' ' + msg2[1];
          });
      } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
        let message = element.message.split(' to ');
        this.translate.get('QR Code Generation Report has been mailed to')
          .subscribe((res: any) => {
            element.transMessage = res + ' ' + message[1];
          });
      } else {
        element.transMessage = element.message;
      }
    });
  }

}
