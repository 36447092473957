import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { AppComponent } from '../app.component';
import { CommunicationService } from '../shared/services/communication.service';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { DashboardService } from '../shared/services/dashboard.service';
import { UserService } from '../shared/services/user.service';
import { UserState } from '../shared/stores/user/user.reducer';
import * as _moment from 'moment';
declare var $: any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrls: ['./dashboard-user.component.scss']
})
export class DashboardUserComponent implements OnInit {
 
  userDashboardData;
  userDataSpinner = true;
  socket;
  features;
  user;
  downloadData;
  filterBy = 'today';
  public lineChartData: ChartDataSets[];
  public lineChartLabels: Label[];
  public lineChartColors: Color[] = [
    {
      borderColor: '#47e1af',
      backgroundColor: 'transparent',
    },
  ];
  public lineChartLegend = true;
  public lineChartPlugins = [];
  public lineChartType = 'line';
  public lineChartOptions = {
    scales: {
      xAxes: [{
        gridLines: { color: '#5A5A5A', borderDash: [6] },
      }],
      yAxes: [{
        gridLines: { color: '#5A5A5A', borderDash: [6] },
      }]
    }
  };
  startDate = new Date(moment(new Date()).subtract(60, 'days'));
  endDate = new Date();
  custStart;
  custEnd;
  refreshSpinner = false;
  groupQrData;
  groupBy = 'brand';
  dateRange = 'today';
  customerId;
  deviceAnalyticsList;
  deviceAnalyticsListSpinner = false;
  deviceInfoList;
  deviceInfoListSpinner = false;
  formatter;
  deviceAnalyticsProfileReDownload;
  deviceAnalyticsEidReDownload;
  limitLength = 30;
  limitProfileEid = 25;

  constructor(
    private dashboardService: DashboardService,
    private communicationService: CommunicationService,
    private appComponent: AppComponent,
    private userStore: Store<UserState>,
    private cookieTrailService: CookieTrailService,
    private translate: TranslateService,
    private userService: UserService) { }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});
    this.socket = this.appComponent.socket;

    this.formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,      
      maximumFractionDigits: 2,
    });

    localStorage.setItem('cookieTrail', JSON.stringify({}));
    
    this.userStore.select('user')
    .subscribe((user: any) => {
      if(user) {
        this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'dashboard-user')].features;
        this.getUserDashboardData();

        if(user.data.currentCustomer) {
          this.customerId = user.data.currentCustomer._id;
        } else {
          this.customerId = user.data.rootCustomer._id;
        }

        this.getDeviceAnalytics();

        this.socket.emit('csspuser', user.data._id);
        this.socket.on('bellnotification', (notiData) => {
         this.userDashboardData.notification.unshift(notiData);
        });
      }
    });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.setDefaults();
  }

  getDeviceAnalytics() {
    this.deviceAnalyticsList = [];
    this.deviceAnalyticsProfileReDownload = [];
    this.deviceAnalyticsEidReDownload = [];
    
    const data = {
      customerId: this.customerId,
      groupBy: this.groupBy,
      dateRange: this.dateRange
    };

    this.deviceAnalyticsListSpinner = true;

    this.dashboardService.getDeviceAnalytics(data)
      .subscribe((res: any) => {
        this.deviceAnalyticsListSpinner = false;
        this.deviceAnalyticsList = res;
        this.deviceAnalyticsList.forEach(element => {
          element.percentDownload = this.formatter.format(element.percentDownload);
        });

        this.deviceAnalyticsProfileReDownload = Object.assign([], this.deviceAnalyticsList);
        this.deviceAnalyticsEidReDownload = Object.assign([], this.deviceAnalyticsList);

        this.deviceAnalyticsList.sort((a,b) => (a.totalDownload < b.totalDownload) ? 1 : ((b.totalDownload < a.totalDownload) ? -1 : 0));
        this.deviceAnalyticsProfileReDownload.sort((a,b) => (a.iccidRedownload < b.iccidRedownload) ? 1 : ((b.iccidRedownload < a.iccidRedownload) ? -1 : 0));
        this.deviceAnalyticsEidReDownload.sort((a,b) => (a.eidRedownload < b.eidRedownload) ? 1 : ((b.eidRedownload < a.eidRedownload) ? -1 : 0))
        
        if(this.groupBy === 'brand') {
          if(this.deviceAnalyticsList.findIndex(ele => ele.brand === 'others') !== -1) {
            const ele = this.deviceAnalyticsList.splice(this.deviceAnalyticsList.findIndex(ele => ele.brand === 'others'), 1);
            this.deviceAnalyticsList.push(ele[0]);
          }

          if(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.brand === 'others') !== -1) {
            const ele = this.deviceAnalyticsProfileReDownload.splice(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.brand === 'others'), 1);
            this.deviceAnalyticsProfileReDownload.push(ele[0]);
          }

          if(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.brand === 'others') !== -1) {
            const ele = this.deviceAnalyticsEidReDownload.splice(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.brand === 'others'), 1);
            this.deviceAnalyticsEidReDownload.push(ele[0]);
          }
        } else if(this.groupBy === 'model') {
          if(this.deviceAnalyticsList.findIndex(ele => ele.model === 'others') !== -1) {
            const ele = this.deviceAnalyticsList.splice(this.deviceAnalyticsList.findIndex(ele => ele.model === 'others'), 1);
            this.deviceAnalyticsList.push(ele[0]);
          }

          if(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.model === 'others') !== -1) {
            const ele = this.deviceAnalyticsProfileReDownload.splice(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.model === 'others'), 1);
            this.deviceAnalyticsProfileReDownload.push(ele[0]);
          }

          if(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.model === 'others') !== -1) {
            const ele = this.deviceAnalyticsEidReDownload.splice(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.model === 'others'), 1);
            this.deviceAnalyticsEidReDownload.push(ele[0]);
          }
        } else if(this.groupBy === 'group') {
          if(this.deviceAnalyticsList.findIndex(ele => ele.group === 'others') !== -1) {
            const ele = this.deviceAnalyticsList.splice(this.deviceAnalyticsList.findIndex(ele => ele.group === 'others'), 1);
            this.deviceAnalyticsList.push(ele[0]);
          }

          if(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.group === 'others') !== -1) {
            const ele = this.deviceAnalyticsProfileReDownload.splice(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.group === 'others'), 1);
            this.deviceAnalyticsProfileReDownload.push(ele[0]);
          }

          if(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.group === 'others') !== -1) {
            const ele = this.deviceAnalyticsEidReDownload.splice(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.group === 'others'), 1);
            this.deviceAnalyticsEidReDownload.push(ele[0]);
          }
        }

        if(window.innerWidth <= 1366) {
          this.limitLength = 11;
          this.limitProfileEid = 10;
        } else if(window.innerWidth <= 1440) {
          this.limitLength = 15;
          this.limitProfileEid = 13;
        } if(window.innerWidth <= 1330) {
          this.limitLength = 9;
          this.limitProfileEid = 9;
        }

      }, err => {
        this.deviceAnalyticsListSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
    if(this.userDashboardData && this.userDashboardData.notification) {

      this.userDashboardData.notification.forEach((element: any) => {
        if(element.message.includes('Campaign Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Campaign Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Reset Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Reset Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Prepare Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QR Code Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QRCode - ')) {
          let msg1 = element.message.split('QRCode');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;
          
          this.translate.get('QRCode')
            .subscribe((res: any) => {
              qrCode = res;
              element.transMessage = qrCode + msg2[0];
            });
          
          this.translate.get('has been mailed to')
            .subscribe((res: any) => {
              mailTo = res;
              element.transMessage += mailTo + msg2[1];
            });
        } else if(element.message.includes('Prepare - ')) {
          let msg1 = element.message.split('Prepare');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;

          this.translate.get('Prepare')
            .subscribe((res: any) => {
              qrCode = res;
              element.transMessage = qrCode + msg2[0];
            });
          
          this.translate.get('has been mailed to')
            .subscribe((res: any) => {
              mailTo = res;
              element.transMessage += mailTo + msg2[1];
            });
        } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else {
          element.transMessage = element.message;
        }
      });
    }
  }

  getUserDashboardData() {
    this.getInventoryData();
    this.dashboardService.getGroupQR().subscribe((res: any) => {
      this.groupQrData = res;
      this.userDataSpinner = false;
    }, err => {
      const cookieTrail = {
        screenName: 'User Dashboard',
        action: 'Get Group QR',
        error: err.error || 'Something went wrong!',
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);
      
      this.userDataSpinner = false;
      this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
    });

    this.userService.getUser().subscribe((res: any) => {
      this.user = res;
    }, err => {
      const cookieTrail = {
        screenName: 'User Dashboard',
        action: 'Get User',
        error: err.error || 'Something went wrong!',
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);
      this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    });

    this.dashboardService.getTotalDownload().subscribe((res: any) => {
      this.downloadData = res;
      this.userDataSpinner = false;
    }, err => {
      const cookieTrail = {
        screenName: 'User Dashboard',
        action: 'Get Total Download',
        error: err.error || 'Something went wrong!',
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);
      
      this.userDataSpinner = false;
      this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
    });
    this.setDefaults()
  }

  totalDownloadData(data){
    this.dashboardService.getTotalDownloadGraphData(data).subscribe((res: any) => {
      this.assignToGraph(res);
    })
  }

  assignToGraph(graphData) {
    const lables = [];
    const data = [];
    if (graphData && graphData.length > 0) {      
      graphData.forEach((element) => {
        data.push(element.count);
        lables.push(`${element.label}`);
      });
  
      this.lineChartLabels = lables;
  
      this.lineChartData = [
        { data, label: 'Profiles', borderWidth: 2 },
      ];
    }
  }

  setDefaults() {
    this.startDate = new Date();
    this.endDate = new Date();
    let data = {
      keyword: 'Date',
    };

    switch (this.filterBy) {
      case 'today':
        data.keyword = 'Date';
        this.custStart = moment(this.startDate).format('YYYY/MM/DD');
        this.custEnd = moment(this.startDate).format('YYYY/MM/DD');
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        break;
      case 'yesterday':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate()-1);
        this.custStart = moment(this.startDate).format('YYYY/MM/DD');
        this.custEnd = moment(new Date()).format('YYYY/MM/DD');
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        data.keyword = 'Date';
        break;

      case '7days':
          this.startDate = new Date();
          this.startDate.setDate(this.startDate.getDate()-6);
          this.custStart = moment(this.startDate).format('YYYY/MM/DD');
          this.custEnd = moment(new Date()).format('YYYY/MM/DD');
          data['fromDate'] = this.custStart;
          data['toDate'] = this.custEnd;
          data.keyword = 'Date';
        break;
      case '1month':
        this.startDate = new Date();
        this.startDate.setDate(1);
          this.custStart = moment().subtract(1, 'months').date(1).format('YYYY/MM/DD');
          this.custEnd = moment().subtract(1, 'months').date(1).endOf('month').format('YYYY/MM/DD');
          data['fromDate'] = this.custStart;
          data['toDate'] = this.custEnd;
          data.keyword = 'Date';       
        break;
      case '1Year':
          this.startDate = new Date();
          this.startDate.setDate(1);
          this.startDate.setMonth(0);
          this.custStart = moment(this.startDate).format('MM/YYYY');
          this.custEnd = moment(new Date()).format('MM/YYYY');
          data.keyword = 'Month';
          data['fromDate'] = this.custStart;
          data['toDate'] = this.custEnd;
          break;
      case '6months':
          this.startDate = new Date();
          this.startDate.setDate(1);
          this.startDate.setMonth(this.startDate.getMonth() - 5);
          this.custStart = moment(this.startDate).format('MM/YYYY');
          this.custEnd = moment(new Date()).format('MM/YYYY');
          data['fromDate'] = this.custStart;
          data['toDate'] = this.custEnd;
          data.keyword = 'Month';
        break;
      default:
        return;
    }
    this.totalDownloadData(data);
  }

  getInventoryData() {
    this.dashboardService.getDashboardData().subscribe((res: any) => {
      this.userDashboardData = res;
      this.userDataSpinner = false;
      this.refreshSpinner = false;
      
      res.notification.forEach((element: any) => {
        if(element.message.includes('PRP Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('PRP Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Campaign Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Reset Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Reset Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Prepare Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QR Code Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QRCode - ')) {
          let msg1 = element.message.split('QRCode');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;
          this.translate.get('QRCode')
            .subscribe((res: any) => {
              qrCode = res;
              this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage = qrCode + msg2[0] + mailTo + msg2[1];
              });
            });
        } else if(element.message.includes('Prepare - ')) {
          let msg1 = element.message.split('Prepare');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;
          this.translate.get('Prepare')
            .subscribe((res: any) => {
              qrCode = res;
              this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage = qrCode + msg2[0] + mailTo + msg2[1];
              });
            });
        } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else {
          element.transMessage = element.message;
        }
      });
    }, err => {
      const cookieTrail = {
        screenName: 'User Dashboard',
        action: 'Get Dashboard Data',
        error: err.error || 'Something went wrong!',
      };
      
      this.cookieTrailService.saveCookie(cookieTrail);
      
      this.userDataSpinner = false;
      this.refreshSpinner = false;
      this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
    });
  }

  openTacInfo(index) {
    
    $('#tacInfoModal').modal();
    this.deviceInfoList = [];
    let data;

    if(this.deviceAnalyticsList[index].brand === 'others'
      || this.deviceAnalyticsList[index].group === 'others'
      || this.deviceAnalyticsList[index].model === 'others' ) {
        
      let notIn = [];

      this.deviceAnalyticsList.forEach(element => {
        if(this.groupBy === 'brand') {
          notIn.push(element.brand);
        } else if(this.groupBy === 'group') {
          notIn.push(element.groupuid);
        } else if(this.groupBy === 'model') {
          notIn.push(element.model);
        }
      });

      data = {
        customerId: this.customerId,
        groupBy: this.groupBy,
        dateRange: this.dateRange,
        notIn
      }

    } else {
      data = {
        customerId: this.customerId,
        groupBy: this.groupBy,
        dateRange: this.dateRange,
        notIn: this.groupBy === 'brand' ? [this.deviceAnalyticsList[index].brand] : this.groupBy === 'group' ? [this.deviceAnalyticsList[index].groupuid] : [this.deviceAnalyticsList[index].model]
      }
    }

    this.deviceInfoListSpinner = true;

    this.dashboardService.getDeviceAnalyticsDetails(data)
      .subscribe((res: any) => {
        this.deviceInfoListSpinner = false;
        this.deviceInfoList = res;
      }, err => {
        this.deviceInfoListSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });

  }
}
