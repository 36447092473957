import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter } from 'events';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { CookieTrailService } from 'src/app/shared/services/cookie-trail.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { UserState } from 'src/app/shared/stores/user/user.reducer';
declare var $: any;

export interface PeriodicElement {
  name: string;
  totalProfiles: number;
  totalDownload: number;
  available: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'sample1', totalProfiles: 1, totalDownload: 35, available: 20},
  {name: 'sample2', totalProfiles: 2, totalDownload: 5, available: 93},
  {name: 'sample3', totalProfiles: 24, totalDownload: 3, available: 38},
  {name: 'sample4', totalProfiles: 30, totalDownload: 87, available: 67},
];
@Component({
  selector: 'app-device-analytics',
  templateUrl: './device-analytics.component.html',
  styleUrls: ['./device-analytics.component.scss']
})
export class DeviceAnalyticsComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  features;
  socket;
  formatter;
  customerId;
  dashboardData;
  profileSpinner = true;
  deviceAnalyticsList;
  deviceAnalyticsListSpinner = false;
  deviceAnalyticsProfileReDownload;
  deviceAnalyticsEidReDownload;
  groupBy = 'brand';
  dateRange = 'today';
  deviceAnalyticsSubscribe: Subscription;
  limitLength = 30;
  limitProfileEid = 25;
  deviceInfoList;
  deviceInfoListSpinner = false;
  sampleData = [
    {brand: "Apple", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4},
    {brand: "pqr", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4},
    {brand: "xyz", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4},
    {brand: "abc", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4},
    {brand: "Motorola", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4},
    {brand: "LG", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4},
    {brand: "Samsung", totalDownload: 6, percentDownload: 100, iccidRedownload: 0, eidRedownload: 4}
  ]
  sampledeviceAnalyticsProfileReDownload = [];
  sampledeviceAnalyticsEidReDownload = [];
  sampledeviceAnalyticsList = [];
  manualRefresh = false;
  isDashboardUser = false;
  
  constructor(private userStore: Store<UserState>,
    private cookieTrailService: CookieTrailService,
    private appComponent: AppComponent,
    private communicationService: CommunicationService,
    private dashboardService: DashboardService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    // #profileType .t-table .inventory-overflow
    // const profileTypeHeight = $('#profileType .t-table').height();

    // $('#element').css("width","calc(100% - 100px)");

    // $('#profileType .t-table').css('max-height', 'calc(90vh - (200px + profileTypeHeight))')
    this.cookieTrailService.saveCookie({});
    this.socket = this.appComponent.socket;

    this.formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,      
      maximumFractionDigits: 0,
    });

    localStorage.setItem('cookieTrail', JSON.stringify({}));
    
    this.userStore.select('user')
    .subscribe((user: any) => {
      if(user) {
        this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Dashboard')].features;
        this.getDashboardData();

        if(user.data.currentCustomer) {
          this.customerId = user.data.currentCustomer._id;
        } else {
          this.customerId = user.data.rootCustomer._id;
        }

        if(this.features && this.features[this.getFeatureIndex('db_device_analytics')].view) {
          this.getDeviceAnalytics();
          if(user.data.roleNames.includes('dashboard-user') && user.data.roleType === 'default') {
            this.isDashboardUser = true;
            this.setWidgetInvterval();
          }
        }
        
        this.socket.emit('csspuser', user.data._id);
        this.socket.on('bellnotification', (notiData) => {
         this.dashboardData.notification.unshift(notiData);
        });
      }
    });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.communicationService.getRefreshDashboardAction()
      .subscribe((res: any) => {
        this.getDeviceAnalytics();
      });
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  setWidgetInvterval() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        
        if (res.refreshTimeSetting.length > 0) {
          let index = res.refreshTimeSetting.findIndex(ele => ele.name === 'Device Analytics');
          if(res.refreshTimeSetting[index].time != 'manual') { 
            setInterval(() => {
              this.getDeviceAnalytics();
            }, Number(res.refreshTimeSetting[index].time));
            this.manualRefresh = false;
          } else {
            this.manualRefresh = true;
          }
        } else {
          setInterval(() => {
            this.getDeviceAnalytics();
          }, 60000 * 1)
        }
      });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  getDashboardData() {
    this.dashboardService.getDashboardData()
      .subscribe((res: any) => {
        this.profileSpinner = false;
        this.dashboardData = res;

        res.notification.forEach((element: any) => {
          if(element.message.includes('PRP Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('PRP Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('Campaign Generation Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('Reset Profile Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('Reset Profile Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('Prepare Profile Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('QR Code Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('QR Code Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('QRCode - ')) {
            let msg1 = element.message.split('QRCode');
            let msg2 = msg1[1].split('has been mailed to');
            let qrCode;
            let mailTo;
            this.translate.get('QRCode')
              .subscribe((res: any) => {
                qrCode = res;
                this.translate.get('has been mailed to')
                .subscribe((res: any) => {
                  mailTo = res;
                  element.transMessage = qrCode + msg2[0] + mailTo + msg2[1];
                });
              });
          } else if(element.message.includes('Prepare - ')) {
            let msg1 = element.message.split('Prepare');
            let msg2 = msg1[1].split('has been mailed to');
            let qrCode;
            let mailTo;
            this.translate.get('Prepare')
              .subscribe((res: any) => {
                qrCode = res;
                this.translate.get('has been mailed to')
                .subscribe((res: any) => {
                  mailTo = res;
                  element.transMessage = qrCode + msg2[0] + mailTo + msg2[1];
                });
              });
          } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('QR Code Generation Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else {
            element.transMessage = element.message;
          }
        });
      }, err => {
        const cookieTrail = {
          screenName: 'Dashboard',
          action: 'Get Dashboard Data',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        
        this.profileSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getDeviceAnalytics() {
    this.deviceAnalyticsList = [];
    this.deviceAnalyticsProfileReDownload = [];
    this.deviceAnalyticsEidReDownload = [];

    const data = {
      customerId: this.customerId,
      groupBy: this.groupBy,
      dateRange: this.dateRange
    };

    this.deviceAnalyticsListSpinner = true;

    if(this.deviceAnalyticsSubscribe) {
      this.deviceAnalyticsSubscribe.unsubscribe();
    }

    this.deviceAnalyticsSubscribe = this.dashboardService.getDeviceAnalytics(data)
      .subscribe((res: any) => {
        this.deviceAnalyticsListSpinner = false;
        if(!res.message) {
        this.deviceAnalyticsList = res;
        this.deviceAnalyticsList.forEach(element => {
          element.percentDownload = this.formatter.format(element.percentDownload);
        });
        this.deviceAnalyticsProfileReDownload = Object.assign([], this.deviceAnalyticsList);
        this.deviceAnalyticsEidReDownload = Object.assign([], this.deviceAnalyticsList);

        this.deviceAnalyticsList.sort((a,b) => (a.totalDownload < b.totalDownload) ? 1 : ((b.totalDownload < a.totalDownload) ? -1 : 0));
        this.deviceAnalyticsProfileReDownload.sort((a,b) => (a.iccidRedownload < b.iccidRedownload) ? 1 : ((b.iccidRedownload < a.iccidRedownload) ? -1 : 0));
        this.deviceAnalyticsEidReDownload.sort((a,b) => (a.eidRedownload < b.eidRedownload) ? 1 : ((b.eidRedownload < a.eidRedownload) ? -1 : 0))
        
        if(this.groupBy === 'brand') {
          if(this.deviceAnalyticsList.findIndex(ele => ele.brand === 'others') !== -1) {
            const ele = this.deviceAnalyticsList.splice(this.deviceAnalyticsList.findIndex(ele => ele.brand === 'others'), 1);
            this.deviceAnalyticsList.push(ele[0]);
          }

          if(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.brand === 'others') !== -1) {
            const ele = this.deviceAnalyticsProfileReDownload.splice(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.brand === 'others'), 1);
            this.deviceAnalyticsProfileReDownload.push(ele[0]);
          }

          if(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.brand === 'others') !== -1) {
            const ele = this.deviceAnalyticsEidReDownload.splice(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.brand === 'others'), 1);
            this.deviceAnalyticsEidReDownload.push(ele[0]);
          }
        } else if(this.groupBy === 'model') {
          if(this.deviceAnalyticsList.findIndex(ele => ele.model === 'others') !== -1) {
            const ele = this.deviceAnalyticsList.splice(this.deviceAnalyticsList.findIndex(ele => ele.model === 'others'), 1);
            this.deviceAnalyticsList.push(ele[0]);
          }

          if(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.model === 'others') !== -1) {
            const ele = this.deviceAnalyticsProfileReDownload.splice(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.model === 'others'), 1);
            this.deviceAnalyticsProfileReDownload.push(ele[0]);
          }

          if(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.model === 'others') !== -1) {
            const ele = this.deviceAnalyticsEidReDownload.splice(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.model === 'others'), 1);
            this.deviceAnalyticsEidReDownload.push(ele[0]);
          }
        } else if(this.groupBy === 'group') {
          if(this.deviceAnalyticsList.findIndex(ele => ele.group === 'others') !== -1) {
            const ele = this.deviceAnalyticsList.splice(this.deviceAnalyticsList.findIndex(ele => ele.group === 'others'), 1);
            this.deviceAnalyticsList.push(ele[0]);
          }

          if(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.group === 'others') !== -1) {
            const ele = this.deviceAnalyticsProfileReDownload.splice(this.deviceAnalyticsProfileReDownload.findIndex(ele => ele.group === 'others'), 1);
            this.deviceAnalyticsProfileReDownload.push(ele[0]);
          }

          if(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.group === 'others') !== -1) {
            const ele = this.deviceAnalyticsEidReDownload.splice(this.deviceAnalyticsEidReDownload.findIndex(ele => ele.group === 'others'), 1);
            this.deviceAnalyticsEidReDownload.push(ele[0]);
          }
        }

        if(window.innerWidth <= 1366) {
          this.limitLength = 3;
          this.limitProfileEid = 3;
        } else if(window.innerWidth <= 1440) {
          this.limitLength = 5;
          this.limitProfileEid = 5;
        } if(window.innerWidth <= 1330) {
          this.limitLength = 2;
          this.limitProfileEid = 2;
        }
      }
      }, err => {
        this.deviceAnalyticsListSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
    if(this.dashboardData && this.dashboardData.notification) {

      this.dashboardData.notification.forEach((element: any) => {
        if(element.message.includes('PRP Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('PRP Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Campaign Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Reset Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Reset Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('Prepare Profile Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QR Code Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else if(element.message.includes('QRCode - ')) {
          let msg1 = element.message.split('QRCode');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;
          
          this.translate.get('QRCode')
            .subscribe((res: any) => {
              qrCode = res;
              element.transMessage = qrCode + msg2[0];
            });
          
          this.translate.get('has been mailed to')
            .subscribe((res: any) => {
              mailTo = res;
              element.transMessage += mailTo + msg2[1];
            });
        } else if(element.message.includes('Prepare - ')) {
          let msg1 = element.message.split('Prepare');
          let msg2 = msg1[1].split('has been mailed to');
          let qrCode;
          let mailTo;

          this.translate.get('Prepare')
            .subscribe((res: any) => {
              qrCode = res;
              element.transMessage = qrCode + msg2[0];
            });
          
          this.translate.get('has been mailed to')
            .subscribe((res: any) => {
              mailTo = res;
              element.transMessage += mailTo + msg2[1];
            });
        } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
          let message = element.message.split('to');
          this.translate.get('QR Code Generation Report has been mailed to')
            .subscribe((res: any) => {
              element.transMessage = res + message[1];
            });
        } else {
          element.transMessage = element.message;
        }
      });
    }
  }

  sampleClicked(e) {
    e.preventDefault();
  }

  openTacInfo(index) {
    $('#tacInfoModal').modal();
    $('.modal-backdrop').removeClass('modal-backdrop');
    this.deviceInfoList = [];
    let data;

    if(this.deviceAnalyticsList[index].brand === 'others'
      || this.deviceAnalyticsList[index].group === 'others'
      || this.deviceAnalyticsList[index].model === 'others' ) {
        
      let notIn = [];

      this.deviceAnalyticsList.forEach(element => {
        if(this.groupBy === 'brand') {
          if(element.brand != 'others') {
            notIn.push(element.brand);
          }
        } else if(this.groupBy === 'group') {
          if(element.group != 'others') {
            notIn.push(element.groupuid);
          }
        } else if(this.groupBy === 'model') {
          if(element.model != 'others') {
            notIn.push(element.model);
          }
        }
      });

      data = {
        customerId: this.customerId,
        groupBy: this.groupBy,
        dateRange: this.dateRange,
        notIn
      }

    } else {
      data = {
        customerId: this.customerId,
        groupBy: this.groupBy,
        dateRange: this.dateRange,
        notIn: this.groupBy === 'brand' ? [this.deviceAnalyticsList[index].brand] : this.groupBy === 'group' ? [this.deviceAnalyticsList[index].groupuid] : [this.deviceAnalyticsList[index].model]
      }
    }
    this.deviceInfoListSpinner = true;

    this.dashboardService.getDeviceAnalyticsDetails(data)
      .subscribe((res: any) => {
        this.deviceInfoListSpinner = false;
        this.deviceInfoList = res;
      }, err => {
        $('#tacInfoModal').modal('hide');
        this.deviceInfoListSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });
  }

  public itemChange(item, itemComponent) {
  }

  refresh() {
    this.getDeviceAnalytics();
  }

}
