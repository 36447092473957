import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-smartjac-forgot-password',
  templateUrl: './smartjac-forgot-password.component.html',
  styleUrls: ['./smartjac-forgot-password.component.scss']
})
export class SmartjacForgotPasswordComponent implements OnInit {

  forgotPassForm: FormGroup;
  submitted = false;
  err = false;
  langSelected = 'en';

  constructor(
    private authService: AuthService,
    private translate: TranslateService) { }

  ngOnInit() {
    if(localStorage.getItem('language')) {
      this.langSelected = localStorage.getItem('language')
    } else {
      this.langSelected = 'en';
    }

    this.forgotPassForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  get f() { return this.forgotPassForm.controls; }

  onForgotPass() {
    this.submitted = true;
    if (this.forgotPassForm.invalid) {
      return;
    }

    const userData = {
      email: this.forgotPassForm.get('email').value
    };
    this.authService.forgot(userData, true);
  }

  changeLanguage() {
    localStorage.setItem('language', this.langSelected);
    this.translate.use(this.langSelected);
  }

}
