import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { ProfileService } from '../../shared/services/profile.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { saveAs } from 'file-saver';
import { Store } from '@ngrx/store';
import JSZip from 'jszip';
import { ApiService } from '../../shared/services/api.service';
import { Subscription } from 'rxjs';
import { UserState } from '../../shared/stores/user/user.reducer';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../../shared/services/cookie-trail.service';
import { PdfGeneratorService } from '../../shared/services/pdf-generator.service';
import { UiCustomizationService } from '../../shared/services/ui-customization.service';
import { environment } from '../../../environments/environment';
import { ManageGroupQrFilterState } from 'src/app/shared/stores/manage-group-qr-filter/filter.reducer';
import { GroupProfileService } from 'src/app/shared/services/group-profile.service';
import { Router } from '@angular/router';

declare var $: any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-profile-inventory',
  templateUrl: './profile-inventory.component.html',
  styleUrls: ['./profile-inventory.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class ProfileInventoryComponent implements OnInit, OnDestroy {

  @ViewChild('apiElementView', { read: ElementRef, static: true }) apiElementView: ElementRef;
  setBtnLabel= 'Aceptar';
  fromDate;
  toDate;
  flg = true;
  searchTerm = '';
  profileList;
  infiniteProfileList;
  statusArray = [
    { checked: false, key: 'Available' },
    { checked: false, key: 'Allocated' },
    { checked: false, key: 'Linked' },
    { checked: false, key: 'Confirmed' },
    { checked: false, key: 'Released' },
    { checked: false, key: 'Downloaded' },
    { checked: false, key: 'Installed' },
    { checked: false, key: 'Enabled' },
    { checked: false, key: 'Deleted' },
    { checked: false, key: 'Error' },
    { checked: false, key: 'Unavailable' }
  ];
  selectedStatusArr = [];
  sortOrderOption = [
    { name: 'ICCID', order: true, value: 'ICCID' },
    { name: 'STATUS', order: true, value: 'STATUS' },
    { name: 'PROFILE_TYPE', order: true, value: 'PROFILE_TYPE' },
    { name: 'LAST_UPDATE', order: true, value: 'LAST_UPDATE' },
  ];
  sortBy = 'ICCID';
  orderBy = 'ASC';
  lockStatus;
  filterArray = [];
  getProfileTypeList = [];
  profileType;
  select = false;
  selectAllIccid = false;
  @ViewChild('myName', { static: true }) myName;
  logList = [];
  profileSpinner = true;
  infoSpinner = true;
  selectedCount = 0;
  selectedIccidList = [];
  confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
  confirmCode = 'Fixed Confirmation Code';
  confirmationCodeFlgs = ['Yes', 'No'];
  confirmationCodeFlg = 'No';
  confirmationCode: any = 1234;
  errMsg = false;
  resetOptions = ['Available', 'Unavailable'];
  resetOption = 'Available';
  qrCodeOptions = [
    { name: 'Text', value: '1$RSP-0002.OBERTHUR.NET$CUMQE-AV6WL-GPIJ0-XVHI1', checked: true },
    { name: 'QR Code', value: 'assets/images/qr-code.png', checked: false },
    { name: 'QR Code with logo', value: 'assets/images/qr-code-with-logo.png', checked: false }
  ];

  textQr = true;
  qrCode = false;
  qrCodeWithLogo = false;
  // qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
  qrCodeWithLogoImg;
  qrCodeWithCampaign = false;
  templateList = [];
  selectedTempateId;
  campaignImg;
  campaignFormat = ['PNG', 'PDF'];
  selectedCampaignFormat = 'PNG';

  qrCodeOption = 'Text';
  errLength = false;
  disable = false;
  prepareRes;
  btnSpinner = false;
  disableReset = false;
  filteTerms;
  onintFlg = false;
  qrEmail = false;
  qrAdditionalEmail = false;
  qrDownload = true;
  eid;
  errEidMsg = false;
  fileToUpload;
  iccidEidObj = [];
  disableUploadCsv = true;
  errEmailDwn = false;
  profileLockStatus = [
    { name: 'Lock', checked: false },
    { name: 'Unlock', checked: false }
  ];
  // selectFlg = false;
  throttle = 700;
  scrollDistance = 1;
  scrollUpDistance = 2;
  lastIccid;
  tempIccid;
  profileInfiniteSpinner = false;
  profileCount = 0;
  apiList;
  profileSubscribe: Subscription;
  operationList;
  infoLogSubscriber: Subscription;
  infoOprSubscriber: Subscription;
  infoTacSubscriber: Subscription;
  noElement;
  apiDisplay = false;
  bulkQrCodeFlg = 'File Upload';
  bulkQrCodeFlgs = ['File Upload', 'Manual'];
  qrFile;
  bulkQrDownload = true;
  bulkQR: FormGroup;
  submitted = false;
  features;
  emailAddress;
  apiFeatures;
  user;
  additionalEmailErr = false;
  isprod;
  logosArray = [];
  logoId;
  logoImg;
  logoSelectErr = false;
  qrCodeDownloadErr = false;
  bulkQrCodeOptions = ['QR Code', 'QR Code With Logo', 'QR Code With Campaign'];
  qrOption = 'QR Code';
  downloadQrCodeIccid;
  fileSize = 1024 * 1024;
  optionsTypes = [
    {
      optionType:'Profile Type',
      profileTypes: [],
      groups: []
    }, {
      optionType:'Upload File'
    }, {
      optionType:'Count'
  }];
  selectedOption;
  prpSettingOptions = ['PRP_5', 'PRP_2a'];
  selectedPRPOption = 'PRP_5';
  prpCounter;
  prpCounterErr = false;
  customerId;
  syncProfileTypes = [];
  selectedIccidCount = 0;
  selectedProfileTypes = [];
  syncGroups = [];
  selectedGroups = [];
  profileTypeErr = false;
  countErr = false;
  groupErr = false;
  prpBtnSpinner = false;
  startingIccidErr = false;
  fileUploadErr = false;
  startingIccid;
  count;
  iccidList = [];
  syncPrpBtnSpinner = false;
  syncPrepareBtnSpinner = false;
  syncResetBtnSpinner = false;
  syncQrBtnSpinner = false;
  selectedProfileCount = 0;
  deselectedIccidList = [];
  logoSelected = false;
  defaultLogoId;
  tempLogoId;
  selectedLogoIndex;
  allLogoSpinner = false;
  tac_info = [];
  showTac = true;
  releaseMethod = [
    {label: 'With QR Code', value: 'withQR'},
    {label: 'Default SM-DP+', value: 'defaultSMDP'},
  ]
  selectedReleaseMethod = 'withQR';
  statusSpinner = false;
  statusList = [];
  eidLengthError = false;
  eidFileName;
  defaultSmdp = null;
  fileNameErr = false;
  disableQrCode = false;
  fieldList = [
    { name: 'Profile Type', selected: true },
    { name: 'Group Type', selected: true },
    { name: 'Status', selected: true },
    { name: 'Update On', selected: true },
    { name: 'PRP', selected: true },
    { name: 'PRP Count', selected: true },
    { name: 'EID', selected: true },
    { name: 'TAC', selected: true },
    { name: 'Device Model', selected: true }
  ];
  activeTableColumns = [];
  columnConfig = ['40px', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr', '40px'];
  activeGroupProfileList: any = [{ name: "None", id: '' }];
  activeGroupSelection: string = "Filter Private Group";
  activeGroupMatchingId: string = "";
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private commnicationService: CommunicationService,
    private apiService: ApiService,
    private userStroer: Store<UserState>,
    // dateTimeAdapter: DateTimeAdapter<any>,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService,
    private pdfService: PdfGeneratorService,
    private themeService: UiCustomizationService,
    private manageGroupQrfilterStore: Store<ManageGroupQrFilterState>,
    private grpPrfoileService: GroupProfileService) {

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
      if (this.router.getCurrentNavigation()?.extras?.state) {
        const { name, id } = this.router.getCurrentNavigation().extras.state;
        this.activeGroupSelection = name;
        this.activeGroupMatchingId = id;
      }
    }

  ngOnInit() {

    this.cookieTrailService.saveCookie({});

    this.isprod = (localStorage.getItem('isProd') === 'true');

    this.userStroer.select('user')
      .subscribe((res: any) => {
        if(res) {
          this.user = res.data;

          if(this.user.currentCustomer) {
            this.customerId = this.user.currentCustomer._id;
          } else {
            this.customerId = this.user.rootCustomer._id;
          }
        }

        if(res && res.featureRole && res.featureRole.length > 0) {
          this.features = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'Manage Profile')].features;
        }

      });

    this.commnicationService.getRemovedCustomer()
      .subscribe((res: any) => {
        this.getProfiles();
      });

    this.filterArray.push(
      { name: 'Sort By', key: 'ICCID', value: 'ICCID', checked: true, group: 'sortBy' },
      { name: 'Order By', key: 'ASC', value: 'Ascending', checked: true, group: 'orderBy' }
    );

    this.manageGroupQrfilterStore.select('manageGroupQrFilter')
      .subscribe(res => {
        if (res) {
          this.profileType = res;
          const temp = JSON.parse(localStorage.getItem('manageGroupQrfilteTerms'));
          const tempObj = temp[0];
          this.sortBy = tempObj.sortBy;
          this.orderBy = tempObj.order;
          this.selectedStatusArr = tempObj.statuses;
          this.statusArray.forEach((ele: any) => {
            if (this.selectedStatusArr.indexOf(ele.key.toUpperCase()) !== -1) {
              ele.checked = true;
            }
          });
          this.fromDate = tempObj.lastUpdateSince;
          this.toDate = tempObj.lastUpdateUntil;
          this.lockStatus = tempObj.profileTypeLockFlag;
          this.profileLockStatus = (JSON.parse(localStorage.getItem('manageGroupQrfilteTerms')))[1];
          this.getProfiles();
        } else if (localStorage.getItem('manageGroupQrfilteTerms')) {
          const temp = JSON.parse(localStorage.getItem('manageGroupQrfilteTerms'));
          const tempObj = temp[0];
          this.sortBy = tempObj.sortBy;
          this.orderBy = tempObj.order;
          this.profileType = tempObj.profileTypesFilter;
          this.selectedStatusArr = tempObj.statuses;
          this.statusArray.forEach((ele: any) => {
            if (this.selectedStatusArr.indexOf(ele.key.toUpperCase()) !== -1) {
              ele.checked = true;
            }
          });
          this.fromDate = tempObj.lastUpdateSince;
          this.toDate = tempObj.lastUpdateUntil;
          this.lockStatus = tempObj.profileTypeLockFlag;
          this.profileLockStatus = (JSON.parse(localStorage.getItem('manageGroupQrfilteTerms')))[1];
          this.getProfiles();
        } else {
          this.getProfiles();
        }
      });

    this.commnicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.grpPrfoileService.getFieldSection().subscribe(result => {
      if (result['data'] && result['data'].length > 0) {
        this.activeTableColumns = result['data'].map(d => {
          if (d.selected) {
              return d.name;
            }
        });
        this.activeTableColumns = this.activeTableColumns.filter(i => i);
        this.updateTableGrid();
        this.fieldList = result['data'];
        const fieldListName = [];
        for (let i = 0; i < this.fieldList.length; i++) {
          fieldListName.push(this.fieldList[i].name);
        }
      } else {
        this.activeTableColumns = ['Profile Type', 'Group Type', 'Status', 'Update On', 'PRP', 'PRP Count', 'EID', 'TAC', 'Device Model'];
      }
    })

    this.grpPrfoileService.getProfileGroups('active')
      .subscribe((res: any) => {
        res?.profileGroups
          .filter((f: any) => f.groupType === 'private')
          .forEach((group: any) => {
          this.activeGroupProfileList.push({ name: group.groupName, id: group.groupMatchingId });
        });
      })
  }

  showGroupMatchingIdList() {
  }

  updateTableFilterByGroupMatchingId(item) {
    let name = item.name;
    if (item.name === 'None') {
      name = 'Filter Private Group';
    }
    this.activeGroupSelection = name;
    this.activeGroupMatchingId = item.id;
    this.getProfiles();
  }

  getFeatureIndex(keyName, featureName) {
    if(featureName === 'api') {
      return this.apiFeatures ? this.apiFeatures.findIndex(ele => ele.key === keyName) : '';
    } else {
      return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
    }
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);
  }

  toggleFilter() {
    $('#filterBox').toggleClass('filter-show');
    $('#filterBtn').toggleClass('btn-filter-grey');
  }

  getProfiles() {
    this.profileList = [];
    if (this.profileSubscribe) {
      this.profileSubscribe.unsubscribe();
    }
    this.profileSpinner = true;
    this.tempIccid = null;
    this.lastIccid = null;

    setTimeout(() => {
      if ((this.profileLockStatus[0].checked === true && this.profileLockStatus[1].checked === true)
        || (this.profileLockStatus[0].checked === false && this.profileLockStatus[1].checked === false)) {
        this.lockStatus = null;
      } else if (this.profileLockStatus[0].checked === true) {
        this.lockStatus = true;
      } else if (this.profileLockStatus[1].checked === true) {
        this.lockStatus = false;
      }
      this.filteTerms = {
        sortBy: this.sortBy,
        order: this.orderBy,
        limit: '20',
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        statuses: this.selectedStatusArr,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastSortedByValue: this.sortBy,
        lastIccid: this.lastIccid,
        profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
        skip: 0
      };
      if (this.activeGroupSelection === 'Filter Private Group' || this.activeGroupSelection === 'None') {
        delete this.filteTerms.groupMatchingId;
      } else {
        this.filteTerms.groupMatchingId = this.activeGroupMatchingId;
      }
      localStorage.setItem('manageGroupQrfilteTerms', JSON.stringify([this.filteTerms, this.profileLockStatus]));

      this.profileSubscribe = this.grpPrfoileService.getProfiles(this.searchTerm, this.filteTerms)
        .subscribe((res: any) => {
          this.profileSpinner = false;
          this.profileList = res.profiles;
          this.profileCount = res.count;
          this.selectedProfileCount = 0;
          this.selectedIccidList = [];
          this.selectedCount = 0;
          this.select = false;
          this.selectAllIccid = false;
          this.deselectedIccidList = [];
          this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
          if (this.profileList && this.profileList.length > 0) {
            this.lastIccid = this.profileList[this.profileList.length - 1].iccid;
            this.profileList.forEach(ele => {
              ele.checked = false;
            });
          }
        }, err => {
          const cookieTrail = {
            screenName: 'Manage Profile',
            action: 'Get Profiles',
            body: {
              searchTerm: this.searchTerm,
              filteTerms: this.filteTerms
            },
            error: err.error || 'Something went wrong!',
          };

          this.cookieTrailService.saveCookie(cookieTrail);
          this.profileSpinner = false;
          this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }, 5);
  }

  getProfileTypes() {
    this.profileService.getProfileTypeList()
      .subscribe((res: any) => {
        this.getProfileTypeList = res.profileTypes;
        this.getProfileTypeList.forEach((e, index) => {
          e.id = index;
          e.checked = false;
        });
      }, err => {
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  selectStatus(index) {
    if (this.selectedStatusArr.indexOf(this.statusArray[index].key.toUpperCase()) === -1) {
      this.selectedStatusArr.push(this.statusArray[index].key.toUpperCase());
      this.filterArray.push({
        name: 'Status',
        key: this.statusArray[index].key,
        value: this.statusArray[index].key,
        group: 'status'
      });
      this.getProfiles();
    } else {
      this.selectedStatusArr.splice(this.selectedStatusArr.indexOf(this.statusArray[index].key.toUpperCase()), 1);
      this.filterArray.splice(this.filterArray.findIndex(i => i.key === this.statusArray[index].key), 1);
      this.getProfiles();
    }
  }

  seletDate(date) {

    if (date === 'fromDate') {
      if ((this.filterArray.findIndex(i => i.key === 'fromDate')) === -1) {
        this.filterArray.push({
          key: 'fromDate',
          name: 'From Date',
          value: moment(this.fromDate).format('DD-MM-YYYYT, hh:mm A'),
          group: 'date'
        });
      } else {
        this.filterArray[this.filterArray.findIndex(i => i.key === 'fromDate')].value
          = moment(this.fromDate).format('DD-MM-YYYYT, hh:mm A');
      }
    } else if (date === 'toDate') {
      if ((this.filterArray.findIndex(i => i.key === 'toDate')) === -1) {
        this.filterArray.push({
          key: 'toDate',
          name: 'To Date',
          value: moment(this.toDate).format('DD-MM-YYYYT, hh:mm A'),
          group: 'date'
        });
      } else {
        this.filterArray[this.filterArray.findIndex(i => i.key === 'toDate')].value
          = moment(this.toDate).format('DD-MM-YYYYT, hh:mm A');
      }
    }
  }

  selectAllRows() {
    this.selectedProfileCount = this.profileCount;
    if (this.select) {
      this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
      this.confirmCode = 'Fixed Confirmation Code';
      this.profileList.forEach(ele => {
        ele.checked = true;
      });
      this.selectedIccidList = [];
      this.deselectedIccidList = [];
    } else {
      this.profileList.forEach(ele => {
        ele.checked = false;
      });
      this.deselectedIccidList = [];
      this.selectedIccidList = [];
    }

  }

  selectCheck(check, iccid, state) {
    let flg = 0;
    this.confirmCode = 'Fixed Confirmation Code';
    this.confirmationCode = 1234;
    if (!check) {
      this.selectedCount--;
      if(this.selectAllIccid) {
        this.selectedProfileCount--;
        this.deselectedIccidList.push(iccid);
      }
      this.selectedIccidList.splice(this.selectedIccidList.findIndex(i => i.iccid === iccid), 1);
      this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
      // setTimeout(() => {
      //   if ((this.selectedIccidList.length === 1) && (this.selectedIccidList[0].state === 'AVAILABLE')) {
      //     this.disableReset = true;
      //   } else {
      //     this.disableReset = false;
      //   }
      // }, 5);
    } else if (this.selectedIccidList.findIndex(i => i.iccid === iccid) === -1) {
      this.selectedCount++;
      if(this.selectAllIccid) {
        this.selectedProfileCount++;
        this.deselectedIccidList.splice(this.deselectedIccidList.findIndex(i => i.iccid === iccid), 1);
      }
      this.selectedIccidList.push({ iccid, state });
      this.commnicationService.sendIccidList({ iccidList: this.selectedIccidList });
      // setTimeout(() => {
      //   if ((this.selectedIccidList.length === 1) && (this.selectedIccidList[0].state === 'AVAILABLE')) {
      //     this.disableReset = true;
      //   } else {
      //     this.disableReset = false;
      //   }
      // }, 5);
    }
    this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
    this.confirmCode = 'Fixed Confirmation Code';

    setTimeout(() => {
      this.profileList.forEach(ele => {
        if (!ele.checked) {
          this.select = false;
          flg = 1;
          return;
        }
        if (flg === 0) {
          this.select = true;
        }
      });
    }, 5);
  }

  lockStatusFilter() {
    if (this.lockStatus === 'true') {
      if (this.filterArray.findIndex(i => i.key === 'lock') === -1) {
        this.filterArray.push({
          name: 'Lock Status',
          key: 'lock',
          value: 'Lock',
          group: 'lockStatus'
        });
      }
      if (this.filterArray.findIndex(i => i.key === 'unlock') !== -1) {
        this.filterArray.splice(this.filterArray.findIndex(i => i.key === 'unlock'), 1);
      }
    } else if (this.lockStatus === 'false') {
      if ((this.filterArray.findIndex(i => i.key === 'unlock')) === -1) {
        this.filterArray.push({
          name: 'Lock Status',
          key: 'unlock',
          value: 'Unlock',
          group: 'lockStatus'
        });
      }
      if (this.filterArray.findIndex(i => i.key === 'lock') !== -1) {
        this.filterArray.splice(this.filterArray.findIndex(i => i.key === 'lock'), 1);
      }
    }
  }

  profileTypeFilter() {
    this.getProfileTypeList.forEach(ele => {
      if (ele.checked && (this.filterArray.findIndex(i => i.key === ele.name) === -1)) {
        this.filterArray.push({
          name: 'Profile Type',
          key: ele.name,
          value: ele.name,
          group: 'proType'
        });
      } else if (!ele.checked && (this.filterArray.findIndex(i => i.key === ele.name) !== -1)) {
        this.filterArray.splice(this.filterArray.findIndex(i => i.key === ele.name), 1);
      }
    });
  }

  clearFilters() {
    this.statusArray.forEach(element => {
      element.checked = false;
    });
    this.selectedStatusArr = [];
    this.fromDate = null;
    this.toDate = null;
    this.lockStatus = '';
    this.sortBy = 'ICCID';
    this.orderBy = 'ASC';
    this.filterArray = [];
    this.selectedIccidList = [];
    this.selectedCount = 0;
    this.searchTerm = '';
    this.profileLockStatus = [
      { name: 'Lock', checked: false },
      { name: 'Unlock', checked: false }
    ];
    this.getProfiles();
  }

  getLogs(iccid, i, selectedTab) {
    this.logList = [];
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');



    if (this.infoOprSubscriber) {
      this.infoOprSubscriber.unsubscribe();
    }

    this.infoLogSubscriber = this.profileService.getLogs({ iccid })
      .subscribe((res: any) => {
        this.logList = res.logs;
        this.infoSpinner = false;
      }, err => {
        this.infoSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getICCIDInfo(iccid) {
    this.defaultSmdp = null;
    this.profileService.getICCIDInfo({iccid})
      .subscribe((res: any) => {
        this.defaultSmdp = res.defaultSmdp;
      }, err => {
        this.defaultSmdp = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getOperations(iccid, i, selectedTab) {
    this.operationList = [];
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');

    if (this.infoLogSubscriber) {
      this.infoLogSubscriber.unsubscribe();
    }

    this.infoOprSubscriber = this.profileService.getOperations({ iccid })
      .subscribe((res: any) => {
        this.operationList = res;
        this.infoSpinner = false;
      }, err => {
        this.infoSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getTacInfo(iccid, i, selectedTab) {
    this.tac_info = [];
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');

    if (this.infoTacSubscriber) {
      this.infoTacSubscriber.unsubscribe();
    }

    this.infoTacSubscriber = this.profileService.getTacInfo({ iccid })
    .subscribe((res: any) => {
      this.tac_info = res;
      this.infoSpinner = false;
    }, err => {
      this.infoSpinner = false;
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    });
  }

  getStatus(iccid, i, selectedTab) {
    this.infoSpinner = true;
    const allTabs = ['log', 'opr', 'tac', 'status'];
    const unselectedTabs = allTabs.filter((tab) => tab != selectedTab);
    $(`#info-${selectedTab}-tab_` + i).addClass('active btn-log-active');
    $(`#info-${unselectedTabs[0]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[1]}-tab_`+ i).removeClass('active btn-log-active');
    $(`#info-${unselectedTabs[2]}-tab_`+ i).removeClass('active btn-log-active');

    $(`#info-${unselectedTabs[0]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[1]}_` + i).removeClass('show active');
    $(`#info-${unselectedTabs[2]}_` + i).removeClass('show active');
    $(`#info-${selectedTab}_` + i).addClass('active show');
  }

  getSelectedCount() {
    this.disableReset = false;
    this.disableQrCode = false;
  }

  getQrCode() {
    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Download QR Code',
      body: {
        iccid: this.downloadQrCodeIccid,
        logoId: this.qrOption === 'QR Code With Logo' ? this.logoId : '',
        qrCodeWithLogo: this.qrOption === 'QR Code With Logo' ? true : false
       },
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    const logoId = this.qrOption === 'QR Code With Logo' ? this.logoId : '';
    const qrCodeWithLogo = this.qrOption === 'QR Code With Logo' ? true : false;

    this.profileService.getQrCode(this.downloadQrCodeIccid, logoId, qrCodeWithLogo)
      .subscribe((res: any) => {
        if (!res.data) {
          return;
        }

        const zip = new JSZip();
        const img = zip.folder(this.downloadQrCodeIccid);
        img.file(this.downloadQrCodeIccid + '.png',  res.data, { base64: true });

        if(res.qrCodeWithLogo) {
          const data = res.qrCodeWithLogo.split(',');
          img.file(this.downloadQrCodeIccid + '-logo.png', data[1], { base64: true });
        }

        zip.generateAsync({ type: 'blob' })
        .then((content) => {
          saveAs(content, `${this.downloadQrCodeIccid}.zip`);
        });

        $('#downloadQrCodeModal').modal('hide');
        this.qrCodeWithLogo = false;
      }, err => {
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  activeButton(selectedBtn) {

    if ((selectedBtn !== 'op-qrCode' && selectedBtn !== 'op-prpSetting' && selectedBtn !== 'op-prepareProfile') && (this.selectedIccidList.length <= 0 && !this.selectAllIccid)) {
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'Please select iccid' });
      return;
    }

    if(selectedBtn !== 'op-prpSetting') {
      this.campaignImg = '';
      this.getDefaultLogo();
    }

    this.emailAddress = '';
    switch (selectedBtn) {

      case 'op-resetProfile':
        if(this.selectAllIccid) {
          this.syncInventory('reset');
        }

        this.resetOption = 'Available';
        $('#op-prepareProfile').removeClass('c-btn-action-active');
        $('#op-resetProfile').addClass('c-btn-action-active');
        $('#op-qrCode').removeClass('c-btn-action-active');
        $('#op-prpSetting').removeClass('c-btn-action-active');
        if(!this.selectAllIccid) {
          ($('#resetProfile') as any).modal({ backdrop: 'static', keyboard: false });
        }
        break;
    }
  }

  get f() { return this.bulkQR.controls; }

  closeOperations() {
    $('#op-prepareProfile').removeClass('c-btn-action-active');
    $('#op-resetProfile').removeClass('c-btn-action-active');
    $('#op-qrCode').removeClass('c-btn-action-active');
    $('#op-prpSetting').removeClass('c-btn-action-active');
    this.confirmationCodes = ['Fixed Confirmation Code', 'Random Confirmation Code'];
    this.confirmCode = 'Fixed Confirmation Code';

    this.errLength = false;
    this.errMsg = false;
    this.disable = false;
    this.qrCodeOption = 'Text';
    this.textQr = true;
    this.qrCode = false;
    this.qrCodeWithLogo = false;
    this.prepareRes = '';
    this.btnSpinner = false;
    this.qrEmail = false;
    this.qrDownload = true;
    this.eid = '';
    this.errEidMsg = false;
    this.errEmailDwn = false;
    this.confirmationCodeFlg = 'No';
    this.disableUploadCsv = true;
    this.bulkQrCodeFlg = 'File Upload';
    this.bulkQrDownload = true;
    this.submitted = false;
    this.emailAddress = '';
    this.qrAdditionalEmail = false;
    this.logoSelectErr = false;
    this.qrCodeDownloadErr = false;
    this.qrOption = 'QR Code';
    this.selectedReleaseMethod = 'withQR';
  }

  openConfirmation() {
    $('#resetProfile').modal('hide');
  }

  resetProfile() {
    this.disable = false;
    let resetData;
    let tempIccid = [];

    if(!this.selectAllIccid) {
      if (this.selectedIccidList.length === 1) {
        resetData = {
          status: this.resetOption.toUpperCase(),
          iccidList: this.selectedIccidList.length === 1 ? [this.selectedIccidList[0].iccid] : ''
        };
      } else if (this.selectedIccidList.length > 1) {
        this.selectedIccidList.forEach((ele: any) => {
          tempIccid.push(ele.iccid);
        });
        resetData = {
          iccidList: tempIccid,
          status: this.resetOption.toUpperCase()
        };
      }
    } else {
      resetData = {
        status: this.resetOption.toUpperCase(),
        operation: 'RESET_M',
        deselectedICCID: this.deselectedIccidList,
        profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
        statuses: this.selectedStatusArr,
        lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
        lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
      }
    }

    // added groupQR flag
    resetData = {
      ...resetData,
      groupQR: true
    }

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Reset Profile',
      body: resetData,
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.btnSpinner = true;

    // this.profileService.allIccidOperation(prepareData)
      // .subscribe((res: any) => {

    if(this.selectAllIccid) {
      this.profileService.resetProfile(resetData, null)
      .subscribe((res: any) => {
        $('#resetProfile').modal('hide');
        $('#resetConfirmation').modal('hide');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        this.resetOption = 'Available';
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        this.selectedCount = 0;
        this.selectedIccidList = [];
        tempIccid = [];
        this.getProfiles();
      }, err => {
        $('#resetConfirmation').modal('hide');
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
    } else {
      this.profileService.resetProfile(resetData, this.selectedIccidList.length === 1 ? this.selectedIccidList[0].iccid : '')
      .subscribe((res: any) => {
        $('#resetProfile').modal('hide');
        $('#resetConfirmation').modal('hide');
        $('#op-resetProfile').removeClass('c-btn-action-active');
        this.resetOption = 'Available';
        this.disable = false;
        this.btnSpinner = false;
        if(this.selectedIccidList.length === 1) {
          let msg;
        this.translate.get('profile is reset successfully!')
          .subscribe(res => {
            msg = res;
          })
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: `${ this.selectedIccidList[0].iccid + ' ' + msg }` });
        } else {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        }

        this.selectedCount = 0;
        this.selectedIccidList = [];
        tempIccid = [];
        this.getProfiles();
      }, err => {
        $('#resetConfirmation').modal('hide');
        this.disable = false;
        this.btnSpinner = false;
        this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
    }
  }

  isEnter(evt) {

    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode === 13 && !evt.shiftKey) {
      this.getProfiles();
    }

  }

  onScrollDown() {

    if (this.tempIccid !== this.lastIccid) {
      this.tempIccid = this.lastIccid;
      this.profileInfiniteSpinner = true;
      setTimeout(() => {
        if ((this.profileLockStatus[0].checked === true && this.profileLockStatus[1].checked === true)
          || (this.profileLockStatus[0].checked === false && this.profileLockStatus[1].checked === false)) {
          this.lockStatus = null;
        } else if (this.profileLockStatus[0].checked === true) {
          this.lockStatus = true;
        } else if (this.profileLockStatus[1].checked === true) {
          this.lockStatus = false;
        }

        this.filteTerms = {
          sortBy: this.sortBy,
          order: this.orderBy,
          limit: '20',
          profileTypesFilter: (!this.profileType || this.profileType === null) ? [] : this.profileType,
          statuses: this.selectedStatusArr,
          lastUpdateSince: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
          lastUpdateUntil: this.toDate ? moment(this.toDate).format('YYYY-MM-DDThh:mm:ss') + 'Z' : null,
          lastSortedByValue: this.sortBy,
          lastIccid: this.lastIccid,
          profileTypeLockFlag: (this.lockStatus === true || this.lockStatus === false) ? (this.lockStatus ? true : false) : null,
          skip: this.profileList.length
        };

        if (this.activeGroupSelection === 'Filter Private Group' || this.activeGroupSelection === 'None') {
          delete this.filteTerms.groupMatchingId;
        } else {
          this.filteTerms.groupMatchingId = this.activeGroupMatchingId;
        }

        localStorage.setItem('manageGroupQrfilteTerms', JSON.stringify([this.filteTerms, this.profileLockStatus]));

        this.grpPrfoileService.getProfiles(this.searchTerm, this.filteTerms)
          .subscribe((res: any) => {
            this.profileInfiniteSpinner = false;
            this.infiniteProfileList = res.profiles;
            // this.selectedIccidList = [];
            // this.selectedCount = 0;
            // this.select = false;
            if (this.infiniteProfileList && this.infiniteProfileList.length > 0) {
              this.lastIccid = this.infiniteProfileList[this.infiniteProfileList.length - 1].iccid;
              for(let i = 0; i < this.infiniteProfileList.length; i++) {
                if(this.selectAllIccid) {
                  this.infiniteProfileList[i].checked = true;
                } else {
                  this.infiniteProfileList[i].checked = false;
                }
                this.profileList.push(this.infiniteProfileList[i]);
              }
            }

            if(this.select) {
              for(let i = 0; i < this.profileList.length; i++) {
                if(!this.profileList[i].checked) {
                  this.select = false;
                  break;
                }
              }
            }

          }, err => {
            this.profileInfiniteSpinner = false;
            this.commnicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
          });
      }, 5);
    }

  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 47 && charCode < 58) {
      return true;
    }
    return false;
  }

  uploadQrFile() {
    $('#bulk-qr-file').click();
  }

  ngOnDestroy() {
    if (this.profileSubscribe) {
      this.profileSubscribe.unsubscribe();
    }
    $('[data-toggle="tooltip"]').tooltip('hide');
  }

  getQrLogos() {
    this.logosArray = [];
    this.allLogoSpinner = true;
    this.profileService.getLogos()
      .subscribe((res: any) => {
        this.allLogoSpinner = false;
        this.logoSelected = false;
        this.selectedLogoIndex = '';
        if(res.message === 'No Logo Found!') {
          this.logosArray = [];
          // this.commnicationService.openAlert({alertType: 'error', alertMsg: 'No Logo Found!'});
        } else {
          // this.logoId = '';
          this.logosArray = res;
        }
        this.logoSelectErr = false;
        ($('#selectLogoModal') as any).modal({ backdrop: 'static', keyboard: false });
      }, err => {
        this.allLogoSpinner = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  selectLogo() {
    this.logoSelected = false;

    if(!this.logoId || (this.logoId === this.tempLogoId)) {
      return;
    }
    const data = {
      logo: this.logoImg,
      text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
    }
    this.pdfService.addLogoInQr(data)
        .subscribe((res: any) => {
            this.qrCodeWithLogoImg = res.qrCode;
            $('#selectLogoModal').modal('hide');
        }, err => {
          this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });
  }

  uploadQrCodeLogo() {
    $('#qr-code-logo').click();
  }

  onQrLogoChange(evt) {

    if (evt.target.files[0].size > (1 * this.fileSize)) {
      this.commnicationService.openAlert({ alertType: 'error', alertMsg: 'File size should not be more than 1mb.' });
      return;
    }

    const fileUpload = evt.target.files[0];
    const formData = new FormData();
    formData.set('file', fileUpload);
    evt.target.value = '';

    this.profileService.uploadQrLogo(formData)
      .subscribe((res: any) => {

        this.logosArray.push({
          _id: res.result._id,
          customer: res.result.customer,
          data: res.pngData
        });
      }, err => {
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });
  }

  // Sync Inventory (Iccid and Profile Types)
  syncInventory(model) {
    this.selectedIccidCount = 0;
    if(model === 'prp') {
      this.syncPrpBtnSpinner = true;
    } else if(model === 'prepare') {
      this.syncPrepareBtnSpinner = true;
    } else if(model === 'reset') {
      this.syncResetBtnSpinner = true;
    } else if(model === 'qr') {
      this.syncQrBtnSpinner = true;
    }

    $('[data-toggle="tooltip"]').tooltip('hide');

    const cookieTrail = {
      screenName: 'Manage Profile',
      action: 'Sync Inventory for prp',
      body: { customerId: this.customerId },
      timeStamp: new Date(),
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.profileService.syncInventory({ customerId: this.customerId, filterProfileTypes: this.profileType })
      .subscribe((res: any) => {
        this.syncPrpBtnSpinner = false;
        this.syncPrepareBtnSpinner = false;
        this.syncResetBtnSpinner = false;
        this.syncQrBtnSpinner = false;
        if(res.message && res.message.includes('There is database operation in progress for profileType')) {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        } else if(res.message === 'Groups are not assigned.') {
          this.commnicationService.openAlert({ alertType: 'success', alertMsg: res.message });
        } else {

          if(this.user.currentCustomer === null || this.user.currentCustomer.isParent) {
            this.optionsTypes[0].optionType = 'Profile Type'
            this.selectedOption = 'Profile Type';
            this.optionsTypes[0].profileTypes = [];
            res.profileTypes.forEach(element => {
              this.optionsTypes[0].profileTypes.push({
                profileType: element.profileType,
                count: element.count,
                checked: false
              });
            });
            this.selectedProfileTypes = [];
          } else {
            this.optionsTypes[0].optionType = 'Groups';
            this.selectedOption = 'Groups';
            this.optionsTypes[0].groups = [];
            res.forEach(element => {
              this.optionsTypes[0].groups.push({
                groupName: element.groupName,
                checkd: false,
                _id: element._id,
                count: element.count
              });
            });
            this.selectedGroups = [];
          }
          if(model === 'prp') {
            $('#prpSetting').modal();
          } else if(model === 'prepare') {
            ($('#prepareProfile') as any).modal({ backdrop: 'static', keyboard: false });
          } else if(model === 'reset') {
            ($('#resetProfile') as any).modal({ backdrop: 'static', keyboard: false });
          } else if(model === 'qr') {
            ($('#qrCode') as any).modal({ backdrop: 'static', keyboard: false });
          }
        }
      }, err => {
        this.syncPrpBtnSpinner = false;
        this.syncPrepareBtnSpinner = false;
        this.syncResetBtnSpinner = false;
        this.syncQrBtnSpinner = false;
        this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  onRadioChange() {
    this.profileTypeErr = false;
    this.countErr = false;
    this.startingIccidErr = false;
    this.prpCounter = '';
    this.fileUploadErr = false;
    this.fileToUpload = '';
    this.selectedProfileTypes = [];
    this.startingIccid = '';
    this.count = '';
    this.iccidList = [];
    this.selectedIccidCount = 0;

    if(this.selectedOption === 'Upload File' || this.selectedOption === 'Count') {
      if(this.user.currentCustomer === null || this.user.currentCustomer.isParent) {
        this.optionsTypes[0].profileTypes.forEach(ele => {
          ele.checked = false;
        });
        this.selectedProfileTypes = [];
      } else {
        this.optionsTypes[0].groups.forEach(ele => {
          ele.checked = false;
        });
        this.selectedGroups = [];
      }
    }
  }

  toggleBorderClass(i) {
    if(this.selectedLogoIndex >= 0 && (this.selectedLogoIndex != i)) {
      $('#profile-single-logo-' + this.selectedLogoIndex).removeClass('img-logo-border');
    }
    $('#profile-single-logo-' + i).toggleClass('img-logo-border');
    if($('#profile-single-logo-' + i).hasClass('img-logo-border')) {
      this.logoSelected = true;
      this.selectedLogoIndex = i;
    } else {
      this.logoSelected = false;
      this.selectedLogoIndex = '';
    }
  }

  getDefaultLogo() {
    this.themeService.getDefaultLogo()
      .subscribe((res: any) => {
        if(res.message === 'No default logo found!') {
          this.logoId = '';
          this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
          this.defaultLogoId = '';
        } else {
          this.defaultLogoId = res._id;
          this.logoId = res._id;
          this.tempLogoId = this.logoId;
          const data = {
            logo: 'data:image/png;base64,' + res.data,
            text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
          }
          this.pdfService.addLogoInQr(data)
            .subscribe((res: any) => {
                this.qrCodeWithLogoImg = res.qrCode;
                $('#selectLogoModal').modal('hide');
            }, err => {
              this.commnicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
            });
        }
      });
  }

  setDefaultLogo() {
    if(!this.logoId) {
      this.logoSelectErr = true;
      return;
    }

    let tempDefaultLogoId = this.defaultLogoId;
    this.defaultLogoId = this.logoId;

    this.themeService.setDefaultLogo({logoId: this.logoId})
      .subscribe((res: any) => {
      }, err => {
        this.defaultLogoId = tempDefaultLogoId;
        this.commnicationService.openAlert({'alertType': 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  deleteLogo() {
    if(!this.logoId) {
      this.logoSelectErr = true;
      return;
    }

    this.themeService.deleteLogo({logoId: this.logoId})
      .subscribe((res: any) => {
        $('#profile-single-logo-' + this.selectedLogoIndex).removeClass('img-logo-border')
        if(this.defaultLogoId != this.logoId) {
          this.logoId = this.defaultLogoId;
        } else {
          this.logoId = '';
          this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
          this.defaultLogoId = '';
        }
        this.logoSelected = false;
        this.logosArray.splice(this.selectedLogoIndex, 1);
        this.selectedLogoIndex = '';
      }, err => {
        this.commnicationService.openAlert({'alertType': 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  updateTableColums(item) {
    item.selected = !item.selected;
    this.grpPrfoileService.updateFieldSection(this.fieldList).subscribe((result) => {
      this.fieldList = result['data'];
      this.activeTableColumns = result['data'].map(d => {
        if(d.selected) {
            return d.name;
          }
      });
      this.activeTableColumns = this.activeTableColumns.filter(i => i);
      this.updateTableGrid();
    });
  }

  updateTableGrid(){
    this.columnConfig = [null, null, ...this.activeTableColumns, null].map((v, i, r) => {
      if (i === 0 || i === r.length - 1) {
        return '40px';
      }
      return '1fr';
    });
  }

  showList(e) {
    e.stopPropagation();
  }

}
