import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingStateService {

  private history = [];
  private preRoute = new Subject<any>();

  constructor(private router: Router) { }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
        this.preRoute.next(this.history[this.history.length - 2] || '/index');
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public onLoadGetPreviousUrl() {
    return this.history[this.history.length - 2] || '/index';
  }

  public getPreviousUrl(): Observable<any> {
    return this.preRoute.asObservable();
  }
}
