import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../../components/shape/shape.component';
import { MousePosition, ACToken } from '../../model/shape';
import { ShapeType } from '../../model/shape-types';
@Component({
  selector: 'app-actoken',
  templateUrl: './actoken.component.html',
  styleUrls: ['./actoken.component.scss']
})
export class ActokenComponent extends ShapeComponent implements OnInit {
  formFields = [
    {
      name: 'name',
      label: 'Name:',
      type: 'input',
      inputType: 'text',
    },
    {
      name: 'x',
      label: 'X:',
      type: 'input',
      inputType: 'text',
    },
    {
      name: 'y',
      label: 'Y:',
      type: 'input',
      inputType: 'text',
    },
    {
      name: 'value',
      label: 'Text:',
      type: 'input',
      inputType: 'text',
    },
  ];

  constructor() {
    super();
    // console.log('TextComponent constructor');
    this.shape = new ACToken();
    // this.shape.value = 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE';
    this.shapeType = ShapeType.ACToken;
  }

  ngOnInit() {
    // console.log('TextComponent ngOnInit');
  }

  updateShapeProperties(value: any) {
    if (this.shape instanceof ACToken) {
      this.shape.shapeProperties.name = value.name;
      this.shape.value = value.value;
      this.shape.originX = value.x;
      this.shape.originY = value.y;
    }
  }

  setStyles() {
    const styles = {
      fill: this.shape.shapeProperties.strokeColor
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof ACToken) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
      this.shape['font-family'] = 'Arial';
      this.shape['font-size'] = 16;
    }
    // console.log('TextComponent startDrawing at ', beginPosition, ', ', this.shape);
  }
}
