import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as _moment from 'moment';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
declare var $: any;
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { Store } from '@ngrx/store';
import { ThemeState } from 'src/app/shared/stores/theme/theme.reducer';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UserState } from 'src/app/shared/stores/user/user.reducer';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-download-trend-graph',
  templateUrl: './download-trend-graph.component.html',
  styleUrls: ['./download-trend-graph.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class DownloadTrendGraphComponent implements OnInit {

  // lineChartData: ChartDataSets[] = [
  //   { data: [20, 72, 48, 75, 47, 25], label: 'Crude oil prices' },
  // ];

  // lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

  // lineChartOptions = {
  //   responsive: true,
  // };

  // lineChartColors: Color[] = [
  //   {
  //     borderColor: 'black',
  //     backgroundColor: 'rgba(255,255,0,0.28)',
  //   },
  // ];
  // lineChartLegend = true;
  // lineChartPlugins = [];
  // lineChartType = 'line';

  startDate = new Date(moment(new Date()).subtract(60, 'days'));
  endDate = new Date();
  filterBy = '7days';
  custStart;
  custEnd;
  language;
  public lineChartData: ChartDataSets[];
  public lineChartLabels: Label[];
  public lineChartColors: Color[] = [
    {
      borderColor: '#FF9016',
      backgroundColor: 'transparent',
    },
  ];
  public lineChartLegend = false;
  public lineChartPlugins = [];
  public lineChartType = 'line';
  public lineChartOptions = {
    scales: {
      xAxes: [{
        gridLines: { color: '#5A5A5A', borderDash: [6] },
      }],
      yAxes: [{
        gridLines: { color: '#5A5A5A', borderDash: [6] },
        ticks: { beginAtZero: true, stepSize: 500 }
      }]
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    layout: {
      padding: {
        bottom: 30,
      }
    }
  };
  filterValue = 'Week-to-Date';
  dateTitle = 10;
  public selectedMoments = [
    moment(new Date()).subtract(14, 'day'),
    moment(new Date())
  ];

  dateRangeOne = moment(this.selectedMoments[0]).format('DD-MM-YYYY');
  dateRangeTwo = moment(this.selectedMoments[1]).format('DD-MM-YYYY');

  trendSpinner = false;
  noDataFound = false;
  manualRefresh = false;
  isDashboardUser = false;
  constructor(private dashboardService: DashboardService,
              private themeStore: Store<ThemeState>,
              private communicationService: CommunicationService,
              private translate: TranslateService,
              private userStore: Store<UserState>) {
    translate.addLangs(environment.languageArray);
    translate.setDefaultLang('en');
    this.language = localStorage.getItem('language');
    if (!this.language) {
      this.language = 'en';
      localStorage.setItem('language', this.language);
    }
    translate.use(this.language.match(environment.langRegex) ? this.language : 'en');
  }

  ngOnInit(): void {

    this.setDefaults();
    this.communicationService.getLanguage().
      subscribe((res: any) => {
        this.changeLanguage();
      });

    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          if(user.data.roleNames.includes('dashboard-user') && user.data.roleType === 'default') {
            this.isDashboardUser = true;
            this.setWidgetInvterval();
          }
        }
      });

    this.communicationService.getRefreshDashboardAction()
      .subscribe((res: any) => {
        this.setDefaults();
      });
  }

  setWidgetInvterval() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        
        if (res.refreshTimeSetting.length > 0) {
          let index = res.refreshTimeSetting.findIndex(ele => ele.name === 'Trend Graph');
          if(res.refreshTimeSetting[index].time != 'manual') {
            setInterval(() => {
              this.setDefaults();
            }, Number(res.refreshTimeSetting[index].time));
            this.manualRefresh = false;
          } else {
            this.manualRefresh = true;
          }
        } else {
          setInterval(() => {
            this.setDefaults();
          }, 60000 * 1)
        }
      });
  }

  setFilterValue(){
    if (this.filterBy === 'today'){
      this.filterValue = 'Today';
    } else if (this.filterBy === '7days') {
      this.filterValue = 'Week-to-Date';
    } else if (this.filterBy === '1month') {
      this.filterValue = 'Month-to-Date';
    } else if (this.filterBy === '1year') {
      this.filterValue = 'Year-to-Date';
    } else if ( this.filterBy === 'custom') {
      this.filterValue = 'Custom';
    }
  }

  onDateChange(){
    this.dateRangeOne = moment(this.selectedMoments[0]).format('DD-MM-YYYY');
    this.dateRangeTwo = moment(this.selectedMoments[1]).format('DD-MM-YYYY');
  }

  setDefaults() {
    this.startDate = new Date();
    this.endDate = new Date();
    let data = {
      keyword: 'Date',
      date_range: this.filterBy
    };
    
    switch (this.filterBy) {
      case 'today':
        data.keyword = 'Date';
        this.custStart = moment(this.startDate).format('YYYY/MM/DD');
        this.custEnd = moment(this.startDate).format('YYYY/MM/DD');
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        break;
      case 'yesterday':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate()-1);
        this.custStart = moment(this.startDate).format('YYYY/MM/DD');
        this.custEnd = moment(new Date()).format('YYYY/MM/DD');
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        data.keyword = 'Date';
        break;          
      case '7days':
        this.startDate = new Date();
        // this.startDate.setDate(this.startDate.getDate()-6);
        this.custStart = moment(this.startDate).startOf('isoweek').format('YYYY/MM/DD');
        this.custEnd = moment(new Date()).format('YYYY/MM/DD');
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        data.keyword = 'Date';
        break;
      case '1month':
        this.startDate = new Date();
        this.custStart = moment().startOf('month').format('YYYY/MM/DD');
        // this.custEnd = moment().endOf('month').format('YYYY/MM/DD');
        this.custEnd = moment(this.startDate).format('YYYY/MM/DD');
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        // data.keyword = 'Date';       
        break;
      case '1year':
        this.startDate = new Date();
        this.startDate.setDate(1);
        this.startDate.setMonth(0);
        this.custStart = moment(this.startDate).startOf('year').format('YYYY/MM/DD');
        this.custEnd = moment(new Date()).format('YYYY/MM/DD');
        data.keyword = 'Month';
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        break;
      case 'custom':
        this.custStart = moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        this.custEnd = moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
        data['fromDate'] = this.custStart;
        data['toDate'] = this.custEnd;
        data.keyword = 'Date';
        break;
      default:
          return;
    }
    this.totalDownloadData(data);
  }
                
  totalDownloadData(data){
    this.trendSpinner = true;
    this.dashboardService.getTotalDownloadGraphData(data).subscribe((res: any) => {
      if(res && res.length > 0) {
        this.lineChartOptions.scales.yAxes[0].ticks.stepSize = Math.round(Math.max(...res.map(d => d.count)) / 5);
      }
      this.assignToGraph(res);
      this.trendSpinner = false;
    })
  }

  assignToGraph(graphData) {
    const lables = [];
    const data = [];
    if (graphData && graphData.length > 0) {      
      graphData.forEach((element) => {
        data.push(element.count);
        lables.push(`${element.label}`);
      });
      this.lineChartLabels = lables;
      this.lineChartData = [
        { data, borderWidth: 2 },
      ];
    } else {
      this.noDataFound = true;
    }
  }

  changeLanguage() {
    this.language = localStorage.getItem('language');
    this.translate.use(this.language);
  }

  refresh() {
    this.setDefaults();
  }

}
