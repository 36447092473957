import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { UserState } from '../shared/stores/user/user.reducer';
import { Store } from '@ngrx/store';
declare var $: any;
import * as _moment from 'moment';
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { GovernanceReportService } from '../shared/services/governance-report.service';
import { CommunicationService } from '../shared/services/communication.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { environment } from '../../environments/environment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD-MM-YYYY, LT',
  datePickerInput: 'DD-MM-YYYY, LT',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-governance-report',
  templateUrl: './governance-report.component.html',
  styleUrls: ['./governance-report.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})
export class GovernanceReportComponent implements OnInit {

  userList;
  selectedUsers = [];
  userDropdownSettings = {
    singleSelection: false,
    idField: 'userId',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'email',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  fromDate = moment(new Date()).subtract(7, 'day');
  toDate = new Date();
  actionArray = [
    { checked: false, key: 'Login' },
    { checked: false, key: 'Prepare' },
    { checked: false, key: 'Reset' },
    { checked: false, key: 'QRCode' },
    { checked: false, key: 'Users' },
    { checked: false, key: 'MyAccount' },
    { checked: false, key: 'Theme' },
    { checked: false, key: 'Group' },
    { checked: false, key: 'Autoblacklist' },
    { checked: false, key: 'Blacklist' },
    { checked: false, key: 'API' },
    { checked: false, key: 'Email' },
    { checked: false, key: 'Group_management' },
    { checked: false, key: 'Campaign' },
    { checked: false, key: 'Logo' },
    { checked: false, key: 'Profile_Limit' }
  ];
  selectedActionArr = [];
  goverenceReportList;
  reportSpinner = true;
  singleUser;
  userReportSpinner = true;
  mailSpinner = false;
  downloadSpinner = false;
  show = false;
  features;
  public selectedMoments = [
    moment(new Date()).subtract(14, 'day'),
    moment(new Date())
  ];

  constructor(
    private userService: UserService,
    private userStore: Store<UserState>,
    private governanceService: GovernanceReportService,
    private communicatonService: CommunicationService,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService) {

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      // const browserLang = translate.getBrowserLang();
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
      translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.userDropdownSettings.selectAllText = res;
        this.refreshDropSettings();
      });

      translate.get('Search').subscribe((res: string) => { 
        this.show = false;       
        this.userDropdownSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });

      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.userDropdownSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.userDropdownSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });

      translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.userDropdownSettings.selectAllText = res;
        this.refreshDropSettings();
      });

      translate.get('Search').subscribe((res: string) => { 
        this.show = false;       
        this.userDropdownSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });

      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.userDropdownSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.userDropdownSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });
    }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});

    this.userStore.select('user')
      .subscribe((user: any) => {
        if (user) {

          if(user.featureRole && user.featureRole.length > 0) {
            this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Governance')].features;
          }

          this.userService.getUsers(user.data.customerId)
            .subscribe((res: any) => {
              this.userList = res;
            }, err => {
              const cookieTrail = {
                screenName: 'Governance Report',
                action: 'Get All Users',
                body: {customerId: user.data.customerId},
                error: err.error || 'Something went wrong!',
              };
              
              this.cookieTrailService.saveCookie(cookieTrail);
              this.communicatonService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
            });
        }
      });

    this.getGovernanceReport();
    this.communicatonService.getLanguage()
    .subscribe((res: any) => {
      this.changeLanguage();
    });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);

    this.translate.get('Select All').subscribe((res: string) => {
      this.show = false;
      this.userDropdownSettings.selectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('Search').subscribe((res: string) => { 
      this.show = false;       
      this.userDropdownSettings.searchPlaceholderText = res;
      this.refreshDropSettings();
    });

    this.translate.get('UnSelect All').subscribe((res: string) => {
      this.show = false;
      this.userDropdownSettings.unSelectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('No data available').subscribe((res: string) => {
      this.show = false;
      this.userDropdownSettings.noDataAvailablePlaceholderText = res;
      this.refreshDropSettings();
    });
  }
  
  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  getGovernanceReport() {
    const userList = [];
    this.selectedUsers.forEach((ele: any) => {
      userList.push(ele.userId);
    });
    const data = {
      fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      users: userList,
      action: this.selectedActionArr,
    };
    this.reportSpinner = true;


    this.governanceService.getGoverenceReport(data)
      .subscribe((res: any) => {
        this.reportSpinner = false;
        this.goverenceReportList = res;
      }, err => {
        const cookieTrail = {
          screenName: 'Governance Report',
          action: 'Get Governance Report',
          body: data,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.reportSpinner = false;
        this.communicatonService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  selectStatus(index) {
    if (this.selectedActionArr.indexOf(this.actionArray[index].key.toUpperCase()) === -1) {
      this.selectedActionArr.push(this.actionArray[index].key.toUpperCase());
      this.getGovernanceReport();
    } else {
      this.selectedActionArr.splice(this.selectedActionArr.indexOf(this.actionArray[index].key.toUpperCase()), 1);
      this.getGovernanceReport();
    }
  }

  getSingleUserReport(userId) {
    this.userReportSpinner = true;
    this.governanceService.getSingleUserReport(userId)
      .subscribe((res: any) => {
        this.userReportSpinner = false;
        this.singleUser = res;
        this.singleUser.info = [];
        Object.keys(this.singleUser.data).forEach((ele, i) => {
          this.singleUser.info.push({ field: ele, value: Object.values(this.singleUser.data)[i] });
        });
        $('#userReport').modal();
      }, err => {
        const cookieTrail = {
          screenName: 'Governance Report',
          action: 'Get Single User Governance Report',
          body: {userId},
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.userReportSpinner = false;
        this.communicatonService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  sendMail() {
    const userList = [];
    this.selectedUsers.forEach((ele: any) => {
      userList.push(ele.userId);
    });
    const data = {
      fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      users: userList,
      action: this.selectedActionArr,
    };

    this.mailSpinner = true;

    const cookieTrail = {
      screenName: 'Governance Report',
      action: 'Send Email',
      body: data
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    
    this.governanceService.sendMail(data)
      .subscribe((res: any) => {
        this.mailSpinner = false;
        let temp = res.message.split('sent to');

        console.log(temp);
        this.translate.get(temp[0] + 'sent to').subscribe((res1: string) => {
          let temp1 = res1;
          this.communicatonService.openAlert({ alertType: 'success', alertMsg: temp1 + temp[1] });
        });

      }, err => {
        this.mailSpinner = false;
        this.communicatonService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  downloadReport() {
    const userList = [];
    this.selectedUsers.forEach((ele: any) => {
      userList.push(ele.userId);
    });
    const data = {
      fromDate: moment(this.selectedMoments[0]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      toDate: moment(this.selectedMoments[1]).format('YYYY-MM-DD') + moment(new Date()).format('THH:mm:ss.sssZ'),
      users: userList,
      action: this.selectedActionArr,
    };

    this.downloadSpinner = true;
    const cookieTrail = {
      screenName: 'Governance Report',
      action: 'Download Email',
      body: data
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.governanceService.downloadRport(data)
      .subscribe((res: any) => {
        this.downloadSpinner = false;

        const fileName = 'Governance Report.json';
        const blob = new Blob([JSON.stringify(res, null, 2)], { type: 'application/json' });
        saveAs(blob, fileName);
      }, err => {
        this.downloadSpinner = false;
        this.communicatonService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }
}
