import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '../../shared/services/communication.service';
import { UserService } from '../../shared/services/user.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  submitted = false;
  err = false;
  langSelected = 'en';
  load = 'default';

  downloadJob;
  features;
  defaultRoute;
  env;
  serverUrl;
  url;
  loginScreenObj;
  logoUrl;
  singleSubdomain;
  subdomain = false;
  apiResponse = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private userService: UserService,
    private http: HttpClient) { }

  ngOnInit() {
    this.getLoginScreenTheme();

    this.signinForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });

    this.route.queryParams.subscribe((params: any) => {
      this.downloadJob = params.downloadJob;

      if(params.err && params.err === '1') {
        setTimeout(() => {
          // this.communicationService.openAlert({alertType: 'error', alertMsg: 'Your download link is expired!'});
          $('#downloadLinkModal').modal();
        }, 500);
        return;
      }

      if (!localStorage.getItem('authToken')) {
        this.userService.getTokenFromCookie().subscribe((res: any) => {
          if (res.token && res.refresh_token) {
            localStorage.setItem('refreshToken', res.refresh_token);
            localStorage.setItem('authToken', res.token);
            location.reload()
          } else {
            if (this.downloadJob) {
              window.location.href = `${environment.loginUrl}?redirect-path=signin?downloadJob=${this.downloadJob}&customer_domain=${location.hostname}` ;
            } else {
              window.location.href = `${environment.loginUrl}?customer_domain=${location.hostname}` ;
            }
            
          }
        }, (error) => {
          // assume it will be invalid token
          if (this.downloadJob) {
            window.location.href = `${environment.loginUrl}?redirect-path=signin?downloadJob=${this.downloadJob}&customer_domain=${location.hostname}` ;
          } else {
            window.location.href = `${environment.loginUrl}?customer_domain=${location.hostname}` ;
          }
        })
      } else if(this.downloadJob && localStorage.getItem('authToken')) {
        let headers = new HttpHeaders({
          Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Auth header
        });
        
        this.serverUrl = environment.serverUrl;
        this.http.get(`${this.serverUrl}/downloadJob/${this.downloadJob}`, {headers})
          .toPromise()
          .then((response: any) => {

            this.url = response.url;
              if(this.url) {
                setTimeout(() => {
                  const aTag = document.createElement("a");
                  aTag.setAttribute("id", 'downloadLink1');
                  aTag.setAttribute("href", this.url);
                  const textNode = document.createTextNode('Download');
                  aTag.appendChild(textNode);
                  document.getElementById('download').appendChild(aTag);
                  document.getElementById('downloadLink1').click();
                  setTimeout(() => {
                    if (localStorage.getItem('authToken')) {
                      this.router.navigate(['/dashboard']);
                      return;
                    }
                  }, 1000);
                }, 500);
              }
          })
          .catch(err => {
            // this.signout();
            this.router.navigate(['/signin'], { queryParams: { downloadJob: this.downloadJob, err: 1 } });
          });
          
        // this.profileService.downloadJob(this.downloadJob);
        
      } else if (localStorage.getItem('authToken')) {
        this.router.navigate(['/dashboard']);
        return;
      } else {
        // this.communicationService.openAlert({ alertType: 'error', 'Something went wrong!' });
        window.location.href = `${environment.loginUrl}?redirect-path=signin?downloadJob=${this.downloadJob}&customer_domain=${location.hostname}` ;
        // this.router.navigate(['/signin'], { queryParams: {downloadJob: this.downloadJob } });
      }

      
    });

    const hostname = window.location.host;
//smartjac.my.10ttech.com/ 

    if(hostname.includes('smartjac')){
      this.load = 'smartjac'
    }
    if(hostname.includes('tunetalk')){
      this.load = 'tunetalk'

    }
    // if (localStorage.getItem('authToken')) {
    //   this.router.navigate(['/dashboard']);
    //   return;
    // }

    if(localStorage.getItem('language')) {
      this.langSelected = localStorage.getItem('language')
    } else {
      this.langSelected = 'en';
    }

    let self = this;
    $('#downloadLinkModal').on('hidden.bs.modal', function () {
      setTimeout(() => {
        if (localStorage.getItem('authToken')) {
          $('#alertModal').modal('hide');
          self.router.navigate(['/dashboard']);
          return;
        }
      }, 2000);
    });
  }

  get f() { return this.signinForm.controls; }

  onSignin() {
    this.submitted = true;
    if (this.signinForm.invalid) {
      return;
    }

    const userData = {
      email: this.signinForm.get('email').value,
      password: this.signinForm.get('password').value
    };

    this.authService.signin(userData)
      .subscribe((res: any) => {
        localStorage.setItem('refreshToken', res.refreshToken);
        localStorage.setItem('authToken', res.token);

        if(this.downloadJob) {
          let headers = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Auth header
          });
          
          this.serverUrl = environment.serverUrl;
          this.http.get(`${this.serverUrl}/downloadJob/${this.downloadJob}`, {headers})
            .toPromise()
            .then((response: any) => {
              this.url = response.url;
              if(this.url) {
                setTimeout(() => {
                  document.getElementById('downloadLink').click();
                  setTimeout(() => {
                    this.userService.getUser()
                    .subscribe((res: any) => {
                      this.features = res.featureRole;
                      // this.defaultRoute = this.features[this.getMainFeatureIndex('Dashboard')].display ? '/dashboard' : '/dashboard-user';
                      window.location.href = '/dashboard';
                    });
                  },1000);
                }, 500);
              }
            }).catch(err => {
              // this.signout();
              window.location.href = `/signin?downloadJob=${this.downloadJob}&err=1`;
            });
        } else {
          this.userService.getUser()
              .subscribe((res: any) => {
                this.features = res.featureRole;
                // this.defaultRoute = this.features[this.getMainFeatureIndex('Dashboard')].display ? '/dashboard' : '/dashboard-user';
                window.location.href = '/dashboard';
              });
        }
      }, (err: any) => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getMainFeatureIndex(name) {
    return this.features ? this.features.findIndex(ele => ele.name === name) : '';
  }

  changeLanguage() {
    localStorage.setItem('language', this.langSelected);
    this.translate.use(this.langSelected);
  }

  generateNewLink() {
    this.serverUrl = environment.serverUrl;
    
    let headers = new HttpHeaders({
      Accept: 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Auth header
      'Content-Type': 'application/json'
    });
    this.http.post(`${this.serverUrl}/generateNewLink`, {jobUID: this.downloadJob}, {headers})
      .subscribe((res: any) => {
        $('#downloadLinkModal').modal('hide');
        this.communicationService.openAlert({ alertType: 'success', alertMsg: res.message });
      });
  }

  signout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('role');
    localStorage.removeItem('customerId');
    localStorage.removeItem('filteTerms');
    localStorage.removeItem('manageGroupQrfilteTerms');
    localStorage.removeItem('notificationMode');
    localStorage.removeItem('screenMode');
    localStorage.removeItem('env');
    localStorage.removeItem('isProd');
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('rootCustomer');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('language');
    localStorage.removeItem('cookieTrail');
    this.translate.use('en');
  }

  getLoginScreenTheme() {
    console.log(window.location.host);
    const data = {
      subdomainURL: window.location.host
    };
    this.apiResponse = false;

    this.authService.getLoginScreenTheme(data)
      .subscribe((res: any) => {
        this.loginScreenObj = res;
        this.apiResponse = true;
        if (this.loginScreenObj.length > 0) {

          this.subdomain = true;
          
          setTimeout(() => {
            $('.createaccount-form').css('padding', '140px 50px');
            $('.signin-inner').css('border', '1px solid rgb(116, 114, 114)');
            $('.signin-inner').css('border-radius', '15px');
            $('.onboarding-form-input').css('border-radius', '0.25rem');
            $('.onboarding-form-input').css('background-color', 'transparent');
            $('.signin-inner-btn').css('border-radius', '0.25rem');
          }, 10);
          
          
          this.singleSubdomain = this.loginScreenObj[0];

          this.logoUrl = this.singleSubdomain.logoUrl;

          document.documentElement.style.setProperty('--p-main-login-background', this.singleSubdomain.background);
          document.documentElement.style.setProperty('--p-main-login-leftSide', this.singleSubdomain.leftColor);
          document.documentElement.style.setProperty('--p-main-login-rightSide', this.singleSubdomain.rightColor);
          document.documentElement.style.setProperty('--p-main-login-btn', this.singleSubdomain.button);
          document.documentElement.style.setProperty('--p-main-login-btn-text', this.singleSubdomain.buttonTextColor);
          document.documentElement.style.setProperty('--p-main-login-font', this.singleSubdomain.font);
        } else {
          this.subdomain = false;
          this.logoUrl = 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/image-10t.png';

          // document.documentElement.style.setProperty('--p-main-login-background', 'black');
          // document.documentElement.style.setProperty('--p-main-login-leftSide', '');
          // document.documentElement.style.setProperty('--p-main-login-rightSide', '#00bc90');
          // document.documentElement.style.setProperty('--p-main-login-btn', '#000');
          // document.documentElement.style.setProperty('--p-main-login-btn-text', '#fff');
          // document.documentElement.style.setProperty('--p-main-login-font', 'rgb(54, 54, 54)');

          document.documentElement.style.setProperty('--p-main-login-background', '#fff');
          document.documentElement.style.setProperty('--p-main-login-leftSide', '');
          document.documentElement.style.setProperty('--p-main-login-rightSide', '#fff');
          document.documentElement.style.setProperty('--p-main-login-btn', '#000');
          document.documentElement.style.setProperty('--p-main-login-btn-text', '#fff');
          document.documentElement.style.setProperty('--p-main-login-font', 'rgb(54, 54, 54)');
          
          setTimeout(() => {
            $('.carousel').carousel({
              interval: 4000
            });
            $('.carousel-control.right').trigger('click');
          }, 10);
        }
      }, err => {
        this.apiResponse = true;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }
}
