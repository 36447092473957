<div class="device-analytics-container">
	<div class="analytics-data">
		<!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
			<ng-container matColumnDef="name">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
			</ng-container>
			<ng-container matColumnDef="position">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Profiles </th>
			  <td mat-cell *matCellDef="let element"> {{element.totalProfiles}} </td>
			</ng-container>
			<ng-container matColumnDef="weight">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Download </th>
			  <td mat-cell *matCellDef="let element"> {{element.totalDownload}} </td>
			</ng-container>
			<ng-container matColumnDef="symbol">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Available </th>
			  <td mat-cell *matCellDef="let element"> {{element.available}} </td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table> -->

		<div class="face-container-inner-bg" *ngIf="features && features[getFeatureIndex('db_device_analytics')].view">
			<div class="border-bottom-light device-analytics-head">
				<div class="p-3 d-flex justify-content-between">
					<h4 class="mr-4 mb-0">{{"Device Analytics" | translate}}</h4>
					<div class="d-flex align-items-center">
						<label class="t-label pull-left mr-3 mb-0">{{'Selection' | translate}} </label>
						<select class="custom-selection-dropdown outline gridster-ignore-drag" [(ngModel)]="groupBy" (ngModelChange)="getDeviceAnalytics()">
							<option value="brand">{{'Brand' | translate}}</option>
							<option value="group">{{'Series' | translate}}</option>
							<option value="model">{{'Model' | translate}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position"></i></span>
					</div>
					<div class="d-flex align-items-center">
						<label class="t-label pull-left mr-3 mb-0 text-right" style="margin-left: 22px; white-space: nowrap;">{{'Date Range' | translate}} </label>
						<select class="custom-selection-dropdown outline gridster-ignore-drag" style="padding-right: 20px; white-space: normal;" [(ngModel)]="dateRange" (ngModelChange)="getDeviceAnalytics()" (click)="sampleClicked($event)">
							<option value="today">{{'Today' | translate}}</option>
							<option value="yesterday">{{'Yesterday' | translate}}</option>
							<option value="week">{{'Week-to-Date' | translate}}</option>
							<option value="month">{{'Month-to-Date' | translate}}</option>
							<option value="year">{{'Year-to-Date' | translate}}</option>
						</select>
						<span><i class="fa fa-caret-down caret-position"></i></span>
					</div>
					<div *ngIf="manualRefresh && isDashboardUser">
						<i class="fa fa-refresh ml-4 cursor-p" style="font-size: 1vw;" (click)="refresh()"></i>
					</div>
				</div>
			</div>
			<div class="tac-info-tables" style="height: 100vh;">
				<div class="table-lg-1 pr-2">
					<div class="dahboard-tac-table">
						<div class="t-grid-table-new">
							<div class="t-grid-table-wrapper t-head-bg dashboard-header">
								<div class="t-box a">{{(groupBy === 'brand' ? 'Brand' : groupBy === 'group' ? 'Series' : 'Model')| translate}}</div>
								<div class="t-box b">{{'%' | translate}}</div>
								<div class="t-box c">{{'Download' | translate}}</div>
								<div class="t-box a">{{'TAC' | translate}}</div>
							</div>
							<div class="loader-flex-center" *ngIf="deviceAnalyticsListSpinner">
								<div class="loader quantum-spinner m-auto"></div>
							</div>
							<div class="t-body-overflow" *ngIf="deviceAnalyticsList && deviceAnalyticsList.length <= 0 && !deviceAnalyticsListSpinner">
								<div class="p-1 t-body-bg cursor-p text-center">
									{{'No Data Found' | translate}}.
								</div>
							</div>
							<div class="t-body-overflow">
								<div class="t-grid-table-wrapper t-body-bg border-bt-ext-light" *ngFor="let item of deviceAnalyticsList; let i = index;">
									<div class="t-box d t-font-light" title="{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model}}">{{(groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model) | limitTo: limitLength}}</div>
									<div class="t-box d t-font-light">{{item.percentDownload  | number: '1.0-0' }}%</div>
									<div class="t-box d t-font-light" style="text-align: center;">{{item.totalDownload}}</div>
									<div class="t-box d t-font-light d-flex align-items-center" style="text-align: center;">
										<a class="t-font-color c-info py-0 c-color gridster-ignore-drag" (click)="openTacInfo(i);">
											<i class="fa fa-info" style="width: 15px;height: 15px;font-size: 10px;"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="table-lg-2 border-rt-ext-light border-lf-ext-light px-2">
					<div class="dahboard-tac-table">
						<div class="t-grid-table-new">
							<div class="t-grid-table-wrapper-profile-download t-head-bg dashboard-header">
								<div class="t-box a">{{(groupBy === 'brand' ? 'Brand' : groupBy === 'group' ? 'Series' : 'Model') | translate}}</div>
								<div class="t-box b">{{'Profile' | translate}} <br/> {{'Re-Download' | translate}}</div>
							</div>
							<div class="loader-flex-center" *ngIf="deviceAnalyticsListSpinner">
								<div class="loader quantum-spinner m-auto"></div>
							</div>
							<div class="t-body-overflow" *ngIf="deviceAnalyticsProfileReDownload && deviceAnalyticsProfileReDownload.length <= 0 && !deviceAnalyticsListSpinner">
								<div class="p-1 t-body-bg cursor-p text-center">
									{{'No Data Found' | translate}}.
								</div>
							</div>
							<div class="t-body-overflow">
								<div class="t-grid-table-wrapper-profile-download t-body-bg border-bt-ext-light" *ngFor="let item of deviceAnalyticsProfileReDownload; let i = index;">
									<div class="t-box d t-font-light" title="{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model}}">{{ (groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model) | limitTo: limitProfileEid}}</div>
									<div class="t-box d t-font-light" style="text-align: center;">{{item.iccidRedownload}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="table-lg-3 pl-2">
					<div class="dahboard-tac-table">
						<div class="t-grid-table-new">
							<div class="t-grid-table-wrapper-profile-download t-head-bg dashboard-header">
								<div class="t-box a">{{(groupBy === 'brand' ? 'Brand' : groupBy === 'group' ? 'Series' : 'Model') | translate}}</div>
								<div class="t-box b">{{'EID' | translate}} <br/> {{'Re-Download' | translate}}</div>
							</div>
							<div class="loader-flex-center" *ngIf="deviceAnalyticsListSpinner">
								<div class="loader quantum-spinner m-auto"></div>
							</div>
							<div class="t-body-overflow" *ngIf="deviceAnalyticsEidReDownload && deviceAnalyticsEidReDownload.length <= 0 && !deviceAnalyticsListSpinner">
								<div class="p-1 t-body-bg cursor-p text-center">
									{{'No Data Found' | translate}}.
								</div>
							</div>
							<div class="t-body-overflow">
								<div class="t-grid-table-wrapper-profile-download t-body-bg border-bt-ext-light" *ngFor="let item of deviceAnalyticsEidReDownload; let i = index;">
									<div class="t-box d t-font-light" title="{{groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model}}">{{ (groupBy === 'brand' ? item.brand : groupBy === 'group' ? item.group : item.model) | limitTo: limitProfileEid}}</div>
									<div class="t-box d t-font-light" style="text-align: center;">{{item.eidRedownload}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="tacInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "TAC Information" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="dahboard-tac-table">
                    <div class="t-grid-table-new">
                        <div class="t-grid-table-wrapper-tac-info t-head-bg">
                            <div class="t-box a">{{'TAC Number' | translate}}</div>
                            <div class="t-box b">{{'Device Name' | translate}}</div>
                            <div class="t-box c">{{'Download' | translate}}</div>
                        </div>
                        <div class="loader-flex-center" *ngIf="deviceInfoListSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="t-body-overflow" *ngIf="deviceInfoList && deviceInfoList.length <= 0 && !deviceInfoListSpinner">
                            <div class="p-1 t-body-bg cursor-p text-center">
                                {{'No Data Found' | translate}}.
                            </div>
                        </div>
                        <div class="tac-info-body-overflow">
                            <div class="t-grid-table-wrapper-tac-info t-body-bg border-bt-ext-light" *ngFor="let item of deviceInfoList; let i = index;">
                                <div class="t-box d t-font-light">{{item.tac}}</div>
                                <div class="t-box d t-font-light">{{item.name}}</div>
                                <div class="t-box d t-font-light" style="text-align: center;">{{item.totalDownload}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>