import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileTroubleshootingService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getLogs(iccidList, searchTerm) {
    const data = {
      iccidList
    };
    if (searchTerm) {
      return this.http.post(`${this.serverUrl}/notifications/${searchTerm}`, data, this.getHeader())
        .pipe(map(response => response));
    } else {
      return this.http.post(`${this.serverUrl}/notifications`, data, this.getHeader())
        .pipe(map(response => response));
    }

  }

  getBulkJob() {
    return this.http.post(`${this.serverUrl}/getjobs`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  playandpauseQueue(data) {
    return this.http.post(`${this.serverUrl}/playandpauseQueue`, data, this.getHeader())
      .pipe(map(response => response));
  }

  cancelQueue(data) {
    return this.http.post(`${this.serverUrl}/cancelQueue`, data, this.getHeader())
      .pipe(map(response => response));
  }

  generateLink(data) {
    return this.http.post(`${this.serverUrl}/generateLink`, data, this.getHeader())
      .pipe(map(response => response));
  }

  // getNotificationMode() {
  //   return this.http.get(`${environment.serverUrl}/notifications`, this.getHeader())
  //     .pipe(map(response => response));
  // }

  // changeNotificationMode(data) {
  //   return this.http.put(`${environment.serverUrl}/notifications`, data, this.getHeader())
  //     .pipe(map(response => response));
  // }
}
