<div class="inner-main-profile">
    <div class="ui-customization-head-section face-container-inner-bg p-2 mb-3">
        <div class="edit-theme-head-row d-flex align-items-center">
            <p class="mb-0 flex-grow-1">{{'Edit Theme' | translate}}</p>
            <div class="preview-btns mr-3">
                <button class="btn btn-edit-head t-bgcolor py-0 px-4 outline" data-toggle="modal" data-target="#confirmationAlert" (click)="confirmationVar = 'saveTheme'">{{'Save' | translate}}</button>
                <button class="btn btn-edit-head t-reset-red py-0 px-4 outline" data-toggle="modal" data-target="#confirmationAlert" (click)="confirmationVar = 'factoryReset'">{{'Factory Reset' | translate}}</button>
                <button class="btn btn-edit-head t-reset-red py-0 px-4 outline" (click)="previousThemeFlag = false;onReset();">{{'Reset to previous theme' | translate}}</button>
            </div>
            <div class="edit-theme-head-right">
                <div class="switch cursor-p">
                    <span class="switcher switcher-1 cursor-p">
                        <input class="cursor-p" (click)="changeScreenMode();getTheme();"  type="checkbox" id="switcher-1">
                        <label class="cursor-p" for="switcher-1"></label>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="ui-customization-logo-section  mb-3">
        <div class="row">
            <div class="col-lg-6">
                <div class="face-container-inner-bg box-height">
                    <div class="border-bottom p-2">{{'Logo' | translate }}</div>
                    <div class="p-2 t-font-light t-font-medium">
                        <div class="mb-5 pt-1">
                            {{'We suggest a logo size of 300px height. File should be in JPG or PNG format' | translate}}.
                        </div>
                        <div class="d-flex mb-5">
                            <div class="d-flex align-items-center pr-3" style="max-width: 250px;">
                                <p class="mb-0">{{'Upload File' | translate}}:</p>
                            </div>
                            <div class="d-flex align-items-center">
                                <button class="btn py-0 mr-5 t-font-medium bg-light-grey text-secondary outline" (click)="clickUploadIcon('large')">
                                    <input id="largeAppIcon" type="file" style="display: none" accept="image/x-png,image/gif,image/jpeg" (change)="onChangeIcon($event, 'large')">
                                    {{'Choose File' | translate}}
                                </button>
                                <p class="mb-0" *ngIf="largeIconName">
                                    {{largeIconName}}
                                </p>
                                <div class="ml-5" style="width: 120px;">
                                    <img id="large-app-icon" class="img-fluid" style="max-height: 65px;" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- <p class="mb-0 py-3">Or</p>
                        <div class="row mb-4">
                            <div class="col-lg-3 d-flex align-items-center">
                                <p class="mb-0">Upload From Url:</p>
                            </div>
                            <div class="col-lg-4">
                                <input type="text" class="t-input bg-light-grey border-0 form-control t-font-medium">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="face-container-inner-bg box-height">
                    <div class="border-bottom p-2">{{'Minimize Sidebar Logo' | translate}}</div>
                    <div class="p-2 t-font-light t-font-medium">
                        <div class="mb-4 pt-1">
                            {{'We suggest a logo size of 300px height. File should be in JPG or PNG format. You can use short logo or symbol' | translate}}.
                        </div>
                        <div class="d-flex mb-5">
                            <div class="d-flex align-items-center pr-3" style="max-width: 150px;">
                                <p class="mb-0">{{'Upload File' | translate}}:</p>
                            </div>
                            <div class="d-flex align-items-center">
                                <button class="btn py-0 mr-5 t-font-medium bg-light-grey text-secondary outline" (click)="clickUploadIcon('small')">
                                    <input id="smallAppIcon" type="file" style="display: none" accept="image/x-png,image/gif,image/jpeg" (change)="onChangeIcon($event, 'small')">
                                    {{'Choose File' | translate}}
                                </button>
                                <p class="mb-0" *ngIf="smallIconName">
                                    {{smallIconName}}
                                </p>
                                <div class="ml-5" style="width: 120px;">
                                    <img id="small-app-icon" style="max-height: 65px;" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- <p class="mb-0 py-3">Or</p>
                        <div class="row mb-4">
                            <div class="col-lg-3 d-flex align-items-center">
                                <p class="mb-0">Upload From Url:</p>
                            </div>
                            <div class="col-lg-4">
                                <input type="text" class="t-input bg-light-grey border-0 form-control t-font-medium">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="ui-customization-theme-color-section face-container-inner-bg mb-3 box-height">
                <div class="border-bottom p-2">{{'Theme Color' | translate}}</div>
                <div class="p-2 t-font-light t-font-medium">
                    <div class="mb-4 pt-1">
                        {{'We suggest you choose dark colours for side bar and background for dark mode theme' | translate}}.
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Side Bar and Face container' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="sideBarColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'sidebar')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="sideBarColor" [style.background]="sideBarColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'sidebar')" (cpInputChange)="onEventLog('cpInputChange', $event, 'sidebar')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'sidebar')" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Background' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="backgroundColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'background')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="backgroundColor" [style.background]="backgroundColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'background')" (cpInputChange)="onEventLog('cpInputChange', $event, 'background')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'background')" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Font' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="fontColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'font')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="fontColor" [style.background]="fontColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'font')" (cpInputChange)="onEventLog('cpInputChange', $event, 'font')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'font')" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row color-input-row">
                        <div class="col-lg-3 d-flex align-items-center">{{'Button' | translate}}</div>
                        <div class="col-lg-9 d-flex">
                            <div>
                                <input type="text" [(ngModel)]="buttonColor" (ngModelChange)="onEventLog('colorPickerClose', $event, 'button')" class="t-input bg-light-grey color-code-input t-font-medium outline">
                            </div>
                            <div>
                                <input class="color-input outline" [(colorPicker)]="buttonColor" [style.background]="buttonColor" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" (colorPickerClose)="onEventLog('colorPickerClose', $event, 'button')" (cpInputChange)="onEventLog('cpInputChange', $event, 'button')"
                                    [cpPosition]="'top-right'" [cpDisableInput]="true" (cpSliderDragEnd)="onEventLog('colorPickerClose', $event, 'button')" type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">

            <div class="face-container-inner-bg box-height">
                <div class="border-bottom p-2">{{'Preview' | translate}}</div>
                <div class="preview-screen d-flex">
                    <div class="preview-screen-sidebar" id="review-nav">
                        <div class="preview-logo-container d-flex align-items-center justify-content-start border-bottom-preview">
                            <img class="preview-big-logo" id="p-large-icon" style="width: 80px; max-height: 30px;" alt="">
                            <img class="preview-small-logo" style="width: 35px; max-height: 35px;" id="p-small-icon" alt="">
                        </div>
                        <div class="p-sidebar-bottom">
                            <div class="sidebar-bottom-inner">
                                <i class="fa fa-user p-sidebar-icon"></i><span class="p-sidebar-acc-title"> {{'Manage Profile' | translate}}</span>
                            </div>
                            <div class="sidebar-bottom-inner preview-color">
                                <i class="fa fa-bar-chart p-sidebar-icon"></i><span class="p-sidebar-acc-title"> {{'Reports' | translate}}</span>
                            </div>
                            <div class="sidebar-bottom-inner">
                                <svg class="svg-t-color p-sidebar-svg-size" xmlns="http://www.w3.org/2000/svg" width="23.364" height="20" viewBox="0 0 23.364 23.364"><defs></defs><g transform="translate(8.944)"><path class="a" d="M198.738,0A2.78,2.78,0,0,0,196,2.784a2.738,2.738,0,1,0,5.476,0A2.78,2.78,0,0,0,198.738,0Z" transform="translate(-196)"/></g><g transform="translate(6.891 5.522)"><path class="a" d="M155.791,121A4.8,4.8,0,0,0,151,125.792a.684.684,0,0,0,.684.684H159.9a.684.684,0,0,0,.685-.684A4.8,4.8,0,0,0,155.791,121Z" transform="translate(-151 -121)"/></g><g transform="translate(15.789 12.367)"><path class="a" d="M348.738,271a2.738,2.738,0,1,0,2.738,2.738A2.741,2.741,0,0,0,348.738,271Z" transform="translate(-346 -271)"/></g><g transform="translate(13.736 17.843)"><path class="a" d="M305.792,391A4.837,4.837,0,0,0,301,395.837a.684.684,0,0,0,.685.684h8.26a.684.684,0,0,0,.685-.684A4.876,4.876,0,0,0,305.792,391Z" transform="translate(-301 -391)"/></g><g transform="translate(2.099 12.367)"><path class="a" d="M48.738,271a2.738,2.738,0,1,0,2.738,2.738A2.741,2.741,0,0,0,48.738,271Z" transform="translate(-46 -271)"/></g><g transform="translate(0 17.843)"><path class="a" d="M4.837,391A4.876,4.876,0,0,0,0,395.837a.684.684,0,0,0,.684.684h8.26a.684.684,0,0,0,.684-.684A4.837,4.837,0,0,0,4.837,391Z" transform="translate(0 -391)"/></g><g transform="translate(8.259 12.367)"><path class="a" d="M187.461,275.179,185.1,274v-2.315a.685.685,0,1,0-1.369,0V274l-2.36,1.18a.685.685,0,1,0,.612,1.225l2.432-1.216,2.432,1.216a.685.685,0,1,0,.612-1.225Z" transform="translate(-180.994 -271)"/></g></svg>
                                <span class="p-sidebar-acc-title"> {{'Manage Profile Group' | translate}}</span>
                            </div>
                            <div class="sidebar-bottom-inner">
                                <svg class="svg-t-color p-sidebar-svg-size" xmlns="http://www.w3.org/2000/svg" width="15.394" height="20" viewBox="0 0 15.394 23.364"><defs></defs><g transform="translate(1.569)"><path class="a" d="M126.226,0a4.523,4.523,0,1,0,4.523,4.523A4.528,4.528,0,0,0,126.226,0Z" transform="translate(-121.703)"/></g><g transform="translate(0 9.998)"><path class="a" d="M96.087,219.1H90.749a3.426,3.426,0,0,0-3.422,3.422v6.708a2.056,2.056,0,0,0,2.053,2.054h3.967a5.891,5.891,0,0,1,6.147-9.089A3.427,3.427,0,0,0,96.087,219.1Z" transform="translate(-87.326 -219.101)"/></g><g transform="translate(8.195 16.165)"><path class="a" d="M273.233,354.239l-6.32,6.32a4.521,4.521,0,0,0,6.32-6.32Z" transform="translate(-266.913 -354.239)"/></g><g transform="translate(6.348 14.318)"><path class="a" d="M230.961,313.763a4.521,4.521,0,0,0-3.644,7.2l6.32-6.32A4.5,4.5,0,0,0,230.961,313.763Z" transform="translate(-226.438 -313.763)"/></g></svg>
                                <span class="p-sidebar-acc-title"> {{'Manage Blacklist' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="flex:1;">
                        <div class="preview-navabr  border-bottom-preview d-flex align-items-center">
                            <button class="btn p-btn-bars" (click)="previewToggle()">
                            <i class="fa fa-bars"></i>
                        </button>
                            <h6 class="mb-0 preview-service-head">{{'My' | translate}}10T</h6>
                            <div class="p-main-toggle-btn">
                                <div class="p-inner-toggle-btn"></div>
                            </div>
                            <div class="p-profile-container">
                                <i class="fa fa-bell ml-3"></i>
                                <img class="p-profile ml-3 mr-2" src="/assets/images/icons/profile-placeholder.png" alt="">
                            </div>
                        </div>
                        <div class="p-2 preview-screen-inner-body">
                            <div class="preview-face-container pb-5 mb-2 d-flex">
                                <div class="d-flex p-search-container" style="flex:1;">
                                    <input type="email" class="form-control" disabled>
                                    <button class="btn preview-search-btn preview-bg-color outline">{{'Search' | translate}}</button>
                                </div>
                                <div class="d-flex">
                                    <button class="btn p-btn-grey outline"> </button>
                                    <button class="btn p-btn-grey outline"> </button>
                                    <button class="btn p-btn-grey outline"> </button>
                                    <button class="btn p-btn-rightmost preview-bg-color outline"> </button>
                                </div>
                            </div>
                            <div class="preview-face-container body-bottom-face-container">

                                <div class="preview-table-head">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="confirmationAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content t-modal">
                <div class="modal-header text-center border-bottom-light">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ confirmationVar === 'saveTheme' ? ('Save Theme'| translate) : (confirmationVar === 'factoryReset' ? ('Factory Reset'| translate) : ('Reset To Previous Theme'| translate))}} {{'Confirmation'| translate}}</h5>
                    <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center p-4">
                    <p *ngIf="confirmationVar === 'saveTheme'">{{'Do you want to save this theme? Changes will be applied to organization level' | translate}}. </p>
                    <p *ngIf="confirmationVar === 'factoryReset'">{{'Do you want to factory reset? Changes will be applied to organization level' | translate}}. </p>
                    <p *ngIf="confirmationVar === 'resetToPrevious'">{{'Do you want to reset to previous theme? Changes will be applied to organization level' | translate}}. </p>

                    <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner && confirmationVar === 'saveTheme'" (click)="onSave()"> {{'Confirm' | translate}}</button>
                    <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner && confirmationVar === 'factoryReset'" (click)="factoryReset()"> {{'Confirm' | translate}}</button>
                    <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner && confirmationVar === 'resetToPrevious'" (click)="resetToPreviousTheme()"> {{'Confirm' | translate}}</button>
                    <div class="btn-spinner" *ngIf="btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>