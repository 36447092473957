import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getInventory() {
    return this.http.post(`${this.serverUrl}/report/inventory`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  getDownloadReport(data) {
    return this.http.post(`${this.serverUrl}/report/profileDownload`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getPrepareReport(data) {
    return this.http.post(`${this.serverUrl}/report/prepare`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getResetReport(data) {
    return this.http.post(`${this.serverUrl}/report/reset`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getQrReport(data) {
    // return this.http.post(`${this.serverUrl}/report/qrcode`, data, this.getHeader())
    return this.http.post(`${this.serverUrl}/report/groupqrs`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendDownloadMail(data) {
    return this.http.post(`${this.serverUrl}/report/profileDownload/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendPreparedMail(data) {
    return this.http.post(`${this.serverUrl}/report/prepare/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendResetMail(data) {
    return this.http.post(`${this.serverUrl}/report/reset/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendQrMail(data) {
    return this.http.post(`${this.serverUrl}/report/groupqrs/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendInventoryMail(data) {
    return this.http.post(`${this.serverUrl}/report/inventory/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  downloadInventoryReport(data) {
    return this.http.post(`${this.serverUrl}/report/inventory/download`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDetailDownloadReport(data) {
    return this.http.post(`${this.serverUrl}/report/detailDownload`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendDetailDownloadReport(data) {
    return this.http.post(`${this.serverUrl}/report/detailDownload/mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDeletionReport(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/deletion-report`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getReEnableReport(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/re-enable-report`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDownloadAnalyticsReport(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/download-report`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDownloadAnalyticsInfoReport(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/download-report-info`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileDownloadReport(data) {
    return this.http.post(`${this.serverUrl}/report/profile-download-report`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileDownloadInfoReport(data) {
    return this.http.post(`${this.serverUrl}/report/profile-download-report-info`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDownloadChartReport(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/download-report-chart`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendDeviceDownloadReportMail(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/download-report-mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendDeviceDeleionReportMail(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/deletion-report-mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendDeviceReenableReportMail(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/re-enable-report-mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendProfileDownloadReportMail(data) {
    return this.http.post(`${this.serverUrl}/report/profile-download-report-mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  sendDeviceDownloadPieReportMail(data) {
    return this.http.post(`${this.serverUrl}/report/device-analytics/download-chart-report-mail`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getBrands() {
    return this.http.get(`${this.serverUrl}/report/get-brands`, this.getHeader())
      .pipe(map(response => response));
  }

  getGroupNames(profileTypesArr) {
    return this.http.post(`${this.serverUrl}/report/groupqr/names`, profileTypesArr, this.getHeader())
      .pipe(map(response => response));
  }

  getTotalDownload(data) {
    return this.http.post(`${this.serverUrl}/report/groupqr/downloadgroupqrgraph`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getTotalDownloadVSRemainingCount(data) {
    return this.http.post(`${this.serverUrl}/report/groupqr/downloadtdvsrdgroupqrgraph`, data, this.getHeader())
      .pipe(map(response => response));
  }
}
