import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageGroupProfileComponent } from './manage-group-profile.component';
import { ProfileInventoryComponent } from './profile-inventory/profile-inventory.component';

const routes: Routes = [
    {
        path: '', component: ManageGroupProfileComponent
    },
    {
        path: 'profile-inventory', component: ProfileInventoryComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManageGroupProfileRoutingModule { }
