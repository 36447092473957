<div class="total-download-container">
	<!-- <div class="total-download-header">
		<p>Total Downloaded</p>
	</div>
	<div class="total-download-data">
		<p>{{ totalDownload }}</p>
	</div> -->
	<div class="face-container-inner-bg p-2 d-flex justify-content-center align-items-center active" *ngIf="features && features[getFeatureIndex('db_total_download')].view">
		<div>
			<!-- <img src="assets/images/dashboard/td.png" class="dashboard-cards" alt=""> -->
			<svg xmlns="http://www.w3.org/2000/svg" width="52.154" height="52.154" viewBox="0 0 62.154 62.154" class="svg-t-color">
				<g id="arrow_2_" data-name="arrow (2)" transform="translate(0.001 0)">
					<g id="Group_878" data-name="Group 878" transform="translate(-0.001 0)">
						<path id="Path_331" class="a" data-name="Path 331" d="M114.965,26.272a6.747,6.747,0,0,0-9.511.033l-3.968,3.967V6.8a6.8,6.8,0,0,0-13.6,0V30.272l-3.908-3.908a6.916,6.916,0,0,0-4.862-2H79.1a6.725,6.725,0,0,0-4.72,11.489L90.739,52.211a5.592,5.592,0,0,0,7.9,0l16.3-16.3a6.918,6.918,0,0,0,2-4.885,6.652,6.652,0,0,0-1.978-4.753Z" transform="translate(-63.612)" fill="#1dbc8e"/>
						<path id="Path_332" class="a" data-name="Path 332" d="M460.856,483.885h-2.913a1.942,1.942,0,1,1,0-3.885h2.913a1.942,1.942,0,1,1,0,3.885Z" transform="translate(-400.644 -421.731)" fill="#1dbc8e"/>
						<path id="Path_333" class="a" data-name="Path 333" d="M4.856,483.885H1.942a1.942,1.942,0,1,1,0-3.885H4.856a1.942,1.942,0,1,1,0,3.885Z" transform="translate(0 -421.731)" fill="#1dbc8e"/>
						<path id="Path_334" class="a" data-name="Path 334" d="M120.788,483.885H81.942a1.942,1.942,0,1,1,0-3.885h38.846a1.942,1.942,0,1,1,0,3.885Z" transform="translate(-70.289 -421.731)" fill="#1dbc8e"/>
					</g>
				</g>
			</svg>
		</div>
		<div class="text-right ml-auto">
			<h4 class="dtitle pt-1 mr-2">{{dashboardData && dashboardData.totalDownloaded ? dashboardData.totalDownloaded : 0}}</h4>
			<!-- <p class="t-color mb-0">{{"Total Downloaded" | translate}}</p> -->
			<div class="d-flex inner-main-profile justify-content-end pr-0" style="padding-top: 2px;">
                <p class="t-color mb-0">{{"eSIM installed on Device" | translate}}</p>
                <div class="c-color c-info py-0 ml-2">
                    <i class="c-color c-info py-0 fa fa-info icon-count-class" style="cursor: pointer;" data-toggle="modal" data-target="#eSimOndeviceDownloadModal"></i>
                </div>
				<div *ngIf="manualRefresh && isDashboardUser">
					<i class="fa fa-refresh ml-4 mr-2 cursor-p" style="font-size: 1vw;" (click)="refresh()"></i>
				</div>
            </div>

			
		</div>    
	</div>
	<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
		Launch demo modal
	  </button>
</div>

