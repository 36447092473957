import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileService } from '../shared/services/profile.service';
import { GroupProfileService } from '../shared/services/group-profile.service';
import { CommunicationService } from '../shared/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UserState } from '../shared/stores/user/user.reducer';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { PdfGeneratorService } from '../shared/services/pdf-generator.service';
import { saveAs } from 'file-saver';
import { UiCustomizationService } from '../shared/services/ui-customization.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
declare var $: any;
import * as FilterAction from '../shared/stores/filter/filter.action';
import { FilterState } from '../shared/stores/filter/filter.reducer';

@Component({
  selector: 'app-manage-group-profile',
  templateUrl: './manage-group-profile.component.html',
  styleUrls: ['./manage-group-profile.component.scss']
})
export class ManageGroupProfileComponent implements OnInit {

  grpProfileForm: FormGroup;
  profileTypes;
  submitted = false;
  btnSpinner = false;
  selectedCount = 0;
  select = false;
  selectedIccidList = [];
  grpProfileList;
  editOn = false;
  profileIndex;
  grpProfileSpinner = true;
  spinner = true;
  qrCode;
  flg = true;
  features;
  qrCodeOptions = ['QR Code', 'QR Code With Logo', 'QR Code With Campaign'];
  qrOption = 'QR Code';
  qrCodeWithLogoImg = "assets/images/qr-code-with-logo.png";
  logoId;
  logosArray;
  logoSelectErr = false;
  logoImg;
  qrCodeIndex;
  fileSize = 1024 * 1024;
  selectedTempateId;
  templateList;
  campaignImg;
  campaignFormat = ['PNG', 'PDF'];
  selectedCampaignFormat = 'PNG';
  allLogoSpinner = false;
  logoSelected = false;
  defaultLogoId;
  tempLogoId;
  selectedLogoIndex;
  show = false;
  isprod;
  allowCreateButton = false;
  tabId = 'active';
  selectionWidth;
  lang;
  profileGroupSubscription: Subscription;
  groupDetails: any;
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private grpProfileService: GroupProfileService,
    private communicationService: CommunicationService,
    private translate: TranslateService,
    private userStore: Store<UserState>,
    private cookieTrailService: CookieTrailService,
    private pdfService: PdfGeneratorService,
    private filterStore: Store<FilterState>,
    private themeService: UiCustomizationService) {

      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      // const browserLang = translate.getBrowserLang();
      let browserLang = localStorage.getItem('language');
      this.lang = browserLang;
      if (!browserLang) {
        browserLang = 'en';
        this.lang = browserLang;
        this.getSelectionWidth();
        this.selectionWidth = '220px';
        localStorage.setItem('language', browserLang);
      } else {
        this.getSelectionWidth();
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
    }

  ngOnInit() {
    this.cookieTrailService.saveCookie({});

    this.isprod = (localStorage.getItem('isProd') === 'true');

    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Manage Group QR')].features;
        }
      });

    if(!this.isprod) {
      this.show = true;
      this.allowCreateButton = true;
    }

    this.initializeForm();

    this.allowManageGroup();

    this.communicationService.getRemovedCustomer()
      .subscribe((res: any) => {
        this.grpProfileSpinner = true;
        this.grpProfileList = [];
        this.allowManageGroup();
      });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });
  }

  getSelectionWidth() {
    switch (this.lang) {
      case 'en':
        this.selectionWidth = '220px';
        break;
      case 'pt':
        this.selectionWidth = '272px';
        break;
      case 'bm':
        this.selectionWidth = '250px';
        break;
      case 'sv':
        this.selectionWidth = '226px';
        break;
      default:
        this.selectionWidth = '220px';
        break;
    }
  }

  allowManageGroup() {
    this.grpProfileService.allowManageGroup()
      .subscribe((res: any) => {
        if(res) {
          this.show = true;
          this.allowCreateButton = true;
          this.getGroupManagementProfile();
        } else {
          $('#profileTypeRequired').modal();

          this.userStore.select('user')
            .subscribe((user: any) => {
              if(user) {
                this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Manage Group QR')].features;
              }
            });

            this.grpProfileList = [];
            this.grpProfileSpinner = false;

            if(this.isprod) {
              this.show = false;
            } else {
              this.show = true;
            }

            this.allowCreateButton = false;
        }
      }, err => {
        this.grpProfileSpinner = false;
        this.grpProfileList = [];
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });

  }

  getGroupManagementProfile() {
    this.initializeForm();
    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Manage Group QR')].features;
        }
      });

    this.grpProfileForm.get('groupType').valueChanges
      .subscribe(groupType => {
        if (groupType === 'private') {
          this.flg = false;
          this.f.downloadLimit.setValue('100');
        } else if (groupType === 'public') {
          this.flg = true;
        }
      });

    this.grpProfileForm.get('downloadLimit').valueChanges
      .subscribe(res => {
        if (this.grpProfileForm.get('groupType').value === 'private') {
          this.f.count.setValue(this.grpProfileForm.get('count').value);
        }
      });

    this.getGroupProfiles();
    this.getProfileTypes();
    let self = this;
    $('#selectLogoModal').on('hidden.bs.modal', function () {
      self.selectLogo();
    });
  }

  getFeatureIndex(keyName) {
      return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  changeLanguage() {
    this.lang = localStorage.getItem('language');
    this.getSelectionWidth();
    this.translate.use(this.lang);
  }

  getProfileTypes() {
    this.profileService.getProfileTypeListForGroup()
      .subscribe((res: any) => {
        this.profileTypes = res.profileTypes;
      }, err => {
        const cookieTrail = {
          screenName: 'Manage Group QR',
          action: 'Get Profile Types',
          error: err.error || 'Something went wrong!',
        };

        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
      });
  }

  getGroupProfiles() {
    this.grpProfileList = [];
    this.grpProfileSpinner = true;

    if (this.profileGroupSubscription) {
      this.profileGroupSubscription.unsubscribe();
    }

    this.profileGroupSubscription = this.grpProfileService.getProfileGroups(this.tabId)
      .subscribe((res: any) => {
        this.grpProfileSpinner = false;
        this.grpProfileList = res.profileGroups;
      }, err => {
        const cookieTrail = {
          screenName: 'Manage Group QR',
          action: 'Get Profile Groups',
          error: err.error || 'Something went wrong!',
        };

        this.cookieTrailService.saveCookie(cookieTrail);
        this.grpProfileSpinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
      });
  }

  get f() { return this.grpProfileForm.controls; }

  initializeForm() {

    this.grpProfileForm = new FormGroup({
      groupName: new FormControl('', [Validators.required]),
      groupType: new FormControl('public', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      status: new FormControl('active', [Validators.required]),
      profileType: new FormControl('', [Validators.required]),
      downloadLimit: new FormControl('100', [Validators.required, Validators.min(100)]),
      count: new FormControl('0', [Validators.required, Validators.min(0)]),
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.grpProfileForm.invalid) {
      return;
    }

    this.btnSpinner = true;

    const grpProfileData = {
      groupName: '10T_' + this.grpProfileForm.get('groupName').value + '_T01',
      groupType: this.grpProfileForm.get('groupType').value,
      description: this.grpProfileForm.get('description').value,
      groupStatus: this.grpProfileForm.get('status').value,
      profileType: this.grpProfileForm.get('profileType').value,
      downloadLimit: (this.grpProfileForm.get('groupType').value === 'private') ? this.grpProfileForm.get('count').value : this.grpProfileForm.get('downloadLimit').value,
      count: (this.grpProfileForm.get('groupType').value === 'private') ? this.grpProfileForm.get('count').value : 0,
      timeZone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
    };

    const cookieTrail = {
      screenName: 'Manage Group QR',
      action: 'Create Group QR',
      body: grpProfileData,
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.grpProfileService.createProfileGroup(grpProfileData)
      .subscribe((res: any) => {
        $('#createEditGroupProfile').modal('hide');
        this.btnSpinner = false;
        this.submitted = false;
        this.getGroupProfiles();
        this.grpProfileList.unshift(res.data);
        this.updateForm();
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'Group QR created successfully!' });
      }, err => {
        this.btnSpinner = false;
        this.submitted = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
      });
  }

  getSingleGrpProfile(index) {
    this.profileIndex = index;
    this.spinner = true;
    this.grpProfileService.getSingleProfile(this.grpProfileList[index].groupMatchingId)
      .subscribe((res: any) => {
        this.spinner = false;
        const temp = res.groupName.substring(4, res.length);
        const tempFinal = temp.substring(temp.length - 4, 0);

        this.f.groupName.setValue(tempFinal);
        this.f.groupType.setValue(res.groupType);
        this.f.description.setValue(res.description);
        this.f.status.setValue(res.status);
        this.f.profileType.setValue(res.profileType);
        this.f.downloadLimit.setValue(res.downloadLimit);
        if(res.groupType === 'private') {
          this.f.downloadLimit.setValue(100);
          this.f.count.setValue(res.downloadLimit);
        } else {
          this.f.count.setValue(res.currentAmount);
        }
      }, err => {
        this.spinner = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
      });

    ($('#createEditGroupProfile') as any).modal({ backdrop: 'static', keyboard: false });
  }

  editGrpProfile() {
    this.submitted = true;
    if (this.grpProfileForm.invalid) {
      return;
    }

    this.btnSpinner = true;

    const groupStatus = this.grpProfileForm.get('status').value;
    let grpProfileData;

    if(this.grpProfileForm.get('groupType').value === 'private') {
      grpProfileData = {
        groupMatchingId: this.grpProfileList[this.profileIndex].groupMatchingId,
        description: this.grpProfileForm.get('description').value,
        groupStatus: this.grpProfileForm.get('status').value,
        downloadLimit: this.grpProfileForm.get('count').value,
        timeZone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
      };
    } else {
      grpProfileData = {
        groupMatchingId: this.grpProfileList[this.profileIndex].groupMatchingId,
        description: this.grpProfileForm.get('description').value,
        groupStatus: this.grpProfileForm.get('status').value,
        downloadLimit: this.grpProfileForm.get('downloadLimit').value,
        timeZone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
      };
    }

    const cookieTrail = {
      screenName: 'Manage Group QR',
      action: 'Update Group QR',
      body: grpProfileData,
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.grpProfileService.editGrpProfile(grpProfileData)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.submitted = false;
        if (res.header.functionExecutionStatus.status === 'Executed-Success') {
          this.grpProfileList[this.profileIndex].groupStatus = groupStatus;
        } else {
          this.communicationService.openAlert({ alertType: 'error', alertMsg: 'Something went wrong!' });
          return;
        }
        this.getGroupProfiles();
        this.updateForm();
        $('#createEditGroupProfile').modal('hide');
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'Group QR edited successfully!' });
      }, err => {
        this.btnSpinner = false;
        this.submitted = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  updateForm() {
    this.submitted = false;
    this.f.groupName.setValue('');
    this.f.groupType.setValue('public');
    this.f.description.setValue('');
    this.f.status.setValue('active');
    this.f.profileType.setValue('');
    this.f.downloadLimit.setValue('100');
    this.f.count.setValue('');
  }

  deleteGrpProfile() {
    this.btnSpinner = true;

    const cookieTrail = {
      screenName: 'Manage Group QR',
      action: 'Delete Group QR',
      body: {
        groupMatchingId: this.grpProfileList[this.profileIndex].groupMatchingId
      },
    };

    this.cookieTrailService.saveCookie(cookieTrail);

    this.grpProfileService.deleteGrpProfile(this.grpProfileList[this.profileIndex].groupMatchingId)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        this.submitted = false;
        if (res.header.functionExecutionStatus.status === 'Executed-Success') {
          this.grpProfileList[this.profileIndex].groupStatus = 'deleted';
          const temp = this.grpProfileList[this.profileIndex];
          this.grpProfileList.splice(this.profileIndex, 1);
          this.grpProfileList.push(temp);
          this.getGroupProfiles();
        } else {
          this.communicationService.openAlert({ alertType: 'error', alertMsg: 'Something went wrong' });
          return;
        }
        $('#deletGrpProfile').modal('hide');
        this.communicationService.openAlert({ alertType: 'success', alertMsg: 'Group QR deleted successfully!' });
      }, err => {
        this.btnSpinner = false;
        this.submitted = false;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getQrcode(id) {
    if(this.features && this.features[this.getFeatureIndex('groupqr_with_campaign')].edit) {
      this.getCampaignList();
      this.getDefaultLogo();
    }

    const cookieTrail = {
      screenName: 'Manage Group QR',
      action: 'Get QR Code',
      body: {
        groupMatchingId: id
      },
    };

    this.cookieTrailService.saveCookie(cookieTrail);
    this.qrCodeWithLogoImg = "assets/images/qr-code-with-logo.png";

    this.grpProfileService.getQrcode(id)
      .subscribe((res: any) => {
        this.qrCode = res.message;
      }, err => {
        $('#qrCodeModal').modal('hide');
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong' });
      });
  }

  openGrpProfileModal() {
    if(this.allowCreateButton) {
      $('#createEditGroupProfile').modal();
      this.submitted = false;
    } else {
      this.communicationService.openAlert({alertType: 'error', alertMsg: 'No Profile Type Assigned!'});
    }
  }

  closeModal() {
    this.flg = true;
    this.f.groupName.setValue('');
    this.f.groupType.setValue('public');
    this.f.status.setValue('active');
    this.f.description.setValue('');
    this.f.profileType.setValue('');
  }

  uploadQrCodeLogo() {
    $('#group-qr-code-logo').click();
  }

  onQrLogoChange(evt) {

    if (evt.target.files[0].size > (1 * this.fileSize)) {
      this.communicationService.openAlert({ alertType: 'error', alertMsg: 'File size should not be more than 1mb' });
      return;
    }

    const fileUpload = evt.target.files[0];
    const formData = new FormData();
    formData.set('file', fileUpload);
    evt.target.value = '';

    this.profileService.uploadQrLogo(formData)
      .subscribe((res: any) => {
        this.logosArray.push({
          _id: res.result._id,
          customer: res.result.customer,
          data: res.pngData
        });
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
      });
  }

  getQrLogos() {
    this.logosArray = [];
    this.allLogoSpinner = true;

    this.profileService.getLogos()
      .subscribe((res: any) => {
        this.allLogoSpinner = false;
        this.logoSelected = false;
        this.selectedLogoIndex = '';
        if(res.message === 'No Logo Found!') {
          this.logosArray = [];
        } else {
          this.logosArray = res;
        }
        this.logoSelectErr = false;
        ($('#selectLogoModal') as any).modal({ backdrop: 'static', keyboard: false });
      }, err => {
        this.allLogoSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  selectLogo() {
    this.logoSelected = false;

    if(!this.logoId || (this.logoId === this.tempLogoId)) {
      // this.logoSelectErr = true;
      return;
    }

    const data = {
      logo: this.logoImg,
      text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
    }
    this.pdfService.addLogoInQr(data)
      .subscribe((res: any) => {
          this.qrCodeWithLogoImg = res.qrCode;
          $('#selectLogoModal').modal('hide');
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
    });
  }

  generateQrCode() {
    if(this.qrOption === 'QR Code') {
      saveAs(this.qrCode, `${this.grpProfileList[this.qrCodeIndex].groupName}.png`);
    } else if(this.qrOption === 'QR Code With Logo') {
      this.grpProfileService.getQrCodeWithLogo(this.grpProfileList[this.qrCodeIndex].groupMatchingId, {logoId: this.logoId})
        .subscribe((res: any) => {
          saveAs(res.qrCodeWithLogo, `${this.grpProfileList[this.qrCodeIndex].groupName}.png`);
        }, err => {
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Somethig went wrong'});
        });
    } else {
      this.grpProfileService.getQrCodeWithCampaign(this.selectedTempateId, {groupMatchingId: this.grpProfileList[this.qrCodeIndex].groupMatchingId, type: this.selectedCampaignFormat.toLowerCase()})
        .subscribe((res: any) => {
          if(this.selectedCampaignFormat.toLowerCase() === 'png') {
            saveAs('data:image/png;base64,' + res.data, `${this.grpProfileList[this.qrCodeIndex].groupName}.png`);
          } else {
            saveAs('data:image/png;base64,' + res.data, `${this.grpProfileList[this.qrCodeIndex].groupName}.pdf`);
          }
        }, err => {
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Somethig went wrong'});
        });
    }
  }

  getCampaignList() {
    this.selectedTempateId = '';
    if(this.features && this.features[this.getFeatureIndex('groupqr_with_campaign')].edit) {
      this.pdfService.getAllTemplates({'campaignType': 'group'})
      .subscribe((res: any) => {
        this.templateList = res;
        this.selectedTempateId = this.templateList[0]._id;
        if(this.selectedTempateId) {
          this.getSingleTemplate();
        }
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
    }
  }

  getSingleTemplate() {
    this.campaignImg = '';
    this.pdfService.getSingleTemplate(this.selectedTempateId)
      .subscribe((res: any) => {
        this.campaignImg = res.png;
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
      });
  }

  toggleBorderClass(i) {
    if(this.selectedLogoIndex >= 0 && (this.selectedLogoIndex != i)) {
      $('#single-logo-' + this.selectedLogoIndex).removeClass('img-logo-border');
    }
    $('#single-logo-' + i).toggleClass('img-logo-border');
    if($('#single-logo-' + i).hasClass('img-logo-border')) {
      this.logoSelected = true;
      this.selectedLogoIndex = i;
    } else {
      this.logoSelected = false;
      this.selectedLogoIndex = '';
    }
  }

  getDefaultLogo() {
    this.themeService.getDefaultLogo()
      .subscribe((res: any) => {
        if(res.message === 'No default logo found!') {
          this.logoId = '';
          this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
          this.defaultLogoId = '';
        } else {
          this.defaultLogoId = res._id;
          this.logoId = res._id;
          this.tempLogoId = this.logoId;
          const data = {
            logo: 'data:image/png;base64,' + res.data,
            text: 'LPA:1$ABC-1234.OBERABCD.ABC$ABCDE-ABCDE-A1BCD-ABCDE'
          }
          this.pdfService.addLogoInQr(data)
            .subscribe((res: any) => {
                this.qrCodeWithLogoImg = res.qrCode;
                $('#selectLogoModal').modal('hide');
            }, err => {
              this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong'});
            });
        }
      });
  }

  setDefaultLogo() {
    if(!this.logoId) {
      this.logoSelectErr = true;
      return;
    }

    let tempDefaultLogoId = this.defaultLogoId;
    this.defaultLogoId = this.logoId;

    this.themeService.setDefaultLogo({logoId: this.logoId})
      .subscribe((res: any) => {
      }, err => {
        this.defaultLogoId = tempDefaultLogoId;
        this.communicationService.openAlert({'alertType': 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  deleteLogo() {
    if(!this.logoId) {
      this.logoSelectErr = true;
      return;
    }

    this.themeService.deleteLogo({logoId: this.logoId})
      .subscribe((res: any) => {
        $('#single-logo-' + this.selectedLogoIndex).removeClass('img-logo-border')
        if(this.defaultLogoId != this.logoId) {
          this.logoId = this.defaultLogoId;
        } else {
          this.logoId = '';
          this.qrCodeWithLogoImg = 'assets/images/qr-code-with-logo.png';
          this.defaultLogoId = '';
        }
        this.logoSelected = false;
        this.logosArray.splice(this.selectedLogoIndex, 1);
        this.selectedLogoIndex = '';
      }, err => {
        this.communicationService.openAlert({'alertType': 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  isSubmitButtonDisabled() {
    return this.grpProfileForm.invalid
  }
  viewGroupProfile(i: number) {
    const target = this.grpProfileList[i];
    const navigationExtras = {
      state: {
        name: target.groupName,
        id: target.groupMatchingId
      }
    };
    this.router.navigate(['/manage-group-profile/profile-inventory'], navigationExtras);
  }
}
