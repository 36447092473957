import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ApiTreeComponent } from './api-tree.component';
import { ApiTreeRoutingModule } from './api-tree-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        ApiTreeComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ApiTreeRoutingModule,
        MatCheckboxModule,
        MatSnackBarModule,
        SharedModule
    ],
    exports: [
        ApiTreeComponent
    ]
})
export class ApiTreeModule { }
